import { Injectable } from '@angular/core';
import { ProductionCalendarDirectoryGridRow, ProductionCalendarDirectoryGridItem } from '../production-calendar-directory-grid.component';
import * as moment from 'moment';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from '../../../../../../../../src/app/modules/trace/decorators/func.decorator';

@Injectable()
@traceClass('ProductionCalendarDirectoryGridService')
export class ProductionCalendarDirectoryGridService {

  constructor(private readonly tracerService: TracerServiceBase) {
  }

  // Получить строки для grid
  @traceFunc()
  public getRows(days: ProductionCalendarDirectoryGridItem[]): ProductionCalendarDirectoryGridRow[] {
    const months = moment.months(); // Получаем имена месяцев

    const rows = months.map((m, i) => {

      const daysInMonth = days.filter(d => d.date.month() === i); // Получаем дни этого месяца
      const firstDayIndex = daysInMonth[0].date.isoWeekday() - 1; // Получаем индекс дня недели первого дня месяца

      const dataItems = Array.from(Array(firstDayIndex), () => null); // Генерируем пустые колонки, для сдвига первого дня месяца

      dataItems.push(...daysInMonth.map(d => new ProductionCalendarDirectoryGridItem(d.id, d.date, d.timestamp, d.dayType))); // Добавляем дни месяца

      return new ProductionCalendarDirectoryGridRow(m, dataItems); // Возращаем строку
    }); // Перебираем месяцы

    const maxLength = Math.max(...rows.map(r => r.dataItems.length)); // Получаем самый длинный месяц

    return rows.map(r => { // Добавляем каждому месяце нехватаюших пустых колонок в конце, для уравновешивания колонок между месяцами
      const { length } = r.dataItems;

      if (length >= maxLength) { // Если количество колонок больше или равно максимальной длине, нет смыла добавлять пусты колонки
        return r;
      }

      return new ProductionCalendarDirectoryGridRow(r.title, [
        ...r.dataItems, // Передаем прошлые колонки
        ...Array.from(Array(maxLength - length), () => null), // Добавляем пустые колонки
      ]);
    });
  }

  @traceFunc()
  public getColumns(length): string[] {
    const weekDays = moment.weekdaysShort(true); // Получаем короткие имена дней недели

    return Array.from(Array(length), (_, i) => weekDays[i % 7]);
  }
}
