import { Injectable } from '@angular/core';
import { WebApi1Service } from '../web-api1.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {IOccupation} from 'src/app/classes/domain/POCOs/stafflist/Occupation';
import {IOccupationType} from 'src/app/classes/domain/POCOs/stafflist/OccupationType';
import { ICode } from 'src/app/classes/domain/POCOs/timesheet_table/Code';

@Injectable({
  providedIn: 'root',
})
export class Api1OccupationsDirectoryGridControlControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить Occupations
   */
  public getForDate$(date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<OccupationsDirectoryGridItem>> {
    return this.httpClient
      .post<IGetOccupationsForDateResponseDTO>(this.webApi1Service.controllers.occupationsDirectoryGridControl.actions.getForDate.toString(),
        { ownerIds, startDate, endDate, date })
      .pipe(map(data => data.occupations.map(occupation => {

        const codes = occupation.codes.map(codeId => {
          const code = data.codes.find(c => c.id === codeId);
          return new OccupationsDirectoryGridItemCode(
            code.id,
            code.name,
            code.key,
            code.isComputedFlag,
          );
        });

        const occupationTypesPath: string[] = [];
        const occupationType = data.occupationTypes.find(f => f.id === occupation.occupationTypeId);
        const occupationTypeParent = data.occupationTypes.find(f => f.id === occupationType?.parentId);

        if (occupation.occupationTypeId) {

          if (occupationTypeParent) {
            occupationTypesPath.push(occupationTypeParent.name);
          }

          if (occupationType) {
            occupationTypesPath.push(occupationType.name);
          }
        }

        return new OccupationsDirectoryGridItem(
          occupation.id,
          occupation.startDate,
          occupation.endDate,
          occupation.comment,
          occupation.timestamp,
          occupation.name,
          occupation.code,
          occupation.occupationTypeId,
          occupationTypesPath.join(' / '),
          codes,
          codes.map(c => c.key).join(", ")
        );
      })));
  }
}

interface IGetOccupationsForDateResponseDTO {
  occupations: IOccupationDTO[];
  codes: Pick<ICode, 'id' | 'name' | 'key' | 'isComputedFlag'>[];
  occupationTypes: Pick<IOccupationType, 'id' | 'name' | 'parentId'>[];
}

interface IOccupationDTO extends Pick<IOccupation, 'id' | 'startDate' | 'endDate' | 'comment' | 'timestamp' | 'name' | 'code' | 'occupationTypeId'> {
  codes: Array<number>;
}


export class OccupationsDirectoryGridItem implements Pick<IOccupation, 'id' | 'startDate' | 'endDate' | 'comment' | 'timestamp' | 'name' | 'code' | 'occupationTypeId'> {
  public constructor(public id: number,
                     public startDate: Date,
                     public endDate: Date,
                     public comment: string,
                     public timestamp: [],
                     public name: string,
                     public code: string,
                     public occupationTypeId: number,
                     public occupationTypeName: string,
                     public codes: Array<OccupationsDirectoryGridItemCode>,
                     public codesString: string
  ) {
  }
}

export class OccupationsDirectoryGridItemCode implements Pick<ICode, 'id' | 'name' | 'key' | 'isComputedFlag'> {
  public constructor(public id: number,
                     public name: string,
                     public key: string,
                     public isComputedFlag: boolean,
  ) {
  }
}
