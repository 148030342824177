export class SnilsData {
  constructor(
    public snils: string
  ) {}

  static getAsString(data: SnilsData): string {
    return data?.snils;
  }
  static getAsString2(data: SnilsData): string {
    return data?.snils ? `${data?.snils.substring(0,3)}-${data?.snils.substring(3,6)}-${data?.snils.substring(6,9)}-${data?.snils.substring(9,11)}` : null;
  }
}

