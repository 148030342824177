import { Injectable } from "@angular/core";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";
import { IWorkModeOptional } from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import { exLoadingMessage } from "src/app/operators/ex-loading-message.operator";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1WorkModeControllerService } from "src/app/services/webApi/webApi1/controllers/api1-work-mode-controller.service";
import { Api1WorkModeTypesControllerService } from "src/app/services/webApi/webApi1/controllers/api1-work-mode-types-controller.service";
import { Api1WorkModesDirectoryGridControlControllerService } from "src/app/services/webApi/webApi1/controllers/api1-work-modes-directory-grid-control-controller.service";

@Injectable()
export class WorkModesDirectoryGridComponentDataService {


  constructor(private api1WorkModesDirectoryGridControlControllerService: Api1WorkModesDirectoryGridControlControllerService,
              private loadingIndicatorService: LoadingIndicatorService,
              private api1WorkModeControllerService: Api1WorkModeControllerService,
              private api1WorkModeTypesControllerService: Api1WorkModeTypesControllerService) {

  }

  public reloadData(date: Date, startDate: Date, endDate: Date) {
    return this.loadingIndicatorService.addToObservable("Загрузка списка периодов работы", this.api1WorkModesDirectoryGridControlControllerService.getForDate$(date, startDate, endDate));
  }

  public addWorkMode(entity: IWorkModeOptional) {
    return this.loadingIndicatorService.addToObservable("Добавление режима работы", this.api1WorkModeControllerService.addWorkMode$(entity))
  }

  public reloadByOwnerId(ownerId: number, date: Date, startDate: Date, endDate: Date) {
    return this.loadingIndicatorService.addToObservable("Обновление данных периода работы", this.api1WorkModesDirectoryGridControlControllerService.getForDate$(date, startDate, endDate, [ownerId]));
  }

  public reloadWorkModeTypes() {
    return this.loadingIndicatorService.addToObservable("Загрузка списка типов режимов работы", this.api1WorkModeTypesControllerService.getAll$());
  }

  public addWorkModeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem) {
    return this.api1WorkModeControllerService.addWorkModeVersion$(actionId, versioningEntity);
  }

  public deleteWorkModeVersion$(versionId: number){
    return this.api1WorkModeControllerService.deleteWorkModeVersion$(versionId);
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum){
    return this.api1WorkModeControllerService.getVersionsByOwnerIdAndActionId$(ownerId,actionId);
  }

  public editWorkModeVersion$(actionId: SupportedActionEnum, entity: VersioningItem) {
    return this.api1WorkModeControllerService.editWorkModeVersion$(actionId, entity);
  }

  public saveWorkMode$(entity){
    return this.api1WorkModeControllerService.save$(entity).pipe(exLoadingMessage(this.loadingIndicatorService, 'Сохранение данных'));
  }

}
