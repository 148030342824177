import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductionCalendarDirectoryGridDataSourceServiceBase } from './services/production-calendar-directory-grid-data-source.service';
import { ProductionCalendarDirectoryGridService } from './services/production-calendar-directory-grid.service';
import { map, Observable, shareReplay } from 'rxjs';
import { Moment } from 'moment';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { exElementByIndexOr } from '../../../../../../../src/app/operators/ex-element-by-index-or';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceClass } from '../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from '../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';
import {DayType} from "../../../../../../../src/app/classes/domain/POCOs/timesheet/DayType";

@Component({
  selector: 'app-production-calendar-directory-grid',
  templateUrl: './production-calendar-directory-grid.component.html',
  styleUrls: ['./production-calendar-directory-grid.component.css'],
  providers: [ProductionCalendarDirectoryGridService],
})
@traceClass('ProductionCalendarDirectoryGridComponent')
export class ProductionCalendarDirectoryGridComponent implements OnInit {

  public rows$: Observable<ProductionCalendarDirectoryGridRow[]>;
  public columns$: Observable<string[]>;

  @Output('onCellClick') public onCellClickEventEmitter = new EventEmitter<ProductionCalendarDirectoryGridItem>();
  @Output('onDbCellClick') public onDbCellClickEventEmitter = new EventEmitter<ProductionCalendarDirectoryGridItem>();

  private _dataSourceService: ProductionCalendarDirectoryGridDataSourceServiceBase;

  @Input()
  public get dataSourceService(): ProductionCalendarDirectoryGridDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: ProductionCalendarDirectoryGridDataSourceServiceBase) {
    if (this._dataSourceService) {
      throw Error('Повторная установка [dataSourceService] не поддерживается!');
    }
    this._dataSourceService = value;
  }

  private _selectedItem: ProductionCalendarDirectoryGridItem;

  @Input()
  public get selectedItem(): ProductionCalendarDirectoryGridItem {
    return this._selectedItem;
  }

  public set selectedItem(value: ProductionCalendarDirectoryGridItem) {
    this._selectedItem = value;
  }

  constructor(private readonly service: ProductionCalendarDirectoryGridService,
              private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit(): void {
    this.rows$ = this.dataSourceService.dataSource.data$.pipe(
      map(data => this.service.getRows(data)),
      shareReplay(),
    );

    this.columns$ = this.rows$.pipe(
      exElementByIndexOr(0),
      map(({ dataItems }) => this.service.getColumns(dataItems.length)),
      shareReplay(),
    );
  }

  @traceFunc()
  public onCellClick(e: CellClickEvent) {
    const dataItem = e.dataItem.dataItems[e.columnIndex - 1];

    if (dataItem) {
      this.onCellClickEventEmitter.emit(dataItem);
    }
  }

  @traceFunc()
  public onDbCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    const dataItem = e.originalEvent.dataItem.dataItems[e.originalEvent.columnIndex - 1];

    if (dataItem) {
      this.onDbCellClickEventEmitter.emit(dataItem);
    }
  }

}

export class ProductionCalendarDirectoryGridRow {
  constructor(public title: string,
              public dataItems: ProductionCalendarDirectoryGridItem[]) {
  }
}

export class ProductionCalendarDirectoryGridItem {
  constructor(public id: number,
              public date: Moment,
              public timestamp: [],
              public dayType: Pick<DayType, 'id' | 'name' | 'color'>) {
  }
}
