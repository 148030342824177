import { Injectable } from "@angular/core";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { SettingsReaderService } from "../../../services/classes/service-settings/settings-reader/settings-reader.service";
import { IComboboxDirectoryEditComponent } from "../i-combobox-directory-edit-control.component";
import { CustomFormValidationFunctions } from "src/app/validators/custom-form-validation-functions.class";
import { ReplaySubject, takeUntil } from "rxjs";

@Injectable()
@traceClass('ComboboxDirectoryEditComponentService')
export class ComboboxDirectoryEditComponentService extends ComponentServiceBase<IComboboxDirectoryEditComponent>{

  private srs = new SettingsReaderService();
  private _unsubscribe$ = new ReplaySubject(1);

  constructor(private tracer: TracerServiceBase)
  {
    super();
  }

  /** Считывание значений из настроек */
  @traceFunc()
  public readSettings() {
    this.component.data$ = this.srs.getValue$(this.component.settings.data);
    this.component.valueField = this.component.settings?.valueField ?? 'id';
  }

  /** Регистрация валидаторов*/
  @traceFunc()
  public addValidators() {
    CustomFormValidationFunctions.validateDataWithControlValue$(this.component.data$, this.component.control, (d,c)=> d[this.component.valueField] === c).pipe(takeUntil(this._unsubscribe$)).subscribe();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
}
