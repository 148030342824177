import {IEntityOwnerId} from "../domain/POCOs/interfaces/IEntityOwnerId";
import {DbChangedListener_Entity} from "../../services/signal-r/listeners/db-changed-listener";
import {ArrayHelper} from "../../helpers/arrayHelper";
import {
  DataState_Item,
  DataStateBuilder,
  DataStateBuilder_PropStateType
} from "./data-state-builder";


/**
 * Строитель состояния данных, реализующих IEntityOwnerId, по событиям signalR<br>
 * Использовать, если данные построены с учетом версионности<br>
 *
 * Принцип определения состояния данных:<br>
 *  Удаление:
 *    1. Если удалили root
 *
 *  Модификация:
 *    1. Если удалили версию
 *    3. Если добавили версию
 *    2. Если модифицировали версию
 *    3. Если модифицировали root
 *
 *  Добавление:
 *   1. Если добавили root
 */
export class DataHasOwnerStateBuilder<TSignalR extends IEntityOwnerId, TSource> extends DataStateBuilder<TSignalR, TSource>{

  constructor(signalR: DbChangedListener_Entity<TSignalR>[],
              data: TSource[],
              idDataGetter: (item: TSource) => IEntityOwnerId['id']) {
    super(DataHasOwnerStateBuilder.distinctByOwnerId(signalR), data, (signalRObj, item) => {
      return signalRObj.ownerId === idDataGetter(item);
    }, 'state');
  }


  protected getState(item: DataState_Item<TSignalR, TSource>, statePropSource: DataStateBuilder_PropStateType): DbChangedListener_Entity<any>["state"] {
    const signalRState = item.signalR[statePropSource];
    const currentOrOrigin = item.signalR.currentOrOrigin;

    if(signalRState === 'deleted' && currentOrOrigin.id == currentOrOrigin.ownerId){//Если удалили root
      return 'deleted';
    }

    if(signalRState === 'added' && currentOrOrigin.id == currentOrOrigin.ownerId){//Если добавили root
      return 'added';
    }

    return 'modified';
  }

  /**
   * Distinct массива по ownerId<br>
   * <br>
   * 1. Метод группирует по ownerId<br>
   * 2. Каждый массив относящийся к конкретному ownerId, сортирует по id. Тем самым, если в массиве имеется root он будет первым<br>
   * 3. Берет первый элемент из каждого массива<br>
   * @private
   */
  private static distinctByOwnerId<T extends IEntityOwnerId>(arr: DbChangedListener_Entity<T>[]): DbChangedListener_Entity<T>[]{
    return ArrayHelper.groupBy(arr, x => x.currentOrOrigin.ownerId)
      .map(x => x.values.sort((a, b) => {
        return a.currentOrOrigin.id - b.currentOrOrigin.id;
      })[0]);
  }
}
