
/**
 * Токен для часов
 * HH - 0..23
 * hh - 0..12
 * kk - 1..24
 */
type HoursTokenType = 'HH' | 'hh' | 'kk';

/**
 * Токен для минут
 * mm - 0..59
 */
type MinuteTokenType = 'mm';

/**
 * Токен для секунд
 * ss -0..59
 */
type SecondTokenType = 'ss';

/** Базовый тип для настроек */
type SettingsBaseType<TToken> = {
  /** Токен */
  token: TToken
}

/** Базовый тип для настроек */
type SettingsBaseType2<TToken> = SettingsBaseType<TToken> & {
  /** Разделитель */
  separator: string,
  /** Может ли отсутствовать часть */
  isOptional: boolean
}

/** Тип настроек для секунд */
type HoursSettingType = SettingsBaseType<HoursTokenType>;
/** Тип настроек для минут */
type MinuteSettingType = SettingsBaseType2<MinuteTokenType>;
/** Тип настроек для секунд */
type SecondsSettingType = SettingsBaseType2<SecondTokenType>;
/** Тип настроек для миллисекунд */
type MillisecondsSettingsType = Pick<SettingsBaseType2<any>, 'separator' | 'isOptional'> & {
  /** Минимальное количество миллисекунд. От 1 до 9(включительно) */
  min: number,
  /** Максимальное количество миллисекунд. От 1 до 9(включительно) */
  max: number
}

interface ITimeOnlyDefaultSettings{
  hours: HoursSettingType,
  minutes: MinuteSettingType | null,
  seconds: SecondsSettingType | null,
  milliseconds: MillisecondsSettingsType | null
}

interface ITimeOnlyDefaultSettingsReadOnly{
  readonly hours: Readonly<HoursSettingType>,
  readonly minutes: Readonly<MinuteSettingType> | null,
  readonly seconds: Readonly<SecondsSettingType> | null,
  readonly milliseconds: Readonly<MillisecondsSettingsType> | null
}

/** Настройки по умолчанию */
export const TIMEONLY_DEFAULT_SETTINGS : ITimeOnlyDefaultSettingsReadOnly = {
  hours: {token: 'HH'},
  minutes: {token: 'mm', separator: ':', isOptional: false},
  seconds: {token: 'ss', separator: ':', isOptional: true},
  milliseconds: {separator: '.', min: 1, max: 9, isOptional: true}
}

/** Строитель форматов для парсинга времени */
export class TimeOnlyFormatBuilder{

  private _hoursSettings: HoursSettingType;
  /** Настройки часов */
  public get hoursSettings(): Readonly<HoursSettingType>{
    return this._hoursSettings;
  }

  private _minutesSettings: MinuteSettingType;
  /** Настройки минут */
  public get minutesSettings(): Readonly<MinuteSettingType>{
    return this._minutesSettings;
  }

  private _secondsSettings: SecondsSettingType;
  /** Настройки секунд */
  public get secondsSettings(): Readonly<SecondsSettingType>{
    return this._secondsSettings;
  }

  private _millisecondsSettings: MillisecondsSettingsType;
  /** Настройки миллисекунд */
  public get millisecondsSettings(): Readonly<MillisecondsSettingsType>{
    return this._millisecondsSettings;
  }

  /**
   * Конструктор
   * @param hours настройки часов
   * @param minutes настройки минут. undefined отсутствуют
   * @param seconds настройки секунд. undefined отсутствуют
   * @param milliseconds настройки миллисекунд. undefined отсутствуют
   */
  constructor(hours: HoursSettingType = TIMEONLY_DEFAULT_SETTINGS.hours,
              minutes: MinuteSettingType | null = TIMEONLY_DEFAULT_SETTINGS.minutes,
              seconds: SecondsSettingType | null = TIMEONLY_DEFAULT_SETTINGS.seconds,
              milliseconds: MillisecondsSettingsType | null = TIMEONLY_DEFAULT_SETTINGS.milliseconds) {
    this._hoursSettings = hours;
    this._minutesSettings = minutes;
    this._secondsSettings = seconds;
    this._millisecondsSettings = milliseconds;
  }

  /**
   * Копировать
   * @param settingsModFn функция модификации текущих настроек
   */
  public copy(settingsModFn: (settings: ITimeOnlyDefaultSettings) => void = undefined): TimeOnlyFormatBuilder{
    const settings: ITimeOnlyDefaultSettings = {
      hours: {...this.hoursSettings},
      minutes: {...this.minutesSettings},
      seconds: {...this.secondsSettings},
      milliseconds: {...this.millisecondsSettings}
    }

    if(settingsModFn){
      settingsModFn(settings);
    }

    return new TimeOnlyFormatBuilder(settings.hours, settings.minutes, settings.seconds, settings.milliseconds);
  }

  /** Построить */
  public build(): string[]{
    this.validateMillisecondsSettings(this._millisecondsSettings);

    const patterns: string[] = [this._hoursSettings.token];

    if(this._minutesSettings){ //Минуты
      this.expandPatterns(patterns, `${this._minutesSettings.separator}${this._minutesSettings.token}`, this._minutesSettings.isOptional);
    }

    if(this._secondsSettings){
      this.expandPatterns(patterns, `${this._secondsSettings.separator}${this._secondsSettings.token}`, this._secondsSettings.isOptional);
    }

    if(this._millisecondsSettings){
      const originPatterns = [...patterns];

      for (let count = this._millisecondsSettings.min; count <= this._millisecondsSettings.max; count++){
        const pattern = `${this._millisecondsSettings.separator}${'S'.repeat(count)}`;

        if(count === this._millisecondsSettings.min){ //Если первый цикл
          this.expandPatterns(patterns, pattern, this._millisecondsSettings.isOptional);
        } else {
          const patternsTemp = [...originPatterns];
          this.expandPatterns(patternsTemp, pattern, false); //Передаем isOptional == false так как мы уже скопировали
          patterns.push(...patternsTemp);
        }
      }
    }

    return patterns.reverse();
  }

  /** Добавляет паттерн в существующий массив паттернов */
  private expandPatterns(targetPatternArrays: string[], pattern: string, isOptional: boolean){
    const length = targetPatternArrays.length;
    for (let i = 0; i < length; i++){
      const newPattern = `${targetPatternArrays[i]}${pattern}`;

      if(isOptional){
        targetPatternArrays.push(newPattern)
      } else {
        targetPatternArrays[i] = newPattern;
      }
    }
  }

  /** Валидация настроек миллисекунд */
  private validateMillisecondsSettings(settings: MillisecondsSettingsType){
    if(!settings){
      return;
    }

    if(settings.min < 1 || settings.min > 9){
      throw new Error('минимальное количество знаков содержащих информацию о миллисекундах не может быть меньше 1 и больше 9');
    }

    if(settings.max < 1 || settings.max > 9){
      throw new Error('максимальное количество знаков содержащих информацию о миллисекундах не может быть меньше 1 и больше 9');
    }

    if(settings.min > settings.max){
      throw new Error('минимальное количество знаков содержащих информацию о миллисекундах не может быть больше максимального количества');
    }
  }
}
