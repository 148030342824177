import {Injectable, OnDestroy} from "@angular/core";
import {SignalRHub} from "../signalR-hub";
import {AppSettingsService} from "../../app-settings.service";
import {defer, Observable, of, ReplaySubject, Subject} from "rxjs";
import {filter, finalize, map, switchMap, takeUntil, tap} from "rxjs/operators";
import {ISendObj} from "../i-send-obj";
import {IEntityDeletedFlag} from "../../../classes/domain/POCOs/interfaces/IEntityDeletedFlag";
import {ObjComparer} from "../../../classes/object-comparers/object-comparer";
import {classBackend, getClassBackendMetadata} from "../../../decorators/classBackend/classBackend.decorator";

/** Тип поля входного объекта */
type PropertyType<TCtor extends abstract new (...args: any) => any> = TCtor;

/** Тип входного объекта в метод onMulti */
type OnMultiInputType = {
  [key: string]: PropertyType<abstract new (...args: any) => any>
}

/** Тип выходного объекта данных метода onMulti */
type OnMultiReturnType<TInputType extends OnMultiInputType> = {
  [Property in keyof TInputType]: TInputType[Property] extends abstract new (...args: any) => any
    ? DbChangedListener_Entity<InstanceType<TInputType[Property]>>[]
    : never;
}


/** Слушатель изменений в базе данных */
@Injectable({providedIn: "root"})
export class DbChangedListener implements OnDestroy{
  /** Метод signalR */
  public readonly method: string = 'DataBaseChanged/POCOs';

  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    method: new Subject<ISendObj<DbChangedListener_EntityExpanded<any>[]>>(),
  }

  /** Конструктор */
  constructor(private readonly signalRHub: SignalRHub,
              private readonly appSettingsService: AppSettingsService) {
    this.signalRHub.subscribe<any>(this.method).pipe(
      tap(value => {
        if(value.company != this.appSettingsService.company){
          throw new Error('Сообщение signalR содержит данные не относящиеся к текущей клинике')
        }
      }),
      map(value => convertSendObj(value, data => (data as any[]).map(x => DbChangedListener_EntityExpanded.Create1(this.method, x)))),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(value => {
      this.streams$.method.next(value);
    })
  }

  /**
   * Подписаться на изменение POCOs объекта<br>
   * Класс {@link entityType} должен иметь декоратор {@link @classBackend} иначе будет ошибка<br>
   * Отправка подписи на сервер произойдет в момент подписи на результирующий Observable, а не в момент вызова этого метода<br>
   * Отправка отписки на сервер произойдет автоматически, когда завершится стрим(.pipe(take() | takeUntil()))<br><br>
   *
   * @example
   * .on(StaffUnit).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => { ..Логика })
   * В данном коде: как только сработает событие this.streams$.unsubscribe, стрим будет завершен и в следствие отпишется от сервера.
   * Т.е необходимо контролировать время жизни возвращаемого стрима. Все остальное происходит под капотом.
   */
  public on<TCtor extends abstract new (...args: any) => any>(entityType: TCtor){
    return this.onMulti({
      entities: entityType
    }).pipe(
      map(x => convertSendObj(x, x => x.entities))
    )
  }

  /**
   * Подписаться на изменение нескольких POCOs объектов<br>
   * POCOs объекты должны иметь декоратора {@link classBackend} иначе будет ошибка<br>
   * Отправка подписи на сервер произойдет в момент подписи на результирующий Observable, а не в момент вызова этого метода<br>
   * Отправка отписки на сервер произойдет автоматически, когда завершится стрим(.pipe(take() | takeUntil()))<br><br>
   *
   * @example
   * .on({subdivision: Subdivision, employee: {entityType: Employee, schema: 'dbo'}}).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => { ..Логика })
   * В данном коде: как только сработает событие this.streams$.unsubscribe, стрим будет завершен и в следствие отпишется от сервера.
   * Т.е необходимо контролировать время жизни возвращаемого стрима. Все остальное происходит под капотом.
   */
  public onMulti<TInput extends OnMultiInputType>(input: TInput): Observable<ISendObj<OnMultiReturnType<TInput>>>{
    if(!input){
      throw new Error('Входной параметр не передан');
    }

    const inputEntries = Object.entries(input)
      .map(keyValue => {
        const classBackend = getClassBackendMetadata(keyValue[1]);

        if(!classBackend){
          throw new Error(`При подписи на signalR, класс должен иметь декоратор classBackend. Не валидное поле "${keyValue[0]}" в переданном объекте.`);
        }

        if(!classBackend.scheme){
          throw new Error(`При подписи на signalR, декоратор класса classBackend должен иметь scheme. Не валидное поле "${keyValue[0]}" в переданном объекте.`);
        }

        return {
          propName: keyValue[0],
          ctor: keyValue[1],
          fullMethod: buildMethod(this.method, classBackend.name, classBackend.scheme)
        };
      })

    if(inputEntries.length == 0){
      throw new Error('Передан пустой входной параметр');
    }

    return defer(() => {
      this.signalRHub.subscribes(...inputEntries.map(x => x.fullMethod));
      return this.streams$.method;
    }).pipe(
        map(value => {
          const data = inputEntries.map(inputEntry => {
            const arr: DbChangedListener_Entity<InstanceType<typeof inputEntry['ctor']>>[] = value.data
              .filter(x => x.methodByTableName === inputEntry.fullMethod || x.methodByTableAndSchemaName == inputEntry.fullMethod)
              .map(x => x.entity);

            return {
              propName: inputEntry.propName,
              arr: arr
            };
          })

          return convertSendObj(value, source => data);
        }),
        filter(value => value.data.some(x => x.arr.length > 0)),
        map(value => { //Конвертируем в результирующий тип
          const resultData = {};
          value.data.forEach(x => resultData[x.propName] = x.arr);

          return convertSendObj(value, source => resultData as any);
        }),
        finalize(() => {
          this.signalRHub.unsubscribes(...inputEntries.map(x => x.fullMethod)); //Отписываемся от всех событий
        }),
      )
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.method.complete();
    this.signalRHub.unsubscribe(this.method);
  }
}

/** Интерфейс события изменений в таблицах поддерживающих версионность */
interface IDbChangedListener_Item<TEntity> {
  /** Название схемы таблицы */
  schemaName: string;
  /** Название таблицы в которой произошли изменения */
  tableName: string;
  /** Идентификатор записи */
  id: string;
  /** Будет заполен если таблица поддерживает версионность*/
  ownerId?: string;
  /** Объект Entity */
  entity: IEntity<TEntity>;
}

/** Интерфейс Entity */
interface IEntity<TEntity>{
  /** Оригинальный объект(до изменения) */
  origin: TEntity;
  /** Текущий объект(со всеми изменениями) */
  current: TEntity;
}

/** Класс расширяет Entity методами signalR к которым он относится */
export class DbChangedListener_EntityExpanded<TEntity>{
  constructor(public readonly methodByTableName: string,
              public readonly methodByTableAndSchemaName: string,
              public readonly entity: DbChangedListener_Entity<TEntity>) {
  }

  /** Конструктор */
  public static Create1<T>(method: string, item: IDbChangedListener_Item<T>){
    return new DbChangedListener_EntityExpanded<T>(
      buildMethod(method, item.tableName),
      buildMethod(method, item.tableName, item.schemaName),
      new DbChangedListener_Entity<T>(item.entity.origin, item.entity.current)
    );
  }
}

/** Тип состояния объекта Entity */
type EntityState = 'deleted' | 'added' | 'modified';

/** Класс Entity */
export class DbChangedListener_Entity<TEntity>{
  /** Состояние объекта Entity как в базе данных */
  public readonly dbState: EntityState;

  /**
   * Состояние объекта Entity с учетом флага .DeletedFlag.<br>
   * Состояние в базе данных может быть как модифицирован, но при этом поставили/сняли флаг удален.
   */
  public readonly state: EntityState;

  /** Вернет current если != null иначе origin */
  public get currentOrOrigin(){
    return this.current ? this.current : this.origin;
  }

  constructor(public readonly origin: Readonly<TEntity>,
              public readonly current: Readonly<TEntity>) {
    this.dbState = this.getDbState();
    this.state = this.getState();
  }

  /**
   * Проверяет, изменилось ли поле <br>
   * Если this.state !== 'modified' вернет false
   */
  public propIsModified<Key extends keyof TEntity>(key: Key, comparer: (item1: TEntity[Key], item2: TEntity[Key]) => boolean = ObjComparer.defaultComparer){
    if(this.state != 'modified'){
      return false;
    }

    return !comparer(this.origin[key], this.current[key]);
  }

  /**  Получить состояние как в базе данных  */
  private getDbState(): EntityState{
    if(!this.origin){
      return 'added';
    }

    if(!this.current){
      return 'deleted';
    }

    return 'modified';
  }

  /** Получить состояние с учетом .DeletedFlag */
  private getState(): EntityState{
    if(this.dbState === 'deleted'){
      return 'deleted';
    }

    const deletedFlagPropName: keyof IEntityDeletedFlag = 'deletedFlag';
    if(!(deletedFlagPropName in this.current)){ //Если поле deletedFlag отсутствует в объекте
      return this.dbState;
    }

    if(this.dbState === 'added'){ //Объект может быть добавлен с флагом deletedFlag == true
      return this.current[deletedFlagPropName] ? 'deleted' : 'added';
    }

    if(this.dbState === 'modified' && this.origin[deletedFlagPropName] !== this.current[deletedFlagPropName]){ //Если модифицировали deletedFlag
      return this.current[deletedFlagPropName] ? 'deleted' : 'added';
    }

    return this.dbState;
  }
}

/** Функция строит полный Метод для signalR */
export function buildMethod(method: string, tableName: string, schemaName?: string){
  const schemaPath = !schemaName ? '' : `/${schemaName}`;
  return `${method}/${tableName}${schemaPath}`;
}

/** Функция конвертирует SendObj */
function convertSendObj<TDataSource, TDataResult>(sendObj: ISendObj<TDataSource>, convertFunc: (source: TDataSource) => TDataResult){
  const result: ISendObj<TDataResult> = {
    tokenGuid: sendObj.tokenGuid,
    isSelfInitiator: sendObj.isSelfInitiator,
    company: sendObj.company,
    program: sendObj.program,
    data: convertFunc(sendObj.data)
  }

  return result;
}
