import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ReplaySubject } from "rxjs";
import { PositionRate } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { TraceParamEnum } from "src/app/modules/trace/decorators/classes/traceSetting.interface";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { CustomFormValidators } from "src/app/validators/custom-form-validators.class";

@Component({
  selector: 'app-add-position-rate-form',
  templateUrl: './add-position-rate-form.component.html',
  styleUrls: ['./add-position-rate-form.component.css']
})

@traceClass('AddPositionRateFormComponent')
export class AddPositionRateFormComponent implements OnInit, OnDestroy {

  @Input("minDate") public minDate: Date;
  @Input("maxDate") public maxDate: Date;

  @Input("dataItem") public dataItem: any;

  @Input("financingSourcesDropDownItems") public financingSourcesDropDownItems: Array<IDropDownItem>;
  @Input("isShowEditFinancingSource") public isShowEditFinancingSource: boolean = true;

  @Input("rateStep") public rateStep: number;

  @Output("onSave") public onSaveEventEmitter = new EventEmitter<any>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>();

  private streams$:{
    unsubscribes: ReplaySubject<any>
  }={
    unsubscribes: new ReplaySubject<any>(1)
  }


  public form: UntypedFormGroup;

  constructor(private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    if(!this.rateStep) {
      throw new Error("[rateStep] обязательно для заполнение в компоненте AddPositionRateFormComponent");
    }
    this.initForm(this.dataItem);
  }

  @traceFunc()
  initForm(item: PositionRate) {
    this.form = new UntypedFormGroup({
      startDate: new UntypedFormControl(item.startDate ?? null, [CustomFormValidators.validateStartDateWithEndDate('endDate'), CustomFormValidators.requiredIf(() => !!this.minDate)]),
      endDate: new UntypedFormControl(item.endDate ?? null, [CustomFormValidators.validateEndDateWithStartDate('startDate'), CustomFormValidators.requiredIf(() => !!this.maxDate)]),
      comment: new UntypedFormControl(item.comment ?? "", Validators.maxLength(100)),
      rate: new UntypedFormControl(item.rate ?? "", [Validators.required, Validators.min(0), Validators.max(1000), CustomFormValidators.multipleOf(this.rateStep)]),
      positionId: new UntypedFormControl(item.positionId ?? null,[Validators.required]),
      financingSourceId: new UntypedFormControl(item.financingSourceId ?? null, [Validators.required]),
    });

    this.form.valueChanges.subscribe(v => this.tracerService.add2("Изменение формы", {obj: v}));
    this.form.markAllAsTouched();
  }

 @traceFunc()
  onClickSave() {
    this.onSaveEventEmitter.emit(this.form.value);
  }

  @traceFunc()
  onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
