import { Observable } from "rxjs";
import {
  CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase
} from "../../../../../modules/auth/interceptors/catch-staff-unit-approved-graph-table.interceptor";
import {Injectable} from "@angular/core";
import {Api1MonitoringTableService} from "../monitoring/api1-monitoring-table.service";
import {Api1MonitoringGraphService} from "../monitoring/api1-monitoring-graph.service";

@Injectable({
  providedIn: 'root'
})
export class CatchStaffUnitApprovedGraphTableInterceptorService extends CatchStaffUnitApprovedGraphTableInterceptor_ServiceBase {

  constructor(private readonly api1MonitoringTableService: Api1MonitoringTableService,
              private readonly api1MonitoringGraphService: Api1MonitoringGraphService) {
    super();
  }

  public tableToWork$(ids: number[]): Observable<void> {
    return this.api1MonitoringTableService.tryCancelRouting$(ids) as any;
  }

  public graphToWork$(ids: number[]): Observable<void> {
    return this.api1MonitoringGraphService.tryCancelRouting$(ids) as any;
  }
}
