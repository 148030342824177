import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IReportPeriodOptional, ReportPeriod } from 'src/app/classes/domain/POCOs/stafflist/ReportPeriod';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { IAddReportPeriodFormComponent, IAddReportPeriodFormComponentForm } from './i-add-report-period-form.component';
import { AddReportPeriodFormComponentService } from './services/add-report-period-form.component.service';

@Component({
  selector: 'app-add-report-period-form',
  templateUrl: './add-report-period-form.component.html',
  styleUrls: ['./add-report-period-form.component.html'],
  providers: [AddReportPeriodFormComponentService]
})
@traceClass('AddReportPeriodFormComponent')
export class AddReportPeriodFormComponent implements IAddReportPeriodFormComponent, OnInit {
  @Input("dataItem") public dataItem: IReportPeriodOptional

  @Output("onSave") public onSaveEventEmitter = new EventEmitter<IReportPeriodOptional>()
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>()

  public form: FormGroup<IAddReportPeriodFormComponentForm>

  constructor(private readonly traceService: TracerServiceBase,
              private readonly service: AddReportPeriodFormComponentService) {
    service.component = this
  }

  @traceFunc()
  ngOnInit() {
    this.service.init()
  }

  @traceFunc()
  onClickSave() {
    this.service.onClickSave()
  }

  @traceFunc()
  onClickCancel() {
    this.service.onClickCancel()
  }
}
