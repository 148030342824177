<kendo-grid [kendoGridBinding]="rows$ | async"

            KendoGridExpanded
            (cellClick)="onCellClick($event)"
            (dbCellClick)="onDbCellClick($event)"
>

  <kendo-grid-column title="Месяц" field="title" class="no-padding" width="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-div cell-div-title">{{ dataItem.title }}</div>
    </ng-template>
  </kendo-grid-column>

  <ng-container *ngFor="let column of columns$ | async; let i = index">
    <kendo-grid-column [title]="column" class="no-padding" width="42">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngTemplateOutlet="dayCell; context: { currentSelectedItem: selectedItem, dataItem: dataItem.dataItems[i] }"></ng-container>
        <ng-template #dayCell let-currentSelectedItem="currentSelectedItem" let-dataItem="dataItem">
          <div *ngIf="dataItem; else cellDivEmpty" class="cell-div"  [style.background-color]="dataItem.dayType.color" [class.cell-div-selected]="currentSelectedItem?.id === dataItem?.id">
            {{ dataItem.date?.format("DD") }}
          </div>
          <ng-template #cellDivEmpty>
            <div class="cell-div cell-div-empty">&nbsp;</div>
          </ng-template>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
  </ng-container>

</kendo-grid>
