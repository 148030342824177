import { Injectable } from "@angular/core";
import { DialogCloseResult, DialogRef, DialogService, DialogSettings } from "@progress/kendo-angular-dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PassportData } from "src/app/classes/extendedDatas/employeeExtended/passport-data";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { IValueResult } from "../services/edit-passport-data-component-form.service";
import { EditPassportDataDialogComponent } from "./edit-passport-data-dialog-component/edit-passport-data-dialog.component";

@Injectable({
  providedIn: "root"
})
@traceClass('EditPassportDataDialogService')
export class EditPassportDataDialogService{
  constructor(private dialogService: DialogService, private tracerService: TracerServiceBase) {
  }

  /** Показать диалог добавления паспортных данных */
  @traceFunc()
  public showAdd(dialogSettings?: DialogSettings) {
    return this.showDialog('add','Добавление паспортных данных', null,dialogSettings);
  }


  /** Показать диалог редактирования паспортных данных */
  @traceFunc()
  public showEdit(data: PassportData, dialogSettings?: DialogSettings) {
    return this.showDialog('edit','Редактирование паспортных данных',data,dialogSettings);
  }

  /** Основной метод отображения диалога добавления паспортных данных */
  @traceFunc()
  private showDialog(action:ActionType, title: string, data?: PassportData, options?: DialogSettings): IShowResult{
    if(!options){
      options = {}
      options.title = title;
      options.minWidth = "50%"
    }

    options.content = EditPassportDataDialogComponent;

    const dialogRef = this.dialogService.open(options);
    const component: EditPassportDataDialogComponent = dialogRef.content.instance;

    component.data = data;

    const retSubj$ = new Subject<EditPassportDataDialogService_Result>();
    let actualData: IValueResult;
    component.onDataChanged$.pipe(takeUntil(retSubj$)).subscribe(r=> actualData = r);

    dialogRef.result.subscribe(result => {
      if(result instanceof DialogCloseResult) {
        this.tracerService.add2('Диалог закрыт кнопкой отмены');
        retSubj$.next(new EditPassportDataDialogService_Result('cancel',action));
      } else {

        this.tracerService.add2('Диалог закрыт кнопкой ОК', {obj: actualData});
        retSubj$.next(new EditPassportDataDialogService_Result('ok',action,actualData));
      }
      retSubj$.complete();
    })

    return {
      dialogRef: dialogRef,
      result$: retSubj$
    }
  }
}

/** Тип действия (добавление или редактирование) */
type ActionType = 'add' | 'edit';

/** Результат отображения диалога */
export interface IShowResult{
  /** Ссылка на диалог */
  dialogRef: DialogRef,
  /** Результат диалога */
  result$: Subject<EditPassportDataDialogService_Result>
}

/** Объект данных результата диалога (нажата кнопка сохранить или диалог закрыт или отмена)*/
export class EditPassportDataDialogService_Result {
  constructor(
    /** Результат диалога*/
    public type: 'ok'| 'cancel',
    /** Тип действия */
    public action: ActionType,
    /** Результат данных диалога */
    public result?: IValueResult
  ) {}
}


