import { Injectable, OnDestroy } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { IEditPassportDataComponent } from "../i-edit-passport-data.component";
import { EditPassportDataComponentForm, EditPassportDataComponentFormService } from "./edit-passport-data-component-form.service";

@Injectable()
/** Основной сервис компонента редактирования паспортных данных */
export class EditPassportDataComponentService extends ComponentServiceBase<IEditPassportDataComponent> implements OnDestroy  {

  private _formData: EditPassportDataComponentForm;

  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private formService: EditPassportDataComponentFormService) {
    super();
  }

  /** Переинициализация формы */
  private _reinitForm() {
    this._formData = this.formService.initForm(this.component.data);
    this._formData.valueChange$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value=> {
      this.component.onDataChanged$.next(value);
    });

    this.component.form = this._formData.form;
  }

  /** Событие происходит когда входные данные изменились и нужно переинициализировать форму*/
  onDataChanged() {
    this._reinitForm();
  }

  /** Событие происходит в момент изменения состояниея disabled */
  onDisabledChanged() {
    this._formData.controls.setDisabled(this.component.disabled);
  }

  init() {
    this._reinitForm();
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

}


