<div kendoDialogContainer></div>

<kendo-treelist style="height: 100%; width: 100%"
                kendoTreeListExpandable

                [kendoTreeListFlatBinding]="dataSourceService.dataSource.data$ | async"

                kendoTreeListSelectable
                [selectedItems]="(def.tempSelection.data$ | async)?.forDirective.selectedItems"
                [itemKey]="(def.tempSelection.data$ | async)?.forDirective.itemKey"
                (selectedItemsChange)="def.tempSelection.data?.forDirective.onSelectedKeysChange($event)"
                [selectable]="{
                  mode: 'single',
                  enabled: true
                }"

                parentIdField="parentId"
                idField="id"
                [initiallyExpanded]="true"

                [resizable]="false"
                filterable="menu"
                KendoTreeListExpanded
                (dbCellClick)="onDblCellClick($event)"
                tableTitleTextWrap="true"
                [sort]="[{ field: 'sortOrder', dir: 'asc' }]"
>
  <ng-template kendoTreeListToolbarTemplate>
    <div class="buttons-area">
      <button (click)="onClickAdd()"
              kendoButton
              [icon]="'plus'"
              primary="true"
              [size]="'small'"
      >
        Добавить
      </button>
      <button (click)="onClickReorderUp()"
              [disabled]="!currentSelectedItem.data || !nearestItem.data?.up"
              kendoButton
              [icon]="'sort-asc-sm'"
              primary="true"
              [size]="'small'"
      >
        Вверх
      </button>
      <button (click)="onClickReorderDown()"
              [disabled]="!currentSelectedItem.data || !nearestItem.data?.down"
              kendoButton
              [icon]="'sort-desc-sm'"
              primary="true"
              [size]="'small'"
      >
        Вниз
      </button>
      <button (click)="startEditParentId()"
              [disabled]="!currentSelectedItem"
              kendoButton
              [icon]="'dictionary-add'"
              primary="true"
              [size]="'small'"
      >
        Изменение иерархии
      </button>
    </div>
  </ng-template>

  <kendo-treelist-column [field]="fieldsNames.longName.toString()"
                         title="Наименование подразделения"
                         [expandable]="true"
                         [width]="500"
  >
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-string-filter-menu [column]="column"
                                         [filter]="filter"
                                         [filterService]="filterService"
                                         [extra]="false"
                                         operator="contains"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-contains-operator></kendo-treelist-filter-contains-operator>
        <kendo-treelist-filter-not-contains-operator></kendo-treelist-filter-not-contains-operator>
        <kendo-treelist-filter-startswith-operator></kendo-treelist-filter-startswith-operator>
        <kendo-treelist-filter-endswith-operator></kendo-treelist-filter-endswith-operator>
        <kendo-treelist-filter-isempty-operator></kendo-treelist-filter-isempty-operator>
        <kendo-treelist-filter-isnotempty-operator></kendo-treelist-filter-isnotempty-operator>
      </kendo-treelist-string-filter-menu>
    </ng-template>

    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span style="margin-left: 5px"></span> {{ dataItem.longName }}
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column [field]="fieldsNames.shortName.toString()"
                         title="Сокращенное наименование подразделения"
  >
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-string-filter-menu [column]="column"
                                         [filter]="filter"
                                         [filterService]="filterService"
                                         [extra]="false"
                                         operator="contains"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-contains-operator></kendo-treelist-filter-contains-operator>
        <kendo-treelist-filter-not-contains-operator></kendo-treelist-filter-not-contains-operator>
        <kendo-treelist-filter-startswith-operator></kendo-treelist-filter-startswith-operator>
        <kendo-treelist-filter-endswith-operator></kendo-treelist-filter-endswith-operator>
        <kendo-treelist-filter-isempty-operator></kendo-treelist-filter-isempty-operator>
        <kendo-treelist-filter-isnotempty-operator></kendo-treelist-filter-isnotempty-operator>
      </kendo-treelist-string-filter-menu>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column [field]="fieldsNames.code.toString()" title="Код подразделения" [width]="140">
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-string-filter-menu [column]="column"
                                         [filter]="filter"
                                         [filterService]="filterService"
                                         [extra]="false"
                                         operator="contains"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-contains-operator></kendo-treelist-filter-contains-operator>
        <kendo-treelist-filter-not-contains-operator></kendo-treelist-filter-not-contains-operator>
        <kendo-treelist-filter-startswith-operator></kendo-treelist-filter-startswith-operator>
        <kendo-treelist-filter-endswith-operator></kendo-treelist-filter-endswith-operator>
        <kendo-treelist-filter-isempty-operator></kendo-treelist-filter-isempty-operator>
        <kendo-treelist-filter-isnotempty-operator></kendo-treelist-filter-isnotempty-operator>
      </kendo-treelist-string-filter-menu>
    </ng-template>
  </kendo-treelist-column>

  <kendo-treelist-column [field]="fieldsNames.startDate.toString()" title="Дата начала" [width]="120">
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-date-filter-menu [column]="column"
                                       [filter]="filter"
                                       [filterService]="filterService"
                                       [extra]="false"
                                       operator="eq"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-after-eq-operator></kendo-treelist-filter-after-eq-operator>
        <kendo-treelist-filter-after-operator></kendo-treelist-filter-after-operator>
        <kendo-treelist-filter-before-eq-operator></kendo-treelist-filter-before-eq-operator>
        <kendo-treelist-filter-before-operator></kendo-treelist-filter-before-operator>
      </kendo-treelist-date-filter-menu>
    </ng-template>

    <ng-template kendoTreeListCellTemplate let-dataItem>
      {{ dataItem.startDate | kendoDate: "dd.MM.yyyy" }}
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column [field]="fieldsNames.endDate.toString()" title="Дата окончания" [width]="120">
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-date-filter-menu [column]="column"
                                       [filter]="filter"
                                       [filterService]="filterService"
                                       [extra]="false"
                                       operator="eq"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-after-eq-operator></kendo-treelist-filter-after-eq-operator>
        <kendo-treelist-filter-after-operator></kendo-treelist-filter-after-operator>
        <kendo-treelist-filter-before-eq-operator></kendo-treelist-filter-before-eq-operator>
        <kendo-treelist-filter-before-operator></kendo-treelist-filter-before-operator>
      </kendo-treelist-date-filter-menu>
    </ng-template>

    <ng-template kendoTreeListCellTemplate let-dataItem>
      {{ dataItem.endDate | kendoDate: "dd.MM.yyyy" }}
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column [field]="fieldsNames.responsibleUsersString.toString()"
                         title="ФИО ответственные за ведение графика/табеля"
  >
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-string-filter-menu [column]="column"
                                         [filter]="filter"
                                         [filterService]="filterService"
                                         [extra]="false"
                                         operator="contains"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-contains-operator></kendo-treelist-filter-contains-operator>
        <kendo-treelist-filter-not-contains-operator></kendo-treelist-filter-not-contains-operator>
        <kendo-treelist-filter-startswith-operator></kendo-treelist-filter-startswith-operator>
        <kendo-treelist-filter-endswith-operator></kendo-treelist-filter-endswith-operator>
        <kendo-treelist-filter-isempty-operator></kendo-treelist-filter-isempty-operator>
        <kendo-treelist-filter-isnotempty-operator></kendo-treelist-filter-isnotempty-operator>
      </kendo-treelist-string-filter-menu>
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column [field]="fieldsNames.comment.toString()" title="Комментарий">
    <ng-template kendoTreeListFilterMenuTemplate
                 let-column="column"
                 let-filter="filter"
                 let-filterService="filterService"
    >
      <kendo-treelist-string-filter-menu [column]="column"
                                         [filter]="filter"
                                         [filterService]="filterService"
                                         [extra]="false"
                                         operator="contains"
      >
        <kendo-treelist-filter-eq-operator></kendo-treelist-filter-eq-operator>
        <kendo-treelist-filter-neq-operator></kendo-treelist-filter-neq-operator>
        <kendo-treelist-filter-contains-operator></kendo-treelist-filter-contains-operator>
        <kendo-treelist-filter-not-contains-operator></kendo-treelist-filter-not-contains-operator>
        <kendo-treelist-filter-startswith-operator></kendo-treelist-filter-startswith-operator>
        <kendo-treelist-filter-endswith-operator></kendo-treelist-filter-endswith-operator>
        <kendo-treelist-filter-isempty-operator></kendo-treelist-filter-isempty-operator>
        <kendo-treelist-filter-isnotempty-operator></kendo-treelist-filter-isnotempty-operator>
      </kendo-treelist-string-filter-menu>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>

<!-- Шаблон для поля с выпадающим списком -->
<ng-template #customParentIdFormFieldValueControlTemplateRef
             let-formControl="formControl"
>
  <kendo-dropdowntree kendoDropDownTreeExpandable
                      [kendoDropDownTreeFlatBinding]="dataSourceService.dataSource.data$ | async"
                      parentIdField="parentId"
                      [valueField]="'id'"
                      [textField]="'longName'"
                      [formControl]="formControl"
                      [valuePrimitive]="true"
                      [dataItem]="getDataItemForParentIdFormField(formControl.value)"
                      [popupSettings]="{ width: 'auto' }"
  >
    <ng-template kendoDropDownTreeNodeTemplate let-dataItem let-index="index">
      <div [style.opacity]="itemDisabled(dataItem) ? 0.4 : 1"
           (click)="blockIfItemDisabled($event, dataItem)"
      >
        {{ dataItem.longName }}
      </div>
    </ng-template>
  </kendo-dropdowntree>
</ng-template>
