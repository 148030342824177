import {Observable} from "rxjs";
import {map} from "rxjs/operators";

/** Оператор из массива получаемого из Observable берет последние n элементов */
export function exLastLimitArray<O>(length: number): (source$: Observable<O[]>) => Observable<O[]>{
  return source$ => {
    return source$.pipe(map(arr => {
      return arr.length > length ? arr.slice(arr.length - length) : arr;
    }));
  }
}
