<ng-container *ngIf="settingsTemplate">
  <h2>Настройки импорта</h2>
  <ng-container *ngTemplateOutlet="settingsTemplate; context: { service: importFromExcelComponentImportSettingsService} "></ng-container>
</ng-container>

<h2>Шаг 1</h2>
<h4>Скачайте шаблон</h4>
<button kendoButton [icon]="'file-excel'" (click)="onClickDownloadTemplate();" [primary]="true" [disabled]="!canImport">Скачать шаблон</button>


<h2>Шаг 2</h2>
<h4>Заполните и загрузите заполненный шаблон</h4>
<kendo-upload
[disabled]="!canImport"
[saveUrl]="uploadSaveUrl"
[multiple]="false"
[autoUpload]="false"
[restrictions]="uploadRestrictions"
[accept]="'.xlsx'"
[withCredentials]="false"
(upload)="onUpload($event)"
(success)="successEventHandler($event)"
(error)="errorEventHandler($event)"
></kendo-upload>
