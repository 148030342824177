import {GraphTableBase, IGraphTableBase} from "../timesheet/GraphTableBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";
import {IGraph} from "../timesheet_graph/Graph";


export interface ITable extends IGraphTableBase{

}

@classBackend('Table', 'timesheet_table')
@className('Table')
/** Табель */
export class Table extends GraphTableBase implements ITable{

  private static _fullComparer: ObjComparer<IGraph>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = GraphTableBase.fullComparerBase;
    }

    return this._fullComparer;
  }
}
