import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { DateFieldControl_Settings } from "../../services/classes/field-controls/date-field-control";
import { DateOnlyDirectoryEditComponentService } from './services/date-only-directory-edit-control-component.service';
import { IDateOnlyDirectoryEditComponent } from './i-date-only-directory-edit-control.component';

@Component({
  selector: 'app-date-only-directory-edit-control',
  templateUrl: './date-only-directory-edit-control.component.html',
  styleUrls: ['./date-only-directory-edit-control.component.css'],
  providers: [DateOnlyDirectoryEditComponentService]
})
@traceClass('DateOnlyDirectoryEditComponent')
export class DateOnlyDirectoryEditComponent implements OnInit, IDateOnlyDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: DateFieldControl_Settings;

  constructor(private service: DateOnlyDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
  }
}
