import {Observable} from "rxjs";
import {filter} from "rxjs/operators";

/**
 * Данный оператор аналог rxjs distinctUntilChanged
 * Разница: передается инициализирующее значение, и если первый транслируемый элемент равняется инициализирующему, то его отфильтровывает
 * Последующая работа: если транслируемый элемент равняется предыдущему, то его отфильтровывает
 * @param initValue Инициализирующее значение
 * @param compareValueFn Функция сравнения предыдущего значения с текущим
 */
export function exDistinctUntilChanged<O, OInit extends O>(
  initValue: OInit,
  compareValueFn: (prev: O, current: O) => boolean = (prev, current) => prev === current
) : (source$: Observable<O>) => Observable<O> {
  let prevValue: O = initValue;

  return source$ => source$.pipe(
    filter(x => {
      if(compareValueFn(prevValue, x)){
        return false;
      }

      prevValue = x;
      return true;
    })
  )
}
