import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WebApi1Service } from '../../web-api1.service';
import { ILimitStaffUnit, LimitStaffUnit } from 'src/app/classes/domain/POCOs/stafflist/LimitStaffUnit';
import { ILimitStaffUnitRate } from 'src/app/classes/domain/POCOs/stafflist/LimitStaffUnitRate';
import { IOccupationType } from 'src/app/classes/domain/POCOs/stafflist/OccupationType';
import { ISubdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { IFinancingSource } from 'src/app/classes/domain/POCOs/stafflist/FinancingSource';
import { IForDate } from '../../../../../classes/for-date';

/**
 * Сервис работы с контроллером LimitStaffUnitsControl
 */
@Injectable({
  providedIn: 'root',
})
export class Api1LimitStaffUnitsControlControllerService {

  constructor(private webApi1Service: WebApi1Service, private httpClient: HttpClient) {
  }

  /** Получить все */
  public getForDate$({ forDate: date, startDate, endDate }: IForDate, ownerIds: number[] = null): Observable<LimitStaffUnitsResponseDTO> {
    return this.httpClient
      .post<LimitStaffUnitsResponseDTO>(this.webApi1Service.controllers.limitStaffUnitsControl.actions.getForDate.toString(), {
        date,
        startDate,
        endDate,
        ownerIds,
      });
  }

  /** Добавить */
  public add$(entity: AddLimitStaffUnitDTO): Observable<LimitStaffUnit> {
    return this.httpClient
      .post<LimitStaffUnit>(this.webApi1Service.controllers.limitStaffUnitsControl.actions.add.toString(), entity);
  }

  /** Удалить */
  public delete$(id: number, timestamp: []): Observable<boolean> {
    return this.httpClient
      .post<boolean>(this.webApi1Service.controllers.limitStaffUnitsControl.actions.delete.toString(), { id, timestamp });
  }

}

export interface LimitStaffUnitsResponseDTO {
  limitStaffUnits: Pick<ILimitStaffUnit, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'comment' | 'timestamp' | 'occupationTypeId' | 'subdivisionId'>[];
  limitStaffUnitRates: Pick<ILimitStaffUnitRate, 'id' | 'ownerId' | 'startDate' | 'endDate' | 'comment' | 'timestamp' | 'limitStaffUnitId' | 'financingSourceId' | 'value'>[];
  occupationTypes: Pick<IOccupationType, 'id' | 'parentId' | 'name'>[];
  subdivisions: Pick<ISubdivision, 'id' | 'longName'>[];
  financingSources: Pick<IFinancingSource, 'id' | 'shortName'>[];
}

export class AddLimitStaffUnitDTO {
  constructor(public startDate: Date,
              public endDate: Date,
              public comment: string,
              public occupationTypeId: number,
              public subdivisionId: number,
              public rates: AddLimitStaffUnitRateDTO[]) {
  }
}

export class AddLimitStaffUnitRateDTO {
  constructor(public financingSourceId: number,
              public value: number) {
  }
}
