<div kendoDialogContainer></div>

<kendo-grid
  [data]="data$ | async"
  kendoGridSelectBy="id"
  [groupable]="false"
  [reorderable]="false"
  [resizable]="false"
  [style.maxHeight.%]="100"
  (selectionChange)="onSelectionChanged($event)"
  [selectedKeys]="selectedItems"
  [selectable]="{
    mode: 'single',
    enabled: true
  }"
  [sortable]="false"
  [sort]="false"
  [filter]="false"
  [pageable]="false"
  scrollable="true"

  KendoGridExpanded
  (dbCellClick)="onDblCellClick($event)"
  tableTitleTextWrap="true"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="grid-header-content">
      <button kendoButton icon="plus" (click)="onClickAdd()" primary="true" [size]="'small'">Добавить</button>
    </div>
  </ng-template>
  <kendo-grid-column field="name" title="Наименование режима работы" [width]="580"></kendo-grid-column>
  <kendo-grid-column field="workModeTypeId" title="Тип режима рабочего времени" [width]="220">
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.workModeTypeName}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="startDate" title="Дата начала" [width]="120">
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.startDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="endDate" title="Дата окончания" [width]="140">
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.endDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comment" title="Комментарий"></kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <section>
      <p><strong>Начало периода совпадает с началом недели:</strong> {{dataItem.arrangedForWeekFlag ? 'Да' : 'Нет'}} <button kendoButton icon="pencil" (click)="startEditButtonClick('arrangedForWeekFlag', dataItem, $event);"></button></p>
      <p><strong>Рабочих дней:</strong> {{dataItem.workingDays}} <button kendoButton icon="pencil" (click)="startEditButtonClick('workingDays', dataItem, $event);"></button></p>
      <p><strong>Продолжительность рабочего дня (ч.):</strong> {{dataItem.workdayHourDuration}} <button kendoButton icon="pencil" (click)="startEditButtonClick('workdayHourDuration', dataItem, $event);"></button></p>
      <p><strong>Продолжительность предпраздничного дня (ч.):</strong> {{dataItem.preHolidayHourDuration}} <button kendoButton icon="pencil" (click)="startEditButtonClick('preHolidayHourDuration', dataItem, $event);"></button></p>
    </section>
  </ng-template>

</kendo-grid>
