import { Component, Input, OnInit } from '@angular/core';
import { LimitStaffUnitsTreeListDataSourceServiceBase } from './services/limit-staff-units-tree-list-data-source.service';
import { LimitStaffUnitsTreeListComponentService } from './services/limit-staff-units-tree-list-component.service';
import { exCreateService$ } from '../../../../../../../src/app/operators/ex-create-service.operator';
import { FinancingSourceDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/financing-source-data-source.service';
import { BehaviorSubject, combineLatestWith, map, Observable, shareReplay, take } from 'rxjs';
import { DbChangedListener } from '../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { SubdivisionDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/subdivision-data-source.service';
import { OccupationTypeDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/occupation-type-data-source.service';
import { ILimitStaffUnitRate } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/LimitStaffUnitRate';
import { xnameofPath } from '../../../../../../../src/app/functions/nameof';
import { FieldType } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/field-type';
import { NotVersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/not-versioning-field';
import { TextAreaFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/text-area-field-control';
import { DateFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/date-field-control';
import { DirectoryEditRef } from '../../../../../../../src/app/components/directory-edit/services/ref-services/directory-edit-ref';
import { DirectoryEditService } from '../../../../../../../src/app/components/directory-edit/services/directory-edit.service';
import { traceFunc } from '../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { TraceParamEnum } from '../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface';
import { CellClickEvent } from '@progress/kendo-angular-treelist';
import { versioningAndNotVersioningDirectoryServiceProvider } from '../../../../../../../src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceClass } from '../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { KendoTreeListDataSourceSelection } from '../../../../../../../src/app/classes/array-data-sources/selections/kendo-treelist-array-data-source-selection';
import { AddLimitStaffUnitDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitsControl-controller.service';
import { VersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/versioning-field';
import { NumberFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/number-field-control';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

@Component({
  selector: 'app-limit-staff-units-tree-list',
  templateUrl: './limit-staff-units-tree-list.component.html',
  styleUrls: ['./limit-staff-units-tree-list.component.css'],
  providers: [
    versioningAndNotVersioningDirectoryServiceProvider,
    LimitStaffUnitsTreeListComponentService,
  ],
})
@traceClass('LimitStaffUnitsTreeListComponent')
export class LimitStaffUnitsTreeListComponent implements OnInit {

  public occupationTypeDataSourceService$: Observable<OccupationTypeDataSourceService>;
  public financingSourceDataSourceService$: Observable<FinancingSourceDataSourceService>;
  public subdivisionDataSourceService$: Observable<SubdivisionDataSourceService>;
  public isHideEmptyColumns$ = new BehaviorSubject(true);
  public rateColumns$: Observable<LimitStaffUnitTreeListRateColumn[]>;
  public rateColumnsInfo$: BehaviorSubject<LimitStaffUnitTreeListRateColumnInfo[]> = new BehaviorSubject([]);
  public rateStep: number = 0.25;

  /** Ключи названий всех колонок */
  public fieldsNames = xnameofPath(LimitStaffUnitTreeListItem, '');

  public canDelete = false;

  private directoryEditServiceRef: DirectoryEditRef;

  /** Настройки колонок для сервиса редактирования */
  private readonly fields: FieldType[] = [
    new NotVersioningField(this.fieldsNames.startDate.toString(), { isAllowNull: false, title: 'Дата начала' }, new DateFieldControl()),
    new NotVersioningField(this.fieldsNames.endDate.toString(), { isAllowNull: true, title: 'Дата окончания' }, new DateFieldControl()),
    new NotVersioningField(this.fieldsNames.comment.toString(), { isAllowNull: true, title: 'Комментарий' }, new TextAreaFieldControl({ maxLength: 100, autoTrimValue: true })),

    new VersioningField('value', SupportedActionEnum.stafflist_limitStaffUnitRates_value, {
        isAllowNull: false, title: 'Количество ставок', isAllowEditRootStartEndData: true,
        dialogTitle: () => `Редактирование "Источник финансирования ${this.directoryEditServiceRef.currentDataItem.title}"`,
      }, new NumberFieldControl(() => ({ minValue: 0, maxValue: 10000.0, multipleOf: this.rateStep, decimals: 2, format: 'n2', step: this.rateStep })),
    ),
  ];

  private _dataSourceService: LimitStaffUnitsTreeListDataSourceServiceBase;

  @Input()
  public get dataSourceService(): LimitStaffUnitsTreeListDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: LimitStaffUnitsTreeListDataSourceServiceBase) {
    if (this._dataSourceService) {
      throw Error('Повторная установка dataSource не поддерживается!');
    }
    this._dataSourceService = value;
  }

  private _selection: KendoTreeListDataSourceSelection<LimitStaffUnitTreeListItem, number>;

  @Input()
  public get selection(): KendoTreeListDataSourceSelection<LimitStaffUnitTreeListItem, number> {
    return this._selection;
  }

  public set selection(val: KendoTreeListDataSourceSelection<LimitStaffUnitTreeListItem, number>) {
    if (this._selection) {
      throw Error('Повторная установка selection не поддерживается!');
    }
    this._selection = val;
  }

  constructor(private readonly service: LimitStaffUnitsTreeListComponentService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly directoryEditService: DirectoryEditService,
              private readonly tracerService: TracerServiceBase) {
  }

  ngOnInit(): void {
    this.initDirectoryEditService();
    this.initUpdateCanDelete();

    this.occupationTypeDataSourceService$ = exCreateService$(() => new OccupationTypeDataSourceService(this.dataSourceService, this.dbChangedListener));
    this.financingSourceDataSourceService$ = exCreateService$(() => new FinancingSourceDataSourceService(this.dataSourceService, this.dbChangedListener));
    this.subdivisionDataSourceService$ = exCreateService$(() => new SubdivisionDataSourceService(this.dataSourceService, this.dbChangedListener));

    // Общий observable со всеми колонками источников финансирования
    const allFinancingSourcesColumnsInfos$ = this.dataSourceService.dataSource.data$.pipe(
      map(data => this.service.getFinancingSourcesColumnsInfos(data)),
      shareReplay(),
    );

    // observable с колонками ставок глобальных и по подразделениям
    this.rateColumns$ = allFinancingSourcesColumnsInfos$.pipe(
      combineLatestWith(this.isHideEmptyColumns$),
      map(([arr, state]) => state === true ? arr.filter(f => f.hasRates === true) : arr),
      shareReplay(1),
    );

    this.dataSourceService.dataSource.data$
      .subscribe(items => this.rateColumnsInfo$.next(this.service.getErrorColumnsInfos(items)));
  }

  public onClickAddOccupationType(): void {
    this.service.showAddLimitStaffUnitDialog(this, 'occupationType', null);
  }

  public onClickAddSubdivision(dataItem: LimitStaffUnitTreeListItem): void {
    this.service.showAddLimitStaffUnitDialog(this, 'subdivision', new AddLimitStaffUnitDTO(dataItem?.startDate ?? null, dataItem?.endDate ?? null, null, dataItem.occupationTypeId, null, null));
  }

  public onClickDelete(): void {
    const { id, timestamp } = this.selection.selectedItems.data[0];
    this.service.showDeleteDialog(() => this.dataSourceService.delete$(id, timestamp));
  }

  public toggleHideEmptyColumns() {
    this.isHideEmptyColumns$.next(!this.isHideEmptyColumns$.getValue());
  }

  public getRatesDisplayValue(dataItem, financingSourceId: number): string | number {
    return this.service.getRatesDisplayValue(dataItem.rates, financingSourceId);
  }

  public getRateColumnInfo(limitStaffUnitId: number, financingSourceId: number): LimitStaffUnitTreeListRateColumnInfo {
    return this.service.getRateColumnInfo(this.rateColumnsInfo$.getValue(), limitStaffUnitId, financingSourceId);
  }

  /** Событие происходит при двойном клике на ячейку */
  @traceFunc({ traceParamType: TraceParamEnum.notTrace })
  public onDbCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    const field = e.originalEvent.column.field;
    const target = e.originalEvent.originalEvent.target;
    const dataItem = e.originalEvent.dataItem;

    switch (field) {
      case this.fieldsNames.text.toString():
        break;

      case this.fieldsNames.startDate.toString():
      case this.fieldsNames.endDate.toString():
      case this.fieldsNames.comment.toString():
        this.directoryEditServiceRef.edit(field, dataItem, target);
        break;

      case this.fieldsNames.rates.toString():
        this.rateColumns$.pipe(take(1)).subscribe(columns => {
          const { financingSourceId } = columns[e.originalEvent.columnIndex - 1];
          const rate = dataItem.rates.find(v => v.financingSourceId === financingSourceId);
          if (rate) {
            if (rate?.id) {
              this.directoryEditServiceRef.edit('value', rate, target);
            } else {
              this.service.showAddLimitStaffUnitRateDialog(this, financingSourceId, dataItem);
            }
          }
        });
        break;
    }
  }

  private initUpdateCanDelete() {
    this.selection.selectedItems.data$.pipe(map((v) => {
      if (v.length > 0) {
        const selected = v[0];
        if (selected.type === 'subdivision') {
          return true;
        } else {
          const childs = this.dataSourceService.dataSource.data.filter(v => v.occupationTypeParentId == selected.occupationTypeId);
          return childs.length == 0;
        }
      } else {
        return false;
      }
    })).subscribe((v) => this.canDelete = v);
  }

  private initDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.init({
      addVersion$: (actionId, versioningEntity) => this.dataSourceService.addVersion$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataSourceService.deleteVersion$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSourceService.getVersions$(ownerId, actionId),
      editVersion$: (actionId, entity) => this.dataSourceService.editVersion$(actionId, entity),
      saveNoVersioning$: (entity) => this.dataSourceService.save$(entity),
    }, this.fields);
    this.directoryEditServiceRef.closeOnCurrentEditingDataItemChanged$(this.dataSourceService.dataSource);
  }

}

export class LimitStaffUnitTreeListItem {
  constructor(public id: number,
              public ownerId: number,
              public startDate: Date,
              public endDate: Date,
              public comment: string,
              public timestamp: [],
              public type: LimitStaffUnitTreeListItemType,
              public occupationTypeId: number,
              public occupationTypeParentId: number,
              public subdivisionId: number,
              public text: string,
              public rates: LimitStaffUnitTreeListItemRate[]) {
  }
}

export type LimitStaffUnitTreeListItemType = 'occupationType' | 'subdivision';

export interface LimitStaffUnitTreeListItemRate extends Pick<ILimitStaffUnitRate, 'id' | 'ownerId' | 'timestamp' | 'value' | 'financingSourceId'> {
  title: string;
}

export class LimitStaffUnitTreeListRateColumn {
  constructor(public financingSourceId: number,
              public title: string,
              public hasRates: boolean) {
  }
}

export class LimitStaffUnitTreeListRateColumnInfo {
  constructor(public limitStaffUnitId: number,
              public financingSourceId: number,
              public hasError: boolean,
              public unallocated: number) {
  }
}
