import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DialogContentBase, DialogRef} from "@progress/kendo-angular-dialog";
import {HierarchiStringSmall} from "../../classes/requestResults/EditEntityWithDependenciesErrorData";
import {ReplaySubject, takeUntil} from "rxjs";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../modules/trace/decorators/func.decorator";
import {traceClass} from "../../modules/trace/decorators/class.decorator";
import {trace} from "../../modules/trace/operators/trace";
import {exErrorHandler} from "../../operators/ex-error-handler";
import * as FileSaver from "file-saver";
import * as moment from "moment/moment";
import {
  Api1PrintReportControllerService
} from "../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {DisplayErrorsService} from "../display-errors/services/display-errors.service";

/** Тип данных используемых в html */
type HierarchiStringExt = HierarchiStringSmall & { isRoot?: boolean };

@Component({
  selector: 'app-hierarchi-strings',
  templateUrl: './hierarchi-strings.component.html',
  styleUrls: ['./hierarchi-strings.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@traceClass('HierarchiStringsComponent')
export class HierarchiStringsComponent  extends DialogContentBase {
  /** Стримы */
  private streams$ = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  /** Данные */
  @Input() public set hierarchiStrings(value: HierarchiStringSmall[]) {
    this.source = value;
    this.source.forEach(x => x.isRoot = true);
  };

  /** Название файла */
  @Input() public exportExcelFileName: string = '';

  /** Параметры необходимые для построения отчета */
  @Input() public params: { subdivisionId: number, month: Date };

  /** Данные используемые в html */
  protected source: HierarchiStringExt[];

  /** Конструктор */
  constructor(
    dialogRef: DialogRef,
    private readonly tracerService: TracerServiceBase,
    private readonly printService: Api1PrintReportControllerService,
    private readonly displayErrorService: DisplayErrorsService) {
    super(dialogRef);
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

  /** Обработка события нажатия на кнопку "Експорт в Excel" */
  @traceFunc()
  public onExcelExport() {
    (this.params
      ? this.printService.printCheckReport$(this.params)
      : this.printService.printCheckReport2$(this.source))
      .pipe(trace(this.tracerService),
        exErrorHandler(this.displayErrorService),
        takeUntil(this.streams$.unsubscribes))
      .subscribe({
        next: value => {
          FileSaver.saveAs(value, `${this.exportExcelFileName}_${moment().format('DD_MM_yyyy')}.xlsx`);
        }});
  }
}
