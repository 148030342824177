import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { InitRangeType, ReportDateValues } from './classes/report-date-values';
import { CustomStorageService, StorageLocationEnum, StorageOptions } from '../../services/storages/custom-storage.service';
import { ReportDatesComponentSharedService } from './services/report-dates-component-shared.service';
import { defer, map, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { DateHelper } from '../../helpers/dateHelper';

@Component({
  selector: 'app-report-dates',
  templateUrl: './report-dates.component.html',
  styleUrls: ['./report-dates.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@traceClass('ReportDatesComponent')
export class ReportDatesComponent implements OnInit, OnDestroy {
  /** Размер внутренних компонентов */
  @Input() size: 'small' | 'medium' | 'large' | undefined = undefined;

  /** Параметр задает даты начала и конца диапазона */
  @Input() initRange: InitRangeType = 'month';

  /** Событие происходит в момент изменения дат отчёта */
  @Output('onChange') onChangeEventEmitter = new EventEmitter<ReportDateValues>();

  /** Событие происходит в момент инициализации дат отчёта */
  @Output('onInitialize') onInitializeEventEmitter = new EventEmitter<ReportDateValues>();

  public reportForm: FormGroup<IReportDatesComponentForm>;
  public readonly streams$ = {
    unsubscribes: new ReplaySubject<any>(1),
    invalid: defer(() => this.reportForm.statusChanges.pipe(map(() => this.reportForm.invalid))),
    dirty: defer(() => this.reportForm.statusChanges.pipe(map(() => this.reportForm.dirty))),
    minDate: new Subject<Date>(),
    maxDate: new Subject<Date>(),
  };
  /** Опции localStorage */
  private readonly storageOptionsReportDate: StorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'ReportDatesComponentService/reportDates',
    86400, //1 сутки
    true,
    false,
  );

  constructor(private tracerService: TracerServiceBase,
              private readonly customStorageService: CustomStorageService,
              /** Если данный сервис был зарегистрирован то будет бросать событие при изменении значений в компоненте */
              @Optional() private readonly reportDatesComponentSharedService?: ReportDatesComponentSharedService,
  ) {
  }

  @traceFunc()
  ngOnInit() {
    const reportDateValues = this.getReportDateValues();
    this.reportForm = this.initForm(reportDateValues);
    this.reportDatesComponentSharedService?.onReportDatesChanged(reportDateValues);
    this.onInitializeEventEmitter.next(reportDateValues);
  }

  /** Применить значения из формы отчёта и отправить в событие onChanged */
  @traceFunc()
  public emitChangesFromFormValue() {
    const startDate = DateHelper.getStartOfDay(this.reportForm.value.startDate);
    const endDate = DateHelper.getEndOfDay(this.reportForm.value.endDate);

    const reportDateValues = new ReportDateValues(this.reportForm.value.date, startDate, endDate);
    this.tracerService.add2('Изменено значение reportDateValues', { obj: reportDateValues });
    this.customStorageService.set(this.storageOptionsReportDate, reportDateValues);
    this.reportDatesComponentSharedService?.onReportDatesChanged(reportDateValues);
    this.onChangeEventEmitter.next(reportDateValues);
    this.reportForm.reset(this.reportForm.value, {onlySelf: true});
  }


  /** Отмена всех изменений (нажатие кнопки очистки фильтра) */
  @traceFunc()
  cancelAllFormChanges() {
    const value = this.getReportDateValues();
    this.reportForm.reset({
      startDate: value.reportRangeStartDate,
      endDate: value.reportRangeEndDate,
      date: value.reportDate,
    }, {
      onlySelf: true
    })
  }

  /** Получить дату отчёта
   * (если были сохранены в SessionStorage то вернёт из них, если нет то вернёт текущую дату) */
  @traceFunc()
  public getReportDateValues(): ReportDateValues {
    const value = this.customStorageService.get<ReportDateValues>(this.storageOptionsReportDate);

    if (!value) return ReportDateValues.createForNow(this.initRange);

    return new ReportDateValues(
      new Date(value.reportDate),
      new Date(value.reportRangeStartDate),
      new Date(value.reportRangeEndDate),
    );
  }

  /** Инициализация формы на основе дат отчёта */
  @traceFunc()
  public initForm(reportDateValues: ReportDateValues) {
    const form = new FormGroup({
      startDate: new FormControl(reportDateValues.reportRangeStartDate, [Validators.required]),
      endDate: new FormControl(reportDateValues.reportRangeEndDate, [Validators.required]),
      date: new FormControl(reportDateValues.reportDate, [Validators.required]),
    });

    /** Подписываемся на изменения даты начала */
    form.controls.startDate.valueChanges.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(value => {
      if (!value) return; //validate inconplete date

      this.checkReportDateForReportRange();
      this.updateMinMaxDates(value, form.controls.endDate.value);
    });

    form.controls.endDate.valueChanges.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(value => {
      if (!value) return; //validate inconplete date

      this.checkReportDateForReportRange();
      this.updateMinMaxDates(form.controls.startDate.value, value);
    });

    form.valueChanges.subscribe(v => {
      this.tracerService.add2('Изменение формы', { obj: v });
    });

    this.updateMinMaxDates(form.value.startDate, form.value.endDate);

    return form;
  }

  /** Обновляет допустимый диапазон минимальной и максимальной даты */
  public updateMinMaxDates(startDate: Date, endDate: Date) {
    this.streams$.minDate.next(this.getMinDate(startDate, endDate));
    this.streams$.maxDate.next(this.getMaxDate(startDate, endDate));
  }

  /** Получить минимальную допустимую дату */
  public getMinDate(startDate: Date, endDate: Date): Date {
    if (startDate <= endDate) {
      return startDate;
    }
    return null;
  }

  /** Получить максимальную допустимую дату */
  public getMaxDate(startDate: Date, endDate: Date): Date {
    if (endDate >= startDate) {
      return endDate;
    }
    return null;
  }

  /**
   * Обязательная проверка на то что дата отчета попадает в диапазон дат, а если не попадает то автоматически исправляет это
   * Нужно для того, чтобы при изменении диапазона дат, дата выборки подставлялась автоматически
   */
  @traceFunc()
  public checkReportDateForReportRange() {
    // если дата отчета не входит в диапазон дат (дата начала больше чем дата отчёта)
    // нужно установить дату отчета равную дате начала выгрузки
    if (this.reportForm.controls.startDate.value >= this.reportForm.controls.date.value) {
      this.reportForm.controls.date.setValue(this.reportForm.controls.startDate.value);
    }
    //Если дата конца периода меньше чем дата отчёта
    //Необходимо установить дату отчёта равную дате конца выгрузки
    if (this.reportForm.controls.endDate.value <= this.reportForm.controls.date.value) {
      this.reportForm.controls.date.setValue(this.reportForm.controls.endDate.value);
    }
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
    this.streams$.minDate.complete();
    this.streams$.maxDate.complete();
  }
}

export interface IReportDatesComponentForm {
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
  date: FormControl<Date>;
}

