import { Injectable, OnDestroy, Optional } from "@angular/core";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { TraceParamEnum } from "src/app/modules/trace/decorators/classes/traceSetting.interface";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { traceParam } from "src/app/modules/trace/decorators/param.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { AlertService } from "src/app/services/alert.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { DisplayErrorsService } from "../../display-errors/services/display-errors.service";
import { FieldType } from "./classes/fields/field-type";
import { NotVersioningField } from "./classes/fields/not-versioning-field";
import { VersioningField } from "./classes/fields/versioning-field";
import { DirectoryEditSettings_VersioningAndNotVersioning } from "./classes/service-settings/directory-edit-settings-all";
import { DirectoryEditSettings_NotVersioning } from "./classes/service-settings/directory-edit-settings-not-versioning";
import { DirectoryEditSettings_Versioning } from "./classes/service-settings/directory-edit-settings-versioning";
import { DirectoryEditNotVersioningService } from "./directory-edit-not-versioning.service";
import { DirectoryEditVersioningService } from "./directory-edit-versioning.service";
import { DirectoryEditRef } from "./ref-services/directory-edit-ref";
import { DirectoryEditRef_NotVersioning } from "./ref-services/directory-edit-ref-not-versioning";
import { DirectoryEditRef_Versioning } from "./ref-services/directory-edit-ref-versioning";

@Injectable()
@traceClass('DirectoryEditService')
export class DirectoryEditService implements OnDestroy{

  private allRefs = new Array<OnDestroy>();

  constructor(
    private readonly kendoNotificationService: KendoNotificationService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly alertService: AlertService,
    @Optional() private readonly versioningService: DirectoryEditVersioningService,
    @Optional() private readonly notVersioningService: DirectoryEditNotVersioningService,
    private readonly displayErrorsService: DisplayErrorsService,
    private readonly tracerService: TracerServiceBase) {
  }

  /** Инициализирует сервис редактирования как версионных так и неверсионных полей.*/
  @traceFunc({traceParamType: TraceParamEnum.traceByDecoratorsOrTraceAll})
  public init(settings: DirectoryEditSettings_VersioningAndNotVersioning, @traceParam({maxLength: 500}) fields: FieldType[]): DirectoryEditRef {
    const versioningFields = fields.filter(field => field instanceof VersioningField) as VersioningField[];
    const notVersioningFields = fields.filter(field => field instanceof NotVersioningField) as NotVersioningField[];

    const versioningService = this.createOnlyVersioningRef(settings, versioningFields);
    const notVersioningService = this.createOnlyNotVersioningRef(settings, notVersioningFields);

    return new DirectoryEditRef(fields, versioningService, notVersioningService, this.tracerService.copy());
  }

  /** Инициализирует сервис редактирования ТОЛЬКО версионных полей.
   * Если в массиве fields будет хоть одно НЕ версионное поле то вернёт ошибку.
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecoratorsOrTraceAll})
  public initVersioningOnly(settings: DirectoryEditSettings_Versioning, @traceParam({maxLength: 500}) fields: VersioningField[]): DirectoryEditRef {

    if(fields.some(field => field instanceof NotVersioningField)) {
      throw new Error("Массив полей для редактирования 'fields' содержит НЕ версионные поля, используй другой метод инициализации!");
    }
    const versioningService = this.createOnlyVersioningRef(settings, fields);
    return new DirectoryEditRef(fields, versioningService, null, this.tracerService.copy());
  }

  /** Инициализирует сервис редактирования ТОЛЬКО НЕ версионных полей.
   * Если в массиве fields будет хоть одно версионное поле то вернёт ошибку.
   */
  @traceFunc({traceParamType: TraceParamEnum.traceByDecoratorsOrTraceAll})
  public initNotVersioningOnly(settings: DirectoryEditSettings_NotVersioning, @traceParam({maxLength: 500}) fields: NotVersioningField[]): DirectoryEditRef {

    if(fields.some(field => field instanceof VersioningField)) {
      throw new Error("Массив полей для редактирования 'fields' содержит версионные поля, используй другой метод инициализации!");
    }
    const notVersioningService = this.createOnlyNotVersioningRef(settings, fields);
    return new DirectoryEditRef(fields, null, notVersioningService, this.tracerService.copy());
  }

  /** Создает экземпляр сервиса редактирования НЕ версионных полей.*/
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  private createOnlyNotVersioningRef(settings: DirectoryEditSettings_NotVersioning, fields: Array<NotVersioningField>): DirectoryEditRef_NotVersioning {
    if(!this.notVersioningService) {
      throw new Error("Не загружен сервис DirectoryEditNotVersioningService, используй провайдер notVersioningOnlyDirectoryServiceProvider (если справочник поддерживает ТОЛЬКО НЕ версионное редактирование) или versioningAndNotVersioningDirectoryServiceProvider (если справочник поддерживает и версионное и не версионное редактирование)!");
    }
    const createdService = new DirectoryEditRef_NotVersioning(settings, this.kendoNotificationService, this.loadingIndicatorService, this.notVersioningService, this.displayErrorsService, this.tracerService.copy(), fields);
    this.allRefs.push(createdService);
    return createdService;
  }

  /** Создает экземпляр сервиса редактирования версионных полей.*/
  @traceFunc({traceParamType: TraceParamEnum.notTrace})
  private createOnlyVersioningRef(settings: DirectoryEditSettings_Versioning, fields: Array<VersioningField>): DirectoryEditRef_Versioning {
    if(!this.versioningService) {
      throw new Error("Не загружен сервис DirectoryEditVersioningService, используй провайдер versioningOnlyDirectoryServiceProvider (если справочник поддерживает ТОЛЬКО версионное редактирование) или versioningAndNotVersioningDirectoryServiceProvider (если справочник поддерживает и версионное и не версионное редактирование)!");
    }
    const createdService = new DirectoryEditRef_Versioning(settings, this.loadingIndicatorService, this.alertService, this.versioningService, this.displayErrorsService, this.tracerService.copy(), fields);
    this.allRefs.push(createdService);
    return createdService;
  }

  public ngOnDestroy(): void {
    //Вызываем метод Destroy для всех созданных ранее экземпляров сервисов
    this.allRefs.forEach(service => service.ngOnDestroy());
  }
}
