import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { TextBoxFieldControl_Settings } from "../../services/classes/field-controls/text-box-field-control";
import { ICheckboxDirectoryEditComponent } from "./i-checkbox-directory-edit-control.component";

@Component({
  selector: 'app-checkbox-directory-edit-control',
  templateUrl: './checkbox-directory-edit-control.component.html',
  styleUrls: ['./checkbox-directory-edit-control.component.css'],
})
@traceClass('CheckboxDirectoryEditComponent')
export class CheckboxDirectoryEditComponent implements ICheckboxDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: TextBoxFieldControl_Settings;

  constructor(private traceService: TracerServiceBase) {  }

}
