/**
 * Помощник путей для Root
 */
export class RootPathHelper {
  /**
   * Объединить пути
   * @param paths
   */
  public static join(...paths: string[]): string{
    return paths.join('/');
  }
}
