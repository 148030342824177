import { FieldControlType } from "../field-controls/field-control-type";
import { IFieldSettings } from "./interfaces/common";

export class NotVersioningField {
  public isVersioning: boolean = false;
  constructor (
    public name: string,
    public settings: NotVersioningFieldSettings,
    public control: FieldControlType
  ) {}
}

export interface NotVersioningFieldSettings extends IFieldSettings{

}
