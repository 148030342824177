export class PhoneData {
  constructor(
    public mobilePhone: string
  ) {}

  /** Получает объект мобильного телефона (если строка нен ачинается с +7 то добавит в начало строки) */
  static getFromPhoneValue(phoneValue: string) {
    return new PhoneData(!phoneValue.startsWith('+7') ? `+7${phoneValue}` : phoneValue);
  }

  static getAsString(data: PhoneData): string {
    return data?.mobilePhone;
  }
  static getAsString2(data: PhoneData): string {
    return data?.mobilePhone ? `${data?.mobilePhone.substring(0,2)} (${data?.mobilePhone.substring(2,5)}) ${data?.mobilePhone.substring(5,8)}-${data?.mobilePhone.substring(8,10)}-${data?.mobilePhone.substring(10,12)}` : null;
  }
}

