import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IReportPeriodOptional } from "src/app/classes/domain/POCOs/stafflist/ReportPeriod";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { IAddReportPeriodFormComponent } from "../i-add-report-period-form.component";

@Injectable()
@traceClass('AddReportPeriodFormComponentService')
export class AddReportPeriodFormComponentService extends ComponentServiceBase<IAddReportPeriodFormComponent> {

  constructor(private readonly traceService: TracerServiceBase) {
    super();
  }

  /** Инициализация сервиса */
  @traceFunc()
  public init() {
    this.component.form = this.createForm(this.component.dataItem);
  }

  @traceFunc()
  public onClickSave() {
    this.component.onSaveEventEmitter.emit(this.component.form.value);
  }

  @traceFunc()
  public onClickCancel() {
    this.component.onCancelEventEmitter.emit(false);
  }

  /** Создание формы */
  @traceFunc()
  private createForm(item: IReportPeriodOptional) {
    const form = new FormGroup({
      name: new FormControl(item?.name ?? "", [Validators.required, Validators.maxLength(255)]),
      comment: new FormControl(item?.comment ?? "", [Validators.minLength(2), Validators.maxLength(100)]),
    });

    form.markAllAsTouched();
    form.valueChanges.subscribe(value => {
      this.traceService.add2(`Изменена форма добавления периода контроля`, { obj: value });
    });

    return form;
  }
}