import {
  ArrayDataSource,
  ArrayDataSourceHasId,
  ArrayDataSourceHasIdReadOnly,
  DataSource,
  DataSourceReadOnly
} from "../data-source";
import {NEVER, Observable, of, ReplaySubject, tap} from "rxjs";
import {ArrayExpanded, ArrayHelper} from "../../../helpers/arrayHelper";
import {filter, map, take, takeUntil} from "rxjs/operators";

/** Тип инициатора установки выделенных строк */
type InitiatorType = 'program' | 'user';

/** Тип данных для DataSource callSetIds */
type CallSetIdsType<TId> = {
  /** Список идентификаторов на выделение */
  ids: TId[],
  /** Инициализатор вызова метода */
  initiator: InitiatorType,
  /** Ожидать ли данные в dataSource */
  waitData: boolean,
};

/**
 * Класс управления выделенными элементами в массиве.<br>
 * Следит за изменениями в dataSource, и в зависимости от изменения в нем, управляет выделенными элементами.<br>
 * Данный класс сам освобождает ресурсы, если dataSource разрушается.<br>
 * Пример: Выделенные идентификаторы элементов = [2], в dataSource происходит удаление элемента с id === 2. Выделенные идентификаторы элементов = []<br>
 */
export class ArrayDataSourceSelection<TDataItem, TId>{
  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  private readonly _callSetIds = new DataSource<CallSetIdsType<TId>>();
  /**
   * Использовать для отслеживания вызовов метода _setIds.<br>
   * События в этом dataSource будут происходить при каждом вызове метода _setIds и содержать параметры передаваемые в метод.<br>
   * Если произошло событие в данном dataSource, то это не значит что изменился набор выбранных элементов.<br>
   */
  public get callSetIds(): DataSourceReadOnly<CallSetIdsType<TId>>{
    return this._callSetIds;
  }

  private readonly _selectedIds = new ArrayDataSource<TId>();
  /**
   * Использовать для отслеживания изменения выделенных идентификаторов<br>
   * События в этом dataSource будут происходить, если изменился набор(НЕ порядок) идентификаторов<br>
   */
  public get selectedIds(): DataSourceReadOnly<TId[]>{
    return this._selectedIds;
  }

  private readonly _selectedItems: ArrayDataSourceHasId<TDataItem, TId>;
  /**
   * Использовать для отслеживания изменения выделенных идентификаторов<br>
   * События в этом dataSource будут происходить если изменился набор(НЕ порядок) идентификаторов<br>
   * Пример: В родительском компоненте НЕ нужно обновлять данные если в выделенных элементах произошли изменения<br>
   */
  public get selectedItems(): ArrayDataSourceHasIdReadOnly<TDataItem, TId>{
    return this._selectedItems;
  }

  private readonly _selectedItems2: ArrayDataSourceHasId<TDataItem, TId>;
  /**
   * Использовать для отслеживания как изменения выделенных идентификаторов, так и изменениях в самих элементах(prev !== new)<br>
   * События в этом dataSource будут происходить если изменился набор(НЕ порядок) идентификаторов, а также если любой элемент изменился<br>
   * Пример: В родительском компоненте нужно обновлять данные если в выделенных элементах произошли изменения<br>
   */
  public get selectedItems2(): ArrayDataSourceHasIdReadOnly<TDataItem, TId>{
    return this._selectedItems2;
  }

  private readonly _withInitiator = new DataSource<{ selection: ArrayDataSourceSelection<TDataItem, TId>, initiator: InitiatorType }>()
  /**
   * Использовать для отслеживания событий с информацией о его инициализаторе('program' | 'user').<br>
   * @example Необходимо скролить до выделенного элемента, если установка произошла только программно
   */
  public get withInitiator(): DataSourceReadOnly<{ selection: ArrayDataSourceSelection<TDataItem, TId>, initiator: InitiatorType }>{
    return this._withInitiator;
  }

  /**
   * Конструктор
   * @param dataSource источник данных
   */
  constructor(public readonly dataSource: ArrayDataSourceHasId<TDataItem, TId>) {
    this._selectedItems = new ArrayDataSourceHasId(this.dataSource.idGetter);
    this._selectedItems2 = new ArrayDataSourceHasId(this.dataSource.idGetter);

    this.dataSource['streams$'].unsubscribe.subscribe(value => { //Если разрушается dataSource, то разрушаем и текущий экземпляр
      this.onDestroy();
    })
  }

  /**
   * Установить выделенные элементы.<br>
   * Смотри метод setIds
   * @param items элементы на выделение
   * @param waitData ожидать ли данные в dataSource
   * @param initiator инициатор вызова
   */
  public setItems(items: TDataItem[], waitData: boolean = true, initiator: InitiatorType = 'program'){
    this.setIds(items.map(x => this.dataSource.idGetter(x)), waitData, initiator);
  }

  /**
   * Установить выделенные идентификаторы.<br>
   * 1. Сделает distinct.<br>
   * 2. Возьмет только те идентификаторы, которые содержатся в dataSource.<br>
   * 3. Установит нового списка идентификаторов произойдет только если набор идентификаторов изменился.<br>
   * @param ids список идентификаторов
   * @param waitData ожидать ли данные в dataSource
   * @param initiator инициатор вызова
   */
  public setIds(ids: TId[], waitData: boolean = true, initiator: InitiatorType = 'program'){
    this._callSetIds.setData({ids: ids, waitData, initiator: initiator});

    const awaitData$ = !waitData ?
      of(null) :
      this.dataSource.data2$.pipe(
        tap(data => {
          if(this.dataSource.fns.isNotDataFn(data) && this.selectedIds.data2 !== undefined){
            this.onIdsChange([], initiator);
          }
        }),
        filter(value => value !== undefined),
        map(value => null)
      );

    awaitData$.pipe(
      take(1), //Одна трансляция
      takeUntil(this._callSetIds.beforeChange$), //До тех пор, пока не будет нового вызова этого метода
      takeUntil(this.streams$.unsubscribe), //До освобождения ресурсов
    ).subscribe(x => {
      ids = ArrayHelper.distinct(ids);

      const grouped = this.groupIdWithItems(ids, this.dataSource.data2)
        .filter(x => x.items.length > 0);//Берем все, где присутствует хоть один элемент с идентификатором

      ids = grouped.map(x => x.id); //Перезаписываем список идентификаторов, оставляем те, которые присутствуют в источнике

      if(!this.dataSource.wasEmitted){ //Если источник данных еще не транслировал, то не транслируем
        return;
      }

      //Если данные присутствуют и при этом набор идентификаторов НЕ изменился, то выходим
      if(!!this.selectedIds.data2 && ArrayHelper.equals2(ids, this.selectedIds.data2, (x1, x2) => x1 === x2 )){
        return;
      }

      this.onIdsChange(ids, initiator);
      this.subscribeToChangeItems(this.callSetIds.data);
    })
  }

  /** Выделить все элементы в source */
  public selectAll(initiator: InitiatorType){
    this.dataSource.data$.pipe(
      map(value => value.map(itm => this.dataSource.idGetter(itm))),
      take(1),
      takeUntil(this._callSetIds.beforeChange$),
      takeUntil(this.streams$.unsubscribe))
      .subscribe(value => this.setIds(value, false, initiator));
  }

  /**
   * Установит выделенные элементы в текущие.<br>
   * Использовать если необходимо повторить все события во всех selected dataSource<br>
   */
  public repeat(){
    if(!this.selectedIds.wasEmitted){ //Если еще не было установок, то выходим
      return;
    }

    this.onIdsChange(this.selectedIds.data, this.withInitiator.data.initiator);
  }

  /**
   * Скопировать состояние выделенных элементов текущего экземпляра на переданный целевой экземпляр.<br>
   * Возьмет идентификаторы выделенных элементов текущего экземпляра, и вызовет метод setIds у переданного экземпляра.<br>
   * @param target Целевой экземпляр. TId должен быть типа текущего экземпляра.
   */
  public copyStateTo<TItem, TTarget extends ArrayDataSourceSelection<TItem, TId>>(target: TTarget){
    if(this.selectedIds.wasEmitted){ //Если устанавливали значения в текущем экземпляре
      target.setIds(this.selectedIds.data, true, this.withInitiator.data.initiator); //приводим в состояние
    } else if(target.selectedIds.wasEmitted) { //Если НЕ установлены значения и при этом у переданного экземпляра были значения
      target.setIds([], false, 'program'); //Сбрасываем выделенные
    }

    return this;
  }

  /**
   * Ретранслировать установку выделенных элементов в данном экземпляре на переданный целевой экземпляр.<br>
   * Ретрансляция будет происходить до освобождения ресурсов текущего экземпляра или освобождения ресурсов целевого экземпляра или до первой трансляции stop$.<br>
   * Ретранслировать будет все последующие изменения с момента вызова данного метода(используется именно dataSource.change$)<br>
   * @param target Целевой экземпляр. TId должен быть типа текущего экземпляра.
   * @param stop$ стрим остановки ретрансляции. Останавливает ретрансляцию при его первой трансляции или его завершению
   */
  public retransmitTo<TItem, TTarget extends ArrayDataSourceSelection<TItem, TId>>(target: TTarget, stop$: Observable<any> = undefined){
    stop$ = !stop$ ? NEVER : stop$;
    this.withInitiator.change$
      .pipe(
        takeUntil(target.streams$.unsubscribe),
        takeUntil(this.streams$.unsubscribe),
        takeUntil(stop$)
      )
      .subscribe(value => {
        target.setIds(value.selection.selectedIds.data, true, value.initiator);
      })

    return this;
  }

  /** Обработка изменения списка выделенных идентификаторов */
  private onIdsChange(ids: TId[], initiator: InitiatorType){
    this._selectedIds.setData(ids);

    const items = this.flatMapGroups(this.groupIdWithItems(ids, this.dataSource.data2)).map(x => x.item);
    this._selectedItems.setData(items);
    this._selectedItems2.setData(items);
    this._withInitiator.setData({selection: this, initiator});
  }

  /**
   * Метод подписывается на изменения в dataSource на элементы по переданному списку идентификаторов, до установки нового списка выделенных идентификаторов.<br>
   * Если элемент в dataSource был удален, то произойдет переинициализация списка выделенных идентификаторов.<br>
   * Если элемент в dataSource был изменен, то установит новый список элементов только в selectedItems2.<br>
   * @param lastCall данные последнего вызова метода setIds<br>
   * @private
   */
  private subscribeToChangeItems(lastCall: CallSetIdsType<TId>){
    this.dataSource.change$.pipe(
      takeUntil(this.selectedIds.beforeChange$),
      takeUntil(this.streams$.unsubscribe)
    ).subscribe(value => {
      //Если в новых данных отсутствует хоть один элемент с идентификатором присутствующем в выделенных идентификаторах
      const groupIdWithItems = this.groupIdWithItems(lastCall.ids, value).filter(x => x.items.length > 0);
      if(this.selectedIds.data.length !== groupIdWithItems.length){
        this.setIds(lastCall.ids, lastCall.waitData, lastCall.initiator); //Устанавливаем новые значения
        return;
      }

      //Если текущий набор this._selectedItems2 не соответствует новым данным
      const items = this.flatMapGroups(groupIdWithItems).map(x => x.item);
      const isEquals = ArrayHelper.equals2(
        this.selectedItems2.data,
        items,
        (item1, item2) => item1 === item2
        );
      if(!isEquals){
        this._selectedItems2.setData(items);
        return;
      }
      //----
    })
  }

  /** Группирует идентификаторы с элементами */
  private groupIdWithItems(ids: TId[], allItems: TDataItem[] | undefined){
    return new ArrayExpanded(ids)
      .leftInnerJoinGroupedRight(
        allItems ?? [],
        x => x,
        this.dataSource.idGetter,
        (left, rights) => ({
          id: left,
          items: rights
        })
      ).array;
  }

  /** Конвертирует переданные группы в плоский массив */
  private flatMapGroups(group: ReturnType<ArrayDataSourceSelection<TDataItem, TId>['groupIdWithItems']>){
    return ArrayHelper.flatMapBy(group, x => x.items, (outItem, innerItem) => ({id: outItem.id, item: innerItem}) )
  }

  private isDestroyed = false;
  /** Высвободить ресурсы */
  public onDestroy(){
    if(this.isDestroyed){
      return;
    }

    this.isDestroyed = true;

    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();

    this._callSetIds.onDestroy();
    this._selectedIds.onDestroy();
    this._selectedItems.onDestroy();
    this._selectedItems2.onDestroy();
    this._withInitiator.onDestroy();
  }
}
