import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { SubdivisionTreeListItem } from 'src/app/services/webApi/webApi1/controllers/api1-subdivisions-directory-treelist-control-controller.service';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { versioningAndNotVersioningDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import { SubdivisionsDirectoryTreeListComponentDataSourceService, SubdivisionsDirectoryTreeListComponentDataSourceServiceBase } from './services/subdivisions-directory-tree-list-component-data-source.service';
import { map, Observable, of, ReplaySubject, switchMap, take, takeUntil } from 'rxjs';
import { HierarchiStringsConflictsDialogService } from 'src/app/components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service';
import { KendoTreeListDataSourceSelection } from '../../../../../../../src/app/classes/array-data-sources/selections/kendo-treelist-array-data-source-selection';
import { FieldType } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/field-type';
import { DirectoryEditRef } from '../../../../../../../src/app/components/directory-edit/services/ref-services/directory-edit-ref';
import { NotVersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/not-versioning-field';
import { TextAreaFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/text-area-field-control';
import { DateFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/date-field-control';
import { VersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/versioning-field';
import { TextBoxFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/text-box-field-control';
import { CustomFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/custom-field-control';
import { CellClickExpandedEvent } from '../../../../../../../src/app/services/directives/grid-treelist-expanded-directive.service';
import { DateHelper } from '../../../../../../../src/app/helpers/dateHelper';
import { trace } from '../../../../../../../src/app/modules/trace/operators/trace';
import { DirectoryEditService } from '../../../../../../../src/app/components/directory-edit/services/directory-edit.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { SubdivisionsDirectoryTreeListComponentService } from './services/subdivisions-directory-tree-list-component.service';
import { combineLatest } from 'rxjs';
import { VersioningItem } from '../../../../../../../src/app/classes/directories/edit/versioningItem';
import { xnameofPath } from '../../../../../../../src/app/functions/nameof';
import { DataSource } from '../../../../../../../src/app/classes/array-data-sources/data-source';
import { exElementByIndexOr } from '../../../../../../../src/app/operators/ex-element-by-index-or';
import { CellClickEvent } from '@progress/kendo-angular-treelist';
import { DeferSelectionService } from '../../../../../../../src/app/services/defer-selection-services/defer-selection.service';
import { ArrayDataSourceSelection } from '../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';
import { SubdivisionDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/subdivision-data-source.service';
import { exCreateService$ } from '../../../../../../../src/app/operators/ex-create-service.operator';
import { DbChangedListener } from '../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';

@Component({
  selector: 'app-subdivisions-directory-tree-list',
  templateUrl: './subdivisions-directory-tree-list.component.html',
  styleUrls: ['./subdivisions-directory-tree-list.component.css'],
  providers: [
    versioningAndNotVersioningDirectoryServiceProvider,
    SubdivisionsDirectoryTreeListComponentService,
    SubdivisionsDirectoryTreeListComponentDataSourceService,
    HierarchiStringsConflictsDialogService,
    DeferSelectionService,
  ],
})
@traceClass('SubdivisionsDirectoryTreeListComponent')
export class SubdivisionsDirectoryTreeListComponent implements OnInit, OnDestroy {

  public subdivisionDataSourceService$: Observable<SubdivisionDataSourceService>;
  @Output('cellClick') public cellClick: EventEmitter<SubdivisionTreeListItem> = new EventEmitter<SubdivisionTreeListItem>();
  @Output('doubleCellClick') public doubleCellClick: EventEmitter<SubdivisionTreeListItem> = new EventEmitter<SubdivisionTreeListItem>();

  @ViewChild('customParentIdFormFieldValueControlTemplateRef', { static: true })
  public customParentIdFormFieldValueControlTemplateRef: TemplateRef<any>;

  /** Ключи названий всех колонок */
  public readonly fieldsNames = xnameofPath(SubdivisionTreeListItem, '');
  public readonly currentSelectedItem = new DataSource<SubdivisionTreeListItem>();

  /* Ближайший элемент сверху и снизу от выделенного */
  public readonly nearestItem = new DataSource<{ up: SubdivisionTreeListItem, down: SubdivisionTreeListItem }>();
  private fields: FieldType[];
  private directoryEditServiceRef: DirectoryEditRef;
  private readonly streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  private _dataSourceService: SubdivisionsDirectoryTreeListComponentDataSourceServiceBase;

  @Input()
  public get dataSourceService(): SubdivisionsDirectoryTreeListComponentDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: SubdivisionsDirectoryTreeListComponentDataSourceServiceBase) {
    this._dataSourceService = value;
    this.def.originSelection = value.dataSource;
  }

  @Input()
  public set selection(value: ArrayDataSourceSelection<SubdivisionTreeListItem, number>) {
    this.def.originSelection = value;
  }

  constructor(private readonly service: SubdivisionsDirectoryTreeListComponentService,
              private readonly directoryEditService: DirectoryEditService,
              private readonly hscDialogService: HierarchiStringsConflictsDialogService,
              private readonly displayErrorsService: DisplayErrorsService,
              public readonly def: DeferSelectionService<SubdivisionsDirectoryTreeListComponentDataSourceServiceBase['dataSource'], KendoTreeListDataSourceSelection<SubdivisionsDirectoryTreeListComponentDataSourceServiceBase['dataSource']['data'][0], number>>,
              private readonly dbChangedListener: DbChangedListener,
              private readonly traceService: TracerServiceBase) {
    def.tempCtorFunc = dataSource => new KendoTreeListDataSourceSelection<SubdivisionsDirectoryTreeListComponentDataSourceServiceBase['dataSource']['data'][0], number>(dataSource);
    def.isDeferApply = false;

    def.tempSelection.data$
      .pipe(
        switchMap(value => this.nearestItem.setData$(
          combineLatest([
            this.currentSelectedItem.setData$(value.selectedItems2.data$.pipe(exElementByIndexOr(0))),
            value.dataSource.data2$,
          ])
            .pipe(
              switchMap(() => this.getUpDownItems$()),
            ),
        )),
        takeUntil(this.streams$.unsubscribes),
      )
      .subscribe();
  }

  @traceFunc()
  public ngOnInit() {
    this.initializeEditableFieldsArray();
    this.initializeDirectoryEditService();

    this.subdivisionDataSourceService$ = exCreateService$(() => new SubdivisionDataSourceService(this.dataSourceService, this.dbChangedListener));
  }

  /** Начать редактирование текущего подразделения */
  @traceFunc()
  public startEditParentId() {
    this.directoryEditServiceRef.edit(this.fieldsNames.parentId.toString(), this.currentSelectedItem.data, null);
  }

  @traceFunc()
  public onClickAdd() {
    this.service.showAddDialog(this);
  }

  @traceFunc()
  public onClickReorderUp() {
    this.reorderSubdivision(this.currentSelectedItem.data.id, this.nearestItem.data2.up.id);
  }

  @traceFunc()
  public onClickReorderDown() {
    this.reorderSubdivision(this.currentSelectedItem.data.id, this.nearestItem.data2.down.id);
  }

  /** При двойном клике открыть неверсионное редактирование поля */
  @traceFunc()
  public onDblCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    if (!e.originalEvent.isEdited) {
      this.directoryEditServiceRef.edit(e.originalEvent.column.field, e.originalEvent.dataItem, e.originalEvent.originalEvent.target);
    }
  }

  @traceFunc()
  public getDataItemForParentIdFormField(id: number): SubdivisionTreeListItem {
    return this.dataSourceService.dataSource.data.find(d => d.id == id) ?? {} as SubdivisionTreeListItem;
  }

  @traceFunc()
  public blockIfItemDisabled(e: any, dataItem: SubdivisionTreeListItem) {
    if (this.itemDisabled(dataItem)) {
      e.stopPropagation();
    }
  }

  @traceFunc()
  public itemDisabled(dataItem: SubdivisionTreeListItem) {
    return dataItem.id === this.currentSelectedItem.data.id;
  }

  @traceFunc()
  private initializeDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.init({
      addVersion$: (actionId, versioningEntity) => this.dataSourceService.addSubdivisionVersion$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataSourceService.deleteSubdivisionVersion$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSourceService.getVersionsByOwnerIdAndActionId$(ownerId, actionId),
      editVersion$: (actionId, entity) => this.dataSourceService.editSubdivisionVersion$(actionId, entity),
      saveNoVersioning$: (entity: any, field: NotVersioningField) => {
        if (field.name === 'endDate') {
          return this.hscDialogService.show2$((isSafe) => this.dataSourceService.editEndDate$(entity, isSafe));
        } else {
          return this.dataSourceService.save$(entity);
        }
      },

      isNeedHideData: (entity) => this.isNeedHideData(entity),
    }, this.fields);
  }

  /** Инициализация полей справочника */
  @traceFunc()
  private initializeEditableFieldsArray() {
    this.fields = [
      new NotVersioningField(this.fieldsNames.comment.toString(), { isAllowNull: true, title: 'Комментарий' }, new TextAreaFieldControl({ maxLength: 100 })),
      new NotVersioningField(this.fieldsNames.startDate.toString(), { isAllowNull: true, title: 'Дата начала' }, new DateFieldControl({ maxValue: () => this.directoryEditServiceRef.currentDataItem.endDate })),
      new NotVersioningField(this.fieldsNames.endDate.toString(), { isAllowNull: true, title: 'Дата окончания' }, new DateFieldControl({ minValue: () => this.directoryEditServiceRef.currentDataItem.startDate })),
      new VersioningField(this.fieldsNames.code.toString(), SupportedActionEnum.stafflist_subdivision_code, { isAllowNull: false, title: 'Код подразделения' }, new TextBoxFieldControl({ minLength: 0, maxLength: 50 })),
      new VersioningField(this.fieldsNames.longName.toString(), SupportedActionEnum.stafflist_subdivision_long_name, { isAllowNull: false, title: 'Наименование подразделения' }, new TextBoxFieldControl({ minLength: 1, maxLength: 255 })),
      new VersioningField(this.fieldsNames.shortName.toString(), SupportedActionEnum.stafflist_subdivision_short_name, { isAllowNull: false, title: 'Сокращенное наименование подразделения' }, new TextBoxFieldControl({ minLength: 1, maxLength: 50 })),
      new VersioningField(this.fieldsNames.parentId.toString(), SupportedActionEnum.stafflist_subdivision_parent_id, { isAllowNull: true, title: 'Входит в состав подразделения', dialogTitle: 'Редактирование иерархии подразделений', preGetVersionsData$: this.preGetVersionsData$.bind(this) }, new CustomFieldControl(this.customParentIdFormFieldValueControlTemplateRef)),
    ];
  }

  /** Проверка попадают ли данные в диапазон отображения */
  @traceFunc()
  private isNeedHideData(entity: any) {
    return !DateHelper.isDateIntervalsIntersect(entity.startDate, entity.endDate, this.dataSourceService.paramsDataSource.data.reportRangeStartDate, this.dataSourceService.paramsDataSource.data.reportRangeEndDate);
  }

  /** Получить данные для Редактирования иерархии подразделений */
  private preGetVersionsData$(versioningItems$: Observable<VersioningItem[]>): Observable<VersioningItem[]> {
    return versioningItems$.pipe(
      switchMap(vis =>
        this.dataSourceService.dataSource.data$.pipe(
          map(
            m => vis.map(v => ({ ...v, displayValue: m.find(f => f.id === +v.valueAsString)?.longName }),
            ),
          ),
        ),
      ),
      takeUntil(this.directoryEditServiceRef.onClose$),
    );
  }

  /** Изменение порядка подразделения */
  @traceFunc()
  private reorderSubdivision(subdivision1Id: number, subdivision2Id: number) {
    this.dataSourceService.reorderSubdivision$(subdivision1Id, subdivision2Id, this.dataSourceService.paramsDataSource.data.forDate)
      .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
      .subscribe({
        error: error => this.displayErrorsService.handleError(error),
      });
  }

  /** Обновить элементы Вверх и Вниз */
  @traceFunc()
  private getUpDownItems$() {
    const { data2 } = this.dataSourceService.dataSource;
    const currentSelectedItem = this.currentSelectedItem.data;

    if (!currentSelectedItem || !data2) {
      return of(null);
    }

    return of({
      up: data2.sort((n1, n2) => n2.sortOrder - n1.sortOrder).find(f => f.parentId == currentSelectedItem.parentId && f.sortOrder < currentSelectedItem.sortOrder),
      down: data2.sort((n1, n2) => n1.sortOrder - n2.sortOrder).find(f => f.parentId == currentSelectedItem.parentId && f.sortOrder > currentSelectedItem.sortOrder),
    });
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
