import {IEntityId} from "../../interfaces/IEntityId";
import {IEntityModify} from "../../interfaces/IEntityModify";
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface IPlan extends IEntityId, IEntityModify{
  /** Год данных */
  sourceYear: number;

  /** Прогнозный год */
  targetYear: number;
}

@classBackend('Plan', 'pfhd_plan')
@className('Plan')
export class Plan implements IPlan{
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public sourceYear: number,
              public targetYear: number,) {
  }

  /** Получить название плана */
  public static GetName(plan: Pick<IPlan, 'sourceYear' | 'targetYear'>, separator: string = '/'){
    return !plan ? undefined : `${plan.sourceYear}${separator}${plan.targetYear}`;
  }
}
