import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IOccupationType, OccupationType } from "src/app/classes/domain/POCOs/stafflist/OccupationType";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1OccupationTypesControllerService } from "src/app/services/webApi/webApi1/controllers/api1-occupation-types-controller.service";

@Injectable()
export class OccupationTypesDirectoryGridComponentDataService {

  constructor(private api1OccupationTypeControllerService: Api1OccupationTypesControllerService,
              private loadingIndicatorService: LoadingIndicatorService) {
  }

  get$(): Observable<Array<OccupationType>> {
    return this.loadingIndicatorService.addToObservable("Загрузка списка категорий должностей",
      this.api1OccupationTypeControllerService.get$()
    )
  }

  saveWithoutLoadingIndicator$(entity: OccupationType): Observable<[]> {
    return this.api1OccupationTypeControllerService.save$(entity)
  }

  save$(entity: OccupationType): Observable<[]> {
    return this.loadingIndicatorService.addToObservable("Сохранение родителя",
      this.saveWithoutLoadingIndicator$(entity)
    )
  }

  delete$(entityId): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable("Удаление категории должностей",
      this.api1OccupationTypeControllerService.delete$(entityId)
    )
  }

  add$(entity: IOccupationType): Observable<OccupationType> {
    return this.loadingIndicatorService.addToObservable("Добавление категории должностей",
      this.api1OccupationTypeControllerService.add$(entity)
    )
  }
}
