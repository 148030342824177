<app-hierarchi-strings
  [exportExcelFileName]="'Подтверждение действий.xlsx'"
  [hierarchiStrings]="hierarchiStrings$ | async"
></app-hierarchi-strings>

<kendo-dialog-actions>
  <div class="bottom-area">
    <div class="code-captcha">
      <app-code-captcha #captcha></app-code-captcha>
    </div>

    <div class="buttons-area">
      <button kendoButton
              (click)="onClickCancel()">
        Отменить
      </button>
      <button kendoButton
              (click)="onClickContinue()"
              primary="true"
              [disabled]="!(captcha.isSolved | async)">
        Подтвердить
      </button>
    </div>
  </div>
</kendo-dialog-actions>
