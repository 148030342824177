import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";
import {debounceTime, map, take, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {
  MultiplePeriodSettingsEnum,
  PrintQuarterSettingsWithSubdivisions
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  CheckedListItemsValidator, RequiredSubPeriodsValue
} from "../report-settings-form-validators";
import {DropDownItem} from "../../../classes/requestResults/iDropDownItem";
import {Api1DateControllerService} from "../../../services/webApi/webApi1/controllers/api1-date-controller.service";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {Observable, share} from "rxjs";
import {PropertyWrapFormControlType} from "../../../classes/types/property-wrap-form-control-type";

@Component({
  selector: 'app-print-quarter-settings-with-subdivisions',
  templateUrl: './print-quarter-settings-with-subdivisions.component.html',
  styleUrls: ['./print-quarter-settings-with-subdivisions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintQuarterSettingsWithSubdivisionsComponent implements OnInit, OnDestroy, IPrintFormSettingsComponentBase {

  public form: FormGroup<PrintQuarterSettingsWithSubdivisionsFormType>;
  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>;
  @Input() public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase<any> {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase<any>){
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection: ArrayDataSourceSelection<ISubdivision, number>;

  private _checkableSettings: CheckableSettings;
  //Настройки checkBox TreeView списка подразделений
  @Input() public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings) {
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Флаг невалидности формы, устанавливаемый внешними компонентами */
  @Input() public outerDisabled: boolean = false;
  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  @Input() public periodTypes: MultiplePeriodSettingsEnum[] = [
    MultiplePeriodSettingsEnum.year,
    MultiplePeriodSettingsEnum.halfOfYear,
    MultiplePeriodSettingsEnum.quarter,
    MultiplePeriodSettingsEnum.coupleOfMonths,
    MultiplePeriodSettingsEnum.months,
  ];

  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() public print$: EventEmitter<PrintQuarterSettingsWithSubdivisions> = new EventEmitter<PrintQuarterSettingsWithSubdivisions>();
  /** Изменение даты, по которой формируются данные (необходимо для выполнения перезагрузки списка подразделений, сформированного на конкретную дату)*/
  @Output() datesChange$: EventEmitter<{ startDate: Date, endDate: Date }> = new EventEmitter<{ startDate: Date, endDate: Date }>();

  public startDate: Date;
  public endDate: Date;
  public periods: DropDownItem[];
  public subPeriodLableText: string;
  public subPeriods: DropDownItem[] = [];
  public currentPeriod: DropDownItem;
  public periodFieldVisible: boolean;
  public years$: Observable<{ minYear: number, maxYear: number }>;
  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _months: DropDownItem[];
  private _coupleMonths: DropDownItem[];
  private _halfsOfYear: DropDownItem[];
  private _quarters: DropDownItem[];

  constructor( private dateControllerService: Api1DateControllerService,
               private chngDetector: ChangeDetectorRef) { }

  public ngOnInit(): void {
    const currentYear = new Date().getFullYear();

    this.years$ = this.dateControllerService.getYears$.pipe(takeUntil(this.unsubscribe$),
      map(years => {
        if (!years.length) {
          return { minYear: currentYear, maxYear: currentYear };
        }
        const minYear = years.length > 5 ? years[years.length - 5] : years[0];
        return { minYear: minYear.id, maxYear: years[years.length - 1].id}
      }), share());
    this.periodsInit();
    this.formInit(currentYear);
  }

  /** Установить значение параметра выбранных подразделений на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  public setCheckedSubdivisionsValue(el: number[]){
    setTimeout(() => this.form.controls.checkedSubdivisions.setValue(el));
  }

  /** Установить значение параметра "Выбрать все подразделения" на следующем круге eventLoop.
   * Необходимо для устранения ошибки afterChenge */
  public setAllSubdivisionsFlagValue(el: boolean){
    setTimeout(() => this.form.controls.allSubdivisionsFlag.setValue(el));
  }

  public onClickOk() {
    this.print$.next(new PrintQuarterSettingsWithSubdivisions(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.subPeriod.value?.text.toLowerCase(),
      this.startDate,
      this.endDate,
      this.form.controls.allSubdivisionsFlag.value,
      this.form.controls.checkedSubdivisions.value));
    this.print$.complete();
  }

  public onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }


  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /* Инициализировать форму и подписаться на события изменения ее полей */
  private formInit(currentYear: number) {
    this.form = new FormGroup<PrintQuarterSettingsWithSubdivisionsFormType>({
      period: new FormControl<DropDownItem>(this.currentPeriod, [Validators.required]),
      subPeriod: new FormControl<DropDownItem>({value: this.subPeriods[0], disabled: !this.currentPeriod.id}, [RequiredSubPeriodsValue()]),
      year: new FormControl<number>(currentYear,[Validators.required]),
      allSubdivisionsFlag: new FormControl<boolean>(false),
      checkedSubdivisions: new FormControl<Array<number>>([], CheckedListItemsValidator()),
    });

    this.setStartEndDates(currentYear);

    this.form.controls.year.valueChanges.pipe(debounceTime(700), takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.setStartEndDates(value);
        this.datesChange$.emit({startDate: this.startDate, endDate: this.endDate});
      });

    this.form.controls.period.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.subPeriodLableText = !!value.id ? `${value.text}:` : '';
        this.currentPeriod = value;
        this.subPeriods = this.getSubPeriods();
        this.form.controls.subPeriod.setValue(this.subPeriods[0]);
        !this.currentPeriod.id ? this.form.controls.subPeriod.disable() : this.form.controls.subPeriod.enable();
        this.setStartEndDates(this.form.controls.year.value);
        this.datesChange$.emit({startDate: this.startDate, endDate: this.endDate});
      });

    this.form.controls.subPeriod.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        this.setStartEndDates(this.form.controls.year.value);
        this.datesChange$.emit({startDate: this.startDate, endDate: this.endDate});
      });

    this.datesChange$.next({startDate: this.startDate, endDate: this.endDate});
  }

  /** Установить start end даты */
  private setStartEndDates(year: number): void{
    switch (this.currentPeriod.id) {
      case 0: // Год
        this.startDate = new Date(year, 0, 1);
        this.endDate = new Date(year, 12, 0, 23, 59, 59);
        return;
      case 1: // Полугодие
        this.setStartEndDateByPeriodLength(year, 6);
        return;
      case 2: // Квартал
        this.setStartEndDateByPeriodLength(year, 3);
        return;
      case 3: // пара месяцев
        this.setStartEndDateByPeriodLength(year, 2);
        return;
      case 4: // месяц
        this.setStartEndDateByPeriodLength(year, 1);
    }
  }

  /** Установить start/end Date согласно переданной параметром продолжительности периода*/
  private setStartEndDateByPeriodLength(year: number, periodLength: number) : void{
    this.startDate = new Date(year, (this.form.controls.subPeriod.value.id - 1) * periodLength, 1);
    this.endDate = new Date(year, (this.form.controls.subPeriod.value.id) * periodLength, 0, 23, 59, 59);
  }

  /* Инициализировать периоды, отображаемые в контроллах окна настроек отчета */
  private periodsInit(){
    this.dateControllerService.getPosibleMonth$
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(months => {
        this._months = months;
        for (let i = 0; i < 6; i++) {
          this._coupleMonths = Array.from(Array(6).keys())
            .map(i => new DropDownItem(i + 1, `${months[i * 2].text} - ${months[i * 2 + 1].text}`));
        }
      });

    this._quarters = [
      {id: 1, text: '1 квартал'},
      {id: 2, text: '2 квартал'},
      {id: 3, text: '3 квартал'},
      {id: 4, text: '4 квартал'}
    ];

    this._halfsOfYear  = [
      {id: 1, text: 'Первое полугодие'},
      {id: 2, text: 'Второе полугодие'}
    ];

    this.periods = [
      {id: 0, text:  'Год'},
      {id: 1, text: 'Полугодие'},
      {id: 2, text: 'Квартал'},
      {id: 3, text: 'Двухмесячный промежуток'},
      {id: 4, text: 'Месяц'}
    ].filter(p => this.periodTypes.some(pEn => pEn === p.id));

    this.currentPeriod = this.periods[0];
    this.periodFieldVisible = this.periodTypes.length > 1;
    this.subPeriodLableText = !!this.currentPeriod.id ? `${this.currentPeriod.text}:` : '';
    this.subPeriods = this.getSubPeriods();
  }

  /** Получить подпериоды */
  private getSubPeriods() {
    switch (this.currentPeriod.id) {
      case 1:  return this._halfsOfYear;
      case 2: return this._quarters;
      case 3: return this._coupleMonths;
      case 4: return this._months;
      default: return [];
    }
  }
}

export type PrintQuarterSettingsWithSubdivisionsValueObj = {
  period: DropDownItem,
  subPeriod: DropDownItem,
  year: number,
  allSubdivisionsFlag: boolean,
  checkedSubdivisions: Array<number>
}

export type PrintQuarterSettingsWithSubdivisionsFormType = PropertyWrapFormControlType<PrintQuarterSettingsWithSubdivisionsValueObj>;
