<div kendoDialogContainer></div>
<kendo-grid
            [kendoGridBinding]="dataSource.dataSource.data$ | async"
            [groupable]="false"
            [reorderable]="false"
            [resizable]="false"
            [style.height.%]="100"

            [kendoGridSelectBy]="selection?.forDirective?.selectionKey"
            (selectedKeysChange)="selection?.forDirective?.onSelectedKeysChange($event)"
            [selectedKeys]="selection?.forDirective?.selectedKeys"
            [selectable]="{
              mode: 'single',
              enabled: true
            }"
            [sortable]="{
              allowUnsort: true,
              mode: 'single'
            }"

            KendoGridExpandedSaveSort
            [sortStorageOptions]="sortStorageOptions"

            [(sort)]="sort"
            (sortChange)="sortChange($event)"
            [pageable]="false"
            scrollable="true"
            KendoGridExpanded
            [kendoGridExpandDetailsBy]="expandDetailsBy"
            [(expandedDetailKeys)]="expandedDetailKeys"
            (dbCellClick)="onDblCellClick($event)"
            tableTitleTextWrap="true"
            columnsPadding="6px 5px"
            headerColumnsPadding="5px"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="grid-header-content">
      <div class="grid-crud staff-positions-crud-buttons-area">
        <button (click)="onClickAdd()"
                [disabled]="!(this.dataSource.paramsDataSource.data$ | async)?.subdivisionId"
                kendoButton
                size="small"
                icon="plus"
                [primary]="true"
        >
          Добавить
        </button>
        <div class="reorder-buttons"
             [title]="sort !== defaultUnsort ? 'Измененение порядка не возможно. Уберите сортировку.' : ''">
          <button kendoButton
                  [icon]="'sort-asc-sm'"
                  size="small"
                  [primary]="true"
                  [disabled]="sort !== defaultUnsort
                    || !(this.selection.selectedIds.data$ | async)?.length
                    || !(this.upItem$ | async )"
                  (click)="onClickReorderUp()"
          >
            Вверх
          </button>
          <button kendoButton
                  [icon]="'sort-desc-sm'"
                  size="small" [primary]="true"
                  [disabled]="sort !== defaultUnsort
                    || !(this.selection.selectedIds.data$ | async)?.length
                    || !(this.downItem$ | async)"
                  (click)="onClickReorderDown()"
          >
            Вниз
          </button>
        </div>
        <button [disabled]="!(this.selection.selectedIds.data$ | async)?.length"
                kendoButton
                size="small"
                icon="delete"
                [primary]="true"
                (click)="onClickDelete()"
        >
          Удалить
        </button>
      </div>

      <kendo-textbox  class="search-input"
                      size="small"
                      placeholder="Поиск"
                      [(value)]="searchValue"
                      (afterValueChanged)="onFilter()"
                      [clearButton]="true">
      </kendo-textbox>
    </div>
  </ng-template>

  <kendo-grid-column [field]="fieldsNames.startDate.toString()" title="Дата начала" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.startDate | kendoDate: "dd.MM.yyyy" }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.endDate.toString()" title="Дата окончания" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.endDate | kendoDate: "dd.MM.yyyy" }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.occupationTitle.toString()" title="Должность" [width]="400">
  </kendo-grid-column>

  <kendo-grid-column-group [title]="staffUnitFieldGroupColumnName" [columnMenu]="false">
    <ng-template  kendoGridHeaderTemplate
                  let-column
                  let-columnIndex="columnIndex"
    >
      <div class="staff-units-column-header-div">
        <span class="k-column-title">{{ column.title }}</span>
        <button kendoButton
                icon="more-horizontal"
                title="Раскрыть/Свернуть"
                (click)="toggleStaffUnitsEmptyColumns()"
                class="show-hide-staff-units-columns-button"
                [primary]="!(isHideStaffUnitsEmptyColumns$ | async)"
        ></button>
      </div>
    </ng-template>
    <kendo-grid-column
      title="Всего"
      [resizable]="false"
      [width]="staffUnitColumnWidth$ | async"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem.ratesSumm
            | kendoNumber
              : { minimumFractionDigits: 1, maximumFractionDigits: 2 }
        }}
      </ng-template>
    </kendo-grid-column>
    <ng-container *ngFor="let prColumn of positionRateColumns$ | async">
      <kendo-grid-column  [title]="prColumn.shortName"
                          [resizable]="false"
                          [width]="staffUnitColumnWidth$ | async"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{
            getRatesDisplayValue(dataItem, prColumn.financingSourceId)
              | kendoNumber
                : { minimumFractionDigits: 1, maximumFractionDigits: 2 }
          }}
        </ng-template>
      </kendo-grid-column>
    </ng-container>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="Вакантные ставки" [columnMenu]="false">
    <ng-template  kendoGridHeaderTemplate
                  let-column
                  let-columnIndex="columnIndex"
    >
      <div class="staff-units-column-header-div">
        <span class="k-column-title">{{ column.title }}</span>
        <button kendoButton
                icon="more-horizontal"
                title="Раскрыть/Свернуть"
                (click)="toggleFreeStaffUnitsEmptyColumns()"
                class="show-hide-staff-units-columns-button"
                [primary]="!(isHideFreeStaffUnitsEmptyColumns$ | async)"
        ></button>
      </div>
    </ng-template>
    <kendo-grid-column  title="Всего"
                        [resizable]="false"
                        [width]="freeStaffUnitColumnWidth$ | async"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem.freeRatesSumm
            | kendoNumber
              : { minimumFractionDigits: 1, maximumFractionDigits: 2 }
        }}
      </ng-template>
    </kendo-grid-column>
    <ng-container *ngFor="let prColumn of positionFreeRateColumns$ | async">
      <kendo-grid-column [title]="prColumn.shortName"
                          [resizable]="false"
                          [width]="freeStaffUnitColumnWidth$ | async"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{
            getFreeRatesDisplayValue(dataItem, prColumn.financingSourceId)
              | kendoNumber
                : { minimumFractionDigits: 1, maximumFractionDigits: 2 }
          }}
        </ng-template>
      </kendo-grid-column>
    </ng-container>
  </kendo-grid-column-group>

  <kendo-grid-column [field]="fieldsNames.comment.toString()" title="Комментарий" [width]="100">
  </kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <div class="details-div">
      <section>
        <p>
          <button class="details-edit-button"
                  kendoButton
                  icon="pencil"
                  (click)="startEditButtonClick(fieldsNames.workModeId.toString(), dataItem, $event)"
          ></button
          ><strong>Режим работы:</strong> {{ dataItem.workModeTitle }}
        </p>
        <p>
          <button class="details-edit-button"
                  kendoButton
                  icon="pencil"
                  (click)="
                    startEditButtonClick(fieldsNames.dinnerHourDuration.toString(), dataItem, $event)
                  "
          ></button>
          <strong>Продолжительность обеда (ч.):</strong>
          {{ dataItem.dinnerHourDuration }}
        </p>
        <p>
          <button class="details-edit-button"
                  kendoButton
                  icon="pencil"
                  (click)="startEditButtonClick(fieldsNames.leaderFlag.toString(), dataItem, $event)"
          ></button>
          <strong>Является руководителем подразделения:</strong>
          {{ dataItem.leaderFlag ? "Да" : "Нет" }}
        </p>
        <p>
          <button [disabled]="isFlexibleWorkMode(dataItem)"
                  class="details-edit-button"
                  kendoButton
                  icon="pencil"
                  (click)="
                    startEditButtonClick(
                      fieldsNames.subtractLunchTimeFromWorkingHoursFlag.toString(),
                      dataItem,
                      $event
                    )
                  "
          ></button>
          <strong>Вычитать продолжительность обеденного перерыва из рабочего времени:</strong>
          {{ dataItem.subtractLunchTimeFromWorkingHoursFlag ? "Да" : "Нет" }}
        </p>
        <p>
          <button class="details-edit-button"
                  kendoButton
                  icon="pencil"
                  (click)="startEditButtonClick(fieldsNames.reportPeriodTitle.toString(), dataItem, $event)"
          ></button>
          <strong>Период контроля:</strong> {{ dataItem.reportPeriodTitle }}
        </p>
        <p>
          <button class="details-edit-button"
                  kendoButton
                  icon="pencil"
                  (click)="
                    startEditButtonClick(
                      fieldsNames.includeScheduleForPaidServiceFlag.toString(),
                      dataItem,
                      $event
                    )
                  "
          ></button>
          <strong>Включать в график приема по платным услугам:</strong>
          {{ dataItem.includeScheduleForPaidServiceFlag ? "Да" : "Нет" }}
        </p>
      </section>
    </div>
  </ng-template>
</kendo-grid>
