<kendo-dropdowntree class="popup-edit-control"
                    kendoDropDownTreeExpandable
                    [expandOnFilter]="settings?.filterExpandSettings"
                    [kendoDropDownTreeFlatBinding]="data$ | async"
                    [textField]="settings.textField ?? 'text'"
                    [valueField]="valueField"
                    [parentIdField]="settings.parentIdField ?? 'parentId'"
                    [valuePrimitive]="true"
                    [formControl]="control"
                    [dataItem]="dataItem$ | async"
                    [filterable]="!!settings?.filterSettings"
                    [filterSettings]="settings?.filterSettings"
                    [popupSettings]="popupSettings"
                    [listHeight]="settings?.listHeight"
>
</kendo-dropdowntree>
