<form class="k-form" [formGroup]="form">
  <div class="form-start-end-date-div">
    <kendo-formfield>
      <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
      <kendo-datepicker required #startDate formControlName="startDate" [min]="minDate" [max]="form.controls.endDate.value ? form.controls.endDate.value : maxDate"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение
        родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
      <kendo-datepicker #endDate formControlName="endDate" [min]="form.controls.startDate.value ? form.controls.startDate.value : minDate" [max]="maxDate"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение
        родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="value" text="Ставка"></kendo-label>
    <kendo-numerictextbox #value [decimals]="2" [format]="'n2'" formControlName="value" max="10000" [step]="rateStep" [min]="0" [autoCorrect]="true"></kendo-numerictextbox>
    <kendo-formerror *ngIf="form.controls.value.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.value.errors?.multipleOf">Количество ставок должно быть кратно: {{form.controls.value.errors?.multipleOf.multiplier}}, {{form.controls.value.errors?.multipleOf.multiplier * 2}}
      , {{form.controls.value.errors?.multipleOf.multiplier * 3}}...
    </kendo-formerror>
    <kendo-formerror *ngIf="form.controls.value.errors?.min">Значение не может быть меньше {{form.controls.value.errors?.min.min}}. Текущее значение: {{form.controls.value.errors?.min.actual}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.value.errors?.max">Значение не может быть больше {{form.controls.value.errors?.max.max}}. Текущее значение: {{form.controls.value.errors?.max.actual}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield *ngIf="isShowEditFinancingSource">
    <kendo-label [for]="financingSourceId" text="Источник финансирования"></kendo-label>
    <kendo-dropdownlist
          #financingSourceId
          [data]="financingSourcesDropDownItems"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControlName]="'financingSourceId'"
    >
    </kendo-dropdownlist>
    <kendo-formerror *ngIf="form.controls.financingSourceId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>


  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment formControlName="comment"/>
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая
      длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая
      длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

