import {Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {ServerLoggerService} from "../../../services/loggers/server-logger.service";

/**
 * Данный интерцептор помогает отловить запросы к серверу к одному и тому же методу контроллера с малым промежутком времени.
 *
 * (Реальный пример на реальном контроле)
 * К примеру, на форме есть два datepicker. При изменении любого идет запрос на сервер для получения данных.
 * Если при изменении первого datepicker срабатывает событие и у второго(хотя его данные не изменились), то будет два запроса в расстояние по времени в милисекунды.
 * Задача интерцептора помочь отловить данный баг.
 *
 * Интерцептор выводит в консоль сообщения Warning с описанием и отправляет на сервер.
 */
@Injectable({
  providedIn: 'root'
})
export class IdenticalRequestInterceptor implements HttpInterceptor {

  /** Максимальная дистанция в запросах к одинаковому ресурсу, при котором будет считаться что это баг */
  private readonly maximumDistanceInMilliseconds: number = 200;

  /** хранение запросов */
  private readonly storage: RequestStorage = new RequestStorage(this.maximumDistanceInMilliseconds);

  private _whiteUrlList: string[];
  /** Список урл которые не нужно анализировать */
  private get whiteUrlList(){
    if(!this._whiteUrlList){
      this._whiteUrlList = this.logger.urlPatch;
    }

    return this._whiteUrlList;
  }

  /** Конструктор */
  constructor(private logger: ServerLoggerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = IdenticalRequestInterceptor.HeaderName;
    if(req.headers.has(headerName)){ //Выходим если в заголовок переданно что нужно пропустить анализ
      return next.handle(req.clone({headers: req.headers.delete(headerName, headerName)}))
    }

    const url = req.url;

    if(this.whiteUrlList.some(x => url.indexOf(x) !== -1)){ //Продолжаем запрос если url содержит любой элемент из белого списка
      return next.handle(req.clone());
    }

    const currentDate = new Date();
    const lastDate = this.storage.getDateLast(url);
    if(lastDate) {
      const durationMilliseconds = +currentDate - +lastDate;

      if(durationMilliseconds < this.maximumDistanceInMilliseconds){
        const message = `[MEDSOFTLAB] К ресурсу ${url} частое обращение c разницей в ${durationMilliseconds} миллисекунд`
        console.warn(message);
        this.logger.error(message);
      }
    }

    this.storage.add(url);

    return next.handle(req.clone());
  }

  /** Название заголовка */
  private static readonly HeaderName = 'skipIdReInter';

  /** Получить заголовок для пропуска анализа дублирующих запросов */
  public static getSkipHeader(){
    return { [this.HeaderName]: this.HeaderName};
  }
}

/** Хранитель запросов */
class RequestStorage{
  /** Хранимые элемент */
  private items: RequestStorageItem[] = [];

  /**
   * Конструктор
   * @param expiredMilliseconds Через сколько миллисекунд считается элемент устаревшим
   */
  constructor(private readonly expiredMilliseconds) {
  }

  public add(url: string){
    this.clearByUrl(url) //Удаляем все по url. Необходимо хранить только последний
    this.items.push(new RequestStorageItem(new Date(), url))
  }

  /** Получить дату последнего итема. Если отсутствует то вернет undefined */
  public getDateLast(url: string) : Date {
    this.clearObsolete();//Удаляем устаревшие по времени
    const item = this.items.find(x => x.url === url)

    return item === undefined ? undefined : item.date;
  }

  /** Очистить элементы содержащие url */
  private clearByUrl(url: string){
    this.items = this.items.filter(x => x.url !== url);
  }

  /** Очистить items от устаревших */
  private clearObsolete(){
    const expired = +new Date() - this.expiredMilliseconds;
    this.items = this.items.filter(x => expired < +x.date);
  }
}

class RequestStorageItem{
  constructor(public date: Date,
              public url: string) {
  }
}
