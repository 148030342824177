import {IDropDownItem} from "./iDropDownItem";

export interface ITreeViewItem extends IDropDownItem{
  dataItems: ITreeViewItem[];
}

/**
 * Класс для заполнения модели дерева подразделений
 */
export class TreeViewItem implements ITreeViewItem{
  constructor(public id: number,
              public parentId: number,
              public text: string,
              public dataItems: Array<TreeViewItem>) {  }

  /**
   * Заполнить дерево подразделений для отображения в диалоговом окне настроек отчета
   */
  public FillDateItems(source: Array<TreeViewItem>) {
    let childrenList = source.filter(x => this.id == x.parentId);
    if (childrenList.length) {
      this.dataItems = childrenList;
      childrenList.forEach(ch => ch.FillDateItems(source));
    }
  }
}
