import { IFieldControl, IMinMaxValue } from "./interfaces/common";

export class DateFieldControl implements IFieldControl<any> {
  public readonly name = 'DateFieldControl';
  public getValueFunc = (stringValue: string) => stringValue;

  constructor (
    public settings?: (() => DateFieldControl_Settings) | DateFieldControl_Settings
  ) {}
}

export interface DateFieldControl_Settings extends IMinMaxValue<Date>  {

}
