import { Directive, TemplateRef } from '@angular/core';

/** Директива используется для передачи шаблона заголовка в базовый WorkspaceComponent */
@Directive({
  selector: '[appWorkspaceHeaderTemplate]',
})
export class WorkspaceHeaderTemplateDirective {

  constructor(public readonly templateRef: TemplateRef<any>) {
  }

}
