<form [formGroup]="reportForm" *ngIf="reportForm">
  <div class="form-controls">
    <div class="form-controls-daterange">
      <kendo-daterange>
        <div class="form-controls-daterange-from">
          <span class="label-text">с: </span>
          <kendo-dateinput kendoDateRangeStartInput autoCorrectOn="blur" formControlName="startDate" [size]="size"></kendo-dateinput>
        </div>
        <div class="form-controls-daterange-to">
          <span class="label-text">по: </span>
          <kendo-dateinput kendoDateRangeEndInput autoCorrectOn="blur" formControlName="endDate" [size]="size"></kendo-dateinput>
        </div>
      </kendo-daterange>
    </div>
    <div class="form-controls-report-date">
      <span class="label-text">Дата отчёта: </span>
      <kendo-datepicker formControlName="date"  [min]="streams$.minDate | async" [max]="streams$.maxDate | async"  [size]="size" ></kendo-datepicker>
    </div>
    <div class="form-controls-buttons">
      <button kendoButton
              [disabled]="(streams$.invalid | async) || !(streams$.dirty | async)" [primary]="(streams$.dirty | async)"
              icon="filter" (click)="emitChangesFromFormValue()"
              title="Применить изменения диапазона дат"
              [size]="size"
              class="filter-button"
      >
        Применить
      </button>
      <button kendoButton
              [disabled]="!(streams$.dirty | async)" [primary]="(streams$.dirty | async)"
              icon="filter-clear"
              (click)="cancelAllFormChanges()"
              title="Отменить все изменения"
              [size]="size"
              class="filter-clear-button"
      >
      </button>
    </div>
  </div>
</form>
