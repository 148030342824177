import {Directive} from "@angular/core";
import {FormControl, NgControl} from "@angular/forms";
import {DateOnly} from "../classes/Dates/date-onlys/date-only";


/**
 * Директива позволяет использовать {@link FormControl} типом {@link DateOnly} для компонентов использующих значение типом {@link Date}.
 * Переопределяет {@link NgControl.valueAccessor.writeValue}, конвертируя {@link DateOnly} в {@link Date}.
 * Переопределяет {@link NgControl.valueAccessor.registerOnChange}, конвертируя {@link Date} в {@link DateOnly}.
 */
@Directive({
  selector: '[dateOnlyFromControlToDate]',
})
export class DateOnlyFormControlToDateDirective {

  /** Конструктор */
  constructor(ngControl: NgControl) {
    const origin_writeValue = ngControl.valueAccessor.writeValue;
    const origin_registerOnChange = ngControl.valueAccessor.registerOnChange;

    // Переопределение функции
    ngControl.valueAccessor.writeValue = (value: any) => {
      origin_writeValue.bind(ngControl.valueAccessor, DateOnly.isDateOnly(value) ? value.date : value)();
    };

    // Переопределение функции
    ngControl.valueAccessor.registerOnChange = (fn: (_: any) => void) => {
      const func = (value: Date) => {
        fn(value ? DateOnly.createFromDate(value) : value);
      }

      origin_registerOnChange.bind(ngControl.valueAccessor, func)();
    }
  }
}
