import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { LoadingIndicatorService } from '../../../../services/loading-indicator.service';
import { take, takeUntil } from 'rxjs/operators';
import { ResponseObjError } from '../../../../classes/requestResults/responseObjError';
import { Observable, ReplaySubject } from 'rxjs';
import { AppSettingsService } from '../../../../services/app-settings.service';
import { LoginComponentDataStorageService } from './login-component-data-storage.service';
import { WorkspaceServiceBase } from 'src/app/components/workspace/workspace.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthMarker } from '../../services/auth-service_marker-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public form = new FormGroup({
    login: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    rememberFlag: new FormControl<boolean>(true, Validators.required),
  });

  /** Сообщение ошибки авторизации */
  public errorMessage: string = '';

  /** Отображать ли форму авторизации */
  public showAuthForm = false;

  /** Происходит ли запрос входа на сервер */
  public isSendRequest = false;

  /** Наименование программы */
  public programName = this.workspaceService.programName;
  public paramCompany: string;
  public marker: AuthMarker;

  private readonly streams$ = { unsubscribe: new ReplaySubject<any>(1) };

  /** Получить компанию для авторизации. Берет либо из строки запроса либо из маркера */
  private get companyForAuth(): Observable<string> {
    return new Observable<string>((subscriber) => {
      this.paramCompany = this.companyFomQueryString;
      this.marker = this.authService.markerStorageService.marker;

      if (!this.paramCompany) {
        if (!this.marker?.company) {
          subscriber.error('Не указана компания');
          return;
        }
        this.paramCompany = this.marker.company;
      } else {
        if (this.marker && this.marker.company != this.paramCompany) {
          subscriber.complete();
          return;
        }
      }

      subscriber.next(this.paramCompany);
      subscriber.complete();
    });
  }

  /** Получить компанию из строки запроса */
  private get companyFomQueryString(): string {
    let company = this.activatedRoute.snapshot.params['company'];
    return !company
      ? null
      : this.authService.jwtHelperService.urlBase64Decode(company);
  }

  constructor(private readonly appSettingsService: AppSettingsService,
              private readonly authService: AuthService,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly dataStorageService: LoginComponentDataStorageService,
              private readonly workspaceService: WorkspaceServiceBase,
              public readonly loadingIndicatorService: LoadingIndicatorService) {
  }

  ngOnInit() {
    if (!this.appSettingsService.program) {
      throw Error('В appSettings не задана программа');
    }

    this.authService.isAuth$
      .pipe(take(1), takeUntil(this.streams$.unsubscribe))
      .subscribe((isAuth) => {
        this.companyForAuth
          .pipe(take(1), takeUntil(this.streams$.unsubscribe))
          .subscribe((company) => {
            if (isAuth) {
              this.router.navigate([this.appSettingsService.workspaceRoute]);
              return;
            }

            this.appSettingsService.company = company;
            this.initFormFromLastAuthUser();
          });
      });
  }

  public onLogout() {
    this.authService.logOut(false);
    this.authService.markerStorageService.marker = null;
    window.location.href = window.location.href;
  }

  /** Обработка события нажатия на "Вход" */
  public onLogin() {
    this.isSendRequest = true;
    const { login, password, rememberFlag } = this.form.value;

    this.loadingIndicatorService
      .addToObservable(
        'Происходит авторизация',
        this.authService.login(login, password),
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isSendRequest = false;

          if (rememberFlag) {
            this.dataStorageService.lastAuthUser = { login, password };
          } else {
            this.dataStorageService.lastAuthUser = null;
          }

          this.showAuthForm = false;
          this.errorMessage = '';

          this.router.navigate([this.appSettingsService.workspaceRoute]);
        },
        error: (error) => {
          this.isSendRequest = false;

          if (ResponseObjError.checkType(error)) {
            const responseObjError = error as ResponseObjError<string>;
            this.errorMessage = responseObjError.data;
          } else {
            this.errorMessage = 'Что то пошло не так';
          }

          console.log(this.errorMessage);
        },
      });
  }

  /** Инициализируем поля формы по последнему авторизируемому пользователю */
  private initFormFromLastAuthUser(): void {
    const lastAuthUser = this.dataStorageService.lastAuthUser;
    if (lastAuthUser) {
      this.form.setValue({
        login: lastAuthUser.login,
        password: lastAuthUser.password,
        rememberFlag: true,
      });
    }
    this.showAuthForm = true;
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
