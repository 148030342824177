import { Injectable, TemplateRef } from "@angular/core";
import { NotificationService, NotificationSettings, Animation } from "@progress/kendo-angular-notification";

/**
 * Сервис для отображения уведомлений кендо
 * Подробнее - https://www.telerik.com/kendo-angular-ui/components/notification/
 */
@Injectable({
  providedIn:'root'
})
export class KendoNotificationService {


  constructor(
    private notificationService: NotificationService
) {
}

/**
 * Настройки анимации по умолчанию для всех типов уведомлений
 */
private _defaultAnimation: Animation = { type: 'fade', duration: 400 };

/**
 * Дефолтные настройки в зависимости от типа
 */
private _defaultSettings: { [type: string]: NotificationSettings }= {

  //По умолчанию, без стилизации
  "default": {
    content: "Default content",
    hideAfter: 4000,
    position: { horizontal: 'right', vertical: 'top' },
    animation: this._defaultAnimation,
    type: { style: 'none', icon: false },
    cssClass: 'notification-class'
  },

  //Успешно
  "success": {
    content: 'Success notification',
    hideAfter: 4000,
    position: { horizontal: 'right', vertical: 'bottom' },
    animation: this._defaultAnimation,
    type: { style: 'success', icon: true },
    cssClass: 'notification-class'
  },

  //Предупреждение
  "warning": {
    content: 'Warning notification',
    hideAfter: 4000,
    position: { horizontal: 'right', vertical: 'bottom' },
    animation: this._defaultAnimation,
    type: { style: 'warning', icon: true },
    cssClass: 'notification-class'
  },

  //Информация
  "info": {
    content: 'Info notification',
    hideAfter: 4000,
    position: { horizontal: 'center', vertical: 'bottom' },
    animation: this._defaultAnimation,
    type: { style: 'info', icon: true },
    cssClass: 'notification-class'
  },

  //Ошибка
  "error": {
    content: 'Error notification',
    hideAfter: 4000,
    position: { horizontal: 'right', vertical: 'bottom' },
    animation: this._defaultAnimation,
    type: { style: 'error', icon: true },
    cssClass: 'notification-class'
  }
}

/**
 * Показать уведомление без стилизации
 */
public showDefault(ns: KendoNotificationSettings): void {
  this.notificationService.show(this.getNotificationSettings("default",ns));
}

/**
 * Показать уведомление со стилизацией успешного выполнения события
 */
public showSuccess(ns: KendoNotificationSettings): void {
  this.notificationService.show(this.getNotificationSettings("success",ns));
}

/**
 * Показать уведомление со стилизацией предупреждения
 */
public showWarning(ns: KendoNotificationSettings): void {
  this.notificationService.show(this.getNotificationSettings("warning",ns));
}

/**
 * Показать уведомление со стилизацией информационного сообщения
 */
public showInfo(ns: KendoNotificationSettings): void {
  this.notificationService.show(this.getNotificationSettings("info",ns));
}

/**
 * Показать уведомление со стилизацией ошибки
 */
public showError(ns: KendoNotificationSettings): void {
  this.notificationService.show(this.getNotificationSettings("error",ns));
}


private getNotificationSettings(type: 'default' | 'success' | 'warning'| 'info'| 'error' , ns: KendoNotificationSettings): NotificationSettings {
  let notificationSettings = this.getDefaultSettings(type);

  if(ns.content) {
    notificationSettings.content = ns.content;
  }

  if(ns.hideAfter) {
    notificationSettings.hideAfter = ns.hideAfter;
  }

  if(ns.closable){
    notificationSettings.closable = ns.closable;
  }

  return notificationSettings;

}

private getDefaultSettings(type: string) {
  const defaultSetting = this._defaultSettings[type];
  return defaultSetting;
}

}

/**
 * Класс настроек уведоилений
 */
export class KendoNotificationSettings {

  /** Контент (строка или TemplateRef<any> или Компонент) */
  content: string | Function | TemplateRef<any>;

  /** Автоматически скрывать после (мс) */
  hideAfter?: number;

  /** Скрывать только нажатием на крестик */
  closable?: boolean;
}
