import { Observable, of, ReplaySubject, shareReplay } from "rxjs";
import { TSettingsValueType, TSettingsValueTypeWithObs } from "../settings-value-types";

/** Сервис для работы с настройками */
export class SettingsReaderService {

  /** Получить значение, которое может быть как функцией так и простым значением */
  public getValue<TValue>(value: TSettingsValueType<TValue>): TValue | null {
    if(value === null || typeof value === "undefined") return null;

    if (value instanceof Function) {
      return value();
    } else {
      return value;
    }
  }

  /** Получить значение, которое может быть функцией, простым значением или  Observable*/
  public getValue$<TValue>(value: TSettingsValueTypeWithObs<TValue>): ReplaySubject<TValue> | null {
    if(value === null || typeof value === "undefined") return null;

    if(value instanceof Observable) {
      return value.pipe(shareReplay(1)) as ReplaySubject<TValue>;
    } else if (value instanceof Function) {
      return of(value()).pipe(shareReplay(1)) as ReplaySubject<TValue>;
    } else {
      return of(value).pipe(shareReplay(1)) as ReplaySubject<TValue>;
    }
  }
}
