<h4>Выберите, какие данные необходимо импортировать:</h4>
<kendo-treeview
  [nodes]="data"
  idField="id"
  textField="text"
  kendoTreeViewFlatDataBinding
  parentIdField="parentId"
  [kendoTreeViewCheckable]="{
    enabled: true,
    mode: 'multiple',
    checkOnClick: true
  }"
  (checkedKeysChange)="onCheckedChanged($event)"
  checkBy="id"
>
</kendo-treeview>
