import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IOccupation extends IEntityVersioning {
  /** Наименование должности */
  name: string;
  /** Код должности */
  code: string;
  /** Тип должности (врач, средний медПерсонал и пр) */
  occupationTypeId: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IOccupationOptional = Partial<IOccupation>;

@classBackend('Occupation', 'stafflist')
@className('Occupation')
/** Модель БД таблицы stafflist.Occupations (Должности) */
export class Occupation implements IOccupation {
  constructor(public id: number,
              public ownerId: number | null,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public actionId: number,
              public orderId: number | null,
              public deletedFlag: boolean,
              public name: string,
              public code: string,
              public occupationTypeId: number | null,
              public timestamp: [],
  ) {
  }

  private static _fullComparer: ObjComparer<Omit<IOccupation, 'timestamp'>>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<Omit<IOccupation, 'timestamp'>>({
        id: true,
        ownerId: true,
        startDate: ObjComparer.dateComparer,
        endDate: ObjComparer.dateComparer,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        comment: true,
        actionId: true,
        orderId: true,
        deletedFlag: true,
        name: true,
        code: true,
        occupationTypeId: true,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IOccupation, 'timestamp' | 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}
