import { IEntityComment } from '../../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../../interfaces/IEntityId';
import { IEntityModify } from '../../interfaces/IEntityModify';
import { IEntityRowVersion } from '../../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface IRequestRow extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityRowVersion {
  requestId: number;
  nomenclatureId: number;
}

@classBackend('RequestRow', 'pfhd_request')
@className('RequestRow')
/** Модель БД таблицы pfhd_request.RequestRow (Строки заявки) */
export class RequestRow implements IRequestRow {
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public comment: string,
              public requestId: number,
              public nomenclatureId: number,
              public timestamp: [],
  ) {
  }
}
