import {IEntityId} from "../../../../../classes/domain/POCOs/interfaces/IEntityId";
import {IEntityRowVersion} from "../../../../../classes/domain/POCOs/interfaces/IEntityRowVersion";

/** Тип объединяет интерфейсы {@link IEntityId} и {@link IEntityRowVersion} */
export type IdAndRowVersionType = IEntityId & IEntityRowVersion;

/** Объект для запроса редактирования даты окончания срока действия */
export class EditEndDateDto{
  constructor(public date: Date,
              public rows: IdAndRowVersionType[],
              public safe: boolean) {
  }
}
