import {ObjectHelper} from "../../helpers/objectHelper";

/**
 * Базовый класс глубокой сериализации объекта.
 * Позволяет заменять объект, или поля в объекте/массиве определенного типа в нужный тип
 */
export abstract class ObjectDeepSerializerServiceBase<T>{

  /** Преобразовать из строки */
  public deserialize(obj: any): any{
    return ObjectHelper.deepReplaceValue(
      obj,
      (value, type) => {
        return type === 'string' ? this.tryParse(value) : undefined;
      }
    )
  };

  /** Преобразовать в строку */
  public serialize(obj: any): any{
    return ObjectHelper.deepReplaceValue(
      obj,
      (value, type) => {
        return this.isType(value) ? this.typeToString(value) : undefined;
      }
    )
  };


  /** Является ли объект типом. Необходимо для {@link serialize} */
  public abstract isType(obj: any): obj is T;

  /** Функция преобразования типа в строку. Необходимо для {@link serialize} */
  public abstract typeToString(obj: T): string;

  /** Распарсить строку. Должен вернуть экземпляр или undefined при неудаче */
  public abstract tryParse(str: string): T | undefined;
}
