import { Injectable } from '@angular/core';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { ComponentServiceBase } from 'src/app/services/abstracts/component-service-base';
import { IDateOnlyDirectoryEditComponent } from '../i-date-only-directory-edit-control.component';

@Injectable()
@traceClass('DateOnlyDirectoryEditComponentService')
export class DateOnlyDirectoryEditComponentService extends ComponentServiceBase<IDateOnlyDirectoryEditComponent> {

  constructor(private tracer: TracerServiceBase) {
    super();
  }

  /** Считывание значений из настроек */
  @traceFunc()
  public readSettings() {
  }

}
