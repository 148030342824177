import {Injectable} from "@angular/core";
import {WebApi1Service} from "../web-api1.service";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {DayType} from "../../../../classes/domain/POCOs/timesheet/DayType";
import {shareReplay} from "rxjs/operators";

type GetAllType = Pick<DayType, 'id' | 'name' | 'color'>;

/** Сервис работы с контроллером DayTYpeController */
@Injectable({
  providedIn: 'root',
})
export class Api1DaysTypeControllerService {
  private _getAll: Observable<GetAllType[]>


  /** Получить все типы дней(используется кеш) */
  public get getAll$(){
    if(!this._getAll){
      this._getAll = this.httpClient.post<GetAllType[]>(
        this.webApi1Service.controllers.dayType.actions.getAll.toString(),
        {}
      ).pipe(
        shareReplay(1)
      )
    }

    return this._getAll;
  }

  constructor(private readonly webApi1Service: WebApi1Service,
              private readonly httpClient: HttpClient) {
  }

}
