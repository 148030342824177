import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Employee } from 'src/app/classes/domain/POCOs/stafflist/Employee';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { AddEmployeeFormComponentService } from './services/add-employee-form-component.service';
import { CustomFormValidators } from '../../../../../../../src/app/validators/custom-form-validators.class';
import { combineLatest, filter, map, startWith, switchMap } from 'rxjs';
import { exDistinctUntilChanged } from '../../../../../../../src/app/operators/ex-distinct-until-changed.operator';
import { takeUntil } from 'rxjs/operators';
import { AddEmployeeFormComponentDataService } from './services/add-employee-form-component-data.service';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { AddEmployeeDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-controller.service';
import { GenderEnum } from '../../../../../../../src/app/classes/domain/enums/gender-enum';

@Component({
  selector: 'app-add-employee-form',
  templateUrl: './add-employee-form.component.html',
  styleUrls: ['./add-employee-form.component.css'],
  providers: [AddEmployeeFormComponentService, AddEmployeeFormComponentDataService],
})
@traceClass('AddEmployeeFormComponent')
export class AddEmployeeFormComponent implements OnInit, OnDestroy {

  public form: FormGroup<AddEmployeeDTOForm>;
  @Input('dataItem') public dataItem: AddEmployeeDTO;
  @Output('onSave') public onSaveEventEmitter = new EventEmitter<AddEmployeeDTO>();
  @Output('onCancel') public onCancelEventEmitter = new EventEmitter<boolean>();
  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private service: AddEmployeeFormComponentService, private traceService: TracerServiceBase) {

  }

  @traceFunc()
  ngOnInit() {
    this.form = this.createForm(this.dataItem);
  }

  /** Событие происходит при клике на кнопку сохранения */
  @traceFunc()
  public onClickSave() {
    // Пустое значение должно быть null
    if (!this.form.value.patronymic) this.form.value.patronymic = null;

    this.onSaveEventEmitter.emit(this.form.value as AddEmployeeDTO);
  }

  /** Событие происходит при клике на кнопку отмены */
  @traceFunc()
  public onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  /** Создание формы добавления*/
  @traceFunc()
  private createForm(item: AddEmployeeDTO) {
    const form = new FormGroup({
      startDate: new FormControl(item?.startDate ?? null, [CustomFormValidators.validateStartDateWithEndDate('endDate')]),
      endDate: new FormControl(item?.endDate ?? null, [CustomFormValidators.validateEndDateWithStartDate('startDate')]),
      code: new FormControl(item?.code ?? '', [this.service.codeValidator, Validators.maxLength(20)]),
      gender: new FormControl(item?.gender ?? GenderEnum.Male, [Validators.required]),
      birthday: new FormControl(item?.birthday ?? null),
      profUnion: new FormControl(item?.profUnion ?? false),
      lastName: new FormControl({ value: item?.lastName ?? '', disabled: false }, { validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)], updateOn: 'blur' }),
      firstName: new FormControl({ value: item?.firstName ?? '', disabled: false }, { validators: [Validators.required, Validators.minLength(2), Validators.maxLength(30)], updateOn: 'blur' }),
      patronymic: new FormControl({ value: item?.patronymic ?? '', disabled: false }, { validators: [Validators.minLength(2), Validators.maxLength(30)], updateOn: 'blur' }),
      comment: new FormControl(item?.comment ?? '', Validators.maxLength(100)),
      isAutogenerateCode: new FormControl(false),
      declensions: new FormControl({ value: null, disabled: true }),
    });

    form.markAllAsTouched();

    form.valueChanges.subscribe(value => {
      this.traceService.add2(`Изменена форма добавления сотрудника`, { obj: value });
    });

    combineLatest([
      form.controls.lastName.valueChanges.pipe(exDistinctUntilChanged(form.controls.lastName.value)),
      form.controls.firstName.valueChanges.pipe(exDistinctUntilChanged(form.controls.firstName.value)),
      form.controls.patronymic.valueChanges.pipe(exDistinctUntilChanged(form.controls.patronymic.value), startWith('')),
    ]).pipe(
      map(([lastName, firstName, patronymic]) => ({ lastName, firstName, patronymic })),
      filter(v => !!v.lastName && !!v.firstName),
      switchMap(v => this.service.generateEmployeeNamesDeclensionsForEmployeeFullName$(Employee.fullName(v).trim())),
      takeUntil(this.streams$.unsubscribes),
    ).subscribe(v => {
      form.controls.declensions.setValue(v);
      if (form.controls.declensions.disabled) {
        form.controls.declensions.enable();
      }
    });

    return form;
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}

export type AddEmployeeDTOForm = PropertyWrapFormControlType<AddEmployeeDTO>;
