import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import { CellClickExpandedEvent, CellMouseEvent, GridTreeListExpandedDirectiveService, TitleHorizontalAlignType, TitleVerticalAlignType } from "../services/directives/grid-treelist-expanded-directive.service";

/**
 * Директива расширающая компонент kendo grid
 */
@Directive({
  selector: '[KendoGridExpanded]',
  providers: [GridTreeListExpandedDirectiveService]
})
export class KendoGridExpandedDirective implements OnInit, AfterContentInit {

  /** Событие клика по ячейке (расширенное) */
  @Output() cellClickExpanded = new EventEmitter<CellClickExpandedEvent<CellClickEvent>>();

  /** Событие двойного клика по ячейке */
  @Output() dbCellClick = new EventEmitter<CellClickExpandedEvent<CellClickEvent>>();

  /** Событие наведения на ячейку таблицы*/
  @Output() get cellMouseOver(): EventEmitter<CellMouseEvent> {
    return this.directiveService.cellMouseOver;
  }

  /** Событие отведения мыши от ячейки таблицы */
   @Output() get cellMouseOut(): EventEmitter<CellMouseEvent> {
    return this.directiveService.cellMouseOut;
  }

  /**
   * Переносить ли текст в шапке таблицы (если он не помещается)
   */
  @Input("tableTitleTextWrap") public set tableTitleTextWrap(value: boolean) {
    this.directiveService.tableTitleTextWrap = value;
  }
  public get tableTitleTextWrap() : boolean {
    return this.directiveService.tableTitleTextWrap;
  }

  /** Вертикальное расположение текста в шапке таблицы: 'top' или 'middle' или 'bottom' */
  @Input("tableTitleVerticalAlign") public set tableTitleVerticalAlign(value: TitleVerticalAlignType) {
    this.directiveService.tableTitleVerticalAlign = value;
  }
  public get tableTitleVerticalAlign() : TitleVerticalAlignType {
    return this.directiveService.tableTitleVerticalAlign;
  }

  /** Padding для шапки грида. Для колонки шапки будет добавлен стиль padding: ЗНАЧЕНИЕ */
  @Input("headerColumnsPadding") public set headerColumnsPadding(paddingValue: string) {
    this.directiveService.headerColumnsPadding = paddingValue;
  }
  public get headerColumnsPadding() : string {
    return this.directiveService.headerColumnsPadding;
  }

  /** Padding для колонок грида. Для колонки будет добавлен стиль padding: ЗНАЧЕНИЕ */
  @Input("columnsPadding") public set columnsPadding(paddingValue: string) {
    this.directiveService.columnsPadding = paddingValue;
  }
  public get columnsPadding() : string {
    return this.directiveService.columnsPadding;
  }

  /** Горизонтальное расположение текста в шапке таблицы: 'left' или 'center' или 'right' */
  @Input("tableTitleHorizontalAlign") public set tableTitleHorizontalAlign(value: TitleHorizontalAlignType) {
    this.directiveService.tableTitleHorizontalAlign = value;
  }
  public get tableTitleHorizontalAlign() : TitleHorizontalAlignType {
    return this.directiveService.tableTitleHorizontalAlign;
  }

  /** Прокручивать скролл до выделенного. Необходимо при инициализации Таблицы */
  @Input() public set scrollIntoSelect(value: boolean){
    this.directiveService.scrollIntoSelect = value;
  }
  public get scrollIntoSelect() : boolean {
    return this.directiveService.scrollIntoSelect;
  }

  /** Обновить скролл прокруткой до выделенного элемента */
  @Input() public set updateScrollIntoSelect(value: any){
    this.directiveService.updateScrollIntoSelect = value;
  }

  /** Прокрутить скролл до строки по индексу */
  @Input() public set scrollToRowByIndex(value: number){
    this.directiveService.scrollIntoRowByIndex(value);
  }

  /** Прокрутить скролл до строки по индексу */
  @Input() public set scrollToRow(value: any){
    this.directiveService.scrollIntoRow(value);
  }

  constructor(private el: ElementRef, private grid: GridComponent, private directiveService: GridTreeListExpandedDirectiveService) {
    directiveService.init(grid, this.el, true);
  }

  /**
   * Прокрутить до нужного элемента
   * @param dataItem элемент до которого нужно прокрутить
   * @param compareFn функция сравнения, по умолчанию == (но чаще всего сравнение по ссылке не подходит)
   */
  public scrollToDataItem<TDataItem>(dataItem: TDataItem, compareFn?:(i1: TDataItem, i2: TDataItem) => boolean){
    this.directiveService.scrollIntoRow(dataItem, compareFn);
  }

  ngOnInit(): void {
    this.directiveService.registerCellClickForCellClickExpandedEvent(this.grid.cellClick, this.cellClickExpanded)
    this.directiveService.registerCellClickForDoubleCellClickEvent(this.cellClickExpanded, this.dbCellClick);
  }

  ngAfterContentInit() {
    this.directiveService.ngAfterContentInit();
  }

}
