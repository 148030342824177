import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, combineLatestWith, defer, map, merge, Observable, ReplaySubject, shareReplay, switchMap, take, takeUntil } from 'rxjs';
import { CellClickEvent, DataBindingDirective } from '@progress/kendo-angular-grid';
import { StaffPositionGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-staff-positions-control.service';
import { KendoGridExpandedDirective } from 'src/app/directives/kendo-grid-expanded.directive';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TraceParamEnum } from 'src/app/modules/trace/decorators/classes/traceSetting.interface';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { versioningAndNotVersioningDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import { PositionsGridComponentDataSourceServiceBase } from './services/positions-grid-component-data-source.service';
import { KendoGridArrayDataSourceSelection } from 'src/app/classes/array-data-sources/selections/kendo-grid-array-data-source-selection';
import { PositionsGridComponentService } from './services/positions-grid-component.service';
import { DbChangedListener } from 'src/app/services/signal-r/listeners/db-changed-listener';
import { ForDate } from 'src/app/classes/for-date';
import { exCreateService$ } from 'src/app/operators/ex-create-service.operator';
import { NotVersioningField } from 'src/app/components/directory-edit/services/classes/fields/not-versioning-field';
import { DropDownListFieldControl } from 'src/app/components/directory-edit/services/classes/field-controls/dropdownlist-field-control';
import { VersioningField } from 'src/app/components/directory-edit/services/classes/fields/versioning-field';
import { TextAreaFieldControl } from 'src/app/components/directory-edit/services/classes/field-controls/text-area-field-control';
import { DateFieldControl } from 'src/app/components/directory-edit/services/classes/field-controls/date-field-control';
import { CheckboxFieldControl } from 'src/app/components/directory-edit/services/classes/field-controls/checkbox-field-control';
import { NumberFieldControl } from 'src/app/components/directory-edit/services/classes/field-controls/number-field-control';
import { xnameofPath } from 'src/app/functions/nameof';
import { FieldType } from 'src/app/components/directory-edit/services/classes/fields/field-type';
import { DirectoryEditRef } from 'src/app/components/directory-edit/services/ref-services/directory-edit-ref';
import { DirectoryEditService } from 'src/app/components/directory-edit/services/directory-edit.service';
import { trace } from 'src/app/modules/trace/operators/trace';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { OccupationDataSourceService } from 'src/app/services/common-data-source-services/occupation-data-source.service';
import { WorkModeDataSourceService } from 'src/app/services/common-data-source-services/work-mode-data-source.service';
import { ReportPeriodDataSourceService } from 'src/app/services/common-data-source-services/report-period-data-source.service';
import { FinancingSourceDataSourceService } from 'src/app/services/common-data-source-services/financing-source-data-source.service';
import { SubdivisionDataSourceService } from 'src/app/services/common-data-source-services/subdivision-data-source.service';
import {WorkModeTypeEnum} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkModeType";
import { exElementByIndexOr } from 'src/app/operators/ex-element-by-index-or';
import { HierarchiStringsConflictsDialogService } from 'src/app/components/hierarchi-strings-conflicts/services/hierarchi-strings-conflicts-dialog.service';
import { StorageLocationEnum, StorageOptions } from 'src/app/services/storages/custom-storage.service';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

@Component({
  selector: 'app-positions-grid',
  templateUrl: './positions-grid.component.html',
  styleUrls: ['./positions-grid.component.css'],
  providers: [
    versioningAndNotVersioningDirectoryServiceProvider,
    PositionsGridComponentService,
    HierarchiStringsConflictsDialogService
  ]
})
@traceClass('PositionsGridComponent')
export class PositionsGridComponent implements OnInit, OnDestroy {

  private _dataSource: PositionsGridComponentDataSourceServiceBase;
  @Input() public get dataSource():PositionsGridComponentDataSourceServiceBase {
    return this._dataSource;
  }
  public set dataSource(value: PositionsGridComponentDataSourceServiceBase){
    if(this._dataSource) {
      throw Error("Повторная установка dataSource не поддерживается!");
     }
    this._dataSource = value;
  }

  private _selection: KendoGridArrayDataSourceSelection<StaffPositionGridItem, number>;
  @Input() public get selection():KendoGridArrayDataSourceSelection<StaffPositionGridItem, number> {
    return this._selection;
  }
  public set selection(val: KendoGridArrayDataSourceSelection<StaffPositionGridItem, number>){
    if(this._selection) {
      throw Error("Повторная установка selection не поддерживается!");
    }
    this._selection = val;
  }

  @ViewChild(KendoGridExpandedDirective) public kendoGridExpandedDirective: KendoGridExpandedDirective;
  @ViewChild(DataBindingDirective) public dataBindingDirective: DataBindingDirective;

  /** Верхний и нижний элемент */
  public upItem$: Observable<StaffPositionGridItem>;
  public downItem$: Observable<StaffPositionGridItem>;

  /** Ключи названий всех колонок */
  public fieldsNames = xnameofPath(StaffPositionGridItem, '');
  public staffUnitFieldGroupColumnName = 'Штатные единицы';

  /** Настройки сортировки */
  public sort: SortDescriptor[] = [{
    field: this.fieldsNames.occupationTitle.toString(),
    dir: 'asc'
  }];

  /** Настройки сортировки */
  public sortStorageOptions = new StorageOptions(
    StorageLocationEnum.LocalStorage,
    `PositionsGridComponent_sortOptions`,
    null, false, false);

  //Сортировка по умолчанию если убрать сортировку, по умочанию по sortOrder
  public defaultUnsort: SortDescriptor[] = [{
    field: this.fieldsNames.sortOrder.toString(),
    dir: 'asc'
  }]

  /** Стримы ширины колонок */
  public staffUnitColumnWidth$: Observable<number>;
  public freeStaffUnitColumnWidth$: Observable<number>;

  /** Стримы колонок занятых и свободных ставок */
  public positionRateColumns$: Observable<FinancingSourcesColumnsInfo[]>;
  public positionFreeRateColumns$: Observable<FinancingSourcesColumnsInfo[]>;

  /** Стримы скрытия-отображения пустых занятых/свободных ставок */
  public isHideStaffUnitsEmptyColumns$ = new BehaviorSubject(true);
  public isHideFreeStaffUnitsEmptyColumns$ = new BehaviorSubject(true);

  /** Стримы необходимых датасорс сервисов */
  public occupationDataSourceService$: Observable<OccupationDataSourceService>;
  public workModeDataSourceService$: Observable<WorkModeDataSourceService>;
  public reportPeriodDataSourceService$: Observable<ReportPeriodDataSourceService>;
  public financingSourceDataSourceService$: Observable<FinancingSourceDataSourceService>;
  public subdivisionDataSourceService$: Observable<SubdivisionDataSourceService>;

  public searchValue: string = '';
  public expandedDetailKeys: any[] = [];

  public expandDetailsBy = (dataItem: StaffPositionGridItem): any => dataItem.id;

  public rateStep: number = 0.25;

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) }

  /** Минимальная ширина колонки группирующей все StaffUnit */
  private minStaffUnitsColumnGroupSize = 130;
  /** Минимальная ширина колонки StaffUnit */
  private minStaffUnitsColumnSize = 65;

  /** Настройки колонок для сервиса редактирования Position */
  private readonly positionFields:Array<FieldType> = [
    new NotVersioningField(this.fieldsNames.occupationId.toString(), { isAllowNull: false, title: "Должность" }, new DropDownListFieldControl({ virtual: { itemHeight: 28 }, filterSettings: { caseSensitive: false, operator: 'contains' }, textField: 'name', data: defer(() => this.occupationDataSourceService$.pipe(switchMap(s=> s.reloadData$({...ForDate.Get(this.directoryEditPositionServiceRef.currentDataItem), occupationIds: null}).pipe(switchMap(d=> d.data2$))), takeUntil(this.directoryEditPositionServiceRef.onEndEdit$))) })),
    new NotVersioningField(this.fieldsNames.workModeId.toString(), { isAllowNull: false, title: "Режим работы", styleSettings: {minWidth: 500} }, new DropDownListFieldControl({ textField: 'name', data: defer(() => this.workModeDataSourceService$.pipe(switchMap(s=> s.reloadData$({...ForDate.Get(this.directoryEditPositionServiceRef.currentDataItem), workModesIds: null}).pipe(switchMap(d=> d.data2$))), takeUntil(this.directoryEditPositionServiceRef.onEndEdit$))) })),
    new NotVersioningField(this.fieldsNames.comment.toString(), { isAllowNull: true, title: "Комментарий" }, new TextAreaFieldControl({ maxLength: 100, autoTrimValue: true })),
    new NotVersioningField(this.fieldsNames.startDate.toString(), { isAllowNull: true, title: "Дата начала" }, new DateFieldControl({ maxValue: () => this.directoryEditPositionServiceRef.currentDataItem.endDate, minValue: () => this.directoryEditPositionServiceRef.currentDataItem.subdivision.startDate })),
    new NotVersioningField(this.fieldsNames.endDate.toString(), { isAllowNull: true, title: "Дата окончания" }, new DateFieldControl({ minValue: () => this.directoryEditPositionServiceRef.currentDataItem.startDate, maxValue:  () => this.directoryEditPositionServiceRef.currentDataItem.subdivision.endDate  })),

    new VersioningField(this.fieldsNames.leaderFlag.toString(), SupportedActionEnum.stafflist_position_leader_flag, { isAllowNull: false, title: "Является руководителем подразделения" }, new CheckboxFieldControl()),
    new VersioningField(this.fieldsNames.dinnerHourDuration.toString(), SupportedActionEnum.stafflist_position_dinner_hour_duration, { isAllowNull: false, title: "Продолжительность обеда (ч.)" }, new NumberFieldControl({ minValue: 0, maxValue: 5.0, decimals: 1, format: 'n1', step: 0.1 })),
    new VersioningField(this.fieldsNames.subtractLunchTimeFromWorkingHoursFlag.toString(), SupportedActionEnum.stafflist_position_subtract_lunch_time_from_working_hours_flag, { isAllowNull: false, title: "Вычитать продолжительность обеденного перерыва из рабочего времени" }, new CheckboxFieldControl()),
    new VersioningField(this.fieldsNames.includeScheduleForPaidServiceFlag.toString(), SupportedActionEnum.stafflist_position_include_schedule_for_paid_service_flag, { isAllowNull: false, title: "Включать в график приема по платным услугам" }, new CheckboxFieldControl()),
    new VersioningField(this.fieldsNames.reportPeriodTitle.toString(), SupportedActionEnum.stafflist_position_report_period_id, { isAllowNull: false, title: "Период контроля", displayValueFunc: () => this.directoryEditPositionServiceRef.currentDataItem.reportPeriodTitle }, new DropDownListFieldControl({ textField: 'name', data: defer(() => this.reportPeriodDataSourceService$.pipe(switchMap(s=> s.reloadData$({reportPeriodsIds: null}).pipe(switchMap(d=> d.data$))), takeUntil(this.directoryEditPositionServiceRef.onEndEdit$))) }))
  ];

  /** Настройки колонок для сервиса редактирования PositionRate */
  private readonly positionRateFields = [
    new VersioningField("rate", SupportedActionEnum.stafflist_positionRate_rate, { isAllowNull: false, title: 'Количество ставок', isAllowEditRootStartEndData: true,
      minParentDateValueFunc: () => this.directoryEditPositionRateServiceRef.currentDataItem?.startDate ?? this.directoryEditPositionRateServiceRef.currentDataItem?.subdivision?.startDate,
      maxParentDateValueFunc: () => this.directoryEditPositionRateServiceRef.currentDataItem?.endDate ?? this.directoryEditPositionRateServiceRef.currentDataItem?.subdivision?.endDate,
      dialogTitle: () => `Редактирование "Источник финансирования ${this.directoryEditPositionRateServiceRef.currentDataItem.financingSourceShortName}"` }, new NumberFieldControl(() => ({ minValue: 0, maxValue: 1000.0, multipleOf: this.rateStep, decimals: 2, format: 'n2', step: this.rateStep }))
    )
  ];

  /** Ref сервисы редактирования колонок */
  private directoryEditPositionServiceRef: DirectoryEditRef;
  private directoryEditPositionRateServiceRef: DirectoryEditRef;


  constructor(private readonly dbChangedListener: DbChangedListener,
              private readonly service: PositionsGridComponentService,
              private readonly directoryEditService: DirectoryEditService,
              private readonly hscDialogService: HierarchiStringsConflictsDialogService,
              private readonly tracerService: TracerServiceBase,
     ) {

  }

  @traceFunc()
  ngOnInit() {
    this.initDirectoryEditPositionService();
    this.initPositionRateDirectoryEditService();
    this.loadRateStep();

    //Нижний элемент от выделенного (если он есть)
    this.downItem$ = combineLatest([
      this.dataSource.dataSource.data$.pipe(map(m => [...m].sort((n1, n2) => n1.sortOrder - n2.sortOrder))),
      this.selection.selectedItems2.data2$.pipe(exElementByIndexOr())
    ]).pipe(map(m => m[1] ? m[0].find(f => f.sortOrder > m[1].sortOrder) : null), shareReplay(1))

    //Верхний элемент от выделенного (если он есть)
    this.upItem$ = combineLatest([
      this.dataSource.dataSource.data$.pipe(map(m => [...m].sort((n1, n2) => n2.sortOrder - n1.sortOrder))),
      this.selection.selectedItems2.data2$.pipe(exElementByIndexOr())
    ]).pipe(map(m => m[1] ? m[0].find(f => f.sortOrder < m[1].sortOrder) : null), shareReplay(1));

    // Сервисы для выпадающих список при редактировании, формы добавления и т.д
    this.occupationDataSourceService$ = exCreateService$(() => new OccupationDataSourceService(this.dataSource, this.dbChangedListener));
    this.workModeDataSourceService$ = exCreateService$(() => new WorkModeDataSourceService(this.dataSource, this.dbChangedListener));
    this.reportPeriodDataSourceService$ = exCreateService$(() => new ReportPeriodDataSourceService(this.dataSource, this.dbChangedListener));
    this.financingSourceDataSourceService$ = exCreateService$(() => new FinancingSourceDataSourceService(this.dataSource, this.dbChangedListener));
    this.subdivisionDataSourceService$ = exCreateService$(() => new SubdivisionDataSourceService(this.dataSource, this.dbChangedListener));

    //Общий observable со всеми колонками источников финансирования
    const allFinancingSourcesColumnsInfos$ = this.dataSource.dataSource.data$.pipe(
      map(data => this.service.getFinancingSourcesColumnsInfos(data)),
      shareReplay()
    );

    //observable с колонками занятых ставок
    this.positionRateColumns$ = allFinancingSourcesColumnsInfos$.pipe(
      combineLatestWith(this.isHideStaffUnitsEmptyColumns$),
      map(value => value[1] === true ? value[0].filter(f=> f.hasRates === true) : value[0]),
      shareReplay(1)
    );

    //observable с колонками вакантных ставок
    this.positionFreeRateColumns$ = allFinancingSourcesColumnsInfos$.pipe(
      combineLatestWith(this.isHideFreeStaffUnitsEmptyColumns$),
      map(value => value[1] === true ? value[0].filter(f=> f.hasFreeRates === true) : value[0]),
      shareReplay(1)
    );

    //observable со значением ширины колонок занятых ставок
    this.staffUnitColumnWidth$ = this.positionRateColumns$.pipe(map(columns => this.service.calculateStaffUnitColumnWidth(columns, this.minStaffUnitsColumnSize, this.minStaffUnitsColumnGroupSize)))

    //observable со значением ширины колонок вакантных ставок
    this.freeStaffUnitColumnWidth$ = this.positionFreeRateColumns$.pipe(map(columns => this.service.calculateStaffUnitColumnWidth(columns, this.minStaffUnitsColumnSize, this.minStaffUnitsColumnGroupSize)))

    //подписываемся на изменения колонок и делаем переинициализацию паддингов
    //лучше способа не нашел, т.к. у кендо грида нет события изменения структуры (добавления/удаления колонки и т.д)
    //поэтому сделать это в самой директиве не получится
    merge(this.positionRateColumns$, this.positionFreeRateColumns$).subscribe(() => {
      next: {
        this.reinitColumnsPaddings();
      }
    });

    this.sort = this.defaultUnsort;
  }

  public getRatesDisplayValue(column: StaffPositionGridItem, financingSourceId: number) {
    return this.service.getRatesDisplayValue(column.staffPositionRates, financingSourceId)
  }

  public getFreeRatesDisplayValue(column: StaffPositionGridItem, financingSourceId: number) {
    return this.service.getRatesDisplayValue(column.staffPositionFreeRates, financingSourceId)
  }

  /** Функция фильтрации (поиска) */
  @traceFunc()
  public onFilter(): void {
    if (!this.dataSource.dataSource.data) return;

    const inputValue = this.searchValue;
    this.tracerService.add2(`Фильтрация`, { obj: this.searchValue });

    const filter = <CompositeFilterDescriptor>{
      logic: "or",
      filters: [
        {
          field: this.fieldsNames.occupationTitle.toString(),
          operator: 'contains',
          value: inputValue
        }
      ],
    };

    this.dataBindingDirective.skip = 0;
    this.dataBindingDirective.filter = filter;
    this.dataBindingDirective.rebind()
  }

  public sortChange(sort: SortDescriptor[]): void {
    //сортировка была сброшена, ставим сортировку по умолчанию
    if(!sort.some(s=> s.dir)) {
      this.sort = this.defaultUnsort;
    } else {
      this.sort = sort;
    }
  }

  @traceFunc()
  public startEditButtonClick(fieldName: string, dataItem: StaffPositionGridItem, e: PointerEvent) {
    this.directoryEditPositionServiceRef.edit(fieldName, dataItem, e.currentTarget);
  }

  @traceFunc()
  public onClickAdd() {
    this.service.showAddPositionDialog(this);
  }

  @traceFunc()
  public onClickDelete() {
    this.service.showRemovePositionDialog(() => this.dataSource.deletePosition$(this.selection.selectedItems.data[0]))
  }

  /** Событие происходит при двойном клике на ячейку */
  @traceFunc({ traceParamType: TraceParamEnum.notTrace })
  public onDblCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    if($event.originalEvent.isEdited) return;

    //Если клик был на колонки штатных единиц
    if($event.originalEvent.column.parent?.title === this.staffUnitFieldGroupColumnName){
      this.positionRateColumns$.pipe(take(1)).subscribe(allStaffPositionsColumns => {

        //Ищем колонку по титлу
        const foundedSpr = allStaffPositionsColumns.find(s => s.shortName === $event.originalEvent.column.title);

        //Если колонка найдена то начинаем редактирование/добавление
        if(foundedSpr) {
          const rate = $event.originalEvent.dataItem.staffPositionRates.find(r => r.financingSourceId === foundedSpr.financingSourceId);
          //Ставка есть - значит редактируем
          if (rate?.rateId) {
            this.directoryEditPositionRateServiceRef.edit("rate", StaffPositionRateItem.Get($event.originalEvent.dataItem, rate), null);
          }
          //Ставки нет - значит добавляем
          else {
            this.service.showAddPositionRateDialog(this, foundedSpr.financingSourceId, $event.originalEvent.dataItem);
          }
        }
      });
    }
    //Редактируем колонку связанную с позицией
    else {
      let fieldForEdit = $event.originalEvent.column.field;
      if(fieldForEdit === this.fieldsNames.occupationTitle.toString()) {
        fieldForEdit = this.fieldsNames.occupationId.toString();
      }

      this.directoryEditPositionServiceRef.edit(fieldForEdit, $event.originalEvent.dataItem, $event.originalEvent.originalEvent.target);
    }
  }

  public toggleStaffUnitsEmptyColumns(){
    this.isHideStaffUnitsEmptyColumns$.next(!this.isHideStaffUnitsEmptyColumns$.getValue());
  }

  public toggleFreeStaffUnitsEmptyColumns(){
    this.isHideFreeStaffUnitsEmptyColumns$.next(!this.isHideFreeStaffUnitsEmptyColumns$.getValue());
  }

  public onClickReorderUp() {
    this.upItem$.pipe(take(1)).subscribe({next: upItem=> {
      this.dataSource.reorder$(this.selection.selectedIds.data[0], upItem.id, this.dataSource.paramsDataSource.data.forDate)
        .pipe(take(1), takeUntil(this.streams$.unsubscribes)).subscribe();
    }});
  }

  public onClickReorderDown() {
    this.downItem$.pipe(take(1)).subscribe({next: downItem=> {
      this.dataSource.reorder$(this.selection.selectedIds.data[0], downItem.id, this.dataSource.paramsDataSource.data.forDate)
        .pipe(take(1), takeUntil(this.streams$.unsubscribes)).subscribe();
    }});
  }

  /** Относится ли переданная параметром должность к гибкому режиму работы */
  public isFlexibleWorkMode(item: StaffPositionGridItem) {
    return item.workModeTypeId === WorkModeTypeEnum.flexibleGraph; }

  private loadRateStep() {
    this.dataSource.settingsRateStep$()
    .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes))
    .subscribe(value => {
      this.rateStep = value;
    });
  }

  /** Инициализация сервиса редактирования */
  @traceFunc()
  private initDirectoryEditPositionService() {
    this.directoryEditPositionServiceRef = this.directoryEditService.init({
      addVersion$: (actionId, versioningEntity) => this.dataSource.addVersionPosition$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataSource.deleteVersionPosition$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSource.getVersionsByOwnerIdAndActionIdPosition$(ownerId, actionId),
      editVersion$: (actionId, entity) => this.dataSource.editVersionPosition$(actionId, entity),
      saveNoVersioning$: (entity:any, field: NotVersioningField) => {
        if(field.name === this.fieldsNames.endDate.toString()){
          return this.hscDialogService.show2$((isSafe) => this.dataSource.editEndDatePosition$(entity, isSafe));
        }
        else
        {
          return this.dataSource.saveNoVersioningPosition$(entity);
        }
      },

    }, this.positionFields);
    this.directoryEditPositionServiceRef.closeOnCurrentEditingDataItemChanged$(this.dataSource.dataSource);
  }

  /** Инициализация сервиса PositionRate редактирования */
  @traceFunc()
  private initPositionRateDirectoryEditService() {
    // Editable options for StaffPositions table
    this.directoryEditPositionRateServiceRef = this.directoryEditService.initVersioningOnly({
      addVersion$: (actionId, versioningEntity) => this.dataSource.addVersionPositionRate$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataSource.deleteVersionPositionRate$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSource.getVersionsByOwnerIdAndActionIdPositionRate$(ownerId, actionId),
      editVersion$: (actionId, entity) => this.dataSource.editVersionPositionRate$(actionId, entity),

      ownerIdGetter: (dataItem: StaffPositionRateItem) => dataItem.rateId
    }, this.positionRateFields);

    this.directoryEditPositionRateServiceRef.closeOnCurrentEditingDataItemChanged$(this.dataSource.dataSource);
  }

  private reinitColumnsPaddings() {
    setTimeout(() => {
      this.kendoGridExpandedDirective.columnsPadding = this.kendoGridExpandedDirective.columnsPadding;
      this.kendoGridExpandedDirective.headerColumnsPadding = this.kendoGridExpandedDirective.headerColumnsPadding;
    });
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}

export class FinancingSourcesColumnsInfo {
  constructor(public financingSourceId: number,
              public shortName: string,
              public hasRates: boolean,
              public hasFreeRates: boolean) { }
}

export class StaffPositionRateItem extends StaffPositionGridItem{

  public rateId: number;
  public financingSourceShortName: string;

  public static Get(dataItem: StaffPositionGridItem, rate: StaffPositionGridItem["staffPositionRates"][0]): StaffPositionRateItem {
    if (rate?.rateId) {
      return <StaffPositionRateItem>{...dataItem, rateId: rate.rateId, financingSourceShortName: rate.financingSourceShortName};
    } else {
      throw new Error(`${xnameofPath(rate.rateId)} обязательно для заполнения`);
    }
  }
}
