<kendo-treeview [nodes]="dataSource$ | async"
                textField="text"
                kendoTreeViewHierarchyBinding
                childrenField="dataItems"
                [filterable]="true"
                [kendoTreeViewCheckable]="checkableSettings"
                [checkBy]="'id'"
                kendoTreeViewSelectable
                [checkedKeys]="selectionService.tempSelection?.data?.forDirective?.selectedKeys"
                (checkedKeysChange)="selectionService.tempSelection?.data?.forDirective?.onSelectedKeysChange($event)"
                kendoTreeViewExpandable
                [expandedKeys]="expandedKeys"
                [expandBy]="'id'">
</kendo-treeview>
