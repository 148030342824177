import {Injectable} from "@angular/core";
import {ObjectDeepSerializerServiceBase} from "../object-deep-serializer-service-base";
import {DateHelper} from "../../../helpers/dateHelper";
import {AppSettingsService} from "../../app-settings.service";

/**
 * Сервис глубокой сериализации/десериализации {@link Date} по переданному формату
 */
export class DateDeepSerializerService extends ObjectDeepSerializerServiceBase<Date>{

  /**
   * Конструктор
   * @param parseFormat формат/форматы для парсинга
   * @param serializeFormat формат для вывода в строку
   */
  constructor(public readonly parseFormat: string | string[], public readonly serializeFormat: string) {
    super();
  }

  public isType(obj: any): obj is Date {
    return DateHelper.isDate(obj);
  }
  public typeToString(date: Date): string {
    return DateHelper.toFormat(date, this.serializeFormat);
  }
  public tryParse(str: string): Date {
    return DateHelper.tryParseByFormat(str, this.parseFormat);
  }
}

/**
 * Сервис глубокой сериализации/десериализации {@link Date} в формат сервера
 */
@Injectable({
  providedIn: "root"
})
export class DateSerializerToServerFormatService extends DateDeepSerializerService{
  constructor(appSettingsService: AppSettingsService) {
    super(appSettingsService.webApiFormatSettings.date, appSettingsService.webApiFormatSettings.date);
  }
}
