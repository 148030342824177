
/** Интерфейс базовых настроек трассировки */
export interface ITraceSettings{
  /** Отключить трассировку */
  disabled?: boolean | (() => boolean);
}

/** Интерфейс настроек декоратора имеющих описание (для декораторов метода/класса) */
export interface ITraceHasDescription{
  /** Описание */
  description?: string;
}

/** Интерфейс настроек декоратора имеющих возможноть управлять выводами параметров (для декораторов метода/класса) */
interface IHasTraceParamType{
  /** Управление выводом параметров */
  traceParamType?: TraceParamEnum
}

/** Интерфейс настроек декоратора имеющих ограничение по максимальной длине вывода */
interface IHasMaxLength{
  /** Максимальная длина параметра для вывода */
  maxLength?: number
}

/** Интерфейс параметров Trace.add */
export interface ITraceAddParams<T> extends ITraceSettings, IHasMaxLength, ITraceHasDescription{
  obj: T,
  map?: (T) => any,
}

/** Настройки декоратора функций */
export interface ITraceClassSettings extends ITraceSettings, ITraceHasDescription, IHasTraceParamType{
}

/** Настройки декоратора функций */
export interface ITraceFuncSettings extends ITraceHasDescription, IHasTraceParamType, ITraceSettings{
  /** Название(псевдоним) функции в выводе */
  name?: string;
}

/** Тип декоратора(включать/исключать вывод) */
export type TraceParamType = 'include' | 'exclude'

/** Интерфейс настройки параметров */
export interface ITraceParamSettings extends ITraceSettings, IHasMaxLength{
  /** Функция конвертации параметра. Использовать если нужно частично выводить параметр */
  map?: (any) => any
  /** Тип декоратора (по умолчанию include/включать в вывод) */
  type?: TraceParamType
  /** Название(псевдоним) переменной в выводе */
  name?: string,
}

/** Интерфейс настройки трассировки асинхронностей */
export interface ITraceAsyncSettings<T> extends ITraceSettings, ITraceHasDescription, IHasMaxLength{
  /** Функция конвертации результата асинхронности. Использовать если нужно частично выводить результат */
  map?: (T) => any
}

/** Перечисление управления вывода параметров */
export enum TraceParamEnum{
  /** Выводится параметры не будут */
  notTrace = 1,
  /** Выводить все. Даже если навешен декоратор параметров только на один параметр, все равно выведет все параметры */
  traceAll = 2,
  /** Управлять выводом будут декораторы параметров. Если их нет то выводить не будет */
  traceByDecorators= 3,
  /** (По умолчанию) Управляет выводом декораторы параметров. Если их нет то выводит все параметры */
  traceByDecoratorsOrTraceAll= 4
}
