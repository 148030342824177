<kendo-grid [height]="height"
            [data]="value"

            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            (add)="addHandler($event)"
            (edit)="editHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand icon="plus"
            [disabled]="disabled || getAllowedFinancingSources.length === 0 || (isEditingNow$ | async)">Добавить
    </button>
  </ng-template>

  <kendo-grid-column field="financingSourceId" title="Источник финансирования">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getFinancingSourceTextById(dataItem.financingSourceId) }}
    </ng-template>

    <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
    >
      <div #financingSourceDiv>
        <kendo-dropdownlist
              [data]="getAllowedFinancingSources"
              textField="shortName"
              valueField="id"
              [valuePrimitive]="true"
              [formControl]="formGroup.get(column.field)"
        >
        </kendo-dropdownlist>

        <kendo-popup
              [anchor]="financingSourceDiv"
              *ngIf="form.get(column.field).invalid && !(isNew && form.get(column.field).untouched)"
              popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
        >
          <div class="errors-popup">
            <div *ngIf="formGroup.get(column.field).errors?.required">Обязательно для заполнения</div>
          </div>
        </kendo-popup>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="value" title="Ставка">
    <ng-template
          kendoGridEditTemplate
          let-column="column"
          let-formGroup="formGroup"
          let-isNew="isNew"
    >
      <div #dinnerHourDurationDiv>
        <kendo-numerictextbox [formControl]="formGroup.get(column.field)"
                              [min]=rateStep
                              [max]="10000"
                              [format]="'n2'"
                              [decimals]="2"
                              [step]=rateStep
                              [autoCorrect]="true"
        ></kendo-numerictextbox>
        <kendo-popup
              [anchor]="dinnerHourDurationDiv"
              *ngIf="form.get(column.field).invalid && !(isNew && form.get(column.field).untouched)"
              popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
        >
          <div class="errors-popup">
            <div *ngIf="formGroup.get(column.field).errors?.required">Обязательно для заполнения</div>
            <div *ngIf="formGroup.get(column.field).errors?.min">Значение не может быть
              меньше {{formGroup.get(column.field).errors?.min.min}}. Текущее
              значение: {{formGroup.get(column.field).errors?.min.actual}}</div>
            <div *ngIf="formGroup.get(column.field).errors?.max">Значение не может быть
              больше {{formGroup.get(column.field).errors?.max.max}}. Текущее
              значение: {{formGroup.get(column.field).errors?.max.actual}}</div>
          </div>
        </kendo-popup>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]=110>
    <ng-template kendoGridCellTemplate let-isNew="isNew">
      <button kendoGridEditCommand [primary]="true" icon="pencil"></button>
      <button kendoGridSaveCommand [disabled]="form?.invalid" icon="save" [primary]="true"></button>
      <button kendoGridCancelCommand icon="close"></button>
      <button kendoGridRemoveCommand *ngIf="!isNew" icon="trash"></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
