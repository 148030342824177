import {GraphTableBase, IGraphTableBase} from "../timesheet/GraphTableBase";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IGraph extends IGraphTableBase{

}

@classBackend('Graph', 'timesheet_graph')
@className('Graph')
/** График */
export class Graph extends GraphTableBase implements IGraph{

  private static _fullComparer: ObjComparer<IGraph>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = GraphTableBase.fullComparerBase;
    }

    return this._fullComparer;
  }
}
