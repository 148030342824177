import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IEmployeeNamesDeclension extends IEntityId, IEntityModify, IEntityDeletedFlag, IEntityRowVersion {

  /** Идентификатор Employee, к которому относятся записи склонения по падежам ФИО */
  employeeId: number;

  /** Именительный падеж для ФИО сотрудника (кто?, что?) */
  nominative: string;

  /** Винительный падеж для ФИО сотрудника (кого?, что?) */
  accusative: string;

  /** Дательный падеж для ФИО сотрудника (кому?, чему?) */
  dative: string;

  /** Родительный падеж для ФИО сотрудника (кого?, чего?) */
  genitive: string;

  /** Творительный падеж для ФИО сотрудника (кем?, чем?) */
  instrumental: string;

  /** Предложный падеж для ФИО сотрудника (о ком?, о чем?) */
  prepositional: string;

}

@classBackend('EmployeeNamesDeclension', 'stafflist')
@className('EmployeeNamesDeclension')
/** Модель БД таблицы stafflist.EmployeeNamesDeclensions (Склонения по падежам ФИО сотрудника) */
export class EmployeeNamesDeclension implements IEmployeeNamesDeclension {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public employeeId: number,
              public nominative: string,
              public accusative: string,
              public dative: string,
              public genitive: string,
              public instrumental: string,
              public prepositional: string,
              public timestamp: []) {
  }
}
