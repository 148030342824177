import { Injectable, OnDestroy } from "@angular/core";
import { DialogAction, DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { SelectionChangeEvent } from "@progress/kendo-angular-treelist";
import { ReplaySubject, takeUntil, take } from "rxjs";
import { ArrayDataSourceIEntityId } from "src/app/classes/array-data-sources/data-source";
import { IOccupationType, OccupationType } from "src/app/classes/domain/POCOs/stafflist/OccupationType";
import { ResponseObjError } from "src/app/classes/requestResults/responseObjError";
import { TextAreaFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/text-area-field-control";
import { TextBoxFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/text-box-field-control";
import { NotVersioningField } from "src/app/components/directory-edit/services/classes/fields/not-versioning-field";
import { DirectoryEditService } from "src/app/components/directory-edit/services/directory-edit.service";
import { DirectoryEditRef } from "src/app/components/directory-edit/services/ref-services/directory-edit-ref";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { exLoadingMessage } from "src/app/operators/ex-loading-message.operator";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { CellClickExpandedEvent } from "src/app/services/directives/grid-treelist-expanded-directive.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { AddOccupationTypeFormComponent } from "../../add/add-occupation-type-form.component";
import { IOccupationTypesDirectoryGridComponent } from "../i-occupation-types-directory-grid.component";
import { OccupationTypesDirectoryGridComponentDataService } from "./occupation-types-directory-grid-component-data.service";


@traceClass('OccupationTypesDirectoryGridComponentService')
@Injectable()
export class OccupationTypesDirectoryGridComponentService extends ComponentServiceBase<IOccupationTypesDirectoryGridComponent> implements OnDestroy {

  /** Настройки полей справочника */
  private fields: Array<NotVersioningField> = [
    new NotVersioningField("code", { isAllowNull: true, title: "Код" }, new TextBoxFieldControl({ maxLength: 50, autoTrimValue: true })),
    new NotVersioningField("name", { isAllowNull: false, title: "Наименование категорий должностей" }, new TextBoxFieldControl({ minLength: 2, maxLength: 50, autoTrimValue: true })),
    new NotVersioningField("comment", { isAllowNull: true, title: "Комментарий" }, new TextAreaFieldControl({ maxLength: 100, autoTrimValue: true })),
  ];

  private directoryEditServiceRef: DirectoryEditRef;
  private _addDialogRef: DialogRef;
  private _removeDialogRef: DialogRef;
  private _editParentDialogRef: DialogRef;
  private directoryData = new ArrayDataSourceIEntityId<IOccupationType>();

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
      unsubscribes: new ReplaySubject<any>(1)
    }

  constructor(private directoryEditService: DirectoryEditService,
              private dataService: OccupationTypesDirectoryGridComponentDataService,
              private tracerService: TracerServiceBase,
              private displayErrorsService: DisplayErrorsService,
              private dialogService: DialogService,
              private kendoNotificationService: KendoNotificationService,
              private loadingIndicatorService: LoadingIndicatorService) {
    super();
  }

  /** Инициализация сервиса (обычно вызывается в OnInit) */
  @traceFunc()
  public init() {
    this.component.data$ = this.directoryData.data$;
    this.initDirectoryEditService();
    this.reloadData()
  }

  /** Инициализация сервиса редактирования */
  @traceFunc()
  private initDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.initNotVersioningOnly({
      saveNoVersioning$: (entity) => this.dataService.saveWithoutLoadingIndicator$(entity).pipe(exLoadingMessage(this.loadingIndicatorService, 'Сохранение данных')),
    }, this.fields);
    this.directoryEditServiceRef.notVersioning.onSaved$.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(s => this.onNoVersioningItemSaved(s));
  }

  /** Доступна ли кнопка удаления - если какой-то элемент выбран */
  @traceFunc()
  private isCanDelete(): boolean {
    return !!this.component.currentSelectedItem && !this.directoryData.data.some(d => d.parentId === this.component.currentSelectedItem.id);
  }

  /** Возрашает категорию должности по выбраному значению в окне выбора родителтской категории должности */
  @traceFunc()
  public getDataItemForEditParentId() {
    return this.component.parents.find(f => f.id === this.component.editParentIdValue) ?? {};
  }

  /** Возрашает отключенные категории должности, в случае, если она выбрана (защита от ребенок является родителем) */
  @traceFunc()
  public parentsDisabled(parent: OccupationType) {
    return parent.id === this.component.currentSelectedItem.id
  }

  /** Вызов окна выбора родительсвой категории должности */
  @traceFunc()
  public startEditParentId() {
    this.component.editParentIdValue = this.component.currentSelectedItem.parentId;
    this._editParentDialogRef?.close()

    this._editParentDialogRef = this.dialogService.open({
      title: 'Редактирование родителя',
      content: this.component.editParentIdTemplateRef,
      actions: [
        { text: 'Сохранить', themeColor: 'primary' }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    this._editParentDialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        let newValue = this.component.currentSelectedItem;
        newValue.parentId = this.component.editParentIdValue;

        this.dataService.save$(newValue)
          .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
            next: timestamp => {
              newValue.timestamp = timestamp;
              this.kendoNotificationService.showSuccess({ content: "Родитель сохранён" });
              this.directoryData.addOrUpdateItems(newValue);
            },
            error: error => this.displayErrorsService.handleError(error)
          });
      }
      this._editParentDialogRef?.close()
    });
  }

  /** Устанавлиает новое значение текущей выделеной категории должности */
  @traceFunc()
  private setCurrentSelectedItem(value: OccupationType) {
    this.component.currentSelectedItem = value
    this.component.canDelete = this.isCanDelete()
  }

  @traceFunc()
  public onClickAdd() {
    this.showAddDialog();
  }

  @traceFunc()
  public onClickRemove() {
    this.showRemoveDialog();
  }

  /** Диалог подверждения удаления категории должности */
  @traceFunc()
  private showRemoveDialog() {
    this._removeDialogRef?.close()

    this._removeDialogRef = this.dialogService.open({
      title: 'Подтвердите удаление',
      content: 'Вы уверены, что хотите удалить выбранную категорию должностей?\nДальнейшее восстановление будет не возможно!',
      actions: [
        { text: 'Нет' },
        { text: 'Да, удалить', themeColor: 'primary' }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    this._removeDialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        this.loadingIndicatorService.addToObservable("Удаление категории должностей", this.dataService.delete$(this.component.currentSelectedItem.id))
          .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
            next: () => {
              this.directoryData.deleteItemByIds(true, this.component.currentSelectedItem.id)
              this.setCurrentSelectedItem(null)
            },
            error: error => {
              if (ResponseObjError.checkTypeReturnCode(error) === "6a76dc60-db99-4672-9078-1783c2b37097") {
                this.displayErrorsService.showSimpleError('Запрещено удаление категории подразделений, которая уже была указана у одного из подразделений');
              } else {
                this.displayErrorsService.handleError(error);
              }
            }
          });
      }
      this._removeDialogRef?.close()
    });
  }

  /** Диалог добавления категории должности */
  @traceFunc()
  private showAddDialog() {
    this._addDialogRef?.close()
    this._addDialogRef = this.dialogService.open({
      title: `Добавление категории должностей`,

      // Show component
      content: AddOccupationTypeFormComponent,
      width: "90%",
    });

    const addFormComponent = this._addDialogRef.content.instance as AddOccupationTypeFormComponent;
    addFormComponent.parents = this.component.parents;
    addFormComponent.onSaveEventEmitter.subscribe(entity =>
      this.dataService.add$(entity)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
          next: data => {
            this.kendoNotificationService.showSuccess({ content: "Категория должности добавлена" });
            this.directoryData.addItems(true, data);
            this._addDialogRef?.close()
          },
          error: error => this.displayErrorsService.handleError(error)
        })
    );

    addFormComponent.onCancelEventEmitter.subscribe(() => this._addDialogRef?.close());
  }

  /** Событие происходит когда НЕ версионное поле было отредактировано и сохранено */
  @traceFunc()
  private onNoVersioningItemSaved(dataItem: IOccupationType) {
    this.directoryData.updateItems2(true, dataItem);
  }

  /** При двойном клике открыть неверсионое редактирование поля */
  @traceFunc()
  public onDblCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    if (!e.originalEvent.isEdited) {
      this.directoryEditServiceRef.edit(e.originalEvent.column.field, e.originalEvent.dataItem, e.originalEvent.originalEvent.target);
    }
  }

  /** Обновляет текущую выбраную категорию и canDelete для неё - срабатывает при любом выборе или снятии выбора в таблице */
  @traceFunc()
  public onSelectionChanged(e: SelectionChangeEvent) {
    this.setCurrentSelectedItem(e.action === 'select' ? e.items[0]?.dataItem as OccupationType : null)
  }

  /** Перезагрузка данных грида */
  @traceFunc()
  private reloadData() {
    this.dataService.get$()
      .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
        next: value => {
          this.directoryData.setData(value)
          this.component.parents = value.filter(p => !p.parentId);
        },
        error: error => this.displayErrorsService.handleError(error)
      });
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
    this.directoryEditServiceRef.close();
    this.directoryData.onDestroy();
  }
}
