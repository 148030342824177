import { HttpParams } from '@angular/common/http';
import * as moment from "moment";
import { DateHelper } from './dateHelper';

/**
 * Помощник заполнения HttpParams
 */
export class HttpParamsHelper {

  /**
   * Получить заполненный HttpParams на основе данных
   * Date -> DateHelper.toServerFormat()
   * String -> toString()
   * Number -> toString()
   * @param paramsArray
   */
  public static getParams(paramsArray:
    Array<[string, string|Date|number]>) : HttpParams {

    let retval = new HttpParams();
      paramsArray.forEach(p=> {
        const key = p[0];
        const value = p[1];

        if(value) {
          if(value instanceof Date) {
            retval = retval.append(key, DateHelper.toServerFormat(value as Date));
          }
          else{
            retval = retval.append(key, value.toString());
          }
        }
      });
    return retval;
  }
}
