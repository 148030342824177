import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebApi1Service } from '../web-api1.service';
import { Observable } from 'rxjs';
import { PrintFormType } from '../../../../classes/domain/enums/print-form-type';

@Injectable(
  { providedIn: 'root' },
)
export class Api1PrintFormControllerService {
  constructor(private httpClient: HttpClient, private api1Service: WebApi1Service) {
  }

  public getPrintFormList$(type: PrintFormType): Observable<Array<PrintForm>> {
    return this.httpClient.get<Array<PrintForm>>(this.api1Service.controllers.printReport.actions.getPrintFormList.toString(),
      { params: new HttpParams({ fromObject: { reportType: type } }) },
    );
  }
}

export class PrintForm {
  public name: string;
  public innerKey: string;
  public order: number;
  public description: string;
}
