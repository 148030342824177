import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthServerRequest, AuthServerResponse } from "../classes/auth-classes";
import { Observable, of, ReplaySubject, Subject, throwError } from "rxjs";
import { UserAuth } from "../../../classes/domain/Auth/UserAuth";
import { catchError, map, shareReplay, take, takeUntil, tap } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { WebApiAuthService } from "../../../services/webApi/webApiAuth/web-api-auth.service";
import { CustomStorageService } from "../../../services/storages/custom-storage.service";
import { LoadingIndicatorService } from "../../../services/loading-indicator.service";
import { AppSettingsService } from "../../../services/app-settings.service";
import { AuthService_MarkerStorageService } from "./auth-service_marker-storage.service";
import { AuthService_AuthDataService, AuthService_AuthDataService_TokenObj } from "./auth-service_auth-data.service";
import { AuthService_AuthDataStorageService } from "./auth-service_auth-data-storage.service";
import { traceClass } from "../../trace/decorators/class.decorator";
import { TraceParamEnum } from "../../trace/decorators/classes/traceSetting.interface";
import { traceFunc } from "../../trace/decorators/func.decorator";
import { TracerServiceBase } from "../../trace/tracers2/trace-services/tracer-base.service";
import { trace } from "../../trace/operators/trace";
import {ResponseObjError} from "../../../classes/requestResults/responseObjError";

/** Сервис авторизации */
@Injectable({
  providedIn: "root"
})
@traceClass('AuthService')
export class AuthService implements OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<void>(1),
    /** Если пользователь вышел транслирует Null иначе НЕ Null */
    tokenChange: new Subject<AuthServerResponse>(),
    /** Авторизован ли пользователь */
    isAuth: new ReplaySubject<boolean>(1)
  }

  private _isAuth: boolean = undefined;
  /** Авторизован ли пользователь */
  public get isAuth() {
    return !!this._isAuth;
  }
  private set isAuth(value: boolean) {
    value = !!value; //Переводим undefined/null в bool
    if(value === this._isAuth){
      return;
    }

    this._isAuth = value;
    this.streams$.isAuth.next(value);
  }

  /** Стрим - пользователь авторизован или нет */
  public readonly isAuth$: Observable<boolean> = this.streams$.isAuth;

  /** Сервис работы с jwt */
  public readonly jwtHelperService: JwtHelperService = new JwtHelperService();

  /** Токен авторизации */
  public get token() {
    return this.authDataService.token;
  }

  /** Токен авторизации как объект */
  public get tokenAsObj() {
    return this.authDataService.tokenAsObj;
  }

  /** Текущий пользователь */
  public get user(): UserAuth {
    return this.authDataService.user;
  }

  /** Получить токен и если он просрочен то обновить */
  public get tokenOrUpdate$(): Observable<string> {
    return this.authDataService.tokenIsExpired ?
      this.refreshAuth$().pipe(map(result => result.token)) :
      of(this.token);
  }

  /** Получить токен авторизации для signalR */
  public get signalToken$(): Observable<string> {
    return this.tokenOrUpdate$;
  }

  /** Конструктор */
  public constructor(
    private readonly appSettingsService: AppSettingsService,
    private readonly httpClient: HttpClient,
    private readonly customStorageService: CustomStorageService,
    private readonly webApiAuthService: WebApiAuthService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    public readonly markerStorageService: AuthService_MarkerStorageService,
    private readonly authDataService: AuthService_AuthDataService,
    private readonly authDataStorageService: AuthService_AuthDataStorageService,
    private readonly tracerService: TracerServiceBase,
  ) {

    //Слушаем изменение токена
    this.streams$.tokenChange.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.authDataService.OnInit(value);
      this.authDataStorageService.authDate = value;
      if (!value) { //Если пользователь вышел
        this.customStorageService.clear(false);
        this.appSettingsService.company = null;
      } else { //Если токен обновился на правильный
        //Обновляем маркер на новый
        this.updateMarker(this.tokenAsObj, this.authDataService.user);
      }

      this.isAuth = !!value;
    })

    this.markerStorageService.onMarkerChangedAnotherInstance$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.streams$.tokenChange.next(null);
      this.redirectToLoginPage();
    })

    const tokenFromStorage = this.authDataService.getTokenAsObj(this.authDataStorageService.authDate?.token);
    if (tokenFromStorage) { //Если токен присутствует в сессии
      this.authDataStorageService.authDate = null; //Очищаем авторизационные данные

      if (!this.markerStorageService.marker || !this.markerStorageService.checkComplianceTokenAndMarker({ company: tokenFromStorage.uCN$, userId: tokenFromStorage.uId$ })) {
        this.redirectToLoginPage();
        return;
      }
    }

    this.appSettingsService.company = this.markerStorageService.marker?.company ?? null;

    if (!this.markerStorageService.marker?.user) { //Если вышли или первый запуск приложения
      this.isAuth = false;
      return;
    }

    setTimeout(() => {
      this.loadingIndicatorService.addToObservable(
        'Обновление авторизации',
        this._serverAuth$(this.markerStorageService.marker.user.login, this.markerStorageService.marker.user.password)
      ).pipe(takeUntil(this.streams$.unsubscribe)).subscribe({ error: () => this.logOut() })
    })
  }

  /** Обновить данные авторизации с сервера */
  @traceFunc({ description: 'обновление/получение данных авторизации' })
  public refreshAuth$(): Observable<AuthServerResponse> {
    return this._serverRefreshAuth$();
  }

  /** Авторизоваться */
  @traceFunc()
  public login(login: string, password: string): Observable<AuthServerResponse> {
    return this._serverAuth$(login, password);
  }

  /** Выйти */
  @traceFunc()
  public logOut(redirectToLoginPage = true) {
    this.streams$.tokenChange.next(null);
    this.markerStorageService.clearUserInMarker();
    if (redirectToLoginPage) {
      this.redirectToLoginPage();
    }
  }

  /** Авторизоваться */
  @traceFunc()
  private _serverAuth$(login: string, password: string): Observable<AuthServerResponse> {
    const observable = this.httpClient.post<AuthServerResponse>(
      this.webApiAuthService.controllers.auth.actions.getTokenAndAuthUser.toString(),
      new AuthServerRequest(
        this.appSettingsService.company,
        this.appSettingsService.program,
        login,
        password
      ));

    return this._extendPipes(observable);
  }

  /** Обновить авторизацию */
  @traceFunc()
  private _serverRefreshAuth$(): Observable<AuthServerResponse> {
    const observable = this.httpClient.post<AuthServerResponse>(
      this.webApiAuthService.controllers.auth.actions.refreshTokenAndAuthUser.toString(),
      { token: this.token });

    return this._extendPipes(observable)
      .pipe(
        catchError(err => {
          if (ResponseObjError.isAuthError(err)) {
            this.logOut();
          }
          return throwError(() => err);
        })
      );
  }

  private _lastObservableCash: Observable<AuthServerResponse> = null;
  /** Расширить pipe наблюдаемого, получения/обновления токена */
  @traceFunc({ traceParamType: TraceParamEnum.notTrace })
  private _extendPipes(observable: Observable<AuthServerResponse>): Observable<AuthServerResponse> {
    if (!this._lastObservableCash) {
      this._lastObservableCash = observable.pipe(
        trace(this.tracerService, { description: 'обновление данных авторизации' }),
        take(1), takeUntil(this.streams$.unsubscribe),
        tap(value => {
          this.streams$.tokenChange.next(value);
          this._lastObservableCash = null;
        }),
        catchError((err, caught) => {
          this._lastObservableCash = null;
          return throwError(() => err);
        }),
        shareReplay(1)
      );
    } else {
      this.tracerService.add('В данный момент уже осуществляется запрос, буду ожидать его результата');
    }

    return this._lastObservableCash;
  }

  /** Редирект на логин страницу */
  @traceFunc()
  private redirectToLoginPage() {
    window.location.pathname = '/login'
  }

  /** Обновить маркер пользователя */
  @traceFunc({description: 'обновление маркера пользователя'})
  private updateMarker(tokenAsObj: AuthService_AuthDataService_TokenObj, user: UserAuth) {
    this.markerStorageService.marker = {
      company: tokenAsObj.uCN$,
      user: {
        id: user.Id,
        login: user.Name,
        password: user.PassWord
      }
    }
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.tokenChange.complete();
    this.streams$.isAuth.complete();
  }
}


