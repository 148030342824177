import {Injectable, OnDestroy} from "@angular/core";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../services/storages/custom-storage.service";
import {interval, Observable, ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {TracerServiceBase} from "../../trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../trace/decorators/class.decorator";
import {traceFunc} from "../../trace/decorators/func.decorator";

/** Сервис хранения авторизационного маркера */
@Injectable({providedIn: "root"})
@traceClass('AuthService_MarkerStorageService')
export class AuthService_MarkerStorageService implements OnDestroy{
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    /** Маркер изменен в другом экземляре(вкладке) */
    onMarkerChangedAnotherInstance: new Subject<AuthMarker>()
  }

  /** Ключ для доступа к названию базы данных куда авторизовался пользователь */
  private readonly storageOptionsForMarker = new StorageOptions(StorageLocationEnum.LocalStorage,'AuthService/Marker',
    null, true, true);

  private _marker: AuthMarker = null
  /** Получить маркер */
  public get marker(){
    return this._marker;
  }

  public set marker(value){
    this.tracerService.add2('установка маркера', {obj: value})
    const toStorage = AuthMarker.Copy(value)
    this.customStorageService.set(this.storageOptionsForMarker, toStorage);
    this._marker = toStorage;
  }

  /** Стрим изменения маркера в другом экземляре(вкладке). Произведет трансляцию если сменился пользователь или компания НЕ этим приложением*/
  public get onMarkerChangedAnotherInstance$(): Observable<AuthMarker>{
    return this.streams$.onMarkerChangedAnotherInstance;
  }

  constructor(private readonly customStorageService: CustomStorageService,
              private readonly tracerService: TracerServiceBase) {
    this._marker = this.getMarkerFromStorage();
    this.registerCheckLogoutOtherPage();
  }

  /** Очистить пользователя из маркера. Необходимо при logout */
  @traceFunc()
  public clearUserInMarker(){
    const storageMarker = this.marker;
    if(storageMarker){
      this.marker = {company: storageMarker.company, user: null};
    }
  }

  /** Проверка соответствия пользователя с маркером.
   *  Вернет true если пользователь в маркере тот же что и в токене, либо маркер и токен отсутствует(пользователь НЕ авторизован).
   *  В остальных случаях вернет false.
   */
  @traceFunc()
  public checkComplianceTokenAndMarker(user: {company: string, userId: string }): boolean{
    const marker = this.marker;
    return marker?.company == user?.company && marker?.user?.id?.toString() == user?.userId;
  }

  /** регистрация проверки выхода/смены пользователя с другой вкладки */
  private registerCheckLogoutOtherPage(){
    interval(1000).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      const fromStorage = this.getMarkerFromStorage();
      if(!AuthMarker.IsEqual(this._marker, fromStorage)){
        this.tracerService.add2('Маркер изменился', {
          obj: {
            oldM: this._marker,
            newM: fromStorage
          }
        })
        this.streams$.onMarkerChangedAnotherInstance.next(fromStorage);
        this._marker = fromStorage;
      }
    })
  }

  /** Получить маркер из хранилища*/
  private getMarkerFromStorage(){
    return AuthMarker.Copy(this.customStorageService.get<AuthMarker>(this.storageOptionsForMarker))
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.onMarkerChangedAnotherInstance.complete();
  }
}


/**
 * Для хранения в localStorage маркера авторизации
 * Для обработки событий авторизации на разных вкладках браузера
 */
export class AuthMarker {
  /**
   * Конструктор
   * @param company Компания в которой авторизовался последний пользователь
   * @param user Пользователь который авторизован в данный момент
   */
  public constructor(
    public readonly company: string,
    public readonly user: {id: number, login: string, password: string}
  ) {
  }

  /** Скопировать объект */
  public static Copy(source: AuthMarker){
    if(!source){
      return null;
    }

    return new AuthMarker(source.company, source.user)
  }

  /** Возвращает true если компания НЕ изманилась и идентификатор пользователя НЕ изменился */
  public static IsEqual(source1: AuthMarker, source2: AuthMarker): boolean{
    if(source1?.company != source2?.company){
      return false;
    }

    if(source1?.user?.id != source2?.user?.id){
      return false;
    }

    return true;
  }
}
