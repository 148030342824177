import { Component, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { PassportData, PassportType } from 'src/app/classes/extendedDatas/employeeExtended/passport-data';
import { DropDownItem, IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { IEditPassportDataComponent } from './i-edit-passport-data.component';
import { EditPassportDataComponentFormService, IValueResult } from './services/edit-passport-data-component-form.service';
import { EditPassportDataComponentService } from './services/edit-passport-data-component.service';

@Component({
  selector: 'app-edit-passport-data',
  templateUrl: './edit-passport-data.component.html',
  styleUrls: ['./edit-passport-data.component.css'],
  providers: [EditPassportDataComponentService, EditPassportDataComponentFormService]
})


export class EditPassportDataComponent implements OnInit, OnDestroy, IEditPassportDataComponent {

  /** Состояние disabled */
  private _disabled: boolean = false;
  @Input("disabled") get disabled() {
    return this._disabled;
  }
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.service.onDisabledChanged();
  }

  /** Исходные паспортные данные */
  private _data: PassportData;
  @Input("data") get data() {
    return this._data;
  }
  set data(data: PassportData) {
    this._data = data;
    this.service.onDataChanged();
  }

  /** Событие изменения данных */
  @Output("onDataChanged$") onDataChanged$ = new Subject<IValueResult>();

  public form: UntypedFormGroup;
  public currentDate: Date = new Date();

  /** Типы документов паспортов (пока что только 1 тип это паспорт россии) */
  public passportTypes: IDropDownItem[] = [
    new DropDownItem(PassportType.RussianFederation, "Паспорт РФ")
  ]

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Конструктор */
  constructor(private service: EditPassportDataComponentService ) {
    service.component = this;
  }

  ngOnInit() {
    this.service.init();
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

