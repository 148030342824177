import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IStaffUnit, IStaffUnitOptional } from 'src/app/classes/domain/POCOs/stafflist/StaffUnit';
import { EmployeeDropDownItem } from '../employee-drop-down-item.class';
import {
  FreeRatesDTO
} from 'src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service';
import { traceClass } from "../../../../../../../src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { traceFunc } from "../../../../../../../src/app/modules/trace/decorators/func.decorator";
import { DataSource } from 'src/app/classes/array-data-sources/data-source';
import { Employee, IEmployee } from 'src/app/classes/domain/POCOs/stafflist/Employee';
import { ArrayDataSourceIEntityIdServiceWithParamsBase, DataSourceWithParamsBase, IArrayDataSourceServiceWithParamsReadonly } from 'src/app/services/data-source-services/data-source.service';
import { ForDate, IForDate } from 'src/app/classes/for-date';
import { IStaffUnitType } from 'src/app/classes/domain/POCOs/stafflist/StaffUnitType';
import {combineLatestWith, map, Observable, ReplaySubject, switchMap, takeUntil} from 'rxjs';
import { IFinancingSource } from 'src/app/classes/domain/POCOs/stafflist/FinancingSource';
import { CustomFormValidators } from 'src/app/validators/custom-form-validators.class';
import { exDistinctUntilChanged } from 'src/app/operators/ex-distinct-until-changed.operator';
import {StaffUnitTypeEnum, StaffUnitTypeEnumHelper} from 'src/app/classes/domain/enums/StaffUnitTypeEnum';
import { PropertyWrapFormControlType } from 'src/app/classes/types/property-wrap-form-control-type';
import { IPosition } from 'src/app/classes/domain/POCOs/stafflist/Position';
import { exElementByIndexOr } from 'src/app/operators/ex-element-by-index-or';
import { CustomFormValidationFunctions } from 'src/app/validators/custom-form-validation-functions.class';
import { IFreeRatesDataSourceService_Params } from 'src/app/services/common-data-source-services/free-rates-data-source.service';
import { exMinMaxStaffUnitFreeRate } from '../ex-min-max-staff-unit-free-rate.operator';

/** Тип формы добавления */
export type AddStaffUnitFormComponentForm = PropertyWrapFormControlType<Pick<IStaffUnit, | 'startDate' | 'endDate' | 'comment' | 'employeeId' | 'financingSourceId' | 'typeId' | 'rate' | 'executionDutiesFlag' | 'percent' | 'positionId' | 'milk'>>;

@Component({
  selector: 'app-add-staffunit-form',
  templateUrl: './add-staffunit-form.component.html',
  styleUrls: ['./add-staffunit-form.component.css']
})
@traceClass('AddStaffUnitFormComponent')
export class AddStaffUnitFormComponent implements OnInit {

  @Input() public dataSource: AddStaffUnitFormComponent_DataSourceService;

  @Input("dataItem") public dataItem: IStaffUnitOptional;

  @Output("onSave") public onSaveEventEmitter = new EventEmitter<IStaffUnitOptional>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>();

  public employeesDropDown$: Observable<EmployeeDropDownItem[]>;
  public staffUnitTypes$: Observable<Pick<IStaffUnitType, 'id' | 'description'>[]>;
  public financingSources$: Observable<Pick<IFinancingSource, 'id' | 'shortName'>[]>;
  public maxRate$ : Observable<number>;
  public currentPosition$: Observable<IPosition>;
  public staffUnitWithPercent: boolean = false;

  public form: FormGroup<AddStaffUnitFormComponentForm>;

  //public rateStep: number;

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(
    private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.financingSources$ = this.dataSource.data$.pipe(switchMap(s=> s.freeRates.dataSource.data$)).pipe(map(f=> f.freeRates.filter(s=> s.rate >= this.dataSource.data.rateStep).map(s=> s.financingSource)));
    this.form = this.initForm(this.dataItem, this.dataSource.data.rateStep);

    this.employeesDropDown$ = this.dataSource.data$.pipe(switchMap(d=> d.employees.dataSource.data$)).pipe(map(d=> d.map(e=> new EmployeeDropDownItem(e.id, Employee.fullName(e), e.startDate, e.endDate))));

    this.staffUnitTypes$ = this.dataSource.data$
      .pipe(switchMap(s=> s.staffUnitTypes.dataSource.data2$
        .pipe(map(x =>x?.filter(sut => !sut.deletedFlag)))));

    this.maxRate$ = this.dataSource.data$.pipe(
      switchMap(s=> s.freeRates.dataSource.data$.pipe(exMinMaxStaffUnitFreeRate(0,1))),
      combineLatestWith(this.form.controls.financingSourceId.valueChanges)
      ).pipe(map(([freeRate, financingSourceId]) => freeRate.freeRates.find(f=> f.financingSource.id === financingSourceId)?.rate ?? 1));

    this.currentPosition$ = this.dataSource.data$.pipe(switchMap(d=> d.positions.dataSource.data$.pipe(exElementByIndexOr(0))));

    this.currentPosition$.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(s=> this.form.controls.positionId.setValue(s.id));

    this.dataSource.data.staffUnitTypes.reloadData$({}).subscribe();
  }

  /** Событие происходит при нажатии на кнопку добавить */
  @traceFunc()
  onClickSave() {
    const values = this.form.getRawValue();
    this.tracerService.add2('Нажата кнопка Save', { obj: values, maxLength: 1000 })
    this.onSaveEventEmitter.emit({
      ...values,
      //Поля disabled не попадают в form.value, поэтому заполняем это поле принудительно
      executionDutiesFlag: this.form.controls.executionDutiesFlag.value
    });
  }

  /** Событие происходит при нажатии на кнопку отменить */
  @traceFunc()
  onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }


  @traceFunc()
  private reloadAllSources(startDate: Date, endDate: Date) {
    const forDate: IForDate = ForDate.Get({startDate, endDate});

    this.dataSource.data.employees.reloadData(forDate);
    this.reloadFreeRatesAndFinancingSources({...this.form.getRawValue(), startDate: startDate, endDate: endDate});
  }

  private reloadFreeRatesAndFinancingSources(params: IFreeRateParamsType){
    this.dataSource.data.freeRates.reloadData(params);
  }

  @traceFunc()
  private initForm(item: IStaffUnitOptional, rateStep: number): FormGroup<AddStaffUnitFormComponentForm> {
    const startDateControl = new FormControl(item?.startDate ?? null, { validators: [CustomFormValidators.validateStartDateWithEndDate('endDate'), Validators.required], updateOn: 'blur' });
    const endDateControl = new FormControl(item?.endDate ?? null, { validators: [CustomFormValidators.validateEndDateWithStartDate('startDate'), CustomFormValidators.requiredIf(() => !!this.dataSource.data.positions.dataSource.data[0]?.endDate)], updateOn: 'blur' });
    const financingSourceIdControl = new FormControl({ value: item.financingSourceId ?? null, disabled: true }, [Validators.required]);
    const typeIdControl = new FormControl({ value: item.typeId ?? null, disabled: true }, [Validators.required]);
    const executionDutiesFlagControl = new FormControl({ value: item.executionDutiesFlag ?? false, disabled: true });
    const percentControl = new FormControl({value: item.percent ?? 0, disabled: true});

    CustomFormValidationFunctions.validateDataWithControlValue$(
        this.financingSources$,
        financingSourceIdControl,
        (d,c)=> d.id === c
      )
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe();

    const form = new FormGroup({
      startDate: startDateControl,
      endDate: endDateControl,
      comment: new FormControl({ value: item.comment ?? "", disabled: true }, { validators: [Validators.maxLength(100)], updateOn: 'blur' }),
      employeeId: new FormControl({ value: item.employeeId ?? null, disabled: true }, [Validators.required]),
      financingSourceId: financingSourceIdControl,
      typeId: typeIdControl,
      rate: new FormControl({ value: item.rate ?? null, disabled: true }, [Validators.required, CustomFormValidators.notEqual(0), CustomFormValidators.multipleOf(rateStep)]),
      executionDutiesFlag: executionDutiesFlagControl,
      percent: percentControl,
      positionId: new FormControl({ value: item.positionId, disabled: true }),
      milk: new FormControl({value: false, disabled: true}),
    });

    form.markAllAsTouched();

    endDateControl.valueChanges.pipe(exDistinctUntilChanged(endDateControl.value)).subscribe(v => {
      //При изменении даты окончания нужно перезагрузить данные для выпадающих списоков
      //или макс доступные ставки на указанную дату
      this.reloadAllSources(this.form.value.startDate, v);
    });

    startDateControl.valueChanges.pipe(exDistinctUntilChanged(startDateControl.value)).subscribe(v => {
      //При изменении даты начала нужно перезагрузить данные для выпадающих списоков
      //или макс доступные ставки на указанную дату
      if (v) {
        this.reloadAllSources(v, this.form.value.endDate);

        //Включаем контролы после выбора даты (если она не null)
        form.controls.comment.enable();
        form.controls.employeeId.enable();
        form.controls.financingSourceId.enable();
        form.controls.typeId.enable();
        form.controls.executionDutiesFlag.enable();
        form.controls.percent.enable();
        form.controls.positionId.enable();
        form.controls.milk.enable();
      }
    });

    financingSourceIdControl.valueChanges.subscribe(v => {
      if (v) {
        form.controls.rate.enable();
      } else {
        form.controls.rate.disable();
      }
    });

    typeIdControl.valueChanges.subscribe(v => {
      if (v === StaffUnitTypeEnum.Basic || v === StaffUnitTypeEnum.MoonlighterExternal) {
        executionDutiesFlagControl.enable();
      } else {
        executionDutiesFlagControl.disable();
        if (executionDutiesFlagControl.value !== false) {
          executionDutiesFlagControl.setValue(false);
        }
      }

      //Запрещаем выбирать молоко если выбран не разрешенный для редактирования молока тип стафф юнита
      if(this.dataSource.data.allowedMilkStaffUnitTypes.some(s=> s === v)) {
        form.controls.milk.enable();
      } else {
        form.controls.milk.disable();
        if(form.controls.milk.value !== false) {
          form.controls.milk.setValue(false);
        }
      }
    });

    executionDutiesFlagControl.valueChanges.pipe(exDistinctUntilChanged(executionDutiesFlagControl.value)).subscribe(v => {
      this.reloadFreeRatesAndFinancingSources({...this.form.getRawValue(), executionDutiesFlag: v});
    });

    form.valueChanges.subscribe(value => {
      this.tracerService.add2('Изменена форма', { obj: value, maxLength: 1000 })
    })

    return form;
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

  public changeStaffUnitType($event: StaffUnitTypeEnum) {
    this.staffUnitWithPercent = StaffUnitTypeEnumHelper.isCombination($event) || StaffUnitTypeEnumHelper.isUvorStaffUnit($event);
    if(!this.staffUnitWithPercent) this.form.controls.percent.setValue(0);
  }
}


export class AddStaffUnitFormComponent_DataSourceService extends DataSource<AddStaffUnitFormComponent_Data> {

}

export class AddStaffUnitFormComponent_Data {
  constructor(
      public readonly rateStep: number,
      public readonly positions: IArrayDataSourceServiceWithParamsReadonly<IForDate, IPosition>,
      public readonly employees: ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, IEmployee>,
      public readonly staffUnitTypes: ArrayDataSourceIEntityIdServiceWithParamsBase<any, IStaffUnitType>,
      public readonly freeRates: DataSourceWithParamsBase<IFreeRateParamsType, FreeRatesDTO>,
      public readonly allowedMilkStaffUnitTypes: StaffUnitTypeEnum[]
    ) { }
}

type IFreeRateParamsType = Pick<IFreeRatesDataSourceService_Params, 'startDate' | 'endDate' | 'executionDutiesFlag' | 'positionId'>;

