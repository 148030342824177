import {IEntityId} from "../../interfaces/IEntityId";
import {IEntityModify} from "../../interfaces/IEntityModify";
import {IEntityComment} from "../../interfaces/IEntityComment";
import {IEntityDeletedFlag} from "../../interfaces/IEntityDeletedFlag";
import {IEntityStartEndDate} from "../../interfaces/IEntityStartEndDate";
import {IEntityRowVersion} from "../../interfaces/IEntityRowVersion";
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface IPlanRequest extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityStartEndDate, IEntityRowVersion{
  /** Идентификатор номенклатуры */
  nomenclatureId: number;

  /** Идентификатор типа заявки */
  typeId: number;

  /** Количество */
  count: number;

  /** Является ли заявка созданной для прогноза */
  isFake: boolean;
}

@classBackend('PlanRequest', 'pfhd_plan')
@className('PlanRequest')
export class PlanRequest implements IPlanRequest{
  constructor(
              public comment: string,
              public count: number,
              public deletedFlag: boolean,
              public endDate: Date | null,
              public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public nomenclatureId: number,
              public startDate: Date | null,
              public typeId: number,
              public timestamp: [],
              public isFake: boolean) {
  }

  /** Максимально допустимое количество знаков после запятой для {@link count} */
  public static readonly CountDigits = 2;
}
