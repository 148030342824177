<kendo-dialog *ngIf="(alertService.currentOption$ | async) as currentOption"
              [title]="currentOption.title"
              (close)="onClose()"
              [minWidth]="300"
              [maxWidth]="650"
              [maxHeight]="'90%'"
>
  <div class="message-title" [innerHTML]="currentOption.titleMessage"></div>
  <div class="message">
    <div class="icon-container">
      <span [ngClass]="{
      'k-icon': currentOption.type > 1,
      'k-font-icon': currentOption.type > 1,
      'k-i-notification': currentOption.type == 2,
      'k-i-information': currentOption.type == 3,
      'k-i-question': currentOption.type == 4,
      'k-i-warning': currentOption.type == 5,
      'k-i-error': currentOption.type == 6
      }">
      </span>
    </div>
    <div style="width: 16px"></div>
    <div style="flex: 1">
      <div [innerHTML]="currentOption.message"></div>
    </div>
  </div>
  <kendo-dialog-actions *ngIf="currentOption.buttons?.length"
                        layout="normal">
    <button *ngFor="let button of currentOption.buttons"
            kendoButton [primary]="button.isPrimary"
            (click)="onAction(button)"
    >
      {{button.text}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
