import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { DataStateBuilder } from "src/app/classes/data-state-builders/data-state-builder";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";
import { IFinancingSource } from "src/app/classes/domain/POCOs/stafflist/FinancingSource";
import { IOccupation, Occupation } from "src/app/classes/domain/POCOs/stafflist/Occupation";
import { IPosition, Position } from "src/app/classes/domain/POCOs/stafflist/Position";
import { IPositionRate, IPositionRateOptional, PositionRate } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import { IReportPeriod, ReportPeriod } from "src/app/classes/domain/POCOs/stafflist/ReportPeriod";
import { StaffUnit } from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { ISubdivision, Subdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { IWorkMode, WorkMode } from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import { IForDate } from "src/app/classes/for-date";
import { ArrayHelper } from "src/app/helpers/arrayHelper";
import { exLoadingMessage } from "src/app/operators/ex-loading-message.operator";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "src/app/services/data-source-services/data-source.service";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { DbChangedListener } from "src/app/services/signal-r/listeners/db-changed-listener";
import { Api1FinancingSourceControllerService } from "src/app/services/webApi/webApi1/controllers/api1-financing-source-controller.service";
import { Api1OccupationsControllerService } from "src/app/services/webApi/webApi1/controllers/api1-occupations-controller.service";
import { AddPositionItem, Api1PositionControllerService } from "src/app/services/webApi/webApi1/controllers/api1-position-controller.service";
import { Api1PositionRateControllerService } from "src/app/services/webApi/webApi1/controllers/api1-position-rate-controller.service";
import { Api1ReportPeriodsControllerService } from "src/app/services/webApi/webApi1/controllers/api1-report-periods-controller.service";
import { Api1StaffListSettingsControllerService } from "src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service";
import { Api1StaffPositionsControlControllerService, StaffPositionGridItem } from "src/app/services/webApi/webApi1/controllers/api1-staff-positions-control.service";
import { Api1SubdivisionControllerService } from "src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service";
import { Api1WorkModeControllerService } from "src/app/services/webApi/webApi1/controllers/api1-work-mode-controller.service";

/** Базовый класс сервиса данных */
@Injectable()
export abstract class PositionsGridComponentDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<PositionsGridComponentDataSourceServiceBase_Params, StaffPositionGridItem>{

  public abstract getOccupationsForDate$(forDate:IForDate, occupationIds: number[]):Observable<IOccupation[]>;
  public abstract getWorkModesForDate$(forDate:IForDate, workModeIds: number[]):Observable<IWorkMode[]>;
  public abstract getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;
  public abstract getReportPeriods$(reportPeriodsIds: number[]): Observable<IReportPeriod[]>;
  public abstract getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]>;
  public abstract addPosition$(entity: AddPositionItem): Observable<Position>;
  public abstract addPositionRate$(entity: IPositionRateOptional): Observable<IPositionRate>;
  public abstract deletePosition$(entity: Pick<IPosition, "id" | "timestamp">): Observable<boolean>;

  public abstract addVersionPositionRate$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<any>;
  public abstract deleteVersionPositionRate$(versionId: number): Observable<any>;
  public abstract getVersionsByOwnerIdAndActionIdPositionRate$(ownerId: number, actionId: SupportedActionEnum): Observable<any>;
  public abstract editVersionPositionRate$(actionId: SupportedActionEnum, entity: VersioningItem): Observable<any>;

  public abstract addVersionPosition$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<any>;
  public abstract deleteVersionPosition$(versionId: number): Observable<any>;
  public abstract getVersionsByOwnerIdAndActionIdPosition$(ownerId: number, actionId: SupportedActionEnum): Observable<any>;
  public abstract editVersionPosition$(actionId: SupportedActionEnum, entity: VersioningItem): Observable<any>;
  public abstract saveNoVersioningPosition$(entity: StaffPositionGridItem): Observable<[]>;

  public abstract settingsRateStep$(): Observable<number>;

  public abstract reorder$(positionId1: number, positionId2: number, date: Date): Observable<any>;

  public abstract editEndDatePosition$(entity: StaffPositionGridItem, isSave: boolean): Observable<boolean>;
}


@Injectable()
export class PositionsGridComponentDataSourceService
  extends ArrayDataSourceIEntityIdServiceWithParamsBase<PositionsGridComponentDataSourceServiceBase_Params, StaffPositionGridItem>
  implements PositionsGridComponentDataSourceServiceBase{

  readonly paramsDataSource = new DataSource<PositionsGridComponentDataSourceServiceBase_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<StaffPositionGridItem>();

  constructor(private readonly api1StaffPositionsControlControllerService: Api1StaffPositionsControlControllerService,
              private readonly api1OccupationControllerService: Api1OccupationsControllerService,
              private readonly api1WorkModeControllerService: Api1WorkModeControllerService,
              private readonly api1ReportPeriodsControllerService: Api1ReportPeriodsControllerService,
              private readonly api1FinancingSourceControllerService: Api1FinancingSourceControllerService,
              private readonly api1SubdivisionControllerService: Api1SubdivisionControllerService,
              private readonly api1PositionControllerService: Api1PositionControllerService,
              private readonly api1PositionRateControllerService: Api1PositionRateControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly settingsService: Api1StaffListSettingsControllerService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
      subdivisions: Subdivision,
      positions: Position,
      workModes: WorkMode,
      occupations: Occupation,
      positionRates: PositionRate,
      staffUnits: StaffUnit,
      reportPeriods: ReportPeriod
    })
    .pipe(
      map(value => value.data),
      map(value => {
        let flattedPositionRates = ArrayHelper.flatMap(this.dataSource.data2.map(m=> m.staffPositionRates.map(s=> ({rateId: s.rateId, positionId: m.id}))));
        let flattedStaffUnits = ArrayHelper.flatMap(this.dataSource.data2.map(m=> m.staffUnitsIds.map(s=> ({staffUnitId: s, positionId: m.id}))));

        let retval =  {
          reportPeriods: new DataStateBuilder(value.reportPeriods, this.dataSource.data2, (x,y) => x.id === y.reportPeriodId).build_()
            .source
            .filter(x => x.state !== 'added') //добавленные периоды контроля не интересны
            .filter(x=> x.dataItem) //Не интересуют периоды контроля, котрые не относятся к нашим данным
              .map(x => x.dataItem.id),

          subdivisions: new DataHasOwnerStateBuilder(value.subdivisions, this.dataSource.data2, x => x.subdivision.id).build_()
            .source
            .filter(x => x.state !== 'added') //добавленные подразделения не интересуют
            .filter(x=> x.dataItem) //Не интересуют подразделения, котрые не относятся к нашим данным
            .map(x => x.dataItem.id),

          positions: new DataHasOwnerStateBuilder(value.positions, this.dataSource.data2, x => x.id).build_()
            .source.filter(x=>
              (x.state === 'added' && x.signalR.currentOrOrigin.subdivisionId === this.paramsDataSource.data2.subdivisionId)
              || x.state === 'modified' || x.state === "deleted"
              )
            .map(x => x.signalR.currentOrOrigin.ownerId),

          positionRates: new DataHasOwnerStateBuilder(value.positionRates, flattedPositionRates, x => x.rateId).build_()
            .source.filter(x=>
              (x.state === 'added' && flattedPositionRates.some(f => f.positionId === x.signalR.currentOrOrigin.positionId))
              || x.state === 'modified' || x.state === "deleted"
              )
            .map(x => x.dataItem?.positionId ?? x.signalR.currentOrOrigin.positionId),

          occupations: new DataHasOwnerStateBuilder(value.occupations, this.dataSource.data2, x => x.occupationId).build_()
            .source
            .filter(x => x.state !== 'added') //добавленные должности не интересны
            .filter(x=> x.dataItem) //Не интересуют должности, котрые не относятся к нашим данным
              .map(x => x.dataItem.id),

          workModes: new DataHasOwnerStateBuilder(value.workModes, this.dataSource.data2, x => x.workModeId).build_()
            .source
            .filter(x => x.state !== 'added') //добавленные режимы работы не интересны
            .filter(x=> x.dataItem) //Не интересуют режимы работы, котрые не относятся к нашим данным
              .map(x => x.dataItem.id),

          staffUnits: new DataHasOwnerStateBuilder(value.staffUnits, flattedStaffUnits, x => x.staffUnitId).build_()
            .source.filter(x=>
              (x.state === 'added' && this.dataSource.data.some(f => f.id === x.signalR.currentOrOrigin.positionId))
              || x.state === 'modified' || x.state === "deleted"
              )
            .map(x => x.dataItem?.positionId ?? x.signalR.currentOrOrigin.positionId),

        }
        return retval;
      }),
      map(value => ([
        ...value.subdivisions,
        ...value.positions,
        ...value.positionRates,
        ...value.occupations,
        ...value.workModes,
        ...value.staffUnits,
        ...value.reportPeriods
      ])),
      map(value => this.reloadFromSignalR$(value))
    )
  }

  public settingsRateStep$(): Observable<number> {
    return this.settingsService.rateStepWithCash$
  }

  //#region Position Rate
  public addPositionRate$(entity: IPositionRateOptional): Observable<IPositionRate> {
    return this.loadingIndicatorService.addToObservable(
      "Добавление штатной единицы",
      this.api1PositionRateControllerService.addPositionRate$(entity)
    )
  }

  public addVersionPositionRate$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<any> {
    return this.api1PositionRateControllerService.addPositionVersion$(actionId, versioningEntity);
  }

  public deleteVersionPositionRate$(versionId: number): Observable<any> {
    return this.api1PositionRateControllerService.deletePositionVersion$(versionId);
  }

  public getVersionsByOwnerIdAndActionIdPositionRate$(ownerId: number, actionId: SupportedActionEnum): Observable<any> {
    return this.api1PositionRateControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }
  public editVersionPositionRate$(actionId: SupportedActionEnum, entity: VersioningItem): Observable<any> {
    return this.api1PositionRateControllerService.editPositionVersion$(actionId, entity);
  }
  //#endregion


  //#region Position
  public addPosition$(entity: AddPositionItem): Observable<Position> {
    return this.loadingIndicatorService.addToObservable(
      "Добавление штатной единицы",
      this.api1PositionControllerService.addPosition$(entity)
    )
  }

  public addVersionPosition$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<any> {
    return this.api1PositionControllerService.addPositionVersion$(actionId, versioningEntity);
  }

  public deleteVersionPosition$(versionId: number): Observable<any> {
    return this.api1PositionControllerService.deletePositionVersion$(versionId);
  }

  public getVersionsByOwnerIdAndActionIdPosition$(ownerId: number, actionId: SupportedActionEnum): Observable<any> {
    return this.api1PositionControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }
  public editVersionPosition$(actionId: SupportedActionEnum, entity: VersioningItem): Observable<any> {
    return this.api1PositionControllerService.editPositionVersion$(actionId, entity);
  }
  public saveNoVersioningPosition$(entity: StaffPositionGridItem): Observable<[]> {
    return this.api1PositionControllerService.save$(entity).pipe(exLoadingMessage(this.loadingIndicatorService, 'Сохранение данных'));
  }

  public deletePosition$(entity: Pick<IPosition, "id" | "timestamp">): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      "Удаление штатной единицы",
      this.api1PositionControllerService.delete$(entity.id, entity.timestamp)
    )
  }

  public editEndDatePosition$(entity: StaffPositionGridItem, isSave: boolean): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable("Сохранение даты окончания",this.api1PositionControllerService.editEndDate$(entity.endDate, [{id: entity.id, timestamp: entity.timestamp}], isSave));
  }
  //#endregion



  public getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>{
    return this.api1SubdivisionControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, subdivisionIds);
  }

  public getOccupationsForDate$(forDate: IForDate, occupationIds: number[]): Observable<IOccupation[]> {
    return this.api1OccupationControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, occupationIds);
  }

  public getWorkModesForDate$(forDate: IForDate, workModeIds: number[]): Observable<IWorkMode[]> {
    return this.api1WorkModeControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, workModeIds);
  }

  public getReportPeriods$(reportPediodsIds: number[]): Observable<IReportPeriod[]> {
    return this.api1ReportPeriodsControllerService.get$(reportPediodsIds);
  }

  public getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]> {
    return this.api1FinancingSourceControllerService.getAll$(financingSourcesIds);
  }

  public reorder$(positionId1: number, positionId2: number, date: Date): Observable<any> {
    return this.loadingIndicatorService.addToObservable(
      "Изменение порядка позиции",
      this.api1PositionControllerService.reorder$(positionId1, positionId2, date)
    )
  }


  protected _reloadData$(params: PositionsGridComponentDataSourceServiceBase_Params): Observable<StaffPositionGridItem[]> {
    return this._reloadFromRemoteByIds$(params, null);
  }

  protected _reloadFromRemoteByIds$(params: PositionsGridComponentDataSourceServiceBase_Params, targets: StaffPositionGridItem["id"][]): Observable<StaffPositionGridItem[]> {
    return this.loadingIndicatorService.addToObservable(
      "Загрузка штатных позиций",
      this.api1StaffPositionsControlControllerService.getForDate$(params.subdivisionId, params.forDate, params.startDate, params.endDate, targets)
    ).pipe(map(m=> m.items));
  }
}

export interface PositionsGridComponentDataSourceServiceBase_Params extends IForDate {
  subdivisionId: number;
}
