import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { PopupRef, PopupService } from "@progress/kendo-angular-popup";
import {fromEvent, Subject, Subscription, skipUntil, timer, ReplaySubject} from "rxjs";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { DirectoryEditNotVersioningComponent } from "../not-versioning/directory-edit-not-versioning.component";
import { NotVersioningField } from "./classes/fields/not-versioning-field";
import { NoVersioningSavedData } from "./classes/no-versioning-saved-data";
import {takeUntil} from "rxjs/operators";

@traceClass('DirectoryEditNotVersioningService')
@Injectable()
export class DirectoryEditNotVersioningService implements OnDestroy {

  /** Стрим события сохранения данных (нажата кнопка сохранить на компоненте) */
  public onSave$: Subject<NoVersioningSavedData> = new Subject<NoVersioningSavedData>();
  public onClose$ = new Subject<void>();

  private _editPopupRef: PopupRef;
  private _editPopupAnchorRef: ElementRef;

  /* Подписка клика по странице */
  private _subscribeOnDocumentClick$: Subscription

  private _streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly popupService: PopupService, private readonly traceService: TracerServiceBase) { }

  /**
   * Начало редактирования (открывает popup с контролом редактирования)
   * @param dataItem Объект с данными
   * @param currentValue Текущее значение
   * @param field Настройки поля
   * @param nativeElement html элемент - к которому будет показываться popup
   */
  @traceFunc()
  public edit(dataItem: any, currentValue: any, field: NotVersioningField, nativeElement: any) {
    this._editPopupAnchorRef = new ElementRef(nativeElement);
    //Необходимо закрыть все другие попапы (если они были)
    this.closeEditPopupIfExists();
    //Необходимо установить минимальную ширину равную минимальной ширине переданного элемента nativeElement
    this.setMinWidthEqualElementWidth(field);

    //Открываем попап
    this._editPopupRef = this.popupService.open({
      anchor: this._editPopupAnchorRef,
      content: DirectoryEditNotVersioningComponent,
    });

    //Скрывать попап если страница была прокручена вне зоны видимости
    this._editPopupRef.popupAnchorViewportLeave.subscribe(() => {
      this.closeEditPopupIfExists();
    });

    //Заполняем необходимые поля компонента
    let componentInstance = this._editPopupRef.content.instance as DirectoryEditNotVersioningComponent;
    componentInstance.field = field;
    componentInstance.dataItem = dataItem;
    componentInstance.value = currentValue;

    //Подписываемся на событие сохранения значения в конмпоненте
    componentInstance.onSaveEventEmitter.subscribe(val => {
      //Бросаем наше событие сохранения
      this.onSave$.next(new NoVersioningSavedData(val, dataItem, field));
      //Закрываем попап
      this.closeEditPopupIfExists();
    });

    //Инициализируем компонент, который будет отображен внутри попапа
    componentInstance.initialize();

    this.subscribeOnDocumentClick();
  }

  /** Закрыть попап (если он был открыт) */
  @traceFunc()
  public close() {
    this.closeEditPopupIfExists();
  }

  @traceFunc()
  public ngOnDestroy() {
    this._streams$.unsubscribe.next(null);
    this._streams$.unsubscribe.complete();
    this.onSave$.complete();
    this.onSave$ = null;
    this.closeEditPopupIfExists()
    this.onClose$.complete();
    this.onClose$ = null;
  }

  /** Функция устанавливает минимальную ширину попапа равную ширине анкора попапа */
  @traceFunc()
  private setMinWidthEqualElementWidth(field: NotVersioningField) {
    let styleSettings = field.settings.styleSettings ?? {};
    //Устанавливаем только если не была задана минимальная ширина
    if (!styleSettings?.minWidth) {
      styleSettings.minWidth = this._editPopupAnchorRef.nativeElement.getBoundingClientRect().width;
    }
    field.settings.styleSettings = styleSettings;
  }

  /** Событие клика по странице */
  private subscribeOnDocumentClick() {
    this._subscribeOnDocumentClick$ = fromEvent(document, 'click')
      .pipe(
        skipUntil(timer(100)),// Пропустить первый 100мс, чтобы первый клик (если сервис был открыт после клика кнопки) не учитывался
        takeUntil(this._streams$.unsubscribe)
      )
      .subscribe(e => this.onDocumentClick(e));
  }

  /** Перехват события клика на странице (нужен чтобы скрывать popup при клике за его пределами) */
  private onDocumentClick(event: any): void {
    if (this._editPopupRef && !(this._editPopupAnchorRef.nativeElement.contains(event.target) ||
      this._editPopupRef.popupElement.contains(event.target) || event.composedPath().includes(this._editPopupRef.popupElement))) {
      this.closeEditPopupIfExists();
    }
  }

  /** Закрывает Popup если он был до этого открыт */
  @traceFunc()
  private closeEditPopupIfExists() {
    if (this._editPopupRef) {
      this._editPopupRef.close();
      this._editPopupRef = null;

      this._subscribeOnDocumentClick$?.unsubscribe();
      this.onClose$.next();
    }
  }
}

