import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IOccupationType extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityRowVersion {
  /** Наименование типа должности (категории персонала) */
  name: string;
  code: string;
  parentId: number;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IOccupationTypeOptional = Partial<IOccupationType>;

@classBackend('OccupationType', 'stafflist')
@className('OccupationType')
/** Тип должностей (категория персонала) */
export class OccupationType implements IOccupationType {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public code: string,
              public name: string,
              public parentId: number,
              public timestamp: [],
  ) {
  }
}
