<div kendoDialogContainer></div>

<kendo-treelist style="height:100%; width:100%"
                kendoTreeListExpandable
                kendoTreeListSelectable
                [kendoTreeListFlatBinding]="data$ | async"
                parentIdField="parentId"
                idField="id"
                [initiallyExpanded]="true"
                [selectable]="{
                  mode: 'single',
                  enabled: true
                }"
                (selectionChange)="onSelectionChanged($event)"
                [resizable]="false"

                KendoTreeListExpanded
                (dbCellClick)="onDblCellClick($event)"
                tableTitleTextWrap="true"
>
  <ng-template kendoTreeListToolbarTemplate>
    <div class="grid-header-content">
      <button kendoButton icon="plus" (click)="onClickAdd()" primary="true" [size]="'small'">Добавить</button>
      <button kendoButton icon="delete" (click)="onClickRemove()" [disabled]="!canDelete" primary="true" [size]="'small'">Удалить</button>
      <button (click)="startEditParentId()" [disabled]="!currentSelectedItem" kendoButton [icon]="'dictionary-add'" primary="true" [size]="'small'">Изменение иерархии</button>
    </div>
  </ng-template>
  <kendo-treelist-column field="name" title="Наименование категории должности" [expandable]="true" [width]="500"></kendo-treelist-column>
  <kendo-treelist-column field="code" title="Код" [width]="100"></kendo-treelist-column>
  <kendo-treelist-column field="comment" title="Комментарий"></kendo-treelist-column>
</kendo-treelist>

<!-- Шаблон для редактирования родителя -->
<ng-template #editParentIdTemplateRef let-formControl="formControl">
  <kendo-formfield>
    <kendo-label [for]="parentId" text="Выберите родителя:"></kendo-label>
    <kendo-dropdowntree #parentId
                        kendoDropDownTreeExpandable
                        [kendoDropDownTreeFlatBinding]="parents"
                        parentIdField="parentId"
                        [valueField]="'id'"
                        [textField]="'name'"
                        [(ngModel)]="editParentIdValue"
                        [valuePrimitive]="true"
                        [popupSettings]="{width:'auto'}"
                        [filterable]="true"
                        [dataItem]="getDataItemForEditParentId()"
                        [itemDisabled]="parentsDisabled.bind(this)"
    >
    </kendo-dropdowntree>
  </kendo-formfield>
</ng-template>
