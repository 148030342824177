import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {PropertyWrapFormControlType} from "../../../classes/types/property-wrap-form-control-type";

@Component({
  selector: 'app-report-period-settings',
  templateUrl: './report-period-settings.component.html',
  styleUrls: ['./report-period-settings.component.css']
})
export class ReportPeriodSettingsComponent implements OnInit {
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() reportDate: Date;
  @Input() dialogTitle: string;

  /** Событие отмены выбора */
  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Событие выбора */
  @Output() public select$: EventEmitter<PrintReportPeriod> = new EventEmitter<PrintReportPeriod>();

  public form: FormGroup<PrintReportPeriodForm>;

  constructor() { }

  ngOnInit() {
    this.initForm();
  }


  /**
   * Обработка события нажания на кнопку "Выбрать"
   */
  onOk() {
    this.select$.emit(this.form.getRawValue());
    this.select$.complete();
  }

  /**
   * Обработка события нажания на кнопку "Отмена"
   */
  onCancel() {
    this.cancel$.emit();
    this.cancel$.complete();
  }

  /** Инициализация формы */
  private initForm(){
    const fromDate = new Date(this.reportDate.getFullYear(), this.reportDate.getMonth(), 1);
    const tillDate = new Date(this.reportDate.getFullYear(), this.reportDate.getMonth()+1, 0);

    this.form = new FormGroup<PrintReportPeriodForm>({
      startDate: new FormControl<Date>(fromDate,
        { validators: [Validators.required],
          updateOn: 'change' }),
      endDate: new FormControl<Date>(tillDate,
        { validators: [Validators.required],
      updateOn: 'change'})
    });

    this.form.markAllAsTouched();
  }
}

export class PrintReportPeriod {
  constructor(  public startDate: Date,
                public endDate: Date) {  }
}

export type PrintReportPeriodForm = PropertyWrapFormControlType<PrintReportPeriod>;
