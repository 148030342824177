import { filter, map, Observable, tap } from "rxjs";
import { DataSource } from "src/app/classes/array-data-sources/data-source";
import { PositionRate } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import { StaffUnit } from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { IForDate } from "src/app/classes/for-date";
import { DataSourceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";
import { FreeRatesDTO } from "../webApi/webApi1/controllers/api1-staff-units-control.service";

export class FreeRatesDataSourceService
extends DataSourceWithParamsBase<IFreeRatesDataSourceService_Params, FreeRatesDTO>{

  public paramsDataSource: DataSource<IFreeRatesDataSourceService_Params> = new DataSource<IFreeRatesDataSourceService_Params>();
  public dataSource: DataSource<FreeRatesDTO> = new DataSource<FreeRatesDTO>();

  constructor(private readonly parentDataSourceService: IFreeRatesDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
      staffUnits: StaffUnit,
      positionRates: PositionRate
    })
      .pipe(
        map(value => value.data),
        map(value => ({
          staffUnits: value.staffUnits.filter(f=> this.dataSource.data.staffUnitIds.some(s=> f.currentOrOrigin.ownerId) || f.currentOrOrigin.positionId === this.paramsDataSource.data.positionId),
          positionRates: value.positionRates.filter(f=> this.dataSource.data.positionRateIds.some(s=> f.currentOrOrigin.ownerId) || f.currentOrOrigin.positionId === this.paramsDataSource.data.positionId),
        })),
        map(f=> ([!!f.positionRates.length, !!f.staffUnits.length])),
        filter(f=> f.some(s=> s)),
        map(value => this.updateData$())
      );
  }

  protected _reloadData$(params: IFreeRatesDataSourceService_Params): Observable<FreeRatesDTO> {
    return this.parentDataSourceService.getFreeRates$(params);
  }
}

export interface IFreeRatesDataSourceService_Params extends Pick<IForDate, 'startDate' | 'endDate'>{
  executionDutiesFlag: boolean;
  positionId: number;
  excludeStaffUnitOwnerIds: number[];
}

export interface IFreeRatesDataSourceService_ParentDataSourceService {
  getFreeRates$(params: IFreeRatesDataSourceService_Params): Observable<FreeRatesDTO>;
}
