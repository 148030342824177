<form class="k-form" [formGroup]="form">
  <div class="form-inline-div">
    <kendo-formfield>
      <kendo-label [for]="passportType" text="Тип документа"></kendo-label>
      <kendo-dropdownlist
        #passportType
        formControlName="passportType"
        [data]="passportTypes"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
    >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield class="seria-control-formfield">
      <kendo-label [for]="seria" text="Серия"></kendo-label>
      <input kendoTextBox #seria formControlName="seria" />
    </kendo-formfield>

    <kendo-formfield class="number-control-formfield">
      <kendo-label [for]="number" text="Номер"></kendo-label>
      <input kendoTextBox #number formControlName="number" />
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="issueDate" text="Дата выдачи"></kendo-label>
      <kendo-datepicker #issueDate [formControlName]="'issueDate'" [max]="currentDate"></kendo-datepicker>
    </kendo-formfield>
  </div>
  <div class="form-inline-div">
    <kendo-formfield class="whom-form-field">
      <kendo-label [for]="whom" text="Кем выдан"></kendo-label>
      <input kendoTextBox #whom [formControlName]="'whom'" />
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="divisionCode" text="Код подразделения"></kendo-label>
      <kendo-maskedtextbox #divisionCode [formControlName]="'divisionCode'" [mask]="'000-000'"></kendo-maskedtextbox>
    </kendo-formfield>
  </div>
</form>
