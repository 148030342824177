import {RootPathItem} from "../../../classes/uiRootPath/root-path-item";
import {Injectable} from "@angular/core";
import {AppSettingsService} from "../../app-settings.service";

/**
 * Сервис получения путей запроса для WebApiAuth
 */
@Injectable({
  providedIn: "root"
})
export class WebApiAuthService {
  /**
   * Базовый путь
   */
  private readonly baseRoot: RootPathItem = null;

  /**
   * Контроллеры WebApiAuth
   */
  public controllers = {

    auth: {
      controller: new RootPathItem('Auth', () => this.baseRoot),
      actions: {
        getToken: new RootPathItem('GetToken', () => this.controllers.auth.controller),
        refreshToken: new RootPathItem('RefreshToken', () => this.controllers.auth.controller),
        getTokenAndAuthUser: new RootPathItem('GetTokenAndAuthUser', () => this.controllers.auth.controller),
        refreshTokenAndAuthUser: new RootPathItem('RefreshTokenAndAuthUser', () => this.controllers.auth.controller)
      }
    },

    authUser: {
      controller: new RootPathItem('AuthUser', () => this.baseRoot),
      actions: {
        getCurrent: new RootPathItem('GetCurrent', () => this.controllers.authUser.controller)
      }
    }
  }

  /**
   * Конструктор
   * @param appSettingsService
   */
  constructor(appSettingsService: AppSettingsService) {
    this.baseRoot = new RootPathItem(appSettingsService.webApiAuthBaseUrl, null);
  }
}
