import { Injectable, OnDestroy } from "@angular/core";
import { DialogService } from "@progress/kendo-angular-dialog";
import { catchError, defer, EMPTY, Observable, of, ReplaySubject, Subject, switchMap, takeUntil, throwError } from "rxjs";
import { EditEntityWithDependenciesErrorData, HierarchiString } from "src/app/classes/requestResults/EditEntityWithDependenciesErrorData";
import { ResponseObjError } from "src/app/classes/requestResults/responseObjError";
import { HierarchiStringsConflictsComponent } from "../hierarchi-strings-conflicts.component";

/** Сервис предназначен для отображения диалогового окна с конфликтами */
@Injectable()
export class HierarchiStringsConflictsDialogService implements OnDestroy {

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(private readonly dialogService: DialogService)  {

  }

  /** Показать окно с конфликтами */
  public show(hierarchiStrings: HierarchiString[]) : HierarchiStringsConflictsDialogService_Result{

    const dialogRef = this.dialogService.open({
      title: "Подтвердите действие",
      width: "96%",
      height: "90%",
      content: HierarchiStringsConflictsComponent
    });

    const resultSubject$ = new Subject<boolean>();

    const component = dialogRef.content.instance as HierarchiStringsConflictsComponent;
    component.hierarchiStrings = hierarchiStrings;

    component.cancel.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(s=> {
      resultSubject$.next(false);
      resultSubject$.complete();
      dialogRef.close();
    });

    component.continue.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(s=> {
      resultSubject$.next(true);
      resultSubject$.complete();
      dialogRef.close();
    });

    dialogRef.result.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(s=> {
      resultSubject$.next(false);
      resultSubject$.complete();
    });

    return new HierarchiStringsConflictsDialogService_Result(resultSubject$.pipe(takeUntil(this.streams$.unsubscribes)));
  }

  /** Обёрка для Observable, которая будет открывать диалоговое окно с подтверждением действия если бросается ошибка
   * при редактировании даты начала или окончания
   */
  public show2$<TResult>(func: (isSafe: boolean) => Observable<TResult>) : Observable<TResult> {
    return defer(() => {
      return func(true).pipe(catchError((error, obs$)=> {
        if(ResponseObjError.checkType(error)){
          if (ResponseObjError.isEntityEditEndDateException(error) || ResponseObjError.isEntityEditStartDateException(error) || ResponseObjError.isEntityEditDeletedFlagException(error)) {
            const errorData = error as ResponseObjError<EditEntityWithDependenciesErrorData>;
            const result = this.show(errorData.data.HierarchiStrings);
            return result.onResult$.pipe(switchMap(r=> {
              if(r){
                return func(false);
              } else {
                return EMPTY;
              }
            }));
          }
        }
        return throwError(() => error);
      }));
    });
  }

  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}

export class HierarchiStringsConflictsDialogService_Result {
  constructor(
    public onResult$: Observable<boolean>
  ) {}
}
