import {MessageService} from "@progress/kendo-angular-l10n";
import { Injectable } from "@angular/core";

/**
 * Сервис переводит сообщения кендо компонентов
 * https://www.telerik.com/kendo-angular-ui/components/globalization/localization/messages/ - раздел Using the Message Service
 * https://github.com/telerik/kendo-angular-messages/tree/develop/messages  - список возможных сообщений по компонентам
 */
@Injectable()
export class KendoMessageService extends MessageService{
  /**
   * Сообщения в переводе
   */
  messages: any = {}

  /**
   * Конструктор
   */
  constructor() {
    super();
    /** Заполнение объекта сообщениями */
    new GridMessage().fillMessageObj(this.messages);
    new TimePickerMessage().fillMessageObj(this.messages);
    new TreeListMessage().fillMessageObj(this.messages);
    new DatePickerMessage().fillMessageObj(this.messages);
    new DateTimePickerMessage().fillMessageObj(this.messages);
    new UploadMessage().fillMessageObj(this.messages);
    new DropDownMessage().fillMessageObj(this.messages);
    new ComboboxMessage().fillMessageObj(this.messages);
    new NumericTextBox().fillMessageObj(this.messages);
    new MultiViewCalendarMessage().fillMessageObj(this.messages);
    new SelectFileMessage().fillMessageObj(this.messages);
    new DialogMessage().fillMessageObj(this.messages);
    new SchedulerMessage().fillMessageObj(this.messages);
  }

  /**
   * В этот метод будет обращаться kendo компонент для получения перевода сообщения
   * @param key
   */
  public get(key: string): string{
    if(!this.messages[key]){
      //console.log(key);
    }
    return this.messages[key];
  }
}

/**
 * Базовый класс сообщений компонентов
 */
abstract class MessageBase {
  /**
   * Первая часть ключа сообщения
   */
  private static kendo = 'kendo';
  /**
   * Вторая часть ключа
   * Название компонента
   */
  protected abstract componentName: string;
  /**
   * Связь названия сообщения с текстом который должен отображаться
   */
  protected abstract messages: Array<[string, string]>;

  /**
   * Метод заполняет переданный объект данными
   * @param target
   */
  public fillMessageObj(target: object){
    this.messages.forEach((item) => {
      target[`${MessageBase.kendo}.${this.componentName}.${item[0]}`] = item[1];
    });
  }
}

/**
 * Сообщения компонента grid
 */
class GridMessage extends MessageBase{
  protected componentName: string = 'grid';
  protected messages: Array<[string, string]> = getFilterMessage().concat([
    ['noRecords', 'Нет записей'],
    ['detailExpand', 'Раскрыть детали'],
    ['detailCollapse', 'Свернуть детали'],
    ['pagerItems','элементов'],
    ['pagerLastPage','Перейти на последнюю страницу'],
    ['pagerFirstPage','Перейти на первую страницу'],
    ['pagerNextPage','Перейти на следующую страницу'],
    ['pagerPreviousPage','Перейти на предыдущую страницу'],
    ['pagerOf','из'],
    ['filter', 'Фильтрация'],
    ['groupCollapse', 'Свернуть группу'],
    ['groupExpand', 'Развернуть группу'],
    ['filterMenuTitle' , 'Фильтровать'],
  ]);
}

/** Сообщения компонента dropdown */
class DropDownMessage extends MessageBase{
  protected componentName: string = 'dropdownlist';
  protected messages: Array<[string, string]> = [
    ['noDataText', 'Нет данных']
  ]
}

/** Сообщения компонента dialog */
class DialogMessage extends MessageBase{
  protected componentName: string = 'dialog';
  protected messages: Array<[string, string]> = [
    ['closeTitle', 'Закрыть']
  ]
}

/** Сообщения компонента combobox */
class ComboboxMessage extends MessageBase{
  protected componentName: string = 'combobox';
  protected messages: Array<[string, string]> = [
    ['noDataText', 'Нет данных'],
    ['clearTitle', 'Очистить']
  ];
}

/** Сообщения компонента numerictextbox */
class NumericTextBox extends MessageBase{
  protected componentName: string = 'numerictextbox';
  protected messages: Array<[string, string]> = [
    ['decrement', 'Уменьшить'],
    ['increment', 'Увеличить']
  ];

}

/**
 * Сообщения для компонента timepicker
 */
class TimePickerMessage extends MessageBase{
  protected componentName: string = 'timepicker';
  protected messages: Array<[string, string]> = [
    ['now','Сейчас'],
    ['nowLabel', 'Установить текущее время'],
    ['accept', 'Выбрать'],
    ['acceptLabel', 'Установить время'],
    ['cancel', 'Отмена'],
    ['cancelLabel', 'Отменить выбор времени'],
    ['toggle', 'Открыть список выбора времени']
  ];
}

/**
 * Сообщения для компонента datetimepicker
 */
class DateTimePickerMessage extends MessageBase{
  protected componentName: string = 'datetimepicker';
  protected messages: Array<[string, string]> = [
    ['today','СЕГОДНЯ'],
    ['todayLabel', 'Установить текущую дату'],
    ['now','СЕЙЧАС'],
    ['nowLabel', 'Установить текущее время'],
    ['accept', 'Выбрать'],
    ['acceptLabel', 'Установить время и дату'],
    ['cancel', 'Отмена'],
    ['cancelLabel', 'Отменить выбор времени и даты'],
    ['toggle', 'Открыть список выбора времени и даты'],
    ['dateTab','Дата'],
    ['dateTabLabel','Выбрать дату'],
    ['timeTab','Время'],
    ['timeTabLabel','Выбрать время'],

  ];
}


/**
 * Сообщения для компонента datepicker
 */
class DatePickerMessage extends MessageBase{
  protected componentName: string = 'datepicker';
  protected messages: Array<[string, string]> = [
    ['today','СЕГОДНЯ'],
    ['toggle', 'Открыть список выбора даты'],

  ];
}

/**
 * Сообщения для компонента treelist
 */
class TreeListMessage extends MessageBase{
  protected componentName: string = 'treelist';
  protected messages: Array<[string, string]> = getFilterMessage().concat([
    ['noRecords', 'Нет записей'],
    ['filter', 'Фильтрация'],
    ['sortAscending', 'Сортировать по возрастанию'],
    ['sortDescending', 'Сортировать по убыванию'],
    ['columns', 'Колонки'],
    ['columnsApply', 'Применить'],
    ['columnsReset' , 'Сбросить'],
    ['filterMenuTitle' , 'Фильтровать'],
    ['columnMenu' , 'Меню столбца'],
  ]);
}


/**
 * Сообщения для компонента upload
 */
class UploadMessage extends MessageBase{
  protected componentName: string = 'upload';
  protected messages: Array<[string, string]> = [
    ['cancel', 'Отмена'],
    ['clearSelectedFiles', 'Очистить выбранные файлы'],
    ['dropFilesHere', 'Перетащите файлы сюда'],
    ['fileStatusFailed', 'Ошибка при загрузке файла'],
    ['fileStatusUploaded', 'Файл успешно загружен'],
    ['remove', 'Удалить'],
    ['resume', 'Возобновить'],
    ['retry', 'Повторить'],
    ['pause', 'Приостановить'],
    ['select', 'Выбрать файлы...'],
    ['headerStatusPaused', 'Приостановлено'],
    ['headerStatusUploaded', 'Завершено'],
    ['headerStatusUploading', 'Загрузка'],
    ['uploadSelectedFiles', 'Загрузить']
  ]
}

/**
 * Сообщения для компонента selectFile
 */
class SelectFileMessage extends MessageBase{
  protected componentName: string = 'fileselect';
  protected messages: Array<[string, string]> = [
    ['dropFilesHere', 'Перетащите файлы сюда'],
    ['remove', 'Удалить'],
    ['select', 'Выбрать файлы...'],
    /*['invalidFileExtension', ''],
    ['invalidMaxFileSize', ''],
    ['invalidMinFileSize', ''],*/
  ];

}

/** Сообщения для компонента multiviewcalendar */
class MultiViewCalendarMessage extends MessageBase{
  protected componentName: string = 'multiviewcalendar';
  protected messages: Array<[string, string]> = [
    ['today', 'СЕГОДНЯ'],
    ['prevButtonTitle', 'Назад'],
    ['nextButtonTitle', 'Вперед'],
    ['parentViewButtonTitle', 'Перейти к родительскому просмотру']
  ];

}

/** Сообщения для компонента scheduler */
class SchedulerMessage extends MessageBase {
  protected componentName: string = 'scheduler';
  protected messages: Array<[string, string]> = [
    ['monthViewTitle', 'Месяц'],
    ['dayViewTitle', 'День'],
    ['weekViewTitle', 'Неделя'],
    ['agendaViewTitle', 'Список'],
    ['dateHeader', 'Дата'],
    ['timeHeader', 'Время'],
    ['eventHeader', 'Событие'],
    ['allDay', 'Весь день'],
    ['cancel', 'Отмена'],
    ['date', 'Дата'],
    ['event', 'Событие'],
    ['defaultRowText', 'Посещение врача'],
    ['save', 'Добавить событие'],
    ['showFullDay', '24 часовая неделя'],
    ['showWorkDay', 'Рабочая неделя'],
    ['time', 'Время'],
    ['today', 'Сегодня'],
    ['confirmation', 'Вы уверены, что хотите удалить текущую запись?'],
    ['editor.allDayEvent', 'Весь день'],
    ['editor.description', 'Примечание'],
    ['editor.editorTitle', 'Добавление нового события'],
    ['editor.end', 'Конец'],
    ['editor.endTimezone', 'Временная зона'],
    ['editor.repeat', 'Повторить'],
    ['editor.start', 'Начало'],
    ['editor.title', 'Заголовок'],
    ['calendarToday', 'Сегодня'],
    ['deleteTitle', 'Удалить'],
    ['nextTitle', 'Следующий'],
    ['previousTitle', 'Предыдущий'],
  ];

}

/**
 * Получить список фильра kendo
 */
function getFilterMessage(): Array<[string, string]>{
  return [
    ['filterEqOperator', 'Равно'],
    ['filterNotEqOperator', 'Не равно'],
    ['filterIsNullOperator', 'Значение отсутствует'],
    ['filterIsNotNullOperator', 'Значение присутствует'],
    ['filterIsEmptyOperator', 'Пустое значение'],
    ['filterIsNotEmptyOperator', 'Не пустое значение'],
    ['filterStartsWithOperator', 'Начинается с'],
    ['filterContainsOperator', 'Содержит'],
    ['filterNotContainsOperator', 'Не содержит'],
    ['filterEndsWithOperator', 'Заканчивается на'],
    ['filterGteOperator', 'Больше или равно'],
    ['filterGtOperator', 'Больше'],
    ['filterLteOperator', 'Меньше или равно'],
    ['filterLtOperator', 'Меньше'],
    ['filterFilterButton','Фильтровать'],
    ['filterClearButton','Очистить'],
    ['filterAndLogic','и'],
    ['filterOrLogic','или'],
    ['filterAfterOrEqualOperator', 'Позже или равно'],
    ['filterBeforeOrEqualOperator', 'Раньше или равно'],
    ['filterAfterOperator','Позже'],
    ['filterBeforeOperator','Раньше'],
    ['filterBooleanAll','Все'],
    ['filterIsTrue','Да'],
    ['filterIsFalse','Нет']
  ]
}
