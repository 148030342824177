import {Component, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { FileRestrictions, SuccessEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { ReplaySubject, Subject } from 'rxjs';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { IImportFromExcelComponent, ImportResult } from './i-import-from-excel.component';
import { ImportFromExcelComponentDataService } from './services/import-from-excel-component-data.service';
import { ImportFromExcelComponentImportSettingsService } from './services/import-from-excel-component-import-settings.service';
import { ImportFromExcelComponentService } from './services/import-from-excel-component.service';

@Component({
  selector: 'app-import-from-excel',
  templateUrl: './import-from-excel.component.html',
  styleUrls: ['./import-from-excel.component.css'],
  providers: [ImportFromExcelComponentService, ImportFromExcelComponentDataService, ImportFromExcelComponentImportSettingsService]
})
@traceClass('ImportFromExcelComponent')
export class ImportFromExcelComponent implements OnInit, OnDestroy, IImportFromExcelComponent {

  /** Url адрес сохранения файла */
  @Input() uploadSaveUrl: string;

  /** Url адрес сохранения файла */
  @Input() downloadTemplateUrl: string;

  /** Subject результата импорта. Успешного или не успешного. */
  @Output("onImportResult$") onImportResult$: Subject<ImportResult> = new Subject<ImportResult>();

  @Input() settingsTemplate: TemplateRef<any>;

  public canImport: boolean;

  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: ['.xlsx']
  };

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Конструктор */
  constructor(private service: ImportFromExcelComponentService, public importFromExcelComponentImportSettingsService: ImportFromExcelComponentImportSettingsService,
    private traceService: TracerServiceBase  ) {
    service.component = this;
  }

  @traceFunc()
  ngOnInit() {
    if(!this.uploadSaveUrl) {
      throw new Error("@Input() uploadSaveUrl обязателен для заполнения в компоненте ImportFromExcelComponent");
    }

    if(!this.downloadTemplateUrl) {
      throw new Error("@Input() downloadTemplateUrl обязателен для заполнения в компоненте ImportFromExcelComponent");
    }

    this.service.init();
  }

  /** Событие происходит в момент отправки файла */
  @traceFunc()
  public onUpload(e: UploadEvent) {
    this.service.onUpload(e);
  }

  /** Событие происходит, если с сервера пришел успешный ответ. */
  @traceFunc()
  public successEventHandler(e: SuccessEvent) {
    this.service.successUploadedEventHandler(e);
  }

  /** Событие происходитпри клике на кнопку скачивания шаблона */
  @traceFunc()
  public onClickDownloadTemplate() {
    this.service.downloadTemplate();
  }

  /** Обработчик ошибок импорта */
  @traceFunc()
  public errorEventHandler(e: any) {
    this.service.errorUploadEventHandler(e);
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

