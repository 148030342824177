import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { IWorkModeOptional } from 'src/app/classes/domain/POCOs/stafflist/WorkMode';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { IAddWorkModeFormComponent, IAddWorkModeFormComponentForm } from './i-add-work-mode-form.component';
import { AddWorkModeFormComponentService } from './services/add-work-mode-form.component.service';

@Component({
  selector: 'app-add-work-mode-form',
  templateUrl: './add-work-mode-form.component.html',
  styleUrls: ['./add-work-mode-form.component.css'],
  providers: [AddWorkModeFormComponentService]
})

@traceClass('AddWorkModeFormComponent')
export class AddWorkModeFormComponent implements IAddWorkModeFormComponent, OnInit {

  /* Источник данных. Отлавливает изменение данных. */
  @Input("dataItem") public dataItem: IWorkModeOptional;

  /* Типы режимов работы */
  @Input("workModeTypesDropDownItems") public workModeTypesDropDownItems: Array<IDropDownItem>;

  @Output("onSave") public onSaveEventEmitter = new EventEmitter<IWorkModeOptional>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>();

  public form: FormGroup<IAddWorkModeFormComponentForm>;

  constructor(private service: AddWorkModeFormComponentService, private traceService: TracerServiceBase) {
    this.service.component = this;
  }

  @traceFunc()
  public ngOnInit() {
    this.service.init();
  }

  /** Событие происходит при клике на кнопку сохранения */
  @traceFunc()
  public onClickSave() {
    this.service.onClickSave();
  }

  /** Событие происходит при клике на кнопку отмены */
  @traceFunc()
  public onClickCancel() {
    this.service.onClickCancel();
  }
}
