import {Injectable, OnDestroy} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../services/storages/custom-storage.service";
import {AuthServerResponse} from "../classes/auth-classes";

/** Сервис хранения авторизационных данных */
@Injectable({providedIn: "root"})
export class AuthService_AuthDataStorageService implements OnDestroy{

  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    /**  */
    value: new ReplaySubject<AuthServerResponse>(1)
  }

  /** Ключ для доступа к хранилищу авторизационных данных */
  private readonly storageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'AuthService/Token',
    null,
    true,
    false
  );

  private _authData: AuthServerResponse = null;

  /** Авторизационные данные */
  public get authDate(){
    return this._authData;
  }

  public set authDate(value){
    const copied = AuthServerResponse.Copy(value);
    this.customStorageService.set(this.storageOptions, copied);
    this._authData = copied;
    this.streams$.value.next(copied);
  }

  /** Авторизационные данные как стрим(ReplaceSubject) */
  public get authDate$(): Observable<AuthServerResponse>{
    return this.streams$.value;
  }

  constructor(private readonly customStorageService: CustomStorageService,) {
    const fromStorage = AuthServerResponse.Copy(this.customStorageService.get<AuthServerResponse>(this.storageOptions));
    this._authData = fromStorage;
    this.streams$.value.next(fromStorage);
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.value.complete();
  }
}
