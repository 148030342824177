import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable, ReplaySubject} from "rxjs";
import {WebApi1Service} from "../web-api1.service";
import {Code, ICode} from "src/app/classes/domain/POCOs/timesheet_table/Code";

@Injectable({
  providedIn: 'root'
})
export class Api1CodeControllerService {
  private unsubscribe$ = new ReplaySubject<any>(1);

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {

  }



  public getAll$(): Observable<Array<Code>>{
    return this.httpClient
      .get<Array<Code>>(this.webApi1Service.controllers.code.actions.getAll.toString());
  }

  /** Получить коды табельного учета для отображения на панели */
  public getCodesForCodePanel$(): Observable<Array<CodePanelItem>>{
    return this.httpClient
      .get<Array<CodePanelItem>>(this.webApi1Service.controllers.code.actions.getCodesForCodePanel.toString());
  }
}

export class CodePanelItem implements Pick<ICode, 'id' | 'name' | 'key' | 'isComputedFlag' | 'supportValue2' | 'isEqualBaseRowValue'>{
  id: number;
  name: string;
  key: string;
  isComputedFlag: boolean;
  supportValue2: boolean;
  isEqualBaseRowValue: boolean;
}
