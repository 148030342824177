<ng-container [formGroup]="form">
  <div style="display: flex; margin-bottom: 5px; margin-left: 20px;" >
    <input kendoCheckBox
           class="k-checkbox"
           type="checkbox"
           #allSubdivision
           [indeterminate]="false"
           formControlName="allSubdivisions"
    >

    <kendo-label style="margin-left: 10px;"
                 class="k-checkbox-label"
                 [for]="allSubdivision"
                 text="Выбрать все подразделения"
    ></kendo-label>
  </div>

  <app-subdivision-treeview2 [dataSourceService]="dataSourceService"
                             [selection]="deferSelectionService.tempSelection.data$ | async"
                             [expandAll]="expandAll"
                             [checkableSettings] = "checkableSettings"
                             [displayTextFn]="displayTextFn"
                             (checkedSubdivisions$)="checkedSubdivisions = $event"
  >
  </app-subdivision-treeview2>
</ng-container>
