import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import { HttpParamsHelper } from 'src/app/helpers/httpParamsHelper';
import { map } from 'rxjs/operators';
import {IStaffUnit, StaffUnit} from "src/app/classes/domain/POCOs/stafflist/StaffUnit";
import { Employee } from "src/app/classes/domain/POCOs/stafflist/Employee";
import { IFinancingSource } from "src/app/classes/domain/POCOs/stafflist/FinancingSource";
import {IStaffUnitType} from "src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import { IOccupation } from "src/app/classes/domain/POCOs/stafflist/Occupation";
import {ISubdivision} from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import {IconsUrlHelper} from "../../../../helpers/icons-url.helper";
import {Helper} from "../../../../../../projects/timesheet/src/app/components/graph-table-workspace/graph-grid/classes/helpers/grid-helper.class";
import {
  DissmissedStaffUnitBlockItem
} from "../../../../../../projects/timesheet/src/app/components/table-dismissed/services/table-dismissed-data.service";
import { IPosition } from "src/app/classes/domain/POCOs/stafflist/Position";
import { IWorkMode } from "src/app/classes/domain/POCOs/stafflist/WorkMode";

/**
 * Сервис работы с контроллером StaffUnitsControlController
 */
@Injectable({
  providedIn: "root"
})
export class Api1StaffUnitsControlControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить StaffUnit
   * @param staffPositionOwnerId
   * @param date;
   */
  public getForDate$(staffPositionOwnerId: number, date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<StaffUnitGridItem>>{
    return this.httpClient
      .post<IStaffUnitsDTO>(this.webApi1Service.controllers.staffUnitsControl.actions.getForDate.toString(),
        {
          staffPositionOwnerId: staffPositionOwnerId,
          date: date,
          startDate: startDate,
          endDate: endDate,
          ownerIds: ownerIds
        }).pipe(map(data => data.staffUnits.map(staffUnit => {

          const employee = data.employees.find(f => f.id === staffUnit.employeeId);
          const type = data.staffUnitTypes.find(f => f.id === staffUnit.typeId);
          const position = data.positions.find(f => f.id === staffUnit.positionId);

          return new StaffUnitGridItem(
            staffUnit.id,
            staffUnit.startDate,
            staffUnit.endDate,
            staffUnit.executionDutiesFlag,
            Employee.fullName(employee),
            staffUnit.employeeId,
            employee.timestamp,
            employee.code,
            staffUnit.rate,
            staffUnit.financingSourceId,
            data.financingSources.find(f=> f.id === staffUnit.financingSourceId).shortName,
            staffUnit.comment,
            staffUnit.positionId,
            staffUnit.typeId,
            staffUnit.timestamp,
            type.description,
            staffUnit.isShortRate,
            staffUnit.milk,
            position,
            staffUnit.percent
          );
        })));
  }

  /**
   * Получить Positions с дополнительными данными
   */
  public getPositionsForDate$(subdivisionIds: number[], date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<PositionWithExtendedData>>{
    return this.httpClient
      .post<IGetPositionsForDateResponse>(this.webApi1Service.controllers.staffUnitsControl.actions.getPositionsForDate.toString(),
        {
          SubdivisionIds: subdivisionIds,
          Date: date,
          StartDate:startDate,
          EndDate: endDate,
          OwnerIds: ownerIds
        }).pipe(map(data => data.positions.map(position => {

          const occupation = data.occupations.find(o=> o.id === position.occupationId);
          const workMode = data.workModes.find(o=> o.id === position.workModeId);

          return new PositionWithExtendedData(
            position.id,
            position.startDate,
            position.endDate,
            position.subdivisionId,
            position.occupationId,
            position.workModeId,
            occupation,
            workMode
          );
        })));
  }


  /**
   * Получить вакантные ставки и источники финансирования
   * @param staffPositionOwnerId
   * @param date;
   */
  public getFreeRates$(staffPositionOwnerId: number, executionDutiesFlag: boolean, startDate: Date = null, endDate: Date = null, excludeStaffUnitOwnerIds: number[] = null): Observable<FreeRatesDTO>{
    return this.httpClient
      .post<IGetFreeRatesResponse>(this.webApi1Service.controllers.staffUnitsControl.actions.getFreeRates.toString(),
        {
          staffPositionOwnerId: staffPositionOwnerId,
          startDate: startDate,
          endDate: endDate,
          excludeStaffUnitOwnerIds: excludeStaffUnitOwnerIds,
          executionDutiesFlag: executionDutiesFlag
        })
        .pipe(
          map(d=> new FreeRatesDTO(d.financingSources.map(financingSource =>
            {
              return new FreeRateDTO2(financingSource, d.freeRates.find(f=> f.financingSourceId === financingSource.id)?.rate ?? 0)
            }),
            d.staffUnitIds,
            d.positionRateIds)
          )
        );
  }

  /**
   * Сохранить ставку для стаффюнита
   */
  public saveStaffUnitWithShortRate$(staffUnitId: number, rate: number): Observable<IStaffUnit> {
    return this.httpClient
      .post<IStaffUnit>(this.webApi1Service.controllers.staffUnitsControl.actions.saveStaffUnitWithShortRate.toString(),
        {
          staffUnitId: staffUnitId,
          rate: rate,
        });
  }

  /** Получить список для заместителей Proxy */
  public getStaffUnitsForProxy$(startDate: Date, endDate : Date) : Observable<Array<StaffUnitForProxyList>>{
    return this.httpClient.get<StaffUnitsForProxyDTO>(this.webApi1Service.controllers.staffUnitsControl.actions.getStaffUnitsForProxy.toString(),
      {
        params: HttpParamsHelper.getParams([['startDate', startDate], ['endDate', endDate]])
      }
    ).pipe(
      map(source=> source.staffUnitsData.map(su=>
        {
          const occupation = source.occupations.find(f => f.id === su.occupationId);
          const subdivision = source.subdivisions.find(f => f.id === su.subdivisionId);
          const employee = source.employers.find(f => f.id === su.employeeId);

          return new StaffUnitForProxyList
          (
            su.ownerId,
            employee?.ownerId,
            employee?.code,
            occupation?.name,
            Employee.fullName(employee),
            su.startDate,
            su.endDate,
            su.rate,
            subdivision?.longName,
            su.staffUnitTypeId,
            su.parentId,
            su.timestamp
          );
        })
      ));
  }

  /** Получить основную/внешнееСовместительство по идентификатору сотрудника */
  public getBasicOrExternalStaffUnitByEmployeeOwnerId$(start: Date, end: Date, employeeOwnerId: number){
    return this.httpClient.post<GetBasicOrExternalStaffUnitByEmployeeOwnerIdResponse>(
      this.webApi1Service.controllers.staffUnitsControl.actions.getBasicOrExternalStaffUnitByEmployeeOwnerId.toString(),
      {
        start: start,
        end: end,
        employeeOwnerId: employeeOwnerId
      }
    )
  }

  /** Получить список уволенных в течении периода сотрудников в данном подразделении */
  public getDismissedStaffUnitsBySubdivisionId$(startPeriod: Date, endPeriod: Date, redactionId: number, subdivisionId: number){
    return this.httpClient.post<DissmissedStaffUnitBlockItem[]>(
      this.webApi1Service.controllers.staffUnitsControl.actions.getDismissedStaffUnitsBySubdivisionId.toString(),
      {
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        redactionId: redactionId,
        subdivisionId: subdivisionId
      }
    )
  }
}


interface IGetPositionsForDateResponse {
  positions: Pick<IPosition,'id' | 'startDate' | 'endDate' | 'subdivisionId' | 'occupationId' | 'workModeId'>[],
  occupations: Pick<IOccupation, 'id' | 'startDate' | 'endDate' | 'name' | 'code' | 'occupationTypeId'>[],
  workModes: Pick<IWorkMode, 'id' | 'startDate' | 'endDate' | 'name' | 'workModeTypeId' | 'arrangedForWeekFlag' | 'workingDays' | 'workdayHourDuration' | 'preHolidayHourDuration'>[]
}

interface IStaffUnitsDTO
{
  staffUnits: Array<IGetForDateStaffUnit>;
  employees: Array<Pick<Employee, 'id' | 'code' | 'lastName' | 'firstName' | 'patronymic' | 'timestamp'>>;
  financingSources: Array<Pick<IFinancingSource, 'id' | 'name' | 'shortName'>>;
  staffUnitTypes: Array<Pick<IStaffUnitType, 'id' | 'description'>>;
  positions: Array<Pick<IPosition, 'id' | 'startDate' | 'endDate'>>;
}

interface IGetForDateStaffUnit extends Pick<StaffUnit, 'id' | 'startDate' | 'endDate' | 'executionDutiesFlag' | 'employeeId' | 'rate' | 'financingSourceId' | 'comment' | 'positionId' | 'typeId' | 'timestamp' | 'milk' | 'percent'>
{
  isShortRate: boolean;
}

interface StaffUnitsForProxyDTO {
  occupations: Array<Pick<IOccupation, 'id' | 'name'>>;
  staffUnitsData: Array<StaffUnitForProxyListItemDTO>;
  subdivisions: Array<Pick<ISubdivision, 'id' | 'longName'>>;
  employers: Array<Pick<Employee, 'id' | 'ownerId' | 'code' | 'lastName' | 'firstName' | 'patronymic'>>;
}

interface StaffUnitForProxyListItemDTO extends Pick<StaffUnit, 'ownerId' | 'employeeId' | 'startDate' | 'endDate' | 'rate' | 'parentId' | 'timestamp'> {
  occupationId : number;
  subdivisionId : number;
  staffUnitTypeId : number;
}

interface IGetFreeRatesResponse_FreeRate{
  financingSourceId: number;
  rate: number;
}

interface IGetFreeRatesResponse {
  financingSources: Array<Pick<IFinancingSource, 'id' | 'name' | 'shortName'>>;
  freeRates: Array<IGetFreeRatesResponse_FreeRate>;
  staffUnitIds: number[];
  positionRateIds: number[];
}


export class GetBasicOrExternalStaffUnitByEmployeeOwnerIdResponse implements Pick<IStaffUnit, 'ownerId' | 'startDate' | 'endDate' | 'rate' | 'employeeId'> {
  constructor(
    public ownerId: number,
    public startDate: Date,
    public endDate: Date,
    public rate: number,
    public employeeId: number,
    public fio: string
  ) {}
}


/**
 * Модель данных для отображения в окне выбора уволенных сотрудников
 */
export class GetDismissedStaffUnitsBySubdivisionIdResponseItem {
  constructor( public staffUnitOwnerId: number, public employeeFullName: string, public occupationName: string) {  }
}


/**
 * Модель списка выбора StaffUnit для Proxy
 */
export class StaffUnitForProxyList implements Pick<IStaffUnit, 'ownerId' | 'employeeId' | 'startDate' | 'endDate' | 'rate' | 'parentId' | 'typeId' | 'timestamp'>  {
  /** img строки */
  public get imageUrl(): string{
    return IconsUrlHelper.getstaffUnitImagePath(this.typeId, this.isProxy);
  }
  /** Является ли Proxy */
  public get isProxy(): boolean{
    return !!this.parentId;
  }

  /** Является ли внешним сотрудником */
  public get isExternal(): boolean{
    return Helper.isExternalStaffUnit(this.typeId);
  }

  constructor(
    public ownerId: number,
    public employeeId : number,
    public code : string,
    public occupationName : string,
    public employeeFullName : string,
    public startDate : Date,
    public endDate : Date,
    public rate : number,
    public subdivisionName : string,
    public typeId : number,
    public parentId: number,
    public timestamp: []
  ){}

}

export class StaffUnitGridItem implements Pick<IStaffUnit, 'id' | 'startDate' | 'endDate' | 'executionDutiesFlag' | 'employeeId' | 'rate' | 'financingSourceId' | 'comment' | 'positionId' | 'typeId' | 'timestamp' | 'milk' | 'percent'> {
  constructor(
    public id: number,
    public startDate: Date,
    public endDate: Date,
    public executionDutiesFlag: boolean,
    public fio: string,
    public employeeId: number,
    public employeeTimestamp: [],
    public code: string,
    public rate: number,
    public financingSourceId: number,
    public financingSourceShortName: string,
    public comment: string,
    public positionId: number,
    public typeId: number,
    public timestamp: [],
    public typeDescription: string,
    public isShortRate: boolean,
    public milk: boolean,
    public position: Pick<IPosition, 'id' | 'startDate' | 'endDate'>,
    public percent: number | null
  ){}

}

export class PositionWithExtendedData implements Pick<IPosition,'id' | 'startDate' | 'endDate' | 'subdivisionId' | 'occupationId' | 'workModeId'>  {
  constructor(
    public id: number,
    public startDate: Date,
    public endDate: Date,
    public subdivisionId: number,
    public occupationId: number,
    public workModeId: number,
    public occupation: IGetPositionsForDateResponse['occupations'][0],
    public workMode: IGetPositionsForDateResponse['workModes'][0]
  ) { }
}

export class FreeRatesDTO {
  constructor(
   public freeRates: FreeRateDTO2[],
   public staffUnitIds: number[],
   public positionRateIds: number[]
  ) {}
}
export class FreeRateDTO2 {
  constructor(
    public financingSource: IGetFreeRatesResponse["financingSources"][0],
    public rate: IGetFreeRatesResponse['freeRates'][0]['rate']
  ) {}
}
