import {Injectable} from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import {combineLatest, map, tap} from "rxjs/operators";
import {HttpHeadersHelper} from "../helpers/HttpHeadersHelper";
import {combineLatestWith, Observable} from "rxjs";

/** Путь по умолчанию для получения config файла приложения */
const AppConfigPathDefault = '/assets/appconfig.json';
/** Путь по умолчанию для получения версии приложения */
const AppVersionPathDefault = '/assets/appversion.json';

/** Сервис настроек приложения */
@Injectable({
  providedIn:"root"
})
export class AppSettingsService implements IAppSettings, IAppVersion{
  /** Настройки полученные с сервера */
  protected settings: IAppSettings & IAppVersion;

  /** Компания */
  public company: string;
  /** @inheritDoc */
  get program(){
    return this.settings?.program;
  }
  /** @inheritDoc */
  get webApiBaseUrl(){
    return this.settings?.webApiBaseUrl;
  }
  /** @inheritDoc */
  get webApiAuthBaseUrl(){
    return this.settings?.webApiAuthBaseUrl;
  }
  /** @inheritDoc */
  get webApiLoggingPath(){
    return this.settings?.webApiLoggingPath;
  }
  /** @inheritDoc */
  get webApiFormatSettings(){
    return this.settings?.webApiFormatSettings;
  }
  /** @inheritDoc */
  get workspaceRoute(){
    return this.settings?.workspaceRoute;
  }
  /** @inheritDoc */
  get signalRPath(){
    return this.settings?.signalRPath;
  }
  /** @inheritDoc */
  get storageVersion(){
    return this.settings?.storageVersion;
  }
  /** @inheritDoc */
  get disconnectedSignalRMaxTime(){
    return this.settings?.disconnectedSignalRMaxTime;
  }
  /** @inheritDoc */
  get appVersion(){
    return this.settings?.appVersion;
  }

  /** Конструктор */
  constructor(private httpBackend: HttpBackend) {
  }

  /** Инициализировать сервис из удаленных данных в формате json */
  public initFromRemote$(appConfigPath: string = AppConfigPathDefault, versionConfigPath: string = AppVersionPathDefault): Observable<AppSettingsService> {
    return this.appConfigFromRemote$(appConfigPath)
      .pipe(
        combineLatestWith(this.appVersionFromRemote$(versionConfigPath)),
        tap(value => {
          this.settings = {
            program: value[0].program,
            webApiBaseUrl: value[0].webApiBaseUrl,
            webApiAuthBaseUrl: value[0].webApiAuthBaseUrl,
            webApiLoggingPath: AppSettings_WebApiLoggingPath.Copy(value[0].webApiLoggingPath),
            webApiFormatSettings: AppSettings_WebApiFormatSettings.Copy(value[0].webApiFormatSettings),
            workspaceRoute: value[0].workspaceRoute,
            signalRPath: value[0].signalRPath,
            storageVersion: value[0].storageVersion,
            disconnectedSignalRMaxTime: value[0].disconnectedSignalRMaxTime,
            appVersion: value[1],
          }
        }),
        map(() => this)
      )
  }

  /** Получить {@link IAppSettings} с сервера */
  public appConfigFromRemote$(appConfigPath: string = AppConfigPathDefault): Observable<IAppSettings>{
    return this.getJson$<IAppSettings>(appConfigPath);
  }

  /** Получить {@link IAppVersion} */
  public appVersionFromRemote$(appVersionPath: string = AppVersionPathDefault): Observable<string>{
    return this.getJson$<string>(appVersionPath)
  }

  /** Запрос к серверу */
  private getJson$<T>(jsonPath: string): Observable<T>{
    let headers = new HttpHeaders();
    headers = HttpHeadersHelper.skipToken(headers);
    headers = HttpHeadersHelper.noCache(headers);

    return new HttpClient(this.httpBackend)
      .get<T>(jsonPath, {headers: headers});
  }
}

/** Интерфейс настроек приложения */
interface IAppSettings{
  /** Программа */
  readonly program: string;
  /** Базовый url webApi */
  readonly webApiBaseUrl: string;
  /** Базовый url webApiAuth */
  readonly webApiAuthBaseUrl: string;
  /** Пути к логированию ошибок ui */
  readonly webApiLoggingPath: AppSettings_WebApiLoggingPath
  /**
   * Настройки серверного формата данных.
   * Необходим для серелизации/десерелизации даты для отправки/получения
   */
  readonly webApiFormatSettings: AppSettings_WebApiFormatSettings
  /**
   * Рабочая область.
   * Роут перехода после авторизации
   */
  readonly workspaceRoute: string;
  /** Путь к подключению signalR */
  readonly signalRPath: string;
  /**
   * Текущая версия хранилища(localStorage/sessionStorage).
   * Необходимо для очистки хранилища при изменении структуры данных
   */
  readonly storageVersion: number;
  /** Максимальное время в секундах, отсутствия подключения singalR */
  readonly disconnectedSignalRMaxTime: number;
}

/** Интерфейс версии приложения */
interface IAppVersion{
  /** Версия приложения */
  readonly appVersion: string;
}

/** Класс хранения путей сервера к логированию ошибок ui */
class AppSettings_WebApiLoggingPath {
  /**
   * Конструктор
   * @param error Путь логирования ошибки если пользователь НЕ авторизован
   * @param errorAuth Путь логирования ошибки если пользователь авторизован
   * @param warn Путь логирования предупреждения если пользователь НЕ авторизован
   * @param warnAuth Путь логирования предупреждения если пользователь авторизован
   */
  constructor(public readonly error: string,
              public readonly errorAuth: string,
              public readonly warn: string,
              public readonly warnAuth: string) {
  }

  /** Копирование объекта */
  public static Copy(source: AppSettings_WebApiLoggingPath){
    return new AppSettings_WebApiLoggingPath(source.error, source.errorAuth, source.warn, source.warnAuth)
  }
}

/**
 * Класс настройки серверного формата даты.
 * Необходим для серелизации/десерелизации даты для отправки/получения на сервер
 */
class AppSettings_WebApiFormatSettings {
  /**
   * Конструктор
   * @param date Формат даты сервера
   * @param dateOnly Формат только даты
   * @param timeOnly Формат только времени
   */
  constructor(public readonly date: string,
              public readonly dateOnly: string,
              public readonly timeOnly: string) {
  }

  /** Метод копирует объект с преобразованием string в Regex */
  public static Copy(source: AppSettings_WebApiFormatSettings): AppSettings_WebApiFormatSettings{
    return new AppSettings_WebApiFormatSettings(source.date, source.dateOnly, source.timeOnly);
  }
}
