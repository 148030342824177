import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';


export interface IReportPeriod extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag {
  /** Наименование режима контроля */
  name: string;
  /** Строковый идентификатор режима контроля */
  innerKey: string;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IReportPeriodOptional = Partial<IReportPeriod>;

@classBackend('ReportPeriod', 'stafflist')
@className('ReportPeriod')
/** Периоды контроля (применяется для контроля нормы рабочего времени в зависимости от режима работы) */
export class ReportPeriod implements IReportPeriod {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public name: string,
              public innerKey: string,
  ) {
  }
}

/** Перечисление ключей innerKey */
export type ReportPeriodInnerKey = '' | ''
