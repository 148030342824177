<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <app-codes-grid formControlName="codesIdsArray" [data]="codes$ | async" [height]="500"></app-codes-grid>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

