import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { PassportData, PassportType } from "src/app/classes/extendedDatas/employeeExtended/passport-data";

@Injectable()
/** Сервис работы с формой */
export class EditPassportDataComponentFormService  {

  constructor() {
  }

  initForm( value: PassportData) : EditPassportDataComponentForm {
    const controls = new EditPassportDataComponentFormControls(
      new UntypedFormControl(value?.passportType ?? PassportType.RussianFederation, [Validators.required]),
      new UntypedFormControl(value?.seria, [Validators.required, Validators.maxLength(15), Validators.minLength(1)]),
      new UntypedFormControl(value?.number, [Validators.required, Validators.maxLength(15), Validators.minLength(1)] ),
      new UntypedFormControl(value?.issueDate, [Validators.required]),
      new UntypedFormControl(value?.whom, [Validators.required]),
      new UntypedFormControl(value?.divisionCode, [Validators.required, Validators.maxLength(6), Validators.minLength(6)])
    );

    const form = new UntypedFormGroup({
      passportType: controls.passportTypeControl,
      seria: controls.seriaControl,
      number: controls.numberControl,
      issueDate: controls.issueDateControl,
      whom: controls.whomControl,
      divisionCode: controls.divisionCodeControl
    });

    const retSubject$ = new Subject<IValueResult>();

    form.valueChanges.subscribe(v => {
      const data = new PassportData(
        controls.passportTypeControl.value,
        controls.seriaControl.value,
        controls.numberControl.value,
        controls.issueDateControl.value,
        controls.whomControl.value,
        controls.divisionCodeControl.value,
      );
      retSubject$.next({isValid: form.valid, data: data});
    });

    return new EditPassportDataComponentForm(form, controls, retSubject$);
  }
}

export class EditPassportDataComponentForm{
  constructor(
   public form: UntypedFormGroup,
   public controls: EditPassportDataComponentFormControls,
   public valueChange$: Subject<IValueResult>
  ){}
}

/** Данные результата */
export interface IValueResult {
  /** Валидны ли данные */
  isValid: boolean;
  /** Паспортные данные */
  data: PassportData
}

/** Класс со всеми контролами формы паспортных данных*/
export class EditPassportDataComponentFormControls{
  constructor(
    public passportTypeControl: UntypedFormControl,
    public seriaControl: UntypedFormControl,
    public numberControl: UntypedFormControl,
    public issueDateControl: UntypedFormControl,
    public whomControl: UntypedFormControl,
    public divisionCodeControl: UntypedFormControl,
  ){}

  private get _allControls():UntypedFormControl[]  {
    return [
      this.passportTypeControl,
      this.seriaControl,
      this.numberControl,
      this.issueDateControl,
      this.whomControl,
      this.divisionCodeControl
    ]
  }
  /** Установить всем контролам состояние неактивно/активно */
  public setDisabled(disabled: boolean) {
    if(disabled === true) {
     this._allControls.forEach(control => control.disable());
    } else {
      this._allControls.forEach(control => control.enable());
    }
  }

  public markAsTouched() {
    this._allControls.forEach(control => control.markAsTouched());
  }
}
