import "reflect-metadata";
import {ITraceParamSettings} from "./classes/traceSetting.interface";
import {TraceParamMetadata} from "./classes/metadatas";

/** Ключ хранения метаданных */
export const traceParamMetadataKey = '_traceParam_'

/** Псевдоним для конструктора. Будет названием метода в выводе */
export const constructorAlias = 'constructor';

/**
 * Декоратор трассировки параметров методов
 * @param settings настройки декоратора
 */
export function traceParam(settings?: ITraceParamSettings): ParameterDecorator{
  return (target: Object, propertyKey: string | symbol, parameterIndex: number) => {
    let array: Array<TraceParamMetadata> = Reflect.getMetadata(traceParamMetadataKey, target);
    if(!array){
      array = [];
      Reflect.defineMetadata(traceParamMetadataKey, array, target);
    }

    array.push(new TraceParamMetadata(propertyKey?.toString() ?? constructorAlias, parameterIndex, settings));
  }
}

/** Получить метаданные всех декораторов параметров(traceParam) зарегистрированных на переданном объекте */
export function getAllTraceParamMetadata<T>(object: T): Array<TraceParamMetadata>{
  return Reflect.getMetadata(traceParamMetadataKey, object) ?? [];
}

/** Получить метаданные параметров метода (объект у которого нужно получить, название метода) */
export function getTraceParamMetadata<T, K extends keyof T = keyof T>(object: T, method: T[K] extends Function ? K : never): Array<TraceParamMetadata>{
  return _getTraceParamMetadata(object, method.toString())
}

/**
 * Получить метаданные параметров метода по строке названия методаж
 * НЕ ИСПОЛЬЗОВАТЬ ПО КОДУ. НЕОБХОДИМО ДЛЯ ВНУТРЕННЕЙ РАБОТЫ
 */
export function _getTraceParamMetadata<T>(object: T, method: string): Array<TraceParamMetadata>{
  return getAllTraceParamMetadata(object).filter(x => x.propertyKey == method);
}
