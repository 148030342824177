import { map, Observable, switchMap } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { IOccupation, Occupation } from "src/app/classes/domain/POCOs/stafflist/Occupation";
import { IForDate } from "src/app/classes/for-date";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";

export class OccupationDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<OccupationDataSourceService_Params, IOccupation>{

  readonly paramsDataSource = new DataSource<OccupationDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<IOccupation>();

  constructor(private readonly parentDataSourceService: IOccupationDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Occupation)
      .pipe(
        map(value => value.data),
        map(value => new DataHasOwnerStateBuilder(value, this.dataSource.data2, x => x.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.ownerId)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: OccupationDataSourceService_Params): Observable<IOccupation[]> {
    return this._reloadFromRemoteByIds$(params, params.occupationIds);
  }

  protected _reloadFromRemoteByIds$(params: OccupationDataSourceService_Params, targets: IOccupation["id"][]): Observable<IOccupation[]> {
    return this.parentDataSourceService.getOccupationsForDate$(params, targets);
  }
}

export interface OccupationDataSourceService_Params extends IForDate{
  occupationIds: number[];
}

export interface IOccupationDataSourceService_ParentDataSourceService {
  getOccupationsForDate$(forDate: IForDate, occupationIds: number[]): Observable<IOccupation[]>;
}
