import {Injectable} from "@angular/core";
import {AES, enc} from "crypto-ts";

/** Сервис шифрования данных */
@Injectable({
  providedIn: "root"
})
export class CryptService{
  public static readonly defaultKey = 'Qw78';

  /** Зашифровать строку */
  public encrypt(str: string, key: string = CryptService.defaultKey): string{
    return AES.encrypt(str, key).toString()
  }

  /** Расшифровать строку */
  public decrypt(encryptStr: string, key: string = CryptService.defaultKey): string{
    return AES.decrypt(encryptStr, key).toString(enc.Utf8)
  }

  /** Зашифровать объект */
  public encryptObj<T>(obj: T, key: string = CryptService.defaultKey): string{
    return this.encrypt(JSON.stringify(obj), key);
  }

  /** Расшифровать строку и получить объект */
  public decryptObj<T>(encryptStr: string, key: string = CryptService.defaultKey): T{
    return JSON.parse(this.decrypt(encryptStr, key));
  }
}
