import { TimeOnly } from '../classes/Dates/time-onlys/time-only';
import {DateOnly} from "../classes/Dates/date-onlys/date-only";

/**
 * Помощник работы с объектом
 */
export class ObjectHelper {
  /**
   * Объект равен null или undefined
   * @param obj
   */
  public static isNullOrUndefined(obj: any): boolean{
    return obj == null || obj == undefined;
  }

  /**
   * Полная копия объекта или массива.
   * Рекурсивно проходит по полям объекта/массива, и копирует вложенные объекты/элементы.
   * Происходит пересоздание объектов типа: {@link Date}, {@link TimeOnly}, {@link Map}, {@link Set}.
   *
   * @param target объект для копирования.
   * @throws {Error} если объект имеет итератор, и при этом нет поддержки его копирования.
   */
  public static deepCopy<T>(target: T): T {
    if(typeof target !== 'object' || target === null){ //Если тип не объект, или null
      return target;
    } else if(Array.isArray(target)) { //Если массив
      const copyArr = new Array<T>(target.length);
      for (let i = 0; i < target.length; i++) {
        copyArr[i] = ObjectHelper.deepCopy(target[i])
      }
      return copyArr as any;
    } else if(typeof target[Symbol.iterator] === 'function'){ //Если имеет итератор
      // копируем известные типы
      if(target instanceof Map){
        return new Map(target) as any;
      }
      if(target instanceof Set){
        return new Set(target) as any;
      }
      if(target instanceof FormData){ //Возможно нужно что-то делать с формой
        return target;
      }
      //

      throw new Error('Объект имеет итератор, но тип не поддерживается для копирования');
    } else {
      // копируем известные типы
      if (target instanceof Date) {
        return new Date(target.getTime()) as any;
      }
      if (TimeOnly.isTimeOnly(target)) {
        return target.copy() as any;
      }
      if (DateOnly.isDateOnly(target)){
        return target.copy() as any;
      }
      //

      const copyObj = {} as any;

      for (let key of Object.keys(target)) {
        copyObj[key] = ObjectHelper.deepCopy(target[key]);
      }

      return copyObj;
    }
  }

  /**
   * Глубокая замена значений
   * @param target объект который/в котором необходимо заменить
   * @param handlers обработчики
   */
  public static deepReplaceValue(target: any, ...handlers: TryConvertFnType[]): any{
    if(handlers.length === 0){
      return target;
    }

    const hierarchyHandler = ObjectHelper.toHierarchyHandler(handlers);
    return this.deepReplaceValueInternal(target, typeof target, hierarchyHandler, hierarchyHandler);
  }

  /** Внутренний метод {@link deepReplaceValue} */
  private static deepReplaceValueInternal(target: any, type: TypeOfReturnType, rootHandler: HierarchyTryConvertFnType, currentHandler: HierarchyTryConvertFnType): any{
    const tryConvertResult = currentHandler.handler(target, type);

    if(tryConvertResult !== undefined){ //Если подлежит конвертации
      return tryConvertResult;
    }

    if(currentHandler.child){ //Если еще есть обработчики
      return ObjectHelper.deepReplaceValueInternal(target, type, rootHandler, currentHandler.child);
    }

    if(typeof target !== 'object' || target === null){ //Если тип не объект, или null
      return target;
    } else if(Array.isArray(target)) { //Если массив
      for (let i = 0; i < target.length; i++) {
        target[i] = ObjectHelper.deepReplaceValueInternal(target[i], typeof target[i], rootHandler, rootHandler)
      }
    } else if(typeof target[Symbol.iterator] === 'function'){ //Если имеет итератор
      if(target instanceof FormData){ //Возможно нужно что-то делать с формой
        return target
      }

      throw new Error('Объект имеет итератор, но тип не поддерживается для замены значения');
    } else {
      for (let key of Object.keys(target)) {
        target[key] = ObjectHelper.deepReplaceValueInternal(target[key], typeof target[key], rootHandler, rootHandler);
      }
    }

    return target;
  }

  /** Преобразования плоского массива обработчиков в иерархическую структуру. Первый элемент в массиве будет рутом */
  private static toHierarchyHandler(handlers: TryConvertFnType[]): HierarchyTryConvertFnType{
    let result : HierarchyTryConvertFnType = {
      handler: handlers[handlers.length - 1],
      child: undefined
    }

    for (let i = handlers.length - 2; i >= 0; i--){
      result = {
        handler: handlers[i],
        child: result
      }
    }

    return result;
  }
}

/** Типы возвращаемые typeOf */
type TypeOfReturnType = 'number' | 'bigint' | 'string' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function';

/** Тип функции выполняющую конвертацию. Функция должна возвращать {@link undefined} если конвертация завершилась неудачей */
type TryConvertFnType = (value: any, type: TypeOfReturnType) => any | undefined;

/** Иерархический тип типа {@link TryConvertFnType} */
type HierarchyTryConvertFnType = {
  readonly handler: TryConvertFnType,
  readonly child: HierarchyTryConvertFnType
}
