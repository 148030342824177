import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IWorkMode extends IEntityVersioning {
  /** Наименование режима работы */
  name: string;
  /** Идентификатор типа режима рабочего времени. FK на таблицу WorkModeType */
  workModeTypeId: number | null;
  /** Начало периода совпадает с началом недели */
  arrangedForWeekFlag: boolean | null;
  /** Количество рабочих дней в периоде */
  workingDays: number | null;
  /** Продолжительность рабочего дня в часах */
  workdayHourDuration: number | null;
  /** Продолжительность предпраздничного дня в часах, если он выпал на рабочее время */
  preHolidayHourDuration: number | null;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IWorkModeOptional = Partial<IWorkMode>;

@classBackend('WorkMode', 'stafflist')
@className('WorkMode')
/** Режим работы */
export class WorkMode implements IWorkMode {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public name: string,
              public workModeTypeId: number | null,
              public arrangedForWeekFlag: boolean | null,
              public workingDays: number | null,
              public workdayHourDuration: number | null,
              public preHolidayHourDuration: number | null,
              public timestamp: [],
  ) {
  }

  private static _fullComparer: ObjComparer<Omit<IWorkMode, 'timestamp'>>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<Omit<IWorkMode, 'timestamp'>>({
        id: true,
        startDate: ObjComparer.dateComparer,
        endDate: ObjComparer.dateComparer,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        comment: true,
        deletedFlag: true,
        ownerId: true,
        actionId: true,
        orderId: true,
        name: true,
        workModeTypeId: true,
        arrangedForWeekFlag: true,
        workingDays: true,
        workdayHourDuration: true,
        preHolidayHourDuration: true,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IWorkMode, 'timestamp' | 'modifiedUserId' | 'modifiedDate'>>
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}
