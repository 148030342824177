import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { TracerServiceBase } from "../../trace/tracers2/trace-services/tracer-base.service";
import { catchError, tap } from "rxjs/operators";
import { trace } from "../../trace/operators/trace";
import { ServerLoggerService } from "../../../services/loggers/server-logger.service";
import { Guid } from "guid-typescript";

/** Интерцептор трассирует все http запросы */
@Injectable({ providedIn: 'root' })
export class TraceInterceptor implements HttpInterceptor {

  constructor(private readonly tracerService: TracerServiceBase,
    private readonly serverLoggerService: ServerLoggerService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.serverLoggerService.urlPatch.some(x => req.url.indexOf(x) > -1)) { //НЕ логгируем отправку ошибки на сервер. Иначе будет бесконечный цикл
      return next.handle(req.clone());
    }


    const requestGuid = Guid.create();

    this.tracerService.add2(`Запрос к серверу ${requestGuid}`, {
      obj: {
        method: req.method,
        url: req.url,
        urlWithParams: req.urlWithParams,
        body: req.body,
      },
      maxLength: 1000
    })

    return next.handle(req.clone()).pipe(
      tap(value => {
        if (value.type !== HttpEventType.Response) {
          return;
        }

        const httpResponse = value as HttpResponse<any>;

        this.tracerService.add2(`Ответ сервера ${requestGuid}`, {
          obj: httpResponse?.body,
          maxLength: 1000
        })
      }),
      catchError((err, caught) => {
        this.tracerService.add(`Запрос к северу завершился ошибкой ${requestGuid}`)

        this.serverLoggerService.send(err); //Отправляем ошибку на сервер

        return throwError(() => err);
      })
    );
  }
}
