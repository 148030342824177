import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[inputCapitalize]',
})
export class InputCapitalizeDirective {

  @Input('autoCapitalize') autoCapitalize: boolean = true;

  constructor(private readonly ngControl: NgControl) {
  }

  /** Событие происходит в момент, когда нужно обработать значение */
  @HostListener('blur')
  public listenBlur(): void {
    this.ngControl.control.setValue(this.processValue(this.ngControl.control.value));
  }

  /** Обработка значения */
  private processValue(value: string): string {
    if (this.autoCapitalize && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return value;
  }

}
