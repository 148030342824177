import { Injectable } from '@angular/core';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { ProductionCalendarDirectoryGridItem } from '../production-calendar-directory-grid.component';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { ArrayDataSourceIEntityId, DataSource } from '../../../../../../../../src/app/classes/array-data-sources/data-source';
import { map, Observable } from 'rxjs';
import { IEntityId } from '../../../../../../../../src/app/classes/domain/POCOs/interfaces/IEntityId';
import { LoadingIndicatorService } from '../../../../../../../../src/app/services/loading-indicator.service';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { Api1DateControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-date-controller.service';
import * as moment from 'moment';
import { exLoadingMessage } from '../../../../../../../../src/app/operators/ex-loading-message.operator';
import { DataStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-state-builder';
import { Day } from '../../../../../../../../src/app/classes/domain/POCOs/timesheet/Day';

/** Базовый класс сервиса источника данных */
@Injectable()
export abstract class ProductionCalendarDirectoryGridDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, ProductionCalendarDirectoryGridItem> {
}

@Injectable()
@traceClass('ProductionCalendarDirectoryGridDataSourceService')
export class ProductionCalendarDirectoryGridDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<number, ProductionCalendarDirectoryGridItem> implements ProductionCalendarDirectoryGridDataSourceServiceBase {

  public readonly dataSource = new ArrayDataSourceIEntityId<ProductionCalendarDirectoryGridItem>();
  public readonly paramsDataSource = new DataSource<number>();

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly api1DateControllerService: Api1DateControllerService,
              private readonly traceService: TracerServiceBase) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Day)
      .pipe(
        map(value => value.data),
        map(value => new DataStateBuilder(value, this.dataSource.data2, (s, i) => s.id === i.id).build_()),
        map(value => value.source.map(x => x.dataItem ? x.dataItem.id : x.signalR.currentOrOrigin.id)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: number): Observable<ProductionCalendarDirectoryGridItem[]> {
    return this.getCalendarDaysForYear$(params).pipe(
      exLoadingMessage(this.loadingIndicatorService, 'Загруска дней'),
    );
  }

  protected _reloadFromRemoteByIds$(params: number, targets: IEntityId['id'][]): Observable<ProductionCalendarDirectoryGridItem[]> {
    return this.getCalendarDaysForYear$(params, targets);
  }

  private getCalendarDaysForYear$(year: number, ids: number[] = null): Observable<ProductionCalendarDirectoryGridItem[]> {
    return this.api1DateControllerService.getCalendarDaysForYear$(year, ids)
      .pipe(
        map(v => v.map(x => new ProductionCalendarDirectoryGridItem(x.id, moment(x.date), x.timestamp, x.dayTypeItem))),
      );
  }
}
