export class AddressData {
  constructor(
    public address: string
  ) {}

  static getAsString(data: AddressData): string {
    return data?.address;
  }
}

