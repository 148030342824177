import { TarifEmployee_SeniorityDTO } from "src/app/services/webApi/webApi3/controllers/api3-tarifEmployee-controller.service";
import { IEntityVersioning } from "../interfaces/IEntityVersioning";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITarifEmployee extends IEntityVersioning {
  /** FK на Сотружники */
  employeeId: number
  /** Выплаты за непрерывный стаж работы: дата начала */
  paymentsExpStartDate: Date
  /** Выплаты за квалификационную категорию: FK на Квалификационные категории */
  paymentsQualificationCategoryId: number
  /** Стимулирующие выплаты молодым специалистам */
  paymentsYoung: number
  /** Выплата за почетное звание */
    paymentsForRankId: number,
  /** Выплаты за ученую степень */
  paymentsForDegreeId: number,
  /** Выплаты по грантам Правительства Москвы */
  paymentsMoskowGovGrants: number
}

@classBackend('TarifEmployee', 'tarification_directories')
@className('TarifEmployee')
/** Модель БД таблицы tarification_directories.TarifEmployees (Сотрудники) */
export class TarifEmployee implements ITarifEmployee {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public timestamp: [],

              public employeeId: number,
              public paymentsExpStartDate: Date,
              public paymentsQualificationCategoryId: number,
              public paymentsYoung: number,
              public paymentsForRankId: number,
              public paymentsForDegreeId: number,
              public paymentsMoskowGovGrants: number
              ) { }
}

export class TarifEmployeeCalculatedFields {
  constructor(public seniorityValue: string,
              public seniorityPercent: number,) { }

  public static get(seniorities: TarifEmployee_SeniorityDTO[], paymentsExpStartDate: Date, employeeEndDate: Date) {
    if (!paymentsExpStartDate) {
      return;
    }

    if (!employeeEndDate) {
      employeeEndDate = new Date();
    }

    /** Вычисляем Лет, Месяцев, Дней */
    let dy = employeeEndDate.getFullYear() - paymentsExpStartDate.getFullYear();
    let dm = employeeEndDate.getMonth() - paymentsExpStartDate.getMonth();
    let dd = employeeEndDate.getDate() - paymentsExpStartDate.getDate();

    if (dd < 0) { dm -= 1; dd += 30; }
    if (dm < 0) { dy -= 1; dm += 12; }

    /** Находим Стаж, который попадает в диапозон */
    const seniority = seniorities.find(s => dy >= s.minYears && ((s.maxYears && dy < s.maxYears) || (!s.maxYears && dy < 255)));

    return new TarifEmployeeCalculatedFields(`${dy ? `${dy}г` : ""} ${dm || dy ? `${dm}м` : ""} ${dd}дн`, seniority?.percent * 100);
  }
}
