import {ITraceClassSettings, TraceParamEnum} from "./classes/traceSetting.interface";
import "reflect-metadata";
import {TraceClassMetadata} from "./classes/metadatas";
import {convertToStr, getAllTraceFuncMetadata} from "./func.decorator";
import {_getTraceParamMetadata, constructorAlias} from "./param.decorator";
import {TracerServiceBase} from "../tracers2/trace-services/tracer-base.service";
import {TracerFakeService} from "../tracers2/trace-services/tracer-fake.service";
import {getTraceServiceIndex} from "./classes/functions";

/** Ключ хранения метаданных */
export const traceClassMetadataKey = '_traceClass_'

/**
 * Декоратор трассировки класса.
 * Конструктор декорированного класса должен принимать один параметр типа TracerServiceBase или его наследников
 *
 * Принцип работы: Подменяет переданный параметр в конструктор на копию TraceServiceBase.
 * Трассирует конструктор + переданные параметры
 *
 * @param name Название класса.(Необходимо поддерживать в актуальном состоянии)
 * @param settings Настройки трассировкию. По умолчанию не трассирует параметры конструктора
 */
export function traceClass(name: string, settings?: ITraceClassSettings){
  if(!name){
    throw new Error('Необходимо передавать название класса')
  }

  return function <T extends { new(...constructorArgs: any[]): {} }>(ctor: T) {
    const ctorTraceParamMetadata = _getTraceParamMetadata(ctor, constructorAlias);
    const metadata = new TraceClassMetadata(name, ctor.name, settings, ctorTraceParamMetadata);
    objForRegisterAllMetadata.register(metadata);
    Reflect.defineMetadata(traceClassMetadataKey, metadata, ctor.prototype);

    getAllTraceFuncMetadata(ctor.prototype).forEach(x => x.traceClassMetadata = metadata); //Заполняем метаданные класса у метаданных функций

    return ctor;
  }
}

/** Сохраняю старый метод. В нем имеется логика обвертки конструктора */

/*export function traceClass(name: string, settings?: ITraceClassSettings){
  if(!name){
    throw new Error('Необходимо передавать название класса')
  }

  return function <T extends { new(...constructorArgs: any[]): {} }>(ctor: T) {
    const ctorTraceParamMetadata = _getTraceParamMetadata(ctor, constructorAlias);
    const metadata = new TraceClassMetadata(name, ctor.name, settings, ctorTraceParamMetadata);
    objForRegisterAllMetadata.register(metadata);
    Reflect.defineMetadata(traceClassMetadataKey, metadata, ctor.prototype);

    getAllTraceFuncMetadata(ctor.prototype).forEach(x => x.traceClassMetadata = metadata); //Заполняем метаданные класса у метаданных функций

    let newConstructorFunction: any = function (...args) {

      const traceServiceArgsIndex = getTraceServiceIndex(args, metadata.name);

      const tracerService = metadata.isDisabled ?
        new TracerFakeService() :
        (args[traceServiceArgsIndex] as TracerServiceBase).copy();

      args[traceServiceArgsIndex] = tracerService;

      tracerService.add(
        convertToStr(
          metadata.name, constructorAlias,
          settings?.description,
          args,
          metadata.notDisabledTraceParamMetadata,
          settings?.traceParamType ?? TraceParamEnum.notTrace,
          tracerService.defaultParamTraceMaxLength)
      );

      tracerService.tracerLevelManager.add();

      try {
        return new ctor(...args);
      }catch (e) {
        tracerService.tracerLevelManager.add();
        tracerService.add(e?.toString() ?? 'Объект ошибки == null')
        tracerService.tracerLevelManager.remove();
        throw e;
      } finally {
        tracerService.tracerLevelManager.remove();
      }
    }

    newConstructorFunction.prototype = ctor;
    Object.keys(ctor).forEach((name: string) => { newConstructorFunction[name] = (<any>ctor)[name]; }); //Копируме поля(иначе падает ангулар)
    return newConstructorFunction;
  }
}*/



/** Получить метаданные класса установленные декоратором traceClass */
export function getTraceClassMetadata<T>(object: T): TraceClassMetadata{
  return Reflect.getMetadata(traceClassMetadataKey, object);
}

/** Класс глобальной регистрации методанных декоратора traceClass */
class GlobalTraceClassObj{
  private _metadata: Array<TraceClassMetadata> = [];
  /** Получить все методанные зарегитрировавшиеся классов */
  get getAll(): Array<TraceClassMetadata>{
    return this._metadata;
  }

  /** Зарегистрировать метаданные класса */
  register(metadata: TraceClassMetadata){
    this._metadata.push(metadata);
  }
}

/** Объект для регистрации всех метаданных. Необходимо для тестирования для валидации переданных name */
export const objForRegisterAllMetadata = new GlobalTraceClassObj();
