import { IEntityComment } from '../../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../../interfaces/IEntityId';
import { IEntityModify } from '../../interfaces/IEntityModify';
import { IEntityRowVersion } from '../../interfaces/IEntityRowVersion';
import { IEntityStartEndDate } from '../../interfaces/IEntityStartEndDate';
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface IRequest extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityRowVersion, IEntityStartEndDate {
  typeId: number;
  subdivisionId: number;
  isSentToCfo: boolean;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IRequestOptional = Partial<IRequest>;

@classBackend('Request', 'pfhd_request')
@className('Request')
/** Модель БД таблицы pfhd_request.Request (Заявки) */
export class Request implements IRequest {
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public comment: string,
              public typeId: number,
              public subdivisionId: number,
              public startDate: Date,
              public endDate: Date,
              public isSentToCfo: boolean,
              public timestamp: [],
  ) {
  }
}
