import {MonitoringBase} from "./monitoringBase";
import { HttpClient } from "@angular/common/http";
import {WebApi1Service} from "../../web-api1.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class Api1MonitoringTableService extends MonitoringBase{
  constructor(httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {
    super(httpClient, {
      get: webApi1Service.controllers.monitoringTable.actions.get.toString(),
      getFor: webApi1Service.controllers.monitoringTable.actions.getFor.toString(),
      getDetail: webApi1Service.controllers.monitoringTable.actions.detailGet.toString(),
      approving: webApi1Service.controllers.monitoringTable.actions.approving.toString(),
      cancelApproving: webApi1Service.controllers.monitoringTable.actions.cancelApproving.toString(),
      decline: webApi1Service.controllers.monitoringTable.actions.decline.toString(),
      approved: webApi1Service.controllers.monitoringTable.actions.approved.toString(),
      toUnderApproving: webApi1Service.controllers.monitoringTable.actions.toUnderApproving.toString(),
      toUnderRevision: webApi1Service.controllers.monitoringTable.actions.toUnderRevision.toString(),
      tryCancelRouting: webApi1Service.controllers.monitoringTable.actions.tryCancelRouting.toString(),
    });
  }
}
