import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { ReportDateValues } from 'src/app/components/report-dates/classes/report-date-values';
import { ReportDatesComponentSharedService } from 'src/app/components/report-dates/services/report-dates-component-shared.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { OccupationsDirectoryGridComponentDataSourceService } from '../../occupations/directory-grid/services/occupations-directory-grid-component-data-source.service';
import { map } from 'rxjs';
import { trace } from '../../../../../../../src/app/modules/trace/operators/trace';
import { exErrorHandler } from '../../../../../../../src/app/operators/ex-error-handler';

@Component({
  selector: 'app-directories-occupation-types',
  templateUrl: './occupations-directory.component.html',
  styleUrls: ['./occupations-directory.component.css'],
  providers: [OccupationsDirectoryGridComponentDataSourceService],
})
export class OccupationsDirectoryComponent implements OnInit, OnDestroy {

  private readonly streams$ = { unsubscribe: new ReplaySubject<any>(1) };

  constructor(private readonly reportDatesComponentSharedService: ReportDatesComponentSharedService,
              public readonly dataSourceService: OccupationsDirectoryGridComponentDataSourceService,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase) {
  }

  ngOnInit() {
    // Подписка на обновление значений
    this.dataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          map(value => ReportDateValues.toForDate(value)),
          trace(this.tracerService),
          exErrorHandler(this.displayErrorService),
        ),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
