<kendo-treelist style="max-height: 100%; max-width: 100%; cursor: default;"
                kendoTreeListExpandable
                [kendoTreeListFlatBinding]="dataSource$ | async"
                [filterable]="true"
                [initiallyExpanded]="true"
                [idField]="'subdivision.id'"
                [parentIdField]="'subdivision.parentId'"
                kendoTreeListSelectable
                [kendoTreelistDataSourceSelection]="selectionService.tempSelection.data$ | async"
                [autoSize]="true"
                [selectable]="{mode: 'row', multiple: multipleSelected}"
                KendoTreeListExpanded
                [updateScrollIntoSelect]="scrollToRow$ | async"
                (dbCellClick)="doubleClick.emit($event.originalEvent.dataItem)"
                [sort]="sort"
>
  <kendo-treelist-column [expandable]="true" field="displayValue" title="" [autoSize]="true" [width]="200" [style]="cellStyle" [sortable]="false">
    <ng-template kendoTreeListFilterCellTemplate let-filter let-column="column">
      <kendo-treelist-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter"
                                          class="kendo-treelist-string-filter"
                                          placeholder="Поиск"
      >
      </kendo-treelist-string-filter-cell>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>
