import {Injectable} from "@angular/core";
import {EnvironmentService} from "../../../../../../src/app/services/environment.service";
import {Router} from "@angular/router";
import {BuilderRoutingService} from "../builder-routing.service";
import {ExtensionObj} from "../../../../../../src/app/helpers/extensionObj";

@Injectable({
  providedIn: "root"
})
export class WorkSpaceErrorComponentService {
  public settings: ErrorPageSettings = new ErrorPageSettings();

  /**
   * Флаг Находимся в режиме отладки
   */
  public isDebug: boolean;

  /**
   * Конструктор
   */
  constructor(environmentService: EnvironmentService,
              private router: Router,
              private builderRoutingService: BuilderRoutingService) {
    this.isDebug = !environmentService.production;
  }

  /**
   * Перенаправить на страницу ошибки
   * @param settings Настройки. Если ничего не передано будут настройки по умолчанию
   */
  public redirect(settings?: ErrorPageSettings): void{
    if(!settings){
      settings = new ErrorPageSettings();
    }
    this.settings = settings;
    this.router.navigate([this.builderRoutingService.workspace.child.error.toString()]).then(x => {
      if(this.settings.callback){
        this.settings.callback();
      }
    });
  }
}

/**
 * Настройки для страницы ошибки
 */
export class ErrorPageSettings {
  /**
   * Заголовок
   * По умолчанию - Произошла ошибка
   */
  title: string = 'Произошла ошибка';
  /**
   * Сообщение
   * По умолчанию - Что то пошло не так!!!
   */
  message: string = 'Что то пошло не так!!!';
  /**
   * Нижняя информация
   * По умолчанию - 'Информация собрана и передана в службу поддержки'
   */
  footer: string = 'Информация собрана и передана в службу поддержки';
  /**
   * StackTrace. Будет выводится только в режиме Debug
   */
  stackTrace: any = null;
  /**
   * Обратный вызов
   * Вызывается после удачного редиректа
   */
  callback: () => {} = null;

  /**
   * Получить экземпляр обвернутый в ExtensionObj
   * @constructor
   */
  public static AsExtensionObj(): ExtensionObj<ErrorPageSettings>{
    return new ExtensionObj<ErrorPageSettings>(new ErrorPageSettings())
  }
}
