import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { EmployeeDeclensionDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-names-declension-controller.service';

export type EmployeeNamesDeclensionEditForm = PropertyWrapFormControlType<EmployeeDeclensionDTO>;

@Component({
  selector: 'app-employee-names-declensions-control',
  templateUrl: './employee-names-declensions-control.component.html',
  styleUrls: ['./employee-names-declensions-control.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmployeeNamesDeclensionsControlComponent),
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: EmployeeNamesDeclensionsControlComponent,
    multi: true,
  }],
})
export class EmployeeNamesDeclensionsControlComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  public form: FormGroup<EmployeeNamesDeclensionEditForm>;

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };
  private onTouched = () => {
  };
  private onChange: (value: EmployeeDeclensionDTO) => void = () => {
  };

  private _value: EmployeeDeclensionDTO;

  get value(): EmployeeDeclensionDTO {
    return this._value;
  }

  set value(value: EmployeeDeclensionDTO) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
  }

  private _disabled: boolean;

  public get disabled(): boolean {
    return this._disabled;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.form = this.createForm();


    this.form.valueChanges.subscribe((v: EmployeeDeclensionDTO) => this.value = v);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { namesDeclensions: true };
  }

  writeValue(value: EmployeeDeclensionDTO): void {
    this.value = value ?? null;
    if (this.value) {
      this.form.setValue(this.value);
    }
    this.onChange(this.value);
  }

  registerOnChange(onChange: (value: EmployeeDeclensionDTO) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this._disabled = disabled;
  }

  private createForm() {
    return new FormGroup({
      nominative: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      accusative: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      dative: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      genitive: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      instrumental: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      prepositional: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
    });
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
