import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WebApi1Service } from '../../web-api1.service';
import { ILimitStaffUnit } from 'src/app/classes/domain/POCOs/stafflist/LimitStaffUnit';
import {EditEndDateDto, IdAndRowVersionType} from "../shared-DTOs/edit-end-date-dto";

/** Сервис работы с контроллером LimitStaffUnit */
@Injectable({
  providedIn: 'root'
})
export class Api1LimitStaffUnitControllerService {

  constructor(private webApi1Service: WebApi1Service, private httpClient: HttpClient) {
  }

  /** Сохранить */
  public save$(entity: SaveLimitStaffUnitDTO): Observable<[]> {
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.limitStaffUnit.actions.save.toString(), entity);
  }

  /** Редактировать окончание срока действия */
  public editEndDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.limitStaffUnit.actions.editEndDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }
}

export type SaveLimitStaffUnitDTO = Pick<ILimitStaffUnit, 'id' | 'timestamp' | 'startDate' | 'endDate' | 'comment'>;
