<div class="wrap"
     [formGroup]="form">
  <label class="k-checkbox-label" [for]='dateFrom'>Дата: </label>
  <kendo-datepicker
    #dateFrom
    style="width: 100%"
    formControlName="date"
    [format]="'dd.MM.yyyy'"
  >
    <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
  </kendo-datepicker>

  <kendo-label
    class="k-checkbox-label"
    [for]="financingSource"
    text="Источник финансирования:"
  ></kendo-label>
  <kendo-combobox
    #financingSource
    formControlName="financingSource"
    [data]="financingSourceList"
    textField="text"
    valueField="id"
    style="width: 100%"
  ></kendo-combobox>

</div>

<app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                         [selection]="selection"
                                         [expandAll]="expandAllSubdivisions"
                                         [checkableSettings]="checkableSettings"
                                         [displayTextFn]="displayTextFn"
                                         (checkedSubdivisions$)="setCheckedSubdivisionsValue($event)"
                                         (allSubdivisions$)="setAllSubdivisionsFlagValue($event)">
</app-subdivision-treeview-with-checkbox2>

<div class="k-form-buttons" style="justify-content: flex-end;">

  <button kendoButton
          type="button"
          primary="true"
          [disabled] = "form.invalid"
          (click)="onClickOk()"
  >
    Сформировать
  </button>

  <button kendoButton
          type="button"
          (click)="onClickCancel()"
  >
    Отменить
  </button>

</div>
