<form class="k-form" [formGroup]="form">

  <kendo-formfield>
    <kendo-label [for]="code" text="Код"></kendo-label>
    <input kendoTextBox inputTrim #code [formControlName]="'code'" />
    <kendo-formerror *ngIf="form.controls.code.errors?.minlength">Длина значения должна быть больше {{form.controls.code.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.code.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.code.errors?.maxlength">Длина значения должна быть меньше {{form.controls.code.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.code.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="name" text="Наименование категории должностей"></kendo-label>
    <input kendoTextBox inputTrim #name [formControlName]="'name'" />
    <kendo-formerror *ngIf="form.controls.name.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.minlength">Длина значения должна быть больше {{form.controls.name.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.maxlength">Длина значения должна быть меньше {{form.controls.name.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="parentId" text="Родительская категория"></kendo-label>
    <kendo-dropdowntree #parentId
                        kendoDropDownTreeExpandable
                        [kendoDropDownTreeFlatBinding]="parents"
                        parentIdField="parentId"
                        [valueField]="'id'"
                        [textField]="'name'"
                        [formControlName]="'parentId'"
                        [valuePrimitive]="true"
                        [popupSettings]="{width:'auto'}"
                        [filterable]="true"
    >
    </kendo-dropdowntree>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment [formControlName]="'comment'" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

