<kendo-combobox [data]="data$ | async"
                class="popup-edit-control"
                [kendoDropDownFilter]="settings?.filterSettings"
                [textField]="settings?.textField ?? 'text'"
                [valueField]="valueField"
                [valuePrimitive]="true"
                [formControl]="control"
                [placeholder]="settings?.placeholder"
                [popupSettings]="settings?.popupSettings"
>
</kendo-combobox>
