import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../interfaces/IEntityId';
import { IEntityComment } from '../interfaces/IEntityComment';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ICfo extends IEntityId, IEntityDeletedFlag, IEntityComment {

}

@classBackend('Cfo', 'pfhd_directories')
@className('Cfo')
/** Модель БД таблицы pfhd.NomenclatureTypes (Типы бюджета) */
export class Cfo implements ICfo {
  constructor(public id: number,
              public deletedFlag: boolean,
              public comment: string,
  ) {
  }
}
