import {StaffUnitVM} from "../view-models/staff-unit-view-model.class";
import {StaffUnitTypeEnum} from "../../../../../../../../../src/app/classes/domain/enums/StaffUnitTypeEnum";
import {IStaffUnit} from "../../../../../../../../../src/app/classes/domain/POCOs/stafflist/StaffUnit";

export class Helper {
  /** Является ли сотрудник внешним */
  public static isExternalStaffUnit(type: StaffUnitTypeEnum): boolean {
    switch (type) {
      case StaffUnitTypeEnum.CombinationExternal:
      case StaffUnitTypeEnum.MoonlighterExternal:
        return true;
      default:
        return false;
    }
    return true;
  }

  /** Является ли сотрудник заместителем */
  public static isProxy(staffUnit: Pick<IStaffUnit, 'parentId'>): boolean {
    return !!staffUnit.parentId;
  }

  /** Является ли сотрудник совместителем */
  public static isEmployment(staffUnit: StaffUnitVM): boolean {
    return !!staffUnit.parentId &&
      (staffUnit.staffUnitType.id == StaffUnitTypeEnum.MoonlighterInner ||
        staffUnit.staffUnitType.id == StaffUnitTypeEnum.MoonlighterExternal)
  }
}
