<kendo-splitter orientation="vertical" style="height: 100%">
  <kendo-splitter-pane [resizable]="false" style="height: 38px; margin-bottom: 3px; display: flex; justify-content: flex-end;" class="k-toolbar">
    <div class="report-date-control">
      <app-report-dates [size]="'small'"></app-report-dates>
    </div>
  </kendo-splitter-pane>
  <kendo-splitter-pane style="height: 100%; overflow: hidden">
    <router-outlet></router-outlet>
  </kendo-splitter-pane>
</kendo-splitter>
