import { filter, map, Observable, race, share, take, takeUntil } from "rxjs";
import { ArrayDataSource } from "src/app/classes/array-data-sources/data-source";
import { IEntityId } from "src/app/classes/domain/POCOs/interfaces/IEntityId";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { traceParam } from "src/app/modules/trace/decorators/param.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { FieldType } from "../classes/fields/field-type";
import { VersioningField } from "../classes/fields/versioning-field";
import { DirectoryEditRef_NotVersioning } from "./directory-edit-ref-not-versioning";
import { DirectoryEditRef_Versioning } from "./directory-edit-ref-versioning";

/** Сервис редактирования версионных и не версионных полей */
@traceClass('DirectoryEditRef')
export class DirectoryEditRef{

  private _currentDataItem: any;
  /** Текущий редактируемый элемент */
  public get currentDataItem(): any {
    return this._currentDataItem;
  }

  //Стрим закрытия окна редактирования
  private _closeOnObservable$: Observable<any>;

  private _onEndEdit$: Observable<void>;
  public get onEndEdit$(): Observable<void>{
    return this._onEndEdit$;
  }

  private _onClose$: Observable<void>;
  public get onClose$(): Observable<void>{
    return this._onClose$;
  }

  constructor(private readonly fields: FieldType[],
              public readonly versioning: DirectoryEditRef_Versioning,
              public readonly notVersioning: DirectoryEditRef_NotVersioning,
              public readonly tracerService: TracerServiceBase) {

      //Инициализируем какие стримы слушать для события окончания редактирования
      this._onEndEdit$ = race([this.notVersioning?.onClose$, this.versioning?.onEndEdit$].filter(f=> f)).pipe(share());

      //Инициализируем какие стримы слушать для событиязакрытия окна редактирования
      this._onClose$ = race([this.notVersioning?.onClose$, this.versioning?.onClose$].filter(f=> f)).pipe(share());
   }

  @traceFunc()
  public edit(@traceParam() fieldName: string, @traceParam({maxLength: 200}) dataItem: IEntityId, popupToElement: any){

    const field = this.fields.find(f => f.name === fieldName);

    if(!field) return;

    this._currentDataItem = dataItem;

    if(field instanceof VersioningField) {
      this.versioning.editField(field, dataItem);
    } else {
      this.notVersioning.editField(field, dataItem, popupToElement);
    }

    //Подписываемся на стрим закрытия окна и закрываем его (если нужно)
    this._closeOnObservable$?.pipe(
      take(1),
      takeUntil(this.onClose$),
    ).subscribe(() => this.close());

  }

  @traceFunc()
  public close() {
    this.versioning?.close();
    this.notVersioning?.close();
    this._currentDataItem = null;
  }

  /**
   * Метод позволяет установить автоматическое закрывание окна редактирования при срабатывании переданного Observable
   * Нужно учитывать, что при КАЖДОМ вызове метода edit() будет происходить подписка на переданный observable и отписка от него при закрытии окна
   */
  @traceFunc()
  public closeOnObservable$(obs$: Observable<any>) {
    this._closeOnObservable$ = obs$;
  }

  /** Метод для включения автоматического закрытия окна редактирования если изменились данные в datasource
   * и при этом изменилась именно та строка, которая в данный момент редактируется
   */
  @traceFunc()
  public closeOnCurrentEditingDataItemChanged$(dataSource: ArrayDataSource<IEntityId>) {
    this.closeOnObservable$(dataSource.observe$((i1, i2)=> i1.id === i2.id, (c1,c2)=> c1 === c2)
      .pipe(
        map(f=> f.some(s=> s.currentOrOrigin.id === this.currentDataItem?.id)),
        filter(f=> f)
      )
    );
  }
}
