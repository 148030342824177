import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ICode extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag {
  /** Наименование кода табельного учета */
  name: string;
  /** Цифровой код табельного учета */
  key: string;
  /** Флаг, указывающий является ли количество часов,
   * отражаемое в табеле по данному коду,
   * авторассчитываемым или вводится вручную */
  isComputedFlag: boolean;
  /** Поддерживает ли данный код ввод дополнительного параметра */
  supportValue2: boolean;
  /** Значение, вносимое в данную строку табеля будет браться из базовой строки данного сотрудника */
  isEqualBaseRowValue: boolean;
}

@classBackend('Code', 'timesheet_table')
@className('Code')
/** Код табельного учета */
export class Code implements ICode {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public name: string,
              public key: string,
              public isComputedFlag: boolean,
              public supportValue2: boolean,
              public isEqualBaseRowValue: boolean,
  ) {
  }
}
