

/** Фабрика получения названия полей по переданному типу. Возвращает функцию nameof */
export function xnameofFactory<TCtor extends abstract new (...args: any) => any>(ctor: TCtor): (key: keyof InstanceType<TCtor>) => string;
/** Фабрика получения названия полей по переданному экземпляру. Возвращает функцию nameof */
export function xnameofFactory<TObject>(instance: TObject): (key: keyof TObject) => string;
export function xnameofFactory(): any{
  return (key) => key;
}


const defaultSeparator = '.';
const defaultSeparatorBefore = true;

/** Построить полный путь по переданному типу */
export function xnameofPath<TCtor extends abstract new (...args: any) => any>(ctor: TCtor, separatorString?: string, separatorBefore?: boolean): PathItemReadonly<InstanceType<TCtor>>;
/** Построить полный путь по переданному экземпляру */
export function xnameofPath<TObject>(instance: TObject, separatorString?: string, separatorBefore?: boolean): PathItemReadonly<TObject>;
export function xnameofPath<TCtorOrObject>(ctorOrInstance: TCtorOrObject, separatorString?: string, separatorBefore?: boolean): any{
  return createProxy(new PathItem<TCtorOrObject>(), separatorString ?? defaultSeparator, separatorBefore ?? defaultSeparatorBefore);
}


/** Элемент пути */
type PathItemProxy<T> = {
  readonly [P in keyof T]: PathItemProxy<T[P]>;
} & PathItem<T>;

type PathItemReadonly<T> = Readonly<PathItemProxy<T>>

/** Создать Proxy объект */
function createProxy<T>(pathItem: PathItem<T>, separator: string, separatorBefore: boolean): PathItemReadonly<T>{
  const proxy = new Proxy(pathItem, {
    get(target: PathItem<T>, p: string | symbol, receiver: any): any {
      return onGet(target, p as keyof T, separator, separatorBefore);
    },
  });

  return proxy as any;
}

/** Функция обработки события обращения к Proxy */
function onGet<T>(target: PathItem<T>, key: keyof T, separator: string, separatorBefore: boolean){
  if(key === 'toString'){
    return target.toString.bind(target);
  }

  if(key.toString() === 'Symbol(Symbol.toPrimitive)'){
    return (hint) => {
      if(hint === 'default' || hint === typeof ''){
        return target.toString();
      }

      throw new Error('попытка преобразовать путь НЕ к string');
    };
  }
  const newPathItem = PathItem.Create(target, key, separator, separatorBefore)
  const proxy = createProxy(newPathItem, separator, separatorBefore);

  return proxy;
}

/** Элемент пути */
class PathItem<TObject> {

  /** Путь в виде массива */
  private readonly _path: string[] = [];


  constructor(private separator: string = defaultSeparator, private separatorBefore: boolean = defaultSeparatorBefore) {

  }

  toString(separatorBefore = this.separatorBefore){
    return this.build(separatorBefore);
  }

  /**
   * Построить путь
   * @param separatorBefore - поставить разделитель до пути.
   */
  private build(separatorBefore): string{
    return `${separatorBefore && this._path.length > 0 ? this.separator : ''}${this._path.join(this.separator)}`;
  }

  /** Создать на основе существующего пути */
  public static Create<T, K extends keyof T>(source: PathItem<T>, key: K, separatorString?: string, separatorBefore?: boolean): PathItem<T[K]>{
    const result = new PathItem(separatorString ?? defaultSeparator, separatorBefore ?? defaultSeparatorBefore);
    result._path.push(...source._path, key.toString());
    return result;
  }
}
