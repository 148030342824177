import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { VersioningItem } from 'src/app/classes/directories/edit/versioningItem';
import { ISubdivision, Subdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { exLoadingMessage } from 'src/app/operators/ex-loading-message.operator';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { Api1SubdivisionControllerService, ReorderResult } from 'src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service';
import { Api1SubdivisionsDirectoryTreeListControlControllerService, SubdivisionTreeListItem } from 'src/app/services/webApi/webApi1/controllers/api1-subdivisions-directory-treelist-control-controller.service';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { IForDate } from '../../../../../../../../src/app/classes/for-date';
import { ArrayDataSourceIEntityId, DataSource } from '../../../../../../../../src/app/classes/array-data-sources/data-source';
import { IEntityId } from '../../../../../../../../src/app/classes/domain/POCOs/interfaces/IEntityId';
import { DataStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-state-builder';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { DataHasOwnerStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-has-owner-state-builder';
import { Employee } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/Employee';
import { Responsible } from '../../../../../../../../src/app/classes/domain/POCOs/timesheet/Responsible';
import { ArrayHelper } from '../../../../../../../../src/app/helpers/arrayHelper';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

/** Базовый класс сервиса источника данных */
@Injectable()
export abstract class SubdivisionsDirectoryTreeListComponentDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, SubdivisionTreeListItem> {
  abstract addSubdivisionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>

  abstract deleteSubdivisionVersion$(versionId: number): Observable<boolean>

  abstract getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]>

  abstract editSubdivisionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>

  abstract save$(entity: Subdivision): Observable<[]>

  abstract editEndDate$(entity: Subdivision, isSave: boolean): Observable<boolean>

  abstract addSubdivision$(entity: Subdivision): Observable<Subdivision>

  abstract reorderSubdivision$(subdivision1Id: number, subdivision2Id: number, date: Date): Observable<ReorderResult>
  abstract getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;
}

@Injectable()
@traceClass('SubdivisionsDirectoryTreeListComponentDataSourceService')
export class SubdivisionsDirectoryTreeListComponentDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, SubdivisionTreeListItem> implements SubdivisionsDirectoryTreeListComponentDataSourceServiceBase {

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly api1SubdivisionControllerService: Api1SubdivisionControllerService,
              private readonly api1SubdivisionsDirectoryTreeListControlControllerService: Api1SubdivisionsDirectoryTreeListControlControllerService,
              private readonly traceService: TracerServiceBase) {
    super();
  }

  @traceFunc()
  public addSubdivisionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.api1SubdivisionControllerService.addSubdivisionVersion$(actionId, versioningEntity);
  }

  @traceFunc()
  public deleteSubdivisionVersion$(versionId: number): Observable<boolean> {
    return this.api1SubdivisionControllerService.deleteSubdivisionVersion$(versionId);
  }

  @traceFunc()
  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]> {
    return this.api1SubdivisionControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }

  @traceFunc()
  public editSubdivisionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.api1SubdivisionControllerService.editSubdivisionVersion$(actionId, versioningEntity);
  }

  @traceFunc()
  public save$(entity: Subdivision): Observable<[]> {
    return this.api1SubdivisionControllerService.save$(entity).pipe(exLoadingMessage(this.loadingIndicatorService, 'Сохранение данных'));
  }

  @traceFunc()
  public editEndDate$(entity: Subdivision, isSave: boolean): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable('Сохранение даты окончания', this.api1SubdivisionControllerService.editEndDate$(entity.endDate, [{ id: entity.id, timestamp: entity.timestamp }], isSave));
  }

  @traceFunc()
  public addSubdivision$(entity: Subdivision): Observable<Subdivision> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление подразделения',
      this.api1SubdivisionControllerService.addSubdivision$(entity),
    );
  }

  @traceFunc()
  public reorderSubdivision$(subdivision1Id: number, subdivision2Id: number, date: Date): Observable<ReorderResult> {
    return this.loadingIndicatorService.addToObservable(
      'Изменение порядка подразделения',
      this.api1SubdivisionControllerService.reorderSubdivision$(subdivision1Id, subdivision2Id, date),
    );
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
        subdivisions: Subdivision,
        employees: Employee,
        responsible: Responsible,
      })
      .pipe(
        map(value => value.data),
        map(value => {
          let flattedEmployees = ArrayHelper.flatMap(this.dataSource.data2.map(m => m.responsibleUsers.map(r => ({ employeeId: r.employeeId, subdivisionId: m.id }))));

          return [
            ...new DataHasOwnerStateBuilder(value.subdivisions, this.dataSource.data2, x => x.id).build_().source.map(x => x.signalR.currentOrOrigin.ownerId),
            ...new DataHasOwnerStateBuilder(value.employees, flattedEmployees, x => x.employeeId).build_().source
              .filter(x =>
                (x.state === 'added' && this.dataSource.data.some(f => f.id === x.signalR.currentOrOrigin.ownerId))
                || x.state === 'modified' || x.state === 'deleted',
              )
              .map(x => x.dataItem?.subdivisionId ?? x.signalR.currentOrOrigin.ownerId),
            ...new DataStateBuilder(value.responsible, this.dataSource.data2, (x, y) => x.subdivisionId === y.id).build_().source
              .filter(x => x.dataItem) // Не интересуют, котрые не относятся к нашим данным
              .map(x => x.dataItem.id),
          ];
        }),
        map(value => this.reloadFromSignalR$(value)),
      );
  }


  protected _reloadData$(params: IForDate): Observable<SubdivisionTreeListItem[]> {
    return this.loadingIndicatorService.addToObservable(
      'Обновление данных подразделения',
      this.api1SubdivisionsDirectoryTreeListControlControllerService.getForDate$(params.forDate, params.startDate, params.endDate),
    );
  }

  protected _reloadFromRemoteByIds$(params: IForDate, targets: IEntityId['id'][]): Observable<SubdivisionTreeListItem[]> {
    return this.api1SubdivisionsDirectoryTreeListControlControllerService.getForDate$(params.forDate, params.startDate, params.endDate, targets);
  }

  public getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]> {
    return  this.api1SubdivisionControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, subdivisionIds)
  }

  readonly paramsDataSource = new DataSource<IForDate>();
  readonly dataSource = new ArrayDataSourceIEntityId<SubdivisionTreeListItem>();
}
