import {Observable} from "rxjs";
import {map} from "rxjs/operators";

/** Оператор объединяет массив в строку */
export function exArrayJoin<O>(separator: string): (source$: Observable<O[]>) => Observable<string> {
  return source$ => {
    return source$.pipe(map(arr => {
      return arr.join(separator)
    }));
  }
}
