<kendo-grid
[height]="height"
[data]="gridView"
[selectable]="selectableSettings"
[navigable]="true"

kendoGridSelectBy="id"
[(selectedKeys)]="selectedIds"

[filter]="gridState.filter"

>
<ng-template kendoGridToolbarTemplate>
  <kendo-textbox placeholder="Поиск" [clearButton]="true" (valueChange)="filterData($event)"></kendo-textbox>
</ng-template>
  <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column>
  <kendo-grid-column field="key" [width]="50" title=""></kendo-grid-column>
  <kendo-grid-column field="name" title="Код табельного учёта" ></kendo-grid-column>
  <kendo-grid-column field="note" title="Примечание" >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.isComputedFlag ? 'Авторассчитываемый код табельного учёта' : ''}}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
