<kendo-treelist kendoTreeListExpandable
                [kendoTreeListHierarchyBinding]='source'
                childrenField='childStrings'
                [initiallyExpanded]='true'
                style='height: 100%;'
>
  <ng-template kendoTreeListToolbarTemplate>
    <button *ngIf="exportExcelFileName"
            kendoButton
            class="button-panel"
            type='button'
            icon='file-excel'
            primary='true'
            size='small'
            (click)="onExcelExport()"
    >
      Экспорт в Excel
    </button>
  </ng-template>

  <kendo-treelist-column
    [expandable]='true'
    title=''
    [width]='300'
    [style]="{ 'display': 'flex' }"
    field='parentString'
  >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span style='white-space: pre-wrap' [class]="dataItem.isRoot ? 'first-row' : 'child-row'">{{dataItem.parentString}}</span>
    </ng-template>
  </kendo-treelist-column>

</kendo-treelist>
