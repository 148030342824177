import {Observable, tap} from "rxjs";

/** Оператор аналог rxjs.tap с возможностью лимитировать количество вызовов. Если превышает, то next не выполняется */
export function exTapN<O>(limit: number, next: (value: O) => void): (source$: Observable<O>) => Observable<O>{
  let current = 0;
  return source$ => {
    return source$.pipe(
      tap(value => {
        if(current >= limit){
          return;
        }

        current++;
        next(value);
      })
    );
  }
}
