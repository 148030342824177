<kendo-grid
  [height]="height"
  [data]="gridData"

  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
  (edit)="editHandler($event)"
>
<ng-template kendoGridToolbarTemplate>
  <button kendoGridAddCommand icon="plus" [disabled]="isDisabled || getAllowedFinancingSources.length == 0 || (isEditingNow$ | async)">Добавить</button>
</ng-template>
  <kendo-grid-column field="financingSourceId" title="Источник финансирования">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{getFinancingSourceNameById(dataItem.financingSourceId)}}
      </ng-template>

      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-column="column"
        let-formGroup="formGroup"
        let-isNew="isNew"
      >
      <div #financingSourceDiv>
        <kendo-dropdownlist
          [defaultItem]="getDefaultDropDownValueForDataItem(dataItem, isNew)"
          [data]="getAllowedFinancingSources"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get(column.field)"
        >
        </kendo-dropdownlist>
        <kendo-popup
            [anchor]="financingSourceDiv"
            *ngIf="form.get(column.field).invalid && !(isNew && form.get(column.field).untouched)"
            popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
          >
          <div class="errors-popup">
            <div *ngIf="formGroup.get(column.field).errors?.required">Обязательно для заполнения</div>
          </div>
        </kendo-popup>
      </div>
    </ng-template>


  </kendo-grid-column>
  <kendo-grid-column field="rate" title="Ставка" >
    <ng-template
    kendoGridEditTemplate
    let-dataItem="dataItem"
    let-column="column"
    let-formGroup="formGroup"
    let-isNew="isNew"
    >
    <div #dinnerHourDurationDiv>
      <kendo-numerictextbox  [formControl]="formGroup.get(column.field)"
                             [min]=rateStep
                             [max]="999.00"
                             [format]="'n2'"
                             [decimals]="2"
                             [step]=rateStep
                             [autoCorrect]="true"
      ></kendo-numerictextbox>
      <kendo-popup
          [anchor]="dinnerHourDurationDiv"
          *ngIf="form.get(column.field).invalid && !(isNew && form.get(column.field).untouched)"
          popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
        >
        <div class="errors-popup">
          <div *ngIf="formGroup.get(column.field).errors?.required">Обязательно для заполнения</div>
          <div *ngIf="formGroup.get(column.field).errors?.multipleOf">Количество ставок должно быть кратно {{formGroup.get(column.field).errors?.multipleOf.multiplier}}: {{(formGroup.get(column.field).errors?.multipleOf.multiplier).toFixed(2)}}, {{(formGroup.get(column.field).errors?.multipleOf.multiplier*2).toFixed(2)}}, {{(formGroup.get(column.field).errors?.multipleOf.multiplier*3).toFixed(2)}}...</div>
          <div *ngIf="formGroup.get(column.field).errors?.min">Значение не может быть меньше {{formGroup.get(column.field).errors?.min.min}}. Текущее значение: {{formGroup.get(column.field).errors?.min.actual}}</div>
          <div *ngIf="formGroup.get(column.field).errors?.max">Значение не может быть больше {{formGroup.get(column.field).errors?.max.max}}. Текущее значение: {{formGroup.get(column.field).errors?.max.actual}}</div>
        </div>
      </kendo-popup>
    </div>
   </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comment" title="Комментарий" >
    <ng-template
    kendoGridEditTemplate
    let-dataItem="dataItem"
    let-column="column"
    let-formGroup="formGroup"
    let-isNew="isNew"
    >
    <div #commentDiv>
      <input kendoTextBox [formControl]="formGroup.get(column.field)" />
      <kendo-popup
          [anchor]="commentDiv"
          *ngIf="form.get(column.field).invalid && !(isNew && form.get(column.field).untouched)"
          popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
        >
        <div class="errors-popup">
          <div *ngIf="formGroup.get(column.field).errors?.minlength">Длина значения должна быть больше {{formGroup.get(column.field).errors?.minlength.requiredLength}}. Текущая длина: {{formGroup.get(column.field).errors?.minlength.actualLength}}</div>
          <div *ngIf="formGroup.get(column.field).errors?.maxlength">Длина значения должна быть меньше {{formGroup.get(column.field).errors?.maxlength.requiredLength}}. Текущая длина: {{formGroup.get(column.field).errors?.maxlength.actualLength}}</div>
        </div>
      </kendo-popup>
    </div>
   </ng-template>


  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]=110>
    <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand [primary]="true" icon="pencil"></button>
        <button kendoGridSaveCommand [disabled]="form?.invalid" icon="save" [primary]="true"></button>
        <button kendoGridCancelCommand icon="close"></button>
        <button kendoGridRemoveCommand *ngIf="!isNew" icon="trash"></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
