<kendo-dialog *ngIf="loadIndicatorService.isShow">
  <div class="loading-indicator-container">
    <div class="loading-indicator-content">{{loadIndicatorService.currentMessage}}</div>
    <kendo-chunkprogressbar
      [indeterminate]="loadIndicatorService.chunkMaxValue == 1"
      [chunkCount]="loadIndicatorService.chunkMaxValue"
      [min]="0"
      [max]="loadIndicatorService.chunkMaxValue"
      [value]="loadIndicatorService.chunkValue"
    ></kendo-chunkprogressbar>
  </div>
</kendo-dialog>


