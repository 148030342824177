import { Directive, HostListener, Input} from "@angular/core";
import {  DefaultValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: '[inputTrim]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InputTrimDirective, multi: true }]
})
export class InputTrimDirective extends DefaultValueAccessor  {

  @Input('autotrim') autotrim: boolean = true;

  /** Регистрируем событие изменения значения
   * При этом пробрасываем туда предварительную обработку значения через функцию processValue.
   */
  registerOnChange(fn: (_: any) => void): void {

    // Нужно запоминать старое значение, чтобы сравнивать с новым после его обработки, для принятия решения бросать событие изменения или нет
    let oldProcessedValue;

    //Создаем новую функцию-обёртку, onChange, которая вызывает onChange но при этом предварительно обрабатывает значение через трим.
    const onchangeWithTrim = (_: any) => {

      //Обрабатываем значение через трим
      const processedValue = this.processValue(_);

      //Если после обработки значение отличается от старого значения, то вызываем событие onChange
      if(processedValue !== oldProcessedValue) {
        fn(processedValue);
      }

      //Запоминаем старое значение, после его обработки
      oldProcessedValue = processedValue;
      return {};
    }

    //Регистрируем наше модифицированное событие onchange
    super.registerOnChange(onchangeWithTrim);
  }

  /** Событие происходит в момент, когда нужно обработать значение */
  @HostListener("blur", ["$event.target.value"])
  listenBlur(val: string): void {
    this.writeValue(this.processValue(val));
  }

  writeValue(value: any): void {
    super.writeValue(this.processValue(value));
  }

  /** Обработка значения, нужно ли триммировать? */
  private processValue(value: string) : string {
    if((this.autotrim !== false) && typeof value === "string") {
      return value.trim();
    }
    return value;
  }

}
