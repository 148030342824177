import { AbstractControl } from "@angular/forms";
import { combineLatest, map, Observable, tap } from "rxjs";
import { ArrayExpanded } from "../helpers/arrayHelper";

/** Общий класс с кастомными функциями валидации
 * НЕ путать с валидаторами
 * Функции вызываются один раз (когда нужно) и вручную управляют ошибками контролов
 * через функцию .setErrors
 */
export class CustomFormValidationFunctions{

  /** Функция возвращает Observable, который слушает переданный стрим данных
   * и проверяет есть ли текущее значение контрола в этом стриме
   * сопоставление происходит переданной фунции compareFn
  */
  static validateDataWithControlValue$<TData, TValue>(data$: Observable<TData[]>, control: AbstractControl<any,TValue>, compareFn: (data: TData, controlValue: TValue) => boolean ): Observable<any> {
    return data$
      .pipe(
        map((data) =>
        {
          if(!control.value) return null;

          if(!data.some(d => compareFn(d, control.value))) {
            const errObj = { invalidData: true };
            return errObj;
          }
          return null;
        }),
        tap(s=> s ? control.setErrors({...control.errors, ...s }, {emitEvent: true}) : this.removeError(control, "invalidData"))
      );
  }

  /** Функция возвращает Observable, который слушает переданный стрим данных
   * и проверяет есть ли текущее значение контрола в этом стриме
   * Функция аналогична функции validateDataWithControlValue$ - за исключением того, что значение контрола будет массивом
   * dataSelectorFn - селектор ключа из данных
   * valueSelectorFn - селектор ключа из массива значений контрола
  */
    static validateDataWithArrayControlValue$<TData, TValue, TSelectorResult>(data$: Observable<TData[]>, control: AbstractControl<any[],TValue[]>, dataSelectorFn: (data: TData) => TSelectorResult, valueSelectorFn: (value: TValue) => TSelectorResult ): Observable<any> {
      return combineLatest([data$, control.valueChanges])
        .pipe(
          map(([data]) =>
          {
            if(!control.value) return null;

            const joined = new ArrayExpanded(control.value)
            .leftInnerJoinGroupedRight(data, l=> valueSelectorFn(l), r=> dataSelectorFn(r), (l, r) => ({
              value: l,
              hasData: !!r[0]
            })).array;

            if(joined.some(s=> !s.hasData)) {
              const errObj = { invalidData: true };
              return errObj;
            }
            return null;
          }),
          tap(s=> s ? control.setErrors({...control.errors, ...s }, {emitEvent: true}) : this.removeError(control, "invalidData"))
        );
    }

  /** Удаляет ошибку у контрола */
  private static removeError(control: AbstractControl, errorCode: string) {
    if(control.hasError(errorCode)){
      const errorsCopy = {...control.errors};
      delete errorsCopy[errorCode];
      control.setErrors(Object.keys(errorsCopy).length === 0 ? null : errorsCopy, {emitEvent: true});
    }
  }

}
