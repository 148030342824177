/** Помощник для http заголовков */
import { HttpHeaders } from "@angular/common/http";
import {JwtHelper} from "./JwtHelper";

export class HttpHeadersHelper{
  /** Отключить кэш */
  public static noCache(httpHeaders: HttpHeaders){
    return httpHeaders.append('Cache-Control', 'no-cache')
      .append('Pragma', 'no-cache')
      .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
  }

  /** Добавить заголовок пропуска токена авторизации */
  public static skipToken(httpHeaders: HttpHeaders){
    return httpHeaders.append(JwtHelper.SkipTokenHeaderName, 'true');
  }
}
