import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WebApi1Service } from "../web-api1.service";
import { Observable, ReplaySubject } from "rxjs";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { IReportPeriodOptional, ReportPeriod } from "src/app/classes/domain/POCOs/stafflist/ReportPeriod";

@Injectable({
  providedIn: "root"
})
export class Api1ReportPeriodsControllerService implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {

  }

  public add$(entity: IReportPeriodOptional): Observable<ReportPeriod> {
    return this.httpClient
      .post<ReportPeriod>(this.webApi1Service.controllers.reportPeriod.actions.add.toString(),
        entity)
  }

  /** Получить */
  public get$(ids: number[] = null): Observable<Array<ReportPeriod>> {
    return this.httpClient.post<Array<ReportPeriod>>(this.webApi1Service.controllers.reportPeriod.actions.get.toString(),
    {
      Ids: ids
    })
  }

  /** Сохранить */
  public save$(entity: ReportPeriod): Observable<[]> {
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.reportPeriod.actions.save.toString(),
        entity
      );
  }

  /** Сохранить */
  public delete$(entityId): Observable<boolean> {
    return this.httpClient.delete<boolean>(this.webApi1Service.controllers.reportPeriod.actions.delete.toString(),
      { params: HttpParamsHelper.getParams([["Id", entityId]]) }
    )
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
