import { IBusinessValidationResult } from "./busines-validation-result";
import {Guid} from "guid-typescript";

const codeName: keyof ResponseObjError<any> = 'code';
const dataName: keyof ResponseObjError<any> = 'data';

/**
 * Интерфейс ошибки ответа сервера
 */
export class ResponseObjError<T> {
  /**
   * Код ошибки сервера
   */
  code: string;
  /**
   * Данные ошибки сервера
   */
  data: T;

  /**
   * Проверяет является ли объект данным типом
   */
  public static checkType(source: any): source is ResponseObjError<any>{
    return !!source && dataName in source && typeof source[codeName] === 'string' && Guid.isGuid(source[codeName]);
  }

  /** Проверить тип и вернуть код ошибки или null */
  public static checkTypeReturnCode(source: any): string{
    if(ResponseObjError.checkType(source)){
      return source.code;
    }

    return null;
  }

  /** Проверить является ли ошибка бизнес ошибкой валидации данных? */
  public static isBusinessValidationError(source: ResponseObjError<any>) {
    return source?.code === "d5e17e15-9f0f-4f72-8a9f-27b860491f35";
  }

  /** Проверить является ли ошибка ошибкой параллельного доступа к данным?
   * Когда одна и та же запись сохранена разными пользователями в одно и то же время, или передан устаревший Timestamp */
  public static isDbUpdateConcurrencyExceptionError(source: ResponseObjError<any>) {
    return source?.code === "a9b49a21-c2e8-40bf-a600-8ef3977cee06";
  }

  /** Проверить является ли ошибка ошибкой парсинга экселя? Например при импорте */
  public static isExcelParsingException(source: ResponseObjError<any>) {
    return source?.code === "589d59ad-7372-4f82-bd72-89a1aba64649";
  }

  /** Проверить является ли ошибка ошибкой при редактировании даты окончания */
  public static isEntityEditEndDateException(source: ResponseObjError<any>) {
    return source?.code === "bcab4a3b-6eac-4bd1-8042-a96977c1b4ed";
  }

  /** Проверить является ли ошибка ошибкой при редактировании даты начала */
  public static isEntityEditStartDateException(source: ResponseObjError<any>) {
    return source?.code === "b9f36c0f-d8fb-484c-a45f-df5be1975595";
  }

  /** Проверить является ли ошибка ошибкой при редактировании DeletedFlag */
  public static isEntityEditDeletedFlagException(source: ResponseObjError<any>) {
    return source?.code === "a3822776-8f53-47e9-94cf-4c3774f8d06b";
  }

  /** Получить ошибку как бизнес ошибку валидации данных */
  public static getAsBusinessValidationError(source: ResponseObjError<any>): ResponseObjError<IBusinessValidationResult[]> {
    if(!ResponseObjError.isBusinessValidationError(source)) {
      throw new Error("Ошибка не является бизнес ошибкой валидации данных");
    }
    return source as ResponseObjError<IBusinessValidationResult[]>;
  }

  /** Массив GUID авторизационных ошибок */
  private static readonly _authErrors = [
    '111c10bd-1341-4078-b08b-98f51fe8f064',
    'ab77dd94-1e1b-4f9f-a8a3-55fa77e4f837',
    '3b925536-efab-4916-9501-6ba7ce25467a',
    '4a2b09eb-033a-419e-8d75-e75257dc104c',
    'b7d8634d-001a-4fe1-aa21-a4fa253b23bf',
    'c1537a87-6db6-48c6-bc40-9a400f3fcb20',
    '19303ef5-51ba-4621-af39-41c9139491ae',
  ]

  /** Метод проверяет, относится ли переданный объект к ошибке авторизации пользователя или обновления авторизации пользователя */
  public static isAuthError(source: any) : source is ResponseObjError<string>{
    if(this.checkType(source)){
      if(this._authErrors.indexOf(source.code) >= 0){
        return true;
      }
    }

    return false;
  }
}
