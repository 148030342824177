import { Observable } from "rxjs";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";
import { FieldControlType } from "../field-controls/field-control-type";
import { TSettingsValueType } from "../service-settings/settings-value-types";
import { IFieldSettings } from "./interfaces/common";

export class VersioningField {
  public isVersioning: boolean = true;
  constructor(
    public name: string,
    public actionId: SupportedActionEnum,
    public settings: VersioningFieldSettings,
    public control: FieldControlType
  ) { }
}

export interface VersioningFieldSettings extends IFieldSettings {
  dialogTitle?: TSettingsValueType<string>;

  minParentDateValueFunc?: (dataItem: any) => Date;
  maxParentDateValueFunc?: (dataItem: any) => Date;

  /** Разрешать ли редактировать дату начала и дату окончания для рут записи */
  isAllowEditRootStartEndData?: boolean;

  /** Скрыть root запись - костыль, Женя против этого, Антон одобрил */
  isHideRoot?: boolean;

  /** Требовать ввод Дата начала */
  isRequiredStartDate?: boolean;
  /** Требовать ввод Дата окончания */
  isRequiredEndDate?: boolean;

  /** Модифицирует метод получения версий с сервера.
   * Типичный пример использования это установка displayValue, которые будут получены путём отправки второго запроса на сервер */
  preGetVersionsData$?: (versioningItems$: Observable<VersioningItem[]>) => Observable<VersioningItem[]>;
}
