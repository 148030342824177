import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs";
import {IStaffUnitType, StaffUnitType} from "../../../../classes/domain/POCOs/stafflist/StaffUnitType";

@Injectable({
  providedIn: "root"
})
export class Api1StaffUnitTypeControllerService {
  constructor(
    private httpClient: HttpClient,
    private webApi1Service: WebApi1Service
  ) {
  }

  /** Получить */
  public get$(body: {ids: number[] | undefined, deletedFlag: boolean | undefined} | undefined){
    return this.httpClient.post<IStaffUnitType[]>(
      this.webApi1Service.controllers.staffUnitType.actions.get.toString(),
      {
        ids: body?.ids,
        deletedFlag: body?.deletedFlag,
      }
    )
  }

  /** @deprecated используй {@link get$} */
  public getAll$(): Observable<Array<StaffUnitType>> {
    return this.httpClient.get<Array<StaffUnitType>>(
      this.webApi1Service.controllers.staffUnitType.actions.getAll.toString()
    );
  }

  /** @deprecated  используй {@link get$}*/
  public getByIds$(ids: Array<number>): Observable<Array<StaffUnitType>> {
    return this.httpClient.post<Array<StaffUnitType>>(
      this.webApi1Service.controllers.staffUnitType.actions.getByIds.toString(),
      ids
    );
  }
}
