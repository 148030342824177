import { IData, IFieldControl } from "./interfaces/common";
import { DropDownFilterSettings, PopupSettings } from "@progress/kendo-angular-dropdowns";
import { FilterExpandSettings } from "@progress/kendo-angular-treeview";

export class DropDownTreeFieldControl implements IFieldControl<number> {
  public readonly name = 'DropDownTreeFieldControl';
  public getValueFunc = (stringValue: string) => !stringValue ? null : Number(stringValue);


  constructor(public settings?: (() => DropDownTreeFieldControl_Settings) | DropDownTreeFieldControl_Settings) { }
}

export interface DropDownTreeFieldControl_Settings extends IData<any> {
  filterSettings?: DropDownFilterSettings;
  filterExpandSettings?: FilterExpandSettings;
  popupSettings?: PopupSettings;
  listHeight?: number;
  textField?: string;
  valueField?: string;
  parentIdField?: string;
}
