import { Component, OnInit } from '@angular/core';
import { IReportPeriodOptional } from 'src/app/classes/domain/POCOs/stafflist/ReportPeriod';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { notVersioningOnlyDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/not-versioning-only-directory-service-provider';
import { IReportPeriodsDirectoryGridComponent } from './i-report-periods-directory-grid.component';
import { ReportPeriodsDirectoryGridComponentService } from './services/report-periods-directory-grid-component.service';
import { Observable } from 'rxjs';
import { CellClickEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { ReportPeriodsDirectoryGridComponentDataService } from './services/report-periods-directory-grid-component-data.service';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';

@Component({
  selector: 'app-report-periods-directory-grid',
  templateUrl: './report-periods-directory-grid.component.html',
  styleUrls: ['./report-periods-directory-grid.component.css'],
  providers: [
    notVersioningOnlyDirectoryServiceProvider,
    ReportPeriodsDirectoryGridComponentService,
    ReportPeriodsDirectoryGridComponentDataService
  ]
})
@traceClass('ReportPeriodsDirectoryGridComponent')
export class ReportPeriodsDirectoryGridComponent implements IReportPeriodsDirectoryGridComponent, OnInit {

  currentSelectedItem: IReportPeriodOptional;

  public data$: Observable<IReportPeriodOptional[]>

  constructor(private readonly service: ReportPeriodsDirectoryGridComponentService,
              private readonly traceService: TracerServiceBase) {
    service.component = this
  }

  @traceFunc()
  ngOnInit() {
    this.service.init();
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  public onClickAdd() {
    this.service.onClickAdd();
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickRemove() {
    this.service.onClickRemove();
  }

  /** Событие происходит при двойном клике на ячейку */
  public onDblCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    this.service.onDblCellClick(e);
  }

  /** Событие при изменении выбранного элемента */
  public onSelectionChanged(e: SelectionEvent) {
    this.service.onSelectionChanged(e);
  }
}
