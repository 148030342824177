import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { DateFieldControl_Settings } from "../../services/classes/field-controls/date-field-control";
import { IDateDirectoryEditComponent } from "./i-date-directory-edit-control.component";
import { DateDirectoryEditComponentService } from "./services/date-directory-edit-control-component.service";

@Component({
  selector: 'app-date-directory-edit-control',
  templateUrl: './date-directory-edit-control.component.html',
  styleUrls: ['./date-directory-edit-control.component.css'],
  providers: [DateDirectoryEditComponentService]
})
@traceClass('DateDirectoryEditComponent')
export class DateDirectoryEditComponent implements OnInit, IDateDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: DateFieldControl_Settings;

  public minValue$: Observable<Date>;
  public maxValue$: Observable<Date>;

  constructor(private service: DateDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
  }
}
