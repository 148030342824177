import {Observable} from "rxjs";
import {LoadingIndicatorService} from "../services/loading-indicator.service";

const defaultMessage = 'Получение данных';
const defaultPredicateFn = () => true;

/**
 * Оператор оборачивает Observable в индикатор загрузки
 * @param service сервис
 * @param message сообщение загрузки. По умолчанию {@link defaultMessage}
 * @param predicateFn функция определяющая необходимость оборачивания. По умолчанию {@link defaultPredicateFn}
 */
export function exLoadingMessage<O>(service: LoadingIndicatorService, message: string = defaultMessage, predicateFn: () => boolean = defaultPredicateFn): (source$: Observable<O>) => Observable<O>{
  return stream$ => {
    if(predicateFn()){
      stream$ = service.addToObservable(message ?? '', stream$)
    }

    return stream$;
  }
}

/** Тип параметров для {@link exLoadingMessage2} */
export type ExLoadingMessageParamType = {
  /** Сервис */
  service: LoadingIndicatorService,
  /** Сообщение. Если не задано будет использовать {@link defaultMessage} */
  message?: string
};

/**
 * Перегрузка для {@link exLoadingMessage}<br>
 * @param param Если не передано, то оборачивать не будет
 */
export function exLoadingMessage2<O>(param: ExLoadingMessageParamType): (source$: Observable<O>) => Observable<O>{
  return stream$ => stream$.pipe(exLoadingMessage(param?.service, param?.message, () => !!param));
}
