import {Injectable} from "@angular/core";
import {
  Api1SubdivisionsTreelistControlControllerService
} from "../../../../services/webApi/webApi1/controllers/api1-subdivisions-treelist-control-controller.service";
import {IForDate} from "../../../../classes/for-date";
import {LoadingIndicatorService} from "../../../../services/loading-indicator.service";
import {Observable, switchMap} from "rxjs";
import {
  ArrayDataSourceIEntityIdServiceWithParamsBase} from "../../../../services/data-source-services/data-source.service";
import {ISubdivision, Subdivision} from "../../../../classes/domain/POCOs/stafflist/Subdivision";
import {
  Api1SubdivisionControllerService
} from "../../../../services/webApi/webApi1/controllers/api1-subdivision-controller.service";
import {ArrayDataSourceIEntityId, DataSource} from "../../../../classes/array-data-sources/data-source";
import {IEntityId} from "../../../../classes/domain/POCOs/interfaces/IEntityId";
import {filter, map, takeUntil} from "rxjs/operators";
import {DataHasOwnerStateBuilder} from "../../../../classes/data-state-builders/data-has-owner-state-builder";
import {DbChangedListener} from "../../../../services/signal-r/listeners/db-changed-listener";
import {Responsible} from "../../../../classes/domain/POCOs/timesheet/Responsible";
import {AuthService} from "../../../../modules/auth/services/auth.service";
import {AlertService} from "../../../../services/alert.service";
import {
  Api2SubdivisionControllerService
} from "../../../../services/webApi/webApi2/controllers/api2-subdivision-controller.service";


const reloadingMessage = 'Загрузка подразделений';
const reloadingItemsMessage = 'Обновление подразделений';

/** Базовый класс сервиса данных */
@Injectable()
export abstract class SubdivisionsTreelistComponentDataSourceServiceBase<TParam> extends ArrayDataSourceIEntityIdServiceWithParamsBase<TParam, ISubdivision>{
}

/**
 * Сервис данных.<br>
 * Используются параметры IForDate<br>
 * Используется для получения данных Api1SubdivisionControllerService<br>
 * Используется signalR - изменение подразделений
 */
@Injectable()
export class SubdivisionsTreelistComponentDataSourceService extends SubdivisionsTreelistComponentDataSourceServiceBase<IForDate>{

  readonly paramsDataSource = new DataSource<IForDate>();
  readonly dataSource = new ArrayDataSourceIEntityId<ISubdivision>();

  constructor(private readonly subdivisionsControllerService: Api1SubdivisionControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Subdivision)
      .pipe(
        map(value => value.data),
        map(value => new DataHasOwnerStateBuilder(value, this.dataSource.data2, x => x.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.ownerId)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: IForDate): Observable<ISubdivision[]> {
    return this.loadingIndicatorService.addToObservable(
      reloadingMessage,
      this.subdivisionsControllerService.getForDate$(params.forDate, params.startDate, params.endDate)
    );
  }

  protected _reloadFromRemoteByIds$(params: IForDate, targets: IEntityId["id"][]): Observable<ISubdivision[]> {
    return this.loadingIndicatorService.addToObservable(
      reloadingItemsMessage,
      this.subdivisionsControllerService.getForDate$(params.forDate, params.startDate, params.endDate, targets)
    );
  }

}

/**
 * Сервис данных для timesheet.<br>
 * Используются параметры Date(Месяц)<br>
 * Используется для получения данных Api1SubdivisionsTreelistControlControllerService<br>
 * Используется signalR - изменение подразделений, изменение ответственных
 */
@Injectable()
export class SubdivisionsTreelistComponentDataSourceService2 extends SubdivisionsTreelistComponentDataSourceServiceBase<Date>{

  readonly paramsDataSource = new DataSource<Date>();
  readonly dataSource = new ArrayDataSourceIEntityId<ISubdivision>();

  constructor(private readonly subdivisionsTreelistControlControllerService: Api1SubdivisionsTreelistControlControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly authService: AuthService,
              private readonly alertService: AlertService) {
    super();

    this.dataSource.data2$.pipe(
      filter(value => !!value && value.length === 0), //если данные пустой массив
      takeUntil(this.unsubscribe$)
    ).subscribe(value => {
      this.alertService.defaultAlertOption.information()
        .mod(x => {
          x.message = 'Вы не назначены ответственным за ведение графика/табеля.\n\rОбратитесь к администратору';
        })
        .showAlert();
    })
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({subdivisions: Subdivision, responsible: Responsible})
      .pipe(
        map(value => value.data),
        map(value => {
          return {
            subdivisions: new DataHasOwnerStateBuilder(value.subdivisions, this.dataSource.data2, x => x.id).build_()
              .source.filter(x => x.state !== 'added') //добавленные подразделения не интересуют
              .map(x => x.signalR.currentOrOrigin.ownerId),
            responsibles: value.responsible
              .filter(x => x.currentOrOrigin.employeeId === this.authService.user?.MedStaffId) //Относящиеся к текущему сотруднику
              .map(x => x.currentOrOrigin.subdivisionId)
          }
        }),
        map(value => { //Объединяем идентификаторы подразделений
          const union: number[] = [];
          union.push(...value.subdivisions);
          union.push(...value.responsibles);
          return union;
        }),
        map(value => this.reloadFromSignalR$(value))
      )
  }

  protected _reloadData$(params: Date): Observable<ISubdivision[]> {
    return this.loadingIndicatorService.addToObservable(
      reloadingMessage,
      this.subdivisionsTreelistControlControllerService.getForTimeSheet2$(params)
    );
  }

  protected _reloadFromRemoteByIds$(params: Date, targets: IEntityId["id"][]): Observable<ISubdivision[]> {
    return this.loadingIndicatorService.addToObservable(
      reloadingItemsMessage,
      this.subdivisionsTreelistControlControllerService.getForTimeSheet2$(params, targets)
    );
  }

}

/**
 * Сервис данных для pfhd.<br>
 * Использует параметр IForDate<br>
 * Использует для получения данных Api2SubdivisionControllerService<br>
 * Используется signalR - ОТСУТСТВУЕТ по причине отсутствия передачи между webApi
 */
@Injectable()
export class SubdivisionsTreelistComponentDataSourceServicePFHD extends SubdivisionsTreelistComponentDataSourceServiceBase<IForDate> {

  readonly paramsDataSource = new DataSource<IForDate>();
  readonly dataSource = new ArrayDataSourceIEntityId<ISubdivision>();

  constructor(private readonly subdivisionService: Api2SubdivisionControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService,) {
    super();
  }

  protected _reloadData$(params: IForDate): Observable<ISubdivision[]> {
    return this.loadingIndicatorService.addToObservable(
      reloadingMessage,
      this.subdivisionService.GetForDate$({
        date: params.forDate,
        startDate: params.startDate,
        endDate: params.endDate,
        includeDeleted: false,
      })
    )
  }

  protected _reloadFromRemoteByIds$(params: IForDate, targets: IEntityId["id"][]): Observable<ISubdivision[]> {
    return this.loadingIndicatorService.addToObservable(
      reloadingItemsMessage,
      this.subdivisionService.GetForDate$({
        date: params.forDate,
        startDate: params.startDate,
        endDate: params.endDate,
        ownerIds: targets,
        includeDeleted: false
      })
    )
  }
}
