import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotVersioningField } from '../services/classes/fields/not-versioning-field';
import { IDirectoryEditNotVersioningComponent } from './i-directory-edit-not-versioning.component';
import { DirectoryEditNotVersioningComponentService } from './service/directory-edit-not-versioning-component.service';

@Component({
  selector: 'app-directory-edit-not-versioning',
  templateUrl: './directory-edit-not-versioning.component.html',
  styleUrls: ['./directory-edit-not-versioning.component.css'],
  providers: [DirectoryEditNotVersioningComponentService]
})
export class DirectoryEditNotVersioningComponent implements IDirectoryEditNotVersioningComponent, OnInit {

  /** Настройки поля */
  @Input("field") field: NotVersioningField;

  /** Данные */
  @Input("dataItem") dataItem: any;

  /** Инициализационное значение  */
  @Input('value') value: any;

  /** Событие происходящее перед сохранением значения */
  @Output("onSave") onSaveEventEmitter = new EventEmitter<any>();

  /** Контейнер используется для прикрепления в "appendTo", например для dropdowntree */
  @ViewChild('containerForAppend', { read: ViewContainerRef }) appendContainer;

  @HostBinding('class.edit-no-versioning-pop-up') editNoVersioningPopUp: boolean = true;
  @HostBinding('attr.field') fieldAttr: string;

  /** Label (вычисляемое поле на основе переданных настроек)  */
  public labelTitle: string;

  public form: UntypedFormGroup;
  public formControl:UntypedFormControl;

  constructor(public componentElementRef:ElementRef, private service: DirectoryEditNotVersioningComponentService ) {
    this.service.component = this;
  }

  ngOnInit() {
    //Мини-костыль только для попапа. В остальных случаях инициализация произойдет автоматически.
    if(this.field) {
      this.initialize();
    }
  }

  public initialize() {
    this.fieldAttr = this.field.name;
    this.service.initialize();
  }

  /**
  * Событие происходит при сохранении значения (нажата кнопка сохранить)
  */
   public onClickSave(e: any) {
    this.service.onClickSave();
    e.preventDefault();
  }
}
