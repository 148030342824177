import {Injectable} from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {AuthService} from "../services/auth.service";
import {
  ErrorPageSettings,
  WorkSpaceErrorComponentService
} from "../../../../../projects/timesheet/src/app/services/workspace/work-space-error.component.service";
import {ServerLoggerService} from "../../../services/loggers/server-logger.service";

/** Заголовок авторизации в запросе */
const AUTHORIZATION_HEADER_NAME = 'Authorization';


/**
 * Интерцептор обрабатывает ответы сервера:<br>
 * 1 Проверка на 401 ошибку, обновляет токен и повторяет запрос<br>
 * 2 Проверка на 403 ошибку, делает редирект на компонент ошибки<br>
 */
@Injectable({
  providedIn: "root"
})
export class CatchErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private errorService: WorkSpaceErrorComponentService,
              private readonly serverLoggerService: ServerLoggerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req.clone())
      .pipe(
        catchError((err, caught) => {
          if(!err || !(err instanceof HttpErrorResponse)){
            return throwError(() => err);
          }

          switch (err.status) {
            case 401:

              if(!req.headers.has(AUTHORIZATION_HEADER_NAME)){
                return throwError(() => err);
              }

              return this.refreshAuth$()
                .pipe(
                  switchMap(authData => {
                    const headers = req.headers.set(AUTHORIZATION_HEADER_NAME, `Bearer ${authData.token}`); //Устанавливаем новый токен

                    return next.handle(req.clone({headers: headers}))
                      .pipe(
                        catchError(repeatErr => {
                          const errorPageSettings = new ErrorPageSettings();
                          errorPageSettings.title = 'Вы не авторизованы';
                          errorPageSettings.message = 'Вы пытаетесь получить данные доступные только авторизованным пользователям.<br>Обратитесь к администратору.';
                          errorPageSettings.stackTrace = this.serverLoggerService.convertErrorToStr(err);

                          this.errorService.redirect(errorPageSettings);
                          return throwError(() => err);
                        })
                      );
                  })
                )
            case 403:
              this.errorService.redirect(ErrorPageSettings.AsExtensionObj().modResult(x => {
                x.title = 'У Вас нет прав доступа к ресурсу';
                x.message = 'Обратитесь к администратору для получения доступа';
                x.stackTrace = this.serverLoggerService.convertErrorToStr(err);
              }))

              return throwError(() => err);
            default:
              return throwError(() => err);
          }
        })
      )
  }

  /** Обновить токен авторизации */
  private refreshAuth$(){
    return this.authService.refreshAuth$()
      .pipe(
        catchError(tokenUpdateError => {
          const errorPateSettings = new ErrorPageSettings();
          errorPateSettings.title = 'Ошибка обновления данных авторизации';
          errorPateSettings.message = 'Ваша авторизация была просрочена.<br>При попытке ее восстановить произошла ошибка.';
          errorPateSettings.stackTrace = tokenUpdateError;

          this.errorService.redirect(errorPateSettings);

          return throwError(() => tokenUpdateError);
        })
      )
  }
}

