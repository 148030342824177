import { IEntityDeletedFlag } from "../../interfaces/IEntityDeletedFlag";
import { IEntityId } from "../../interfaces/IEntityId";
import { IEntityModify } from "../../interfaces/IEntityModify";
import { IEntityRowVersion } from "../../interfaces/IEntityRowVersion";
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface ICfoSummaryValuesPreset extends IEntityId, IEntityModify, IEntityDeletedFlag, IEntityRowVersion {
  name: string,
  subdivisionId: number | null,
  cfoId: number | null,
  nomenclatureId: number | null,
  userId: number | null
}

@classBackend('CfoSummaryValuesPreset', 'pfhd_cfo_request')
@className('CfoSummaryValuesPreset')
/** Модель БД таблицы pfhd_cfo_request.CfoSummaryValuesPresets (Пресеты значений свода цфо) */
export class CfoSummaryValuesPreset implements ICfoSummaryValuesPreset {
  constructor(
    public id: number,
    public modifiedDate: Date,
    public modifiedUserId: number | null,
    public deletedFlag: boolean,
    public name: string,
    public subdivisionId: number | null,
    public cfoId: number | null,
    public nomenclatureId: number | null,
    public userId: number | null,
    public timestamp: []
  ) { }

  public static nameMaxLength = 300;
}
