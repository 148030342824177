import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";


@Injectable()
export class ImportFromExcelComponentDataService implements OnDestroy {
  constructor(private httpClient: HttpClient, private loadingIndicatorService: LoadingIndicatorService){

  }
  /** Получение шаблона для импорта */
  public downloadTemplateAsDataURI$(url:string, importSettings?: any): Observable<DownloadTemplateAsDataURI> {
    return this.loadingIndicatorService.addToObservable('Загрузка Excel шаблона',
    this.httpClient
    .post<IDownloadTemplateResponse>(url, {settings: importSettings})).pipe(map(m=>
      new DownloadTemplateAsDataURI(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${m.base64Content}`, 'template.xlsx')
      ));
  }

  ngOnDestroy(): void {
  }
}

export class DownloadTemplateAsDataURI {
  constructor(public dataURI: string, public fileName: string){}
}

interface IDownloadTemplateResponse {
  base64Content: string;
}
