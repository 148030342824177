import {TraceStorageItem, TraceStorageServiceBase} from "./trace-storage-base.service";
import * as moment from "moment";

/** Сервис хранения трассировки в массиве */
export class TraceStorageService extends TraceStorageServiceBase{

  private _buffer: Array<TracerBufferItem> = [];
  /** Буфер */
  public get buffer(){
    return this._buffer;
  };

  private _bufferLength: number = 500;
  /** Длина буфера трассировки. По умолчанию 500 */
  public get bufferLength(){
    return this._bufferLength;
  }

  public set bufferLength(value){
    if(!value){
      throw new Error('bufferLength не может быть null | undefined | 0')
    }
    this._bufferLength = value;
  }

  constructor() {
    super();
  }

  add(value: TraceStorageItem): void {
    this._buffer.push(!value ? null : new TracerBufferItem(value.level, value.message));
    this.checkBuffer();
  }

  buildAsString(): string {
    const oneMinute = 60 * 1000;
    const minDowntime = oneMinute;

    const bufferCopy = [...this.buffer]; //Копируем буфер
    bufferCopy.push(new TracerBufferItem(0, ''));//Добавляем с текущей датой

    const messages: string[] = [];

    let prevDate: Date = null;
    for (let x of bufferCopy) {
      if(!x) {
        messages.push('');
        continue;
      }

      if(!prevDate){
        prevDate = x.date;
      }

      const dateDiff = +x.date - +prevDate;
      if(dateDiff > minDowntime){
        messages.push(this.getDowntimeString(Math.round(dateDiff / oneMinute)));
      }

      prevDate = x.date;

      messages.push(`${this.buildTab(x.level)}[${moment(x.date).format('DD.MM.YYYY HH:mm ss SSS')}]  ${x.message}`)
    }

    return messages.join('\r\n');
  }

  clear(): void {
    this._buffer = [];
  }

  /** Получить информационную строку о простое программы*/
  private getDowntimeString(minutes: number){
    return `\r\n-- Простой ${minutes} мин.\r\n`
  }

  /** Построить отступ в зависимости от уровня */
  private buildTab(level: number): string{
    if(level < 1) {return ''}

    let result = '\t';
    for (let i = 1; i < level; i++){
      result = result + '|\t';
    }

    return result;
  }

  /** Проверить на переполнение и удалить старые при необходимости */
  private checkBuffer(){
    while (this.buffer.length > this.bufferLength){
      this.buffer.shift();
    }
  }
}

/** Класс элемента буфера */
class TracerBufferItem extends TraceStorageItem{

  public readonly date: Date = new Date();

  constructor(level: number,
              message: string) {
    super(level, message);
  }
}
