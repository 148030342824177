<div class="kendo-custom-form">
  <form class="k-form kendo-custom-form-content" [formGroup]="form">

    <ng-container *ngIf="this.currentSubdivision$ | async as subdivision">
      <div class="form-inline-div">
        <kendo-formfield>
          <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
          <kendo-datepicker #startDate [formControlName]="'startDate'" [min]="subdivision.startDate" [max]="form.controls.endDate.value ? form.controls.endDate.value : subdivision.endDate"></kendo-datepicker>
          <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
          <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
          <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield>
          <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
          <kendo-datepicker #endDate [formControlName]="'endDate'" [min]="form.controls.startDate.value ? form.controls.startDate.value : subdivision.startDate" [max]="subdivision.endDate"></kendo-datepicker>
          <kendo-formerror *ngIf="form.controls.endDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
          <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
          <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
        </kendo-formfield>
      </div>
    </ng-container>

    <kendo-formfield>
      <kendo-label [for]="occupationId" text="Должность"></kendo-label>
      <kendo-dropdownlist
        #occupationId
        [data]="occupations$ | async"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [virtual]="{
          itemHeight: 28
        }"
        [kendoDropDownFilter]="{
          caseSensitive: false,
          operator: 'contains'
        }"
        [formControlName]="'occupationId'"
      >
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="form.controls.occupationId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield class="horizontal-form-field">
      <kendo-label [for]="leaderFlag" text="Является руководителем подразделения:"></kendo-label>
      <input type="checkbox" kendoCheckBox #leaderFlag [formControlName]="'leaderFlag'"/>
    </kendo-formfield>

    <!-- Оставил т.к. формап в будущем станет с возможностью выбирать подразделение-->
    <!-- <kendo-formfield>
      <kendo-label [for]="subdivisionId" text="Подразделение"></kendo-label>
      <kendo-dropdownlist
        #subdivisionId
        [data]="null"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControlName]="'subdivisionId'"
      >
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="form.controls.subdivisionId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield> -->

    <div class="form-inline-div">
      <div class="form-work-mode-field-div">
        <kendo-formfield>
          <kendo-label [for]="workModeId" text="Режим работы"></kendo-label>
          <kendo-dropdownlist
            #workModeId
            [data]="workModes$ | async"
            textField="name"
            valueField="id"
            [valuePrimitive]="true"
            [formControlName]="'workModeId'"
          >
          </kendo-dropdownlist>
          <kendo-formerror *ngIf="form.controls.workModeId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
        </kendo-formfield>
      </div>
      <kendo-formfield>
        <kendo-label [for]="reportPeriodId" text="Период контроля"></kendo-label>
        <kendo-dropdownlist
          #reportPeriodId
          [data]="reportPeriods$ | async"
          textField="name"
          valueField="id"
          [valuePrimitive]="true"
          [formControlName]="'reportPeriodId'"
        >
        </kendo-dropdownlist>
        <kendo-formerror *ngIf="form.controls.reportPeriodId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label [for]="dinnerHourDuration" text="Продолжительность обеда (часы)"></kendo-label>
        <kendo-numerictextbox #dinnerHourDuration [formControlName]="'dinnerHourDuration'" [min]="0" [max]="5.0"  [format]="'n1'" [decimals]="1" [step]="0.1" [autoCorrect]="true">
        </kendo-numerictextbox>
        <kendo-formerror *ngIf="form.controls.dinnerHourDuration.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.dinnerHourDuration.errors?.min">Значение не может быть меньше {{form.controls.dinnerHourDuration.errors?.min.min}}. Текущее значение: {{form.controls.dinnerHourDuration.errors?.min.actual}}</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.dinnerHourDuration.errors?.max">Значение не может быть больше {{form.controls.dinnerHourDuration.errors?.max.max}}. Текущее значение: {{form.controls.dinnerHourDuration.errors?.max.actual}}</kendo-formerror>
      </kendo-formfield>
    </div>

    <div class="form-inline-div">
      <kendo-formfield class="horizontal-form-field">
        <kendo-label [for]="subtractLunchTimeFromWorkingHoursFlag"
                     text="Вычитать продолжительность обеденного перерыва из рабочего времени">
        </kendo-label>
        <input type="checkbox"
               kendoCheckBox
               #subtractLunchTimeFromWorkingHoursFlag
               [formControlName]="'subtractLunchTimeFromWorkingHoursFlag'"
        />
      </kendo-formfield>

      <kendo-formfield class="horizontal-form-field">
        <kendo-label [for]="includeScheduleForPaidServiceFlag" text="Включать в график приема по платным услугам"></kendo-label>
        <input type="checkbox" kendoCheckBox #includeScheduleForPaidServiceFlag [formControlName]="'includeScheduleForPaidServiceFlag'"/>
      </kendo-formfield>
    </div>

    <kendo-formfield>
      <kendo-label [for]="rates" text="Ставки"></kendo-label>
      <app-add-position-rates-grid-control #rates [height]="220" [formControlName]="'rates'" [financingSourcesDropDownItems]="financingSourcesDropDownData$ | async" (isEditingNow$)="onChangeIsEditingPositionRatesGrid($event)"></app-add-position-rates-grid-control>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="comment" text="Комментарий"></kendo-label>
      <input kendoTextBox #comment [formControlName]="'comment'" />
      <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>
  </form>

  <div class="k-form-buttons kendo-custom-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid || IsEditingPositionRatesGrid" (click)="onClickSave()">Сохранить</button>
  </div>
</div>
