import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, ReplaySubject } from 'rxjs';
import { ReportDatesComponentSharedService } from '../../../../../../../src/app/components/report-dates/services/report-dates-component-shared.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { trace } from '../../../../../../../src/app/modules/trace/operators/trace';
import { exErrorHandler } from '../../../../../../../src/app/operators/ex-error-handler';
import { LimitStaffUnitsTreeListDataSourceService } from '../limit-staff-units-tree-list/services/limit-staff-units-tree-list-data-source.service';
import { LimitStaffUnitTreeListItem } from '../limit-staff-units-tree-list/limit-staff-units-tree-list.component';
import { KendoTreeListDataSourceSelection } from '../../../../../../../src/app/classes/array-data-sources/selections/kendo-treelist-array-data-source-selection';
import { ReportDateValues } from '../../../../../../../src/app/components/report-dates/classes/report-date-values';

@Component({
  selector: 'app-limit-staff-unit-directory',
  templateUrl: './limit-staff-unit-directory.component.html',
  styleUrls: ['./limit-staff-unit-directory.component.css'],
  providers: [LimitStaffUnitsTreeListDataSourceService],
})
export class LimitStaffUnitDirectoryComponent implements OnInit, OnDestroy {

  public readonly selection: KendoTreeListDataSourceSelection<LimitStaffUnitTreeListItem, number>;
  private streams$ = { unsubscribe: new ReplaySubject<any>(1) };

  constructor(private readonly reportDatesComponentSharedService: ReportDatesComponentSharedService,
              public readonly dataSourceService: LimitStaffUnitsTreeListDataSourceService,
              private readonly displayErrorService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase) {
    this.selection = new KendoTreeListDataSourceSelection(this.dataSourceService.dataSource);
  }

  ngOnInit(): void {
    // Подписка на обновление значений
    this.dataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          map(value => ReportDateValues.toForDate(value)),
          trace(this.tracerService),
          exErrorHandler(this.displayErrorService),
        ),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

}
