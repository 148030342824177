import { TreeListComponent } from "@progress/kendo-angular-treelist";
import { xnameofPath } from "../functions/nameof";

/** Функция сравнения элементов по умолчанию. По умолчанию это сравнение по idField */
const defaultCompareDataItemsFn = (treeList: TreeListComponent) => (( d1, d2) => treeList.idGetter(d1) === treeList.idGetter(d2));

export class KendoTreeListHelper {

  /**
   * Получить все данные в трилисте
   */
  public static getAllDataItems<TItem>(treeList: TreeListComponent): GetAllDataItemsItem<TItem>[]{
    const retarr:GetAllDataItemsItem<TItem>[] = [];
    addChildrensToArray(treeList, null, retarr);

    function addChildrensToArray(treelist: TreeListComponent, parent: GetAllDataItemsItem<TItem>, retArr:GetAllDataItemsItem<TItem>[]) {

      const childrens = ((treelist.fetchChildren(parent?.item) as any).data as any[]).map(m=> new GetAllDataItemsItem(m, parent, null));
      if(parent){
        parent.childrens = childrens;
      }

      childrens.forEach(c=> {
        retArr.push(c);
        addChildrensToArray(treelist, c, retArr);
      })
    }

    return retarr;
  }

  /**
   * Раскрыть всё дерево до определённого dataItem
   * @param treeList трилист
   * @param dataItem элемент трилиста
   * @param compareFn функция сравнения элементов. По умолчанию
   * @param isExpandDataItem раскрывать сам dataItem? По умолчанию нет
   */
  public static expandToDataItem(treeList: TreeListComponent, dataItem: any, compareFn:(dataItem1, dataItem2) => boolean = null, isExpandDataItem: boolean = false){
    const compareFunc = compareFn ?? defaultCompareDataItemsFn(treeList);
    const allDataItems = this.getAllDataItems(treeList);

    const foundedExpandDataItem = allDataItems.find(f=> compareFunc(f.item, dataItem));
    if(!foundedExpandDataItem) {
      return;
    }
    const itemsForExpand = GetAllDataItemsItem.getHierarchyUp(isExpandDataItem ? foundedExpandDataItem : foundedExpandDataItem?.parent);
    itemsForExpand.forEach(f=> treeList.expand(f.item));
  }

  /**
   * Раскрыть всех родителей до этого элемента и проскроллить до него
   * @param treeList трилист
   * @param dataItem элемент трилиста
   * @param compareFn функция сравнения элементов. По умолчанию
   */
  public static expandAndScrollToDataItem(treeList: TreeListComponent, dataItem: any, compareFn:(dataItem1, dataItem2) => boolean = null){

    const compareFunc = compareFn ?? defaultCompareDataItemsFn(treeList);

    const isExpandedFn = (treeList as any).expandStateService.isExpanded;
    if(!isExpandedFn) {
      throw new Error('Функция раскрытия не найдена!');
    }

    this.expandToDataItem(treeList, dataItem, compareFunc);

    setTimeout(() => {
      const expandedItems = this.getAllDataItems(treeList).filter(f=> isExpandedFn(f.item) || !f.parent || isExpandedFn(f.parent?.item));
      const foundedItemIndex = expandedItems.findIndex(f => compareFunc(f.item, dataItem));
      if(foundedItemIndex < 0) {
        return;
      }

      this.scrollTo(treeList, foundedItemIndex);
    });

  }

  /**
   * Проскроллить до индекса строки (не забывать что не раскрытые элементы не учитываются!)
   * @param treeList трилист
   * @param rowIndex индекс строки (в порядке как она отображается)! Не раскрытые элементы не учитываются!
   * @param columnIndex индекс колонки (в порядке как она отображается на момент раскрытия)
   */
  public static scrollTo(treeList: TreeListComponent, rowIndex: number, columnIndex?: number){
    switch (treeList.scrollable){

      case "virtual": {
        treeList.scrollTo({
          row: rowIndex,
          column: columnIndex
        });
        break;
      }

      case "scrollable": {
        if(columnIndex){
          throw new Error("Не умею работать с columnIndex!");
        }
        treeList.wrapper.nativeElement.querySelector(`tr[ng-reflect-data-row-index="${rowIndex}"]`)?.scrollIntoView();
        break;
      }

      default: throw new Error(`${xnameofPath(TreeListComponent).scrollable}="${treeList.scrollable}" не поддерживается!`);
    }
  }
}

export class GetAllDataItemsItem<TItem> {

  constructor(
    public item: any,
    public parent?: GetAllDataItemsItem<TItem>,
    public childrens?: GetAllDataItemsItem<TItem>[]
  ){  }

  /**
   * Получает всех родителей для элемента (реурсивно)
   * @param item элемент
   * @returns массив родителей
   */
  public static getHierarchyUp<TItem>(item: GetAllDataItemsItem<TItem>):GetAllDataItemsItem<TItem>[]{

    const retArray:GetAllDataItemsItem<TItem>[] = [];
    const addParentsToArrayFn = (item: GetAllDataItemsItem<TItem>, retArray:GetAllDataItemsItem<TItem>[]) => {
      retArray.push(item);
      if(item.parent){
        addParentsToArrayFn(item.parent, retArray);
      }
    }

    addParentsToArrayFn(item, retArray);

    return retArray;

  }

  /**
   * Получает плоский массив из иерархичного
   * @param items элементы
   * @returns массив плоских элементов
   */
  public static getFlatItems<TItem>(items: GetAllDataItemsItem<TItem>[]):TItem[]{

    const retArray:any[] = [];
    const addChildrensToArrayFn = (item: GetAllDataItemsItem<TItem>, retArray:any[]) => {
      retArray.push(item.item);
      if(item.childrens?.length > 0){
        item.childrens.forEach(children => {
          addChildrensToArrayFn(children, retArray)
        })
      }
    }

    items.forEach(item => {
      addChildrensToArrayFn(item, retArray);
    })
    return retArray;

  }
}
