import { IEntityVersioning } from "../interfaces/IEntityVersioning";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ITarifStaffUnit extends IEntityVersioning {
  /** FK на Ставки по позициям */
  positionRateId: number
  /** FK на ПКГ и КУ */
  pkgKuId: number
  /** Повышающий коэффициент */
  increasingCoefficient: number
  /** Выплаты за работу с вредными/опасным условиями труда (%) */
  paymentsDangerousWork: number

  /** Класс вредности */
  workingConditionHarmId: number
  /** Приказ устанавливающий класс вредности */
  workingConditionHarmDecreeId: number
  /** Выплаты за работу со сведениями, составляющими гос.тайну (%) */
  paymentsSecretWork: number
  /** Выплата за работу в ночное время (%) */
  paymentsNightWork: number

  /** Фактор вредности */
  harmFactorId: number
}

@classBackend('TarifStaffUnit', 'tarification_directories')
@className('TarifStaffUnit')
/** Модель БД таблицы tarification_directories.TarifStaffUnits (Штатные единицы) */
export class TarifStaffUnit implements ITarifStaffUnit {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public timestamp: [],

              public positionRateId: number,
              public pkgKuId: number,
              public increasingCoefficient: number,
              public paymentsDangerousWork: number,

              public workingConditionHarmId: number,
              public workingConditionHarmDecreeId: number,
              public paymentsSecretWork: number,
              public paymentsNightWork: number,

              public harmFactorId: number) { }
}


export class TarifStaffUnitCalculatedFields {
  constructor(public officialSalary: number) { }

  public static get(minSalary: number, increasingCoefficient: number) {
    const officialSalary = (minSalary || 0) * (increasingCoefficient || 1);

    return new TarifStaffUnitCalculatedFields(officialSalary);
  }
}
