import { Injectable, TemplateRef } from "@angular/core";
import { DialogRef, DialogService, DialogSettings } from "@progress/kendo-angular-dialog";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { ImportResult } from "../i-import-from-excel.component";
import { ImportFromExcelComponent } from "../import-from-excel.component";

@Injectable({
  providedIn: "root"
})
/** Сервис диалога импорта из файла */
export class ImportFromExcelDialogService{
  constructor(private dialogService: DialogService, private notificationService: KendoNotificationService) {
  }

  /** Показать диалог импорта
   * @param params Обязательные параметры импорта
   * @param options Опции настроек диалога
  */
  public show(params: ImportFromExcelDialog_ShowDialogParams, options?: DialogSettings): IShowResult{
    if(!options){
      options = {}
      options.title = 'Импорт данных из Excel'
      options.height = "90%"
      options.width = "90%"
      options.actions = [];
    }

    options.content = ImportFromExcelComponent;

    const dialogRef = this.dialogService.open(options);
    const component: ImportFromExcelComponent = dialogRef.content.instance;

    component.downloadTemplateUrl = params.downloadTemplateUrl;
    component.uploadSaveUrl = params?.uploadFileUrl;
    component.settingsTemplate = params?.settingsTemplate;

    component.onImportResult$.pipe(take(1)).subscribe(s=> {
      if(s.isSuccess) {
        this.notificationService.showSuccess({content: "Данные успешно импортированы"});
      } else {
        this.notificationService.showError({content: "Ошибка импорта данных"});
      }
      setTimeout(() => dialogRef.close(), 1);
    });

    return {
      dialogRef: dialogRef,
      importResult$: component.onImportResult$
    }
  }
}

/** Результат диалога */
export interface IShowResult{

  /** Ссылка на диалог */
  dialogRef: DialogRef,

  /** Результаты импорта */
  importResult$: Subject<ImportResult>
}

/** Параметры диалогового окна импорта */
export class ImportFromExcelDialog_ShowDialogParams {
  constructor(
    /** URL адрес GET запроса загрузки шаблона для импорта */
    public downloadTemplateUrl: string,

    /** URL адрес куда будет отправляться файл */
    public uploadFileUrl: string,

    /** Шаблон настроек импорта */
    public settingsTemplate?: TemplateRef<any>
  ){}
}


