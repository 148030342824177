import { IData, IFieldControl } from "./interfaces/common";
import { DropDownFilterSettings, PopupSettings, VirtualizationSettings } from "@progress/kendo-angular-dropdowns";

export class DropDownListFieldControl implements IFieldControl<number> {
  public readonly name = 'DropDownListFieldControl';
  public getValueFunc = (stringValue: string) => !stringValue ? null : Number(stringValue);


  constructor(public settings?: (() => DropDownListFieldControl_Settings) | DropDownListFieldControl_Settings) { }
}

export interface DropDownListFieldControl_Settings extends IData<any> {
  filterSettings?: DropDownFilterSettings;
  virtual?: boolean | VirtualizationSettings;
  popupSettings?: PopupSettings;
  textField?: string;
  valueField?: string;
}
