import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";

export class EmployeeDropDownItem implements IDropDownItem{
  constructor(
    public id: number,
    public text:string,
    public startDate: Date,
    public endDate: Date
    ) { }
}
