<app-edit-passport-data [data]="data" (onDataChanged$)="onPassportDataChange($event)"></app-edit-passport-data>

<kendo-dialog-actions>
  <div class="dialog-buttons">
    <button kendoButton (click)="onCancelAction()">Отмена</button>
    <button
      kendoButton
      (click)="onConfirmAction()"
      [primary]="true"
      [disabled]="!isCanSaveOrAdd"
    >
      Сохранить
    </button>
  </div>
</kendo-dialog-actions>
