
/** Тип элемента */
type RetryItemType = {
  /** миллисекунд */
  delay: number,
  /** Сколько раз повторить с задержкой {@link delay} */
  count: number,
}

/** Хранилище для настроек времени повтора */
export class RetryStorage {
  private items: Array<RetryItemType> = [];

  constructor(...items: Array<number | RetryItemType>) {
    items.forEach((item) => {
      if(typeof item === 'number'){
        this.items.push({
          delay: item,
          count: 1,
        })

        return;
      } else if (isRetryType(item)){
        if(item.delay < 0) { throw new Error('delay НЕ может быть отрицательным') }
        if(item.count < 1) {
          return;
        }

        this.items.push({
          delay: item.delay,
          count: item.count,
        })

        return;
      } else {
        throw new Error('Переданный тип НЕ поддерживается')
      }
    })
  }

  /** Имеется ли первый элемент */
  public hasFirst(): boolean{
    return this.items.length > 0;
  }

  /**
   * Получить первую задержку повтора
   * @param andRemove удалит из очереди
   * @return undefined если нет элементов
   */
  public getFirst(andRemove = true): number | undefined {
    const first = this.items[0];
    if(!first){
      return undefined;
    }

    if(andRemove){
      if(first.count <= 1){
        this.items = this.items.slice(1);
      } else {
        first.count--;
      }
    }

    return first.delay;
  }

  /**
   * Получить все элементы как массив
   * @param andRemove удалит из очереди все элементы
   */
  public toArray(andRemove = true): Array<number>{
    const arr: Array<number> = [];

    this.items.forEach((item) => {
      for (let i = 0; i < item.count; i++) {
        arr.push(item.delay);
      }
    })

    return arr;
  }
}

/** Защитник типа {@link RetryItemType} */
function isRetryType(item: any): item is RetryItemType {
  if(!item || typeof item !== 'object') {
    return false;
  }

  const temp = item as RetryItemType;
  return typeof temp.count === 'number' && typeof temp.delay === 'number';
}
