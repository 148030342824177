<kendo-splitter orientation="vertical" style="height: 100%">
  <kendo-splitter-pane [resizable]="false" style="height: 38px; margin-bottom: 3px; display: flex; justify-content: flex-end;" class="k-toolbar">
    <!-- <div class="report-date-label">
      Дата отчёта:
    </div> -->
    <div class="report-date-control">
      <app-report-dates [size]="'small'"></app-report-dates>
    </div>
  </kendo-splitter-pane>
  <kendo-splitter-pane style="margin-top: 3px">
    <kendo-splitter>
      <kendo-splitter-pane size="400px">
        <div class="stafflist-grid">
          <div class="k-toolbar subdivisions-text-div">Подразделения</div>
          <div class="stafflist-grid-container">
            <app-subdivisions-treelist [dataSourceService]="subdivisionsDataSourceService"
                                       [selection]="subdivisionsSelection"
                                       [multipleSelected]="false"
                                       [cellStyle]="{'padding': '4px 12px'}"
            >
            </app-subdivisions-treelist>
          </div>
        </div>
      </kendo-splitter-pane>

      <kendo-splitter-pane>
        <kendo-splitter orientation="vertical" style="height: 100%;">
          <kendo-splitter-pane style="height: 100%;">
            <div class="stafflist-grid">
              <div class="k-toolbar staff-positions-text-div">Штатные единицы
                <ng-container *ngIf="this.subdivisionsSelection.selectedItems2.data$ | async as subdivision"><span *ngIf="subdivision[0]">({{subdivision[0].longName}})</span></ng-container>
              </div>
              <div class="stafflist-grid-container">
                <app-positions-grid [dataSource]="positionsDataSourceService"
                                    [selection]="positionsSelection"
                                    class="positions-grid"
                ></app-positions-grid>
              </div>
            </div>
          </kendo-splitter-pane>
          <kendo-splitter-pane [collapsible]="true" style="height: 100%;">
            <div class="stafflist-grid">
              <div class="k-toolbar staff-units-text-div">Исполнение должностей</div>
              <div class="stafflist-grid-container">
                <app-staff-units-grid2 [dataSource]="staffUnitsDataSourceService"
                                       [selection]="staffUnitsSelection"
                                       class="staff-units-grid"
                ></app-staff-units-grid2>
              </div>
            </div>
          </kendo-splitter-pane>
        </kendo-splitter>
      </kendo-splitter-pane>
    </kendo-splitter>
  </kendo-splitter-pane>
</kendo-splitter>

