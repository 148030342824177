import {Injectable, OnDestroy} from "@angular/core";
import {Guid} from "guid-typescript";
import {BaseExtensionObj, ExtensionObj} from "../helpers/extensionObj";
import {Observable, ReplaySubject, share, Subject, tap} from "rxjs";
import {TracerServiceBase} from "../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../modules/trace/decorators/class.decorator";
import {traceFunc} from "../modules/trace/decorators/func.decorator";
import {filter, finalize, map, take, takeUntil} from "rxjs/operators";


/** Перечисления типа алерта */
export enum AlertType {
  /**
   * Без типа.
   * Не будет отображаться иконка
   */
  none = 1,
  /** Уведомление(Иконка - колокол) */
  notification = 2,
  /** Информация(Иконка - буква i) */
  information = 3,
  /** Вопрос(Иконка - вопросительный знак) */
  question = 4,
  /** Предупреждение(Иконка - восклицательный знак) */
  warning = 5,
  /** Ошибка(Иконка - крест) */
  error = 6
}

/** Класс кнопок алерта */
export class AlertButton {
  /** Текст кнопки */
  public text: string;
  /** Является ли кнопка по умолчанию(подсвечена) */
  public isPrimary: boolean;
  /** Является ли кнопка Отмена */
  public isCancel: boolean;
  /** Функция которую нужно вызвать по нажатию */
  public callBack: () => void;

  /**
   * Конструктор
   * @constructor
   */
  public static Get(text: string, isPrimary: boolean, isCancel: boolean, callBack: () => void): AlertButton{
    const instance = new AlertButton();
    instance.text = text;
    instance.isPrimary = isPrimary;
    instance.isCancel = isCancel;
    instance.callBack = callBack;

    return instance;
  }

  /** Равны ли два объекта */
  public static IsEquals(instance1: AlertButton, instance2: AlertButton): boolean{
    if(instance1 == instance2){
      return true;
    }

    return instance1.text == instance2.text &&
      instance1.isPrimary == instance2.isPrimary &&
      instance1.isCancel == instance2.isCancel &&
      instance1.callBack == instance2.callBack;
  }

  /** Равны ли два массива */
  public static IsEqualsArray(array1: Array<AlertButton>, array2: Array<AlertButton>): boolean{
    if(array1 == array2){
      return true;
    }
    if(array1 == null || array2 == null){
      return false;
    }
    if(array1.length != array2.length){
      return false;
    }

    for (let i = 0; i < array1.length; i++){
      if(!AlertButton.IsEquals(array1[i], array2[i])){
        return false;
      }
    }

    return true;
  }

  /** Валидация объекта */
  public static Validation(button: AlertButton){
    if(!button){
      throw new Error('button is null');
    }
    if(!button.text){
      throw new Error('Текст кнопки null или undefined');
    }
  }
}

/** Сервис алертов */
@Injectable({
  providedIn: "root"
})
@traceClass('AlertService')
export class AlertService implements OnDestroy {
  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(),
    /** Стрим удаления сообщения из очереди */
    messageDeleted: new Subject<string>(),
  }

  /** Событие удаления сообщения из очереди */
  public get messageDeleted$(): Observable<string> {
    return this.streams$.messageDeleted;
  }

  /** Фабрика кнопок по умолчанию */
  private readonly _defaultButtons = {
    /** Кнопка Отмена */
    cancel: (text = 'Отмена', isPrimary = false, callBack = null) => {
      return new ExtensionObj(AlertButton.Get(text, isPrimary, true, callBack));
    },

    /** Кнопка Ок */
    ok: (text = 'Ok', isPrimary = true, callBack = null) => {
      return new ExtensionObj(AlertButton.Get(text, isPrimary, false, callBack));
    }
  }
  /** Фабрика кнопок по умолчанию */
  public get defaultButtons(): Readonly<typeof this._defaultButtons>{
    return this._defaultButtons;
  }

  /** Фабрика сообщений по умолчанию */
  private readonly _defaultAlertOption = {

    /**
     * Без типа.
     * Не будет отображаться иконка
     * Одна кнопка 'Закрыть'(primary)
     */
    none: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.none, ' ', null, null, [this.defaultButtons.cancel('Закрыть', true, null).source]
        )
      );
    },

    /**
     * Уведомление
     * Одна кнопка 'Закрыть'(primary)
     */
    notification: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.notification, 'Уведомление', null, null, [this.defaultButtons.cancel('Закрыть', true, null).source]
        )
      );
    },
    /**
     * Информация
     * Одна кнопка 'Прочитано'(primary) которая закрывает окно
     */
    information: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.information, 'Информация', null, null, [this.defaultButtons.cancel('Прочитано', true, null).source]
        )
      );
    },

    /**
     * Вопрос
     * Две кнопки - 'Отмена' 'Принять'(primary)
     */
    question: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.question, 'Вопрос', null, null, [
            this.defaultButtons.cancel('Отмена', false, null).source,
            this.defaultButtons.ok('Принять', true, null).source
          ]
        )
      );
    },

    /**
     * Подтверждение
     * Две кнопки - 'Отмена' 'Подтвердить'(primary)
     */
    confirmation: () => {
      return new AlertOptionExtension(AlertOption.Get(
        AlertType.question, 'Подтверждение', null, 'Необходимо подтвердить действие', [
          this.defaultButtons.cancel('Отмена', false, null).source,
          this.defaultButtons.ok('Подтвердить', true, null).source
        ]
      ));
    },

    /**
     * Подтверждение выхода из программы
     * @param callBack обратный вызов если нажали Ok
     */
    logout: (callBack: () => void) => {
      return new AlertOptionExtension(AlertOption.Get(
        AlertType.question, 'Выход из программы', null, 'Вы точно хотите выйти из программы?', [
          this.defaultButtons.cancel('Отмена', false, null).source,
          this.defaultButtons.ok('Выход', true, callBack).source
        ]
      ))
    },

    /**
     * Предупреждение
     * Одна кнопка - 'Понятно' которая закрывает окно
     */
    warning: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.warning, 'Предупреждение',
          'При выполнении произошла ошибка!',
          `\tОшибка не должна привести к серьезным последствиям. Попробуйте еще раз.
\t<b>Рекомендуем:</b> Все же не стоит рисковать. Полной гарантии, что все хорошо, увы нет. Если есть несохраненные данные, сохраните и перезагрузите страницу.

\t\t<b><small>Данные об ошибке собраны и переданы в службу поддержки</small></b>`,
          [this.defaultButtons.cancel('Понятно', true, null).source]
        )
      );
    },

    /**
     * Ошибка
     * Одна кнопка - 'Понятно' которая закрывает окно
     */
    error: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.error, 'Ошибка',
          'При выполнении произошла непредвиденная критическая ошибка!',
          `\tК сожалению, мы не можем гарантировать стабильность Вашей дальнейшей работы.
\t<b>Рекомендуем:</b> Если есть несохраненные данные, попробуйте их сохранить, затем перезагрузите страницу.

\t\t<b><small>Данные об ошибке собраны и переданы в службу поддержки</small></b>`,
          [this.defaultButtons.cancel('Понятно', true, null).source]
        )
      );
    },

    /**
     * Ошибка при загрузке
     */
    downloadError: () => {
      return new AlertOptionExtension(
        AlertOption.Get(
          AlertType.error, 'Ошибка',
          'При загрузке данных произошла ошибка!',
          `\tК сожалению, мы не можем гарантировать стабильность Вашей дальнейшей работы.
\t<b>Рекомендуем:</b> Перезагрузите страницу.

\t\t<b><small>Данные об ошибке собраны и переданы в службу поддержки</small></b>`,
          [this.defaultButtons.cancel('Понятно', true, null).source]
        )
      );
    }
  }

  /** Фабрика сообщений по умолчанию */
  public get defaultAlertOption(): Readonly<typeof this._defaultAlertOption>{
    return this._defaultAlertOption;
  }

  /** Сообщения */
  private messages: Array<[string, AlertOption]> = new Array<[string, AlertOption]>();

  /** Стрим текущих настроек алертк */
  public readonly currentOption$ = new Subject<AlertOption>()

  private _currentOption: AlertOption = null;
  /** Текущие настройки алерта */
  public get currentOption(): AlertOption{
    return this._currentOption;
  };
  private set currentOption(value){
    if(this._currentOption === value){ //Не допускаем одинаковые значения
      return;
    }

    this._currentOption = value;
    this.currentOption$.next(value);
  }

  /** Конструктор */
  constructor(private readonly traceService: TracerServiceBase) {
    AlertOptionExtension.alertService = this;
  }

  /**
   * Добавить сообщение
   * @return идентификатор сообщения
   */
  @traceFunc()
  public add(option: AlertOption): string{
    AlertOption.Validation(option);

    const exist = this.messages.find(item => AlertOption.isEquals(item[1], option));

    if(!!exist){
      return exist[0];
    }

    const guid = Guid.create().toString();

    this.messages.push([guid, option]);
    this.currentOption = option;
    return guid;
  }

  /**
   * Удалить последнее сообщение из массива сообщений
   * Если сообщений больше нет - то окно закроется
   */
  @traceFunc()
  public removeLast(){
    if(this.messages.length == 0){
      return;
    }
    const guid = this.messages[this.messages.length - 1][0];
    if(this.messages.length == 1){
      setTimeout(() => {
        this.remove(guid);
      }, 10);
      return;
    }

    this.remove(guid);
  }

  /**
   * Удалить последнее сообщение из массива сообщений
   * Если последний то окно закроется
   * @return Произошло ли удаление сообщения
   */
  @traceFunc()
  public remove(guid: string): boolean {
    const currentLength = this.messages.length;
    this.messages = this.messages.filter(item => item[0] !== guid);

    if(currentLength === this.messages.length){
      return false;
    }

    this.currentOption = !this.messages.length ? null : this.messages[this.messages.length - 1][1];
    this.streams$.messageDeleted.next(guid);
    return true;
  }

  /** Очищает все сообщения и скрывает Алерт */
  @traceFunc()
  public clear(){
    for (let i = this.messages.length; i > 0; i--){
      this.removeLast();
    }
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.currentOption$.complete();
  }
}

/** Класс опций алерта */
export class AlertOption{
  /**
   * Тип алерта
   * От него зависит иконка в алерте
   */
  public type: AlertType;
  /** Заголовок окна */
  public title: string;
  /**
   * Заколовок сообщения
   * Поддерживает \n\r \t
   */
  public titleMessage: string;
  /**
   * Сообщение
   * Поддерживает \n\r \t
   */
  public message: string;
  /** Текст кнопки */
  public buttons: Array<AlertButton>;

  /**
   * Конструктор
   * @constructor
   */
  public static Get(
    type: AlertType,
    title: string,
    titleMessage: string,
    message: string,
    buttons: Array<AlertButton>) : AlertOption
  {
    const instance = new AlertOption();
    instance.type = type;
    instance.title = title;
    instance.titleMessage = titleMessage;
    instance.message = message;
    instance.buttons = buttons;

    return instance;
  }

  /** Равны ли два объекта */
  public static isEquals(instance1: AlertOption, instance2: AlertOption): boolean{
    if(instance1 == instance2){
      return true;
    }

    return instance1.type == instance2.type &&
      instance1.title == instance2.title &&
      instance1.titleMessage == instance2.titleMessage &&
      instance1.message == instance2.message &&
      AlertButton.IsEqualsArray(instance1.buttons, instance2.buttons);
  }

  /** Валидация объекта */
  public static Validation(instance: AlertOption): void{
    if(instance == null){
      throw new Error('Переданные на валидацию опции алерта == null');
    }
    if(!instance.type){
      throw new Error('Тип алерта в опциях не установлен');
    }
    if(instance.buttons != null){
      instance.buttons.forEach(button => {
        AlertButton.Validation(button);
      });
      const cancelButtonTotal = instance.buttons.filter(button => {
        return button.isCancel;
      }).length;
      if(cancelButtonTotal > 1){
        throw new Error('В алерте ктопок с флагом isCancel == true больше 1');
      }
    }
  }
}

/** Тип ссылки на алерт */
type AlertRefType = {
  /** Функция закрывает алерт */
  readonly close: () => void,
  /** Событие срабатывает один раз когда алерт закрылся */
  readonly closed$: Observable<void>
};

/** Тип входного объекта кнопок для отображения алерта */
type ShowAlertButtonsType = Record<string, {text: string, isPrimary?: boolean, isCancel?: boolean}>;

/** Расширение */
export class AlertOptionExtension extends BaseExtensionObj<AlertOption, AlertOptionExtension>{
  public static alertService: AlertService = null;

  constructor(source: AlertOption) {
    super(source, () => {
      return this;
    });
  }

  /**
   * Отобразить алерт с текущими опциями
   * @param openUntil диалоговое окно будет открыто до первой трансляции данного наблюдаемого
   */
  public showAlert(openUntil: Observable<any> = undefined): AlertRefType {
    if(AlertOptionExtension.alertService == null){
      throw new Error('AlertService еще не проинициализирован. Необходимо инжектировать его в конструктор до использования метода AlertOptionExtension.show');
    }

    const guid = AlertOptionExtension.alertService.add(this.source);
    let callClose = false;

    /** Функция закрывающая алерт */
    const closeFn = () => {
      if(callClose){
        return;
      }
      callClose = true;
      AlertOptionExtension.alertService.remove(guid);
    }

    /** Стрим сообщает что алерт закрылся */
    const closed$ = AlertOptionExtension.alertService.messageDeleted$
      .pipe(
        filter(value => value === guid),
        map(() => {}),
        take(1),
        share(),
      );

    if(!!openUntil){
      openUntil
        .pipe(
          take(1),
          takeUntil(closed$) //До закрытия
        ).subscribe(() => closeFn());
    }

    return {
      close: closeFn,
      closed$: closed$
    }
  }

  /**
   * Отобразить алерт с текущими опциями.
   * @param buttons объект-настройка кнопок.
   * @param notEmitCancel true - возвращаемый стрим завершается БЕЗ транлсяции события если окно закрывается или нажали на любую кнопку {@link AlertButton.isCancel}.
   * @return стрим транслирующий нажатую кнопку
   */
  public showAlert$<TButtons extends ShowAlertButtonsType>(buttons: TButtons,
                                                           notEmitCancel: boolean = true): Observable<keyof TButtons>{
    /** идентификатор сообщения */
    let guid: string;
    return new Observable<keyof TButtons>(subscriber => {
      const options = this.modResult(x => {
        x.buttons = Object.keys(buttons)
          .map(key => {
            const value = buttons[key];
            return AlertButton.Get(value.text, !!value.isPrimary, !!value.isCancel, () => {
              if(!!value.isCancel && notEmitCancel){
                return;
              }

              subscriber.next(key);
            })
          });
      });

      guid = AlertOptionExtension.alertService.add(options); //Добавляем сообщение
    }).pipe(
      share(),
      takeUntil(AlertOptionExtension.alertService.messageDeleted$
        .pipe(
          filter(value => value === guid),
          tap(() => { guid = undefined; })
        )),
      finalize(() => {
        if(guid){ //Если еще не закрылось диалоговое окно. Такое может быть, если все подписчики отписались
          AlertOptionExtension.alertService.remove(guid);
          guid = undefined;
        }
      }),
    )
  }
}
