import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkspaceErrorComponent } from 'projects/timesheet/src/app/components/workspace-error/workspace-error.component';
import { AuthGuard } from 'src/app/modules/auth/guards/authGuard';
import { DirectoriesComponent } from './components/directories/directories.component';
import { EmployeesDirectoryComponent } from './components/directories/employees/employees-directory.component';
import { DirectoriesListComponent } from './components/directories/list/directories-list.component';
import { OccupationTypesDirectoryComponent } from './components/directories/occupation-types/occupation-types-directory.component';
import { OccupationsDirectoryComponent } from './components/directories/occupations/occupations-directory.component';
import { ReportPeriodsDirectoryComponent } from './components/directories/report-periods/report-periods.component';
import { SubdivisionsDirectoryComponent } from './components/directories/subdivisions/subdivisions-directory.component';
import { WorkModesDirectoryComponent } from './components/directories/work-modes/work-modes-directory.component';
import { StafflistComponent } from './components/stafflist/stafflist.component';
import { WorkspaceComponent } from '../../../../src/app/components/workspace/workspace.component';
import { PrintFormsComponent } from './components/print-forms/print-forms/print-forms.component';
import { AuthModule } from '../../../../src/app/modules/auth/auth.module';
import {
  LimitStaffUnitDirectoryComponent
} from './components/limit-staff-unit/limit-staff-unit-directory/limit-staff-unit-directory.component';
import { ProductionCalendarDirectoryComponent } from './components/production-calendar/production-calendar-directory/production-calendar-directory.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'workspace', redirectTo: 'workspace/stafflist' },
  {
    path: 'workspace',
    component: WorkspaceComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'stafflist', component: StafflistComponent },
      { path: 'directories', redirectTo: 'directories/list' },
      { path: 'directories/list', component: DirectoriesListComponent },
      { path: 'directories/production-calendar', component: ProductionCalendarDirectoryComponent },
      {
        path: 'directories', component: DirectoriesComponent,
        children: [
          { path: 'employees', component: EmployeesDirectoryComponent },
          { path: 'subdivisions', component: SubdivisionsDirectoryComponent },
          { path: 'occupations', component: OccupationsDirectoryComponent },
          { path: 'occupation-types', component: OccupationTypesDirectoryComponent },
          { path: 'report-periods', component: ReportPeriodsDirectoryComponent },
          { path: 'work-modes', component: WorkModesDirectoryComponent },
          { path: 'limit-staff-units', component: LimitStaffUnitDirectoryComponent }
        ]
      },
      { path: 'print', component: PrintFormsComponent },
      { path: 'error', component: WorkspaceErrorComponent }
    ]
  },

  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [
    AuthModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
