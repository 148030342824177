import { Injectable } from '@angular/core';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { IForDate } from '../../../../../../../../src/app/classes/for-date';
import { map, Observable } from 'rxjs';
import { VersioningItem } from '../../../../../../../../src/app/classes/directories/edit/versioningItem';
import { LimitStaffUnit } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/LimitStaffUnit';
import { AddLimitStaffUnitDTO, Api1LimitStaffUnitsControlControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitsControl-controller.service';
import { LimitStaffUnitRate } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/LimitStaffUnitRate';
import { AddLimitStaffUnitRateDTO, Api1LimitStaffUnitRateControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitRate-controller.service';
import { Api1LimitStaffUnitControllerService, SaveLimitStaffUnitDTO } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnit-controller.service';
import { traceFunc } from '../../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { LoadingIndicatorService } from '../../../../../../../../src/app/services/loading-indicator.service';
import { ArrayDataSourceIEntityId, DataSource } from '../../../../../../../../src/app/classes/array-data-sources/data-source';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { IFinancingSource } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/FinancingSource';
import { Api1FinancingSourceControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-financing-source-controller.service';
import { ISubdivision, Subdivision } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision';
import { Api1SubdivisionControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service';
import { IOccupationType, OccupationType } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/OccupationType';
import { Api1OccupationTypesControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-occupation-types-controller.service';
import { LimitStaffUnitTreeListItem, LimitStaffUnitTreeListItemType } from '../limit-staff-units-tree-list.component';
import { DataStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-state-builder';
import { DataHasOwnerStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-has-owner-state-builder';
import { ArrayHelper } from '../../../../../../../../src/app/helpers/arrayHelper';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

/** Базовый класс сервиса источника данных */
@Injectable()
export abstract class LimitStaffUnitsTreeListDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, LimitStaffUnitTreeListItem> {

  abstract add$(entity: AddLimitStaffUnitDTO): Observable<LimitStaffUnit>;

  abstract addRate$(entity: AddLimitStaffUnitRateDTO): Observable<LimitStaffUnitRate>;

  abstract save$(entity: SaveLimitStaffUnitDTO): Observable<[]>;

  abstract delete$(id: number, timestamp: []): Observable<boolean>;

  abstract getOccupationTypes$(occupationTypeIds: number[]): Observable<IOccupationType[]>;

  abstract getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]>;

  abstract getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;

  //#region Versioning

  abstract addVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem);

  abstract deleteVersion$(versionId: number);

  abstract getVersions$(ownerId: number, actionId: SupportedActionEnum);

  abstract editVersion$(actionId: SupportedActionEnum, entity: VersioningItem);

  //#endregion
}

@Injectable({
  providedIn: 'root',
})
@traceClass('LimitStaffUnitsTreeListDataSourceService')
export class LimitStaffUnitsTreeListDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, LimitStaffUnitTreeListItem> implements LimitStaffUnitsTreeListDataSourceServiceBase {

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1LimitStaffUnitControllerService: Api1LimitStaffUnitControllerService,
              private readonly api1LimitStaffUnitRateControllerService: Api1LimitStaffUnitRateControllerService,
              private readonly api1LimitStaffUnitsControlControllerService: Api1LimitStaffUnitsControlControllerService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly api1OccupationTypesControllerService: Api1OccupationTypesControllerService,
              private readonly api1FinancingSourceControllerService: Api1FinancingSourceControllerService,
              private readonly api1SubdivisionControllerService: Api1SubdivisionControllerService,
              private readonly traceService: TracerServiceBase) {
    super();
  }

  getForDate$(params: IForDate, ownerIds?: number[]): Observable<LimitStaffUnitTreeListItem[]> {
    return this.api1LimitStaffUnitsControlControllerService.getForDate$(params, ownerIds)
      .pipe(
        map(d => d.limitStaffUnits
          .map(l => {
            const occupationType = d.occupationTypes.find(v => v.id === l.occupationTypeId);
            const parentLimitStaffUnit = occupationType.parentId ? d.limitStaffUnits.find(v => v.occupationTypeId === occupationType.parentId) || this.dataSource.data.find(v => v.occupationTypeId === occupationType.parentId) : undefined;
            const type: LimitStaffUnitTreeListItemType = l.subdivisionId ? 'subdivision' : 'occupationType';
            const subdivision = type === 'subdivision' ? d.subdivisions.find(v => v.id === l.subdivisionId) : undefined;
            const limitStaffUnitRates = d.limitStaffUnitRates.filter(v => v.limitStaffUnitId === l.id);

            const occupationTypeId = type === 'occupationType' ? occupationType.id : undefined;
            const occupationTypeParentId = ((type === 'occupationType') ? (parentLimitStaffUnit ? occupationType.parentId : null) : occupationType.id);
            const text = type === 'occupationType' ? occupationType?.name : subdivision?.longName;

            const rates = d.financingSources
              .map(({ id, shortName }) => {
                const rate = limitStaffUnitRates.find(v => v.financingSourceId === id);
                return { id: rate?.id, ownerId: rate?.ownerId, timestamp: rate?.timestamp, financingSourceId: id, value: rate?.value, title: shortName, error: false };
              });

            return new LimitStaffUnitTreeListItem(l.id, l.ownerId, l.startDate, l.endDate, l.comment, l.timestamp, type, occupationTypeId, occupationTypeParentId, l.subdivisionId, text, rates);
          }),
        ),
      );
  }

  add$(entity: AddLimitStaffUnitDTO): Observable<LimitStaffUnit> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление лимитов',
      this.api1LimitStaffUnitsControlControllerService.add$(entity),
    );
  }

  addRate$(entity: AddLimitStaffUnitRateDTO): Observable<LimitStaffUnitRate> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление лимита ставки',
      this.api1LimitStaffUnitRateControllerService.add$(entity),
    );
  }

  save$(entity: SaveLimitStaffUnitDTO): Observable<[]> {
    return this.loadingIndicatorService.addToObservable(
      'Обновление лимита',
      this.api1LimitStaffUnitControllerService.save$(entity),
    );
  }

  delete$(id: number, timestamp: []): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      'Удаление лимита',
      this.api1LimitStaffUnitsControlControllerService.delete$(id, timestamp),
    );
  }

  public getOccupationTypes$(occupationTypeIds: number[]): Observable<IOccupationType[]> {
    return this.api1OccupationTypesControllerService.get$();
  }

  public getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]> {
    return this.api1FinancingSourceControllerService.getAll$(financingSourcesIds);
  }

  public getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]> {
    return this.api1SubdivisionControllerService.getForDate$(forDate.forDate, forDate.startDate, forDate.endDate, subdivisionIds);
  }

  @traceFunc()
  addVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem) {
    return this.api1LimitStaffUnitRateControllerService.addVersion$(actionId, versioningEntity);
  }

  @traceFunc()
  deleteVersion$(versionId: number) {
    return this.api1LimitStaffUnitRateControllerService.deleteVersion$(versionId);
  }

  @traceFunc()
  getVersions$(ownerId: number, actionId: SupportedActionEnum) {
    return this.api1LimitStaffUnitRateControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }

  @traceFunc()
  editVersion$(actionId: SupportedActionEnum, entity: VersioningItem) {
    return this.api1LimitStaffUnitRateControllerService.editVersion$(actionId, entity);
  }

  readonly paramsDataSource = new DataSource<IForDate>();


  readonly dataSource = new ArrayDataSourceIEntityId<LimitStaffUnitTreeListItem>();


  @traceFunc()
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
        limitStaffUnits: LimitStaffUnit,
        limitStaffUnitRates: LimitStaffUnitRate,
        occupationTypes: OccupationType,
        subdivisions: Subdivision,
      })
      .pipe(
        map(value => value.data),
        map(value => {
          let flattedRates = ArrayHelper.flatMap(this.dataSource.data2.map(m => m.rates.map(s => ({ id: s.id, limitStaffUnitId: m.id }))));

          return {
            limitStaffUnits: new DataHasOwnerStateBuilder(value.limitStaffUnits, this.dataSource.data2, x => x.id).build_().source.map(x => x.signalR.currentOrOrigin.ownerId),
            limitStaffUnitRates: new DataHasOwnerStateBuilder(value.limitStaffUnitRates, flattedRates, x => x.id).build_()
              .source.filter(x =>
                (x.state === 'added' && flattedRates.some(f => f.limitStaffUnitId === x.signalR.currentOrOrigin.limitStaffUnitId))
                || x.state === 'modified' || x.state === 'deleted',
              ).map(x => x.dataItem?.limitStaffUnitId ?? x.signalR.currentOrOrigin.limitStaffUnitId),
            occupationTypes: new DataStateBuilder(value.occupationTypes, this.dataSource.data2, (x, y) => x.id === y.occupationTypeId).build_().source
              .filter(x => x.state !== 'added') // Добавленные категории не интересны
              .filter(x => x.dataItem) // Не интересуют категории, котрые не относятся к нашим данным
              .map(x => x.dataItem.id),
            subdivisions: new DataHasOwnerStateBuilder(value.subdivisions, this.dataSource.data2, x => x.subdivisionId).build_().source
              .filter(x => x.state !== 'added') // Добавленные подразделения не интересны
              .filter(x => x.dataItem) // Не интересуют подразделения, котрые не относятся к нашим данным
              .map(x => x.dataItem.id),
          };

        }),
        map(value => ([
          ...value.limitStaffUnits,
          ...value.limitStaffUnitRates,
          ...value.occupationTypes,
          ...value.subdivisions,
        ])),
        map(value => this.reloadFromSignalR$(value)),
      );
  }


  @traceFunc()
  protected _reloadFromRemoteByIds$(params: IForDate, targets: number[]): Observable<LimitStaffUnitTreeListItem[]> {
    return this.getForDate$(params, targets);
  }

  @traceFunc()
  protected _reloadData$(params: IForDate): Observable<LimitStaffUnitTreeListItem[]> {
    return this.loadingIndicatorService.addToObservable(
      'Обновление списка',
      this.getForDate$(params),
    );
  }

}
