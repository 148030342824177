import {Component, OnDestroy, OnInit} from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { ReportDateValues } from 'src/app/components/report-dates/classes/report-date-values';
import { ReportDatesComponentSharedService } from 'src/app/components/report-dates/services/report-dates-component-shared.service';

@Component({
  selector: 'app-directories-work-modes',
  templateUrl: './work-modes-directory.component.html',
  styleUrls: ['./work-modes-directory.component.css']
})
export class WorkModesDirectoryComponent implements OnInit, OnDestroy {

  private streams$: {
    unsubscribe: ReplaySubject<any>
  } = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  //Даты на которые нужно получать данные
  public reportDateValues: ReportDateValues = null;


  constructor(private reportDatesComponentSharedService: ReportDatesComponentSharedService) {
  }

  ngOnInit() {
    //Подписка на обновление значений
    this.reportDatesComponentSharedService.reportDateValues$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(report => {
      this.reportDateValues = report;
    });
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
