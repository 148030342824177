import { Injectable } from '@angular/core';
import { WebApi1Service } from '../web-api1.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Subdivision} from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import {Responsible} from 'src/app/classes/domain/POCOs/timesheet/Responsible';

@Injectable({
  providedIn: 'root',
})
export class Api1SubdivisionsDirectoryTreeListControlControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить Subdivisions
   */
  public getForDate$(date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<SubdivisionTreeListItem>> {
    return this.httpClient
      .post<IGetSubdivisionsForDateResponse>(
        this.webApi1Service.controllers.subdivisionsDirectoryTreeListControl.actions.getForDate.toString(),
        { ownerIds, startDate, endDate, date },
      ).pipe(map(data => data.subdivisions.map(subdivision => {

        const responsibleUsers = data.responsibles
          .filter(r => r.subdivisionId == subdivision.id)
          .map(r => {
            const responsibleUnit = data.responsibleUnits.find(ru => ru.employeeId === r.employeeId);
            if (responsibleUnit) {
              return new ResponsibleUser(responsibleUnit.employeeId, responsibleUnit.fullName);
            } else {
              return new ResponsibleUser(r.employeeId, 'СОТРУДНИК УВОЛЕН');
            }
          });

        return new SubdivisionTreeListItem(
          subdivision.id,
          subdivision.startDate,
          subdivision.endDate,
          subdivision.comment,
          subdivision.orderId,
          subdivision.parentId,
          subdivision.shortName,
          subdivision.longName,
          subdivision.code,
          subdivision.sortOrder,
          subdivision.timestamp,
          responsibleUsers,
          responsibleUsers.map(r => r.employeeFullName).join(', '),
        );

      })));
  }

  /**
   * Получить сотрудников которые могут быть ответственными
   */
  public getEmployeesForResponsibility$(forDate: Date = null): Observable<Array<ResponsibleEmployee>> {
    return this.httpClient
      .post<Array<ResponsibleEmployee>>(this.webApi1Service.controllers.subdivisionsDirectoryTreeListControl.actions.getEmployeesForResponsibility.toString(),
        {
          Date: forDate,
        });
  }

}

interface IGetSubdivisionsForDateResponse {
  subdivisions: Pick<Subdivision, 'id' | 'startDate' | 'endDate' | 'sortOrder' | 'orderId' | 'parentId' | 'code' | 'longName' | 'shortName' | 'comment' | 'timestamp'>[];
  responsibles: Pick<Responsible, 'employeeId' | 'subdivisionId'>[];
  responsibleUnits: Array<IResponsibleUnitDTO>;
}

interface IResponsibleUnitDTO {
  employeeId: number;
  fullName: string;
}

export class ResponsibleEmployee {
  constructor(
    public id: number,
    public fio: string,
  ) {
  }
}


export class SubdivisionTreeListItem implements Pick<Subdivision, 'id' | 'startDate' | 'endDate' | 'comment' | 'orderId' | 'parentId' | 'shortName' | 'longName' | 'code' | 'sortOrder' | 'timestamp'> {
  constructor(public id: number,
              public startDate: Date,
              public endDate: Date,
              public comment: string,
              public orderId: number,
              public parentId: number,
              public shortName: string,
              public longName: string,
              public code: string,
              public sortOrder: number,
              public timestamp: [],
              public responsibleUsers: Array<ResponsibleUser>,
              public responsibleUsersString: string,
  ) {
  }

}

export class ResponsibleUser {
  public constructor(public employeeId: number,
                     public employeeFullName: string) {
  }

}
