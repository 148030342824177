import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { FormGroup } from '@angular/forms';
import { VersioningItem } from 'src/app/classes/directories/edit/versioningItem';
import { filter, iif, map, Observable, tap } from 'rxjs';
import { VersioningField } from '../services/classes/fields/versioning-field';
import { DirectoryEditVersioningComponentService } from './service/directory-edit-versioning-component.service';
import { IDirectoryEditVersioningComponent, IVersioningForm, ValueChangeEvent } from './i-directory-edit-versioning.component';
import { CheckboxFieldControl } from '../services/classes/field-controls/checkbox-field-control';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { ServerLoggerService } from 'src/app/services/loggers/server-logger.service';

const dataSourceInput = "dataSource$";

@Component({
  selector: 'app-directory-edit-versioning',
  templateUrl: './directory-edit-versioning.component.html',
  styleUrls: ['./directory-edit-versioning.component.css'],
  providers: [DirectoryEditVersioningComponentService]
})
@traceClass('DirectoryEditVersioningComponent')
export class DirectoryEditVersioningComponent implements IDirectoryEditVersioningComponent, OnInit, OnDestroy {

  /** Настройки поля */
  @Input("field") field: VersioningField;

  /** Данные */
  @Input("dataItem") dataItem: any;

  /**
   * Источник данных. Отлавливает изменение данных. (ОБЯЗАТЕЛЬНО к заполнению)
   * Если переназначить значение переменной то грид будет перезагружен с новыми данными.
   */
  private _dataSource$: Observable<Array<VersioningItem>>;
  @Input(dataSourceInput) public set dataSource$(value: Observable<Array<VersioningItem>>) {
    this.traceService.add2(`Установка [${dataSourceInput}]`, { obj: value });
    this.onDataSourceChanged(value);
  }
  public get dataSource$() {
    return this._dataSource$
  }

  /** Событие происходящее при изменении даты начала */
  @Output("onStartDateChangeWithInit") onStartDateChangeWithInitEventEmitter = new EventEmitter<ValueChangeEvent>();

  /** Событие происходящее при изменении даты окончания */
  @Output("onEndDateChangeWithInit") onEndDateChangeWithInitEventEmitter = new EventEmitter<ValueChangeEvent>();

  /** Событие происходящее когда началось редактирование */
  @Output("onStartEditOrAdd") onStartEditOrAddEventEmitter = new EventEmitter<VersioningItem>();

  /** Событие происходящее когда закончилось редактирование или добавление */
  @Output("onEndEditOrAdd") onEndEditOrAddEventEmitter = new EventEmitter<VersioningItem>();

  /** Событие происходящее перед сохранением значения */
  @Output("onSaving") onSavingEventEmitter = new EventEmitter<VersioningItem>();

  /** Событие происходящее перед добавлением значения */
  @Output("onAdding") onAddingEventEmitter = new EventEmitter<VersioningItem>();

  /** Событие происходящее при удалении значения */
  @Output("onDeleting") onDeletingEventEmitter = new EventEmitter<VersioningItem>();

  /** Контейнер используется для прикрепления в "appendTo", например для dropdowntree */
  @ViewChild('containerForAppend', { read: ViewContainerRef }) appendContainer;

  public startDateMaxValue: Date;
  public startDateMinValue: Date;
  public endDateMinValue: Date;
  public endDateMaxValue: Date;

  public columnTitle: string;

  public form: FormGroup<IVersioningForm>;

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
      unsubscribes: new ReplaySubject<any>(1)
    }

  constructor(private service: DirectoryEditVersioningComponentService, private traceService: TracerServiceBase, private readonly serverLoggerService: ServerLoggerService) {
    this.service.component = this;
  }

  @traceFunc()
  public ngOnInit() {
    if (this.field && this.dataSource$) {
      this.initialize();
    }
  }

  @traceFunc()
  public initialize() {
    this.service.initialize();
  }

  public getDisplayValue(dataItem: VersioningItem) {
    if (this.field.control instanceof CheckboxFieldControl) {
      return (dataItem?.valueAsString?.toLowerCase() === "true") ? "Да" : "Нет";
    }
    return this.field.settings.displayValueFunc ? this.field.settings.displayValueFunc(dataItem) : dataItem.valueAsString;
  }

  @traceFunc()
  public addHandler({ sender }) {
    this.service.addHandler({ sender });
  }

  @traceFunc()
  public editHandler({ sender, rowIndex, dataItem }) {
    this.service.editHandler({ sender, rowIndex, dataItem });
  }

  @traceFunc()
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    this.service.saveHandler({ sender, rowIndex, formGroup, isNew });
  }

  @traceFunc()
  public removeHandler({ dataItem, isNew, rowIndex, sender }) {
    this.service.removeHandler({ dataItem, isNew, rowIndex, sender });
  }

  @traceFunc()
  public cancelHandler({ sender, rowIndex }) {
    this.service.cancelHandler({ sender, rowIndex });
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

  /** Событие происходит в момент изменения dataSource (в основном когда нужно перерисовать данные на основе новых данных из observable) */
  @traceFunc()
  private onDataSourceChanged(value: Observable<Array<VersioningItem>>) {
    this._dataSource$ = value.pipe(
      tap(value => {
        // Try find parent value
        this.service.parent = value.find(f => this.service.isParentVersioningItem(f));
      }),
      map(m=> m.map(v=> v.displayValue ? v : {...v, displayValue: this.getDisplayValue(v)})),
      map(value => (this.field?.settings?.isHideRoot) ? value.filter(x => x.id !== x.ownerId) : value),
      tap(data=> {
        data.forEach(d=> {
          if(d.displayValue === null || d.displayValue === undefined) {
            this.traceService.add2('Нет значения у displayValue', {obj: d});
            this.serverLoggerService.error('Нет значения у displayValue');
          }
        })
      })
    );
  }
}
