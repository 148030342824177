<div class="kendo-custom-form">
  <form class="k-form kendo-custom-form-content" [formGroup]="form" *ngIf="form">
    <div class="form-inline-div">
      <kendo-formfield>
        <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
        <kendo-datepicker required #startDate [formControlName]="'startDate'" [min]="dataItem?.startDate" [max]="form.controls.endDate.value" class="ng-touched"></kendo-datepicker>
        <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение
          родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
        <kendo-datepicker #endDate [formControlName]="'endDate'" [min]="form.controls.startDate.value" [max]="dataItem?.endDate"></kendo-datepicker>
        <kendo-formerror *ngIf="form.controls.endDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение
          родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
      </kendo-formfield>
    </div>

    <kendo-formfield>
      <kendo-label [for]="occupationTypeId" text="Категория должности"></kendo-label>
      <kendo-dropdowntree #occupationTypeId
                          formControlName="occupationTypeId"
                          kendoDropDownTreeExpandable
                          [kendoDropDownTreeFlatBinding]="occupationTypes$ | async"
                          [dataItem]="getDefaultDataItemOccupationType()"
                          textField="name"
                          valueField="id"
                          parentIdField="parentId"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          [filterSettings]="{ ignoreCase: true, operator: 'contains' }"
                          [expandOnFilter]="{ expandMatches: true }"
                          class="ng-touched"
      >
      </kendo-dropdowntree>
      <kendo-formerror *ngIf="form.controls.occupationTypeId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield *ngIf="type === 'subdivision'">
      <kendo-label [for]="subdivisionId" text="Подразделение"></kendo-label>
      <kendo-dropdowntree #subdivisionId
                          formControlName="subdivisionId"
                          kendoDropDownTreeExpandable
                          [kendoDropDownTreeFlatBinding]="subdivisions$ | async"
                          [dataItem]="{}"
                          textField="longName"
                          valueField="id"
                          parentIdField="parentId"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          [filterSettings]="{ ignoreCase: true, operator: 'contains' }"
                          [expandOnFilter]="{ expandMatches: true }"
      >
      </kendo-dropdowntree>
      <kendo-formerror *ngIf="form.controls.subdivisionId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="rates" text="Ставки"></kendo-label>
      <app-add-limit-staff-unit-rate-grid-control #rates [height]="220" formControlName="rates"
                                                  [financingSources]="financingSources$ | async"
                                                  (isEditingNow$)="onChangeIsEditingPositionRatesGrid($event)"></app-add-limit-staff-unit-rate-grid-control>
      <kendo-formerror *ngIf="form.controls.rates.errors?.required">Пожалуйста, введите хотя бы один лимит.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="comment" text="Комментарий"></kendo-label>
      <input kendoTextBox #comment [formControlName]="'comment'"/>
      <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая
        длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая
        длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>
  </form>

  <div class="k-form-buttons kendo-custom-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="form.invalid || isEditingRatesGrid" (click)="onClickSave()">Сохранить</button>
  </div>
</div>
