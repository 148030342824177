import {DrawerItem} from "@progress/kendo-angular-layout";
import { Observable } from "rxjs";

/** Базовый класс сервиса для компонента workspace.component */
export abstract class WorkspaceServiceBase {

  /** Название программы. Выводится внизу левого меню */
  public abstract programName: string;

  /** Получение элементов для левого меню */
  public abstract getItems$(basePath: string, userFunctions: string[], companyName: string): Observable<DrawerItemCustom[]>;
}

/** Интерфейс кустомного элемента DrawerItem */
export interface DrawerItemCustom extends DrawerItem {
  /** Путь роутинга */
  routPath?: string;

  /** Дочерние элементы */
  items?: DrawerItemCustom[];

  /** Выключить редирект */
  disableRedirect?: boolean;

  /** Выключить подсветку активного текущего роута  */
  disableIsActive?: boolean;

  /** Является ли элемент меню дочерним (нужно для корректной отрисовки меню) */
  child?: boolean;

  /** Всплывающий текст. В свернутом меню заменяет text, в открытом как подсказка */
  title?: string;
}
