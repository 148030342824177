import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IPaymentForRankAndDegree extends IEntityId, IEntityModify,
  IEntityDeletedFlag, IEntityComment, IEntityRowVersion {
  /** Полное наименование выплаты */
  name: string;
  /** Сокращенное наименование выплаты */
  shortName: string;
  /** Размер выплаты/надбавки */
  percent: number;
}

@classBackend('PaymentForRankAndDegree', 'tarification_directories')
@className('PaymentForRankAndDegree')
/** Модель БД для строки справочинка "Выплаты за наличие ученой степени, почетного звания" */
export class PaymentForRankAndDegree implements IPaymentForRankAndDegree {
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public comment: string,
              public name: string,
              public shortName: string,
              public percent: number,
              public timestamp: [],
  ) {
  }
}
