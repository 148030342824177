import { IEntityId } from '../interfaces/IEntityId';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IGraphTableBase extends IEntityId {
  /** Подразделение, на работников которого составлен данный график */
  subdivisionId: number;
  /** Месяц, на который составлен график */
  month: Date;
}

/** Общая информация о графиках работы работников определенного подразделения */
export abstract class GraphTableBase implements IGraphTableBase {
  constructor(public id: number,
              public subdivisionId: number,
              public month: Date,
  ) {
  }

  private static _fullComparerBase: ObjComparer<IGraphTableBase>;
  /** Сравнение по всем полям */
  protected static get fullComparerBase(){
    if(!this._fullComparerBase){
      this._fullComparerBase = new ObjComparer<IGraphTableBase>({
        id: true,
        subdivisionId: true,
        month: ObjComparer.dateComparer
      })
    }

    return this._fullComparerBase;
  }
}
