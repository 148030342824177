import {Injectable, OnDestroy} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {defer, Observable, ReplaySubject} from "rxjs";
import { IOccupationType, OccupationType } from "src/app/classes/domain/POCOs/stafflist/OccupationType";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";

@Injectable({
  providedIn: "root"
})
export class Api1OccupationTypesControllerService implements OnDestroy{
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {

  }

  public add$(entity: IOccupationType): Observable<OccupationType>
  {
    return this.httpClient
    .post<OccupationType>(this.webApi1Service.controllers.occupationType.actions.add.toString(),
      entity)
  }

  /**
   * Получить
   */
  public get$() : Observable<Array<OccupationType>>{
    return this.httpClient.get<Array<OccupationType>>(this.webApi1Service.controllers.occupationType.actions.get.toString()
      )
  }

  /**
   * Сохранить
   */
   public save$(entity:OccupationType): Observable<[]>{
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.occupationType.actions.save.toString(),
        entity
      );
  }

  /**
   * Сохранить
   */
  public delete$(entityId): Observable<boolean>{
    return this.httpClient.delete<boolean>(this.webApi1Service.controllers.occupationType.actions.delete.toString(),
    {params: HttpParamsHelper.getParams([["Id",entityId]])}
    )
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
