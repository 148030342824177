import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { versioningAndNotVersioningDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import { OccupationsDirectoryGridComponentService } from './services/occupations-directory-grid-component.service';
import { defer, Observable, ReplaySubject, switchMap, takeUntil } from 'rxjs';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';
import { DirectoryEditRef } from '../../../../../../../src/app/components/directory-edit/services/ref-services/directory-edit-ref';
import { NotVersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/not-versioning-field';
import { xnameofPath } from '../../../../../../../src/app/functions/nameof';
import { OccupationsDirectoryGridItem } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-occupations-directory-grid-control-controller.service';
import { VersioningField } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/versioning-field';
import { SupportedActionEnum } from '../../../../../../../src/app/classes/domain/enums/supported-action-enum';
import { TextBoxFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/text-box-field-control';
import { DateFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/date-field-control';
import { TraceParamEnum } from '../../../../../../../src/app/modules/trace/decorators/classes/traceSetting.interface';
import { traceParam } from '../../../../../../../src/app/modules/trace/decorators/param.decorator';
import { DirectoryEditService } from '../../../../../../../src/app/components/directory-edit/services/directory-edit.service';
import { OccupationsDirectoryGridComponentDataSourceServiceBase } from './services/occupations-directory-grid-component-data-source.service';
import { DisplayErrorsService } from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { FieldType } from '../../../../../../../src/app/components/directory-edit/services/classes/fields/field-type';
import { OccupationTypeDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/occupation-type-data-source.service';
import { DbChangedListener } from '../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { exCreateService$ } from '../../../../../../../src/app/operators/ex-create-service.operator';
import { DropDownTreeFieldControl } from '../../../../../../../src/app/components/directory-edit/services/classes/field-controls/dropdowntree-field-control';
import { CodeDataSourceService } from '../../../../../../../src/app/services/common-data-source-services/code-data-source.service';

@Component({
  selector: 'app-occupations-directory-grid',
  templateUrl: './occupations-directory-grid.component.html',
  styleUrls: ['./occupations-directory-grid.component.css'],
  providers: [
    versioningAndNotVersioningDirectoryServiceProvider,
    OccupationsDirectoryGridComponentService,
  ],
})
@traceClass('OccupationsDirectoryGridComponent')
export class OccupationsDirectoryGridComponent implements OnInit, OnDestroy {
  /** Ключи названий всех колонок */
  public readonly fieldsNames = xnameofPath(OccupationsDirectoryGridItem, '');
  public occupationTypeDataSourceService$: Observable<OccupationTypeDataSourceService>;
  public codeDataSourceService$: Observable<CodeDataSourceService>;
  private directoryEditServiceRef: DirectoryEditRef;
  private fields: FieldType[];
  private readonly streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  private _dataSourceService: OccupationsDirectoryGridComponentDataSourceServiceBase;

  @Input()
  public get dataSourceService(): OccupationsDirectoryGridComponentDataSourceServiceBase {
    return this._dataSourceService;
  }

  public set dataSourceService(value: OccupationsDirectoryGridComponentDataSourceServiceBase) {
    if (this._dataSourceService) {
      throw Error('Повторная установка [dataSource] не поддерживается!');
    }
    this._dataSourceService = value;
  }

  constructor(private readonly directoryEditService: DirectoryEditService,
              private readonly service: OccupationsDirectoryGridComponentService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  public ngOnInit() {
    this.occupationTypeDataSourceService$ = exCreateService$(() => new OccupationTypeDataSourceService(this.dataSourceService, this.dbChangedListener));
    this.codeDataSourceService$ = exCreateService$(() => new CodeDataSourceService(this.dataSourceService, this.dbChangedListener));

    this.initFields();
    this.initDirectoryEditService();
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickAdd() {
    this.service.showAddDialog(this);
  }

  /** При двойном клике открыть неверсионое редактирование поля */
  @traceFunc()
  public onDblCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    if (!e.originalEvent.isEdited) {
      this.startEdit(e.originalEvent.column.field, e.originalEvent.dataItem, e.originalEvent.originalEvent.target);
    }
  }

  /** Событие начала редактирования */
  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  protected startEdit(@traceParam() fieldName: string, @traceParam({ maxLength: 500 }) dataItem: OccupationsDirectoryGridItem, elementRef: any) {
    switch (fieldName) {
      case this.fieldsNames.codesString.toString():
        this.service.showEditCodesDialog(this, dataItem);
        break;
      case this.fieldsNames.occupationTypeName.toString():
        this.directoryEditServiceRef.edit(this.fieldsNames.occupationTypeId.toString(), dataItem, elementRef);
        break;
      default:
        this.directoryEditServiceRef.edit(fieldName, dataItem, elementRef);
        break;
    }
  }

  /** Инициализация полей справочника */
  private initFields() {
    this.fields = [
      new VersioningField(this.fieldsNames.code.toString(), SupportedActionEnum.stafflist_occupation_code, { isAllowNull: false, title: 'Код должности' }, new TextBoxFieldControl({ minLength: 1, maxLength: defer(() => this.dataSourceService.getOccupationCodeLength$().pipe(takeUntil(this.streams$.unsubscribes))), autoTrimValue: true })),
      new VersioningField(this.fieldsNames.name.toString(), SupportedActionEnum.stafflist_occupation_name, { isAllowNull: false, title: 'Наименование должности' }, new TextBoxFieldControl({ minLength: 1, maxLength: 255, autoTrimValue: true })),
      new NotVersioningField(this.fieldsNames.comment.toString(), { isAllowNull: true, title: 'Комментарий' }, new TextBoxFieldControl({ maxLength: 100, autoTrimValue: true })),
      new NotVersioningField(this.fieldsNames.occupationTypeId.toString(), { isAllowNull: false, title: 'Категория должности' }, new DropDownTreeFieldControl({
        data: defer(() => this.occupationTypeDataSourceService$.pipe(
          switchMap(d => d.reloadData$({ occupationTypeIds: null })),
          switchMap(d => d.data2$),
          takeUntil(this.directoryEditServiceRef.onEndEdit$),
        )),
        textField: 'name',
      })),
      new NotVersioningField(this.fieldsNames.startDate.toString(), { isAllowNull: true, title: 'Дата начала' }, new DateFieldControl({ maxValue: () => this.directoryEditServiceRef.currentDataItem.endDate })),
      new NotVersioningField(this.fieldsNames.endDate.toString(), { isAllowNull: true, title: 'Дата окончания' }, new DateFieldControl({ minValue: () => this.directoryEditServiceRef.currentDataItem.startDate })),
    ];
  }

  /** Инициализация сервиса редактирования */
  @traceFunc()
  private initDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.init({
      addVersion$: (actionId, versioningEntity) => this.dataSourceService.addOccupationVersion$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataSourceService.deleteOccupationVersion$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataSourceService.getVersionsByOwnerIdAndActionId$(ownerId, actionId),
      editVersion$: (actionId, entity) => this.dataSourceService.editOccupationVersion$(actionId, entity),
      saveNoVersioning$: (entity) => this.dataSourceService.save$(entity),
    }, this.fields);

    this.directoryEditServiceRef.closeOnCurrentEditingDataItemChanged$(this.dataSourceService.dataSource);
  }

  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
    this.directoryEditServiceRef?.close();
  }
}
