import {Injectable} from "@angular/core";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../../../services/storages/custom-storage.service";


/** Сервис хранения данных в хранилище для компонента Login */
@Injectable({providedIn: "root"})
export class LoginComponentDataStorageService{
  /** Ключ для работы с localStorage - Последний авторизовавшийся пользователь */
  private readonly storageOptionsLastUser = new StorageOptions(
    StorageLocationEnum.LocalStorage,
    'LastAuthUser',
    null,
    true,
    true
  )

  /** Данные последнего авторизованного пользователя */
  public get lastAuthUser(): LastAuthUser{
    return LastAuthUser.Copy(this.customStorageService.get<LastAuthUser>(this.storageOptionsLastUser));
  }

  public set lastAuthUser(value){
    this.customStorageService.set(this.storageOptionsLastUser, LastAuthUser.Copy(value));
  }

  constructor(private readonly customStorageService: CustomStorageService,) {
  }
}

/** Класс последнего пользователя который авторизовался */
class LastAuthUser {
  /**
   * Конструктор
   * @param login Логин
   * @param password Пароль
   */
  constructor(
    public login: string,
    public password: string
  ) {
  }

  /** Копировать */
  public static Copy(source: LastAuthUser){
    return !source ?
      null :
      new LastAuthUser(source.login, source.password);
  }
}
