import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IReportPeriod, IReportPeriodOptional } from "src/app/classes/domain/POCOs/stafflist/ReportPeriod";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { exLoadingMessage } from "src/app/operators/ex-loading-message.operator";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { Api1ReportPeriodsControllerService } from "src/app/services/webApi/webApi1/controllers/api1-report-periods-controller.service";

@traceClass('ReportPeriodsDirectoryGridComponentDataService')
@Injectable()
export class ReportPeriodsDirectoryGridComponentDataService {

  constructor(private readonly traceService: TracerServiceBase,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1ReportPeriodsControllerService: Api1ReportPeriodsControllerService) { }

  @traceFunc()
  public get$(): Observable<IReportPeriod[]> {
    return this.loadingIndicatorService.addToObservable("Загрузка списка периодов контроля", this.api1ReportPeriodsControllerService.get$())
  }

  @traceFunc()
  public delete$(entityId): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable("Удаление периода контроля", this.api1ReportPeriodsControllerService.delete$(entityId))
  }

  @traceFunc()
  public add$(entity: IReportPeriodOptional): Observable<IReportPeriod> {
    return this.loadingIndicatorService.addToObservable("Добавление периода контроля", this.api1ReportPeriodsControllerService.add$(entity))
  }

  @traceFunc()
  public save$(entity: IReportPeriod): Observable<[]> {
    return this.api1ReportPeriodsControllerService.save$(entity).pipe(exLoadingMessage(this.loadingIndicatorService, 'Сохранение данных'))
  }
}
