import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IMeasureUnit extends IEntityId, IEntityModify, IEntityDeletedFlag, IEntityRowVersion {
  /** Наименование */
  name: string;
  /** Сокращенное наименование */
  shortName: string;
  /** Описание */
  description: string;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IMeasureUnitOptional = Partial<IMeasureUnit>;

@classBackend('MeasureUnit', 'pfhd_directories')
@className('MeasureUnit')
/** Модель БД таблицы pfhd.MeasureUnits (Единицы измерения) */
export class MeasureUnit implements IMeasureUnit {
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public name: string,
              public shortName: string,
              public description: string,
              public timestamp: [],
  ) {
  }
}
