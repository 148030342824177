/** Единица выпадающего списка */
export interface IDropDownItem {
  /** Идентификатор */
  id: number,
  /** Текст */
  text: string
}

export class DropDownItem implements IDropDownItem {
  constructor(
    public id: number,
    public text: string
  ) {}
}
