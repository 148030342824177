import {ObjectDeepSerializerServiceBase} from "../object-deep-serializer-service-base";
import {DateOnly} from "../../../classes/Dates/date-onlys/date-only";
import {AppSettingsService} from "../../app-settings.service";
import {Injectable} from "@angular/core";

/**
 * Сервис глубокой сериализации/десериализации {@link DateOnly} по переданному формату
 */
export class DateOnlyDeepSerializerService extends ObjectDeepSerializerServiceBase<DateOnly>{

  /**
   * Конструктор
   * @param parseFormat формат/форматы для парсинга
   * @param serializeFormat формат для вывода в строку
   */
  constructor(public readonly parseFormat: string | string[], public readonly serializeFormat: string) {
    super();
  }

  public isType(obj: any): obj is DateOnly {
    return DateOnly.isDateOnly(obj);
  }
  public typeToString(obj: DateOnly): string {
    return obj.format(this.serializeFormat);
  }
  public tryParse(str: string): DateOnly | undefined {
    return DateOnly.tryParse(str, this.parseFormat, true);
  }
}

/**
 * Сервис глубокой сериализации/десериализации {@link DateOnly} в формат сервера
 */
@Injectable({
  providedIn: "root"
})
export class DateOnlySerializerToServerFormatService extends DateOnlyDeepSerializerService{
  constructor(appSettingsService: AppSettingsService) {
    super(appSettingsService.webApiFormatSettings.dateOnly, appSettingsService.webApiFormatSettings.dateOnly);
  }
}
