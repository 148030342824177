<div kendoDialogContainer></div>

<kendo-grid
[data]="data$ | async"
kendoGridSelectBy="id"
[groupable]="false"
[reorderable]="false"
[resizable]="false"
[style.maxHeight.%]="100"
(selectionChange)="onSelectionChanged($event)"
[selectable]="{
  mode: 'single',
  enabled: true
}"
[sortable]="false"
[sort]="false"
[filter]="false"
[pageable]="false"
scrollable="true"


KendoGridExpanded
(dbCellClick)="onDblCellClick($event)"
>
<ng-template kendoGridToolbarTemplate>
  <div class="grid-header-content">
    <button kendoButton icon="plus" (click)="onClickAdd()" primary="true" [size]="'small'">Добавить</button>
    <button kendoButton icon="delete" (click)="onClickRemove()" [disabled]="!currentSelectedItem" primary="true" [size]="'small'">Удалить</button>
  </div>
</ng-template>
    <kendo-grid-column field="name" title="Наименование периода контроля"></kendo-grid-column>
    <kendo-grid-column field="comment" title="Комментарий" [sortable]="false"></kendo-grid-column>
</kendo-grid>
