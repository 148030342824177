/**
 * Интерфейс расширения объекта
 * В нем расположены те поля который нужно реализовывать в ExtensionObj и BaseExtensionObj
 * больше ни для чего не нужен
 */
interface IExtensionObj<TSource> {
  /**
   * Источник который обворачиваем
   */
  source: TSource;
  /**
   * Модифицировать объект-источник.
   * Если this.source == null | undefined то функция не будет вызвана для предотвращения ошибки
   * @param func функция модификации объекта
   */
  mod(func: (source: TSource) => void): any
  /**
   * Модифицировать объект-источник и возвращает его.
   * Если this.source == null | undefined то функция не будет вызвана для предотвращения ошибки
   * @param func функция модификации объекта
   */
  modResult(func: (source: TSource) => void): TSource;
  /**
   * Конвертирует объект-источник в другой объект.
   * Если this.source == null | undefined то функция не будет вызвана для предотвращения ошибки
   * @param func функция конвертирующая объекта
   */
  mapResult<TResult>(func: (source: TSource) => TResult): TResult;
  /**
   * Конвертирует объект-источник в другой объект и обворачивает результат в расширение объекта
   * Если this.source == null | undefined то функция не будет вызвана для предотвращения ошибки
   * @param func функция конвертации объекта
   */
  map<TResult>(func: (source: TSource) => TResult): ExtensionObj<TResult>;
  /**
   * Равен ли источник null | undefined
   */
  sourceIsEmpty: boolean
}

/**
 * Класс расширяющий объект
 * Работа с ним на подобии методов расширения c#
 */
export class ExtensionObj<TSource> implements IExtensionObj<TSource>{
  /**
   * Конструктор
   */
  constructor(public source: TSource) {
  }

  public mod(func: (source: TSource) => void): ExtensionObj<TSource>{
    if(!this.sourceIsEmpty){
      func(this.source)
    }
    return this;
  }

  public modResult(func: (source: TSource) => void): TSource{
    this.mod(func);
    return this.source;
  }

  public mapResult<TResult>(func: (source: TSource) => TResult): TResult{
    if(this.sourceIsEmpty){
      return null;
    }

    return func(this.source);
  }


  public map<TResult>(func: (source: TSource) => TResult): ExtensionObj<TResult>{
    return new ExtensionObj<TResult>(
      this.mapResult(func)
    );
  }

  public get sourceIsEmpty(): boolean{
    return this.source == null || this.source == undefined;
  }
}

export abstract class BaseExtensionObj<TSource, TExtends extends BaseExtensionObj<TSource, TExtends>>
  implements IExtensionObj<TSource>
{
  private readonly extensionObj: ExtensionObj<TSource> = null;

  /**
   * Конструктор
   * @param source Источник который обворачиваем
   * @param getExtend Функция получающая наследника. Необходимо в конструкторе наследника передать () => return this;
   */
  protected constructor(public source: TSource, private getExtend: () => TExtends) {
    this.extensionObj = new ExtensionObj<TSource>(source);
  }

  mod(func: (source: TSource) => void): TExtends {
    if(!this.sourceIsEmpty){
      func(this.source)
    }
    return this.getExtend();
  }

  modResult(func: (source: TSource) => void): TSource {
    return this.extensionObj.modResult(func);
  }

  mapResult<TResult>(func: (source: TSource) => TResult): TResult {
    return this.extensionObj.mapResult(func);
  }

  map<TResult>(func: (source: TSource) => TResult): ExtensionObj<TResult> {
    return new ExtensionObj<TResult>(
      this.mapResult(func)
    );
  }

  get sourceIsEmpty(): boolean{
    return this.extensionObj.sourceIsEmpty;
  };
}
