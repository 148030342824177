import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { ComponentServiceBase } from 'src/app/services/abstracts/component-service-base';
import { ITextBoxDirectoryEditComponent } from '../i-textbox-directory-edit-control.component';
import { SettingsReaderService } from '../../../services/classes/service-settings/settings-reader/settings-reader.service';
import { combineLatest, of } from 'rxjs';

@Injectable()
@traceClass('TextBoxDirectoryEditComponentService')
export class TextBoxDirectoryEditComponentService extends ComponentServiceBase<ITextBoxDirectoryEditComponent> {

  private readonly srs = new SettingsReaderService();

  constructor(private readonly tracer: TracerServiceBase) {
    super();
  }

  /** Считываем настройки компонента */
  @traceFunc()
  public readSettings() {
    this.component.placeholder = this.component.settings.placeholder ?? '';
    this.component.minLength$ = this.srs.getValue$(this.component.settings.minLength);
    this.component.maxLength$ = this.srs.getValue$(this.component.settings.maxLength);
  }

  /** Регистрация валидаторов допустимых для данного типа контрола  */
  @traceFunc()
  public registerValidators() {
    combineLatest([this.component.minLength$ ?? of(null), this.component.maxLength$ ?? of(null)])
      .subscribe(([minLength, maxLength]) => {
        this.component.control.clearValidators();

        if (minLength) {
          this.component.control.addValidators(Validators.minLength(minLength));
          this.component.control.addValidators(Validators.required);
        }

        if (maxLength) {
          this.component.control.addValidators(Validators.maxLength(maxLength));
        }
      });
  }

}
