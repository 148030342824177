import {StaffUnitTypeEnum} from "../classes/domain/enums/StaffUnitTypeEnum";

export class IconsUrlHelper{

  /** Базовый путь для ВСЕХ иконок */
  public static patchToIcons = '../../../../assets/icons/';

  /** Получить путь для иконки штатной единицы */
  public static getstaffUnitImagePath(value: StaffUnitTypeEnum, hasParent: boolean): string {
    switch (value) {
      case StaffUnitTypeEnum.MoonlighterInner:
      case StaffUnitTypeEnum.MoonlighterExternal:
        return hasParent ?
          `${IconsUrlHelper.patchToIcons}moonlighter-combination/moonlighter_bosy_rate.png` :
          `${IconsUrlHelper.patchToIcons}moonlighter-combination/moonlighter_free_rate.png`;
      case StaffUnitTypeEnum.CombinationInner:
      case StaffUnitTypeEnum.CombinationExternal:
        return hasParent ?
          `${IconsUrlHelper.patchToIcons}moonlighter-combination/combination_bosy_rate.png?hash=YtfTAf` :
          `${IconsUrlHelper.patchToIcons}moonlighter-combination/combination_free_rate.png`;
      case StaffUnitTypeEnum.Duty:
        return `${IconsUrlHelper.patchToIcons}moonlighter-combination/duty.png?hash=DdJnJS`;
      case StaffUnitTypeEnum.Uvor:
        return hasParent ?
          `${IconsUrlHelper.patchToIcons}moonlighter-combination/uvor_busy_rate.png?hash=q10gXc` :
          `${IconsUrlHelper.patchToIcons}moonlighter-combination/uvor_free_rate.png?hash=zIOff2`;
      default:
        return '';
    }
  }
}
