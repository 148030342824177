import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ReportDateValues } from 'src/app/components/report-dates/classes/report-date-values';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';

/** Данный сервис предназначен для перехвата изменения значений в компоненте дат отчёта */
@Injectable()
@traceClass('ReportDatesComponentSharedService')
export class ReportDatesComponentSharedService implements OnDestroy {

  private readonly streams$ = { valueChanged$: new ReplaySubject<ReportDateValues>(1) };

  /** Стрим для получения дат отчёта */
  public get reportDateValues$(): Observable<ReportDateValues> {
    return this.streams$.valueChanged$;
  }

  constructor(private readonly tracerService: TracerServiceBase) {
  }

  /** Событие происходит в момент изменения дат отчёта в компоненте дат отчёта */
  @traceFunc()
  public onReportDatesChanged(e: ReportDateValues) {
    this.streams$.valueChanged$.next(e);
  }

  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.valueChanged$.complete();
  }
}
