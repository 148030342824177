import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkSpaceErrorComponentService} from "../../services/workspace/work-space-error.component.service";

@Component({
  selector: 'app-workspace-error',
  templateUrl: './workspace-error.component.html',
  styleUrls: ['./workspace-error.component.css']
})
export class WorkspaceErrorComponent implements OnInit, OnDestroy {
  /**
   * конструктор
   */
  constructor(public workSpaceComponentService: WorkSpaceErrorComponentService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
