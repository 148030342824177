import { IEntityId } from '../interfaces/IEntityId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IResponsible extends IEntityId {
  /** Идентификатор сотрудника, ответственного за составление графиков и табелей в конкретном подразделении */
  employeeId: number;
  /** Идентификатор подразделения */
  subdivisionId: number;
}

@classBackend('Responsible', 'timesheet')
@className('Responsible')
/** Сотрудник, ответственный за составление графиков и табелей в конкретном подразделении */
export class Responsible implements IResponsible {
  constructor(public id: number,
              public employeeId: number,
              public subdivisionId: number,
  ) {
  }
}
