import {Injectable} from "@angular/core";
import {ServerLoggerService} from "./loggers/server-logger.service";
import {TracerServiceBase} from "../modules/trace/tracers2/trace-services/tracer-base.service";

/** Сервис-помощник для разработки */
@Injectable({providedIn: "root"})
export class MedsoftlabService{
  private static readonly propertyName: string = 'medsoftlab';

  constructor(private readonly tracerService: TracerServiceBase,
              private readonly serverLoggerService: ServerLoggerService) {
    window[MedsoftlabService.propertyName] = this;
  }

  /** Вывести трассировку в консоль и отправить ее на сервер */
  public tracing(){
    this.tracerService.addNewLine();
    this.tracerService.addNewLine();
    this.tracerService.add('Трассировка снята через console tracing');
    console.log(this.tracerService.storageService.buildAsString())
    this.serverLoggerService.send('');
  }
}
