import { map, Observable } from 'rxjs';
import { ArrayDataSourceIEntityId, DataSource } from 'src/app/classes/array-data-sources/data-source';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../data-source-services/data-source.service';
import { DbChangedListener } from '../signal-r/listeners/db-changed-listener';
import { IOccupationType, OccupationType } from '../../classes/domain/POCOs/stafflist/OccupationType';
import { DataStateBuilder } from '../../classes/data-state-builders/data-state-builder';

export class OccupationTypeDataSourceService
  extends ArrayDataSourceIEntityIdServiceWithParamsBase<OccupationTypeDataSourceService_Params, IOccupationType> {

  readonly paramsDataSource = new DataSource<OccupationTypeDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<IOccupationType>();

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(OccupationType)
      .pipe(
        map(value => value.data),
        map(value => new DataStateBuilder(value, this.dataSource.data2, (x, y) => x.id === y.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.id)),
        map(value => this.reloadFromSignalR$(value)),
      );
  }

  protected _reloadData$(params: OccupationTypeDataSourceService_Params): Observable<IOccupationType[]> {
    return this._reloadFromRemoteByIds$(params, params.occupationTypeIds);
  }

  constructor(private readonly parentDataSourceService: IOccupationTypeDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected _reloadFromRemoteByIds$(params: OccupationTypeDataSourceService_Params, targets: IOccupationType['id'][]): Observable<IOccupationType[]> {
    return this.parentDataSourceService.getOccupationTypes$(targets);
  }
}

export interface OccupationTypeDataSourceService_Params {
  occupationTypeIds: number[];
}

export interface IOccupationTypeDataSourceService_ParentDataSourceService {
  getOccupationTypes$(occupationIds: number[]): Observable<IOccupationType[]>;
}
