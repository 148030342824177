import {ArrayDataSourceSelection} from "./array-data-source-selection";
import {ArrayDataSourceHasId} from "../data-source";
import {ArrayHelper} from "../../../helpers/arrayHelper";
import {TreeItem} from "@progress/kendo-angular-treeview/treeitem.interface";
import {
  KendoGridSelectByArrayDataSourceSelectionDirective
} from "../../../directives/selections/kendo-grid-select-by-array-data-source-selection.directive";

/**
 * @deprecated может не верно работать с onPush. Необходимо реализовать директиву по аналогии с {@link KendoGridSelectByArrayDataSourceSelectionDirective}
 * Класс расширяет возможности {@link ArrayDataSourceSelection} для KendoTreeViewComponent, директива [kendoTreeViewSelectable]
 */
export class KendoTreeViewArrayDataSourceSelection<TDataItem, TId> extends ArrayDataSourceSelection<TDataItem, TId>{
  /**
   * Данные для директивы [kendoTreeViewSelectable]<br>
   * НЕ ДОПУСКАЕТСЯ ПРОГРАММНО УСТАНАВЛИВАТЬ ЗНАЧЕНИЯ В ОБЪЕКТЕ!<br>
   */
  public readonly forDirective: ForDirective<TDataItem, TId>;

  /**
   * Конструктор
   * @param dataSource Источник данных
   */
  constructor(dataSource: ArrayDataSourceHasId<TDataItem, TId>) {
    super(dataSource);

    this.forDirective = new ForDirective<TDataItem, TId>(
      dataSource.idGetter,
      (ids) => this.onUserChangedSelectedIdsCallback(ids)
    );

    this.selectedIds.data$.subscribe(value => {
      this.update_forDirective_selectedKeys(value);
    })
  }

  /** Обработка изменения пользователем выделенных строк(идентификаторов) */
  private onUserChangedSelectedIdsCallback(ids: TId[]){
    this.setIds(ids, true, 'user');
  }

  /** Метод поддерживает в актуальности список выделенных идентификаторов в this.forDirective.selectedKeys */
  private update_forDirective_selectedKeys(ids: TId[]){
    if(!ArrayHelper.equals2(ids, this.forDirective.selectedKeys, (x1, x2) => x1 === x2)){
      this.forDirective.selectedKeys = [...ids]; //Обязательно копируем
    }
  }
}

/** Класс данных для директивы kendoTreeViewSelectable */
class ForDirective<TDataItem, TId>{
  /**
   * Использовать для биндинга в директиве.<br>
   * ОБЯЗАТЕЛЬНО ДЛЯ РАБОТЫ!<br>
   * @example <kendo-treeview kendoTreeViewSelectable [selectBy]="путь.selectBy"
   * @example <kendo-treeview kendoTreeViewCheckable [checkBy]="путь.selectBy"
   */
  public readonly selectBy: (dataItem: TreeItem) => TId = x => this.idGetter(x.dataItem);

  /**
   * Использовать для биндинга в директиве.<br>
   * ОБЯЗАТЕЛЬНО ДЛЯ РАБОТЫ!<br>
   * @example <kendo-treeview kendoTreeViewSelectable [selectedKeys]="путь.selectedKeys"
   * @example <kendo-treeview kendoTreeViewCheckable [checkedKeys]="путь.selectedKeys"
   */
  public selectedKeys: TId[] = [];

  /**
   * Конструктор
   * @param idGetter функция получения идентификатора из элемента данных. Можно получить из dataSource
   * @param userChangedSelectedIdsCallback функция обратного вызова при изменении выделенных строк пользователем
   */
  constructor(private readonly idGetter: (item: TDataItem) => TId,
              private readonly userChangedSelectedIdsCallback: (ids: TId[]) => void) {

  }

  /**
   * Использовать для биндинга в директиве.<br>
   * ОБЯЗАТЕЛЬНО ДЛЯ РАБОТЫ!<br>
   * @example <kendo-treeview kendoTreeViewSelectable (selectedItemsChange)="путь.onSelectedKeysChange($event)
   * @example <kendo-treeview kendoTreeViewSelectable (checkedKeysChange)="путь.onSelectedKeysChange($event)
   * @param event данные события
   */
  public onSelectedKeysChange(event: TId[]){
    this.userChangedSelectedIdsCallback(event);
  }
}
