import { map, Observable } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataStateBuilder } from "src/app/classes/data-state-builders/data-state-builder";
import { FinancingSource, IFinancingSource } from "src/app/classes/domain/POCOs/stafflist/FinancingSource";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";

export class FinancingSourceDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<FinancingSourceDataSourceService_Params, IFinancingSource>{

  readonly paramsDataSource = new DataSource<FinancingSourceDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<IFinancingSource>();

  constructor(private readonly parentDataSourceService: IFinancingSourceDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(FinancingSource)
      .pipe(
        map(value => value.data),
        map(value => new DataStateBuilder(value, this.dataSource.data2, (x,y) => x.id === y.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.id)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: FinancingSourceDataSourceService_Params): Observable<IFinancingSource[]> {
    return this._reloadFromRemoteByIds$(params, params.financingSourcesIds);
  }

  protected _reloadFromRemoteByIds$(params: FinancingSourceDataSourceService_Params, targets: IFinancingSource["id"][]): Observable<IFinancingSource[]> {
    return this.parentDataSourceService.getFinancingSources$(targets);
  }
}

export interface FinancingSourceDataSourceService_Params{
  financingSourcesIds: number[];
}

export interface IFinancingSourceDataSourceService_ParentDataSourceService {
  getFinancingSources$(financingSourcesIds: number[]): Observable<IFinancingSource[]>;
}
