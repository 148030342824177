<div class="auth-wrapper">
  <div class="auth-wrapper-logo">
    <img src="assets/icons/logo_blue.png" alt="Логотип">
    <div>
      <span>МедСофтЛаб</span>
      <span>Лаборатория медицинского программного обеспечения</span>
    </div>
  </div>
  <div class="k-dialog-wrapper">
    <div class="auth-module">
      <span>Модуль</span>
      <div class="auth-program-name">{{ programName }}</div>
    </div>
    <form *ngIf="showAuthForm" [formGroup]="form" class="k-window k-dialog auth-shadow">
      <div class="k-window-titlebar k-dialog-titlebar">
        <div class="k-window-title k-dialog-title">Авторизация</div>
      </div>
      <div class="k-content k-window-content k-dialog-content">
        <div class="auth-error" *ngIf="errorMessage.length > 0">
          {{ errorMessage }}
        </div>
        <div class="auth-content">
          <div>
            <kendo-floatinglabel text="Введите логин">
              <input kendoTextBox
                     formControlName="login"
              />
            </kendo-floatinglabel>
          </div>
          <div>
            <kendo-floatinglabel text="Введите пароль">
              <input kendoTextBox
                     type="password"
                     formControlName="password"
              />
            </kendo-floatinglabel>
          </div>
          <div class="remember">
            <input type="checkbox"
                   kendoCheckBox
                   formControlName="rememberFlag"
                   #remember
            />
            <kendo-label [for]="remember" text="Запомнить меня"></kendo-label>
          </div>
        </div>
      </div>
      <div class="k-dialog-buttongroup k-actions">
        <button kendoButton
                [disabled]="form.invalid || isSendRequest"
                (click)="onLogin()"
                primary="true"
        >
          Войти
        </button>
      </div>
    </form>

    <form *ngIf="paramCompany && marker && marker.company !== paramCompany" class="k-window k-dialog auth-shadow">
      <div class="k-window-titlebar k-dialog-titlebar">
        <div class="k-window-title k-dialog-title">Информация</div>
      </div>
      <div class="k-content k-window-content k-dialog-content">
        <div class="message">
          <div class="icon-container">
            <span class="k-icon k-i-information k-font-icon"></span>
          </div>
          <div style="width: 16px;"></div>
          <div style="flex: 1;">
            <div>В данный момент вы авторизованы в другой компании.<br>Если есть не сохраненные данные в другой компании,<br>то рекомендуем сперва их сохранить и выйти.</div>
          </div>
        </div>
      </div>
      <div class="k-dialog-buttongroup k-actions">
        <button kendoButton (click)="onLogout()">
          Все равно продолжить (Внимание! убедитесь, что прочитали сообщение)
        </button>
      </div>
    </form>

    <form *ngIf="!paramCompany && !marker?.company" class="k-window k-dialog auth-shadow">
      <div class="k-window-titlebar k-dialog-titlebar">
        <div class="k-window-title k-dialog-title">Информация</div>
      </div>
      <div class="k-content k-window-content k-dialog-content">
        <div class="message">
          <div class="icon-container">
            <span class="k-icon k-i-information k-font-icon"></span>
          </div>
          <div style="width: 16px;"></div>
          <div style="flex: 1;">
            <div>Неправильный запуск приложения.<br>Не укакза компания.<br><br><strong>Обратитесь к администратору.</strong></div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
