import { Component, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Component({
  selector: 'app-custom-directory-edit-control',
  templateUrl: './custom-directory-edit-control.component.html'
})
@traceClass('CustomDirectoryEditComponent')
export class CustomDirectoryEditComponent {

  @Input("appendContainer") public appendContainer: ViewContainerRef;
  @Input("templateRef") public templateRef: TemplateRef<any>;
  @Input("control") public control: FormControl;

  constructor( private traceService: TracerServiceBase) {
  }
}
