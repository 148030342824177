import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductionCalendarDirectoryGridDataSourceService } from '../production-calendar-directory-grid/services/production-calendar-directory-grid-data-source.service';
import { ReplaySubject, startWith, switchMap, take } from 'rxjs';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceClass } from '../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { ProductionCalendarDirectoryGridItem } from '../production-calendar-directory-grid/production-calendar-directory-grid.component';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ProductionCalendarDirectoryDataService } from './services/production-calendar-directory-data.service';
import { IDropDownItem } from '../../../../../../../src/app/classes/requestResults/iDropDownItem';
import { ArrayDataSourceIEntityId, DataSource } from '../../../../../../../src/app/classes/array-data-sources/data-source';
import { traceFunc } from '../../../../../../../src/app/modules/trace/decorators/func.decorator';
import {DayType} from "../../../../../../../src/app/classes/domain/POCOs/timesheet/DayType";

@Component({
  selector: 'app-production-calendar-directory',
  templateUrl: './production-calendar-directory.component.html',
  styleUrls: ['./production-calendar-directory.component.css'],
  providers: [
    ProductionCalendarDirectoryDataService,
    ProductionCalendarDirectoryGridDataSourceService,
  ],
})
@traceClass('ProductionCalendarDirectoryComponent')
export class ProductionCalendarDirectoryComponent implements OnInit, OnDestroy {

  public readonly yearFormControl = new FormControl(new Date().getFullYear());
  public readonly yearsDataSource = new DataSource<IDropDownItem[]>();
  public readonly dayTypesDataSource = new ArrayDataSourceIEntityId<Pick<DayType, 'id' | 'name' | 'color'>>();
  public readonly selectedItemDataSource = new DataSource<ProductionCalendarDirectoryGridItem>();
  private readonly unsubscribe$ = new ReplaySubject<any>(1);

  constructor(public readonly dataService: ProductionCalendarDirectoryDataService,
              public readonly dataSourceService: ProductionCalendarDirectoryGridDataSourceService,
              private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit(): void {
    this.yearsDataSource.setData$(this.dataService.getYears$).subscribe();
    this.dayTypesDataSource.setData$(this.dataService.getDayTypes$).subscribe();

    this.yearFormControl.valueChanges
      .pipe(
        startWith(this.yearFormControl.value),
        switchMap(v => this.dataSourceService.reloadData$(v)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    this.dataSourceService.dataSource.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => this.selectedItemDataSource.setData(value.find(v => v.id === this.selectedItemDataSource.data?.id) ?? null));
  }

  @traceFunc()
  public onCellClick(dataItem: ProductionCalendarDirectoryGridItem) {
    this.selectedItemDataSource.setData(dataItem);
  }

  @traceFunc()
  public onDbCellClick(dataItem: ProductionCalendarDirectoryGridItem) {
    this.dayTypeIncrement(dataItem);
  }

  @traceFunc()
  public setDayType(dayTypeId: number, dataItem: ProductionCalendarDirectoryGridItem = this.selectedItemDataSource.data) {
    const dayType = this.dayTypesDataSource.data.find(d => d.id === dayTypeId);
    const oldDayTye = { ...dataItem.dayType };

    dataItem.dayType = dayType;

    return this.dataService.setDayType$(dataItem.id, dayTypeId, dataItem.timestamp)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe({
        next: timestamp => {
          this.selectedItemDataSource.setData({ ...dataItem, dayType, timestamp });
        },
        error: () => {
          this.selectedItemDataSource.setData({ ...dataItem, dayType: oldDayTye });
        },
      });
  }

  @traceFunc()
  private dayTypeIncrement(dataItem: ProductionCalendarDirectoryGridItem) {
    const dayTypes = this.dayTypesDataSource.data;

    const currentDayTypeIndex = dayTypes.findIndex(d => d.id === dataItem.dayType.id);
    let nextDayType = dayTypes[currentDayTypeIndex + 1 >= dayTypes.length ? 0 : currentDayTypeIndex + 1];

    if (nextDayType.id == 1 && dataItem.date.isoWeekday() < 6) {
      nextDayType = dayTypes[2];
    }

    return this.setDayType(nextDayType.id, dataItem);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
