import { Component, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { State } from '@progress/kendo-data-query';
import {IPositionRate} from 'src/app/classes/domain/POCOs/stafflist/PositionRate';
import { DropDownItem, IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { CustomFormValidators } from 'src/app/validators/custom-form-validators.class';
import { Api1StaffListSettingsControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { trace } from 'src/app/modules/trace/operators/trace';

@Component({
  selector: 'app-add-position-rates-grid-control',
  templateUrl: './add-position-rates-grid-control.component.html',
  styleUrls: ['./add-position-rates-grid-control.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddPositionRatesGridControlComponent),
    multi: true,
  }],
})
@traceClass('AddPositionRatesGridControlComponent')
export class AddPositionRatesGridControlComponent implements ControlValueAccessor, OnInit {

  @Input('height') public height: number;
  @Input('financingSourcesDropDownItems') public financingSourcesDropDownItems: Array<IDropDownItem> = [];

  @Output('isEditingNow$') public isEditingNow$: Subject<boolean> = new Subject<boolean>();

  public gridData: Array<AddPositionRateItem>;

  public rateStep: number;

  public gridState: State = {};
  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public form: UntypedFormGroup;
  private editedRowIndex: number;

  private _isDisabled: boolean;

  public get isDisabled(): boolean {
    return this._isDisabled;
  }

  /** Получить доступные источники */
  public get getAllowedFinancingSources(): Array<IDropDownItem> {
    return this.financingSourcesDropDownItems.filter(f => this.gridData.findIndex(gd => gd.financingSourceId === f.id) === -1);
  }

  constructor(private formBuilder: UntypedFormBuilder,
              private settingsService: Api1StaffListSettingsControllerService,
              private readonly tracerService: TracerServiceBase,
  ) {
  }

  @traceFunc()
  ngOnInit() {
    this.init();
  }

  onChange(_: any) {
  }

  writeValue(obj: any): void {
    this.gridData = obj ?? [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    //Вызываем событие изменения
    this.onChange(this.gridData);
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  public getFinancingSourceNameById(financingSourceId: number) {
    return this.financingSourcesDropDownItems.find(f => f.id === financingSourceId)?.text;
  }

  public getDefaultDropDownValueForDataItem(dataItem: AddPositionRateItem, isNew: boolean) {
    if (!isNew) {
      return new DropDownItem(dataItem.financingSourceId, this.getFinancingSourceNameById(dataItem.financingSourceId));
    } else {
      return new DropDownItem(null, 'Не выбрано');
    }
  }

  @traceFunc()
  init() {
    this.settingsService.rateStepWithCash$
      .pipe(trace(this.tracerService), take(1), takeUntil(this.unsubscribe$))
      .subscribe(value => this.rateStep = value);
  }

  @traceFunc()
  public addHandler({ sender }) {
    sender.addRow(this.createFormGroup(null));
    this.isEditingNow$.next(true);
  }

  @traceFunc()
  public cancelHandler({ sender, rowIndex }) {
    sender.closeRow(rowIndex);
    this.isEditingNow$.next(false);
  }

  @traceFunc()
  public saveHandler({ sender, formGroup, rowIndex, isNew }) {
    if (formGroup.valid) {
      if (isNew) {
        this.gridData.push(formGroup.value);
      } else {
        this.gridData[rowIndex] = formGroup.value;
      }
      sender.closeRow(rowIndex);
      this.isEditingNow$.next(false);
    }
  }

  @traceFunc()
  public removeHandler({ sender, dataItem }) {
    this.gridData = this.gridData.filter(f => f.financingSourceId !== dataItem.financingSourceId);
    sender.cancelCell();
  }

  @traceFunc()
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.form);
    this.isEditingNow$.next(true);
  }

  @traceFunc()
  public createFormGroup(item: AddPositionRateItem): UntypedFormGroup {
    this.form = this.formBuilder.group({
      financingSourceId: [item?.financingSourceId, [Validators.required]],
      rate: [item?.rate, [Validators.required, Validators.min(0), Validators.max(999), CustomFormValidators.multipleOf(this.rateStep)]],
      comment: [item?.comment, [Validators.maxLength(100)]],
    });
    this.form.valueChanges.subscribe(v => this.tracerService.add2('Изменение формы', { obj: v }));
    return this.form;
  }

  @traceFunc()
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.form = undefined;
    this.isEditingNow$.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

export class AddPositionRateItem implements Pick<IPositionRate, 'financingSourceId' | 'rate' | 'comment'> {
  constructor(
    public financingSourceId: number,
    public rate: number,
    public comment: string) {
  }
}
