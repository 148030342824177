import { IEntityStartEndDate } from "./domain/POCOs/interfaces/IEntityStartEndDate";

/** Интерфейс получения данных */
export class IForDate{
  /** Дата начала выборки */
  startDate: Date | null;
  /** Дата окончания выборки */
  endDate: Date | null;
  /** На дату */
  forDate?: Date | null;
}

/** Класс получения данных*/
export class ForDate implements IForDate{
  constructor(public startDate: Date | null,
              public endDate: Date | null,
              public forDate: Date | null) {
  }

  /** Скопировать */
  public static Copy(source: IForDate){
    return new ForDate(source.startDate, source.endDate, source.endDate);
  }

  /** Получить из любого объекта релазизующего интерфейс IEntityStartEndDate*/
  public static Get(source: Partial<Pick<IEntityStartEndDate, 'startDate' | 'endDate'>>) {
    return new ForDate(source.startDate, source.endDate, source.endDate ?? source.startDate ?? new Date());
  }

  private static readonly startDateName: keyof IForDate = 'startDate';
  private static readonly endDateName: keyof IForDate = 'endDate';
  private static readonly forDateName: keyof IForDate = 'forDate';

  /** Является ли типом IForDate */
  public static InstanceOf_IForDate(source: any): source is IForDate{
    return this.startDateName in source && this.endDateName in source && this.forDateName in source;
  }
}
