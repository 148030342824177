import { Injectable } from '@angular/core';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { Api1EmployeeNamesDeclensionControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-names-declension-controller.service';

@Injectable()
@traceClass('AddEmployeeFormComponentDataService')
export class AddEmployeeFormComponentDataService {

  constructor(private readonly traceService: TracerServiceBase,
              private readonly api1EmployeeNamesDeclensionControllerService: Api1EmployeeNamesDeclensionControllerService) {
  }

  public generateEmployeeNamesDeclensionsForEmployeeFullName$(fullName: string) {
    return this.api1EmployeeNamesDeclensionControllerService.generateEmployeeNamesDeclensionsForEmployeeFullName$(fullName);
  }

}
