import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {StaffUnitType} from "../../../classes/domain/POCOs/stafflist/StaffUnitType";
import {BehaviorSubject, Observable} from "rxjs";
import {
  PrintQuarterSettingsWithSubdivisionsComponent,
} from "../print-quarter-settings-with-subdivisions/print-quarter-settings-with-subdivisions.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {
  PrintQuarterSettingsWithSubdivisions, PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {
  Api1StaffUnitTypeControllerService
} from "../../../services/webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";
import {map, take, takeUntil} from "rxjs/operators";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";

@Component({
  selector: 'app-print-quarter-settings-with-subdivisions-and-staffunittypes',
  templateUrl: './print-quarter-settings-with-subdivisions-and-staff-unit-types.component.html',
  styleUrls: ['./print-quarter-settings-with-subdivisions-and-staff-unit-types.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent implements IPrintFormSettingsComponentBase{

  public form: FormGroup<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesFormType>;

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>;
  @Input() public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase<any> {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase<any>){
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection: ArrayDataSourceSelection<ISubdivision, number>;

  //Настройки checkBox TreeView списка подразделений
  private _checkableSettings: CheckableSettings;
  @Input() public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings) {
    this._checkableSettings = value;
  }

  private _displayTextFn: (subdivision: ISubdivision) => string;
  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  @Input() public get displayTextFn(){ return this._displayTextFn; }
  public set displayTextFn(value){ this._displayTextFn = value; }

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  @Output() public print$: EventEmitter<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes> = new EventEmitter<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes>();

  @Output() public cancel$: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("childQuarterSettingsComponent")
  public childSettingsComponent: PrintQuarterSettingsWithSubdivisionsComponent;

  public staffUnitTypes$: Observable<StaffUnitType[]>;
  public innerFormDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly _staffUnitTypeControllerService: Api1StaffUnitTypeControllerService,
              ) {  }

  public ngOnInit() {
    this.form = new FormGroup<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesFormType>(
      { staffUnitTypes: new FormControl<StaffUnitType[]>([], Validators.required) });

    this.staffUnitTypes$ = this._staffUnitTypeControllerService.getAll$()
      .pipe(take(1), takeUntil(this.unsubscribe$),
        map(x => x.filter(s => !s.deletedFlag)));

    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(_ => {
        this.innerFormDisabled$.next(!this.form.valid);
      });
  }

  public onPrint($event: PrintQuarterSettingsWithSubdivisions) {
    this.print$.next(new PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes(
      '', // будет заполнено в обработчике событий диалогового окна
      $event.subPeriodName?.toLowerCase(),
      $event.startDate,
      $event.endDate,
      $event.allSubdivisionsFlag,
      $event.subdivisionOwnerIds,
      this.form.controls.staffUnitTypes.value.map((x: StaffUnitType) => x.id)));

    this.print$.complete();
  }

  public onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

export type PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesFormType = { staffUnitTypes: FormControl<StaffUnitType[]> };
