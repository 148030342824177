import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

/** Валидатор установки параметра выбранных элементов списка (обязательность выбора хотябы одного элемента) */
export function CheckedListItemsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value.length ? {checkedListItemErr: true} : null;
  }
}

/** Валидатор обязательности установки подпериода (номер полугодия, квартала, месяца) во всех случаях, кроме года, тк. у года нет подпериода*/
export function RequiredSubPeriodsValue(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if(control.parent){
      return !!(<FormGroup>control.parent).controls.period.value.id ? null : {notFilling: {i: false}};
    }
    return null;
  }
}
