import { Injectable, OnDestroy } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable, ReplaySubject } from "rxjs";
import { finalize, take, takeUntil } from "rxjs/operators";

/**
 * Сервис отображения индикатора загрузки.
 * Первый зашел - последним вышел.
 */
@Injectable({
  providedIn: "root"
})
export class LoadingIndicatorService implements OnDestroy {
  private unsubscribe$ = new ReplaySubject<any>(1);

  /** Задержка */
  private delay: number = 10;

  /** Хранитель сообщений */
  private messageStorage: MessageStorage = new MessageStorage();

  /** Конструктор */
  constructor() {
  }

  /** Получить текущее сообщение */
  public get currentMessage(): string {
    const lastMessage = this.messageStorage.lastMessage;
    return lastMessage == null ? null : lastMessage[1];
  }

  /** Максимальное значение для chunkProcessBar */
  public get chunkMaxValue(): number {
    return this.messageStorage.countAddedMessage;
  }

  /** Текущее значение для chunkProcessBar */
  public get chunkValue(): number {
    return this.messageStorage.countAddedMessage - this.messageStorage.count;
  }

  /** Добавить сообщение индикатора загрузки */
  public add(message: string): Guid {
    const guid = Guid.create();
    this.messageStorage.add([guid, message]);
    return guid;
  }

  /**
   * Обворачивает долгую функцию
   * Выполнение функции происходит в try finally. Если упадет индикатор загрузки уберется
   */
  public addToFunction(message: string, func: () => void) {
    const messageGuid = this.add(message);
    try {
      func();
    } finally {
      this.remove(messageGuid);
    }
  }

  /**
   * Добавить индикацию к Observable
   * Используется Pipe take(1)
   * Используется Pipe takeUntil, если сервис будет уничтожаться отпишется
   */
  public addToObservable<T>(message: string, observable: Observable<T>): Observable<T> {
    let messageGuid: Guid;
    return new Observable<T>(subscriber => {
      const activeElement = document?.activeElement as HTMLElement; // Получаем элемент на котором сейчас фокус
      activeElement?.blur(); // Сбрасываем фокус с элемента, если он есть

      messageGuid = this.add(message);
      observable.pipe(
          take(1),
          takeUntil(this.unsubscribe$),
          finalize(() => activeElement?.focus()) // Возвращаем фокус на элемент, если он был
        ).subscribe({
          next: value => {
            this.remove(messageGuid);
            subscriber.next(value);
          }, error: error => {
            this.remove(messageGuid);
            subscriber.error(error);
          }, complete: () => {
            this.remove(messageGuid);
            subscriber.complete();
          }
        });
    })
  }

  /**
   * Удалить сообщение по индентификатору
   * @param guid Идентификатор сообщения
   * @param isLastDelay Если сообщение последнее то удаление произойдет с задержкой
   * @param isDelay Принудительно удалить сообщение с задержкой
   */
  public remove(guid: Guid, isLastDelay: boolean = true, isDelay: boolean = false) {
    if ((this.messageStorage.count === 1 && isLastDelay) || isDelay) {
      setTimeout(() => {
        this.messageStorage.remove(guid);
      }, this.delay);
      return;
    }
    this.messageStorage.remove(guid);
  }

  /**
   * Удалить несколько сообщений по идентификатору
   * @param isLastDelay Если будет последний то удалится с задержкой
   * @param guides Список идентификаторов сообщений для удаления
   */
  public removeMany(isLastDelay: boolean = true, ...guides: Guid[]) {
    this._removeMany(guides, isLastDelay);
  }

  /** Очищает все записи и закрывает индикатор */
  public clear(isLastDelay: boolean = true) {
    this._removeMany(this.messageStorage.getAllGuides(), isLastDelay);
  }


  private _enabled = 1;

  /** Отключить отображение */
  public disable(){
    this._enabled -= 1;
  }

  /** Включить отображение */
  public enable(){
    if(this._enabled < 1){
      this._enabled += 1;
    }
  }


  /** Отображается ли индикатор загрузки */
  public get isShow(): boolean {
    return this._enabled > 0 && this.messageStorage.count > 0;
  }

  /**
   * Удалить несколько
   * @param guides список идентификаторов сообщений на удаление
   * @param isLastDelay если последний то будет удаляться с задержкой
   * @private
   */
  private _removeMany(guides: Array<Guid>, isLastDelay: boolean) {
    guides.forEach(guid => {
      this.remove(guid, isLastDelay);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

/** Хранитель сообщений */
class MessageStorage {
  /** Сообщения */
  private messages: Array<[Guid, string]> = new Array<[Guid, string]>();

  /** Хранитель данных о количестве */
  private countStorage: CountStorage = new CountStorage();

  /** Количество сообщений */
  public get count(): number {
    return this.countStorage.count;
  }

  /** Количество добавленных сообщений за сет */
  public get countAddedMessage(): number {
    return this.countStorage.countAdded;
  }

  /** Последнее сообщение */
  public get lastMessage(): [Guid, string] {
    return this.messages.length == 0 ? null : this.messages[this.messages.length - 1];
  }

  /** Добавить */
  public add(value: [Guid, string]) {
    this.countStorage.add();
    this.messages.push(value);
  }

  /**
   * Удалить
   * @return true если найден и удален элемент
   */
  public remove(guid: Guid): boolean {
    const elementIndex = this.findIndex(guid);
    if (elementIndex == -1) {
      return false;
    }

    this.countStorage.remove();
    this.messages = this.messages.filter((_, index) => index !== elementIndex);
    return true;
  }

  /** Получить список Guid всех сообщений */
  public getAllGuides(): Array<Guid> {
    return this.messages.map(item => item[0]);
  }

  /**
   * Получить индекс первого найденого элемента
   * @param guid
   */
  private findIndex(guid: Guid): number {
    return this.messages.findIndex((item) => {
      return guid.equals(item[0]);
    });
  }
}

/** Хранитель количества сообщений */
class CountStorage {
  private _count: number = 0;
  private _countAdded: number = 0;

  /** Добавить */
  public add() {
    this._count++;
    this._countAdded++;
  }

  /** Вычесть */
  public remove() {
    this._count--;
    if (this._count < 0) {
      throw new Error('Хранимое количество меньше нуля')
    }
    if (this._count == 0) {
      this._countAdded = 0;
    }
  }

  /** Количество */
  public get count() {
    return this._count;
  }

  /** Количество добавлений за сет */
  public get countAdded() {
    return this._countAdded;
  }
}
