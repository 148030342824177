import { map, Observable, switchMap } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { IPosition, Position } from "src/app/classes/domain/POCOs/stafflist/Position";
import { IForDate } from "src/app/classes/for-date";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";

export class PositionDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<PositionDataSourceService_Params, IPosition>{

  readonly paramsDataSource = new DataSource<PositionDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<IPosition>();

  constructor(private readonly parentDataSourceService: IPositionDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Position)
      .pipe(
        map(value => value.data),
        map(value => new DataHasOwnerStateBuilder(value, this.dataSource.data2, x => x.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.ownerId)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: PositionDataSourceService_Params): Observable<IPosition[]> {
    return this._reloadFromRemoteByIds$(params, params.positionIds);
  }

  protected _reloadFromRemoteByIds$(params: PositionDataSourceService_Params, targets: IPosition["id"][]): Observable<IPosition[]> {
    return this.parentDataSourceService.getPositionsForDate$(params, targets);
  }
}

export interface PositionDataSourceService_Params extends IForDate{
  positionIds: number[];
}

export interface IPositionDataSourceService_ParentDataSourceService {
  getPositionsForDate$(forDate: IForDate, positionIds: number[]): Observable<IPosition[]>;
}
