import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {IPrintFormSettingsComponentBase} from "../abstractions/IPrintFormSettingsComponentBase";
import {PrintFormForDateSettings} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {DateHelper} from "../../../helpers/dateHelper";

@Component({
  selector: 'app-print-form-for-date-settings',
  templateUrl: './print-form-for-date-settings.component.html',
  styleUrls: ['./print-form-for-date-settings.component.css']
})
export class PrintFormForDateSettingsComponent implements OnInit, OnDestroy, IPrintFormSettingsComponentBase {
  public form: FormGroup;

  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  @Output() print$: EventEmitter<PrintFormForDateSettings> = new EventEmitter<PrintFormForDateSettings>();

  constructor() { }

  ngOnInit(): void {
    this.form = new FormGroup({
      date: new FormControl(DateHelper.getStartOfMounth(new Date(), true), {validators: Validators.required, updateOn: 'blur'})
    });
    this.form.controls.date.markAsTouched();
  }

  onClickOk() {
    this.print$.next(new PrintFormForDateSettings(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.date.value));
    this.print$.complete();
  }

  onClickCancel() {
    this.cancel$.next();
    this.cancel$.complete();
  }

  ngOnDestroy(): void {
  }
}
