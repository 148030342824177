import {NumberHelper} from "./numberHelper";
import * as moment from "moment";

/**
 * Помощник работы с временем
 */
export class DateHelper {
  /**
   * Объект настроек для работы с серверным форматом даты
   * Необходимо инициализировать при старте приложения из AppSettingsService
   */
  public static serverDateSettings: {
    readonly format: string
  } = null;

  /**
   * Перевести часы в минуты
   * @param hour
   */
  public static hoursToMinutes(hour: number): number{
    return hour * 60;
  }

  /**
   * Перевести минуты в часы
   * @param minutes
   * @param digits точность округления. Если < 0 то не округляется
   */
  public static minutesToHours(minutes: number, digits: number): number{
    const hours = minutes / 60;
    return NumberHelper.round(hours, digits);
  }

  /** Конвертировать {@link Date} в строку в серверном формате даты */
  public static toFormat(date: Date, format: string){
    if(!date){
      return undefined;
    }

    return moment(date).format(format);
  }

  /** Конвертировать {@link Date} в строку в серверном формате даты */
  public static toServerFormat(date: Date): string{
    return this.toFormat(date, this.serverDateSettings.format)
  }

  /** Метод парсит дату по переданному формату/фоматам, если не получилось, вернет {@link undefined} */
  public static tryParseByFormat(str: string, format: string | string[]): Date | undefined{
    const m= moment(str, format, true);
    return m.isValid() ? m.toDate() : undefined;
  }

  /** Конвертировать строку в {@link Date}. Бросит ошибку, если строка не содержит дату */
  public static parseByFormat(str: string, format: string | string[]): Date{
    const date = this.tryParseByFormat(str, format);
    if(!date){
      const formatAsStr = Array.isArray(format) ? `[${format.join(', ')}]` : format;
      throw new Error(`Строка не содержит дату в формате ${formatAsStr}`);
    }

    return date;
  }

  /** Метод парсит дату в формате сервера, если не получилось, вернет {@link undefined} */
  public static tryParseServerDate(str: string): Date | undefined{
    return this.tryParseByFormat(str, this.serverDateSettings.format);
  }

  /** Конвертировать строку в серверном формате даты в Date. Бросит ошибку если строка не содержит дату в формате сервера */
  public static parseServerDate(dateAsString: string) : Date{
    return this.parseByFormat(dateAsString, this.serverDateSettings.format)
  }

  /** Получить формат даты сервера */
  public static getServerFormat(): string{
    return this.serverDateSettings.format;
  }

  /**
   * Возвращает дату и время окончания переданного параметром дня
   * @param date
   */
  public static getEndOfDay(date: Date):Date {
    let newVal = moment(date).endOf("day").toDate();
    newVal.setMilliseconds(997); //Именно 997, т.к у SQL идет округление милисекунд в дате (998,999,000 равны между собой). Если поставить 999 то в sql сохранется 000 следующего дня...
    return newVal;
  }

 /**
   * Возвращает дату и время начала переданного параметром дня
   * @param date
   */
  public static getStartOfDay(date: Date):Date {
    return moment(date).startOf("day").toDate();
  }

  /**
   * Возвращает дату и время начала переданного параметром неделя
   * @param date - дата
   * @param isStartOfDay - установить ли на начало дня через функцию: getStartOfDay
   */
  public static getStartOfWeek(date: Date, isStartOfDay: boolean = true): Date {
    const ret = moment(date).startOf('week').toDate();
    return isStartOfDay ? this.getStartOfDay(ret) : ret;
  }

  /**
   * Возвращает дату и время окончания переданного параметром неделя
   * @param date
   * @param isEndOfDay - установить ли на конец дня через функцию: getEndOfDay
   */
  public static getEndOfWeek(date: Date, isEndOfDay: boolean = true): Date {
    const ret = moment(date).endOf('week').startOf('day').toDate();
    return isEndOfDay ? this.getEndOfDay(ret) : ret;
  }

   /**
   * Возвращает дату и время начала переданного параметром месяца
   * @param date - дата
   * @param isStartOfDay - установить ли на начало дня через функцию: getStartOfDay
   */
  public static getStartOfMounth(date: Date, isStartOfDay: boolean = true): Date {
    const ret = moment(date).startOf('month').toDate();
    return isStartOfDay ? this.getStartOfDay(ret) : ret;
  }

   /**
   * Возвращает дату и время окончания переданного параметром месяца
   * @param date
   * @param isEndOfDay - установить ли на конец дня через функцию: getEndOfDay
   */
  public static getEndOfMounth(date: Date, isEndOfDay: boolean = true): Date {
    const ret = moment(date).endOf('month').startOf('day').toDate();
    return isEndOfDay ? this.getEndOfDay(ret) : ret;
  }

  /**
   * Возвращает дату и время начала переданного параметром года
   * @param date - дата
   * @param isStartOfDay - установить ли на начало дня через функцию: getStartOfDay
   */
  public static getStartOfYear(date: Date, isStartOfDay: boolean = true): Date {
    const ret = moment(date).startOf('year').toDate();
    return isStartOfDay ? this.getStartOfDay(ret) : ret;
  }

  /**
   * Возвращает дату и время окончания переданного параметром года
   * @param date
   * @param isEndOfDay - установить ли на конец дня через функцию: getEndOfDay
   */
  public static getEndOfYear(date: Date, isEndOfDay: boolean = true): Date {
    const ret = moment(date).endOf('year').startOf('day').toDate();
    return isEndOfDay ? this.getEndOfDay(ret) : ret;
  }

  /**
   * Функция проверяет пересекается ли один интервал времени с другим
   * @param interval1StartDate дата начала проверяемого интервала
   * @param interval1EndDate  дата окончания проверяемого интервала
   * @param interval2StartDate дата начала интервала
   * @param interval2EndDate дата окончания интервала
   * @returns
   */
  public static isDateIntervalsIntersect(interval1StartDate: Date, interval1EndDate:Date, interval2StartDate: Date, interval2EndDate:Date) {
    if(interval2StartDate && interval2EndDate && (interval1StartDate || interval1EndDate))
  {
    if(interval1StartDate && (interval1StartDate > interval2EndDate) || interval1EndDate && (interval1EndDate < interval2StartDate))
    {
      return false;
    }
  }
    return true;
  }


  /**
   * Функция проверяет ВХОДИТ ли один интервал времени в другой
   * @param mainIntervalStartDate дата начала основного интервала (он является границами вхождения, null = бесконечно)
   * @param mainIntervalEndDate  дата окончания основного интервала (он является границами вхождения, null = бесконечно)
   * @param interval2StartDate дата начала интервала
   * @param interval2EndDate дата окончания интервала
   * @returns
   */
   public static isDateIntervalIncludeAnother(mainIntervalStartDate: Date, mainIntervalEndDate:Date, interval2StartDate: Date, interval2EndDate:Date) {

    if(mainIntervalStartDate || mainIntervalEndDate)
    {
      if((mainIntervalStartDate && interval2StartDate && (interval2StartDate < mainIntervalStartDate)) || (mainIntervalEndDate && interval2EndDate && (interval2EndDate > mainIntervalEndDate)))
      {
        return false;
      }
    }
      return true;
  }

  /**
   * Получить максимальную дату из переданных дат.
   * @param @param dateArr массив дат. Если элемент !{@link Date}, то данный элемент не будет участвовать.
   * @param defaultConverter данная функция будет вызвана для конвертации элемента !{@link Date} в {@link Date}. Тем самым !{@link Date} будет участвовать в анализе
   * @return {@link Date} если есть хоть один !!{@link Date}
   */
  public static getMaxDateFromDateArray(dateArr: Array<Date>, defaultConverter: () => Date = undefined): Date | undefined {
    defaultConverter = !defaultConverter ? () => undefined : defaultConverter;

    let max: number = undefined;

    for (let date of dateArr) {
      if(!date){
        date = defaultConverter();

        if(!date){
          continue;
        }
      }

      const dateAsNum = +date;

      if(max === undefined){
        max = dateAsNum;
        continue;
      }

      if(max < dateAsNum){
        max = dateAsNum;
      }
    }

    return max === undefined ? undefined : new Date(max);
  }

  /**
   * Получить минимальную из переданных дат.
   * @param dateArr массив дат. Если элемент !{@link Date}, то данный элемент не будет участвовать.
   * @param defaultConverter данная функция будет вызвана для конвертации элемента !{@link Date} в {@link Date}. Тем самым !{@link Date} будет участвовать в анализе
   * @return {@link Date} если есть хоть один !!{@link Date}
   */
  public static getMinDateFromDateArray(dateArr: Array<Date> , defaultConverter: () => Date = undefined) : Date | undefined {
    defaultConverter = !defaultConverter ? () => undefined : defaultConverter;

    let min: number = undefined;

    for (let date of dateArr) {
      if(!date){
        date = defaultConverter();

        if(!date){
          continue;
        }
      }

      const dateAsNum = +date;

      if(min === undefined){
        min = dateAsNum;
        continue;
      }

      if(min > dateAsNum){
        min = dateAsNum;
      }
    }

    return min === undefined ? undefined : new Date(min);
  }

  /** Получить значение или если null, то минимальное возможное */
  public static valueOrMin(date: Date | null){
    return date ? date : new Date(0);
  }

  /** Получить значение или если null, то максимальное возможное */
  public static valueOrMax(date: Date | null){
    return date ? date : new Date(8640000000000000);
  }

  /** Входит ли переданная дата в диапазон */
  public static isExistInRange(date: Date, rangeStart: Date | null, rangeEnd: Date | null): boolean{
    if(!date){
      throw new Error('НЕ допускается передача null')
    }

    if(rangeStart && rangeEnd && rangeStart > rangeEnd){
      throw new Error('Дата начала не может быть больше даты окончания')
    }

    return +this.valueOrMin(rangeStart) <= +date && +this.valueOrMax(rangeEnd) >= +date;
  }

  /** Равны ли две даты */
  public static equals(date1: Date | null, date2: Date | null){
    if(!date1 && !date2){
      return true;
    }

    if(!date1 || !date2){
      return false;
    }

    return +date1 === +date2;
  }

  /** Получить разницу между двумя датами */
  public static subtractDates(startDate: Date, endDate: Date): SubtractDatesResult {

    // Преобразуем строки в объекты moment
    const start = moment(startDate);
    const end = moment(endDate);

    // Проверяем корректность дат
    if (!start.isValid() || !end.isValid()) {
        throw new Error('Invalid dates');
    }

    // Вычисляем полную разницу между датами
    const diffYears = end.diff(start, 'years');
    start.add(diffYears, 'years');

    const diffMonths = end.diff(start, 'months');
    start.add(diffMonths, 'months');

    const diffDays = end.diff(start, 'days');
    start.add(diffDays, 'days');

    const diffHours = end.diff(start, 'hours');
    start.add(diffHours, 'hours');

    const diffMinutes = end.diff(start, 'minutes');
    start.add(diffMinutes, 'minutes');

    const diffSeconds = end.diff(start, 'seconds');
    start.add(diffSeconds, 'seconds');

    const diffMilliseconds = end.diff(start, 'milliseconds');

    // Возвращаем результат в виде объекта
    return new SubtractDatesResult(
      diffYears,
      diffMonths,
      diffDays,
      diffHours,
      diffMinutes,
      diffSeconds,
      diffMilliseconds
    );
  }

  /** Является ли объект {@link Date} */
  public static isDate(obj: any): obj is Date{
    return obj instanceof Date;
  }
}

export class SubtractDatesResult{
  constructor(
    public years: number,
    public months: number,
    public days: number,
    public hours: number,
    public minutes: number,
    public seconds: number,
    public milliseconds: number
  ){}
}
