<form class="k-form" [formGroup]="form" *ngIf="form">

  <div class="form-start-end-date-div">
    <kendo-formfield>
      <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
      <kendo-datepicker #startDate [formControlName]="'startDate'"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
      <kendo-datepicker #endDate [formControlName]="'endDate'" [min]="form.controls.startDate.value"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="name" text="Наименование режима работы"></kendo-label>
    <input kendoTextBox inputTrim #name [formControlName]="'name'" />
    <kendo-formerror *ngIf="form.controls.name.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.minlength">Длина значения должна быть больше {{form.controls.name.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.maxlength">Длина значения должна быть меньше {{form.controls.name.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
  <kendo-label [for]="workModeTypeId" text="Тип режима рабочего времени"></kendo-label>
    <kendo-dropdownlist
      #workModeTypeId
      [data]="workModeTypesDropDownItems"
      textField="text"
      valueField="id"
      [valuePrimitive]="true"
      [formControlName]="'workModeTypeId'"
    >
    </kendo-dropdownlist>
    <kendo-formerror *ngIf="form.controls.name.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment [formControlName]="'comment'" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield class="horizontal-form-field">
    <kendo-label [for]="arrangedForWeekFlag" text="Начало периода совпадает с началом недели:"></kendo-label>
    <input type="checkbox" kendoCheckBox #arrangedForWeekFlag [formControlName]="'arrangedForWeekFlag'" />
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="workingDays" text="Рабочих дней"></kendo-label>
    <kendo-numerictextbox #workingDays [decimals]="0" [format]="'n0'" [formControlName]="'workingDays'"></kendo-numerictextbox>
    <kendo-formerror *ngIf="form.controls.workingDays.errors?.min">Значение не может быть меньше {{form.controls.workingDays.errors?.min.min}}. Текущее значение: {{form.controls.workingDays.errors?.min.actual}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.workingDays.errors?.max">Значение не может быть больше {{form.controls.workingDays.errors?.max.max}}. Текущее значение: {{form.controls.workingDays.errors?.max.actual}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="workdayHourDuration" text="Продолжительность рабочего дня (ч.)"></kendo-label>
    <kendo-numerictextbox #workdayHourDuration [decimals]="1" [format]="'n1'" [step]="0.1" [formControlName]="'workdayHourDuration'"></kendo-numerictextbox>
    <kendo-formerror *ngIf="form.controls.workdayHourDuration.errors?.min">Значение не может быть меньше {{form.controls.workdayHourDuration.errors?.min.min}}. Текущее значение: {{form.controls.workdayHourDuration.errors?.min.actual}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.workdayHourDuration.errors?.max">Значение не может быть больше {{form.controls.workdayHourDuration.errors?.max.max}}. Текущее значение: {{form.controls.workdayHourDuration.errors?.max.actual}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="preHolidayHourDuration" text="Продолжительность предпраздничного дня (ч.)"></kendo-label>
    <kendo-numerictextbox #preHolidayHourDuration [decimals]="1" [format]="'n1'" [step]="0.1" [formControlName]="'preHolidayHourDuration'"></kendo-numerictextbox>
    <kendo-formerror *ngIf="form.controls.preHolidayHourDuration.errors?.min">Значение не может быть меньше {{form.controls.preHolidayHourDuration.errors?.min.min}}. Текущее значение: {{form.controls.preHolidayHourDuration.errors?.min.actual}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.preHolidayHourDuration.errors?.max">Значение не может быть больше {{form.controls.preHolidayHourDuration.errors?.max.max}}. Текущее значение: {{form.controls.preHolidayHourDuration.errors?.max.actual}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

