import {Injectable} from "@angular/core";
import {DateSerializerToServerFormatService} from "../date-serializer-services/date-deep-serializer.service";
import {
  TimeOnlySerializerToServerFormatService
} from "../time-only-serializer-services/time-only-deep-serializer.service";
import {ObjectDeepSerializerServiceBase} from "../object-deep-serializer-service-base";
import {ObjectHelper} from "../../../helpers/objectHelper";
import {
  DateOnlySerializerToServerFormatService
} from "../date-only-serializer-services/date-only-deep-serializer.service";

@Injectable({
  providedIn: 'root'
})
export class DeepSerializerForServerService implements Pick<ObjectDeepSerializerServiceBase<any>, 'deserialize' | 'serialize'>{

  constructor(public readonly dateSerializerToServerFormatService: DateSerializerToServerFormatService,
              public readonly timeOnlySerializerToServerFormatService: TimeOnlySerializerToServerFormatService,
              public readonly dateOnlySerializerToServerFormatService: DateOnlySerializerToServerFormatService) {
  }

  public deserialize(obj: any) {
    return ObjectHelper.deepReplaceValue(
      obj,
      (value, type) => {
        if(type !== 'string'){
          return undefined;
        }

        const date = this.dateSerializerToServerFormatService.tryParse(value);
        if(date !== undefined){
          return date;
        }

        const dateOnly = this.dateOnlySerializerToServerFormatService.tryParse(value);
        if(dateOnly !== undefined){
          return dateOnly;
        }

        return this.timeOnlySerializerToServerFormatService.tryParse(value);
      }
    )
  }

  public serialize(obj: any) {
    return ObjectHelper.deepReplaceValue(
      obj,
      (value, type) => {

        if(this.timeOnlySerializerToServerFormatService.isType(value)){
          return this.timeOnlySerializerToServerFormatService.typeToString(value);
        }

        if(this.dateOnlySerializerToServerFormatService.isType(value)){
          return this.dateOnlySerializerToServerFormatService.typeToString(value);
        }

        if(this.dateSerializerToServerFormatService.isType(value)){
          return this.dateSerializerToServerFormatService.typeToString(value);
        }

        return undefined;
      }
    )
  }
}
