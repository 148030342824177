import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICode } from 'src/app/classes/domain/POCOs/timesheet_table/Code';
import { OccupationsDirectoryGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-occupations-directory-grid-control-controller.service';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { IOccupationOptional, Occupation } from 'src/app/classes/domain/POCOs/stafflist/Occupation';
import { CustomFormValidators } from '../../../../../../../src/app/validators/custom-form-validators.class';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { DataSource } from '../../../../../../../src/app/classes/array-data-sources/data-source';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../src/app/services/data-source-services/data-source.service';
import { map, Observable, startWith, switchMap } from 'rxjs';
import { IOccupationType } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/OccupationType';

@Component({
  selector: 'app-add-occupation-type-form',
  templateUrl: './add-occupation-form.component.html',
  styleUrls: ['./add-occupation-form.component.css'],
  providers: [],
})
@traceClass('AddOccupationFormComponent')
export class AddOccupationFormComponent implements OnInit {

  @Input('dataSource') public dataSource: AddOccupationFormComponent_DataSourceService;
  @Input('dataItem') public dataItem: OccupationsDirectoryGridItem;

  @Output('onSave') public onSaveEventEmitter = new EventEmitter<IOccupationOptional>();
  @Output('onCancel') public onCancelEventEmitter = new EventEmitter<boolean>();

  public codes$: Observable<ICode[]>;
  public occupationTypes$: Observable<IOccupationType[]>;
  public occupationCodeLength$: Observable<number>;
  public form: FormGroup<IAddOccupationFormComponentForm>;

  constructor(private traceService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.codes$ = this.dataSource.data$
      .pipe(
        switchMap(data => data.codes.reloadData$({ codeIds: null })),
      )
      .pipe(
        switchMap(s => s.data$),
        startWith([]),
      );

    this.occupationTypes$ = this.dataSource.data$
      .pipe(
        switchMap(data => data.occupationType.reloadData$({ occupationTypeIds: null })),
      )
      .pipe(
        switchMap(s => s.data$),
        startWith([]),
      );

    this.occupationCodeLength$ = this.dataSource.data$.pipe(map(data => data.occupationCodeLength));

    this.form = this.createForm(this.dataItem);
  }

  @traceFunc()
  public onClickSave() {
    this.onSaveEventEmitter.emit(this.form.value);
  }

  @traceFunc()
  public onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  /** Создание формы добавления */
  @traceFunc()
  private createForm(item: OccupationsDirectoryGridItem) {
    const form = new FormGroup({
      startDate: new FormControl(item?.startDate ?? null, [Validators.required, CustomFormValidators.validateStartDateWithEndDate('endDate')]),
      endDate: new FormControl(item?.endDate ?? null, [CustomFormValidators.validateEndDateWithStartDate('startDate')]),
      code: new FormControl(item?.code ?? '', [this.codeValidator]),
      name: new FormControl(item?.name ?? '', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      comment: new FormControl(item?.comment ?? '', Validators.maxLength(100)),
      occupationTypeId: new FormControl(item?.occupationTypeId ?? null, [Validators.required]),
      isAutogenerateCode: new FormControl(true),
      codesIdsArray: new FormControl(item?.codes.map(c => c.id) ?? null),
    });

    form.markAllAsTouched();
    form.valueChanges.subscribe(value => {
      this.traceService.add2(`Изменена форма добавления должности`, { obj: value });
    });
    return form;
  }

  /** Кастомный валидатор кода должности */
  private codeValidator(codeControl: AbstractControl): { [s: string]: boolean } | null {
    if (!codeControl.parent) return null;
    if (codeControl.parent.value.isAutogenerateCode !== true) {
      return Validators.required(codeControl);
    }
    return null;
  }
}


export interface IAddOccupationFormComponentForm extends PropertyWrapFormControlType<Pick<Occupation, 'startDate' | 'endDate' | 'code' | 'name' | 'comment' | 'occupationTypeId'>> {
  /** Генерировать код должности автоматически */
  isAutogenerateCode: FormControl<boolean>;
  /** Коды табельного учёта */
  codesIdsArray: FormControl<number[]>;
}

export class AddOccupationFormComponent_DataSourceService extends DataSource<AddOccupationFormComponent_Data> {
}

export class AddOccupationFormComponent_Data {
  constructor(public readonly codes: ArrayDataSourceIEntityIdServiceWithParamsBase<any, ICode>,
              public readonly occupationType: ArrayDataSourceIEntityIdServiceWithParamsBase<any, IOccupationType>,
              public readonly occupationCodeLength: number) {
  }
}
