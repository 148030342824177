import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { VersioningItem } from 'src/app/classes/directories/edit/versioningItem';
import { Employee } from 'src/app/classes/domain/POCOs/stafflist/Employee';
import { AddressData } from 'src/app/classes/extendedDatas/employeeExtended/address-data';
import { InnData } from 'src/app/classes/extendedDatas/employeeExtended/inn-data';
import { PassportData } from 'src/app/classes/extendedDatas/employeeExtended/passport-data';
import { PhoneData } from 'src/app/classes/extendedDatas/employeeExtended/phone-data';
import { SnilsData } from 'src/app/classes/extendedDatas/employeeExtended/snils-data';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { AddEmployeeDTO, Api1EmployeeControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-employee-controller.service';
import { Api1EmployeeExtendedControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-employee-extended-controller.service';
import { Api1EmployersGridControlControllerService, EmployeeItem } from 'src/app/services/webApi/webApi1/controllers/api1-employers-grid-control-controller.service';
import { WebApi1Service } from 'src/app/services/webApi/webApi1/web-api1.service';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { IExtendedData } from '../../../../../../../../src/app/classes/extendedDatas/extended-data';
import { IForDate } from '../../../../../../../../src/app/classes/for-date';
import { DataSource, ArrayDataSourceIEntityId } from 'src/app/classes/array-data-sources/data-source';
import { DataHasOwnerStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-has-owner-state-builder';
import { DataStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-state-builder';
import { EmployeeExtended } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/EmployeeExtended';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { EmployeeDirectoryItem } from '../employees-grid.component';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

/** Базовый класс сервиса источника данных */
@Injectable()
export abstract class EmployeesGridComponentDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, EmployeeDirectoryItem> {
  abstract getDownloadTemplateUrl(): string;

  abstract getUploadFileUrl(): string;

  abstract addEmployeeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>;

  abstract deleteVersion$(versionId: number): Observable<boolean>;

  abstract getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]>;

  abstract editEmployeeVersion$(actionId: SupportedActionEnum, entity: VersioningItem): Observable<VersioningItem>;

  abstract saveEmployee$(entity): Observable<[]>;

  abstract addEmployee$(entity: AddEmployeeDTO): Observable<Employee>;

  abstract deleteEmployee$(entity: Pick<Employee, 'id' | 'timestamp'>): Observable<boolean>;

  abstract addAddressData$(address: string, employeeId: number): Observable<IExtendedData<AddressData>>;

  abstract saveAddressData$(address: string, employeeId: number, timestamp: []): Observable<IExtendedData<AddressData>>;

  abstract addInnData$(inn: string, employeeId: number): Observable<IExtendedData<InnData>>;

  abstract saveInnData$(inn: string, employeeId: number, timestamp: []): Observable<IExtendedData<InnData>>;

  abstract addSnilsData$(snils: string, employeeId: number): Observable<IExtendedData<SnilsData>>;

  abstract saveSnilsData$(snils: string, employeeId: number, timestamp: []): Observable<IExtendedData<SnilsData>>;

  abstract addPhoneData$(phone: string, employeeId: number): Observable<IExtendedData<PhoneData>>;

  abstract savePhoneData$(phone: string, employeeId: number, timestamp: []): Observable<IExtendedData<PhoneData>>;

  abstract addPassportData$(passportData: PassportData, employeeId: number): Observable<IExtendedData<PassportData>>;

  abstract savePassportData$(passportData: PassportData, employeeId: number, timestamp: []): Observable<IExtendedData<PassportData>>;

  abstract editEndDateEmployee$(entity: EmployeeDirectoryItem, isSave: boolean): Observable<boolean>;
}

@Injectable()
export class EmployeesGridComponentDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, EmployeeDirectoryItem> implements EmployeesGridComponentDataSourceServiceBase {
  public readonly dataSource = new ArrayDataSourceIEntityId<EmployeeDirectoryItem>();
  public readonly paramsDataSource = new DataSource<IForDate>();

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1EmployersGridControlControllerService: Api1EmployersGridControlControllerService,
              private readonly api1EmployeeExtendedControllerService: Api1EmployeeExtendedControllerService,
              private readonly api1EmployeeControllerService: Api1EmployeeControllerService,
              private readonly webApi1Service: WebApi1Service,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  public getDownloadTemplateUrl(): string {
    return this.webApi1Service.controllers.importEmployeeExtendedData.actions.downloadImportTemplate.toString();
  }

  public getUploadFileUrl(): string {
    return this.webApi1Service.controllers.importEmployeeExtendedData.actions.importData.toString();
  }

  public addEmployeeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.api1EmployeeControllerService.addEmployeeVersion$(actionId, versioningEntity);
  }

  public deleteVersion$(versionId: number): Observable<boolean> {
    return this.api1EmployeeControllerService.deleteVersion$(versionId);
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]> {
    return this.api1EmployeeControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }

  public editEmployeeVersion$(actionId: SupportedActionEnum, entity: VersioningItem): Observable<VersioningItem> {
    return this.api1EmployeeControllerService.editEmployeeVersion$(actionId, entity);
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
        employee: Employee,
        employeeExtended: EmployeeExtended,
      })
      .pipe(
        map(value => value.data),
        map(value => ({
          employee: new DataHasOwnerStateBuilder(value.employee, this.dataSource.data2, x => x.id).build_().source.map(x => x.signalR.currentOrOrigin.ownerId),
          employeeExtended: new DataStateBuilder(value.employeeExtended, this.dataSource.data2, (x, y) => x.employeeId === y.id).build_().source
            .filter(x => x.dataItem)
            .map(x => x.dataItem.id),
        })),
        map(value => ([...value.employee, ...value.employeeExtended])),
        map(value => this.reloadFromSignalR$(value)),
      );
  }

  public saveEmployee$(entity): Observable<[]> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение сотрудника', this.api1EmployeeControllerService.save$(entity)
    );
  }

  public addEmployee$(entity: AddEmployeeDTO): Observable<Employee> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление сотрудника',
      this.api1EmployeeControllerService.add$(entity),
    );
  }

  public deleteEmployee$(entity: Pick<Employee, 'id' | 'timestamp'>): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      'Удаление сотрудника',
      this.api1EmployeeControllerService.deleteEmployee$(entity.id, entity.timestamp),
    );
  }

  public addAddressData$(address: string, employeeId: number): Observable<IExtendedData<AddressData>> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление адреса',
      this.api1EmployeeExtendedControllerService.addAddressData$(new AddressData(address), employeeId),
    );
  }

  public saveAddressData$(address: string, id: number, timestamp: []): Observable<IExtendedData<AddressData>> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение адреса',
      this.api1EmployeeExtendedControllerService.saveAddressData$({ id, timestamp, data: new AddressData(address) }),
    );
  }

  public addInnData$(inn: string, employeeId: number): Observable<IExtendedData<InnData>> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление ИНН',
      this.api1EmployeeExtendedControllerService.addInnData$(new InnData(inn), employeeId),
    );
  }

  public saveInnData$(inn: string, id: number, timestamp: []): Observable<IExtendedData<InnData>> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение ИНН',
      this.api1EmployeeExtendedControllerService.saveInnData$({ data: new InnData(inn), id, timestamp }),
    );
  }

  public addSnilsData$(snils: string, employeeId: number): Observable<IExtendedData<SnilsData>> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление СНИЛС',
      this.api1EmployeeExtendedControllerService.addSnilsData$(new SnilsData(snils), employeeId),
    );
  }

  public saveSnilsData$(snils: string, id: number, timestamp: []): Observable<IExtendedData<SnilsData>> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение СНИЛС',
      this.api1EmployeeExtendedControllerService.saveSnilsData$({ id, timestamp, data: new SnilsData(snils) }),
    );
  }

  public addPhoneData$(phone: string, employeeId: number): Observable<IExtendedData<PhoneData>> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление телефона',
      this.api1EmployeeExtendedControllerService.addPhoneData$(PhoneData.getFromPhoneValue(phone), employeeId),
    );
  }

  public savePhoneData$(phone: string, id: number, timestamp: []): Observable<IExtendedData<PhoneData>> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение телефона',
      this.api1EmployeeExtendedControllerService.savePhoneData$({ id, timestamp, data: PhoneData.getFromPhoneValue(phone) }),
    );
  }

  public addPassportData$(passportData: PassportData, employeeId: number): Observable<IExtendedData<PassportData>> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление паспортных данных',
      this.api1EmployeeExtendedControllerService.addPassportData$(passportData, employeeId),
    );
  }

  public savePassportData$(passportData: PassportData, id: number, timestamp: []): Observable<IExtendedData<PassportData>> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение паспортных данных',
      this.api1EmployeeExtendedControllerService.savePassportData$({ id, timestamp, data: passportData }),
    );
  }

  public getForDate$(params: IForDate, ownerIds?: number[]): Observable<EmployeeDirectoryItem[]> {
    return this.api1EmployersGridControlControllerService.getForDate$(params.forDate, params.startDate, params.endDate, ownerIds)
      .pipe(map(v => v.map(v => ({
          ...v,
          state: this.getEmployeeStateText(v),
          passportsString: this.getEmployeePassportsText(v),
          addressString: AddressData.getAsString(v.address?.data),
          innString: InnData.getAsString(v.inn?.data),
          snilsString: SnilsData.getAsString2(v.snils?.data),
          phoneString: PhoneData.getAsString2(v.phone?.data),
          fullName: Employee.fullName(v),
        })),
      ));
  }

  public editEndDateEmployee$(entity: EmployeeDirectoryItem, isSave: boolean): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable("Сохранение даты окончания",this.api1EmployeeControllerService.editEndDate$(entity.endDate, [{id: entity.id, timestamp: entity.timestamp}], isSave));
  }

  private getEmployeeStateText(employee: EmployeeItem): '' | 'Оформлен' | 'Не оформлен' {
    if (!employee) return '';

    return employee.isWork ? 'Оформлен' : 'Не оформлен';
  }

  private getEmployeePassportsText(employee: EmployeeItem): string {
    if (!employee?.passports) return '';

    return employee?.passports?.map(p => PassportData.getAsString(p.data)).join('\n');
  }

  protected _reloadData$(params: IForDate): Observable<EmployeeDirectoryItem[]> {
    return this.loadingIndicatorService.addToObservable(
      'Обновление списка сотрудников',
      this.getForDate$(params),
    );
  }

  protected _reloadFromRemoteByIds$(params: IForDate, targets: number[]): Observable<EmployeeDirectoryItem[]> {
    return this.getForDate$(params, targets);
  };

}
