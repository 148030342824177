import * as moment from 'moment';

export class PassportData {
  constructor(
    public passportType: PassportType,
    public seria: string,
    public number: string,
    public issueDate: Date,
    public whom: string,
    public divisionCode: string
  ) {}

  static getAsString(pd: PassportData): string {
    return `Паспорт РФ Серия: ${pd.seria} Номер: ${pd.number} Дата выдачи: ${moment(pd.issueDate).format("DD.MM.YYYY")} Выдан: ${pd.whom} Код подразделения: ${pd.divisionCode}`;
  }
}

export enum PassportType {
  RussianFederation = 1
}

