import {ITraceClassSettings, ITraceFuncSettings, ITraceParamSettings} from "./traceSetting.interface";
import {getDisabled} from "./functions";

/** Интерфейс объектов содержащих метаданные класса(декоратор traceClass) */
export interface IHasTraceClassMetadata{
  /** Метаданные класса */
  traceClassMetadata: TraceClassMetadata
}

/** Цель метаданных трассировки */
export type TraceMetadataTarget = 'class' | 'func' | 'param';

/** Интерфейс метаданных трассировки */
export interface ITraceMetadata{
  /** Цель метаданных */
  readonly metadataTarget: TraceMetadataTarget;
}

/** Объект метаданных для декоратора класса */
export class TraceClassMetadata implements ITraceMetadata{
  readonly metadataTarget = 'class';

  /**
   * Конструктор
   * @param name Название класса
   * @param constructorName Название функции-конструктора
   * @param settings Настройки трассировки класса
   * @param constructorTraceParamMetadata Метаданные декоратора traceParam для конструктора класса
   */
  constructor(
    public name: string,
    public constructorName: string,
    public settings: ITraceClassSettings,
    public constructorTraceParamMetadata: Array<TraceParamMetadata>
  ) {
  }

  /** Отключен ли декоратор */
  public get isDisabled(): boolean{
    return getDisabled(this.settings);
  }

  /** Получить метаданные НЕ отключенных параметров конструктора */
  public get notDisabledTraceParamMetadata(): Array<TraceParamMetadata>{
    return this.constructorTraceParamMetadata.filter(x => !x.isDisabled)
  }
}

/** Объект метаданных для декоратора функции */
export class TraceFuncMetadata implements ITraceMetadata, IHasTraceClassMetadata{
  readonly metadataTarget = 'func';

  public traceClassMetadata: TraceClassMetadata;

  /**
   * Конструктор
   * @param name Название функции
   * @param settings Настройки трассировки функции
   */
  constructor(
    public name: string,
    public settings: ITraceFuncSettings
  ) {
  }

  /** Отключена ли трассировка функции */
  public get isDisable(){
    if(this.traceClassMetadata.isDisabled){
      return true;
    }

    return getDisabled(this.settings);
  }
}

/** Объект метаданных для декоратора параметра */
export class TraceParamMetadata implements ITraceMetadata{
  readonly metadataTarget = 'param';
  /**
   * Конструктор
   * @param propertyKey Название функции к которой относится параметр
   * @param parameterIndex Индекс параметра
   * @param settings Настройки декоратора переданные при инициализации
   */
  constructor(
    public propertyKey: string,
    public parameterIndex: number,
    public settings: ITraceParamSettings,
  ) {
  }

  /** Отключен ли декоратор */
  public get isDisabled(): boolean{
    return getDisabled(this.settings);
  }
}
