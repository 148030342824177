import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { DropDownTreeFieldControl_Settings } from "../../services/classes/field-controls/dropdowntree-field-control";
import { IDropDownTreeDirectoryEditComponent } from "./i-dropdowntree-directory-edit-control.component";
import { DropDownTreeDirectoryEditComponentService } from "./services/dropdown-directory-edit-control-component.service";
import { FieldType } from '../../services/classes/fields/field-type';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-dropdowntree-directory-edit-control',
  templateUrl: './dropdowntree-directory-edit-control.component.html',
  styleUrls: ['./dropdowntree-directory-edit-control.component.css'],
  providers: [DropDownTreeDirectoryEditComponentService]
})
@traceClass('DropDownTreeDirectoryEditComponent')
export class DropDownTreeDirectoryEditComponent implements OnInit, IDropDownTreeDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: DropDownTreeFieldControl_Settings;
  @Input("field") public field: FieldType;
  @Input("appendContainer") public appendContainer: ViewContainerRef;

  public data$: Observable<any[]>;
  public dataItem$: Observable<any>;
  public valueField: string;
  public popupSettings: PopupSettings;

  constructor(private service: DropDownTreeDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
    this.service.addValidators();
  }
}
