import { Component, Input, Output } from "@angular/core";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { Subject } from "rxjs";
import { PassportData } from "src/app/classes/extendedDatas/employeeExtended/passport-data";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { IValueResult } from "../../services/edit-passport-data-component-form.service";

@Component({
  selector: 'edit-passport-data-dialog',
  templateUrl: 'edit-passport-data-dialog.component.html',
  styleUrls: ['edit-passport-data-dialog.component.css'],
})
@traceClass('EditPassportDataDialogComponent')
export class EditPassportDataDialogComponent extends DialogContentBase {

  /** Паспортные данные */
  @Input() data: PassportData;

  @Output("onDataChanged$") onDataChanged$ = new Subject<IValueResult>();

  public isCanSaveOrAdd: boolean;

  constructor(public dialog: DialogRef, private tracerService: TracerServiceBase) {
    super(dialog);
  }

  @traceFunc()
  public onCancelAction(): void {
    this.dialog.close();
  }

  @traceFunc()
  public onConfirmAction(): void {
    this.dialog.close({text:"Сохранить", primary: true});
  }

  @traceFunc()
  public onPassportDataChange(result: IValueResult) {
    this.isCanSaveOrAdd = result.isValid;
    this.onDataChanged$.next(result);
  }

}
