import {Component, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectableSettings} from '@progress/kendo-angular-grid';
import { Code } from 'src/app/classes/domain/POCOs/timesheet_table/Code';
import { process,  State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-codes-grid',
  templateUrl: './codes-grid.component.html',
  styleUrls: ['./codes-grid.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodesGridComponent),
    multi: true
   }]
})
export class CodesGridComponent implements ControlValueAccessor  {

  @Input('height') public height: number;

    @Input("data") public set data(value: Array<Code>) {
      this._data = this.getWitnSortAsc(value);
      this.gridView = value;
      this._permanentSelectedIds = this._data.filter(d => d.isComputedFlag).map(d=> d.id);
    }

    public get data() : Array<Code> {
      return this._data;
    }

    private _data: Array<Code> = null;

    public gridView: Array<Code>;

    /*
      Настройки селекта
    */
    public selectableSettings: SelectableSettings = {
      mode: "multiple",
      checkboxOnly: true
    };

    private _permanentSelectedIds: Array<number> = [];
    private _selectedIds: Array<number> = [];

    public get selectedIds(): Array<number> {
      return this._selectedIds;
    }

    public gridState:State = {
    };

    public set selectedIds(value: Array<number>) {
      const selectedValues = [...value, ...this._permanentSelectedIds];

      //get distinct array
      const distinct = [...new Set(selectedValues)];
      this._selectedIds = distinct;
      this.onChange(this._selectedIds);
    }


    /**
     * Получить данные с сортировкой по возрастанию
     * @param data
     * @returns
     */
    getWitnSortAsc(data:any[]) {
      return data.sort((one, two) => (one.key < two.key ? -1 : 1));
    }


  /**
   * Функция фильтрации поиска, для грида
   */
  public filterData(value:any): void {
    if(!this.data) return;

    const inputValue = value;

    this.gridState.filter = {
        logic: "or",
        filters: [
            {
                field: 'key',
                operator: 'contains',
                value: inputValue
            },
            {
                field: 'name',
                operator: 'contains',
                value: inputValue
            }
        ],
    };
    this.gridView = process(this._data, this.gridState).data;
}



    onChange(_: any) {}

    constructor( ) {

    }

    writeValue(obj: any): void {
      this.selectedIds = obj ?? [];
    }

    registerOnChange(fn: any): void {
      this.onChange = fn;

      //Вызываем событие изменения
      this.onChange(this._selectedIds);
    }

    registerOnTouched(fn: any): void {

    }

    setDisabledState?(isDisabled: boolean): void {

    }

    ngOnInit() {
      this.init();
    }

    init() {
      if(!this.data) {
        throw Error("Свойство [data] обязательно для заполнения для компонента TableCodesGridComponent");
      }
    }

    ngOnDestroy() {

    }
}
