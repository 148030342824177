import {DateMap} from "../classes/maps/date-maps/date-map";
import {ObjComparer} from "../classes/object-comparers/object-comparer";

/**
 * Тип результата сравнения элементов.<br>
 * added - добавлен
 * deleted - удален
 * modified - изменен
 * unchanged - без изменений
 */
type ArrayItemComparerResultType = 'added' | 'deleted' | 'modified' | 'unchanged';

/** Класс помощник для работы с массивами */
export class ArrayHelper {

  /**
   * Просуммировать выбраные элементы массива
   * @param array массив
   * @param keySelector селекторэлемента, который нужно суммировать
   * @returns Возвращает сумму выбранных элементов массива
   */
  public static sumBy<T>(array: Array<T>,  keySelector: (item: T) => number): number {
    return array.reduce((p, c) => p += keySelector(c), 0);
  }

  /** Получить плоский массив из массива массивов */
  public static flatMap<T>(array: Array<T[]>):T[] {
    const len = this.sumBy(array, k=> k.length);
    const retArray = new Array<T>(len);
    let index = 0;
    array.forEach(arr => {
      arr.forEach(i => {
        retArray[index] = i;
        index++;
      });
    });
    return retArray;
  }

  /** Метод позволяет выполнить {@link ArrayHelper.flatMap} с возможностью указать как получить вложенный массив из элемента */
  public static flatMapBy<TOutItem, TInnerItem>(array: TOutItem[], selector: (out: TOutItem) => TInnerItem[]): TInnerItem[]
  public static flatMapBy<TOutItem, TInnerItem, TResultItem>(array: TOutItem[], selector: (out: TOutItem) => TInnerItem[], resultSelector: (outItem: TOutItem, innerItem: TInnerItem, index: number, outIndex: number, innerIndex: number) => TResultItem): TResultItem[]
  public static flatMapBy(array: any[], selector: (Item: any) => any[], resultSelector: (outItem: any, innerItem: any, index: number, outIndex: number, innerIndex: number) => any = undefined): any[]{
    resultSelector = resultSelector
      ? resultSelector
      : (outItem, innerItem, index, outIndex, innerIndex) => innerItem;

    const len = this.sumBy(array, k => selector(k).length);
    const retArray = new Array<any>(len);
    let index = 0;
    array.forEach((outItem, outIndex) => {
      selector(outItem).forEach((innerItem, innerIndex) => {
        retArray[index] = resultSelector(outItem, innerItem, index, outIndex, innerIndex);
        index++;
      });
    });
    return retArray;
  }

  /**
   * Оставляет в массиве только отличающиеся элементы
   * @param array массив
   */
  public static distinct<T, TKey>(array: Array<T>): Array<T>{
    return [...new Set<T>(array).keys()];
  }

  /**
   * Оставляет в массиве только элементы, с уникальными ключами
   * @param array массив
   * @param keySelector функция селектор, по ней определяется равенство объектов
   * @param groupSelector функция получения элемента из группы. По умолчанию возвращает первый элемент
   */
  public static distinctBy<T, TKey>(array: Array<T>, keySelector: (item: T) => TKey, groupSelector: (group: T[]) => T = (x => x[0])): Array<T>{
    return this.groupBy(array, keySelector, (key, items) => groupSelector(items));
  }

  /**
   * Группирует массив по функции селектору
   * @param array - массив
   * @param keySelector - функция получения ключа
   */
  public static groupBy<T, TKey>(array: Array<T>, keySelector: (item: T) => TKey): Array<{key: TKey, values: Array<T>}>
  /**
   * Группировать массив по функции селектору
   * @param array - массив
   * @param keySelector - функция получения ключа
   * @param resultSelector - результирующая функция
   */
  public static groupBy<T, TKey, TResult>(array: Array<T>, keySelector: (item: T) => TKey, resultSelector: (key: TKey, items: Array<T>) => TResult): TResult[]
  public static groupBy<T, TKey>(array: Array<T>, keySelector: (item: T) => TKey, resultSelector: (key: TKey, items: Array<T>) => any = undefined): any[]{
    resultSelector = resultSelector ?? ((key, values: T[]) => { return { key: key, values: values} });

    const map = this.groupBy_Internal(array, keySelector);

    return Array.from(map, ([key, value]) => {
      return resultSelector(key, value);
    });
  }

  /**
   * Группировать два массива.<br>
   * 1. Получает все ключи из левого и правого массива(уникальные)<br>
   * 2. На каждый ключ получает элементы из массивов<br>
   * 3. Если в левой или правой части отсутствуют элементы с ключом, вернет пустой массив<br>
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector функция получения ключа из элемента левого массива
   * @param rightSelector функция получения ключа из элемента правого массива
   */
  public static fullGroupJoin<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): {key: TSelector, lefts: Left[], rights: Right[]}[]

  /**
   * Группировать два массива.<br>
   * 1. Получает все ключи из левого и правого массива(уникальные)<br>
   * 2. На каждый ключ получает элементы из массивов<br>
   * 3. Если в левой или правой части отсутствуют элементы с ключом, вернет пустой массив<br>
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector функция получения ключа из элемента левого массива
   * @param rightSelector функция получения ключа из элемента правого массива
   * @param resultSelector функция конвертации результата
   */
  public static fullGroupJoin<Left, Right, TSelector, TResult>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (key: TSelector, lefts: Left[], rights: Right[]) => TResult): TResult[]

  public static fullGroupJoin<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (key: TSelector, lefts: Left[], rights: Right[]) => any = undefined): any[]{

    const leftGrouped = this.groupBy_Internal(leftArray, leftSelector);
    const rightGrouped = this.groupBy_Internal(rightArray, rightSelector);
    const keys = new Set([...leftGrouped.keys(), ...rightGrouped.keys()]);

    const map = !!resultSelector ? resultSelector : (key, lefts, rights) => {return {key: key, lefts: lefts, rights: rights}}

    return Array.from(keys.values(), key => {
      return map(key, leftGrouped.get(key) ?? [], rightGrouped.get(key) ?? []);
    })
  }

  /**
   * Получить элементы из левого массива, ключи которых НЕ пересекаются с ключами правого массива
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   */
  public static leftDisjointElements<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): Left[]{
    const innerJoinKeys = this.InnerJoinKeys_Internal(leftArray, rightArray, leftSelector, rightSelector);
    return leftArray.filter(l => !innerJoinKeys.has(leftSelector(l)));
  }

  /**
   * Получить элементы из правого массива, ключи которых НЕ пересекаются с ключами левого массива
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   */
  public static rightDisjointElements<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): Right[]{
    const innerJoinKeys = this.InnerJoinKeys_Internal(leftArray, rightArray, leftSelector, rightSelector);
    return rightArray.filter(r => !innerJoinKeys.has(rightSelector(r)));
  }

  /**
   * Оставляет в каждом массиве элементы, ключи которых не пересекаются
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   */
  public static disjointElements<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): {left: Left[], right: Right[]}

  /**
   * Оставляет в каждом массиве элементы, ключи которых не пересекаются
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   * @param resultSelector результирующая функция
   */
  public static disjointElements<Left, Right, TSelector, TResult>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: Left[], rights: Right[]) => TResult): TResult

  public static disjointElements<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: Left[], rights: Right[]) => any = undefined): any{

    const innerJoinKeys = this.InnerJoinKeys_Internal(leftArray, rightArray, leftSelector, rightSelector);

    resultSelector = resultSelector ?? ((left, right) => {return {left: left, right: right}});
    return resultSelector(
      leftArray.filter(left => !innerJoinKeys.has(leftSelector(left))),
      rightArray.filter(right => !innerJoinKeys.has(rightSelector(right)))
    );
  }

  /**
   * Получить элементы из левого массива, ключи которых пересекаются с ключами правого массива
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   */
  public static leftInnerJointElements<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): Left[]{
    const innerJoinKeys = this.InnerJoinKeys_Internal(leftArray, rightArray, leftSelector, rightSelector);
    return leftArray.filter(l => innerJoinKeys.has(leftSelector(l)));
  }

  /**
   * Получить элементы из правого массива, ключи которых пересекаются с ключами левого массива
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   */
  public static rightInnerJointElements<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): Right[]{
    const innerJoinKeys = this.InnerJoinKeys_Internal(leftArray, rightArray, leftSelector, rightSelector);
    return rightArray.filter(r => innerJoinKeys.has(rightSelector(r)));
  }

  /**
   * Оставляет в каждом массиве элементы, ключи которых пересекаются
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   */
  public static innerJoin<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): {left: Left[], right: Right[]}

  /**
   * Оставляет в каждом массиве элементы, ключи которых пересекаются
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector выбор поля из элемента левого массива
   * @param rightSelector выбор поля из элемента правого массива
   * @param resultSelector результирующая функция
   */
  public static innerJoin<Left, Right, TSelector, TResult>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: Left[], rights: Right[]) => TResult): TResult

  public static innerJoin<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: Left[], rights: Right[]) => any = undefined): any{

    const innerJoinKeys = this.InnerJoinKeys_Internal(leftArray, rightArray, leftSelector, rightSelector);
    resultSelector = resultSelector ?? ((lefts, rights) => {return {left: lefts, right: rights}})

    return resultSelector(
      leftArray.filter(l => innerJoinKeys.has(leftSelector(l))),
      rightArray.filter(r => innerJoinKeys.has(rightSelector(r))));
  }

  /**
   * Каждый элемент левого массива объединит с группой из правого массива.<br>
   * Если в правом массиве отсутствуют элементы с таким ключом, вернет []
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector функция получения ключа из левого элемента
   * @param rightSelector функция получения ключа из правого элемента
   */
  public static leftInnerJoinGroupedRight<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): {left: Left, rights: Right[]}[]

  /**
   * Каждый элемент левого массива объединит с группой из правого массива.<br>
   * Если в правом массиве отсутствуют элементы с таким ключом, вернет []
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector функция получения ключа из левого элемента
   * @param rightSelector функция получения ключа из правого элемента
   * @param resultSelector результирующая функция
   */
  public static leftInnerJoinGroupedRight<Left, Right, TSelector, TResult>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: Left, rights: Right[]) => TResult): TResult[]

  public static leftInnerJoinGroupedRight<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: Left, rights: Right[]) => any = undefined): any[]{

    resultSelector = resultSelector ?? ((left, rights) => {
      return {left: left, rights: rights};
    })

    const rightGrouped = this.groupBy_Internal(rightArray, rightSelector);
    return leftArray.map(left => resultSelector(left, rightGrouped.get(leftSelector(left)) ?? []))
  }

  /**
   * Каждый элемент левого массива объединит с группой из правого массива.<br>
   * Если в правом массиве отсутствуют элементы с ключом левого элемента, правая группа будет пуста [].<br>
   * Все элементы правого массива, которые не имеют ключа в левом массиве, будут в последнем элементе, и их левый элемент будет null.<br>
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector функция получения ключа из левого элемента
   * @param rightSelector функция получения ключа из правого элемента
   */
  public static leftOuterJoinGroupedRight<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): {left: Left, rights: Right[]}[]

  /**
   * Каждый элемент левого массива объединит с группой из правого массива.<br>
   * Если в правом массиве отсутствуют элементы с ключом левого элемента, правая группа будет пуста [].<br>
   * Все элементы правого массива, которые не имеют ключа в левом массиве, будут в последнем элементе, и их левый элемент будет null.<br>
   * @param leftArray левый массив
   * @param rightArray правый массив
   * @param leftSelector функция получения ключа из левого элемента
   * @param rightSelector функция получения ключа из правого элемента
   * @param resultSelector результирующая функция
   */
  public static leftOuterJoinGroupedRight<Left, Right, TSelector, TResult>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: Left, rights: Right[]) => TResult): TResult[]

  public static leftOuterJoinGroupedRight<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: Left, rights: Right[]) => any = undefined): any[]{

    resultSelector = resultSelector ?? ((left, rights) => {
      return {left: left, rights: rights};
    })

    const leftInnerJoin = this.leftInnerJoinGroupedRight(leftArray, rightArray, leftSelector, rightSelector, resultSelector);

    const notInLeft = this.rightDisjointElements(leftArray, rightArray, leftSelector, rightSelector);
    if(notInLeft.length > 0){
      leftInnerJoin.push(resultSelector(null, notInLeft));
    }

    return leftInnerJoin;
  }

  /**
   * Получить иерархию ввехр
   * @param sources Источник плоских данных
   * @param item Элемент у которого нужно получить дерево
   * @param idGetter Функция получения id
   * @param parenIdGetter Функция получения parentId
   * @param includeSelf Включать ли в результат сам item
   */
  public static getHierarchyUp<Item, ItemId>(
    sources: Item[],
    item: Item,
    idGetter: (item: Item) => ItemId,
    parenIdGetter: (item: Item) => ItemId,
    includeSelf: boolean = false): Item[]{

    const result: Item[] = [];
    if(includeSelf){
      result.push(item)
    }

    if(!parenIdGetter(item)){
      return result;
    }

    const parent = sources.find(x => idGetter(x) === parenIdGetter(item) && item !== x);
    if(parent){
      result.push(...this.getHierarchyUp(sources, parent, idGetter, parenIdGetter, true))
    }

    return result;
  }

  /**
   * Получить первый уровень детей
   * @param sources Источник плоских данных
   * @param item Элемент у которого нужно получить дерево
   * @param idGetter Функция получения id
   * @param parenIdGetter Функция получения parentId
   */
  public static fetchChildren<Item, ItemId>(
    sources: Item[],
    item: Item,
    idGetter: (item: Item) => ItemId,
    parenIdGetter: (item: Item) => ItemId): Item[]{
    return  sources.filter(x => idGetter(item) === parenIdGetter(x) && item !== x);
  }

  /**
   * Получить иерархию вниз со всеми вложениями
   * @param sources Источник плоских данных
   * @param item Элемент у которого нужно получить дерево (если передан null или undefined вернет sources)
   * @param idGetter Функция получения id
   * @param parenIdGetter Функция получения parentId
   * @param includeSelf Включать ли в результат сам item
   */
  public static getHierarchyDown<Item, ItemId>(
    sources: Item[],
    item: Item,
    idGetter: (item: Item) => ItemId,
    parenIdGetter: (item: Item) => ItemId,
    includeSelf: boolean = false): Item[]{

    if(item === null || item === undefined) {
      return  sources;
    }

    const result: Item[] = [];
    if(includeSelf){
      result.push(item)
    }

    const child = this.fetchChildren(sources, item, idGetter, parenIdGetter);
    child.forEach(c => result.push(...this.getHierarchyDown(sources, c, idGetter, parenIdGetter, true)))

    return result;
  }

  /**
   * Проверяет равным ли между собой 2 массива одного типа.
   * @param array1 массив 1
   * @param array2 массив 2
   * @param equalFunc функция сравнения двух элементов массива (по умолчанию просто === для каждого элемента)
   * @return true если КОЛИЧЕСТВО и ПОРЯДОК элементов соответствует в двух массивах.
   */
  public static equals<TArrayItem>(array1: Array<TArrayItem>, array2: Array<TArrayItem>, equalFunc: (a1Element: TArrayItem, a2Element:TArrayItem) => boolean = (a1,a2)=> a1 === a2): boolean{
    if(array1 === array2){
      return true;
    }

    if(!array1 || !array2){
      return false;
    }

    if(array1.length !== array2.length){
      return false;
    }

    return array1.every((v,i) => equalFunc(v,array2[i]));
  }

  /**
   * Сравнить два массива между собой, БЕЗ УЧЕТА ПОРЯДКА элементов в них
   * @param arr1 первый массив
   * @param arr2 второй массив
   * @param comparer функция сравнения элементов. По умолчанию сравнивает на ===
   */
  public static equals2<T1, T2>(arr1: T1[], arr2: T2[], comparer: (item1: T1, item2: T2) => boolean = (item1, item2) => (item1 as any) === item2){
    if((arr1 as any) === arr2){
      return true;
    }

    if(!arr1 || !arr2){
      return false;
    }

    if(arr1.length !== arr2.length){
      return false;
    }

    arr2 = [...arr2]; // Обязательно копируем. Используется splice
    //arr1 копировать НЕ нужно

    for (let i1 = 0; i1 < arr1.length; i1++) {
      const item1 = arr1[i1];

      let isEqual = false;
      for (let i2 = 0; i2 < arr2.length; i2++) {
        const item2 = arr2[i2];

        if(comparer(item1, item2)){
          isEqual = true;
          arr2.splice(i2, 1); // Убираем найденный
          break;
        }
      }

      if(!isEqual){
        return false;
      }
    }

    return true;
  }

  /**
   * Найти все элементы которые соответствуют переданному предикату
   * @return [] если нет соответствующих элементов или [item1, item2, ...]
   */
  public static findAll<TDataItem>(source: TDataItem[], predicate: (item: TDataItem) => boolean): FindAllItemResultType<TDataItem>[]{
    if(!source){
      throw new Error('передан источник === null | undefined');
    }

    const result: FindAllItemResultType<TDataItem>[] = [];

    for (let i = 0; i < source.length; i++) {
      if(predicate(source[i])){
        result.push({item: source[i], index: i})
      }
    }

    return result;
  }

  //Как только не пробовал реализовать метод itemsComparer, лучше решения не нашел по производительности.
  //Пробовал и Map<number, TTarget> - при удалении поля из него, много времени занимает сборщик мусора, и в два раза медленней этой реализации
  //Минус то что используется reduce.
  /**
   * Функция получения результата сравнения элементов между двумя массивами
   * @param targets что сравнивать. Безопасно на undefined
   * @param sources с чем сравнивать. Безопасно на undefined
   * @param resultSelector результирующая функция
   * @param states элементы с какими состояниями будут транслироваться
   * @param matchingItemsFn функция сопоставления элементов между собой.
   * @param itemComparerFn функция сравнения элементов. Используется если элементы сопоставлены.
   */
  public static itemsComparer<TTarget, TSource, TResult>(
    targets: TTarget[],
    sources: TSource[],
    resultSelector: (state: ArrayItemComparerResultType, target: TTarget, source: TSource) => TResult,
    states: ArrayItemComparerResultType[] = ['added', 'modified', 'deleted', 'unchanged'],
    matchingItemsFn: (item1: TTarget, item2: TSource) => boolean = (item1, item2) => (item1 as any) === item2,
    itemComparerFn: (item1: TTarget, item2: TSource) => boolean = () => true,
  ): TResult[] {
    if(!states?.length){
      return [];
    }

    const results: TResult[] = [];

    //Определение функций
    const changesSet = new Set<ArrayItemComparerResultType>(states);

    const added: (target: TTarget) => void = changesSet.has('added')
      ? (target) => {
        results.push(resultSelector('added', target, undefined));
      }
      : () => {};
    const deleted: (source: TSource) => void = changesSet.has('deleted')
      ? (source) => {
        results.push(resultSelector('deleted', undefined, source));
      }
      : () => {};
    const modified: (target: TTarget, source: TSource) => void = changesSet.has('modified')
      ? (target, source) => {
        results.push(resultSelector('modified', target, source));
      }
      : () => {};
    const unchanged: (target: TTarget, source: TSource) => void = changesSet.has('unchanged')
      ? (target, source) => {
        results.push(resultSelector('unchanged', target, source));
      }
      : () => {};
    //

    sources = !sources ? [] : [...sources]; //Обязательно копируем так как используется splice
    //targets НЕ нужно копировать

    //Элементы без изменения и добавленные
    targets = (targets ?? []).filter(tItem => {
      let hasMatching: boolean;
      const sIndex = sources.findIndex(sItem => {
        if(!matchingItemsFn(tItem, sItem)) {
          return false;
        }

        hasMatching = true;

        return itemComparerFn(tItem, sItem);
      });

      if(sIndex < 0){
        if(!hasMatching){
          added(tItem); //Добавлен элемент
          return false;
        }

        return true;
      }

      unchanged(tItem, sources[sIndex]);

      sources.splice(sIndex, 1); //Убираем из источника полное соответствие

      return false; //Не берем элемент
    })

    //Модифицированные или удаленные
    for (let i = 0; i < sources.length; i++) {
      const source = sources[i];

      const tIndex = targets.findIndex(tItem => matchingItemsFn(tItem, source));

      if(tIndex < 0){
        deleted(source);
        continue;
      }

      modified(targets[tIndex], source);

      targets.splice(tIndex, 1); //Убираем из источника полное соответствие
    }

    return results;
  }

  /**
   * Метод сравнивает два массива (без учета порядка элементов) по переданным функциям.<br>
   * Массивы могут быть разных типов.<br>
   * @param target целевой массив(что сравнивает)
   * @param source источник массив(с чем сравнивает)
   * @param matchingItemsFn функция сопоставления элементов между массивами
   * @param itemComparerFn функция сравнения элементов двух массивов.
   * @returns массив измененных элементов со состоянием(удален, добавлен, модифицирован)
   */
  public static difference<TItem1, TItem2>(
    target: TItem1[] | undefined,
    source: TItem2[] | undefined,
    matchingItemsFn: (item1: TItem1, item2: TItem2) => boolean,
    itemComparerFn: (item1: TItem1, item2: TItem2) => boolean = () => true): DifferenceItemResult<TItem2, TItem1>[]{

    return ArrayHelper.itemsComparer(
      target,
      source,
      (state, target, source) => new DifferenceItemResult<TItem2, TItem1>(state, source, target),
      ['added', 'deleted' , 'modified'],
      matchingItemsFn,
      itemComparerFn
    )
  }


  /**
   * Метод сравнивает два массива (без учета порядка элементов) по переданным функциям.<br>
   * Массивы должны быть одинакового типа.
   * @param target целевой массив(что сравнивает)
   * @param source источник массив(с чем сравнивает)
   * @param matchingItemsFn функция сопоставления элементов между массивами
   * @param itemComparerFn функция сравнения элементов двух массивов.
   * @returns массив измененных элементов со состоянием(удален, добавлен, модифицирован)
   */
  public static difference2<TItem>(
    target: TItem[] | undefined,
    source: TItem[] | undefined,
    matchingItemsFn: (item1: TItem, item2: TItem) => boolean,
    itemComparerFn: (item1: TItem, item2: TItem) => boolean = () => true): Difference2ItemResult<TItem>[]{

    return ArrayHelper.itemsComparer(
      target,
      source,
      (state, target, source) => new Difference2ItemResult<TItem>(state, source, target),
      ['added', 'deleted' , 'modified'],
      matchingItemsFn,
      itemComparerFn
    )
  }

  /**
   * Получить первый элемент или значение по умолчанию
   * @param arr массив-источник
   * @param defaultValue значение по умолчанию, или функция его получения
   * @param predicate функция поиска элемента. По умолчанию любой элемент
   */
  public static firstOrDefault<T>(arr: Array<T>, defaultValue: T | (() => T) = undefined, predicate: (value: T, index: number, obj: T[]) => boolean = undefined){
    predicate = predicate ?? (() => true);
    return arr.find(predicate) ?? (defaultValue instanceof Function ? defaultValue() : defaultValue);
  }

  /**
   * Получить первый элемент или ошибка
   * @param arr массив-источник
   * @param predicate функция поиска элемента. По умолчанию любой элемент
   * @param errorMessage сообщение ошибки, если элемент отсутствует
   */
  public static first<T>(arr: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean = undefined, errorMessage: string = 'В массиве отсутствуют элементы'){
    return this.firstOrDefault(arr, () => {throw new Error(errorMessage)}, predicate);
  }

  /**
   * Получить один элемент или значение по умолчанию
   * @param arr массив-источник
   * @param defaultValue значение по умолчанию, или функция его получения
   * @param predicate функция поиска элемента. По умолчанию любой элемент
   * @param errorMessage сообщение ошибки, если элементов несколько
   */
  public static singleOrDefault<T>(arr: Array<T>, defaultValue: T | (() => T) = undefined, predicate: (value: T, index: number, obj: T[]) => boolean = undefined, errorMessage: string = 'В массиве не может быть больше одного элемента'): T{
    const result = arr.filter(predicate ?? (() => true));
    if(result.length > 1){
      throw new Error(errorMessage);
    }

    return this.firstOrDefault(result, defaultValue);
  }

  /**
   * Получить один элемент
   * @param arr массив-источник
   * @param predicate функция поиска элемента. По умолчанию любой элемент
   * @param errorMessage сообщение ошибки, если элементов несколько или они отсутствуют
   */
  public static single<T>(arr: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean = undefined,  errorMessage: string = 'В массиве должен быть один элемент'){
    return this.singleOrDefault(arr, () => { throw new Error(errorMessage) }, predicate);
  }

  /**
   * Получить последний или значение по умолчанию
   * @param arr массив-источник
   * @param defaultValue значение по умолчанию, или функция его получения
   * @param predicate функция поиска элемента. По умолчанию любой элемент
   */
  public static lastOrDefault<T>(arr: Array<T>, defaultValue: T | (() => T) = undefined, predicate: (value: T, index: number, obj: T[]) => boolean = undefined){
    return this.firstOrDefault(arr.reverse(), defaultValue, predicate);
  }

  /**
   * Получить последний элемент
   * @param arr массив-источник
   * @param predicate функция поиска элемента. По умолчанию любой элемент
   * @param errorMessage сообщение ошибки, если элемент отсутствует
   */
  public static last<T>(arr: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean = undefined, errorMessage: string = 'В массиве отсутствуют элементы'){
    return this.first(arr.reverse(), predicate, errorMessage);
  }


  /** Внутренний метод группировки массива по переданной функции селектора ключа */
  private static groupBy_Internal<A, KeyT>(array: Array<A>, selectorFunc: (item: A) => KeyT): Map<KeyT, A[]>{
    const map = new Map<KeyT, A[]>();

    for (let item of array) {
      const key = selectorFunc(item);

      let inMap = map.get(key);
      if(inMap === undefined){ //если денных еще нет
        inMap = [];
        map.set(key, inMap);
      }

      inMap.push(item);
    }

    return map;
  }

  /** Внутренний метод объединения ключей из двух массивов */
  private static InnerJoinKeys_Internal<Left, Right, TSelector>(
    leftArray: Left[],
    rightArray: Right[],
    leftSelector: (item: Left) => TSelector,
    rightSelector: (item: Right) => TSelector): Set<TSelector>{
    const leftSet = new Set(leftArray.map(x => leftSelector(x)));
    const rightSet = new Set(rightArray.map(x => rightSelector(x)));

    const result = new Set<TSelector>();

    for (let left of leftSet) {
      if(rightSet.has(left)){
        result.add(left);
      }
    }

    return result;
  }
}

/** Тип результата метода findAll */
export type FindAllItemResultType<TDataItem> = {item: TDataItem, index: number};

/** Результат сравнения элемента массивов */
class DifferenceItemResult<TOrigin, TCurrent> {
  constructor(public readonly state: Exclude<ArrayItemComparerResultType, 'unchecked'>,
              public readonly origin: TOrigin,
              public readonly current: TCurrent) {
  }
}

/** Результат сравнения элемента массивов */
export class Difference2ItemResult<TItem> extends DifferenceItemResult<TItem, TItem>{

  /** Получить текущий или оригинальный элемент */
  public get currentOrOrigin(){
    return this.current === undefined ? this.origin : this.current;
  }

  /**
   * Проверяет, изменилось ли поле <br>
   * Если this.state !== 'modified' вернет false
   */
  public propIsModified<Key extends keyof TItem>(key: Key, comparer: (item1: TItem[Key], item2: TItem[Key]) => boolean = ObjComparer.defaultComparer){
    if(this.state != 'modified'){
      return false;
    }

    return !comparer(this.origin[key], this.current[key]);
  }
}

/** Класс композиция для {@link Array}, расширяет его возможности */
export class ArrayExpanded<T>{
  constructor(public readonly array: Array<T>) {
  }

  /** @see ArrayHelper.flatMapBy */
  public flatMap<TInnerItem>(selector: (out: T) => TInnerItem[]): ArrayExpanded<TInnerItem>
  public flatMap<TInnerItem, TResultItem>(selector: (out: T) => TInnerItem[], resultSelector: (outItem: T, innerItem: TInnerItem, index: number, outIndex: number, innerIndex: number) => TResultItem): ArrayExpanded<TResultItem>
  public flatMap(selector: (Item: any) => any[], resultSelector: (outItem: any, innerItem: any, index: number, outIndex: number, innerIndex: number) => any = undefined): ArrayExpanded<any>{
    return new ArrayExpanded<any>(
      ArrayHelper.flatMapBy(this.array, selector, resultSelector)
    );
  }

  /** Копировать экземпляр с возможностью изменить текущий массив */
  public copy<TNew>(fn: (arr: Array<T>) => Array<TNew>){
    return new ArrayExpanded(fn(this.array));
  }

  /** @see Array.filter */
  public filter(predicate: (value: T, index: number, obj: T[]) => boolean = undefined){
    return new ArrayExpanded(this.array.filter(predicate));
  }

  /** @see Array.map */
  public map<U>(callbackfn: (value: T, index: number, array: T[]) => U){
    return new ArrayExpanded(this.array.map(callbackfn));
  }

  /** @see Array.sort */
  public sort(compareFn?: (a: T, b: T) => number){
    return new ArrayExpanded(this.array.sort(compareFn));
  }

  /** Преобразовать в {@link Map} */
  public toMap<TKey>(keySelector: (item: T) => TKey): Map<TKey, T>
  /** Преобразовать в {@link Map} */
  public toMap<TKey, TResult>(keySelector: (item: T) => TKey, resultSelector: (item: T) => TResult): Map<TKey, TResult>
  public toMap<TKey>(keySelector: (item: T) => TKey, resultSelector: (item: T) => any = undefined): Map<TKey, any>
  {
    if(!resultSelector){
      resultSelector = x => x;
    }

    return new Map<TKey, T>(
      this.array.map(x => [
        keySelector(x),
        resultSelector(x),
      ])
    )
  }

  /**
   * Преобразовать в {@link DateMap}<br>
   * Использовать если {@link keySelector} возвращает {@link Date} в формате {@link number}
   */
  public toDateMap(keySelector: (item: T) => number): DateMap<T>
  public toDateMap<TResult>(keySelector: (item: T) => number, resultSelector: (item: T) => TResult): DateMap<TResult>
  public toDateMap(keysSelector: (item: T) => number, resultSelector: (item: T) => any = undefined): DateMap<any>{
    if(!resultSelector){
      resultSelector = x => x;
    }

    return DateMap.Create2(this.array, item => keysSelector(item), resultSelector);
  }


  /**
   * Преобразовать в {@link DateMap}<br>
   * Использовать если {@link keySelector} возвращает {@link Date}<br>
   * Использует метод {@link toDateMap}
   */
  public toDateMap2(keySelector: (item: T) => Date): DateMap<T>
  public toDateMap2<TResult>(keySelector: (item: T) => Date, resultSelector: (item: T) => TResult): DateMap<TResult>
  public toDateMap2(keysSelector: (item: T) => Date, resultSelector: (item: T) => any = undefined): DateMap<any>{
    return this.toDateMap(x => +keysSelector(x), resultSelector);
  }


  /** Функция {@link ArrayHelper.distinct} */
  public distinct(){
    return new ArrayExpanded(ArrayHelper.distinct(this.array));
  }

  /** Функция {@link ArrayHelper.distinctBy} */
  public distinctBy<TKey>(keySelector: (item: T) => TKey, groupSelector: (group: T[]) => T = undefined){
    return new ArrayExpanded(ArrayHelper.distinctBy(this.array, keySelector, groupSelector));
  }

  /** Функция {@link ArrayHelper.groupBy} */
  public groupBy<TKey>(keySelector: (item: T) => TKey): ArrayExpanded<{key: TKey, values: Array<T>}>
  /** Функция {@link ArrayHelper.groupBy} */
  public groupBy<TKey, TResult>(keySelector: (item: T) => TKey, resultSelector: (key: TKey, items: Array<T>) => TResult): ArrayExpanded<TResult>
  public groupBy<TKey>(keySelector: (item: T) => TKey, resultSelector: (key: TKey, items: Array<T>) => any = undefined){
    return new ArrayExpanded(ArrayHelper.groupBy(this.array, keySelector, resultSelector));
  }

  /** Функция {@link ArrayHelper.fullGroupJoin} */
  public fullGroupJoin<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
  ): ArrayExpanded<{key: TSelector, lefts: T[], rights: Right[]}>

  /** Функция {@link ArrayHelper.fullGroupJoin} */
  public fullGroupJoin<Right, TSelector, TResult>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (key: TSelector, lefts: T[], rights: Right[]) => TResult): ArrayExpanded<TResult>

  public fullGroupJoin<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (key: TSelector, lefts: T[], rights: Right[]) => any = undefined): ArrayExpanded<any>{

    return new ArrayExpanded(ArrayHelper.fullGroupJoin(this.array, rightArray, leftSelector, rightSelector, resultSelector));
  }

  /** Функция {@link ArrayHelper.leftInnerJoinGroupedRight} */
  public leftInnerJoinGroupedRight<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): ArrayExpanded<{left: T, rights: Right[]}>

  /** Функция {@link ArrayHelper.leftInnerJoinGroupedRight} */
  public leftInnerJoinGroupedRight<Right, TSelector, TResult>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: T, rights: Right[]) => TResult): ArrayExpanded<TResult>

  public leftInnerJoinGroupedRight<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: T, rights: Right[]) => any = undefined): ArrayExpanded<any>{
    return new ArrayExpanded(ArrayHelper.leftInnerJoinGroupedRight(this.array, rightArray, leftSelector, rightSelector, resultSelector));
  }

  /** Функция {@link ArrayHelper.leftDisjointElements} */
  public leftDisjointElements<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): ArrayExpanded<T>{
    return new ArrayExpanded(ArrayHelper.leftDisjointElements(this.array, rightArray, leftSelector, rightSelector)) ;
  }

  /** Функция {@link ArrayHelper.rightDisjointElements} */
  public rightDisjointElements<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): ArrayExpanded<Right> {
    return new ArrayExpanded(ArrayHelper.rightDisjointElements(this.array, rightArray, leftSelector, rightSelector));
  }

  /** Функция {@link ArrayHelper.disjointElements} */
  public disjointElements<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): {left: T[], right: Right[]}

  /** Функция {@link ArrayHelper.disjointElements} */
  public disjointElements< Right, TSelector, TResult>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: T[], rights: Right[]) => TResult): TResult

  public disjointElements<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: T[], rights: Right[]) => any = undefined): any{
    return ArrayHelper.disjointElements(this.array, rightArray, leftSelector, rightSelector, resultSelector);
  }

  /** Функция {@link ArrayHelper.leftInnerJointElements} */
  public leftInnerJointElements<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): ArrayExpanded<T>{
    return new ArrayExpanded(ArrayHelper.leftInnerJointElements(this.array, rightArray, leftSelector, rightSelector));
  }

  /** Функция {@link ArrayHelper.rightInnerJointElements} */
  public rightInnerJointElements<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): ArrayExpanded<Right>{
    return new ArrayExpanded(ArrayHelper.rightInnerJointElements(this.array, rightArray, leftSelector, rightSelector));
  }


  /** Функция {@link ArrayHelper.leftOuterJoinGroupedRight}  */
  public leftOuterJoinGroupedRight<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): ArrayExpanded<{left: T, rights: Right[]}>

  /** Функция {@link ArrayHelper.leftOuterJoinGroupedRight}  */
  public leftOuterJoinGroupedRight<Right, TSelector, TResult>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: T, rights: Right[]) => TResult): ArrayExpanded<TResult>

  public leftOuterJoinGroupedRight<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (left: T, rights: Right[]) => any = undefined): ArrayExpanded<any>{

    return new ArrayExpanded(ArrayHelper.leftOuterJoinGroupedRight(this.array, rightArray, leftSelector, rightSelector, resultSelector));
  }


  /** Функция {@link ArrayHelper.innerJoin} */
  public innerJoin<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector): {left: T[], right: Right[]}

  /** Функция {@link ArrayHelper.innerJoin} */
  public innerJoin<Right, TSelector, TResult>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: T[], rights: Right[]) => TResult): TResult

  public innerJoin<Right, TSelector>(
    rightArray: Right[],
    leftSelector: (item: T) => TSelector,
    rightSelector: (item: Right) => TSelector,
    resultSelector: (lefts: T[], rights: Right[]) => any = undefined): any{

    return ArrayHelper.innerJoin(this.array, rightArray, leftSelector, rightSelector, resultSelector);
  }

  /** Функция {@link ArrayHelper.firstOrDefault} */
  public firstOrDefault(defaultValue: T | (() => T) = undefined, predicate: (value: T, index: number, obj: T[]) => boolean = undefined){
    return ArrayHelper.firstOrDefault(this.array, defaultValue, predicate);
  }

  /** Функция {@link ArrayHelper.first} */
  public first(predicate: (value: T, index: number, obj: T[]) => boolean = undefined, errorMessage: string = undefined){
    return ArrayHelper.first(this.array, predicate, errorMessage);
  }

  /** Функция {@link ArrayHelper.singleOrDefault} */
  public singleOrDefault(defaultValue: T | (() => T) = undefined, predicate: (value: T, index: number, obj: T[]) => boolean = undefined, errorMessage: string = undefined): T{
    return ArrayHelper.singleOrDefault(this.array, defaultValue, predicate, errorMessage);
  }

  /** Функция {@link ArrayHelper.single} */
  public single(predicate: (value: T, index: number, obj: T[]) => boolean = undefined,  errorMessage: string = undefined){
    return ArrayHelper.single(this.array, predicate, errorMessage)
  }

  /** Функция {@link ArrayHelper.lastOrDefault} */
  public lastOrDefault(defaultValue: T | (() => T) = undefined, predicate: (value: T, index: number, obj: T[]) => boolean = undefined){
    return ArrayHelper.lastOrDefault(this.array, defaultValue, predicate);
  }

  /** Функция {@link ArrayHelper.last} */
  public last(predicate: (value: T, index: number, obj: T[]) => boolean = undefined, errorMessage: string = undefined){
    return ArrayHelper.last(this.array, predicate, errorMessage);
  }
}
