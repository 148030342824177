<app-header text="Справочники">
  <kendo-grid
    [data]="gridDirectoriesData"
    (edit)="editHandler($event)"
    KendoGridExpanded
    (dbCellClick)="onDblCellClick($event)"
  >
    <kendo-grid-command-column title="" [width]="62">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button
          kendoGridEditCommand
          [primary]="true"
          icon="folder-open"
          [title]="getEditButtonTitle(dataItem)"
        ></button>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column field="name" title="Справочник" [width]="300">
    </kendo-grid-column>
    <kendo-grid-column field="description" title="Описание справочника">
    </kendo-grid-column>
  </kendo-grid>
</app-header>
