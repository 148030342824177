import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent } from "../../../components/loading-indicator/loading-indicator.component";
import { ProgressBarModule } from "@progress/kendo-angular-progressbar";
import { DialogModule } from "@progress/kendo-angular-dialog";
import { AfterValueChangedDirective } from "../../../directives/after-value-changed.directive";
import { HeaderComponent } from 'src/app/components/header/header.component';

@NgModule({
  declarations: [
    LoadingIndicatorComponent,
    AfterValueChangedDirective,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    DialogModule
  ],
  exports: [
    LoadingIndicatorComponent,
    AfterValueChangedDirective,
    HeaderComponent
  ]
})
export class SharedComponentModule { }
