import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownItem, IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { AddPositionItem } from 'src/app/services/webApi/webApi1/controllers/api1-position-controller.service';
import { CustomFormValidators } from 'src/app/validators/custom-form-validators.class';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import { exDistinctUntilChanged } from "../../../../../../../src/app/operators/ex-distinct-until-changed.operator";
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { DataSource } from 'src/app/classes/array-data-sources/data-source';
import { ISubdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { IOccupation } from 'src/app/classes/domain/POCOs/stafflist/Occupation';
import { IForDate } from 'src/app/classes/for-date';
import { ArrayDataSourceIEntityIdServiceWithParamsBase, IArrayDataSourceServiceWithParamsReadonly } from 'src/app/services/data-source-services/data-source.service';
import { IWorkMode } from 'src/app/classes/domain/POCOs/stafflist/WorkMode';
import { IReportPeriod } from 'src/app/classes/domain/POCOs/stafflist/ReportPeriod';
import { IFinancingSource } from 'src/app/classes/domain/POCOs/stafflist/FinancingSource';
import { Observable } from 'rxjs';
import { exElementByIndexOr } from 'src/app/operators/ex-element-by-index-or';
import { PropertyWrapFormControlType } from 'src/app/classes/types/property-wrap-form-control-type';
import { IPosition } from 'src/app/classes/domain/POCOs/stafflist/Position';
import { IPositionRateOptional } from 'src/app/classes/domain/POCOs/stafflist/PositionRate';
import { CustomFormValidationFunctions } from 'src/app/validators/custom-form-validation-functions.class';
import {WorkModeTypeEnum} from "../../../../../../../src/app/classes/domain/POCOs/stafflist/WorkModeType";

/** Тип формы добавления */
export type AddPositionFormComponentForm = PropertyWrapFormControlType<Pick<IPosition, | "startDate" | "endDate" | "comment" | "occupationId" | "subdivisionId" | "workModeId" | "reportPeriodId" | "leaderFlag" | "dinnerHourDuration" | "subtractLunchTimeFromWorkingHoursFlag" | "includeScheduleForPaidServiceFlag">> & {
   rates: FormControl<Array<IPositionRateOptional>>
  }


@Component({
  selector: 'app-add-position-form',
  templateUrl: './add-position-form.component.html',
  styleUrls: ['./add-position-form.component.css']
})
@traceClass('AddPositionFormComponent')
export class AddPositionFormComponent implements OnInit, OnDestroy {

  @Input("dataSource") public dataSource: AddPositionFormComponent_DataSourceService;

  @Output("onSave") public onSaveEventEmitter = new EventEmitter<AddPositionItem>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>();

  public occupations$: Observable<IOccupation[]>;
  public workModes$: Observable<IWorkMode[]>;
  public reportPeriods$: Observable<IReportPeriod[]>;
  public financingSourcesDropDownData$: Observable<IDropDownItem[]>;
  public currentSubdivision$: Observable<ISubdivision>;
  public form: FormGroup<AddPositionFormComponentForm>;
  public IsEditingPositionRatesGrid: boolean;

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor( private tracerService: TracerServiceBase) { }

  @traceFunc()
  ngOnInit() {
    this.occupations$ = this.dataSource.data$.pipe(switchMap(d=> d.occupations.dataSource.data$));
    this.workModes$ = this.dataSource.data$.pipe(switchMap(d=> d.workModes.dataSource.data$));
    this.reportPeriods$ = this.dataSource.data$.pipe(switchMap(d=> d.reportPeriods.dataSource.data$));
    this.financingSourcesDropDownData$ = this.dataSource.data$.pipe(switchMap(d=> d.financingSources.dataSource.data$.pipe(map(s=> s.map(o=> new DropDownItem(o.id, o.shortName))))));
    this.currentSubdivision$ = this.dataSource.data$.pipe(switchMap(d=> d.subdivisions.dataSource.data$.pipe(exElementByIndexOr(0))));

    this.currentSubdivision$.pipe(takeUntil(this.streams$.unsubscribes)).subscribe(s=> this.form.controls.subdivisionId.setValue(s.id));

    this.dataSource.data$.pipe(switchMap(s=> s.reportPeriods.reloadData$({}))).subscribe();
    this.dataSource.data$.pipe(switchMap(s=> s.financingSources.reloadData$({}))).subscribe();

    this.initForm();
  }

  @traceFunc()
  initForm() {
    const startDateControl = new FormControl(null, { validators: [CustomFormValidators.validateStartDateWithEndDate('endDate'), Validators.required], updateOn: 'blur' });
    const endDateControl = new FormControl(null, { validators: [CustomFormValidators.validateEndDateWithStartDate('startDate'), CustomFormValidators.requiredIf(() => !!this.dataSource.data.subdivisions.dataSource.data[0]?.endDate)], updateOn: 'blur' });

    this.form = new FormGroup({
      startDate: startDateControl,
      endDate: endDateControl,

      comment: new FormControl({ value: "", disabled: true }, Validators.maxLength(100)),
      occupationId: new FormControl({ value: null, disabled: true }, [Validators.required]),
      subdivisionId: new FormControl({ value: null, disabled: true }, [Validators.required]),
      workModeId: new FormControl({ value: null, disabled: true }, [Validators.required]),
      reportPeriodId: new FormControl({ value: null, disabled: true }, [Validators.required]),
      rates: new FormControl({ value: null, disabled: true }),
      leaderFlag: new FormControl({ value: false, disabled: true }),
      dinnerHourDuration: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.min(0), Validators.max(5.0)]),
      subtractLunchTimeFromWorkingHoursFlag: new FormControl({ value: false, disabled: true }),
      includeScheduleForPaidServiceFlag: new FormControl({ value: false, disabled: true }),
    });

    CustomFormValidationFunctions.validateDataWithControlValue$(
      this.occupations$,
      this.form.controls.occupationId,
      (d,c)=> d.id === c
    )
    .pipe(takeUntil(this.streams$.unsubscribes))
    .subscribe();

    CustomFormValidationFunctions.validateDataWithControlValue$(
      this.workModes$,
      this.form.controls.workModeId,
      (d,c)=> d.id === c
    )
    .pipe(takeUntil(this.streams$.unsubscribes))
    .subscribe();

    CustomFormValidationFunctions.validateDataWithControlValue$(
      this.reportPeriods$,
      this.form.controls.reportPeriodId,
      (d,c)=> d.id === c
    )
    .pipe(takeUntil(this.streams$.unsubscribes))
    .subscribe();

    this.form.markAllAsTouched();

    endDateControl.valueChanges.pipe(exDistinctUntilChanged(endDateControl.value)).subscribe(v => {
      this.reloadAllSources(this.form.controls.startDate.value, v);
    });

    startDateControl.valueChanges.pipe(exDistinctUntilChanged(startDateControl.value)).subscribe(v => {
      if (v) {
        this.reloadAllSources(v, this.form.controls.endDate.value);

        this.form.controls.comment.enable();
        this.form.controls.occupationId.enable();
        this.form.controls.subdivisionId.enable();
        this.form.controls.workModeId.enable();
        this.form.controls.reportPeriodId.enable();
        this.form.controls.leaderFlag.enable();
        this.form.controls.dinnerHourDuration.enable();
        this.form.controls.subtractLunchTimeFromWorkingHoursFlag.enable();
        this.form.controls.includeScheduleForPaidServiceFlag.enable();
        this.form.controls.rates.enable();
      }
    });

    this.form.controls.workModeId.valueChanges.pipe(
      switchMap(workModeId => this.workModes$.pipe(map(x => x.find(i => i.id === workModeId)))))
      .subscribe(value => {
        if(value?.workModeTypeId === WorkModeTypeEnum.flexibleGraph){
          this.form.controls.subtractLunchTimeFromWorkingHoursFlag.setValue(false);
          this.form.controls.subtractLunchTimeFromWorkingHoursFlag.disable();
        } else{
          this.form.controls.subtractLunchTimeFromWorkingHoursFlag.enable();
      }
    });

    this.form.valueChanges.subscribe(v => this.tracerService.add2("Изменение формы", { obj: v, maxLength: 500 }));
  }

  @traceFunc()
  onClickSave() {
    const addPositionItem = {...this.form.value} as AddPositionItem;
    if (addPositionItem.subtractLunchTimeFromWorkingHoursFlag === undefined){
      addPositionItem.subtractLunchTimeFromWorkingHoursFlag = false;
    }

    this.onSaveEventEmitter.emit(addPositionItem);
  }

  @traceFunc()
  onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

  @traceFunc()
  private reloadAllSources(startDate: Date, endDate: Date) {
    const forDate: IForDate = {startDate, endDate, forDate: endDate ?? startDate};

    this.dataSource.data.occupations.reloadData(forDate);
    this.dataSource.data.workModes.reloadData(forDate);
  }

  @traceFunc()
  public onChangeIsEditingPositionRatesGrid(val: boolean) {
    this.IsEditingPositionRatesGrid = val;
  }
}

export class AddPositionFormComponent_DataSourceService extends DataSource<AddPositionFormComponent_Data> {

}

export class AddPositionFormComponent_Data {
  constructor(
      public subdivisions: IArrayDataSourceServiceWithParamsReadonly<IForDate,ISubdivision>,
      public occupations: ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, IOccupation>,
      public workModes: ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, IWorkMode>,
      public reportPeriods: ArrayDataSourceIEntityIdServiceWithParamsBase<any, IReportPeriod>,
      public financingSources: ArrayDataSourceIEntityIdServiceWithParamsBase<any, IFinancingSource>,
    ) { }
}
