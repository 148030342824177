import { EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { VersioningField } from "../services/classes/fields/versioning-field";

export interface IDirectoryEditVersioningComponent {
  field: VersioningField;
  dataItem: any;

  startDateMaxValue: Date;
  startDateMinValue: Date;
  endDateMinValue: Date;
  endDateMaxValue: Date;
  form: FormGroup<IVersioningForm>;

  columnTitle: string;

  dataSource$: Observable<Array<VersioningItem>>;

  onStartDateChangeWithInitEventEmitter: EventEmitter<ValueChangeEvent>;
  onEndDateChangeWithInitEventEmitter: EventEmitter<ValueChangeEvent>;
  onStartEditOrAddEventEmitter: EventEmitter<VersioningItem>;
  onEndEditOrAddEventEmitter: EventEmitter<VersioningItem>;
  onSavingEventEmitter: EventEmitter<VersioningItem>;
  onAddingEventEmitter: EventEmitter<VersioningItem>;
  onDeletingEventEmitter: EventEmitter<VersioningItem>;
}

export class ValueChangeEvent {
  constructor(
    public dataItem: VersioningItem,
    public newValue: any
  ){}
}

export interface IVersioningForm {
  id: FormControl<number>;
  ownerId: FormControl<number>;
  comment: FormControl<string>;
  endDate: FormControl<Date>;
  startDate: FormControl<Date>;
  value: FormControl<any>;
  timestamp: FormControl<[]>;
}
