import {Injectable, OnDestroy} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Employee} from "../../../../classes/domain/POCOs/stafflist/Employee";
import {defer, Observable, ReplaySubject} from "rxjs";
import {map, take, takeUntil} from "rxjs/operators";
import {AuthService} from "../../../../modules/auth/services/auth.service";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { IExtendedData } from "src/app/classes/extendedDatas/extended-data";
import { PassportData } from "src/app/classes/extendedDatas/employeeExtended/passport-data";
import { AddressData } from "src/app/classes/extendedDatas/employeeExtended/address-data";
import { InnData } from "src/app/classes/extendedDatas/employeeExtended/inn-data";
import { SnilsData } from "src/app/classes/extendedDatas/employeeExtended/snils-data";
import { PhoneData } from "src/app/classes/extendedDatas/employeeExtended/phone-data";

@Injectable({
  providedIn: "root"
})
export class Api1EmployeeExtendedControllerService{


  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) { }


  public addPassportData$(pasportData: PassportData, employeeId: number): Observable<IExtendedData<PassportData>>
  {
    return this.httpClient
    .post<IExtendedData<PassportData>>(this.webApi1Service.controllers.employeeExtended.actions.addPassportData.toString(),
      {
        data: pasportData,
        employeeId: employeeId
      }
    )
  }

  public savePassportData$(passportData: IExtendedData<PassportData>): Observable<IExtendedData<PassportData>>
  {
    return this.httpClient
    .post<IExtendedData<PassportData>>(this.webApi1Service.controllers.employeeExtended.actions.savePassportData.toString(),
      passportData
    )
  }



  public addAddressData$(addressData: AddressData, employeeId: number): Observable<IExtendedData<AddressData>>
  {
    return this.httpClient
    .post<IExtendedData<AddressData>>(this.webApi1Service.controllers.employeeExtended.actions.addAddressData.toString(),
      {
        data: addressData,
        employeeId: employeeId
      }
    )
  }

  public saveAddressData$(addressData: IExtendedData<AddressData>): Observable<IExtendedData<AddressData>>
  {
    return this.httpClient
    .post<IExtendedData<AddressData>>(this.webApi1Service.controllers.employeeExtended.actions.saveAddressData.toString(),
    addressData
    )
  }



  public addInnData$(innData: InnData, employeeId: number): Observable<IExtendedData<InnData>>
  {
    return this.httpClient
    .post<IExtendedData<InnData>>(this.webApi1Service.controllers.employeeExtended.actions.addInnData.toString(),
      {
        data: innData,
        employeeId: employeeId
      }
    )
  }

  public saveInnData$(innData: IExtendedData<InnData>): Observable<IExtendedData<InnData>>
  {
    return this.httpClient
    .post<IExtendedData<InnData>>(this.webApi1Service.controllers.employeeExtended.actions.saveInnData.toString(),
    innData
    )
  }



  public addSnilsData$(snilsData: SnilsData, employeeId: number): Observable<IExtendedData<SnilsData>>
  {
    return this.httpClient
    .post<IExtendedData<SnilsData>>(this.webApi1Service.controllers.employeeExtended.actions.addSnilsData.toString(),
      {
        data: snilsData,
        employeeId: employeeId
      }
    )
  }

  public saveSnilsData$(snilsData: IExtendedData<SnilsData>): Observable<IExtendedData<SnilsData>>
  {
    return this.httpClient
    .post<IExtendedData<SnilsData>>(this.webApi1Service.controllers.employeeExtended.actions.saveSnilsData.toString(),
    snilsData
    )
  }


  public addPhoneData$(phoneData: PhoneData, employeeId: number): Observable<IExtendedData<PhoneData>>
  {
    return this.httpClient
    .post<IExtendedData<PhoneData>>(this.webApi1Service.controllers.employeeExtended.actions.addPhoneData.toString(),
      {
        data: phoneData,
        employeeId: employeeId
      }
    )
  }

  public savePhoneData$(phoneData: IExtendedData<PhoneData>): Observable<IExtendedData<PhoneData>>
  {
    return this.httpClient
    .post<IExtendedData<PhoneData>>(this.webApi1Service.controllers.employeeExtended.actions.savePhoneData.toString(),
    phoneData
    )
  }

}
