<div [formGroup]="form" class="k-rounded-md form-fio-div">
  <div class="form-inline-fio-div">
    <kendo-formfield>
      <label [for]="accusative">Родительный&nbsp;<i>(вопрос падежа - кого?)</i></label>
      <input kendoTextBox inputTrim #accusative formControlName="accusative"/>
    </kendo-formfield>
    <kendo-formfield>
      <label [for]="dative">Дательный&nbsp;<i>(вопрос падежа - кому?)</i></label>
      <input kendoTextBox inputTrim #dative formControlName="dative"/>
    </kendo-formfield>
  </div>
  <div class="form-inline-fio-div">
    <kendo-formfield>
      <label [for]="genitive">Винительный&nbsp;<i>(вопрос падежа - кого?)</i></label>
      <input kendoTextBox inputTrim #genitive formControlName="genitive"/>
    </kendo-formfield>
    <kendo-formfield>
      <label [for]="instrumental">Творительный&nbsp;<i>(вопрос падежа - кем?)</i></label>
      <input kendoTextBox inputTrim #instrumental formControlName="instrumental"/>
    </kendo-formfield>
  </div>
</div>
