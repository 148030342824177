import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface INomenclature extends IEntityVersioning {
  name: string;
  shortName: string;
  code: string;
  description: string;
  typeId: number;
  measureUnitId: number;
  cosguId: number;
  kvrId: number;
  cfoId: number;
  mnn: string;
  dosage: string;
  packing: string;
  dosageForm: string;
  signZhnvlp: boolean;
  existLp: boolean;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type INomenclatureOptional = Partial<INomenclature>;

@classBackend('Nomenclature', 'pfhd_directories')
@className('Nomenclature')
/** Модель БД таблицы pfhd_directories.Nomenclatures (КВР) */
export class Nomenclature implements INomenclature {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public name: string,
              public shortName: string,
              public code: string,
              public description: string,
              public typeId: number,
              public measureUnitId: number,
              public cosguId: number,
              public kvrId: number,
              public cfoId: number,
              public mnn: string,
              public dosage: string,
              public packing: string,
              public dosageForm: string,
              public signZhnvlp: boolean,
              public existLp: boolean,
              public timestamp: [],
  ) {
  }
}
