import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { SettingsReaderService } from "../../services/classes/service-settings/settings-reader/settings-reader.service";
import { IDirectoryEditNotVersioningComponent } from "../i-directory-edit-not-versioning.component";

@Injectable()
@traceClass('DirectoryEditNotVersioningComponentService')
export class DirectoryEditNotVersioningComponentService extends ComponentServiceBase<IDirectoryEditNotVersioningComponent> {

  constructor(private readonly tracer: TracerServiceBase) {super()}

  private srs = new SettingsReaderService();

  /** Инициализация сервиса */
  @traceFunc()
  public initialize() {
    this.setLabelTitle();
    this.initForm();
    this.autofocus();
  }

  /** Автофкусировка на контроле компонента (если она возможно и необходима) */
  @traceFunc()
  public autofocus() {
    //Execute .focus() function for element with class .popup-edit-control
    if(this.component.field.settings.autoFocus !== false)
    {
      setTimeout(() => {
        const mainControl = this.component.componentElementRef.nativeElement.querySelector(".popup-edit-control");
        const childInput = mainControl?.querySelector("input");
        const controlElement = childInput ? childInput : mainControl;
        if(controlElement) {
          controlElement.focus();
        }
      }, 1);
    }
  }

  /** Установить labelTitle из переданных настроек */
  @traceFunc()
  public setLabelTitle() {
    this.component.labelTitle = this.srs.getValue(this.component.field.settings.title);
  }

  /** Инициализация формы редактирования */
  @traceFunc()
  public initForm() {
    this.component.formControl = new UntypedFormControl(this.component.value);
    this.component.form = new UntypedFormGroup({Value: this.component.formControl});
  }

  /** Событие нажатия кнопки сохранения */
  @traceFunc()
  public onClickSave() {
    this.component.onSaveEventEmitter.emit(this.component.formControl.value);
  }
}
