import {Observable} from "rxjs";
import {bufferWhen, debounceTime} from "rxjs/operators";

/** Оператор начинает буфферизацию от первого сигнала и продолжает буфферизацию пока от последнего сигнала не пройдет переданное количество миллесекунд */
export function exBufferDebounce<O>(debounceTimeInterval: number): (source$: Observable<O>) => Observable<O[]>{
  return source$ => {
    const stop$ = source$.pipe(debounceTime(debounceTimeInterval))
    return source$.pipe(bufferWhen(() => stop$))
  }
}
