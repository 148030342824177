import {DateOnly} from "../../Dates/date-onlys/date-only";

/**
 * Класс обвертка над {@link Map}<{@link number}, {@link TValue}> позволяющий использовать ключ типом {@link Date}<br>
 * Преобразует ключ {@link Date} в {@link number}<br>
 * Решает проблему сравнения типа {@link Date}, так как он сравнивается по ссылке, а не по значению<br>
 */
export class DateMap<TValue> implements Map<Date, TValue> {
  /** {@link Map} хранилище */
  private readonly internalMap: Map<number, TValue>;

  /** @inheritdoc */
  get [Symbol.toStringTag]() {
    return 'DateMap';
  }

  /** @inheritdoc */
  public get size(): number {
    return this.internalMap.size;
  }

  /**
   * Конструктор
   * @param map {@link Map} над которым будет происходить обвертка
   */
  constructor(private readonly map: Map<number, TValue> = undefined) {
    this.internalMap = !map
      ? new Map<number, TValue>()
      : map;
  }

  /** @inheritdoc */
  public clear(): void {
    this.internalMap.clear();
  }

  /** @inheritdoc */
  public delete(date: Date): boolean {
    return this.internalMap.delete(this.dateToNumber(date));
  }

  /** @inheritdoc */
  public entries(): IterableIterator<[Date, TValue]> {
    const iterator = this.internalMap.entries();
    const numberToDate = this.numberToDate.bind(this); // Биндим метод

    return {
      [Symbol.iterator]() {
        return this;
      },
      next(): IteratorResult<[Date, TValue]> {
        const result = iterator.next();
        if (result.done) {
          return { done: true, value: undefined };
        }
        const [key, value] = result.value;
        return { done: false, value: [numberToDate(key), value] };
      }
    };
  }

  /** @inheritdoc */
  public forEach(callbackfn: (value: TValue, key: Date, map: Map<Date, TValue>) => void, thisArg?: any): void {
    this.internalMap.forEach((value, key) => {
      callbackfn.call(thisArg, value, this.numberToDate(key), this);
    });
  }

  /** @inheritdoc */
  public get(date: Date): TValue | undefined {
    return this.internalMap.get(this.dateToNumber(date));
  }

  /** @inheritdoc */
  public has(date: Date): boolean {
    return this.internalMap.has(this.dateToNumber(date));
  }

  /** @inheritdoc */
  public keys(): IterableIterator<Date> {
    const iterator = this.internalMap.keys();
    const numberToDate = this.numberToDate.bind(this); // Биндим метод

    return {
      [Symbol.iterator]() {
        return this;
      },
      next(): IteratorResult<Date> {
        const result = iterator.next();
        if (result.done) {
          return { done: true, value: undefined };
        }
        return { done: false, value: numberToDate(result.value) };
      }
    };
  }

  /** @inheritdoc */
  public set(date: Date, value: TValue): this {
    this.internalMap.set(this.dateToNumber(date), value);
    return this;
  }

  /** @inheritdoc */
  public values(): IterableIterator<TValue> {
    return this.internalMap.values();
  }

  /** @inheritdoc */
  [Symbol.iterator](): IterableIterator<[Date, TValue]> {
    return this.entries();
  }

  /** Конвертировать {@link Date} в {@link number} */
  private dateToNumber(date: Date): number {
    return date.getTime();
  }

  /** Конвертировать {@link number} в {@link Date} */
  private numberToDate(timestamp: number): Date {
    return new Date(timestamp);
  }

  /** Создать */
  public static Create<TValue>(iterable: Iterable<readonly [number, TValue]>): DateMap<TValue>{
    const map = new Map<number, TValue>(iterable);
    return new DateMap(map);
  }

  /** Создать */
  public static Create2<TValue, TResult>(sources: Iterable<TValue>, keyGetter: (item: TValue) => number, valueGetter: (value) => TResult){
    return this.Create(internal());

    function* internal(): Generator<[number, TResult]>{
      for (let source of sources) {
        yield [keyGetter(source), valueGetter(source)];
      }
    }
  }
}
