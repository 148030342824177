<ng-container [ngSwitch]="componentTypeString">
  <ng-container *ngSwitchCase="'TextBoxFieldControl'">
    <app-textbox-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-textbox-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'NumberFieldControl'">
    <app-number-directory-edit-control [control]="formControl" [settings]="fieldControlSettings"></app-number-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'TextAreaFieldControl'">
    <app-textarea-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-textarea-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'DateFieldControl'">
    <app-date-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-date-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'DateOnlyFieldControl'">
    <app-date-only-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-date-only-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'DropDownListFieldControl'">
    <app-dropdown-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-dropdown-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'DropDownTreeFieldControl'">
    <app-dropdowntree-directory-edit-control [control]="formControl" [settings]="field.control.settings" [field]="field" [appendContainer]="appendContainer"></app-dropdowntree-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'ComboboxFieldControl'">
    <app-combobox-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-combobox-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'MaskedTextBoxFieldControl'">
    <app-masked-textbox-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-masked-textbox-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'CheckboxFieldControl'">
    <app-checkbox-directory-edit-control [control]="formControl" [settings]="field.control.settings"></app-checkbox-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchCase="'CustomFieldControl'">
    <app-custom-directory-edit-control [templateRef]="customTemplateRef"  [control]="formControl" [appendContainer]="appendContainer"></app-custom-directory-edit-control>
  </ng-container>
  <ng-container *ngSwitchDefault>
    Контрол не найден
  </ng-container>
</ng-container>
