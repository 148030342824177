import { SubdivisionsTreelistComponentDataSourceServiceBase } from "../../subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {
  PrintFormMilkOrderSettings
} from "../../../services/webApi/webApi1/controllers/api1-print-report-controller.service";
import {ReplaySubject} from "rxjs";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {DateHelper} from "../../../helpers/dateHelper";
import {CheckedListItemsValidator} from "../report-settings-form-validators";
import {takeUntil} from "rxjs/operators";
import {trace} from "../../../modules/trace/operators/trace";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";

@Component({
  selector: 'app-milk-order-settings',
  templateUrl: './milk-order-settings.component.html',
  styleUrls: ['./milk-order-settings.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('MilkOrderSettingsComponent')
export class MilkOrderSettingsComponent implements OnInit, OnDestroy{

  public form: FormGroup<MilkOrderForm>;

  private _subdivisionDataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>;
  @Input()
  public get subdivisionDataSourceService() : SubdivisionsTreelistComponentDataSourceServiceBase<any> {
    return this._subdivisionDataSourceService;
  }
  public set subdivisionDataSourceService(value: SubdivisionsTreelistComponentDataSourceServiceBase<any>) {
    this._subdivisionDataSourceService = value;
  }

  @Input() public selection;

  // Настройки checkBox TreeView списка подразделений
  @Input()
  public checkableSettings: CheckableSettings;

  /** Функция формирования отображаемой в treeView строки из объекта ISubdivision */
  @Input() public displayTextFn: (subdivision: ISubdivision) => string;

  /** Развернуть (отобразить) все элементы списка подразделений при отображении формы настроек */
  @Input() public expandAllSubdivisions: boolean;

  /** Изменение даты, по которой формируются данные (необходимо для выполнения перезагрузки списка подразделений, сформированного на конкретную дату)*/
  @Output() dateChange$: EventEmitter<Date> = new EventEmitter<Date>();
  /** Нажата кнопка отмены */
  @Output() cancel$: EventEmitter<void> = new EventEmitter<void>();
  /** Нажата кнопка подтверждения выбранных/изменненных настроек */
  @Output() print$: EventEmitter<PrintFormMilkOrderSettings> = new EventEmitter<PrintFormMilkOrderSettings>();

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);


  constructor(private readonly traceService: TracerServiceBase) {  }

  public ngOnInit() {
    this.formInit();
  }

  /** Установить значение параметра выбранных подразделений */
  @traceFunc()
  public setCheckedSubdivisionsValue(el: number[]){
    this.form.controls.checkedSubdivisions.setValue(el);
  }

  /** Установить значение параметра "Выбрать все подразделения" */
  @traceFunc()
  public setAllSubdivisionsFlagValue(el: boolean){
    this.form.controls.allSubdivisionsFlag.setValue(el);
  }

  /** Событие нажатия на кнопку подтверждения выбранных/измененных настроек */
  @traceFunc()
  public onClickOk() {
    this.print$.next(new PrintFormMilkOrderSettings(
      '', // будет заполнено в обработчике событий диалогового окна
      this.form.controls.date.value,
      this.form.controls.milkCost.value,
      this.form.controls.allSubdivisionsFlag.value,
      this.form.controls.checkedSubdivisions.value));
  }

  /** Событие нажатия на кнопку Отмена */
  @traceFunc()
  public onClickCancel() {
    this.cancel$.next();
  }

  public ngOnDestroy(): void {
    this.print$.complete();
    this.cancel$.complete();
    this.dateChange$.complete();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /** Инициализация формы окна настроек печатной формы */
  private formInit() {
    this.form = new FormGroup({
      date: new FormControl(DateHelper.getStartOfMounth(new Date(), true), {validators: Validators.required, updateOn: "blur"}),
      milkCost: new FormControl(0, [Validators.required, Validators.min(0.01)]),
      allSubdivisionsFlag: new FormControl(false),
      checkedSubdivisions: new FormControl([], CheckedListItemsValidator()),
    });

    this.form.controls.date.valueChanges.pipe(trace(this.traceService), takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.dateChange$.emit(value);
      });

    this.form.controls.date.updateValueAndValidity({emitEvent: true});
    this.form.controls.date.markAsTouched();
  }
}

interface MilkOrderForm {
  date: FormControl<Date>;
  milkCost: FormControl<number>;
  allSubdivisionsFlag: FormControl<boolean>;
  checkedSubdivisions: FormControl<number[]>;
}
