import {ResponseObjError} from "./responseObjError";

/** Стандартизированный ответ сервера */
export class ResponseObj<TErrorData, TData> {
  /**
   * Данные ошибки сервера
   * если null то все прошло штатно
   */
  public error: ResponseObjError<TErrorData>;
  /**
   * Данные ответа
   * Если произошла ошибка сервера то null
   */
  public data: TData;

  /** Проверяет, является ли объект данным типом */
  public static checkType(source: any): source is ResponseObj<any, any> {
    return source && 'error' in source && 'data' in source && (!source.error || ResponseObjError.checkType(source.error));
  }
}
