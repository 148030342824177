import { Component } from '@angular/core';
import {MedsoftlabService} from "../../../../src/app/services/medsoftlab.service";
import {BrowserDetectionService} from "../../../../src/app/services/browser-detection-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(medSoftLab: MedsoftlabService, browserDetectionService: BrowserDetectionService) {
    browserDetectionService.checkBrowser();
  }
}
