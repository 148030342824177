import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { WebApi1Service } from '../../web-api1.service';
import { VersioningItem } from 'src/app/classes/directories/edit/versioningItem';
import { HttpParamsHelper } from 'src/app/helpers/httpParamsHelper';
import { ILimitStaffUnitRate, LimitStaffUnitRate } from 'src/app/classes/domain/POCOs/stafflist/LimitStaffUnitRate';
import {EditEndDateDto, IdAndRowVersionType} from "../shared-DTOs/edit-end-date-dto";
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

/** Сервис работы с контроллером LimitStaffUnitRate */
@Injectable({
  providedIn: 'root'
})
export class Api1LimitStaffUnitRateControllerService {

  constructor(private webApi1Service: WebApi1Service, private httpClient: HttpClient) {
  }

  /** Добавить */
  public add$(entity: AddLimitStaffUnitRateDTO): Observable<LimitStaffUnitRate> {
    return this.httpClient
      .post<LimitStaffUnitRate>(this.webApi1Service.controllers.limitStaffUnitRate.actions.add.toString(), entity)
  }

  //#region Versioning

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]> {
    return this.httpClient
      .get<Array<VersioningItem>>(this.webApi1Service.controllers.limitStaffUnitRate.actions.getVersionsByOwnerIdAndActionId.toString(),
        {
          params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
        })
  }

  public addVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.httpClient
      .post<VersioningItem>(this.webApi1Service.controllers.limitStaffUnitRate.actions.addVersion.toString(),
        {
          actionId,
          ownerId: versioningEntity.ownerId,
          data: versioningEntity
        })
  }

  public editVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.httpClient
      .post<VersioningItem>(this.webApi1Service.controllers.limitStaffUnitRate.actions.editVersion.toString(),
        {
          actionId,
          ownerId: versioningEntity.ownerId,
          data: versioningEntity
        })
  }

  public deleteVersion$(versionId: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(this.webApi1Service.controllers.limitStaffUnitRate.actions.deleteVersion.toString(),
        { params: HttpParamsHelper.getParams([['versionId', versionId]]) }
      )
  }

  //#endregion

  /** Редактировать окончание срока действия */
  public editEndDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.limitStaffUnitRate.actions.editEndDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }
}

export type AddLimitStaffUnitRateDTO = Pick<ILimitStaffUnitRate, 'startDate' | 'endDate' | 'comment' | 'limitStaffUnitId' | 'financingSourceId' | 'value'>;
