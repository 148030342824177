import { Injectable } from '@angular/core';
import { WebApi1Service } from '../web-api1.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDropDownItem } from '../../../../classes/requestResults/iDropDownItem';
import {map, shareReplay} from 'rxjs/operators';
import { IEntityId } from '../../../../classes/domain/POCOs/interfaces/IEntityId';
import { IEntityRowVersion } from '../../../../classes/domain/POCOs/interfaces/IEntityRowVersion';
import {DayType} from "../../../../classes/domain/POCOs/timesheet/DayType";

/** Сервис работы с контроллером DateController */
@Injectable({
  providedIn: 'root',
})
export class Api1DateControllerService {

  private _years$: Observable<IDropDownItem[]> = null;
  private _months$: Observable<IDropDownItem[]> = null;

  /** Получить доступные года */
  public get getYears$() {
    if (!this._years$) {
      this._years$ = this.httpClient
        .get<number[]>(this.webApi1Service.controllers.date.actions.getYears.toString())
        .pipe(
          map(value => {
            return value.map(year => {
              return {
                id: year,
                text: year.toString(),
              }
            })
          }),
          shareReplay(1)
        );
    }

    return this._years$;
  }

  /** Получить месяца */
  public get getPosibleMonth$() {
    if (!this._months$) {
      this._months$ = this.httpClient
        .get<IDropDownItem[]>(this.webApi1Service.controllers.date.actions.getPosibleMonth.toString())
        .pipe(shareReplay(1));
    }

    return this._months$;
  }

  /** Конструктор */
  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  public getCalendarDaysForYear$(year: number, ids: number[] = null): Observable<CalendarDayModel[]> {
    return this.httpClient
      .post<CalendarDayModel[]>(
        this.webApi1Service.controllers.date.actions.getCalendarDaysForYear.toString(),
        { year, ids },
      );
  }

  public setDayType$(id: number, dayTypeId: number, timestamp: []): Observable<[]> {
    return this.httpClient
      .post<[]>(
        this.webApi1Service.controllers.date.actions.setDayType.toString(),
        { id, dayTypeId, timestamp },
      );
  }
}

export interface CalendarDayModel extends IEntityId, IEntityRowVersion {
  date: Date;
  dayTypeItem: Pick<DayType, 'id' | 'name' | 'color'>;
}
