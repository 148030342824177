<kendo-drawer-container>
  <kendo-drawer #drawer
                mode="overlay"
                [mini]="true"
                [expanded]="false"
  >
    <ng-template kendoDrawerTemplate>
      <div kendoTooltip
           position="right"
           class="menu-button-container"
      >
        <button kendoButton
                class="custom-button-in-menu"
                title="Раскрыть/Свернуть меню"
                icon="menu"
                look="flat"
                (click)="drawer.toggle()"
        ></button>
      </div>

      <kendo-menu class="workspace-drawer-menu" [class.workspace-drawer-menu-expanded]="drawer.expanded" (select)="onSelect($event)" [items]="items" [vertical]="true" style="display:inline-block;">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div kendoTooltip position="right" [title]="!drawer.expanded && !item.child && !item.items?.length ? (item.title || item.text) : (item.title || '')" class="workspace-drawer-menu-item" [class.workspace-drawer-menu-item-active]="!item?.disableIsActive && isActive(item.routPath)">
            <div *ngIf="!item.child">
              <kendo-icon [name]="item.icon" size="default"></kendo-icon>
            </div>

            <div class="item-descr-wrap" *ngIf="drawer.expanded || item.child">
              <span class="item-descr">{{ item.text }}</span>
            </div>
          </div>
        </ng-template>
      </kendo-menu>

      <div class="panel-footer">
        <div class="left-menu-info-container">
          <span class="vertical-text program-name">{{workspaceService.programName}}:</span>
          <span class="vertical-text user-name">{{userFio}}</span>
        </div>
        <button kendoButton
                class="custom-button-in-menu"
                title="Выход"
                icon="logout"
                look="flat"
                (click)="logout()"
        ></button>
      </div>
    </ng-template>
  </kendo-drawer>

  <kendo-drawer-content class="!k-h-screen">
    <div class="!k-d-flex !k-max-w-screen">
      <div style="min-width: 50px;"></div>
      <div class="!k-d-flex !k-flex-col !k-h-screen !k-w-full !k-pos-relative !k-overflow-hidden">
        <div class="!k-overflow-auto">
          <ng-container [ngTemplateOutlet]="workspaceHeaderTemplateDirective?.templateRef"></ng-container>
        </div>
        <div class="!k-overflow-auto !k-flex-1" style="margin: 0 5px">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </kendo-drawer-content>
</kendo-drawer-container>


