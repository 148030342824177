import {RootPathHelper} from "../../helpers/rootPathHelper";

/**
 * Класс маршрутизации angular
 */
export class RootPathItem {
  /**
   * Название текущего
   */
  name: string;
  /**
   * Функция получения родительского root
   */
  parent: () => RootPathItem;

  /**
   * Конструктор
   */
  constructor(name: string, parent: () => RootPathItem) {
    this.name = name;
    this.parent = parent;
  }

  /**
   * Построить путь до данного рута
   */
  public toString(): string{
    return this.parent ? RootPathHelper.join(this.parent().toString(), this.name) : this.name;
  }
}
