<form class="k-form" [formGroup]="form" *ngIf="form">

<div class="form-start-end-date-div">
  <kendo-formfield>
    <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
    <kendo-datepicker #startDate formControlName="startDate"></kendo-datepicker>
    <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
    <kendo-datepicker #endDate formControlName="endDate" [min]="form.controls.startDate.value"></kendo-datepicker>
    <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
  </kendo-formfield>
</div>

  <div>
    <kendo-formfield *ngIf="!form.value.isAutogenerateCode">
      <kendo-label [for]="code" text="Код должности"></kendo-label>
      <input kendoTextBox inputTrim #code formControlName="code" [maxlength]="occupationCodeLength$ | async" />
      <kendo-formerror *ngIf="form.controls.code.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.code.errors?.minlength">Длина значения должна быть больше {{form.controls.code.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.code.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.code.errors?.maxlength">Длина значения должна быть меньше {{form.controls.code.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.code.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield class="horizontal-form-field">
      <kendo-label [for]="isAutogenerateCode" text="Генерировать код должности автоматически:"></kendo-label>
      <input type="checkbox" kendoCheckBox #isAutogenerateCode formControlName="isAutogenerateCode" (change)="form.controls.code.updateValueAndValidity();"/>
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="name" text="Наименование должности "></kendo-label>
    <input kendoTextBox inputTrim #name formControlName="name" />
    <kendo-formerror *ngIf="form.controls.name.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.minlength">Длина значения должна быть больше {{form.controls.name.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.maxlength">Длина значения должна быть меньше {{form.controls.name.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="occupationTypeId" text="Категория должности"></kendo-label>
    <kendo-dropdowntree #occupationTypeId
                        kendoDropDownTreeExpandable
                        [kendoDropDownTreeFlatBinding]="occupationTypes$ | async"
                        parentIdField="parentId"
                        valueField="id"
                        textField="name"
                        formControlName="occupationTypeId"
                        [valuePrimitive]="true"
                        [popupSettings]="{width:'auto'}"
                        [dataItem]="{}"
                        [filterable]="true"
                        expandBy="id"
    >
    </kendo-dropdowntree>
    <kendo-formerror *ngIf="form.controls.occupationTypeId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>


  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment formControlName="comment" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="codesIdsArray" text="Коды табельного учёта"></kendo-label>
    <app-codes-grid #codesIdsArray formControlName="codesIdsArray" [data]="codes$ | async" [height]="250"></app-codes-grid>
  </kendo-formfield>


  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

