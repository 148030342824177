/**
 * Базовый класс сервиса хранения данных.
 * Используется для localStorage и sessionStorage
 */
export abstract class CustomStorageService {
  /** Получить данные из хранилища */
  public abstract get<T>(key: StorageOptions): T

  /** Записать данные в хранилище */
  public abstract set<T>(key: StorageOptions, value: T)

  /** Удалить запись по ключу */
  public abstract remove(key: StorageOptions)

  /**
   * Очищает данные storage относящиеся к этой программе и компании
   * @param andIsSaved Удалять ли данные помеченные как isSaved
   */
  public abstract clear(andIsSaved: boolean)
}



/** Перечисление возможных мест хранения данных */
export enum StorageLocationEnum {
  /** Хранение данных будет в localStorage */
  LocalStorage = 1,
  /** Хранение данных будет в sessionStorage */
  SessionStorage = 2
}

/** Ключ хранения данных */
export class StorageOptions {
  /**
   *
   * @param location Место хранение
   * @param key Дополнительный ключ для хранения разных данных под этой программой
   * @param liveSeconds Количество секунд после чего данные становятся не актуальными. null - без срока
   * @param isConfidentialData Конфедициальные ли данные будут храниться?
   * @param isSaved Являются ли данные защищенные от очищения. Если да то если будет очистка storage сервисом то они не будет удалены
   */
  constructor(public readonly location: StorageLocationEnum,
              public readonly key: string,
              public readonly liveSeconds: number,
              public readonly isConfidentialData: boolean = true,
              public readonly isSaved: boolean = false,) {
  }
}
