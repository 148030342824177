import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { PropertyWrapFormControlType } from "src/app/classes/types/property-wrap-form-control-type";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";

/** Тип формы добавления */
export type DismissStaffUnitFormComponentForm = PropertyWrapFormControlType<{
  date: Date;
}>;

@Component({
  selector: 'app-dismiss-staff-unit-form',
  templateUrl: './dismiss-staff-unit-form.component.html',
  styleUrls: ['./dismiss-staff-unit-form.component.css']
})
@traceClass('DismissStaffUnitFormComponent')
export class DismissStaffUnitFormComponent implements OnInit {

  @Output("onDismiss") public onDismissEventEmitter = new EventEmitter<FormGroup<DismissStaffUnitFormComponentForm>['value']>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<void>();

  public form: FormGroup<DismissStaffUnitFormComponentForm>;
  /** Дата приема на работу */
  public admissionDate: Date;

  constructor(
    private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.form = this.initForm();
  }

  /** Событие происходит при нажатии на кнопку увольнения */
  @traceFunc()
  onClickDismiss() {
    const values = this.form.getRawValue();
    this.tracerService.add2('Нажата кнопка увольнения', { obj: values, maxLength: 1000 })
    this.onDismissEventEmitter.emit(values);
  }

  /** Событие происходит при нажатии на кнопку отменить */
  @traceFunc()
  onClickCancel() {
    this.onCancelEventEmitter.emit();
  }

  @traceFunc()
  private initForm(): FormGroup<DismissStaffUnitFormComponentForm> {
    const dateControl = new FormControl<Date>(null, { validators: [Validators.required] });

    const form = new FormGroup({
      date: dateControl
    });

    form.markAllAsTouched();

    form.valueChanges.subscribe(value => {
      this.tracerService.add2('Изменена форма', { obj: value, maxLength: 1000 })
    })

    return form;
  }
}
