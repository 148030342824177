<app-header text="Печатные формы">
  <kendo-tabstrip (tabSelect)="printFormType = tabs[$event.index].type">
    <kendo-tabstrip-tab *ngFor="let tab of tabs" [title]="tab.title" [selected]="tab.selected">
      <ng-template kendoTabContent>
        <kendo-grid
              [kendoGridBinding]="printFormList$ | async"
              (edit)="print($event)"
              KendoGridExpanded
              (dbCellClick)="print($event.originalEvent)"
        >
          <kendo-grid-command-column [width]="62">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <button
                    kendoGridEditCommand
                    [primary]="true"
                    [title]="getPrintButtonTitle(dataItem)"
              >
                <span class="k-icon k-i-print k-font-icon"></span>
              </button>
            </ng-template>
          </kendo-grid-command-column>

          <kendo-grid-column field="name" title="Наименование"> </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <div kendoDialogContainer></div>
</app-header>
