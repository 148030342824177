<div class="wrap" [formGroup]="form">
  <label class="k-checkbox-label" for='dateFrom'>Дата: </label>
  <kendo-datepicker
    id = "dateFrom"
    style="width: 100%"
    formControlName="date"
    [format]="datePickerViewParams.format"
    [activeView]="datePickerViewParams.activeView"
    [bottomView]="datePickerViewParams.deepBottomView"
    [topView]="datePickerViewParams.deepTopView"
  >
    <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
  </kendo-datepicker>
  <ng-content select="[title1]"></ng-content>
  <ng-content select="[component1]"></ng-content>
</div>

<app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                         [selection]="selection"
                                         [expandAll]="expandAllSubdivisions"
                                         [checkableSettings] ="checkableSettings"
                                         [displayTextFn]="displayTextFn"
                                        (checkedSubdivisions$)="setCheckedSubdivisionsValue($event)"
                                        (allSubdivisions$)="setAllSubdivisionsFlagValue($event)">
</app-subdivision-treeview-with-checkbox2>

<div class="k-form-buttons" style="justify-content: flex-end;">

  <button kendoButton
          type="button"
          primary="true"
          [disabled] = "form.invalid"
          (click)="onClickOk()"
  >
    Сформировать
  </button>

  <button kendoButton
          type="button"
          (click)="onClickCancel()"
  >
    Отменить
  </button>

</div>


