import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { ReplaySubject} from "rxjs";
import { HierarchiString,  HierarchiStringSmall
} from "src/app/classes/requestResults/EditEntityWithDependenciesErrorData";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Component({
  selector: 'app-hierarchi-strings-conflicts',
  templateUrl: './hierarchi-strings-conflicts.component.html',
  styleUrls: ['./hierarchi-strings-conflicts.component.css']
})
@traceClass('HierarchiStringsConflictsComponent')
export class HierarchiStringsConflictsComponent extends DialogContentBase {

  public hierarchiStrings$: ReplaySubject<HierarchiStringSmall[]> = new ReplaySubject<HierarchiStringSmall[]>(1);
  @Input() public set hierarchiStrings(value: HierarchiStringSmall[] | HierarchiString[]) {
    if(value && 'ParentString' in value[0]){
      this.hierarchiStrings$.next(HierarchiStringSmall.createFromHierchiStrings(value as HierarchiString[]));
      return;
    }

    this.hierarchiStrings$.next(value as Array<HierarchiStringSmall>);
  }

  @Output("cancel") public cancel = new EventEmitter<void>();
  @Output("continue") public continue = new EventEmitter<void>();

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(
    private readonly dialogRef: DialogRef,
    private readonly tracerService: TracerServiceBase) {
      super(dialogRef)
  }

  @traceFunc()
  public onClickContinue() {
    this.continue.emit();
  }

  @traceFunc()
  public onClickCancel() {
    this.cancel.emit();
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
