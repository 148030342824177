import { OnDestroy } from "@angular/core";
import { filter, Observable, ReplaySubject, share, take, takeUntil } from "rxjs";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { ObjectHelper } from "src/app/helpers/objectHelper";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { TraceParamEnum } from "src/app/modules/trace/decorators/classes/traceSetting.interface";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { traceParam } from "src/app/modules/trace/decorators/param.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { NotVersioningField } from "../classes/fields/not-versioning-field";
import { DirectoryEditSettings_NotVersioning } from "../classes/service-settings/directory-edit-settings-not-versioning";
import { DirectoryEditNotVersioningService } from "../directory-edit-not-versioning.service";

/** Сервис для редактирования неверсионных полей */
@traceClass('DirectoryEditRef_NotVersioning')
export class DirectoryEditRef_NotVersioning implements OnDestroy {

  public onSaved$ = new ReplaySubject<any>(1);
  public onClose$: Observable<any>;

  private _streams$:{
    unsubscribes: ReplaySubject<any>
  }={
    unsubscribes: new ReplaySubject<any>(1)
  }
  constructor(private readonly settings: DirectoryEditSettings_NotVersioning,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly service: DirectoryEditNotVersioningService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase,
              private readonly fields: Array<NotVersioningField>
  )
  {
    this.onClose$ = this.service.onClose$;

    //Подписываемся на событие сохранения
    this.service.onSave$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes)).subscribe(data=> {
      this.onNoVersioningDataItemSaved(data.value, data.field, data.dataItem);
    });
  }

  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public editField(@traceParam() field: NotVersioningField, @traceParam({maxLength: 200}) dataItem: any, popupToElement: any) {
    this.service.edit(dataItem, dataItem[field.name], field, popupToElement);
  }

  //Закрыть все попапы
  @traceFunc()
  public close() {
    this.service.close();
  }

  /** Событие происходит у полей не поддерживаемых версионность при сохранении */
  @traceFunc({traceParamType: TraceParamEnum.traceAll})
  private onNoVersioningDataItemSaved(value:any, field: NotVersioningField, dataItem: any) {

    let objectForSave = ObjectHelper.deepCopy(dataItem);
    objectForSave[field.name] = value;

    this.settings.saveNoVersioning$(objectForSave, field)
    .pipe(trace(this.tracerService), take(1),takeUntil(this._streams$.unsubscribes)).subscribe({
      next: newRowVersion => {
        dataItem[field.name] = value;
        //К сожалению, временный костыль. Его нужно убрать когда будут переведены все контролы на обновление через signalR
        if(Array.isArray(newRowVersion)){
          dataItem.timestamp = newRowVersion;
        }
        this.onSaved$.next(dataItem);
        this.showSaveNotificationMessage(dataItem);
      },
      error: error => this.displayErrorsService.handleError(error)});
    this.service.close();
  }

  @traceFunc()
  public ngOnDestroy() {
    this._streams$.unsubscribes.next(null);
    this._streams$.unsubscribes.complete();
    this.onSaved$.complete();
  }

  /** Отображает информационное сообщение (после сохранения данных не поддерживаемых версионность) */
  @traceFunc()
  private showSaveNotificationMessage(dataItem: any) {

    let notificationText = (this.settings.isNeedHideData && this.settings.isNeedHideData(dataItem)) ? "Данные сохранены, но редактируемая строка была скрыта, т.к. не попадает в диапазон дат выборки" :"Данные успешно сохранены";
    //Если задана дата начала и/или дата окончания, нужно проверить продолжает ли попадать элемент в диапазон дат.
    this.kendoNotificationService.showSuccess({content: notificationText});
  }
}
