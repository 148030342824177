import {Guid} from "guid-typescript";
import {ITraceAddParams} from "../../decorators/classes/traceSetting.interface";
import {TraceStorageServiceBase} from "../trace-storage-services/trace-storage-base.service";

/** Класс управления/хранения табуляции(/t) */
export class TracerLevelManager{
  private _count: number = 0;
  /** Количество табов в начале строки */
  public get count(): number{
    return this._count;
  }

  constructor(level: number = 0) {
    if(level < 0){
      throw new Error('Не допускается меньше нуля')
    }

    if(level){
      this._count = level;
    }
  }

  /** Добавить отступ */
  public add(){
    this._count = this._count + 1
  }

  /** Убрать отступ */
  public remove(){
    if(this._count < 1){
      return;
    }

    this._count = this._count - 1;
  }

  /** Копировать */
  public copy(){
    return new TracerLevelManager(this.count);
  }
}

/** Значение поля по которому определяется - является ли объект TracerBase */
const _isTraceBase_value = '#43AklIlkd';

/** Базовый класс трассировщика */
export abstract class TracerServiceBase{
  /** Защитник типа */
  private static readonly _isTraceBase_ = _isTraceBase_value;

  /** Идентификатор трассировщика */
  public readonly guid = Guid.create();

  protected _tracerLevelManager: TracerLevelManager = new TracerLevelManager(0);
  /** Управление уровнем отсупа */
  public get tracerLevelManager(){
    return this._tracerLevelManager;
  }

  /** Хранилище трассировки */
  public abstract readonly storageService: TraceStorageServiceBase;

  /** Отключен ли трассировщик */
  public isDisabled: boolean = false;

  protected _defaultParamTraceMaxLength: number = 200;
  /** Максимально допустимая длина одного параметра метода в вывод. По умолчанию 100 символов */
  public get defaultParamTraceMaxLength(){
    return this._defaultParamTraceMaxLength;
  }
  public set defaultParamTraceMaxLength(value){
    if(!value){ throw new Error('defaultParamTraceMaxLength не может быть null | undefined | 0')}
    if(value < 0) {throw new Error('defaultParamTraceMaxLength не может быть < 0')}

    this._defaultParamTraceMaxLength = value;
  }

  /** Копировать. Использовать если необходимо создать независимый трассировщик */
  public abstract copy(): TracerServiceBase;

  /**
   * Регистрирует асинхронность.
   * Вернет копию трайсера и добавит сообщения о регистрации с guid нового трейсера для удобного поиска
   */
  public registerAsync(): TracerServiceBase{
    const newTracer = this.copy();
    this.add(`регистрация асинхронности: ${newTracer.guid}`)
    newTracer.tracerLevelManager.add();

    return newTracer;
  }

  /**
   * Добавить в буфер
   * @param message Сообщение
   */
  public abstract add(message: string);

  public abstract add2(message?: string, ...params: ITraceAddParams<any>[]);

  /** Добавить пустую строку(как разделитель) */
  public abstract addNewLine();

  /** Является ли переданный */
  public static IsTraceBase(obj: any): boolean{
    if(typeof obj != 'object' || !obj){
      return false;
    }

    return obj.constructor?._isTraceBase_ === _isTraceBase_value;
  }
}
