import {IEntityId} from "../../interfaces/IEntityId";
import {IEntityModify} from "../../interfaces/IEntityModify";
import {IEntityRowVersion} from "../../interfaces/IEntityRowVersion";
import {IEntityDeletedFlag} from "../../interfaces/IEntityDeletedFlag";
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface IPlanRow extends IEntityId, IEntityModify, IEntityRowVersion, IEntityDeletedFlag{
  /** Идентификатор заявки */
  requestId: number;

  /** Идентификатор плана */
  planId: number;

  /** Количество */
  count: number;

  /** Цена */
  price: number;

  /** Отправлена ли строка на закупку (доступна для взятия в заявку на закупку) */
  isSendToPurchase: boolean;
}

@classBackend('PlanRow', 'pfhd_plan')
@className('PlanRow')
export class PlanRow implements IPlanRow{
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public timestamp: [],
              public planId: number,
              public requestId: number,
              public count: number,
              public price: number,
              public isSendToPurchase: boolean) {
  }

  /** Максимально допустимое количество знаков после запятой для {@link count} */
  public static readonly CountDigits = 2;

  /** Максимально допустимое количество знаков после запятой для {@link price} */
  public static readonly PriceDigits = 2;
}
