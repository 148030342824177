import {Component, OnInit} from '@angular/core';
import { StaffPositionGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-staff-positions-control.service';
import { CustomStorageService, StorageLocationEnum, StorageOptions } from 'src/app/services/storages/custom-storage.service';
import { StaffUnitGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { ReportDatesComponentSharedService } from 'src/app/components/report-dates/services/report-dates-component-shared.service';
import {ISubdivision} from "../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision";
import { StaffUnitsGridComponentDataSourceService } from '../staff-units/staff-units-grid/services/staff-unts-grid-component-data.service';
import { exErrorHandler } from 'src/app/operators/ex-error-handler';
import { combineLatestWith, map } from 'rxjs';
import { DisplayErrorsService } from 'src/app/components/display-errors/services/display-errors.service';
import { KendoGridArrayDataSourceSelection } from 'src/app/classes/array-data-sources/selections/kendo-grid-array-data-source-selection';
import { exElementByIndexOr } from 'src/app/operators/ex-element-by-index-or';
import { SubdivisionsTreelistComponentDataSourceService } from 'src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service';
import { ReportDateValues } from 'src/app/components/report-dates/classes/report-date-values';
import { trace } from 'src/app/modules/trace/operators/trace';
import { PositionsGridComponentDataSourceService } from '../positions/grid/services/positions-grid-component-data-source.service';
import {
  ArrayDataSourceSelection
} from "../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection";

@Component({
  selector: 'app-stafflist',
  templateUrl: './stafflist.component.html',
  styleUrls: ['./stafflist.component.css'],
  providers:
  [
    ReportDatesComponentSharedService,
    StaffUnitsGridComponentDataSourceService,
    PositionsGridComponentDataSourceService,
    SubdivisionsTreelistComponentDataSourceService
  ]
})
@traceClass('StafflistComponent')
export class StafflistComponent implements OnInit{

  /** Selections */
  public readonly positionsSelection: KendoGridArrayDataSourceSelection<StaffPositionGridItem, number>;
  public readonly staffUnitsSelection: KendoGridArrayDataSourceSelection<StaffUnitGridItem, number>;
  public readonly subdivisionsSelection: ArrayDataSourceSelection<ISubdivision, number>;

  /**
   * Опции хранения информации о выбранных контролах
   */
  private _storageOptionsKey: StorageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'StafflistComponent',
    null,
    true,
    false
  );

  public constructor(
    public readonly staffUnitsDataSourceService: StaffUnitsGridComponentDataSourceService,
    public readonly positionsDataSourceService: PositionsGridComponentDataSourceService,
    public readonly subdivisionsDataSourceService: SubdivisionsTreelistComponentDataSourceService,
    private readonly reportDatesComponentSharedService: ReportDatesComponentSharedService,
    private readonly displayErrorService: DisplayErrorsService,
    private readonly customStorageService: CustomStorageService,
    private readonly tracerService: TracerServiceBase
  )
  {
    this.staffUnitsSelection = new KendoGridArrayDataSourceSelection(this.staffUnitsDataSourceService.dataSource);
    this.positionsSelection = new KendoGridArrayDataSourceSelection(this.positionsDataSourceService.dataSource);
    this.subdivisionsSelection = new ArrayDataSourceSelection(this.subdivisionsDataSourceService.dataSource);
  }

  @traceFunc()
  ngOnInit() {
    //Грузим данные из локал сторейджа
    const pageStateData = this.loadPageStateData();

    //Подписываемся на изменения выделения (чтобы сохранять в локалсторейдж при изменении)
    this.subscribeToChangeSelection(pageStateData);

    //Проставляем выделения из локал сторейджа
    this.setSelectionsFromPageStateData(pageStateData);

    //Инициализируем перезагрузку данных
    this.initializeReloadData();
  }

  /** Функция сохраняет данные состояния страницы */
  @traceFunc()
  private updatePageStateDataInStorage(pageStateData: PageState) {
    this.customStorageService.set(this._storageOptionsKey, pageStateData);
  }

  /** Получить данные о состоянии страницы */
  @traceFunc()
  private loadPageStateData():PageState {
    return this.customStorageService.get(this._storageOptionsKey) ?? new PageState(null,null,null);
  }

  /** Установить выделения на основне данных о состоянии страницы */
  @traceFunc()
  private setSelectionsFromPageStateData(pageStateData: PageState) {
    if(pageStateData.subdivisionId){
      this.subdivisionsSelection.setIds([pageStateData.subdivisionId]);
    }

    if(pageStateData.staffUnitId){
      this.staffUnitsSelection.setIds([pageStateData.staffUnitId]);
    }

    if(pageStateData.staffPositionId){
      this.positionsSelection.setIds([pageStateData.staffPositionId]);
    }
  }

  /** Подписаться на изменения выделений
   * (в данном случае для его последующего сохранения в состояние страницы)
   */
  @traceFunc()
  private subscribeToChangeSelection(pageStateData: PageState){
    this.subdivisionsSelection.selectedIds.data$.pipe(exElementByIndexOr(0, undefined)).subscribe(s=> {
      pageStateData.subdivisionId = s;
      this.updatePageStateDataInStorage(pageStateData);
    });

    this.positionsSelection.selectedIds.data$.pipe(exElementByIndexOr(0, undefined)).subscribe(s=> {
      pageStateData.staffPositionId = s;
      this.updatePageStateDataInStorage(pageStateData);
    });

    this.staffUnitsSelection.selectedIds.data$.pipe(exElementByIndexOr(0, undefined)).subscribe(s=> {
      pageStateData.staffUnitId = s;
      this.updatePageStateDataInStorage(pageStateData);
    });
  }

  /** Инициализация перезагрузки данных в дата сорсах */
  @traceFunc()
  private initializeReloadData() {
    this.subdivisionsDataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          map(value => ReportDateValues.toForDate(value)),
          exErrorHandler(this.displayErrorService),
          trace(this.tracerService)
        )
    ).subscribe();

    this.positionsDataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          combineLatestWith(this.subdivisionsSelection.selectedIds.data$),
          map(value => !!value[1][0] ? { subdivisionId: value[1][0],  ...ReportDateValues.toForDate(value[0])} : undefined),
          exErrorHandler(this.displayErrorService),
          trace(this.tracerService)
        )
    ).subscribe();

    this.staffUnitsDataSourceService.reloadData$(
      this.reportDatesComponentSharedService.reportDateValues$
        .pipe(
          combineLatestWith(this.positionsSelection.selectedIds.data$),
          map(value => !!value[1][0] ? { positionId: value[1][0], ...ReportDateValues.toForDate(value[0]) } : undefined),
          exErrorHandler(this.displayErrorService),
          trace(this.tracerService)
        )
    ).subscribe();
  }
}

/** Класс со значениями состояния страницы */
class PageState {
  constructor(
    /** Выделенный идентификатор подразделения */
    public subdivisionId: number,

    /** Выделенный идентификатор позиции */
    public staffPositionId: number,

    /** Выделенный идентификатор стаффюнита */
    public staffUnitId: number
  ) {}
}
