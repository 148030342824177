<form class="k-form" [formGroup]="form">
  <kendo-formfield>
    <kendo-label [for]="name" text="Наименование периода контроля"></kendo-label>
    <input kendoTextBox #name [formControlName]="'name'" />
    <kendo-formerror *ngIf="form.controls.name.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.minlength">Длина значения должна быть больше {{form.controls.name.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.name.errors?.maxlength">Длина значения должна быть меньше {{form.controls.name.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.name.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment [formControlName]="'comment'" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

