import {Injectable} from "@angular/core";
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ResponseObjError} from "../../../classes/requestResults/responseObjError";
import {ResponseObj} from "../../../classes/requestResults/response-obj";

/**
 * Интерцептор проверяет на стандартизированный ответ {@link ResponseObj}<br>
 *    1. Если объект содержит ошибку: пробросит именно ее дальше. {@link ResponseObj.error}<br>
 *    2. Если объект НЕ содержит ошибку: вернет только данные. {@link ResponseObj.data}<br>
 */
@Injectable({
  providedIn: "root"
})
export class StandardServerResponseInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone())
      .pipe(
        switchMap(value => {
          if (value.type !== HttpEventType.Response) { //Анализируем только ответы сервера
            return of(value);
          }

          const httpResponse = value as HttpResponse<any>;
          const body = httpResponse.body;

          if (!ResponseObj.checkType(body)){ //Если НЕ стандартизированный ответ
            return of(value);
          }

          if (ResponseObjError.checkType(body.error)) { //Если есть стандартизированная ошибка
            return throwError(() => body.error);
          } else { //Если нет ошибки, то отдаем данные из поля .data
            return of(httpResponse.clone({ body: body.data }));
          }
        }
      ));
  }
}

