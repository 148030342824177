import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";
import {IEntityRowVersion} from "../interfaces/IEntityRowVersion";

export interface IDay extends IEntityId, IEntityModify, IEntityRowVersion {
  /** Тип календарного дня (рабочий, выходной, праздничный и пр) */
  dayTypeId: number;
  /** День календаря в формате даты */
  date: Date;
}

@classBackend('Day', 'timesheet')
@className('Day')
/**  Календарный день */
export class Day implements IDay {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public dayTypeId: number,
              public date: Date,
              public timestamp: []
  ) {
  }

  private static _fullComparer: ObjComparer<Omit<IDay, 'timestamp'>>;
  /** Сравнение по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<Omit<IDay, 'timestamp'>>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        dayTypeId: true,
        date: ObjComparer.dateComparer,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IDay, 'modifiedUserId' | 'modifiedDate' | 'timestamp'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      });

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}
