import {Injectable, OnDestroy} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable, ReplaySubject} from "rxjs";
import { WorkModeType } from "src/app/classes/domain/POCOs/stafflist/WorkModeType";

@Injectable({
  providedIn: "root"
})
export class Api1WorkModeTypesControllerService implements OnDestroy{
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {

  }


  /** Получить все */
  public getAll$() : Observable<Array<WorkModeType>>{
    return this.httpClient.get<Array<WorkModeType>>(this.webApi1Service.controllers.workModeType.actions.getAll.toString()
      )
  }


  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
