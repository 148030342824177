import { IEntityComment } from '../../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../../interfaces/IEntityId';
import { IEntityModify } from '../../interfaces/IEntityModify';
import { IEntityRowVersion } from '../../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface ICfoRequestRow extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityRowVersion {
  cfoRequestId: number;
  nomenclatureId: number;
  price: number;
}

@classBackend('CfoRequestRow', 'pfhd_cfo_request')
@className('CfoRequestRow')
/** Модель БД таблицы pfhd_cfo_request.RequestRow (Строки заявки ЦФО) */
export class CfoRequestRow implements ICfoRequestRow {
  /** Максимально допустимое количество знаков после запятой для {@link price} */
  public static readonly PriceDigits = 2;

  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public comment: string,
              public cfoRequestId: number,
              public timestamp: [],
              public nomenclatureId: number,
              public price: number,
  ) {
  }
}
