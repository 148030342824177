import 'reflect-metadata';

/** Ключ хранения метаданных */
const classNameMetadataKey = '_classNameDecorator_'

/**
 * Декоратор позволяет хранить оригинальное название класса в метаданных которое не будет минимизировано компилятором.
 * Для получения метаданных используй метод {@link getClassNameMetadata}
 * @example Если в коде имеется привязка к названию класса.
 */
export function className(name: string) {
  if(!name){
    throw new Error('Параметр `name` должен быть заполнен');
  }

  return function <T extends { new(...constructorArgs: any[]): {} }>(ctor: T) {
    Reflect.defineMetadata(classNameMetadataKey, name, ctor);
    return ctor;
  }
}

/**
 * Получить название класса из метаданных
 * @param ctor Тип
 */
export function getClassNameMetadata<T extends abstract new (...args: any) => any>(ctor: T): string {
  return Reflect.getMetadata(classNameMetadataKey, ctor);
}
