import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OccupationType } from 'src/app/classes/domain/POCOs/stafflist/OccupationType';
import { FormGroup } from '@angular/forms';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { IAddOccupationTypeFormComponent, IAddOccupationTypeFormComponentForm } from './i-add-occupation-type-form.component';
import { AddOccupationTypeFormComponentService } from './services/add-occupation-type-form.component.service';

@Component({
  selector: 'app-add-occupation-type-form',
  templateUrl: './add-occupation-type-form.component.html',
  styleUrls: ['./add-occupation-type-form.component.html'],
  providers: [AddOccupationTypeFormComponentService]
})
@traceClass('AddOccupationTypeFormComponent')
export class AddOccupationTypeFormComponent implements IAddOccupationTypeFormComponent, OnInit {

  @Input("parents") public parents: OccupationType[];
  @Input("dataItem") public dataItem: OccupationType;

  @Output("onSave") public onSaveEventEmitter = new EventEmitter<OccupationType>();
  @Output("onCancel") public onCancelEventEmitter = new EventEmitter<boolean>();

  public form: FormGroup<IAddOccupationTypeFormComponentForm>;

  constructor(private traceService: TracerServiceBase,
              private service: AddOccupationTypeFormComponentService) {
    service.component = this
  }

  @traceFunc()
  ngOnInit() {
    this.service.init()
  }

  @traceFunc()
  onClickSave() {
    this.service.onClickSave();
  }

  @traceFunc()
  onClickCancel() {
    this.service.onClickCancel();
  }
}
