<div [formGroup]="form"
     class="wrap-div">

  <kendo-daterange *ngIf="form" class="date-range-control">
    <kendo-formfield class="form-formfield-control">
      <kendo-label [for]="startDate" text="с:" class="label-control"></kendo-label>
      <kendo-dateinput #startDate
                       kendoDateRangeStartInput
                       [min]="minDate"
                       [max]="maxDate"
                       autoCorrectOn="blur"
                       formControlName="startDate">
      </kendo-dateinput>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.minError">Выходит за пределы месяца</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.maxError">Выходит за пределы месяца</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield class="form-formfield-control">
      <kendo-label [for]="endDate" text="по:" class="label-control" style="margin-left: 20px;"></kendo-label>
      <kendo-dateinput #endDate
                       kendoDateRangeEndInput
                       [min]="minDate"
                       [max]="maxDate"
                       autoCorrectOn="blur"
                       formControlName="endDate"
      ></kendo-dateinput>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.minError">Выходит за пределы месяца</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.maxError">Выходит за пределы месяца</kendo-formerror>
    </kendo-formfield>
  </kendo-daterange>

  <div class="k-form-buttons" style="justify-content: flex-end; align-content: end;">

    <button kendoButton
            type="button"
            primary="true"
            [disabled]="form?.invalid"
            (click)="onOk()"
    >Сформировать</button>

    <button kendoButton
            type="button"
            (click)="onCancel()"
    >Отменить</button>

  </div>

</div>
