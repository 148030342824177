import {Component} from '@angular/core';

@Component({
  selector: 'app-directories-occupation-types',
  templateUrl: './occupation-types-directory.component.html',
  styleUrls: ['./occupation-types-directory.component.css']
})
export class OccupationTypesDirectoryComponent {

}
