import {Component} from '@angular/core';

@Component({
  selector: 'app-directories-report-periods',
  templateUrl: './report-periods.component.html',
  styleUrls: ['./report-periods.component.css']
})
export class ReportPeriodsDirectoryComponent {

}
