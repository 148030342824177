import { IEntityId } from '../interfaces/IEntityId';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IOccupationCode extends IEntityId {
  /** Идентификатор должности. FK на таблицу Occupations */
  occupationId: number;
  /** Идентификатор кода должности. FK на таблицу Codes */
  codeId: number;
}

@classBackend('OccupationCode', 'timesheet_table')
@className('OccupationCode')
/** Код должности */
export class OccupationCode implements IOccupationCode {
  constructor(public id: number,
              public occupationId: number,
              public codeId: number,
  ) {
  }
}
