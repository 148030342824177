import { IFieldControl } from "./interfaces/common";

export class CheckboxFieldControl implements IFieldControl<boolean> {
  public readonly name = 'CheckboxFieldControl';
  public getValueFunc = (stringValue: string) => !stringValue ? false : (stringValue.toLowerCase() === "true");

  constructor (
    public settings?: (() => CheckboxFieldControl_Settings) | CheckboxFieldControl_Settings
  ) {}
}

export interface CheckboxFieldControl_Settings {
}
