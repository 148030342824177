import {Injectable, OnDestroy } from "@angular/core";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { Observable, ReplaySubject, Subject, takeUntil } from "rxjs";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { DirectoryEditVersioningComponent } from "../versioning/directory-edit-versioning.component";
import { ValueChangeEvent } from "../versioning/i-directory-edit-versioning.component";
import { VersioningField } from "./classes/fields/versioning-field";
import { SettingsReaderService } from "./classes/service-settings/settings-reader/settings-reader.service";


@traceClass('DirectoryEditVersioningService')
@Injectable()
export class DirectoryEditVersioningService implements OnDestroy {

  /** Событие происходит когда добавляется новая версия */
  public onVersionAdding$ = new Subject<VersioningDialogEventData>();

  /** Событие происходит когда редактируется существующая версия */
  public onVersionEditing$ = new Subject<VersioningDialogEventData>();

  /** Событие происходит когда удаляется существующая версия */
  public onVersionDeleting$ = new Subject<VersioningDialogEventData>();


  /** Событие происходит когда изменяется дата начала версии */
  public onStartDateChangeWithInit$ = new Subject<ValueChangeEventData>();

  /** Событие происходит когда изменяется дата окончания версии */
  public onEndDateChangeWithInit$ = new Subject<ValueChangeEventData>();

  /** Событие происходит когда начинается редактироварние или добавление версии */
  public onStartEditOrAdd$ = new Subject<StartEditOrAddEventData>();

  /** Событие происходит когда заканчивается редактирование / добавление версии */
  public onEndEditOrAdd$ = new Subject<EndEditOrAddEventData>();

  public onClose$ = new Subject<void>();

  private _editDialogRef: DialogRef;

  private _streams$:{
    unsubscribes: ReplaySubject<any>
  }={
    unsubscribes: new ReplaySubject<any>(1)
  }

  private srs = new SettingsReaderService();

  constructor(private readonly dialogService: DialogService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly tracerService: TracerServiceBase) {
  }

  /**
   * Начать редактирование версионного поля
   * @param dataItem Объект с данными
   * @param field Настройки поля
   * @param dataSource$ источник данных получения версий
   */
  @traceFunc()
  public edit(dataItem: any, field: VersioningField, dataSource$: Observable<VersioningItem[]>){

    //Закрываем диалоговое окно редактирования если оно было открыто
    this._closeEditDialogIfExists();
    //Начинаем подписку (переинициализируем unsubscribes)
    this._startSubscribe();

    //Получаем из настроек title диалогового окна
    const dialogTitle = this.srs.getValue(field.settings.dialogTitle);

    this._editDialogRef = this.dialogService.open({
      //Если dialogTitle не задано то формируем название диалогового окна на основе переданного из настроек title
      title: dialogTitle ? dialogTitle : `Редактирование "${this.srs.getValue(field.settings.title)}"`,

      // Show component
      content: DirectoryEditVersioningComponent,
      width: "90%",
    });

    //Заполняем Inputы у компонента
    const editVersioningGridComponent = this._editDialogRef.content.instance as DirectoryEditVersioningComponent;
    editVersioningGridComponent.field = field;
    editVersioningGridComponent.dataItem = dataItem;
    this.updateDataSource(dataSource$, field);


    //Начинаем слушать событие добавление новой версии, если оно случается то бросаем наше событие
    editVersioningGridComponent.onAddingEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(entity=>{
      this.onVersionAdding$.next(new VersioningDialogEventData(entity, field));
    });

    //Начинаем слушать событие сохранения версии, если оно случается то бросаем наше событие
    editVersioningGridComponent.onSavingEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(entity=>
      this.onVersionEditing$.next(new VersioningDialogEventData(entity, field))
    );

    //Начинаем слушать событие удаления версии, если оно случается то бросаем наше событие
    editVersioningGridComponent.onDeletingEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(entity=>
      this.onVersionDeleting$.next(new VersioningDialogEventData(entity, field))
    );

    //Начинаем слушать событие изменения даты начала, если оно случается то бросаем наше событие
    editVersioningGridComponent.onStartDateChangeWithInitEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(e =>
      this.onStartDateChangeWithInit$.next(new ValueChangeEventData(e, field))
    );

    //Начинаем слушать событие изменения даты окончания, если оно случается то бросаем наше событие
    editVersioningGridComponent.onEndDateChangeWithInitEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(e =>
      this.onEndDateChangeWithInit$.next(new ValueChangeEventData(e, field))
    );

    //Начинаем слушать событие инициализации формы редактирования (событие начала редактирования или добавления), если оно случается то бросаем наше событие
    editVersioningGridComponent.onStartEditOrAddEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(e =>
      this.onStartEditOrAdd$.next(new StartEditOrAddEventData(e, field))
    );

    //Начинаем слушать событие окончания редактирования, если оно случается то бросаем наше событие
    editVersioningGridComponent.onEndEditOrAddEventEmitter.pipe(takeUntil(this._streams$.unsubscribes)).subscribe(e =>
      this.onEndEditOrAdd$.next(new EndEditOrAddEventData(e, field))
    );

    //Инициализируем компонент
    editVersioningGridComponent.initialize();

    //Подписываемся на результат диалога и при любом результате его закрываем
    this._editDialogRef.result.subscribe(r=> {
      this._closeEditDialogIfExists();
    });
  }

  @traceFunc()
  /** Функция обновления dataSource */
  public updateDataSource(dataSource$: Observable<VersioningItem[]>, field: VersioningField) {
    const editVersioningGridComponent = this._editDialogRef.content.instance as DirectoryEditVersioningComponent;
    if(editVersioningGridComponent) {
      let ds$: Observable<VersioningItem[]>;

      if(field.settings.preGetVersionsData$) {
        ds$ = field.settings.preGetVersionsData$(dataSource$);
      } else {
        ds$ = dataSource$;
      }

      editVersioningGridComponent.dataSource$ = this.loadingIndicatorService.addToObservable(
        "Загрузка данных версионности",ds$);
    }
  }

  /** Закрыть диалоговое оно редактирования */
  @traceFunc()
  public close() {
    this._closeEditDialogIfExists();
  }

  @traceFunc()
  public ngOnDestroy() {
    this._closeEditDialogIfExists();
    this._unsubscribe();

    //Помечаем все события как завершенные
    this.onVersionAdding$.complete();
    this.onVersionEditing$.complete();
    this.onVersionDeleting$.complete();
    this.onStartDateChangeWithInit$.complete();
    this.onEndDateChangeWithInit$.complete();
    this.onStartEditOrAdd$.complete();
    this.onEndEditOrAdd$.complete();
    this.onClose$.complete();
  }

  /** Закрывает окно редактирования если оно было до этого открыто */
  @traceFunc()
  private _closeEditDialogIfExists(){
    if(this._editDialogRef)
    {
      this._editDialogRef.close();
      this._editDialogRef = null;
      this.onClose$.next();
      this._unsubscribe();
    }
  }

  @traceFunc()
  private _unsubscribe() {
    this._streams$.unsubscribes.next(null);
    this._streams$.unsubscribes.complete();
  }

  @traceFunc()
  private _startSubscribe() {
    this._streams$.unsubscribes = new ReplaySubject<any>(1);
  }
}

/** Данные события добавления/изменения/редактирования версии */
export class VersioningDialogEventData {
  constructor(
    public versioningItem: VersioningItem,
    public field: VersioningField
  ) {}
}

/** Данные события изменения даты начала/окончания */
export class ValueChangeEventData {
  constructor(
    public data: ValueChangeEvent,
    public field: VersioningField
  ) {}
}

/** Данные события начала редактирования или добавления */
export class StartEditOrAddEventData {
  constructor(
    public versioningItem: VersioningItem,
    public field: VersioningField
  ) {}
}

/** Данные события окончания редактирования или добавления */
export class EndEditOrAddEventData {
  constructor(
    public versioningItem: VersioningItem,
    public field: VersioningField
  ) {}
}
