import { AfterContentInit, Directive, ElementRef, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { CellClickEvent, ExpandableDirective, TreeListComponent } from '@progress/kendo-angular-treelist';
import { CellClickExpandedEvent, CellMouseEvent, GridTreeListExpandedDirectiveService, TitleHorizontalAlignType, TitleVerticalAlignType } from "../services/directives/grid-treelist-expanded-directive.service";
import { KendoTreeListHelper } from '../helpers/kendo-tree-list.helper';

/**
 * Директива расширяющая компонент kendo tree list
 */
@Directive({
  selector: '[KendoTreeListExpanded]',
  providers: [GridTreeListExpandedDirectiveService]
})
export class KendoTreeListExpandedDirective implements OnInit, AfterContentInit {

  /** Событие клика по ячейке (расширенное) */
  @Output() cellClickExpanded = new EventEmitter<CellClickExpandedEvent<CellClickEvent>>();


  /** Событие наведения на ячейку таблицы*/
  @Output() get cellMouseOver(): EventEmitter<CellMouseEvent> {
    return this.directiveService.cellMouseOver;
  }

  /** Событие отведения мыши от ячейки таблицы */
  @Output() get cellMouseOut(): EventEmitter<CellMouseEvent> {
    return this.directiveService.cellMouseOut;
  }

  /** Событие двойного клика по ячейке */
  @Output() dbCellClick = new EventEmitter<CellClickExpandedEvent<CellClickEvent>>();

  /** Переносить ли текст в шапке таблицы (если он не помещается) */
  @Input("tableTitleTextWrap") public set tableTitleTextWrap(value: boolean) {
    this.directiveService.tableTitleTextWrap = value;
  }
  public get tableTitleTextWrap(): boolean {
    return this.directiveService.tableTitleTextWrap;
  }

  /** Вертикальное расположение текста в шапке таблицы: 'top' или 'middle' или 'bottom' */
  @Input("tableTitleVerticalAlign") public set tableTitleVerticalAlign(value: TitleVerticalAlignType) {
    this.directiveService.tableTitleVerticalAlign = value;
  }
  public get tableTitleVerticalAlign(): TitleVerticalAlignType {
    return this.directiveService.tableTitleVerticalAlign;
  }

  /** Padding для шапки грида. Для колонки шапки будет добавлен стиль padding: ЗНАЧЕНИЕ */
  @Input("headerColumnsPadding") public set headerColumnsPadding(paddingValue: string) {
    this.directiveService.headerColumnsPadding = paddingValue;
  }
  public get headerColumnsPadding(): string {
    return this.directiveService.headerColumnsPadding;
  }

  /** Padding для колонок грида. Для колонки будет добавлен стиль padding: ЗНАЧЕНИЕ */
  @Input("columnsPadding") public set columnsPadding(paddingValue: string) {
    this.directiveService.columnsPadding = paddingValue;
  }
  public get columnsPadding(): string {
    return this.directiveService.columnsPadding;
  }

  /** Горизонтальное расположение текста в шапке таблицы: 'left' или 'center' или 'right' */
  @Input("tableTitleHorizontalAlign") public set tableTitleHorizontalAlign(value: TitleHorizontalAlignType) {
    this.directiveService.tableTitleHorizontalAlign = value;
  }
  public get tableTitleHorizontalAlign(): TitleHorizontalAlignType {
    return this.directiveService.tableTitleHorizontalAlign;
  }

  /** Прокручивать скролл до выделенного. Необходимо при инициализации Таблицы */
  @Input() public set scrollIntoSelect(value: boolean) {
    this.directiveService.scrollIntoSelect = value;
  }
  public get scrollIntoSelect(): boolean {
    return this.directiveService.scrollIntoSelect;
  }

  /** Обновить скролл прокруткой до выделенного элемента */
  @Input() public set updateScrollIntoSelect(value: any){
    this.directiveService.updateScrollIntoSelect = value;
  }

  /** Прокрутить скролл до строки по индексу */
  @Input() public set scrollToRowByIndex(value: number) {
    this.directiveService.scrollIntoRowByIndex(value);
  }

  /** Прокрутить скролл до строки по индексу */
  @Input() public set scrollToRow(value: any) {
    this.directiveService.scrollIntoRow(value);
  }

  /** Разварачивать при фильтрации */
  @Input()
  public filterableExpanded = false;

  constructor(private el: ElementRef, private treeList: TreeListComponent, private directiveService: GridTreeListExpandedDirectiveService, @Optional() private treeListExpandable?: ExpandableDirective) {
    directiveService.init(treeList, el, false);
  }

  /**
   * Прокрутить до нужного элемента
   * @param dataItem элемент до которого нужно прокрутить
   * @param compareFn функция сравнения, по умолчанию == (но чаще всего сравнение по ссылке не подходит)
   */
  public scrollToDataItem<TDataItem>(dataItem: TDataItem, compareFn?:(i1: TDataItem, i2: TDataItem) => boolean){
    this.directiveService.scrollIntoRow(dataItem, compareFn);
  }

  ngOnInit(): void {
    this.directiveService.registerCellClickForCellClickExpandedEvent(this.treeList.cellClick, this.cellClickExpanded)
    this.directiveService.registerCellClickForDoubleCellClickEvent(this.cellClickExpanded, this.dbCellClick);

    this.registerFilterableExpanded();
  }

  /** Регистрируем обработку фильтрации для разворачивания treeList при filterableExpanded == true */
  private registerFilterableExpanded() {
    if(this.filterableExpanded) {
      if(!this.treeListExpandable) throw `У TreeListComponent нет директивы ExpandableDirective`;

      this.treeList.filterChange.subscribe(({ filters }) => {
        if(filters.length) {
          //Раскрываем все элементы, которые прошли фильтр
          const items = KendoTreeListHelper.getAllDataItems(this.treeList);
          items.forEach(f=> this.treeList.expand(f.item));
        }
      })
    }
  }

  ngAfterContentInit() {
    this.directiveService.ngAfterContentInit();
  }
}
