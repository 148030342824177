import { map, Observable, switchMap } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { Employee, IEmployee } from "src/app/classes/domain/POCOs/stafflist/Employee";
import { IForDate } from "src/app/classes/for-date";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";

export class EmployeeDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<EmployeeDataSourceService_Params, IEmployee>{

  readonly paramsDataSource = new DataSource<EmployeeDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<IEmployee>();

  constructor(private readonly parentDataSourceService: IEmployeeDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Employee)
      .pipe(
        map(value => value.data),
        map(value => new DataHasOwnerStateBuilder(value, this.dataSource.data2, x => x.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.ownerId)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: EmployeeDataSourceService_Params): Observable<IEmployee[]> {
    return this._reloadFromRemoteByIds$(params, params.employeeIds);
  }

  protected _reloadFromRemoteByIds$(params: EmployeeDataSourceService_Params, targets: IEmployee["id"][]): Observable<IEmployee[]> {
    return this.parentDataSourceService.getEmployeesForDate$(params, targets);
  }
}

export interface EmployeeDataSourceService_Params extends IForDate{
  employeeIds: number[];
}

export interface IEmployeeDataSourceService_ParentDataSourceService {
  getEmployeesForDate$(forDate: IForDate, employeeIds: number[]): Observable<IEmployee[]>;
}
