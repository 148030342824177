import {ITraceSettings} from "./traceSetting.interface";
import {TracerServiceBase} from "../../tracers2/trace-services/tracer-base.service";

/** Получить состояние отключения */
export function getDisabled(settings: ITraceSettings) {
  if (!settings?.disabled) {
    return false;
  }
  switch (typeof settings.disabled) {
    case "boolean":
      return settings.disabled;
    case "function":
      return settings.disabled()
    default:
      throw new Error('settings.disabled not boolean or not function')
  }
}

/** Преобразовать объект и JSON.stringify */
export function convertObjToString<T>(obj: T, map: (T) => any) : string {
  let source = obj;
  if (map) {
    try {
      source = map(source);
    } catch (e) {
      return `Ошибка конвертации: ${e?.message?.toString() ?? 'причина не известна'}`;
    }
  }

  try {
    const result = JSON.stringify(source);
    return result === undefined ? 'undefined' : result;
  } catch (e) {
    return 'НЕ УДАЛОСЬ JSON.stringify'
  }
}

/** Лимитировать строку. Ограничивает длину по maxLength и добавляет .... в конце */
export function limitString(str: string, maxLength: number) {
  let result = str === undefined ? 'undefined' : str;
  if (result.length > maxLength) {
    result = `${result.substring(0, maxLength)}....`
  }
  return result;
}

/**
 * Получить индекс Tracer из параметров переданном в конструкторе.
 * Бросит ошибку если нет параметров или больше 1
 */
export function getTraceServiceIndex(args: any[], className: string): number{
  const traceServiceBaseArgsLength = args.filter(x => TracerServiceBase.IsTraceBase(x)).length;

  if(traceServiceBaseArgsLength != 1){
    throw new Error(`Класс ${className} декорирован traceClass, и его коструктор принимает ${traceServiceBaseArgsLength} параметра типа TracerBase. Должно быть один параметр`)
  }

  return args.findIndex(x => TracerServiceBase.IsTraceBase(x));
}

/** Получить tracer из объекта */
export function getTracerFromObj(obj: any): TracerServiceBase {
  if(!obj){
    return null;
  }

  const properties = Object.values(obj) as Array<any>;
  const tracerIndex = getTraceServiceIndex(properties, 'traceFunc')

  return properties[tracerIndex];
}
