import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComboboxFieldControl_Settings } from "../../services/classes/field-controls/combobox-field-control";
import { IComboboxDirectoryEditComponent } from "./i-combobox-directory-edit-control.component";
import { ComboboxDirectoryEditComponentService } from "./services/combobox-directory-edit-control-component.service";

@Component({
  selector: 'app-combobox-directory-edit-control',
  templateUrl: './combobox-directory-edit-control.component.html',
  styleUrls: ['./combobox-directory-edit-control.component.css'],
  providers: [ComboboxDirectoryEditComponentService]
})
@traceClass('DropDownListDirectoryEditComponent')
export class ComboboxDirectoryEditComponent implements OnInit, IComboboxDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: ComboboxFieldControl_Settings;

  public data$: Observable<any>;
  public valueField: string;

  constructor(private service: ComboboxDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
    this.service.addValidators();
  }
}
