<form class="k-form" [formGroup]="form" *ngIf="form">
  <div class="form-inline-div">
    <kendo-formfield>
      <kendo-label [for]="date" text="Дата увольнения"></kendo-label>
      <kendo-datepicker #date
                        [formControlName]="'date'"
                        [min]="admissionDate"
      ></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.date.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>
  </div>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickDismiss()">Уволить</button>
  </div>
</form>

