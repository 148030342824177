import { Observable, throwError } from "rxjs";
import { TracerServiceBase } from "../tracers2/trace-services/tracer-base.service";
import { catchError, finalize, tap } from "rxjs/operators";
import { ITraceAsyncSettings, ITraceSettings } from "../decorators/classes/traceSetting.interface";

/**
 * Оператор трассировки
 * @param source$
 */
export function trace<O>(tracerService: TracerServiceBase, resultSettings: ITraceAsyncSettings<O> = null, errorSettings: ITraceSettings = null): (source$: Observable<O>) => Observable<O> {
  const newTracer = tracerService.registerAsync();

  return source$ => source$.pipe(
    tap(value => {
      newTracer.addNewLine();
      newTracer.add2(
        'Асинхронность удачно выполнена:',
        {
          obj: value,
          disabled: resultSettings?.disabled === undefined ? true : resultSettings?.disabled,
          maxLength: resultSettings?.maxLength,
          description: resultSettings?.description,
          map: resultSettings?.map
        });
      newTracer.addNewLine();
    }),
    catchError((err, caught) => {
      newTracer.addNewLine();
      newTracer.add2('Асинхронность закончилась неудачей:',
        {
          obj: err,
          disabled: errorSettings?.disabled == undefined ? true : errorSettings?.disabled
        });
      newTracer.addNewLine();

      return throwError(() => err);
    })
  )
}
