import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { RandomHelper } from "src/app/helpers/randomHelper";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";

@Component({
  selector: 'app-code-captcha',
  templateUrl: './code-captcha.component.html',
  styleUrls: ['./code-captcha.component.css']
})
@traceClass('CodeCaptchaComponent')
export class CodeCaptchaComponent implements OnInit {

  public code: string;
  public mask: string;

  public codeControlValue: string;
  public codeLength: number = 3;

  @Output("isSolved") public isSolved = new EventEmitter<boolean>;

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(
    private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.code = this.getRandomCode(this.codeLength);
    this.mask = "0".repeat(this.codeLength);
  }

  public onValueChange(val: string) {
    this.isSolved.emit(val === this.code);
  }

  private getRandomCode(length: number): string {
    let retval = "";
    for(let i = 1; i <= length; i++) {
      retval += RandomHelper.getInt(0,9);
    }
    return retval;
  }

  @traceFunc()
  public ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
