import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ILimitStaffUnit extends IEntityVersioning {
  /** Идентификатор Категории должности. FK на таблицу stafflist.OccupationTypes */
  occupationTypeId: number;

  subdivisionId: number;
  /** Идентификатор Источника финансирования. FK на таблицу stafflist.FinancingSources */
}

@classBackend('LimitStaffUnit', 'stafflist')
@className('LimitStaffUnit')
/** Модель БД таблицы stafflist.LimitStaffUnits (Лимиты штатных единиц) */
export class LimitStaffUnit implements ILimitStaffUnit {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public timestamp: [],
              public occupationTypeId: number,
              public subdivisionId: number) {
  }
}
