import { Injectable, OnDestroy } from "@angular/core";
import { DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { CellClickEvent, SelectionEvent } from "@progress/kendo-angular-grid";
import { ReplaySubject, take, takeUntil } from "rxjs";
import { ArrayDataSourceIEntityId } from "src/app/classes/array-data-sources/data-source";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";
import { IDropDownItem } from "src/app/classes/requestResults/iDropDownItem";
import { CheckboxFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/checkbox-field-control";
import { DateFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/date-field-control";
import { DropDownListFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/dropdownlist-field-control";
import { NumberFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/number-field-control";
import { TextAreaFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/text-area-field-control";
import { TextBoxFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/text-box-field-control";
import { FieldType } from "src/app/components/directory-edit/services/classes/fields/field-type";
import { NotVersioningField } from "src/app/components/directory-edit/services/classes/fields/not-versioning-field";
import { VersioningField } from "src/app/components/directory-edit/services/classes/fields/versioning-field";
import { DirectoryEditService } from "src/app/components/directory-edit/services/directory-edit.service";
import { DirectoryEditRef } from "src/app/components/directory-edit/services/ref-services/directory-edit-ref";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { DateHelper } from "src/app/helpers/dateHelper";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { CellClickExpandedEvent } from "src/app/services/directives/grid-treelist-expanded-directive.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { WorkModesDirectoryGridItem } from "src/app/services/webApi/webApi1/controllers/api1-work-modes-directory-grid-control-controller.service";
import { AddWorkModeFormComponent } from "../../add/add-work-mode-form.component";
import { IWorkModesDirectoryGridComponent } from "../i-work-modes-directory-grid.component";
import { WorkModesDirectoryGridComponentDataService } from "./work-modes-directory-grid-component-data.service";

@traceClass('WorkModesDirectoryGridComponentService')
@Injectable()
export class WorkModesDirectoryGridComponentService extends ComponentServiceBase<IWorkModesDirectoryGridComponent> implements OnDestroy{

  /** Настройки полей справочника */
  private fields: Array<FieldType> = [
    new VersioningField("name", SupportedActionEnum.stafflist_workMode_name, {isAllowNull: false, title: "Наименование режима работы"}, new TextBoxFieldControl({maxLength: 255, autoTrimValue: true})),
    new VersioningField("workingDays", SupportedActionEnum.stafflist_workMode_working_days, {isAllowNull: false, title: "Рабочих дней"}, new NumberFieldControl({minValue: 0, maxValue: 7, decimals: 0, format: 'n0', step: 1})),
    new VersioningField("workdayHourDuration", SupportedActionEnum.stafflist_workMode_workday_hour_duration, {isAllowNull: false, title: "Продолжительность рабочего дня (ч.)"}, new NumberFieldControl({minValue: 0, maxValue: 24, decimals: 1, format: 'n1', step: 0.1})),
    new VersioningField("preHolidayHourDuration", SupportedActionEnum.stafflist_workMode_pre_holiday_hour_duration, {isAllowNull: false, title: "Продолжительность пердпраздничного дня (ч.)"}, new NumberFieldControl({minValue: 0, maxValue: 24, decimals: 1, format: 'n1', step: 0.1})),
    new VersioningField("workModeTypeId", SupportedActionEnum.stafflist_workMode_work_mode_type_id, {isAllowNull: false, title: "Тип режима рабочего времени", displayValueFunc: (dataItem) => this.getDisplayValue(dataItem)}, new DropDownListFieldControl({data: () => this.workModeTypesDropDownItems})),

    new NotVersioningField("arrangedForWeekFlag", {isAllowNull: false, title: "Начало периода совпадает с началом недели"}, new CheckboxFieldControl({})),
    new NotVersioningField("comment", {isAllowNull: true, title: "Комментарий"}, new TextAreaFieldControl({maxLength: 100, autoTrimValue: true})),
    new NotVersioningField("startDate", {isAllowNull: true, title: "Дата начала"}, new DateFieldControl({maxValue: ()=> this.directoryEditServiceRef.currentDataItem.endDate})),
    new NotVersioningField("endDate", {isAllowNull: true, title: "Дата окончания"}, new DateFieldControl({minValue: ()=> this.directoryEditServiceRef.currentDataItem.startDate})),
  ];

  private directoryEditServiceRef: DirectoryEditRef;
  private _addDialogRef: DialogRef;
  private workModeTypesDropDownItems: Array<IDropDownItem>;
  private directoryData = new ArrayDataSourceIEntityId<WorkModesDirectoryGridItem>();

  private streams$:{
    unsubscribes: ReplaySubject<any>
  }={
    unsubscribes: new ReplaySubject<any>(1)
  }

  constructor(private directoryEditService: DirectoryEditService,
              private dataService: WorkModesDirectoryGridComponentDataService,
              private traceService: TracerServiceBase,
              private displayErrorsService: DisplayErrorsService,
              private dialogService: DialogService,
              private kendoNotificationService: KendoNotificationService) {
    super();
  }

  /** Инициализация сервиса (обычно вызывается в OnInit) */
  public init() {
    this.component.data$ = this.directoryData.data$;
    this.initDirectoryEditService();
    this.reloadWorkModeTypes();
  }

  /** Инициализация сервиса редактирования */
  public initDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.init({
      addVersion$: (actionId, versioningEntity) => this.dataService.addWorkModeVersion$(actionId, versioningEntity),
      deleteVersion$: (versionId) => this.dataService.deleteWorkModeVersion$(versionId),
      getVersionsByOwnerIdAndActionId$: (ownerId, actionId) => this.dataService.getVersionsByOwnerIdAndActionId$(ownerId,actionId),
      editVersion$:(actionId, entity) => this.dataService.editWorkModeVersion$(actionId,entity),
      saveNoVersioning$: (entity)=> this.dataService.saveWorkMode$(entity),
      isNeedHideData: (entity) => this.isNeedHideData(entity)
    }, this.fields);

    this.directoryEditServiceRef.notVersioning.onSaved$.subscribe((entity) => this.onNoVersioningItemSaved(entity));
    this.directoryEditServiceRef.versioning.onVersionAdded$.subscribe((version)=> this.reloadByOwnerId(version.ownerId));
    this.directoryEditServiceRef.versioning.onVersionDeleted$.subscribe((ownerId)=> this.reloadByOwnerId(ownerId));
    this.directoryEditServiceRef.versioning.onVersionEdited$.subscribe((version)=> this.reloadByOwnerId(version.ownerId));
  }

  /** Начало редактирования колонки */
  @traceFunc()
  public edit(fieldName: string, dataItem: any, elementRef: any){
    this.directoryEditServiceRef.edit(fieldName, dataItem, elementRef);
  }

  /** Перезагрузка данных типов режимов работы */
  @traceFunc()
  public reloadWorkModeTypes() {
    this.dataService.reloadWorkModeTypes()
    .pipe(trace(this.traceService), take(1),takeUntil(this.streams$.unsubscribes)).subscribe({
      next: data => {
        this.workModeTypesDropDownItems = data.map(item=><IDropDownItem> { id: item.id, text: item.name });
      }, error: error => this.displayErrorsService.handleError(error)});
  }

  /** Перезагрузка данных грида */
  @traceFunc()
  public reloadData() {
    if(this.component.reportDateValues){
      this.dataService.reloadData(this.component.reportDateValues.reportDate, this.component.reportDateValues.reportRangeStartDate, this.component.reportDateValues.reportRangeEndDate)
      .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
        next: value => {
          this.directoryData.setData(value);
        }, error: error => this.displayErrorsService.handleError(error)});
    } else {
      this.directoryData.setData(null);
    }
  }

  /** Получение данных должности по Id */
  @traceFunc()
  public reloadByOwnerId(ownerId: number) {
    this.dataService.reloadByOwnerId(ownerId, this.component.reportDateValues.reportDate, this.component.reportDateValues.reportRangeStartDate, this.component.reportDateValues.reportRangeEndDate)
    .pipe(trace(this.traceService), take(1),takeUntil(this.streams$.unsubscribes)).subscribe({
      next: (data) => {
        if(data){
          const newItem = data[0];
          this.component.currentSelectedItem = newItem;
          this.directoryData.addOrUpdateItems(newItem);
        }
      }, error: error => this.displayErrorsService.handleError(error)});
  }

  /** Показать диалог добавления нового режима работы */
  @traceFunc()
  public showAddDialog()
  {
    this._addDialogRef = this.dialogService.open({
      title: `Добавление режима работы`,

      // Show component
      content: AddWorkModeFormComponent,
      width: "90%",
    });

    const addFormComponent = this._addDialogRef.content.instance as AddWorkModeFormComponent;
    addFormComponent.workModeTypesDropDownItems = this.workModeTypesDropDownItems;

    addFormComponent.onSaveEventEmitter.subscribe(entity=>
      this.dataService.addWorkMode(entity)
      .pipe(trace(this.traceService), take(1),takeUntil(this.streams$.unsubscribes)).subscribe({
        next: data => {
          if(DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, this.component.reportDateValues.reportRangeStartDate, this.component.reportDateValues.reportRangeEndDate)){
            this.reloadByOwnerId(data.id);
            this.kendoNotificationService.showSuccess({content:"Режим работы добавлен"});
          } else {
            this.kendoNotificationService.showSuccess({content:"Режим работы добавлен, но он не входит в диапазон выборки дат отчёта, поэтому он не будет отображен.", hideAfter: 7000});
          }
          this._addDialogRef?.close();
        }, error: error => this.displayErrorsService.handleError(error)})
    );

    addFormComponent.onCancelEventEmitter.subscribe(()=> {
      this._addDialogRef?.close();
    });
  }

  /** Событие происходит когда НЕ версионное поле было отредактировано и сохранено */
  @traceFunc()
  public onNoVersioningItemSaved(dataItem: WorkModesDirectoryGridItem) {
    if(this.isNeedHideData(dataItem)){
      this.directoryData.deleteItemByIds(true, dataItem.id);
    }
  }

  /** Проверка попадают ли данные в диапазон отображения */
  @traceFunc()
  public isNeedHideData(entity: any) {
    return !DateHelper.isDateIntervalsIntersect(entity.startDate, entity.endDate, this.component.reportDateValues.reportRangeStartDate, this.component.reportDateValues.reportRangeEndDate);
  }

  /** Событие происходит при двойном клике на ячейку */
  public onDblCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    if (!$event.originalEvent.isEdited) {
      this.edit($event.originalEvent.column.field, $event.originalEvent.dataItem, $event.originalEvent.originalEvent.target);
    }
  }

  /** Событие при изменении выбранного элемента */
  public onSelectionChanged(e: SelectionEvent){
    const selectedItem = e.selectedRows[0]?.dataItem as WorkModesDirectoryGridItem;
    this.component.currentSelectedItem = selectedItem;
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickAdd() {
    this.showAddDialog();
  }

  /** Событие происходит при изменении знаений диапазона дат отчёта */
  public onReportDateValuesChanged() {
    this.component.currentSelectedItem = null;
    this.reloadData();
  }

  public ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
    this.directoryEditServiceRef.close();
    this.directoryData.onDestroy();
  }

  private getDisplayValue(dataItem: any) {
    const displayValue: string = this.workModeTypesDropDownItems.find(d=> d.id.toString() === dataItem.valueAsString)?.text ??  dataItem.valueAsString;
    return displayValue ? displayValue :  "Нет";
  }

}
