import { Injectable } from "@angular/core";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { CustomFormValidators } from "src/app/validators/custom-form-validators.class";
import { SettingsReaderService } from "../../../services/classes/service-settings/settings-reader/settings-reader.service";
import { INumberDirectoryEditComponent } from "../i-number-directory-edit-control.component";

@Injectable()
@traceClass('NumberDirectoryEditComponentService')
export class NumberDirectoryEditComponentService extends ComponentServiceBase<INumberDirectoryEditComponent>{

  private srs = new SettingsReaderService();

  constructor(private tracer: TracerServiceBase)
  {
    super();
  }

  /** Регистрация валидаторов*/
  @traceFunc()
  public addValidators() {
    if (this.component.settings.multipleOf) {
      this.component.control.addValidators(CustomFormValidators.multipleOf(this.component.settings.multipleOf));
    }

    this.component.control.updateValueAndValidity();
  }

  /** Регистрация валидаторов*/
  @traceFunc()
  public readSettings() {
    this.component.minValue$ = this.srs.getValue$(this.component.settings.minValue);
    this.component.maxValue$ = this.srs.getValue$(this.component.settings.maxValue);
  }

}
