import { OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";

export interface IImportFromExcelComponent extends OnDestroy, OnInit {
  uploadSaveUrl: string;
  downloadTemplateUrl: string;
  onImportResult$: Subject<ImportResult>;
  canImport: boolean;
  settingsTemplate: TemplateRef<any>;
}
export class ImportResult {
  constructor(public isSuccess: boolean, public responseData: any){}
}
