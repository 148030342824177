import { IEntityComment } from "../interfaces/IEntityComment";
import { IEntityDeletedFlag } from "../interfaces/IEntityDeletedFlag";
import { IEntityId } from "../interfaces/IEntityId";
import { IEntityOwnerId } from "../interfaces/IEntityOwnerId";
import { IEntityRowVersion } from "../interfaces/IEntityRowVersion";
import { IEntityStartEndDate } from "../interfaces/IEntityStartEndDate";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IPkgKu extends IEntityId, IEntityOwnerId, IEntityStartEndDate, IEntityComment, IEntityDeletedFlag, IEntityRowVersion {
  parentId: number,
  sortOrder: number,
  longName: string,
  shortName: string,
  code: string,
  minSalary: number,
  minSalaryDecreeId: number
}

@classBackend('PkgKu', 'tarification_directories')
@className('PkgKu')
/** Модель БД таблицы tarification_directories.PkgKus (ПКГ и КУ) */
export class PkgKu implements IPkgKu {
  constructor(public id: number,
              public ownerId: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public deletedFlag: boolean,
              public parentId: number,
              public sortOrder: number,
              public longName: string,
              public shortName: string,
              public code: string,
              public minSalary: number,
              public minSalaryDecreeId: number,
              public comment: string,
              public timestamp: []) { }
}
