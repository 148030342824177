<kendo-dropdownlist class="popup-edit-control"
                    [data]="data$ | async"
                    [textField]="settings?.textField ?? 'text'"
                    [valueField]="valueField"
                    [valuePrimitive]="true"
                    [formControl]="control"
                    [filterable]="!!settings?.filterSettings"
                    [kendoDropDownFilter]="settings?.filterSettings"
                    [virtual]="settings?.virtual"
                    [popupSettings]="settings?.popupSettings"
>
</kendo-dropdownlist>
