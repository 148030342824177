import { Component, Input, OnDestroy, OnInit, SkipSelf} from '@angular/core';
import { DropDownItem, IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { ImportFromExcelComponentImportSettingsService } from 'src/app/components/import-from-excel/services/import-from-excel-component-import-settings.service';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';

@Component({
  selector: 'app-employee-import-settings',
  templateUrl: './employee-import-settings.component.html',
  styleUrls: ['./employee-import-settings.component.css']
})

@traceClass('EmployeeImportSettingsComponent')
export class EmployeeImportSettingsComponent {

  @Input() service: ImportFromExcelComponentImportSettingsService;

  public data: IDropDownItem[] = [
    new DropDownItem(1, "Паспортные данные"),
    new DropDownItem(2, "ИНН"),
    new DropDownItem(3, "СНИЛС"),
    new DropDownItem(4, "Место жительства"),
    new DropDownItem(5, "Номер телефона")
  ];

  constructor( private tracerService: TracerServiceBase){}

  @traceFunc()
  onCheckedChanged(checkedKeys: any[]){
    this.service.onSettingsChanged$.next(checkedKeys);
    this.service.canImport$.next(checkedKeys.length > 0);
  }
}

