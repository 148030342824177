import { Injectable, OnDestroy } from "@angular/core";
import { SuccessEvent, UploadEvent } from "@progress/kendo-angular-upload";
import * as saveAs from "file-saver";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { DisplayErrorsService } from "../../display-errors/services/display-errors.service";
import { IImportFromExcelComponent, ImportResult } from "../i-import-from-excel.component";
import { ImportFromExcelComponentDataService } from "./import-from-excel-component-data.service";
import { ImportFromExcelComponentImportSettingsService } from "./import-from-excel-component-import-settings.service";

@Injectable()
@traceClass('ImportFromExcelComponentService')
export class ImportFromExcelComponentService extends ComponentServiceBase<IImportFromExcelComponent> implements OnDestroy  {

  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  private importSettings: any;

  constructor(private dataService: ImportFromExcelComponentDataService, public importSettingsService: ImportFromExcelComponentImportSettingsService,
     private authService: AuthService, private displayErrorsService: DisplayErrorsService, private traceService: TracerServiceBase) {
    super();
  }

  @traceFunc()
  init() {
    //Шаблон настроек не задан, а значит что импорт по умолчанию разрешён.
    if(!this.component.settingsTemplate) {
      this.component.canImport = true;
    }

    //Управляем разреением импорта путём подписки на изменение возможности импорта из сервиса.
    this.importSettingsService.canImport$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(v=> {
      this.traceService.add2('Разрешённость импорта была изменена', {obj: v});
      this.component.canImport = v
    });

    //подписываемся на настройки импорта
    this.importSettingsService.onSettingsChanged$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(s=> {
      this.traceService.add2('Настройки импорта были изменены', {obj: s, maxLength: 500});
      this.importSettings = s
    });
  }

  /** Событие происходит в момент отправки файла */
  @traceFunc()
  public onUpload(e: UploadEvent) {
    e.data = {settingsJsonString: JSON.stringify({settings: this.importSettings})};
    //Подставляем заголовки авторизации
    if(!e.headers.has("Authorization")) {
    e.headers = e.headers.append("Authorization",`Bearer ${this.authService.token}`);
    }
  }

  /** Импорт прошёл успешно (не было ошибок) */
  @traceFunc()
  public successUploadedEventHandler(e: SuccessEvent) {
    this.component.onImportResult$.next(new ImportResult(e.response.ok, e.response.body));
  }

  /** Импорт прошел не успешно, есть ошибки. */
  @traceFunc()
  public errorUploadEventHandler(e: any) {
    this.displayErrorsService.handleError(e.response);
    this.component.onImportResult$.next(new ImportResult(false, e.response));
  }

  /** Скачать файл шаблона для импорта */
  @traceFunc()
  public downloadTemplate() {
    this.dataService.downloadTemplateAsDataURI$(this.component.downloadTemplateUrl, this.importSettings)
    .pipe(trace(this.traceService), takeUntil(this.streams$.unsubscribe)).subscribe({
      next: res=> {
        saveAs(res.dataURI, res.fileName);
      },
      error: error => this.displayErrorsService.handleError(error)});
  }

  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.dataService.ngOnDestroy();
  }
}


