<!-- Контейнер используется для прикрепления в "appendTo", например для dropdowntree -->
<ng-container #containerForAppend></ng-container>

<kendo-grid
[kendoGridBinding]="dataSource$ | async"
kendoGridSelectBy="id"
[pageable]="false"
[sortable]="false"
[groupable]="false"
[reorderable]="false"
[resizable]="true"
[style.maxHeight.%]="100"
(edit)="editHandler($event)" (cancel)="cancelHandler($event)"
(save)="saveHandler($event)" (add)="addHandler($event)"
(remove)="removeHandler($event)"
>
<ng-template kendoGridToolbarTemplate>
  <button kendoGridAddCommand icon="plus">Добавить</button>
</ng-template>
  <kendo-grid-column field="startDate" title="Дата начала" [width]=180>
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.startDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
      <div #startDateInput><kendo-datepicker [max]="startDateMaxValue" [min]="startDateMinValue" [formControl]="formGroup.get(column.field)"></kendo-datepicker></div>
      <kendo-popup
        [anchor]="startDateInput"
        *ngIf="formGroup.get(column.field).invalid && (formGroup.get(column.field).dirty || formGroup.get(column.field).touched)"
        popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
      >
        <div>
          <div *ngIf="formGroup.get(column.field).errors?.required">Дата начала обязательна</div>
          <div *ngIf="formGroup.get(column.field).errors?.minError">Дата окончания не может быть раньше {{formGroup.get(column.field).errors?.minError.minValue | kendoDate : 'dd.MM.yyyy'}}</div>
          <div *ngIf="formGroup.get(column.field).errors?.maxError">Дата окончания не может быть позже {{formGroup.get(column.field).errors?.maxError.maxValue| kendoDate : 'dd.MM.yyyy'}}</div>
        </div>
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="endDate" title="Дата окончания" [width]=180>
    <ng-template kendoGridCellTemplate  let-dataItem >
      {{dataItem.endDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column" let-isNew="isNew">
      <div #endDateInput><kendo-datepicker [min]="endDateMinValue" [max]="endDateMaxValue" [formControl]="formGroup.get(column.field)"></kendo-datepicker></div>
      <kendo-popup
        [anchor]="endDateInput"
        *ngIf="formGroup.get(column.field).invalid && (formGroup.get(column.field).dirty || formGroup.get(column.field).touched)"
        popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
        >
        <div>
          <div *ngIf="formGroup.get(column.field).errors?.required">Дата окончания обязательна</div>
          <div *ngIf="formGroup.get(column.field).errors?.minError">Дата окончания не может быть раньше {{formGroup.get(column.field).errors?.minError.minValue | kendoDate : 'dd.MM.yyyy'}}</div>
          <div *ngIf="formGroup.get(column.field).errors?.maxError">Дата окончания не может быть позже {{formGroup.get(column.field).errors?.maxError.maxValue| kendoDate : 'dd.MM.yyyy'}}</div>
        </div>
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="value" [title]="columnTitle">
    <ng-template kendoGridCellTemplate  let-dataItem>
      {{dataItem.displayValue ?? "Нет значения"}}
    </ng-template>

    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <div #controlDiv >
        <app-directory-edit-control #directoryEditControl [field]="field" [valueFormControl]="formGroup.controls.value" [appendContainer]="appendContainer"></app-directory-edit-control>
        <!-- <ng-container *ngTemplateOutlet="formFieldValueTemplateRef; context: {formControl: formGroup.get(column.field)}"></ng-container> -->
      </div>
      <kendo-popup
      [anchor]="controlDiv"
      *ngIf="formGroup.get(column.field).invalid && (formGroup.get(column.field).dirty || formGroup.get(column.field).touched)"
      popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
      >
      <div class="errors-popup">
        <app-display-validation-errors [control]="formGroup.controls.value"></app-display-validation-errors>
      </div>
    </kendo-popup>

      <!-- <ng-container *ngTemplateOutlet="formFieldsError; context: {formControl: formGroup.get(column.field), isNew: isNew, inputDiv: controlDiv}"></ng-container> -->

    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="comment" title="Комментарий">
    <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup" let-isNew="isNew">
      <input #coment class="k-textbox" kendoTextBox [formControl]="formGroup.get(column.field)">
      <kendo-popup
        [anchor]="coment"
        *ngIf="formGroup.get(column.field).invalid && !(isNew && formGroup.get(column.field).untouched)"
        popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
        >
        <div class="errors-popup">
          <div *ngIf="formGroup.get(column.field).errors?.minlength">Длина значения должна быть больше {{formGroup.get(column.field).errors?.minlength.requiredLength}}. Текущая длина: {{formGroup.get(column.field).errors?.minlength.actualLength}}</div>
          <div *ngIf="formGroup.get(column.field).errors?.maxlength">Длина значения должна быть меньше {{formGroup.get(column.field).errors?.maxlength.requiredLength}}. Текущая длина: {{formGroup.get(column.field).errors?.maxlength.actualLength}}</div>
        </div>
      </kendo-popup>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="110">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
      <button kendoGridEditCommand [primary]="true" icon="pencil"></button>
      <button kendoGridSaveCommand [disabled]="!form?.touched || form?.invalid" icon="save" [primary]="true"></button>
      <button kendoGridCancelCommand icon="close"></button>
      <button kendoGridRemoveCommand *ngIf="!(dataItem.id !== 0 && (dataItem.id === dataItem.ownerId))" icon="trash"></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
