<div kendoDialogContainer></div>

<kendo-grid [kendoGridBinding]="dataSourceService.dataSource.data$ | async"
            kendoGridSelectBy="id"
            [groupable]="false"
            [reorderable]="false"
            [resizable]="false"
            [style.maxHeight.%]="100"
            [sortable]="{
              allowUnsort: true,
              mode: 'single'
            }"
            filterable="menu"
            [pageable]="false"
            scrollable="true"

            KendoGridExpanded
            (dbCellClick)="onDblCellClick($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <div class="grid-header-content">
      <button kendoButton icon="plus" (click)="onClickAdd()" primary="true" [size]="'small'">Добавить</button>
    </div>
  </ng-template>
  <kendo-grid-column [field]="fieldsNames.code.toString()" title="Код должности" [width]="160">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
        <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.name.toString()" title="Наименование должности">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
        <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.occupationTypeName.toString()" title="Категория/подкатегория должности" [width]="280">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
        <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.codesString.toString()" title="Коды табельного учёта">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
        <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.startDate.toString()" title="Дата начала" [width]="140">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.startDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>

    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="eq">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
        <kendo-filter-after-operator></kendo-filter-after-operator>
        <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
        <kendo-filter-before-operator></kendo-filter-before-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.endDate.toString()" title="Дата окончания" [width]="160">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.endDate | kendoDate : 'dd.MM.yyyy'}}
    </ng-template>

    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="eq">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
        <kendo-filter-after-operator></kendo-filter-after-operator>
        <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
        <kendo-filter-before-operator></kendo-filter-before-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [field]="fieldsNames.comment.toString()" title="Комментарий">
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" [extra]="false" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
        <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
