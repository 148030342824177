import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { IOccupationOptional, Occupation } from "src/app/classes/domain/POCOs/stafflist/Occupation";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";

/**
 * Сервис работы с контроллером Occupations
 */
@Injectable({
  providedIn: "root"
})
export class Api1OccupationsControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  public addOccupation$(entity: IOccupationOptional): Observable<Occupation>
  {
    return this.httpClient
    .post<Occupation>(this.webApi1Service.controllers.occupation.actions.add.toString(),
      entity)
  }

  /**
   * Сохранить Occupation
   */
  public save$(entity:Occupation): Observable<[]>{
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.occupation.actions.save.toString(),
        entity
      );
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>
  {
    return this.httpClient
    .get<Array<VersioningItem>>(this.webApi1Service.controllers.occupation.actions.getVersionsByOwnerIdAndActionId.toString(),
      {
        params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
      })
  }

  public addOccupationVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.occupation.actions.addVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public editOccupationVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.occupation.actions.editVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }


    public deleteOccupationVersion$(versionId: number) : Observable<boolean>{
      return this.httpClient.delete<boolean>(this.webApi1Service.controllers.occupation.actions.deleteVersion.toString(),
        {params: HttpParamsHelper.getParams([["versionId",versionId]])}
        )
    }


  /**
   * Сохранить Коды табельного учёта
   */
  public updateOccupationCodes$(occupationId: number, codesIdsArray: number[]): Observable<boolean>{
    return this.httpClient
      .post<boolean>(this.webApi1Service.controllers.occupation.actions.updateOccupationCodes.toString(),
        {
          OccupationId: occupationId,
          CodesIdsArray: codesIdsArray
        }
      );
  }

  /** Получить по списку OwnerId */
  public getForDate$(toDate: Date, startDate: Date | null, endDate: Date | null, ownerIds: Array<number> = null) : Observable<Array<Occupation>>{
    return this.httpClient.post<Array<Occupation>>(
      this.webApi1Service.controllers.occupation.actions.getForDate.toString(),
      {
        ownerIds: ownerIds,
        date: toDate,
        startDate: startDate,
        endDate: endDate
      }
    )
  }
}
