import { ElementRef, EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { GridComponent } from "@progress/kendo-angular-grid";
import { TreeListComponent } from "@progress/kendo-angular-treelist";
import { ReplaySubject, fromEvent} from "rxjs";
import {auditTime, bufferWhen, take, takeUntil} from "rxjs/operators";

@Injectable()
export class GridTreeListExpandedDirectiveService implements OnDestroy {


  private _cellMouseOver: EventEmitter<CellMouseEvent> = null;
  public get cellMouseOver(): EventEmitter<CellMouseEvent> {
    if(!this._cellMouseOver) {
      this._cellMouseOver = new EventEmitter<CellMouseEvent>();
      this.startListenMouseoverEvent();
    }
    return this._cellMouseOver;
  }

  private _cellMouseOut: EventEmitter<CellMouseEvent> = null;
  public get cellMouseOut(): EventEmitter<CellMouseEvent> {
    if(!this._cellMouseOut) {
      this._cellMouseOut = new EventEmitter<CellMouseEvent>();
      this.startListenMouseoutEvent();
    }
    return this._cellMouseOut;
  }

  /** Переносить ли текст в шапке таблицы */
  public set tableTitleTextWrap(value: boolean) {
    this._tableTitleTextWrap = Boolean(value);
    this.updateTableTitleTextWrap();
  }
  public get tableTitleTextWrap() {
    return this._tableTitleTextWrap;
  }
  private _tableTitleTextWrap:boolean = null;


  /** Вертикальное расположение текста в шапке таблицы: 'top' или 'middle' или 'bottom' */
  public set tableTitleVerticalAlign(value: TitleVerticalAlignType) {
    this._tableTitleVerticalAlign = value;
    this.updateTitleVerticalAlign();
  }
  public get tableTitleVerticalAlign() {
    return this._tableTitleVerticalAlign;
  }
  private _tableTitleVerticalAlign:TitleVerticalAlignType = null;

  /** Горизонтальное расположение текста в шапке таблицы: 'left' или 'center' или 'right' */
  public set tableTitleHorizontalAlign(value: TitleHorizontalAlignType) {
    this._tableTitleHorizontalAlign = value;
    this.updateTitleHorizontalAlign();
  }
  public get tableTitleVHorizontalAlign() {
    return this._tableTitleHorizontalAlign;
  }
  private _tableTitleHorizontalAlign:TitleHorizontalAlignType = null;

  /** Прокрутить сколл к выделенной строке при инициализации */
  public scrollIntoSelect: boolean = false;

  /** Обновить скролл прокруткой до выделенного элемента */
  public set updateScrollIntoSelect(value: any){
    this.streams$.afterGridOrTreeListInit
      .pipe(
        take(1),
        takeUntil(this.streams$.unsubscribe)
      ).subscribe(value => {
        this.scrollIntoSelectRow();
    })
  }

  /** Padding для шапки грида. Для колонки шапки будет добавлен стиль padding: ЗНАЧЕНИЕ */
  public set headerColumnsPadding(paddingValue: string) {
    this._headerColumnsPadding = paddingValue;
    this.updateHeaderColumnsPadding();
  }
  public get headerColumnsPadding() {
    return this._headerColumnsPadding;
  }
  private _headerColumnsPadding:string = null;

  /** Padding для колонок грида. Для колонки будет добавлен стиль padding: ЗНАЧЕНИЕ */
  public set columnsPadding(paddingValue: string) {
    this._columnsPadding = paddingValue;
    this.updateColumnsPadding();
  }
  public get columnsPadding() {
    return this._columnsPadding;
  }
  private _columnsPadding:string = null;


  /** Компонент грида или древовидного списка */
  private component: GridComponent | TreeListComponent;
  /** HTML елемент */
  private elementRef: ElementRef;

  private _headerColumnsSelector = 'th.k-header';
  private _headerColumnsTextSelector = `${this._headerColumnsSelector} span.k-column-title`;

  /**
   * Конструктор
   */
  constructor() {

  }

  /* Работаем ли мы с гридом или с трилистом */
  private isGrid: boolean;

  private streams$= {
    unsubscribe: new ReplaySubject<any>(1),
    /** Событие окончания инициализации контента в грид/трилист */
    afterContentInit: new ReplaySubject<any>(1),
    /** Полное окончание прорисовки */
    afterGridOrTreeListInit: new ReplaySubject<any>(1),
  }

  public init(gridOrTreeList:  GridComponent | TreeListComponent, elementRef: ElementRef, isGrid:boolean) {
    this.component = gridOrTreeList;
    this.elementRef = elementRef;
    this.isGrid = isGrid;
  }

  private startListenMouseoverEvent() {
    fromEvent(this.elementRef.nativeElement, 'mouseover').pipe(takeUntil(this.streams$.unsubscribe)).subscribe((e: MouseEvent) => {
      const element = e.target as HTMLElement;
      const event: any = e;

      //Если пеермещение было в пределах одного элемента - игнорируем его
      if((event.target ?? event.toElement)?.contains(event.relatedTarget ?? event.fromElement)) {
        return;
      }

      if(element.nodeName === "TD")  {
        const event = this.getCellMouseEventFromHTMLElement(element, e.type);
        this._cellMouseOver.next(event);
      }
    });
  }

  private startListenMouseoutEvent() {
    fromEvent(this.elementRef.nativeElement, 'mouseout').pipe(takeUntil(this.streams$.unsubscribe)).subscribe((e: MouseEvent) => {
      const element = e.target as HTMLElement;
      const event: any = e;

      //Если пеермещение было в пределах одного элемента - игнорируем его
      if((event.relatedTarget ?? event.fromElement)?.contains(event.target ?? event.toElement)) {
        return;
      }

      if(element.nodeName === "TD")  {
        const event = this.getCellMouseEventFromHTMLElement(element, e.type);
        this._cellMouseOut.next(event);
      }
    });
  }

  private getCellMouseEventFromHTMLElement(element: HTMLElement, eventTypeString: string):CellMouseEvent {
    const gridAttribute = "data-kendo-grid-column-index";
    const elementTd:any = element;
    const elementTr:any = element.closest("tr");
    const rowIndex: number = elementTr.rowIndex;
    let columnIndex: number = undefined;

    if(this.isGrid ){
      columnIndex = element.getAttribute(gridAttribute) ? +element.getAttribute(gridAttribute) : undefined;
    } else {
      columnIndex = elementTd.cellIndex;
    }

    let dataItemAtRowIndex =  this.component.view.at(rowIndex);
    const dataItem = dataItemAtRowIndex?.type == "data" ? dataItemAtRowIndex?.data : dataItemAtRowIndex;

    //В некоторых случаях индекс колонки может быть пустой, например если включен режим details у грида
    const column = columnIndex >= 0 ? this.component.columnList.filter(c=> c.leafIndex === columnIndex)[0] : undefined;
    return new CellMouseEvent(
      element,
      eventTypeString,
      columnIndex,
      rowIndex,
      column,
      dataItem
      );
  }

  /**
   * Регистрирует обработчик события CellClick для того чтобы бросать событие CellClickExpanded
   * @param inputCellClickEventEmitter Входящее событие cellClick
   * @param outputCellClickExpandedEventEmitter Исходящее событие cellClickExpanded
   */
  public registerCellClickForCellClickExpandedEvent<TCellClickEvent extends {sender: GridComponent | TreeListComponent, column: any, columnIndex: number, rowIndex: number}>(inputCellClickEventEmitter: EventEmitter<TCellClickEvent>, outputCellClickExpandedEventEmitter: EventEmitter<CellClickExpandedEvent<TCellClickEvent>> ) {
    inputCellClickEventEmitter.pipe(
      takeUntil(this.streams$.unsubscribe),
    ).subscribe(value => {
      //Основная идея была использовать перебор колонок для поиска их по индексу.
      //Но проблема вылазит когда появляются группирующиее колонки, поэтому приняли решение пока просто использовать leafIndex
      //const columnIndex = value.sender.columnList.toArray().findIndex(f=> f === value.column);
      const columnIndex = (value.column).leafIndex;
      outputCellClickExpandedEventEmitter.emit(new CellClickExpandedEvent<TCellClickEvent>(value, columnIndex));
    });
  }

  /**
   * Регистрирует обработчик события CellClick для того чтобы бросать событие doubleCellClick
   * @param inputCellClickExpandedEventEmitter Входящее событие cellClickExpanded
   * @param outputDblCellClickEventEmitter Исходящее событие dblCellClick
   */
  public registerCellClickForDoubleCellClickEvent<TCellClickEvent extends {columnIndex: number, rowIndex: number}>(inputCellClickExpandedEventEmitter: EventEmitter<CellClickExpandedEvent<TCellClickEvent>>, outputDblCellClickEventEmitter: EventEmitter<CellClickExpandedEvent<TCellClickEvent>> ) {
    inputCellClickExpandedEventEmitter.pipe(
      takeUntil(this.streams$.unsubscribe),
      bufferWhen(() => inputCellClickExpandedEventEmitter.pipe(takeUntil(this.streams$.unsubscribe), auditTime(250)))
    ).subscribe(value => {
      if(value.length < 2) {
        return;
      }
      const last = value[value.length - 1];
      const prev = value[value.length - 2];
      if(last.originalEvent.rowIndex != prev.originalEvent.rowIndex || last.originalEvent.columnIndex != prev.originalEvent.columnIndex){
        return;
      }

      outputDblCellClickEventEmitter.emit(last);
    });
  }

  public registerCellMouseOverEvent(outpuCellMouseOverEventEmitter: EventEmitter<CellMouseEvent>) {

  }

  /** Прокрутить скрол до первой выделенной строки */
  public scrollIntoSelectRow(){
    setTimeout(() => {
      this.elementRef.nativeElement.querySelector('.k-table-row.k-selected')?.scrollIntoView();
    }, 100);
  }

  /** Прокрутить до строки по индексу */
  public scrollIntoRowByIndex(index: number){
    if(!index && index !== 0){ //Выходим если не число
      return;
    }

    setTimeout(() => {
      this.elementRef.nativeElement.querySelector(`tr[ng-reflect-data-row-index="${index}"]`)?.scrollIntoView();
    }, 100);
  }

  /** Прокрутить до строки по данным */
  public scrollIntoRow<TDataItem>(dataItem: TDataItem, compareFn?:(i1: TDataItem, i2: TDataItem) => boolean){
    if(!dataItem){
      return;
    }
    if(!compareFn) {
      compareFn = (i1,i2) => i1 == i2;
    }

    setTimeout(() => {
      const indexArr: number[] = [];
      this.component.view.forEach((item, index) => {
        if(compareFn(item.data, dataItem)){
          indexArr.push(item.rowIndex);
        }
      });
      if(indexArr.length == 0){
        return;
      }

      this.scrollIntoRowByIndex(indexArr[0]);
    });
  }

  /**
   * Обновление Padding для шапки грида. Для колонки шапки будет добавлен стиль padding: ЗНАЧЕНИЕ
   */
  private updateHeaderColumnsPadding() {
    this.component.columns.forEach(e=> {
      this.setColumnHeaderStyle(e,"padding", this._headerColumnsPadding);
    });
  }

  /**
 * Обновление Padding для колонок грида. Для колонки будет добавлен стиль padding: ЗНАЧЕНИЕ
 */
  private updateColumnsPadding() {
    this.component.columns.forEach(e=> {
      this.setColumnStyle(e,"padding", this._columnsPadding);
    });
  }

  private setColumnHeaderStyle(column: any, style: string, value:string) {
    if(column.isColumnGroup === true) {
      column.children.forEach(e=> {
        this.setColumnHeaderStyle(e, style, value);
      });
    }
    let headerStyle = column.headerStyle ?? {};
    if(value){
      headerStyle[style]=value;
    } else {
      delete headerStyle[style];
    }
    column.headerStyle = headerStyle;
  }

  private setColumnStyle(column: any, style: string, value:string) {
    if(column.isColumnGroup === true) {
      column.children.forEach(e=> {
        this.setColumnStyle(e, style, value);
      });
    }

    let styles = column.style ?? {};
    if(value){
      styles[style]=value;
    } else {
      delete styles[style];
    }
    column.style = styles;
  }


  /**
   * Обновление стиля вертикльного положения текста в титле грида
   */
  private updateTitleVerticalAlign() {
  this.component.columns.forEach(e=> {
    this.setColumnHeaderStyle(e,"vertical-align", this._tableTitleVerticalAlign);
  });
  }

  /**
   * Обновление стиля горизонтальное положения текста в титле грида
   */
   private updateTitleHorizontalAlign() {
    this.component.columns.forEach(e=> {
      this.setColumnHeaderStyle(e,"text-align", this._tableTitleHorizontalAlign);
    });
  }

  /**
   * Обновление стиля переноса строк в титле грида
   */
  private updateTableTitleTextWrap() {
    const columnsTextElements = this.getHeaderColumnTextNodes();

    columnsTextElements.forEach(columnElement => {
      columnElement.style.whiteSpace = this._tableTitleTextWrap === true ? "normal" : "";
    });
  }

  /** Получить только те элементы непосредственно в которых содержится текст наименования колонки
   * Нужны, например, для того чтобы поменять текст названия колонки или параметры разрыва/переноса текста
   *
   * Как работает: Ищет все ноды th, смотрит есть ли в них span с классом k-column-title, если есть то добавляет только спан если нет то всю ноду th
   */
  private getHeaderColumnTextNodes() {
    let retNodes = [];

    const headersNodes = this.elementRef.nativeElement.querySelectorAll(this._headerColumnsSelector);
    const columnsTextElements = this.elementRef.nativeElement.querySelectorAll(this._headerColumnsTextSelector);

    for(let headerNode of headersNodes) {
      if(!this.containsAnyChildNode(headerNode, columnsTextElements)){
        retNodes.push(headerNode);
      }
    }

    return [...retNodes,...columnsTextElements]
  }

  private containsAnyChildNode(headerNode:any, childNodes: any) {
    for(let childNode of childNodes) {
      if(headerNode.contains(childNode)) {
        return true
      }
    }
    return false;
  }


  /** Событие происходит когда компоненты уже проинициализированы,
   * НО ГРИД еще не проинициализирован. Если нужно должаться инициализации грида или трилиста - используй событие onAfterGridOrTreeListInit
   */
  public ngAfterContentInit() {
    this.streams$.afterContentInit.next(null);//сообщаем

    if(this._tableTitleVerticalAlign) {
      this.updateTitleVerticalAlign();
    }

    if(this._tableTitleHorizontalAlign) {
      this.updateTitleHorizontalAlign();
    }

    if(this.scrollIntoSelect){
      this.scrollIntoSelectRow();
    }

    if(this._headerColumnsPadding) {
      this.updateHeaderColumnsPadding();
    }


    if(this._columnsPadding) {
      this.updateColumnsPadding();
    }

    // Ждем и вызываем событие после инициализации грида или трилиста
    setTimeout(() => {
      this.onAfterGridOrTreeListInit();
    }, 1);
  }

  onAfterGridOrTreeListInit() {
    this.streams$.afterGridOrTreeListInit.next(null); //сообщаем

    if(this._tableTitleTextWrap) {
      this.updateTableTitleTextWrap();
    }
  }


  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();

    this.streams$.afterContentInit.next(null);
    this.streams$.afterContentInit.complete();

    this.streams$.afterGridOrTreeListInit.next(null);
    this.streams$.afterGridOrTreeListInit.complete();
  }

}


export type TitleVerticalAlignType = 'top'|'middle'|'bottom';
export type TitleHorizontalAlignType = 'left'|'center'|'right';

export class CellMouseEvent {
  constructor(
    /** Html элемент на который произошло событие наведения мышкой */
    public htmlElement:any,

    /** Тип события (строка), например: 'mouseover', 'mouseout' */
    public eventType:string,

    /** Индекс колонки (!МОЖЕТ БЫТЬ undefined в некоторых случаях, например если событие произошло на колонку с деталями у грида) */
    public cellIndex: number,

    /** Индекс строки таблицы */
    public rowIndex: number,

    /** Ссылка на компонент колонки грида или трилиста  (!МОЖЕТ БЫТЬ undefined в некоторых случаях, например если событие произошло на колонку с деталями у грида)*/
    public columnComponent: any,

    /** DataItem выбранного элемента */
    public dataItem:any
    ) {}
}

/** Класс эвента обёртки над оригинальным эвентом */
export class CellClickExpandedEvent<TOriginalEvent> {
  /**
   * Эвент обёртка над стандартным CellClick
   * @param originalEvent Оригинальный эвент
   * @param columnIndex Индекс колонки
   */
  constructor(
    public originalEvent: TOriginalEvent,
    public columnIndex: number
  ) {}
}
