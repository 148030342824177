import {Injectable, OnDestroy} from '@angular/core';
import {WebApi1Service} from "../web-api1.service";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs/internal/Observable";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {shareReplay} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class Api1StaffListSettingsControllerService implements OnDestroy{

  public unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _rateStepWithCash$: Observable<number>;

  public get rateStepWithCash$(): Observable<number>{
    if(!this._rateStepWithCash$){
      this._rateStepWithCash$ =  this.getRateStep$().pipe(shareReplay(1));
    }
    return this._rateStepWithCash$;
  }

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) { }

  public getRateStep$(): Observable<number>{
    return  this.httpClient.get<number>(this.webApi1Service.controllers.staffListSettings.actions.getRateStep.toString());
  }

  public getOccupationCodeLength$(): Observable<number>{
    return  this.httpClient.get<number>(this.webApi1Service.controllers.staffListSettings.actions.getOccupationCodeLength.toString());
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
