export class EditEntityWithDependenciesErrorData {
  constructor(
    public CanAfterSafeException: boolean,
    public HierarchiStrings: HierarchiString[]
  ) {}
}

/** Модель данных, отображаемых в строках отчетов об конфликтах и прочих формах с строчной иерархической структурой */
export class HierarchiString {
  constructor(
    public ParentString: string,
    public ChildStrings: HierarchiString[]
  ){}
}

/**
 * Модель данных, отображаемых в строках отчетов об конфликтах и прочих формах с строчной иерархической структурой<br>
 * В отличии от {@link HierarchiString}, названия полей модели начинаются со строчных букв
 */
export class HierarchiStringSmall {
  constructor(
    public parentString: string,
    public childStrings: HierarchiStringSmall[]
  ) {  }

  /** Создать из {@link HierarchiString} */
  public static createFromHierchiString(source: HierarchiString){
    return !source
      ? undefined
      : new HierarchiStringSmall(
        source.ParentString,
        source.ChildStrings?.map(x => this.createFromHierchiString(x))
      )
  }

  /** Создать из массива {@link HierarchiString} */
  public static createFromHierchiStrings(source: HierarchiString[]){
    return source?.map(x => this.createFromHierchiString(x));
  }
}
