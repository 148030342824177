/**
 * Сервис работы с контроллером PrintReportController
 */
import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import {Observable} from "rxjs/internal/Observable";
import {IPrintFormSettingsBase} from "../../../../components/print-form-settings/abstractions/printFormSettingsBase";
import {
  HierarchiStringSmall
} from "../../../../classes/requestResults/EditEntityWithDependenciesErrorData";
import {
  PrintReportPeriod
} from "../../../../components/print-form-settings/report-period-settings/report-period-settings.component";

@Injectable({
  providedIn: "root"
})

export class Api1PrintReportControllerService {

  /**
   * Конструктор
   */
  public constructor(
    private httpClient: HttpClient,
    private webApi1Service: WebApi1Service
  ) {
  }


  public printGraphNew$(graphRedactionId: number, innerKey: string) {
    //ПРОВЕРИТЬ ВСЕ ЛИ ДАННЫЕ СОХРАНЕНЫ НА СЕРВЕРЕ, ЕСЛИ ДА, ТО ДЕЛАЕМ ЗАПРОС НА СЕРВЕР ДЛЯ ФОРМИРОВАНИЯ ОТЧЕТА
    return this.httpClient.get(
      this.webApi1Service.controllers.printReport.actions.printGraphReport.toString(),
      {
        responseType: "blob",
        params: new HttpParams({
            fromObject: {
              redactionId: graphRedactionId.toString(),
              innerKey: innerKey
            }
          }
        )
      }
    );
  }

  public printGraphCustom$(graphRedactionId: number, staffUnitIds: number[]) {
    return this.httpClient.get(
      this.webApi1Service.controllers.printReport.actions.printGraphCustomReport.toString(),
      {
        responseType: "blob",
        params: new HttpParams({
            fromObject: {
              redactionId: graphRedactionId.toString(),
              innerKey: 'graph_custom',
              staffUnitIds: staffUnitIds
            }
          }
        )
      }
    );
  }

  public printTable$(redactionId: number, innerKey: string, startDate: Date, endDate: Date,
                     staffUnitList: number[] = null, staffUnitTypeIds: number[] = null) {
    const reportSettings = {
      redactionId: redactionId, innerKey: innerKey, startDate: startDate, endDate: endDate,
      staffUnitOwnerIdList: staffUnitList, staffUnitTypeIds: staffUnitTypeIds
    };

    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printTableReport.toString(),
      reportSettings,
      {responseType: "blob"}
    );
  }

  public printTableMilk$(redactionId: number, innerKey: string, startDate: Date, endDate: Date) {
    const reportSettings = {
      redactionId: redactionId,
      innerKey: innerKey,
      startDate: startDate,
      endDate: endDate
    };

    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printTableMilkReport.toString(),
      reportSettings,
      {responseType: "blob"}
    );
  }

  public printOperatingPlan$(month: Date) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printOperatingPlan.toString(),
      { month },
      { responseType: "blob" }
    );
  }

  public printProxyMemoNew$(redactionId: number, innerKey: string, startDate: Date, endDate: Date) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printProxyMemoReport.toString(),
      {
        redactionId,
        innerKey,
        startDate,
        endDate
      },
      {responseType: "blob"}
    );
  }

  public printOverTimeMemo$(redactionId: number,
                            innerKey: string,
                            date: Date) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printOverTimeMemoReport.toString(),
      {
        redactionId: redactionId,
        innerKey: innerKey,
        startDate: date
      },
      {responseType: "blob"}
    );
  }

  public printCodeMemo$(redactionId: number,
                        innerKey: string,
                        date: Date,
                        staffUnitTypeIds: number[]) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printCodeMemoReport.toString(),
      {
        redactionId: redactionId,
        innerKey: innerKey,
        startDate: date,
        staffUnitTypeIds: staffUnitTypeIds
      },
      {responseType: "blob"}
    );
  }

  public printMemoRv$(redactionId: number,
                      startDate: Date,
                      innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printMemoRv.toString(),
      {redactionId, startDate, innerKey},
      {responseType: "blob"}
    );
  }

  public printFLReportNew$(reportModel: ReportSettingsWithSubdivisionList) {
    const reportSettings = {
      reportDate: reportModel.reportDate,
      considerLastMinutFlag: reportModel.considerLastMinutFlag,
      subdivisionIdList: reportModel.subdivisionIdList,
      reportTimeIntervalList: reportModel.reportTimeIntervalList
    };
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printFL_Report.toString(),
      reportSettings, {responseType: 'blob'}
    );
  }

  public printTimeIntervalComporisonReport$(reportModel: PrintFormForDateSettingsWithSubdivisions) {
    const req = {
      printFormInnerKey: reportModel.printFormInnerKey,
      date: reportModel.date,
      allSubdivisionsFlag: reportModel.allSubdivisionsFlag,
      subdivisionOwnerIds: reportModel.subdivisionOwnerIds
    };
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printTimeIntervalComporisonReport.toString(),
      req,
      {responseType: "blob"}
    );
  }

  public printCovidRegisterReportNew$(reportModel: RegisterReportSettings, redactionId: number, innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printCovidRegisterReport.toString(),
      {
        InnerKey: innerKey,
        RedactionId: redactionId,
        SectionDates: reportModel.Sections.map(x => x.SectionDate),
        StaffUnitTypeIds: reportModel.StaffUnitTypeIds
      },
      {responseType: 'blob'}
    );
  }

  // Сформировать печатную форму "Журнал учета рабочего времени по табельному коду (Вич/Туб и пр.)" по типам
  // в зависимости от переданного значения innerKey
  public printTableCodeRegister$(reportModel: RegisterReportSettings, redactionId: number, innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printTableCodeRegisterReport.toString(),
      {
        InnerKey: innerKey,
        RedactionId: redactionId,
        SectionDates: reportModel.Sections.map(x => x.SectionDate),
        StaffUnitTypeIds: reportModel.StaffUnitTypeIds,
      },
      {responseType: 'blob'}
    );
  }

  public printInternalConcurrencyRegistration$(staffUnitOwnerId: number, redactionId: number, innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printInternalConcurrencyRegistration.toString(),
      {staffUnitOwnerId: staffUnitOwnerId, redactionId: redactionId, innerKey: innerKey},
      {responseType: 'blob'}
    )
  }

  public printUvorRegistration$(staffUnitOwnerId: number, redactionId: number, innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printUvorRegistration.toString(),
      {staffUnitOwnerId: staffUnitOwnerId, redactionId: redactionId, innerKey: innerKey},
      {responseType: 'blob'}
    )
  }

  public printCombinationRegistration$(staffUnitOwnerId: number, redactionId: number, innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printCombinationRegistration.toString(),
      {staffUnitOwnerId: staffUnitOwnerId, redactionId: redactionId, innerKey: innerKey},
      {responseType: 'blob'}
    )
  }

  public printDutyRegistration$(staffUnitOwnerId: number, redactionId: number, innerKey: string): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printDutyRegistration.toString(),
      {staffUnitOwnerId, redactionId, innerKey},
      {responseType: 'blob'}
    );
  }

  public printTotalCovidReport$(reportModel: PrintReportPeriod) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printTotalCovidReport.toString(),
      reportModel,
      {responseType: 'blob'}
    );
  }

  // Получение списка редакций, необходимых для формирования корректирующих табелей
  public getCorrectionTableRedactions$(subdivisionId: number, month: Date) {
    return this.httpClient.get<CorrectionRedactionInfo[]>(
      this.webApi1Service.controllers.printReport.actions.getCorrectionTableRedactions.toString(),
      {
        params: new HttpParams({
          fromObject: {subdivisionId: subdivisionId.toString(), month: month.toDateString()}
        })
      }
    )
  }

  // Получение списка редакций, необходимых для формирования корректирующих графиков
  public getCorrectionGraphRedactions$(subdivisionId: number, month: Date) {
    return this.httpClient.get<CorrectionRedactionInfo[]>(
      this.webApi1Service.controllers.printReport.actions.getCorrectionGraphRedactions.toString(),
      {
        params: new HttpParams({
          fromObject: {subdivisionId: subdivisionId.toString(), month: month.toDateString()}
        })
      }
    )
  }

  // Сформировать отчет "Список сотрудников подразделения"
  public subdivisionStaffUnitsReport$(settings: PrintFormFromTillDatesWithStaffUnitTypesSettings, subdivisionId: number): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printSubdivisionStaffUnitsReport.toString(),
      {...settings, subdivisionId},
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Перечень подразделений" (innerKey = "subdivision_list")
  public subdivisionListReport$(settings: PrintFormForDateSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printSubdivisionListReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Список сотрудников, исполняющих обязанности" (innerKey = "duties_execution")
  public dutiesExecutionReport$(settings: PrintFormFromtillDatesSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printDutiesExecutionReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Отчет по занятым ставкам" (innerKey = "busy_rates")
  public busyRatesReport$(settings: PrintFormForDateSettingsWithSubdivisions): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printBusyRatesReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Отчет - занятые ставки по сотрудникам" (innerKey = "staff_busy_rates")
  public staffBusyRatesReport$(settings: PrintFormFromtillDatesSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printStaffBusyRatesReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Отчет по отработанному времени" (innerKey = "worked_time_report")
  public workedTimeReport$(settings: PrintFormFromtillDatesSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printworkedTimeReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Лимит штатных единиц" (innerKey = "limit_staffunit_rates_report")
  public printLimitStaffUnitRatesReport$(settings: LimitStaffUnitRatesReportSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printLimitStaffUnitRatesReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  public printEmploymentContract$(innerKey: string, staffUnitId: number, staffUnitStartDate: Date): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printEmploymentContract.toString(),
      {innerKey, staffUnitId, staffUnitStartDate},
      {responseType: 'blob'}
    );
  }

  public printMilkOrderReport$(settings: PrintFormMilkOrderSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printMilkOrderReport.toString(),
      settings,
      {responseType: 'blob'}
    )
  }

  public printMilkOrderFirstList$(date: Date): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printMilkOrderFirstList.toString(),
      {date: date},
      {responseType: 'blob'}
    )
  }


  // Сформировать отчет "Суммированный учет" (innerKey = "summary_report")
  public summaryReportReport$(settings: PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printSummaryReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать печатную форму "Отчет по вакантным ставкам (по дням)" (innerKey = "daily_free_rates")
  public dailyFreeRatesReport$(settings: PrintFormForDateSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.dailyFreeRatesReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Фактическое количество часов по должностям" (innerKey = "position_hours_count")
  public printPositionHoursCountReport$(redactionId: number) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printPositionHoursCountReport.toString(),
      redactionId,
      {responseType: 'blob'}
    );
  }

  // Сформировать печатную форму "Приказ О начислении дополнительной оплаты (месяц)" (innerKey = 'short_proxy_order.xlsx')
  public printShortProxyOrder(settings: PrintFormForDateSettings): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printShortProxyOrder.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  // Сформировать печатную форму "Служебная записка на стимулирование принятых сотрудников" (innerKey = "memo_stimul_new_staffunit")
  // или "Служебная записка на стимулирование сотрудников по внутреннему совместительству" (innerKey = "memo_stimul_inner_moonlighter")
  // в зависимости от переданного значения innerKey
  public printMemoStimul$(innerKey: string, redactionId: number) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printMemoStimul.toString(),
      {innerKey: innerKey, redactionId: redactionId},
      {responseType: 'blob'}
    );
  }

  // Сформировать печатную форму "Рапорт на совмещение за счет вакантной ставки" или
  // "Рапорт на совмещение за счет временного отсутствия сотрудника"
  // в зависимости от переданного значения innerKey
  public printCombinationRaport$(innerKey: string, redactionId: number) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printCombinationRaport.toString(),
      {innerKey: innerKey, redactionId: redactionId},
      {responseType: 'blob'}
    );
  }

  // Сформировать печатную форму "Рапорт на УВОР за счет вакантной ставки" или
  // "Рапорт на УВОР за счет временного отсутствия сотрудника"
  // в зависимости от переданного значения innerKey
  public printUvorRaport$(innerKey: string, redactionId: number) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printUvorRaport.toString(),
      {innerKey: innerKey, redactionId: redactionId},
      {responseType: 'blob'}
    );
  }

  /** Сформировать печатную форму "Служебная записка на стимулирование дополнительного объёма работы" */
  public printMemoStimulDuty$(graphRedactionId: number, innerKey: string) {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printMemoStimulDuty.toString(),
      {graphRedactionId: graphRedactionId, innerKey: innerKey},
      {responseType: 'blob'}
    );
  }

  // Сформировать отчет "Штатное расписание" (innerKey = "staff_list")
  public printStaffListReport$(settings: any): Observable<Blob> {
    return this.httpClient.post(
      this.webApi1Service.controllers.printReport.actions.printStaffListReport.toString(),
      settings,
      {responseType: 'blob'}
    );
  }

  //Сформировать отчет "Движение пациентов" (innerKey = "patientMovement")
  public printPatientMovement$(date: Date): Observable<Blob> {
    return this.httpClient.post(this.webApi1Service.controllers.printReport.actions.printPatientMovement.toString(),
      {
        printFormInnerKey: 'patientMovement',
        date: date
      },
      {responseType: 'blob'});
  }

  /** Сформировать файл Excel по данным, сформированным по результату проверки данных графика */
  public printCheckReport$(parameters: { subdivisionId: number, month: Date }): Observable<Blob> {
    return this.httpClient.post(this.webApi1Service.controllers.printReport.actions.printCheckReport.toString(),
      {
        subdivisionId: parameters.subdivisionId,
        month: parameters.month
      },
      {responseType: 'blob'});
  }

  /** Сформировать файл Excel по сформированному в виде иерархической строки результату проверки на ошибки и конфликты */
  public printCheckReport2$(hierarchiStrings: HierarchiStringSmall[]): Observable<Blob> {
    return this.httpClient.post(this.webApi1Service.controllers.printReport.actions.printHierarchiStrings.toString(),
      {
        hierarchiStrings: hierarchiStrings
      },
      {responseType: 'blob'});
  }
}

/**
 * Модель, передаваемая серверу
 */
export class ReportSettingsWithSubdivisionList {
  constructor(public innerKey: string,
              public reportDate: Date,
              public subdivisionIdList: Array<number>,
              public reportTimeIntervalList: Array<PeriodTimeIntervalModel>,
              public considerLastMinutFlag: boolean) {
  }
}

export class PeriodTimeIntervalModel {
  constructor(public startTime: Date,
              public endTime: Date) {
  }
}


/**
 * Дата начала раздела журнала
 */
export class CovidRegisterSection {
  constructor(public SectionDate: Date) {
  }
}

/** Настройки печатаемых журналов */
export class RegisterReportSettings {
  constructor(public Sections: CovidRegisterSection[],
              public StaffUnitTypeIds: number[]) {
  }
}

export class PrintFormForDateSettings implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public date: Date) {
  }
}

export class DateSettingsWithSubdivisionId extends PrintFormForDateSettings {
  constructor(public subdivisionId: number,
              public date: Date,
              public printFormInnerKey: string) {
    super(printFormInnerKey, date);
  }
}

export class PrintFormForDateSettingsWithSubdivisions extends PrintFormForDateSettings implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              date: Date,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[]) {
    super(printFormInnerKey, date);
  }
}

/** Модель настроек печатной формы "Приказ О распределении денежной компенсации за выдачу молока*/
export class PrintFormMilkOrderSettings extends PrintFormForDateSettingsWithSubdivisions implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              date: Date,
              public milkCost: number,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[]) {
    super(printFormInnerKey, date, allSubdivisionsFlag, subdivisionOwnerIds);
  }
}

/** Модель настроек печатной формы, содержащей выбор даты, списка подразделений и типов исполнения должностей */
export class PrintFormForDateSettingsWithSubdivisionsAndStaffUnitTypes extends PrintFormForDateSettingsWithSubdivisions implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public date: Date,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[],
              public staffUnitTypeIds: number[]) {
    super(printFormInnerKey, date, allSubdivisionsFlag, subdivisionOwnerIds);
  }
}

export class PrintFormFromtillDatesSettings implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public fromDate: Date,
              public tillDate: Date,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[]) {
  }
}

export class PrintFormFromTillDatesWithStaffUnitTypesSettings implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public fromDate: Date,
              public tillDate: Date,
              public staffUnitTypeIds: number[]) {
  }
}

export class PrintFormFromTillDatesWithSubdivisionIdAndStaffUnitTypesSettings extends PrintFormFromTillDatesWithStaffUnitTypesSettings {
  constructor(public printFormInnerKey: string,
              public fromDate: Date,
              public tillDate: Date,
              public subdivisionId: number,
              public staffUnitTypeIds: number[]) {
    super(printFormInnerKey, fromDate, tillDate, staffUnitTypeIds);
  }
}

export class LimitStaffUnitRatesReportSettings implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public reportDate: Date,
              public occupationTypeIds: number[],
              public financingSourceIds: number[],
              public subdivisionIds: number[]
  ) {
  }
}

export class PrintQuarterSettingsWithStaffUnitTypes {
  constructor(public printFormInnerKey: string,
              public subPeriodName: string,
              public startDate: Date,
              public endDate: Date,
              public staffUnitTypeIds: number[]) {  }
}


export class PrintQuarterSettingsWithSubdivisions implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public subPeriodName: string,
              public startDate: Date,
              public endDate: Date,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[]) {
  }
}

export class PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes extends PrintQuarterSettingsWithSubdivisions {
  constructor(public printFormInnerKey: string,
              public subPeriodName: string,
              public startDate: Date,
              public endDate: Date,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[],
              public staffUnitTypeIds: number[]) {
    super(printFormInnerKey, subPeriodName, startDate, endDate, allSubdivisionsFlag, subdivisionOwnerIds);
  }
}

/* Тип мультипериодного отчета */
export enum MultiplePeriodSettingsEnum {
  /* Год */
  year = 0,
  /* Полугодие */
  halfOfYear = 1,
  /* Квартал */
  quarter = 2,
  /* Два месяца */
  coupleOfMonths = 3,
  /* Месяц */
  months = 4
}

export class StaffListReportSettings implements IPrintFormSettingsBase {
  constructor(public printFormInnerKey: string,
              public date: Date,
              public allSubdivisionsFlag: boolean,
              public subdivisionOwnerIds: number[],
              public financingSourceId: number) {
  }
}

export class CorrectionRedactionInfo {
  redactionId: number;
  redactionDate: Date;
  versionNumber: number;
}
