import { Injector, OnDestroy, ProviderToken } from "@angular/core";
import { BehaviorSubject, defer, finalize, Observable } from "rxjs";

  /**
   * Создает экземпляр класса сервиса, который реализует {@link OnDestroy}
   * создание нового экземпляра происходит при каждой подписке, использует {@link BehaviorSubject}
   * после того как все отписались вызывает у созданного класса метод {@link ngOnDestroy}
   */
  export function exCreateService$<TServiceInstance extends OnDestroy>(createServiceFactory: () => TServiceInstance): Observable<TServiceInstance> {
    return exCreateService2$(createServiceFactory, (inst: TServiceInstance) => {
      inst.ngOnDestroy();
    })
  }

  /**
   * Создает Observable, который при подписке на него создаст экземпляр сервиса и вызовет переданную лямду onDestroy когда от него все отпишутся
   * @param createServiceFactory функция создания экземпляра сервиса
   * @param onDestroy функция, которая будет выполнена когда от сервиса все отпишутся
   * @returns
   */
  export function exCreateService2$<TServiceInstance>(createServiceFactory: () => TServiceInstance, onDestroyFn: (inst: TServiceInstance) => void): Observable<TServiceInstance> {
  return defer(() => {
    const oss = createServiceFactory();
    return new BehaviorSubject(oss)
    .pipe(
      finalize(() => onDestroyFn(oss))
      )
    });
  }
