import { Injectable } from '@angular/core';
import { Api1DateControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-date-controller.service';
import { exLoadingMessage } from '../../../../../../../../src/app/operators/ex-loading-message.operator';
import { LoadingIndicatorService } from '../../../../../../../../src/app/services/loading-indicator.service';
import { Observable, take } from 'rxjs';
import {
  Api1DaysTypeControllerService
} from "../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-day-type-controller.service";

@Injectable()
export class ProductionCalendarDirectoryDataService {

  public get getYears$() {
    return this.api1DateControllerService.getYears$
      .pipe(take(1), exLoadingMessage(this.loadingIndicatorService, 'Загрузка списка годов'));
  }

  public get getDayTypes$() {
    return this.api1DaysTypeControllerService.getAll$
      .pipe(take(1), exLoadingMessage(this.loadingIndicatorService, 'Загрузка типов дней'));
  }

  constructor(private readonly api1DateControllerService: Api1DateControllerService,
              private readonly api1DaysTypeControllerService: Api1DaysTypeControllerService,
              private readonly loadingIndicatorService: LoadingIndicatorService) {
  }

  public setDayType$(id: number, dayTypeId: number, timestamp: []): Observable<[]> {
    return this.api1DateControllerService.setDayType$(id, dayTypeId, timestamp);
  }

}
