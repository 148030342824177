import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

/** Строка справочника исчисления стража */
export interface ISeniority extends IEntityId, IEntityModify, IEntityDeletedFlag, IEntityComment, IEntityRowVersion {
  percent: number;
  minYears: number;
  maxYears: number;
}

@classBackend('Seniority', 'tarification_directories')
@className('Seniority')
/** Строка справочника исчисления стажа */
export class Seniority implements ISeniority {
  /** Идентификатор */
  id: number;
  /** Дата последней модификации строки справочника */
  modifiedDate: Date;
  /** Идентификатор пользователя, модифицировавшего запись */
  modifiedUserId: number | null;
  /** Флаг удаления строки справочника */
  deletedFlag: boolean;
  /** Комментарий к строке справочника */
  comment: string;
  /** Надбавка за стаж */
  percent: number;
  /** От скольки лет вкючительно */
  minYears: number;
  /** До скольки лет не вкючительно */
  maxYears: number;
  /** Значение, автоматически присваиваемое БД при модификации строки */
  timestamp: [];
}

