import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IDropDownItem} from "../../../../classes/requestResults/iDropDownItem";
import { HttpClient, HttpParams } from "@angular/common/http";
import {WebApi1Service} from "../web-api1.service";
import { Subdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { HttpParamsHelper } from "src/app/helpers/httpParamsHelper";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import {EditEndDateDto, IdAndRowVersionType} from "./shared-DTOs/edit-end-date-dto";
import { SupportedActionEnum } from "src/app/classes/domain/enums/supported-action-enum";

/** Сервис работы с контроллером Subdivision */
@Injectable({
  providedIn: "root"
})
export class Api1SubdivisionControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить подразделение
   * @param year
   * @param month
   * @param subdivisionOwnerId
   */
  public getSubdivisionByYearMonthAndId$(year: number, month: number, subdivisionOwnerId: number): Observable<IDropDownItem>{
    return this.httpClient
      .get<IDropDownItem>(this.webApi1Service.controllers.subdivision.actions.getSubdivisionByYearMonthAndId.toString(),
        {
          params: new HttpParams({
            fromObject: {
              year: year.toString(),
              month: month.toString(),
              subdivisionId: subdivisionOwnerId.toString()
            }})
        });
  }

  /** Получить Subdivisions на указанную дату */
  public getForDate$(date: Date, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<Subdivision>> {
    return this.httpClient
      .post<Array<Subdivision>>(
        this.webApi1Service.controllers.subdivision.actions.getForDate.toString(),
        { ownerIds, startDate, endDate, date }
      );
  }

  public addSubdivision$(entity: Subdivision): Observable<Subdivision>
  {
    return this.httpClient
    .post<Subdivision>(this.webApi1Service.controllers.subdivision.actions.add.toString(),
      entity)
  }

  public reorderSubdivision$(subdivision1Id: number,subdivision2Id: number, date:Date ): Observable<ReorderResult>
  {
    return this.httpClient
    .post<ReorderResult>(this.webApi1Service.controllers.subdivision.actions.reorder.toString(),
      { subdivision1Id, subdivision2Id, date })
  }


  /** Сохранить Subdivision */
  public save$(entity:Subdivision): Observable<[]>{
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.subdivision.actions.save.toString(),
        entity
      );
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>>
  {
    return this.httpClient
    .get<Array<VersioningItem>>(this.webApi1Service.controllers.subdivision.actions.getVersionsByOwnerIdAndActionId.toString(),
      {
        params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]])
      })
  }

  public addSubdivisionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.subdivision.actions.addVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }

  public editSubdivisionVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>
  {
    return this.httpClient
    .post<VersioningItem>(this.webApi1Service.controllers.subdivision.actions.editVersion.toString(),
      {
        OwnerId:versioningEntity.ownerId,
        ActionId:actionId,
        Data: versioningEntity
      })
  }


  public deleteSubdivisionVersion$(versionId: number) : Observable<boolean>{
    return this.httpClient.delete<boolean>(this.webApi1Service.controllers.subdivision.actions.deleteVersion.toString(),
      {params: HttpParamsHelper.getParams([["versionId",versionId]])}
      )
  }

  /** Редактировать окончание срока действия */
  public editEndDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.subdivision.actions.editEndDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }
}

export class ReorderResult
{
  currentSubdivision: Subdivision;
  affectedSubdivision: Subdivision;
}
