import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { DataBindingDirective, GridComponent } from "@progress/kendo-angular-grid";
import { SortDescriptor } from "@progress/kendo-data-query";
import { ReplaySubject, takeUntil } from "rxjs";
import { CustomStorageService, StorageOptions } from "../services/storages/custom-storage.service";

/**
 * Директива расширающая сортировку в компоненте kendo grid и позволяющая запоминать её
 */
@Directive({
  selector: '[KendoGridExpandedSaveSort]'
})
export class KendoGridExpandedSaveSortDirective implements OnInit, OnDestroy {

  /** Инпут чтобы перехватить данные сортировки, которые пытаются установить по умолчанию */
  @Input() sort: Array<SortDescriptor> | null | undefined;

  /** Настройки сохранения сортировки, которые должны быть заполнены */
  @Input() sortStorageOptions: StorageOptions;

  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  constructor(private readonly grid: GridComponent,
    private readonly dbDirective: DataBindingDirective,
    private readonly customStorageService: CustomStorageService,) {
  }

  public ngOnInit(): void {
    /** Если настройки не задали, то никакого сохранения и загрузки из хранилища нет */
    if(this.sortStorageOptions){
      this.initSortStorage();
    }
  }

  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }

  /** Инициализация хранения сортировки в хранилище */
  private initSortStorage() {

    //Подписываемся на событие изменения сортировки
    this.grid.sortChange.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(sort=> {

      //Сохраняем настройки сортировки
      this.customStorageService.set(this.sortStorageOptions, sort);
    });

    // Пытаемся загрузкить настройки сортировки из хранилища
    const loadedSort = this.customStorageService.get<SortDescriptor[]>(this.sortStorageOptions);
    if(loadedSort) {

      //Если в хранилище были настройки сортировки то устанавливаем их в директиву биндинга данных для грида
      //Почему через директиву? Потому что она сама вызывает все события чтобы пересчитались данные
      this.dbDirective.sort = loadedSort;
    }
  }


}
