import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { AddLimitStaffUnitDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitsControl-controller.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TracerServiceBase } from '../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceClass } from '../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { combineLatest, filter, map, Observable, ReplaySubject, startWith } from 'rxjs';
import { traceFunc } from '../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { CustomFormValidators } from '../../../../../../../src/app/validators/custom-form-validators.class';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IOccupationType } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/OccupationType';
import { IFinancingSource } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/FinancingSource';
import { DataSource } from '../../../../../../../src/app/classes/array-data-sources/data-source';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../src/app/services/data-source-services/data-source.service';
import { ISubdivision } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision';
import { ForDate, IForDate } from '../../../../../../../src/app/classes/for-date';
import { exDistinctUntilChanged } from '../../../../../../../src/app/operators/ex-distinct-until-changed.operator';

@Component({
  selector: 'app-add-limit-staff-unit',
  templateUrl: './add-limit-staff-unit.component.html',
  styleUrls: ['./add-limit-staff-unit.component.css'],
})
@traceClass('AddLimitStaffUnitComponent')
export class AddLimitStaffUnitComponent implements OnInit, OnDestroy {

  @Input('dataItem') public dataItem: AddLimitStaffUnitDTO;
  @Output('onSave') public onSaveEventEmitter = new EventEmitter<AddLimitStaffUnitDTO>();
  @Output('onCancel') public onCancelEventEmitter = new EventEmitter<boolean>();

  public form: AddLimitStaffUnitComponentFormGroup;
  public type: 'occupationType' | 'subdivision' = 'occupationType';
  public dataSource: AddLimitStaffUnitComponent_DataSourceService;
  public occupationTypes$: Observable<IOccupationType[]>;
  public subdivisions$: Observable<ISubdivision[]>;
  public financingSources$: Observable<IFinancingSource[]>;
  public isEditingRatesGrid = false;
  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly tracerService: TracerServiceBase) {
  }

  ngOnInit(): void {
    this.form = this.initForm(this.dataItem);

    this.occupationTypes$ = this.dataSource.data.occupationTypes
      .reloadData$({ occupationTypeIds: null })
      .pipe(switchMap(s => s.data$));
    this.financingSources$ = this.dataSource.data.financingSources
      .reloadData$({ financingSourcesIds: null })
      .pipe(switchMap(s => s.data$));
    this.subdivisions$ = this.dataSource.data.subdivisions.dataSource.data$;
  }

  public onClickSave() {
    this.onSaveEventEmitter.emit({
      ...this.form.value as AddLimitStaffUnitDTO,
      occupationTypeId: this.form.controls.occupationTypeId.value,
    });
  }

  public onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  public onChangeIsEditingPositionRatesGrid(state: boolean) {
    this.isEditingRatesGrid = state;
  }

  public getDefaultDataItemOccupationType() {
    return this.dataSource.data.occupationTypes.dataSource.data?.find(v => v.id === this.form.controls.occupationTypeId.value) ?? {};
  }

  @traceFunc()
  private initForm(item: AddLimitStaffUnitDTO): AddLimitStaffUnitComponentFormGroup {
    const startDateControl = new FormControl(null, { validators: [CustomFormValidators.validateStartDateWithEndDate('endDate'), Validators.required], updateOn: 'blur' });
    const endDateControl = new FormControl( null, { validators: [CustomFormValidators.validateEndDateWithStartDate('startDate')], updateOn: 'blur' });

    const form = new FormGroup({
      startDate: startDateControl,
      endDate: endDateControl,
      comment: new FormControl(item?.comment ?? '', { validators: [Validators.maxLength(100)] }),
      occupationTypeId: new FormControl({ value: item?.occupationTypeId ?? null, disabled: this.type === 'subdivision' }, [Validators.required]),
      subdivisionId: new FormControl({ value: item?.subdivisionId ?? null, disabled: true }, [CustomFormValidators.requiredIf(() => this.type === 'subdivision')]),
      rates: new FormControl(item?.rates),
    });

    form.controls.rates.addValidators(CustomFormValidators.withExpression(
      () => form.controls.rates.pristine || !form.controls.rates.value?.length,
      () => Validators.required,
    ));

    form.markAllAsTouched();

    if (this.type === 'subdivision') {
      combineLatest([
        startDateControl.valueChanges.pipe(exDistinctUntilChanged(startDateControl.value)),
        endDateControl.valueChanges.pipe(exDistinctUntilChanged(endDateControl.value), startWith(null)),
      ]).pipe(
        map(([startDate, endDate]) => ({ startDate, endDate })),
        filter(v => !!v.startDate),
        switchMap(v => this.dataSource.data.subdivisions.reloadData$(ForDate.Get(v))),
        takeUntil(this.streams$.unsubscribes),
      ).subscribe(() => {
        if (this.form.controls.subdivisionId.disabled) {
          this.form.controls.subdivisionId.enable();
        }
      });
    }

    return form;
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}

/** Тип формы добавления */
export type AddLimitStaffUnitComponentFormGroup = FormGroup<PropertyWrapFormControlType<AddLimitStaffUnitDTO>>;

export class AddLimitStaffUnitComponent_DataSourceService extends DataSource<AddLimitStaffUnitComponent_Data> {

}

export class AddLimitStaffUnitComponent_Data {
  constructor(public occupationTypes: ArrayDataSourceIEntityIdServiceWithParamsBase<any, IOccupationType>,
              public subdivisions: ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, ISubdivision>,
              public financingSources: ArrayDataSourceIEntityIdServiceWithParamsBase<any, IFinancingSource>) {
  }
}
