import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Api1PrintFormControllerService, PrintForm,
}
  from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-form-controller.service';
import { delay, take, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  PrintQuarterSettingsWithSubdivisionsComponent,
} from '../../../../../../../src/app/components/print-form-settings/print-quarter-settings-with-subdivisions/print-quarter-settings-with-subdivisions.component';
import {
  PrintFormFromtillDatesSettingsComponent,
} from '../../../../../../../src/app/components/print-form-settings/print-form-fromtill-dates-settings/print-form-fromtill-dates-settings.component';
import {
  PrintFormForDateSettingsComponent,
} from '../../../../../../../src/app/components/print-form-settings/print-form-for-date-settings/print-form-for-date-settings.component';
import { defer, race } from 'rxjs';
import { IPrintFormSettingsComponentBase } from '../../../../../../../src/app/components/print-form-settings/abstractions/IPrintFormSettingsComponentBase';
import { Observable } from 'rxjs/internal/Observable';
import {
  Api1PrintReportControllerService,
  LimitStaffUnitRatesReportSettings,
  PrintFormForDateSettings,
  PrintFormForDateSettingsWithSubdivisions,
  PrintFormFromtillDatesSettings, PrintFormMilkOrderSettings,
  PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes,
  StaffListReportSettings,
} from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-print-report-controller.service';
import {
  StaffListReportSettingsComponent,
} from '../../../../../../../src/app/components/print-form-settings/staff-list-report-settings/staff-list-report-settings.component';
import {
  PrintFormForDateSettingsWithSubdivisionTreeComponent,
} from '../../../../../../../src/app/components/print-form-settings/print-form-for-date-settings-with-subdivision-tree/print-form-for-date-settings-with-subdivision-tree.component';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { trace } from 'src/app/modules/trace/operators/trace';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import {
  DisplayErrorsService,
} from '../../../../../../../src/app/components/display-errors/services/display-errors.service';
import {
  SubdivisionsTreelistComponentDataSourceService,
} from '../../../../../../../src/app/components/subdivisions/subdivisions-treelist/services/subdivisions-treelist-component-data.service';
import {
  Api1SubdivisionControllerService,
} from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-subdivision-controller.service';
import { DbChangedListener } from '../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { ForDate } from '../../../../../../../src/app/classes/for-date';
import { exErrorHandler } from '../../../../../../../src/app/operators/ex-error-handler';
import {
  ArrayDataSourceSelection,
} from '../../../../../../../src/app/classes/array-data-sources/selections/array-data-source-selection';
import { ISubdivision } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/Subdivision';
import {
  LimitStaffunitRatesReportSettingsComponent,
} from '../../../../../../../src/app/components/print-form-settings/limit-staffunit-rates-report-settings/limit-staffunit-rates-report-settings.component';
import { PrintFormType } from '../../../../../../../src/app/classes/domain/enums/print-form-type';
import {
  MilkOrderSettingsComponent
} from "../../../../../../../src/app/components/print-form-settings/milk-order-settings/milk-order-settings.component";
import {
  PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent
} from "../../../../../../../src/app/components/print-form-settings/print-quarter-settings-with-subdivisions-and-staffunittypes/print-quarter-settings-with-subdivisions-and-staff-unit-types.component";

@Component({
  selector: 'app-print-forms',
  templateUrl: './print-forms.component.html',
  styleUrls: ['./print-forms.component.css'],
})
@traceClass('PrintFormsComponent')
export class PrintFormsComponent implements OnInit, OnDestroy {

  public printFormList$: Observable<PrintForm[]>;
  public printFormType: PrintFormType = PrintFormType.StaffListReport;
  public tabs = [{ title: 'Отчёты', type: PrintFormType.StaffListReport, selected: true }, { title: 'Приказы', type: PrintFormType.StaffListOrder, selected: false }];
  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _dialogRef: DialogRef;
  private printFormDict: { [innerKey: string]: HendleSettings<IPrintFormSettingsComponentBase> } = {
    'busy_rates': new HendleSettings<PrintFormForDateSettingsWithSubdivisionTreeComponent>(
      PrintFormForDateSettingsWithSubdivisionTreeComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.dateChange$.pipe(takeUntil(x.unsubscribe$)).subscribe({
          next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value, null, value))
            .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
        });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
        x.datePickerViewParams.activeView ="month";
        x.datePickerViewParams.deepBottomView = "month";
        x.datePickerViewParams.format = "dd.MM.yyyy";
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormForDateSettingsWithSubdivisions) => this.printReportControllerService.busyRatesReport$(settings)),
    'worked_time_report': new HendleSettings<PrintFormFromtillDatesSettingsComponent>(
      PrintFormFromtillDatesSettingsComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.datesChange$.pipe(takeUntil(x.unsubscribe$))
          .subscribe({
            next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value.startDate, value.endDate, value.startDate))
              .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
          });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormFromtillDatesSettings) => this.printReportControllerService.workedTimeReport$(settings)),
    'duties_execution': new HendleSettings<PrintFormFromtillDatesSettingsComponent>(
      PrintFormFromtillDatesSettingsComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.datesChange$.pipe(takeUntil(x.unsubscribe$))
          .subscribe({
            next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value.startDate, value.endDate, value.startDate))
              .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
          });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormFromtillDatesSettings) => this.printReportControllerService.dutiesExecutionReport$(settings)),
    'staff_busy_rates': new HendleSettings<PrintFormFromtillDatesSettingsComponent>(
      PrintFormFromtillDatesSettingsComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.datesChange$.pipe(takeUntil(x.unsubscribe$))
          .subscribe({
            next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value.startDate, value.endDate, value.startDate))
              .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
          });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormFromtillDatesSettings) => this.printReportControllerService.staffBusyRatesReport$(settings)),
    'staff_list': new HendleSettings<StaffListReportSettingsComponent>(
      StaffListReportSettingsComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.dateChange$.pipe(takeUntil(x.unsubscribe$)).subscribe({
          next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value, value, value))
            .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
        });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },

      (settings: StaffListReportSettings) => this.printReportControllerService.printStaffListReport$(settings)),
    'subdivision_list': new HendleSettings<PrintFormForDateSettingsComponent>(
      PrintFormForDateSettingsComponent,
      x => void x,
      x => void x,
      (settings: PrintFormForDateSettings) => this.printReportControllerService.subdivisionListReport$(settings)),
    'summary_report': new HendleSettings<PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent>(
      PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypesComponent,
      x => {
        setTimeout(() => {
          x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
            this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
          x.childSettingsComponent.datesChange$.pipe(takeUntil(x.childSettingsComponent.unsubscribe$))
            .subscribe({
              next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value.startDate, value.endDate, value.startDate))
                .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe()
            });
          x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
          x.childSettingsComponent.datesChange$.emit({startDate: x.childSettingsComponent.startDate, endDate: x.childSettingsComponent.endDate});
        })
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintQuarterSettingsWithSubdivisionsAndStaffUnitTypes) => this.printReportControllerService.summaryReportReport$(settings)),
    'limit_staffunit_rates_report': new HendleSettings<LimitStaffunitRatesReportSettingsComponent>(
      LimitStaffunitRatesReportSettingsComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.reportDateChange$.pipe(takeUntil(x.unsubscribe$)).subscribe({
          next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value, value, value))
            .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
        });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: LimitStaffUnitRatesReportSettings) => this.printReportControllerService.printLimitStaffUnitRatesReport$(settings)),
    'milk_order': new HendleSettings<MilkOrderSettingsComponent>(
      MilkOrderSettingsComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.dateChange$.pipe(takeUntil(x.unsubscribe$)).subscribe({
          next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value, null, value))
            .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
        });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormMilkOrderSettings) => this.printReportControllerService.printMilkOrderReport$(settings)),

    'daily_free_rates': new HendleSettings<PrintFormForDateSettingsWithSubdivisionTreeComponent>(
      PrintFormForDateSettingsWithSubdivisionTreeComponent,
      x => {
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.dateChange$.pipe(takeUntil(x.unsubscribe$)).subscribe({
          next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value, null, value))
            .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
        });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
        x.datePickerViewParams.format = 'Y';
        x.datePickerViewParams.activeView = 'year';
        x.datePickerViewParams.deepBottomView = 'year';
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormForDateSettingsWithSubdivisions) => this.printReportControllerService.dailyFreeRatesReport$(settings)),

    'short_proxy_order': new HendleSettings<PrintFormForDateSettingsWithSubdivisionTreeComponent>(
      PrintFormForDateSettingsWithSubdivisionTreeComponent,
      x => {
        x.datePickerViewParams.deepBottomView = 'year';
        x.datePickerViewParams.format = 'LLLL';
        x.datePickerViewParams = {...x.datePickerViewParams};
        x.subdivisionDataSourceService = new SubdivisionsTreelistComponentDataSourceService(
          this.subdivisionControllerService, this.loadingIndicatorService, this.dbChangedListener);
        x.dateChange$.pipe(takeUntil(x.unsubscribe$)).subscribe({
          next: value => x.subdivisionDataSourceService.reloadData$(new ForDate(value, null, value))
            .pipe(exErrorHandler(this.displayErrorsService), take(1)).subscribe(),
        });
        x.selection = new ArrayDataSourceSelection<ISubdivision, number>(x.subdivisionDataSourceService.dataSource);
      },
      x => {
        x.subdivisionDataSourceService.ngOnDestroy();
        x.selection.onDestroy();
      },
      (settings: PrintFormForDateSettingsWithSubdivisions) => this.printReportControllerService.printShortProxyOrder(settings)),
  };

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly printFormService: Api1PrintFormControllerService,
              private readonly dialogService: DialogService,
              private readonly printReportControllerService: Api1PrintReportControllerService,
              private readonly traceService: TracerServiceBase,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly subdivisionControllerService: Api1SubdivisionControllerService,
              private readonly dbChangedListener: DbChangedListener) {
  }

  @traceFunc()
  ngOnInit(): void {
    this.printFormList$ = defer(() =>
      this.loadingIndicatorService.addToObservable(
          'Загрузка форм для печати',
          this.printFormService.getPrintFormList$(this.printFormType),
        )
        .pipe(trace(this.traceService), takeUntil(this.unsubscribe$), take(1)),
    );
  }

  // Сформировать и показать диалог настроек печатной формы
  @traceFunc()
  public print({ dataItem: { innerKey, name } }): void {
    let item = this.printFormDict[innerKey];
    if (!item) throw new Error('Передан невалидный тип отчета');

    this._dialogRef = this.dialogService.open({
      title: `Настройка печати "${name}"`,
      content: item.component,
      maxWidth: '90%',
      width: '560px',
      maxHeight: '90%',
    });

    this.hendleDialogEvents<typeof item.component>(item, innerKey, name);
  }

  public getPrintButtonTitle(dataItem: PrintForm) {
    return `Печать формы "${dataItem.name}"`;
  }

  // обработка событий диалогового окна (отмена/печать)
  @traceFunc()
  private hendleDialogEvents<TComponent extends IPrintFormSettingsComponentBase>(item: HendleSettings<TComponent>, innerKey: string, name: string): void {
    const componentRef = this._dialogRef.content.instance as TComponent;

    if (item.initialComponentAction) {
      item.initialComponentAction(componentRef);
    }

    const unsubscribeRice = race(this.unsubscribe$, componentRef.cancel$, componentRef.print$)
      .pipe(delay(1));

    componentRef.cancel$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(() => {
      item.destroyComponentAction(componentRef);
      this._dialogRef.close();
      this.traceService.add('Нажата кнопка отмены');
    });

    componentRef.print$.pipe(take(1), takeUntil(unsubscribeRice)).subscribe(value => {
      item.destroyComponentAction(componentRef);
      this._dialogRef.close();
      this.traceService.add2('Нажата кнопка сохранения отчёта', { obj: value });
      value.printFormInnerKey = innerKey;
      item.requestMethod(value).subscribe({
        next: resp => {
          switch (innerKey){
            case 'milk_order':
              this.saveFile(resp, name, 'zip');
              break;
            default:
              this.saveFile(resp, name, 'xlsx');
          }
        },
        error: err => {
          this.displayErrorsService.handleError(err);
        },
      });
    });
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  /** Сохранить байтмассив в виде файла с определенным расширением */
  private saveFile(fileByteArr: Blob, name: string, fileExtention: string){
    FileSaver.saveAs(fileByteArr, `${name}_${moment().format('DD_MM_yyyy')}.${fileExtention}`);
  }
}

export class HendleSettings<TComponent> {
  constructor(public component: any,
              public initialComponentAction: (x: TComponent) => void,
              public destroyComponentAction: (x: TComponent) => void,
              public requestMethod: (params: any) => Observable<Blob>) {
  }

}
