import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, take } from 'rxjs';
import { DialogAction, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { KendoNotificationService } from '../../../../../../../../src/app/services/kendo-notification.service';
import { DisplayErrorsService } from '../../../../../../../../src/app/components/display-errors/services/display-errors.service';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from '../../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { LimitStaffUnitTreeListRateColumn, LimitStaffUnitTreeListItem, LimitStaffUnitsTreeListComponent, LimitStaffUnitTreeListItemType, LimitStaffUnitTreeListItemRate, LimitStaffUnitTreeListRateColumnInfo } from '../limit-staff-units-tree-list.component';
import { AddLimitStaffUnitComponent, AddLimitStaffUnitComponent_Data, AddLimitStaffUnitComponent_DataSourceService } from '../../add-limit-staff-unit/add-limit-staff-unit.component';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { takeUntil } from 'rxjs/operators';
import { trace } from '../../../../../../../../src/app/modules/trace/operators/trace';
import { DateHelper } from '../../../../../../../../src/app/helpers/dateHelper';
import { AddLimitStaffUnitDTO } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitsControl-controller.service';
import { AddLimitStaffUnitRateFormComponent } from '../../add-limit-staff-unit-rate/add-limit-staff-unit-rate-form.component';

@Injectable()
@traceClass('LimitStaffUnitsTreeListComponentService')
export class LimitStaffUnitsTreeListComponentService implements OnDestroy {

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly dialogService: DialogService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase) {
  }

  /** Преобразование информации об источниках финансирования, свободных и занятых ставках в массив */
  public getFinancingSourcesColumnsInfos(data: LimitStaffUnitTreeListItem[]): LimitStaffUnitTreeListRateColumn[] {
    if (!data?.length) return [];

    const allRates = data.reduce((all, u) => [...all, ...u.rates], []);

    const uniqFinancingSources = allRates
      .filter(
        (thing, i, arr) => arr.findIndex(t => t.financingSourceId === thing.financingSourceId) === i,
      );

    return uniqFinancingSources.map(fs =>
      new LimitStaffUnitTreeListRateColumn(
        fs.financingSourceId,
        fs.title,
        allRates.findIndex(r => fs.financingSourceId === r.financingSourceId && r.value > 0) >= 0,
      ),
    );
  }

  public getErrorColumnsInfos(items: LimitStaffUnitTreeListItem[]): LimitStaffUnitTreeListRateColumnInfo[] {
    return items.map((item) => {
        if (item.occupationTypeId) {
          const itemRates = items.filter(i => i.occupationTypeParentId === item.occupationTypeId).map(i => i.rates);

          return item.rates.map(rate => {
            const sum = itemRates
              .map(rates => rates
                .filter(r => r.financingSourceId === rate.financingSourceId)
                .map(rate => rate.value),
              )
              .map(rate => rate[0])
              .reduce((partialSum, a) => (partialSum || 0) + (a || 0), 0);

            return new LimitStaffUnitTreeListRateColumnInfo(item.id, rate.financingSourceId, rate.value < sum, sum !== 0 ? (rate.value ?? 0) - sum : 0);
          });
        }
      })
      .reduce((prev, curr) => ([...prev, ...curr || []]), []);
  }

  /** Функция получения строки отображения ставок */
  public getRatesDisplayValue(rates: Pick<LimitStaffUnitTreeListItemRate, 'value' | 'financingSourceId'>[], financingSourceId: number): string | number {
    const spr = rates.find(f => f.financingSourceId === financingSourceId);
    return (spr ? spr.value : 0) ?? '';
  }

  public getRateColumnInfo(columns: LimitStaffUnitTreeListRateColumnInfo[], limitStaffUnitId: number, financingSourceId: number): LimitStaffUnitTreeListRateColumnInfo {
    return columns.find(c => c.limitStaffUnitId === limitStaffUnitId && c.financingSourceId === financingSourceId);
  }

  /** Открывает диалоговое окно добавления лимитов штатных единиц */
  @traceFunc()
  showAddLimitStaffUnitDialog(component: LimitStaffUnitsTreeListComponent, type: LimitStaffUnitTreeListItemType, dataItem: AddLimitStaffUnitDTO): DialogRef {
    const dialogRef = this.dialogService.open({
      title: `Добавление лимитов штатных единиц`,

      content: AddLimitStaffUnitComponent,
      width: '90%',
      maxHeight: '90%',
    });

    const dataSource = new AddLimitStaffUnitComponent_DataSourceService();

    combineLatest([
      component.occupationTypeDataSourceService$,
      component.subdivisionDataSourceService$,
      component.financingSourceDataSourceService$,
    ]).pipe(
        takeUntil(this.streams$.unsubscribes),
        takeUntil(dialogRef.result),
      )
      .subscribe(([occupationTypes, subdivisions, financingSources]) => dataSource.setData(
        new AddLimitStaffUnitComponent_Data(
          occupationTypes,
          subdivisions,
          financingSources,
        ),
      ));

    const instance: AddLimitStaffUnitComponent = dialogRef.content.instance;

    instance.type = type;
    instance.dataItem = dataItem;
    instance.dataSource = dataSource;

    instance.onSaveEventEmitter.subscribe((entity: AddLimitStaffUnitDTO) =>
      component.dataSourceService.add$(entity)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
        next: data => {
          if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSourceService.paramsDataSource.data.startDate, component.dataSourceService.paramsDataSource.data.endDate)) {
            this.kendoNotificationService.showSuccess({ content: 'Лимит штатной единицы добавлена' });
          } else {
            this.kendoNotificationService.showSuccess({ content: 'Лимит штатной единицы добавлена, но она не входит в диапазон выборки дат отчёта, поэтому она не будет отображена.', hideAfter: 7000 });
          }
          dialogRef?.close();
        }, error: error => this.displayErrorsService.handleError(error),
      }),
    );

    instance.onCancelEventEmitter.subscribe(() => dialogRef?.close());

    return dialogRef;
  }

  /** Открывает диалоговое окно добавления лимитов штатной единицы */
  @traceFunc()
  public showAddLimitStaffUnitRateDialog(component: LimitStaffUnitsTreeListComponent, financingSourceId: number, dataItem: LimitStaffUnitTreeListItem): DialogRef {
    const dialogRef = this.dialogService.open({
      title: `Добавление лимита штатных единицы`,
      content: AddLimitStaffUnitRateFormComponent,
      width: '90%',
    });

    const instance = dialogRef.content.instance as AddLimitStaffUnitRateFormComponent;
    instance.dataItem = { limitStaffUnitId: dataItem.id, financingSourceId: financingSourceId };
    instance.isShowEditFinancingSource = false;
    instance.minDate = dataItem.startDate ?? null;
    instance.maxDate = dataItem.endDate ?? null;
    instance.rateStep = component.rateStep;

    instance.onSaveEventEmitter.subscribe(entity =>
      component.dataSourceService.addRate$(entity)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
        next: data => {
          if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSourceService.paramsDataSource.data.startDate, component.dataSourceService.paramsDataSource.data.endDate)) {
            this.kendoNotificationService.showSuccess({ content: 'Лимит штатной единицы добавлен' });
          } else {
            this.kendoNotificationService.showSuccess({ content: 'Лимит штатной единицы добавлен, но она не входит в диапазон выборки дат отчёта, поэтому она не будет отображена.', hideAfter: 7000 });
          }
          dialogRef?.close();
        }, error: error => this.displayErrorsService.handleError(error),
      }),
    );

    instance.onCancelEventEmitter.subscribe(() => dialogRef?.close());

    // Закрываем диалоговое окно если в датасорсе изменился текущий выбранный элемент, или что-то в самом элементе (например его изменил сигналр)
    component.selection.selectedItems2.change$.pipe(take(1), takeUntil(dialogRef.result)).subscribe(() => dialogRef.close());

    return dialogRef;
  }

  /** Показать диалог удаления лимита штатной единицы */
  @traceFunc()
  public showDeleteDialog(delete$: () => Observable<any>) {
    const dialogRef = this.dialogService.open({
      title: 'Подтвердите удаление',
      content: 'Вы уверены, что хотите удалить выбранные лимиты штатных единиц?\nДальнейшее восстановление будет не возможно!',
      actions: [
        { text: 'Нет' },
        { text: 'Да, удалить', themeColor: 'primary' },
      ],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        delete$()
          .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes))
          .subscribe({
            error: error => this.displayErrorsService.handleError(error),
          });
      }
      dialogRef?.close();
    });

    return dialogRef;
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
