import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { IDropDownItem } from 'src/app/classes/requestResults/iDropDownItem';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { CustomFormValidators } from 'src/app/validators/custom-form-validators.class';
import { LimitStaffUnitRate } from '../../../../../../../src/app/classes/domain/POCOs/stafflist/LimitStaffUnitRate';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { AddLimitStaffUnitRateDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitRate-controller.service';

@Component({
  selector: 'app-add-limit-staff-unit-rate-form',
  templateUrl: './add-limit-staff-unit-rate-form.component.html',
  styleUrls: ['./add-limit-staff-unit-rate-form.component.css'],
})
@traceClass('AddLimitStaffUnitRateFormComponent')
export class AddLimitStaffUnitRateFormComponent implements OnInit, OnDestroy {

  @Input('minDate') public minDate: Date;
  @Input('maxDate') public maxDate: Date;
  @Input('dataItem') public dataItem: any;

  @Input('financingSourcesDropDownItems') public financingSourcesDropDownItems: Array<IDropDownItem>;
  @Input('isShowEditFinancingSource') public isShowEditFinancingSource: boolean = true;

  @Input('rateStep') public rateStep: number;
  @Output('onSave') public onSaveEventEmitter = new EventEmitter<AddLimitStaffUnitRateDTO>();
  @Output('onCancel') public onCancelEventEmitter = new EventEmitter<boolean>();
  public form: FormGroup<PropertyWrapFormControlType<AddLimitStaffUnitRateDTO>>;
  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    if (!this.rateStep) {
      throw new Error('[rateStep] обязательно для заполнение в компоненте AddLimitStaffUnitRateFormComponent');
    }
    this.initForm(this.dataItem);
  }

  @traceFunc()
  public onClickSave() {
    this.onSaveEventEmitter.emit(this.form.value as AddLimitStaffUnitRateDTO);
  }

  @traceFunc()
  public onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  @traceFunc()
  private initForm(item: LimitStaffUnitRate) {
    this.form = new FormGroup({
      startDate: new FormControl<Date>(item.startDate ?? null, [CustomFormValidators.validateStartDateWithEndDate('endDate'), CustomFormValidators.requiredIf(() => !!this.minDate)]),
      endDate: new FormControl<Date>(item.endDate ?? null, [CustomFormValidators.validateEndDateWithStartDate('startDate')]),
      comment: new FormControl<string>(item.comment ?? '', Validators.maxLength(100)),
      value: new FormControl<number>(item.value ?? null, [Validators.required, Validators.min(0), Validators.max(10000), CustomFormValidators.multipleOf(this.rateStep)]),
      limitStaffUnitId: new FormControl<number>(item.limitStaffUnitId ?? null, [Validators.required]),
      financingSourceId: new FormControl<number>(item.financingSourceId ?? null, [Validators.required]),
    });

    this.form.valueChanges.subscribe(v => {
      this.tracerService.add2('Изменение формы', { obj: v });
    });
    this.form.markAllAsTouched();
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
