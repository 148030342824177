import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { GenderEnum } from '../../enums/gender-enum';
import { DateOnly } from '../../../Dates/date-onlys/date-only';
import {className} from "../../../../decorators/className/className.decorator";
import {classBackend} from "../../../../decorators/classBackend/classBackend.decorator";
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IEmployee extends IEntityVersioning {
  /** Фамилия */
  lastName: string;
  /** Имя */
  firstName: string;
  /** Отчество */
  patronymic: string;
  /** Табельный номер */
  code: string;
  /** Пол */
  gender: GenderEnum;
  /** Дата рождения */
  birthday: DateOnly;
  /** Статус участия работника в профсоюзе */
  profUnion: boolean;
}

@classBackend('Employee', 'stafflist')
@className('Employee')
/** Модель БД таблицы stafflist.Employees (Сотрудник) */
export class Employee implements IEmployee {
  constructor(public id: number,
              public ownerId: number | null,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public comment: string,
              public actionId: number,
              public orderId: number | null,
              public deletedFlag: boolean,
              public code: string,
              public firstName: string,
              public lastName: string,
              public patronymic: string,
              public gender: GenderEnum,
              public birthday: DateOnly,
              public profUnion: boolean,
              public timestamp: [],
  ) {
  }

  /** Получить ФИО */
  public static fullName(source: Pick<IEmployee, 'lastName' | 'firstName' | 'patronymic'>) {
    return `${source.lastName} ${source.firstName}${source.patronymic ? ` ${source.patronymic}` : ''}`;
  }

  private static _fullComparer: ObjComparer<Omit<IEmployee, 'timestamp'>>;
  /** Сравнить по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<Omit<IEmployee, 'timestamp'>>({
        id: true,
        ownerId: true,
        startDate: ObjComparer.dateComparer,
        endDate: ObjComparer.dateComparer,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        comment: true,
        actionId: true,
        orderId: true,
        deletedFlag: true,
        code: true,
        firstName: true,
        lastName: true,
        patronymic: true,
        gender: true,
        birthday: true,
        profUnion: true,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IEmployee, 'timestamp' | 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнить по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}
