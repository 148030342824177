export class InnData {
  constructor(
    public inn: string
  ) {}

  static getAsString(data: InnData): string {
    return data?.inn;
  }
}

