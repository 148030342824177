import { IAutoCapitalize, IAutoTrim, IFieldControl, IMinMaxLength, IPlaceholder } from './interfaces/common';

export class TextBoxFieldControl implements IFieldControl<string> {
  public readonly name = 'TextBoxFieldControl';
  public getValueFunc = (stringValue: string) => stringValue;
  constructor (
    public settings?: (() => TextBoxFieldControl_Settings) | TextBoxFieldControl_Settings
  ) {}
}

export interface TextBoxFieldControl_Settings extends IMinMaxLength, IAutoTrim, IAutoCapitalize, IPlaceholder {
}
