import {ObservableInput, retry, RetryConfig, tap, throwError, timer} from "rxjs";
import {RetryStorage} from "../../classes/retry-storage.class";

/** Конфигуратор повторов для пайпа {@link retry} */
export class CustomRetryConfig implements RetryConfig{
  /** Максимальное время повторов */
  private readonly timeout: number;

  /**
   * Конструктор
   * @param retries Настройка повторов
   * @param timeout количество миллисекунд после которых происходит прекращение повторов и возвращается ошибка.
   * @param onRetry функция будет выполняться перед каждым повтором
   */
  constructor(private readonly retries: Pick<RetryStorage, 'getFirst'>,
              timeout: number | undefined,
              private readonly onRetry: (error: any, retryCount: number) => void = undefined) {
    if(typeof timeout === 'number'){
      this.timeout = Date.now() + timeout;
    }
  }

  /** @inheritDoc */
  public delay: number | ((error: any, retryCount: number) => ObservableInput<any>) = (error, retryCount) => {
    const delay = this.retries.getFirst();

    if (delay === undefined) {
      return throwError(() => new Error('Превышено максимальное количество повторов'))
    }

    if(this.timeout !== undefined && Date.now() + delay > this.timeout){
      return throwError(() => new Error('Превышено максимальное время выполнения повторов'))
    }

    return timer(delay)
      .pipe(
        tap(() => {
          if(this.onRetry){
            this.onRetry(error, retryCount);
          }
        })
      );
  };
}
