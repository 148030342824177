import { Injectable } from '@angular/core';
import { DialogAction, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { filter, ReplaySubject, take, takeUntil } from 'rxjs';
import { DisplayErrorsService } from 'src/app/components/display-errors/services/display-errors.service';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TraceParamEnum } from 'src/app/modules/trace/decorators/classes/traceSetting.interface';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { traceParam } from 'src/app/modules/trace/decorators/param.decorator';
import { trace } from 'src/app/modules/trace/operators/trace';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { KendoNotificationService } from 'src/app/services/kendo-notification.service';
import { EmployeeItem } from 'src/app/services/webApi/webApi1/controllers/api1-employers-grid-control-controller.service';
import { EditPassportDataDialogService } from '../../../controls/extended-data-controls/edit-passport-data/dialog/edit-passport-data-dialog.service';
import { AddEmployeeFormComponent } from '../../add/add-employee-form.component';
import { EmployeesGridComponent } from '../employees-grid.component';

@Injectable()
@traceClass('EmployeesGridComponentService')
export class EmployeesGridComponentService {

  private addDialogRef: DialogRef;
  private removeDialogRef: DialogRef;
  private readonly streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private traceService: TracerServiceBase,
              private displayErrorsService: DisplayErrorsService,
              private dialogService: DialogService,
              private kendoNotificationService: KendoNotificationService,
              private editPassportDataDialogService: EditPassportDataDialogService) {
  }

  /** Происходит в момент сохранения Адреса места жительства */
  @traceFunc()
  public onAddressDataSaved(dataItem: EmployeeItem, value: string, component: EmployeesGridComponent) {
    const currentAddress = dataItem.address;
    if (!currentAddress?.id) {
      component.dataSourceService.addAddressData$(value, dataItem.id)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    } else {
      component.dataSourceService.saveAddressData$(value, currentAddress.id, currentAddress.timestamp)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    }
  }

  /** Происходит в момент сохранения ИНН */
  @traceFunc()
  public onInnDataSaved(dataItem: EmployeeItem, value: string, component: EmployeesGridComponent) {
    const currentInn = dataItem.inn;
    if (!currentInn?.id) {
      component.dataSourceService.addInnData$(value, dataItem.id)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    } else {
      component.dataSourceService.saveInnData$(value, currentInn.id, currentInn.timestamp)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    }
  }

  /** Происходит в момент сохранения СНИЛС */
  @traceFunc()
  public onSnilsDataSaved(dataItem: EmployeeItem, value: string, component: EmployeesGridComponent) {
    const currentSnils = dataItem.snils;
    if (!currentSnils?.id) {
      component.dataSourceService.addSnilsData$(value, dataItem.id)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    } else {
      component.dataSourceService.saveSnilsData$(value, currentSnils.id, currentSnils.timestamp)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    }
  }

  /** Происходит в момент сохранения Телефона */
  @traceFunc()
  public onPhoneDataSaved(dataItem: EmployeeItem, value: string, component: EmployeesGridComponent) {
    const currentPhone = dataItem.phone;
    if (!currentPhone?.id) {
      component.dataSourceService.addPhoneData$(value, dataItem.id)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    } else {
      component.dataSourceService.savePhoneData$(value, currentPhone.id, currentPhone.timestamp)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          error: error => this.displayErrorsService.handleError(error),
        });
    }
  }

  @traceFunc({ traceParamType: TraceParamEnum.traceByDecorators })
  public startEditPassportData(@traceParam({ maxLength: 500 }) dataItem: EmployeeItem, component: EmployeesGridComponent) {
    const passportData = dataItem.passports ? dataItem.passports[0] : null;
    if (!passportData?.id) {
      this.editPassportDataDialogService.showAdd().result$.pipe(filter(f => f.type === 'ok')).subscribe(result => {
        component.dataSourceService.addPassportData$(result.result.data, dataItem.id)
          .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
          .subscribe({
            error: error => this.displayErrorsService.handleError(error),
          });
      });
    } else {
      this.editPassportDataDialogService.showEdit(passportData.data).result$.pipe(filter(f => f.type === 'ok')).subscribe(result => {
        component.dataSourceService.savePassportData$(result.result.data, passportData.id, passportData.timestamp)
          .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
          .subscribe({
            error: error => this.displayErrorsService.handleError(error),
          });
      });
    }
  }

  @traceFunc()
  public showAddDialog(component: EmployeesGridComponent) {
    this.addDialogRef?.close();

    this.addDialogRef = this.dialogService.open({
      title: `Добавление сотрудника`,

      // Show component
      content: AddEmployeeFormComponent,
      width: '90%',
    });

    const addEmployerFormComponent = this.addDialogRef.content.instance;
    addEmployerFormComponent.onSaveEventEmitter.subscribe(entity =>
      component.dataSourceService.addEmployee$(entity)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          next: () => {
            this.kendoNotificationService.showSuccess({ content: 'Сотрудник добавлен' });
            this.addDialogRef?.close();
          },
          error: error => this.displayErrorsService.handleError(error),
        }),
    );
    addEmployerFormComponent.onCancelEventEmitter.subscribe(() => {
      this.addDialogRef?.close();
    });
  }

  /** Диалог подтверждения удаления ЦФО */
  @traceFunc()
  public showDeleteDialog(component: EmployeesGridComponent) {
    this.removeDialogRef?.close();

    this.removeDialogRef = this.dialogService.open({
      title: 'Подтвердите удаление',
      content: 'Вы уверены, что хотите удалить выбранного Сотрудника?\nДальнейшее восстановление будет не возможно!',
      actions: [
        { text: 'Нет' },
        { text: 'Да, удалить', themeColor: 'primary' },
      ],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    this.removeDialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        component.dataSourceService.deleteEmployee$(component.selection.selectedItems.data[0])
          .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
          .subscribe({
            error: error => {
              this.displayErrorsService.handleError(error);
            },
          });
      }
      this.removeDialogRef?.close();
    });
  }

  public ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
