import {Injectable} from "@angular/core";
import {WebApi1Service} from "../web-api1.service";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {IWorkMode} from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import {IWorkModeType} from "src/app/classes/domain/POCOs/stafflist/WorkModeType";

@Injectable({
  providedIn: "root"
})
export class Api1WorkModesDirectoryGridControlControllerService{

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

 /**
   * Получить WorkModes
   */
  public getForDate$( date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<WorkModesDirectoryGridItem>>{
    return this.httpClient
      .post<GetWorkModesForDateResponseDTO>(this.webApi1Service.controllers.workModesDirectoryGridControl.actions.getForDate.toString(),
      {
        OwnerIds: ownerIds,
        StartDate: startDate,
        EndDate: endDate,
        Date: date,
      }).pipe(map(data => data.workModes.map(workMode => {

        const workModeType = data.workModeTypes.find(w=> w.id === workMode.workModeTypeId);

        return new WorkModesDirectoryGridItem(
          workMode.id,
          workMode.startDate,
          workMode.endDate,
          workMode.comment,
          workMode.name,
          workMode.workModeTypeId,
          workModeType?.name,
          workMode.arrangedForWeekFlag,
          workMode.workingDays,
          workMode.workdayHourDuration,
          workMode.preHolidayHourDuration,
          workMode.timestamp
        );
      })));
  }
}

interface GetWorkModesForDateResponseDTO
{
  workModes: Array<IWorkModeDTO>;
  workModeTypes: Array<IWorkModeTypeDTO>;
}

interface IWorkModeTypeDTO extends Pick<IWorkModeType, 'id' | 'name' | 'description'>
{
  id: number;
  name: string;
  description: string;
}

interface IWorkModeDTO extends Pick<IWorkMode, 'id' | 'startDate' | 'endDate' | 'comment' | 'timestamp' | 'name'
  | 'workModeTypeId' | 'arrangedForWeekFlag' | 'workingDays' | 'preHolidayHourDuration'>
{
  workdayHourDuration: number | null;
}


export class WorkModesDirectoryGridItem implements Pick<IWorkMode, 'id' | 'startDate' | 'endDate' | 'comment' | 'name'
  | 'workModeTypeId' | 'arrangedForWeekFlag' | 'workingDays' | 'workdayHourDuration' | 'preHolidayHourDuration' | 'timestamp'> {

  constructor(
    public id: number,
    public startDate: Date | null,
    public endDate: Date | null,
    public comment: string,
    public name: string,
    public workModeTypeId: number | null,
    public workModeTypeName: string | null,
    public arrangedForWeekFlag: boolean | null,
    public workingDays: number | null,
    public workdayHourDuration: number | null,
    public preHolidayHourDuration: number | null,
    public timestamp: [],
  ) {}
}
