import { Injectable, OnDestroy } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ReplaySubject, takeUntil, take, combineLatest, filter } from 'rxjs';
import { DisplayErrorsService } from 'src/app/components/display-errors/services/display-errors.service';
import { DateHelper } from 'src/app/helpers/dateHelper';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { trace } from 'src/app/modules/trace/operators/trace';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { KendoNotificationService } from 'src/app/services/kendo-notification.service';
import { OccupationsDirectoryGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-occupations-directory-grid-control-controller.service';
import { AddOccupationFormComponent, AddOccupationFormComponent_Data, AddOccupationFormComponent_DataSourceService } from '../../add/add-occupation-form.component';
import { EditOccupationsCodesFormComponent, EditOccupationsCodesFormComponent_Data, EditOccupationsCodesFormComponent_DataSourceService } from '../../edit-occupations-codes/edit-occupations-codes.component';
import { OccupationsDirectoryGridComponent } from '../occupations-directory-grid.component';

@traceClass('OccupationsDirectoryGridComponentService')
@Injectable()
export class OccupationsDirectoryGridComponentService implements OnDestroy {

  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly tracerService: TracerServiceBase,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly dialogService: DialogService,
              private readonly kendoNotificationService: KendoNotificationService) {
  }

  /** Открывает диалог добавления должности */
  @traceFunc()
  public showAddDialog(component: OccupationsDirectoryGridComponent): DialogRef {
    const dialogRef = this.dialogService.open({
      title: `Добавление должности`,
      content: AddOccupationFormComponent,
      width: '90%',
      maxHeight: '95%',
    });

    const dataSource = new AddOccupationFormComponent_DataSourceService();

    combineLatest([
      component.codeDataSourceService$,
      component.occupationTypeDataSourceService$,
      component.dataSourceService.getOccupationCodeLength$(),
    ])
      .pipe(
        takeUntil(this.streams$.unsubscribes),
        takeUntil(dialogRef.result),
      )
      .subscribe(
        ([codes, occupationTypes, occupationCodeLength]) => dataSource.setData(
          new AddOccupationFormComponent_Data(codes, occupationTypes, occupationCodeLength),
        ),
      );

    const instance = dialogRef.content.instance as AddOccupationFormComponent;
    instance.dataSource = dataSource;

    instance.onSaveEventEmitter.subscribe(entity =>
      component.dataSourceService.addOccupation$(entity)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
        next: data => {
          if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSourceService.paramsDataSource.data.startDate, component.dataSourceService.paramsDataSource.data.endDate)) {
            this.kendoNotificationService.showSuccess({ content: 'Должность добавлена' });
          } else {
            this.kendoNotificationService.showSuccess({ content: 'Должность добавлена, но она не входит в диапазон выборки дат отчёта, поэтому она не будет отображена.', hideAfter: 7000 });
          }
          dialogRef?.close();
        },
        error: error => this.displayErrorsService.handleError(error),
      }),
    );

    instance.onCancelEventEmitter.subscribe(() => dialogRef?.close());

    return dialogRef;
  }

  /** Открывает диалог редактирование кодов табельного учёта */
  @traceFunc()
  public showEditCodesDialog(component: OccupationsDirectoryGridComponent, entity: OccupationsDirectoryGridItem): DialogRef {
    const dialogRef = this.dialogService.open({
      title: `Редактирование кодов табельного учёта`,
      content: EditOccupationsCodesFormComponent,
      width: '90%',
    });

    const dataSource = new EditOccupationsCodesFormComponent_DataSourceService();

    component.codeDataSourceService$.pipe(
        takeUntil(this.streams$.unsubscribes),
        takeUntil(dialogRef.result),
      )
      .subscribe(
        (codes) => dataSource.setData(new EditOccupationsCodesFormComponent_Data(codes)),
      );

    const instance = dialogRef.content.instance as EditOccupationsCodesFormComponent;
    instance.codesIdsArray = entity.codes.map(c => c.id);
    instance.dataSource = dataSource;

    instance.onSaveEventEmitter.subscribe(codesIdsArray =>
      component.dataSourceService.updateOccupationCodes$(entity.id, codesIdsArray)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          next: () => dialogRef?.close(),
          error: error => this.displayErrorsService.handleError(error),
        }),
    );

    instance.onCancelEventEmitter.subscribe(() => dialogRef?.close());

    component.dataSourceService.dataSource.observe$()
      .pipe(
        filter(d => d.some(s => s.currentOrOrigin.id === entity.id)),
        takeUntil(dialogRef.result),
        takeUntil(this.streams$.unsubscribes),
      )
      .subscribe(() => dialogRef?.close());

    return dialogRef;
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
