import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OccupationType } from 'src/app/classes/domain/POCOs/stafflist/OccupationType';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { notVersioningOnlyDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/not-versioning-only-directory-service-provider';
import { Observable } from 'rxjs';
import { OccupationTypesDirectoryGridComponentService } from './services/occupation-types-directory-grid-component.service';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { OccupationTypesDirectoryGridComponentDataService } from './services/occupation-types-directory-grid-component-data.service';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { SelectionChangeEvent } from '@progress/kendo-angular-treelist';

@Component({
  selector: 'app-occupation-types-directory-grid',
  templateUrl: './occupation-types-directory-grid.component.html',
  styleUrls: ['./occupation-types-directory-grid.component.css'],
  providers: [
    notVersioningOnlyDirectoryServiceProvider,
    OccupationTypesDirectoryGridComponentService,
    OccupationTypesDirectoryGridComponentDataService
  ]
})
@traceClass('OccupationTypesDirectoryGridComponent')
export class OccupationTypesDirectoryGridComponent implements OnInit {

  /** Шаблон редактирования родителя */
  @ViewChild('editParentIdTemplateRef') public editParentIdTemplateRef: TemplateRef<any>;

  public data$: Observable<OccupationType[]>
  public currentSelectedItem: OccupationType
  public parents: OccupationType[]
  public editParentIdValue: number
  public canDelete: boolean

  constructor(private tracerService: TracerServiceBase,
              private service: OccupationTypesDirectoryGridComponentService) {
    service.component = this
  }

  @traceFunc()
  ngOnInit() {
    this.service.init();
  }

  @traceFunc()
  public getDataItemForEditParentId() {
    return this.service.getDataItemForEditParentId()
  }

  /** Вернет список отключеных элементов для редактирования родителя */
  @traceFunc()
  public parentsDisabled(parent: OccupationType) {
    return this.service.parentsDisabled(parent)
  }

  /** Начало редактирования иерархии подразделений */
  @traceFunc()
  public startEditParentId() {
    this.service.startEditParentId()
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickAdd() {
    this.service.onClickAdd();
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickRemove() {
    this.service.onClickRemove()
  }

  /** Событие происходит при двойном клике на ячейку */
  @traceFunc()
  public onDblCellClick(e: CellClickEvent) {
    this.service.onDblCellClick(e);
  }

  /** Событие при изменении выбранного элемента */
  @traceFunc()
  public onSelectionChanged(e: SelectionChangeEvent) {
    this.service.onSelectionChanged(e);
  }
}
