import { map, Observable, switchMap } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { ISubdivision, Subdivision } from "src/app/classes/domain/POCOs/stafflist/Subdivision";
import { IForDate } from "src/app/classes/for-date";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";

export class SubdivisionDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<SubdivisionDataSourceService_Params, ISubdivision>{

  readonly paramsDataSource = new DataSource<SubdivisionDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<ISubdivision>();

  constructor(private readonly parentDataSourceService: ISubdivisionDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Subdivision)
      .pipe(
        map(value => value.data),
        map(value => new DataHasOwnerStateBuilder(value, this.dataSource.data2, x => x.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.ownerId)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: SubdivisionDataSourceService_Params): Observable<ISubdivision[]> {
    return this._reloadFromRemoteByIds$(params, params.subdivisionIds);
  }

  protected _reloadFromRemoteByIds$(params: SubdivisionDataSourceService_Params, targets: ISubdivision["id"][]): Observable<ISubdivision[]> {
    return this.parentDataSourceService.getSubdivisionsForDate$(params, targets);
  }
}

export interface SubdivisionDataSourceService_Params extends IForDate{
  subdivisionIds: number[];
}

export interface ISubdivisionDataSourceService_ParentDataSourceService {
  getSubdivisionsForDate$(forDate: IForDate, subdivisionIds: number[]): Observable<ISubdivision[]>;
}
