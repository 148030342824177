/** Класс элемента буфера */
export class TraceStorageItem{
  constructor(public readonly level: number,
              public readonly message: string) {
    if(level < 0){
      throw new Error('level не может быть отрицательным')
    }

    if(!level && level !== 0){
      throw new Error('level не может быть null или undefined')
    }

    if(message === null || message === undefined){
      throw new Error('message is null or undefined')
    }
  }
}

/** Базовый класс сервиса хранения трассировки */
export abstract class TraceStorageServiceBase{

  /** Добавить сообщение в хранилище. Добпускается передача null - новая строка */
  public abstract add(value: TraceStorageItem): void;

  /** Очистить хранилище */
  public abstract clear(): void;

  /** Получить данные хранилище в виде строки */
  public abstract buildAsString(): string;
}
