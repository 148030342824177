export class RandomHelper {

  /**
   * Генерация случайного целого числа
   * @param min минимальное число
   * @param max максимальное число
   * @returns
   */
  public static getInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
}
