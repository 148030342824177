import { map, Observable, switchMap } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { IWorkMode, WorkMode } from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import { IForDate } from "src/app/classes/for-date";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";


export class WorkModeDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<WorkModeDataSourceService_Params, IWorkMode>{

  readonly paramsDataSource = new DataSource<WorkModeDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<IWorkMode>();

  constructor(private readonly parentDataSourceService: IWorkModeDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(WorkMode)
      .pipe(
        map(value => value.data),
        map(value => new DataHasOwnerStateBuilder(value, this.dataSource.data2, x => x.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.ownerId)),
        map(value => this.reloadFromSignalR$(value))
      );
  }

  protected _reloadData$(params: WorkModeDataSourceService_Params): Observable<IWorkMode[]> {
    return this._reloadFromRemoteByIds$(params, params.workModesIds);
  }

  protected _reloadFromRemoteByIds$(params: WorkModeDataSourceService_Params, targets: IWorkMode["id"][]): Observable<IWorkMode[]> {
    return this.parentDataSourceService.getWorkModesForDate$(params, targets);
  }
}

export interface WorkModeDataSourceService_Params extends IForDate{
  workModesIds: number[];
}

export interface IWorkModeDataSourceService_ParentDataSourceService {
  getWorkModesForDate$(forDate: IForDate, workModesIds: number[]): Observable<IWorkMode[]>;
}
