import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {DisplayErrorsService, IShowErrorsSettings} from "../components/display-errors/services/display-errors.service";

/** Оператор обработки ошибок */
export function exErrorHandler<O>(service: DisplayErrorsService, settings: IShowErrorsSettings = undefined): (source$: Observable<O>) => Observable<O> {
  return source$ => {
    return source$.pipe(
      catchError((err, caught) => {
        service.handleError(err, settings);
        return throwError(() => err);
      })
    );
  }
}
