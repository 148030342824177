import { Injectable, OnDestroy } from "@angular/core";
import { DialogAction, DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { CellClickEvent, SelectionEvent } from "@progress/kendo-angular-grid";
import { ReplaySubject, take, takeUntil } from "rxjs";
import { ArrayDataSourceIEntityId } from "src/app/classes/array-data-sources/data-source";
import { IReportPeriod } from "src/app/classes/domain/POCOs/stafflist/ReportPeriod";
import { ResponseObjError } from "src/app/classes/requestResults/responseObjError";
import { TextBoxFieldControl } from "src/app/components/directory-edit/services/classes/field-controls/text-box-field-control";
import { NotVersioningField } from "src/app/components/directory-edit/services/classes/fields/not-versioning-field";
import { DirectoryEditService } from "src/app/components/directory-edit/services/directory-edit.service";
import { DirectoryEditRef } from "src/app/components/directory-edit/services/ref-services/directory-edit-ref";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { CellClickExpandedEvent } from "src/app/services/directives/grid-treelist-expanded-directive.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { AddReportPeriodFormComponent } from "../../add/add-report-period-form.component";
import { IReportPeriodsDirectoryGridComponent } from "../i-report-periods-directory-grid.component";
import { ReportPeriodsDirectoryGridComponentDataService } from "./report-periods-directory-grid-component-data.service";

@traceClass('ReportPeriodsDirectoryGridComponentService')
@Injectable()
export class ReportPeriodsDirectoryGridComponentService extends ComponentServiceBase<IReportPeriodsDirectoryGridComponent> implements OnDestroy {

  private directoryEditServiceRef: DirectoryEditRef;
  private directoryData = new ArrayDataSourceIEntityId<IReportPeriod>();
  private _addDialogRef: DialogRef;
  private _removeDialogRef: DialogRef;

  /** Настройки полей справочника */
  private fields = [
    new NotVersioningField("name", { isAllowNull: false, title: "Наименование периода контроля" }, new TextBoxFieldControl({ minLength: 2, maxLength: 255, autoTrimValue: true })),
    new NotVersioningField("comment", { isAllowNull: true, title: "Комментарий" }, new TextBoxFieldControl({ minLength: 2, maxLength: 100, autoTrimValue: true })),
  ];

  private streams$: { unsubscribes: ReplaySubject<any> } = { unsubscribes: new ReplaySubject<any>(1) }

  constructor(private readonly directoryEditService: DirectoryEditService,
              private readonly traceService: TracerServiceBase,
              private readonly dialogService: DialogService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly dataService: ReportPeriodsDirectoryGridComponentDataService) {
    super();
  }

  /** Инициализация сервиса (обычно вызывается в OnInit) */
  @traceFunc()
  public init() {
    this.component.data$ = this.directoryData.data$
    this.reloadData()
    this.initDirectoryEditService()
  }

  /** Инициализация сервиса редактирования */
  @traceFunc()
  private initDirectoryEditService() {
    this.directoryEditServiceRef = this.directoryEditService.initNotVersioningOnly({
      saveNoVersioning$: (entity) => this.dataService.save$(entity),
    }, this.fields);
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickAdd() {
    this.showAddDialog();
  }

  /** Событие при изменении выбранного элемента */
  @traceFunc()
  public onSelectionChanged(e: SelectionEvent) {
    const selectedItem = e.selectedRows[0]?.dataItem as IReportPeriod;
    this.component.currentSelectedItem = selectedItem;
  }

  /** Событие происходит при двойном клике на ячейку */
  @traceFunc()
  public onDblCellClick(e: CellClickExpandedEvent<CellClickEvent>) {
    if (!e.originalEvent.isEdited) {
      this.directoryEditServiceRef.edit(e.originalEvent.column.field, e.originalEvent.dataItem, e.originalEvent.originalEvent.target);
    }
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickRemove() {
    this.showRemoveDialog();
  }

  /** Перезагрузка данных грида */
  @traceFunc()
  private reloadData() {
    this.dataService.get$()
      .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
        next: value => this.directoryData.setData(value),
        error: error => this.displayErrorsService.handleError(error)
      });
  }

  @traceFunc()
  private showRemoveDialog() {
    this._removeDialogRef?.close()

    this._removeDialogRef = this.dialogService.open({
      title: 'Подтвердите удаление',
      content: 'Вы уверены, что хотите удалить выбранный период контроля?\nДальнейшее восстановление будет не возможно!',
      actions: [
        { text: 'Нет' },
        { text: 'Да, удалить', themeColor: 'primary' }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    this._removeDialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        this.dataService.delete$(this.component.currentSelectedItem.id)
          .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
            next: value => {
              this.directoryData.deleteItemByIds(true, this.component.currentSelectedItem.id)
              this.component.currentSelectedItem = null
            },
            error: error => {
              if (ResponseObjError.checkTypeReturnCode(error) === "b7c55cb7-e215-4bfc-a81f-70602217ea43") {
                this.displayErrorsService.showSimpleError('Запрещено удаление периода контроля, который используется');
              } else {
                this.displayErrorsService.handleError(error);
              }
            }
          });
      }
      this._removeDialogRef?.close()
    });
  }

  @traceFunc()
  private showAddDialog() {
    this._addDialogRef?.close()

    this._addDialogRef = this.dialogService.open({
      title: `Добавление периода контроля`,

      // Show component
      content: AddReportPeriodFormComponent,
      width: "90%",
    });
    const addFormComponent = this._addDialogRef.content.instance as AddReportPeriodFormComponent;
    addFormComponent.onSaveEventEmitter.subscribe(entity =>
      this.dataService.add$(entity)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
          next: data => {
            this.kendoNotificationService.showSuccess({ content: "Период контроля добавлен" })
            this.directoryData.addItems(true, data);
            this._addDialogRef?.close()
          },
          error: error => this.displayErrorsService.handleError(error)
        })
    )
    addFormComponent.onCancelEventEmitter.subscribe(() => { this._addDialogRef?.close() })
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.directoryEditServiceRef.close()
    this.streams$.unsubscribes.next(null)
    this.streams$.unsubscribes.complete()
    this.directoryData.onDestroy()
  }
}
