import * as moment from "moment/moment";


/** Сообщение ошибки НЕ валидной строки */
const STRING_NOT_VALID_MESSAGE = 'Строка не валидна';
/** Ключ для проверки типа */
const IS_DATEONLY_KEY = '$#i_d_o#$';
/** Формат по умолчанию для вывода в строку */
const DEFAULT_TO_STRING_FORMAT = 'DD.MM.YYYY';
/** Формат по умолчанию для парсинга строки */
const DEFAULT_PARSE_FORMAT = 'YYYY-MM-DD';
/**
 * Строгий синтаксический анализ требует, чтобы формат и вводимые данные точно совпадали, включая разделители. Строгий синтаксический анализ часто является лучшим вариантом синтаксического анализа.
 */
const STRICT_INFO = '';


/** Тип значения */
type ValueType = {
  /** Год */
  year: number,
  /** Месяц. 0...11 */
  month: number,
  /** Месяц. 1...12 */
  m: number,
  /** День. 1...31 */
  day: number
}

/** Тип для параметра конструктора */
type ConstructorParameterType = Partial<ValueType> & {
  /**
   * Поведение если значение поля {@link ValueType} отсутствует.
   * current - возьмет значение из текущего времени.
   * default - установит по умолчанию.
   * По умолчанию значение 'current'
   */
  behavior?: 'current' | 'default'
};

/**
 * Класс работы с датой.
 * Под капотом работает moment.
 */
export class DateOnly implements Pick<Date, 'getFullYear' | 'getMonth' | 'getDate'>{
  /** Значение по умолчанию для года */
  public static readonly defaultYear = 0;
  /** Значение по умолчанию для месяца */
  public static readonly defaultMonth = 0;
  /** Значение по умолчанию для дня */
  public static readonly defaultDay = 1;
  /** Значение по умолчанию для часа */
  public static readonly defaultHour = 0;
  /** Значение по умолчанию для минут */
  public static readonly defaultMinute = 0;
  /** Значение по умолчанию для секунд */
  public static readonly defaultSecond = 0;
  /** Значение по умолчанию для миллисекунд */
  public static readonly defaultMilliseconds = 0;

  /** Ключ для проверки типа */
  private get isDateOnly(){
    return IS_DATEONLY_KEY;
  }

  private _moment: moment.Moment;

  private _date: Date;
  /**
   * Получить как {@link Date}.
   * Часы - {@link DateOnly.defaultHour}.
   * Минуты - {@link DateOnly.defaultMinute}.
   * Секунды - {@link DateOnly.defaultSecond}.
   * Миллисекунды - {@link DateOnly.defaultMilliseconds}.
   */
  public get date(){
    return this._date;
  }

  /**
   * Конструктор
   * @param value значение для инициализации. Если !value, текущая дата.
   */
  constructor(value: ConstructorParameterType = undefined) {
    value = !!value ? value : {};

    if(value.m){
      value.month = value.m - 1;
    }

    if(value.behavior === 'default'){
      value.year = value.year === undefined ? DateOnly.defaultYear : value.year;
      value.month = value.month === undefined ? DateOnly.defaultMonth : value.month;
      value.day = value.day === undefined ? DateOnly.defaultDay : value.day;
    } else {
      const currentM = moment();

      value.year = value.year === undefined ? currentM.year() : value.year;
      value.month = value.month === undefined ? currentM.month() : value.month;
      value.day = value.day === undefined ? currentM.date() : value.day;
    }

    this.init(moment({
      year: value.year,
      month: value.month,
      day: value.day,
      hour: DateOnly.defaultHour,
      minutes: DateOnly.defaultMinute,
      seconds: DateOnly.defaultSecond,
      milliseconds: DateOnly.defaultMilliseconds
    }));
  }

  /** Получить год */
  public getFullYear(): number {
    return this._moment.year();
  }

  /** Получить месяц в формате 0...11 */
  public getMonth(): number {
    return this._moment.month();
  }

  /** Получить месяц в формате 1...12 */
  public getM(): number {
    return this._moment.month() + 1;
  }

  public getDate(): number {
    return this._moment.date();
  }

  /** Копировать */
  public copy(){
    return DateOnly.createFromMomentInternal(this._moment);
  }

  /**
   * Сформировать строку.
   * @param format формат поддерживающий в moment
   */
  public format(format: string = DEFAULT_TO_STRING_FORMAT): string{
    return this._moment.format(format);
  }

  /** Переопределение. Вывод в формате {@link DEFAULT_TO_STRING_FORMAT} */
  public toString(){
    return this.format();
  }

  /** Переопределение. Вывод в формате {@link DEFAULT_TO_STRING_FORMAT} */
  public toJSON(){
    return this.format();
  }

  /** Сравнить */
  public compare(dateOnly: DateOnly): boolean{
    return DateOnly.compare(this, dateOnly);
  }

  /** Сравнить с датой из {@link Date} */
  public compareDate(date: Date): boolean{
    return DateOnly.compareDate(this, date);
  }

  /** Инициализация */
  private init(moment: moment.Moment){
    if(!moment.isValid()){
      throw new Error('данные даты не валидны');
    }

    this._moment = moment;
    this._date = moment.toDate();
  }

  /** Внутренний метод создания экземпляра из moment. */
  private static createFromMomentInternal(m: moment.Moment): DateOnly{
    return new DateOnly({
      year: m.year(),
      month: m.month(),
      day: m.date(),
    })
  }

  /**
   * Попробовать получить {@link DateOnly} из строки.
   * Если строка не валидна вернет undefined
   * @param value строка содержащая дату
   * @param format формат или возможные форматы
   * @param strict строго. см.{@link STRICT_INFO}
   */
  public static tryParse(value: string, format: string | string[], strict: boolean = true): DateOnly | undefined{
    const instance = moment(value, format, strict);

    if(!instance.isValid()){
      return undefined;
    }

    return this.createFromMomentInternal(instance);
  }

  /**
   * Получить {@link DateOnly} из строки.
   * Если строка не валидна бросит ошибку содержащую сообщение {@link STRING_NOT_VALID_MESSAGE}
   * @param value строка содержащая дату
   * @param format формат или возможные форматы
   * @param strict строго. см.{@link STRICT_INFO}
   */
  public static parse(value: string, format: string | string[], strict: boolean = true): DateOnly{
    const instance = this.tryParse(value, format, strict);
    if(!instance){
      throw new Error(STRING_NOT_VALID_MESSAGE)
    }

    return instance;
  }

  /** Создать экземпляр из moment */
  public static createFromMoment(m: moment.Moment): DateOnly{
    if(!m){
      return undefined;
    }

    if(!m.isValid()){
      throw new Error('переданный момент НЕ валиден')
    }

    return this.createFromMomentInternal(m);
  }

  /** Создать экземпляр из {@link Date} */
  public static createFromDate(date: Date): DateOnly{
    if(!date){
      return undefined;
    }

    return this.createFromMomentInternal(moment(date));
  }

  /** Создать экземпляр из числа {@link Date} */
  public static createFromDateNumber(value: number): DateOnly{
    if(!value){
      return undefined;
    }

    return this.createFromMomentInternal(moment(value));
  }

  /** Вернет, является ли переданный объект экземпляром {@link DateOnly} */
  public static isDateOnly(obj: any): obj is DateOnly{
    return (obj as DateOnly)?.isDateOnly === IS_DATEONLY_KEY;
  }

  /** Сравнить два объекта {@link DateOnly} */
  public static compare(first: DateOnly, second: DateOnly): boolean{
    if(!first || !second){
      return first === second;
    }

    return first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate();
  }

  /** Сравнить дату с датой {@link Date} */
  public static compareDate(dateOnly: DateOnly, date: Date): boolean{
    return dateOnly?.getFullYear() === date?.getFullYear() &&
      dateOnly?.getMonth() === date?.getMonth() &&
      dateOnly?.getDate() === date?.getDate();
  }
}
