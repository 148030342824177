import { Component, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { AddLimitStaffUnitRateDTO } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/limitStaffUnit/api1-limitStaffUnitsControl-controller.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { PropertyWrapFormControlType } from '../../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from '../../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { IFinancingSource } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/FinancingSource';
import { trace } from '../../../../../../../../src/app/modules/trace/operators/trace';
import { take, takeUntil } from 'rxjs/operators';
import { Api1StaffListSettingsControllerService } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service';

export type AddLimitStaffUnitRateDTOFormGroup = FormGroup<PropertyWrapFormControlType<Pick<AddLimitStaffUnitRateDTO, 'financingSourceId' | 'value'>>>;

@Component({
  selector: 'app-add-limit-staff-unit-rate-grid-control',
  templateUrl: './add-limit-staff-unit-rate-grid-control.component.html',
  styleUrls: ['./add-limit-staff-unit-rate-grid-control.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddLimitStaffUnitRateGridControlComponent),
    multi: true,
  }],
})
@traceClass('AddLimitStaffUnitRateGridControlComponent')
export class AddLimitStaffUnitRateGridControlComponent implements ControlValueAccessor, OnInit, OnDestroy {

  @Input('height') public height: number;
  @Input('financingSources') public financingSources = new Array<IFinancingSource>();
  @Output('isEditingNow$') public isEditingNow$: Subject<boolean> = new Subject<boolean>();
  public rateStep: number;
  public gridState: State = {};
  public unsubscribe$ = new ReplaySubject<any>(1);
  public form: AddLimitStaffUnitRateDTOFormGroup;
  private editedRowIndex: number;
  private onTouched = () => {
  };
  private onChange: (value: AddLimitStaffUnitRateDTO[]) => void = () => {
  };

  private _value: AddLimitStaffUnitRateDTO[];

  get value(): AddLimitStaffUnitRateDTO[] {
    return this._value;
  }

  set value(value: AddLimitStaffUnitRateDTO[]) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
  }

  private _disabled: boolean;

  public get disabled(): boolean {
    return this._disabled;
  }

  public get getAllowedFinancingSources() {
    return this.financingSources?.filter(f => !this.value.find(gd => gd.financingSourceId === f.id)) ?? [];
  }

  constructor(private readonly formBuilder: FormBuilder,
              private readonly settingsService: Api1StaffListSettingsControllerService,
              private readonly tracerService: TracerServiceBase) {
  }

  ngOnInit(): void {
    this.settingsService.rateStepWithCash$
      .pipe(trace(this.tracerService), take(1), takeUntil(this.unsubscribe$))
      .subscribe(value => this.rateStep = value);
  }

  writeValue(value: AddLimitStaffUnitRateDTO[]): void {
    this.value = value ?? [];
    this.onChange(this.value);
  }

  registerOnChange(onChange: (value: AddLimitStaffUnitRateDTO[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
  }

  @traceFunc()
  public getFinancingSourceTextById(id: number) {
    return this.financingSources.find(f => f.id === id)?.shortName;
  }

  @traceFunc()
  public addHandler({ sender }) {
    sender.addRow(this.createFormGroup(null));
    this.isEditingNow$.next(true);
  }

  @traceFunc()
  public cancelHandler({ sender, rowIndex }) {
    this.onTouched();
    sender.closeRow(rowIndex);
    this.isEditingNow$.next(false);
  }

  @traceFunc()
  public saveHandler({ sender, formGroup, rowIndex, isNew }) {
    if (formGroup.valid) {
      if (isNew) {
        this.value.push(formGroup.value);
      } else {
        this.value[rowIndex] = formGroup.value;
      }

      sender.closeRow(rowIndex);
      this.onChange(this.value);
      this.isEditingNow$.next(false);
    }
  }

  @traceFunc()
  public removeHandler({ sender, dataItem }) {
    this.value = this.value.filter(gd => gd.financingSourceId !== dataItem.financingSourceId);
    sender.cancelCell();
  }

  @traceFunc()
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.form);
    this.isEditingNow$.next(true);
  }

  @traceFunc()
  public createFormGroup(item: AddLimitStaffUnitRateDTO): AddLimitStaffUnitRateDTOFormGroup {
    this.form = this.formBuilder.group({
      financingSourceId: [item?.financingSourceId ?? null, [Validators.required]],
      value: [item?.value ?? null, [Validators.required, Validators.max(10000)]],
    });

    this.form.valueChanges.subscribe(v => this.tracerService.add2('Изменение формы', { obj: v }));

    return this.form;
  }

  @traceFunc()
  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.form = undefined;
    this.isEditingNow$.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

}
