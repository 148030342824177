import {Injectable, OnDestroy} from "@angular/core";
import {AuthServerResponse} from "../classes/auth-classes";
import {JwtHelperService} from "@auth0/angular-jwt";
import {UserAuth} from "../../../classes/domain/Auth/UserAuth";
import {Observable, ReplaySubject} from "rxjs";
import {TracerServiceBase} from "../../trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../trace/decorators/class.decorator";
import {TraceParamEnum} from "../../trace/decorators/classes/traceSetting.interface";
import {traceFunc} from "../../trace/decorators/func.decorator";

/** Сервис для работы с авторизационными данными */
@Injectable({providedIn: "root"})
@traceClass('AuthService_AuthDataService')
export class AuthService_AuthDataService implements OnDestroy{

  /** За сколько секунд до протухания токена считать его протухшим. Необходимо для обновления токена за ранее */
  private readonly expiredTokenAdvanceSeconds = 20;

  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    value: new ReplaySubject<AuthService_AuthDataService>(1)
  }

  /** Сервис работы с jwt */
  public readonly jwtHelperService: JwtHelperService = new JwtHelperService();

  private _token: string = null;
  /** Токен авторизации */
  public get token(){
    return this._token;
  }

  private _tokenAsObj: AuthService_AuthDataService_TokenObj = null;
  /** Токен авторизации как объект */
  public get tokenAsObj(){
    return this._tokenAsObj;
  }

  private _user: UserAuth = null;
  /** Текущий пользователь */
  public get user(){
    return this._user;
  }


  /** Время протухания токена */
  private _expiredDate : Date;

  /** Протух ли токен. Если токен отсутствует будет ошибка */
  public get tokenIsExpired(){
    if(!this.tokenAsObj){
      throw new Error('Токен авторизации отсутствует');
    }

    return new Date() > this._expiredDate;
  }

  /** Стрим изменения состояния данного сервиса */
  public readonly onChange$: Observable<AuthService_AuthDataService> = this.streams$.value;

  constructor(private readonly tracerService: TracerServiceBase) {
  }

  @traceFunc()
  public OnInit(authData: AuthServerResponse){
    this._token = authData?.token ?? null;
    this._tokenAsObj = this.getTokenAsObj(this.token);
    this.setExpiredDate();
    const userSource = authData?.authUser;
    this._user = !userSource ? null : JSON.parse(this.jwtHelperService.urlBase64Decode(userSource))
    this.streams$.value.next(this);
  }

  /** Получить токен как объект из токена */
  public getTokenAsObj(token: string): AuthService_AuthDataService_TokenObj{
    return !token ? null : this.jwtHelperService.decodeToken(token);
  }

  /** Устанавливаем дату протухания токена */
  private setExpiredDate(){
    if(this._tokenAsObj){
      const currentDate = +new Date();

      const exp = (new Date(0)).setUTCSeconds(this.tokenAsObj.exp);
      const nbf = (new Date(0)).setUTCSeconds(this.tokenAsObj.nbf);
      const duration = exp - nbf;

      this._expiredDate = new Date(currentDate + duration - (this.expiredTokenAdvanceSeconds * 1000));
    } else {
      this._expiredDate = null;
    }
  }

  @traceFunc()
  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.value.complete();
  }
}

/** Объект токена */
export interface AuthService_AuthDataService_TokenObj{
  /** Время истечения */
  exp: number
  /** Время создания */
  nbf: number
  /** Компания */
  uCN$: string
  /** Идентификатор пользователя */
  uId$: string
  /** Название программы */
  uPN$: string
  /** Идентификатор токена */
  g$: string
}
