import {RootPathItem} from "../../../../../src/app/classes/uiRootPath/root-path-item";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BuilderRoutingService {

  public workspace = {
    root: new RootPathItem('workspace', null),
    child: {
      error: new RootPathItem('error', () => this.workspace.root),
      graph: new RootPathItem('graph', () => this.workspace.root)
    }
  }

}
