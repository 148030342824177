import {Injectable, Injector, OnDestroy} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {RootPathHelper} from "../../helpers/rootPathHelper";
import {map, take} from "rxjs/operators";
import {AuthService} from "../../modules/auth/services/auth.service";
import {Observable, Subject, switchMap} from "rxjs";
import {AppSettingsService} from "../app-settings.service";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {BrowserDetectionService} from "../browser-detection-service";
import {exBufferDebounce} from "../../operators/ex-buffer-debounce.operator";
import {exLastLimitArray} from "../../operators/ex-last-limit-array.operator";
import {exArrayJoin} from "../../operators/ex-array-join.operator";
import {Error} from "@progress/kendo-licensing/dist/error.type";

/** Логирование ошибок на сервере */
@Injectable({
  providedIn: "root"
})
export class ServerLoggerService implements OnDestroy{
  /** Стримы */
  private streams$ = {
    warning: new Subject<string>(),
    error: new Subject<string>()
  }

  /** Время буферизации ошибок */
  private readonly _bufferDebounceTime: number = 1000;
  /** Лимит буфера. Сколько последних сообщений будет отправлено */
  private readonly _maxBufferLength: number = 10;
  /** Разделитель ошибок */
  private readonly _errorSeparator: string = '\r\n\r\n//---\r\n\r\n';

  private _urlPatch: string[];
  /**
   * Получить список всех используемых url для логирования.
   * Можно использовать для построения белых/черных списков и т.д.
   */
  public get urlPatch() {
    if(!this._urlPatch){
      this._urlPatch = [
        this.appSettingsService.webApiLoggingPath.warnAuth,
        this.appSettingsService.webApiLoggingPath.warn,
        this.appSettingsService.webApiLoggingPath.error,
        this.appSettingsService.webApiLoggingPath.errorAuth
      ];
    }

    return this._urlPatch;
  }

  /** Конструктор */
  constructor(private httpClient: HttpClient,
              private appSettingsService: AppSettingsService,
              private injector: Injector,
              public readonly tracerService: TracerServiceBase,
              private readonly browserDetectionService: BrowserDetectionService) {


    this.streams$.warning.pipe(
      exBufferDebounce(this._bufferDebounceTime),
      exLastLimitArray(this._maxBufferLength),
      exArrayJoin(this._errorSeparator)).subscribe(value => {
        this._send(LogLevel.Warn, value);
    });

    this.streams$.error.pipe(
      exBufferDebounce(this._bufferDebounceTime),
      exLastLimitArray(this._maxBufferLength),
      exArrayJoin(this._errorSeparator)).subscribe(value => {
        this._send(LogLevel.Error, value);
    })
  }

  /** Логирование ошибки на сервере уровнем error */
  public error(message: string, tracingSettings: ITracingSettings = {include: true, clearAfter: true}): void{
    this.streams$.error.next(this.getBody(message, tracingSettings))
  }

  /** Логирование на сервере уровнем warn */
  public warn(message: string, tracingSettings: ITracingSettings = {include: true, clearAfter: false}): void{
    this.streams$.warning.next(this.getBody(message, tracingSettings))
  }

  /** Отправляет ошибку на сервер. Метод определяет выполнить warn или error и получает сообщение из переданной ошибки */
  public send(error: HttpErrorResponse | Error | any){
    if(error instanceof Error){
      if(error?.message?.includes('NG0100')){
        return;
      }
    }
    const body = this.convertErrorToStr(error);
    const method = this.getLoggingMethod(error);
    method(body);
  }

  /** Получить метод отправки лога по переданному объекту */
  public getLoggingMethod(error: HttpErrorResponse | any) : (message: string) => void {
    if (error instanceof HttpErrorResponse){
      const errorAsHttp = error as HttpErrorResponse;
      switch (errorAsHttp.status){
        case 0: //Неизвестная ошибка
        case 400: //"Плохой запрос". Этот ответ означает, что сервер не понимает запрос из-за неверного синтаксиса.
        case 404: //"Не найден". Сервер не может найти запрашиваемый ресурс. Код этого ответа, наверно, самый известный из-за частоты его появления в вебе.
        case 412: //Клиент указал в своих заголовках условия, которые сервер не может выполнить
        case 414: //URI запрашиваемый клиентом слишком длинный для того, чтобы сервер смог его обработать
        case 500: //"Внутренняя ошибка сервера". Сервер столкнулся с ситуацией, которую он не знает как обработать
        case 501: //"Не выполнено". Метод запроса не поддерживается сервером и не может быть обработан. Единственные методы, которые сервера должны поддерживать (и, соответственно, не должны возвращать этот код) -  GET и HEAD.
        case 503: //Сервис недоступен". Сервер не готов обрабатывать запрос. Зачастую причинами являются отключение сервера или то, что он перегружен.
          return message => this.error(message);

        default: return message => this.warn(message);
      }
    }else {
      return message => this.error(message);
    }
  }

  /** Конверировать ошибку в строку */
  public convertErrorToStr(error: HttpErrorResponse | Error | any) : string{
    if (error instanceof HttpErrorResponse){
      const httpErrorResponse = error as HttpErrorResponse;
      return JSON.stringify({
        status: httpErrorResponse.status,
        statusText: httpErrorResponse.statusText,
        name: httpErrorResponse.name,
        message: httpErrorResponse.message,
        ok: httpErrorResponse.ok,
        url: httpErrorResponse.url,
        type: httpErrorResponse.type
      });
    }else if(error instanceof Error){
      return JSON.stringify({
        name: error.name,
        message: error.message,
        stack: error.stack,
      })
    } else {
      try {
        return JSON.stringify(error);
      } catch {
        return 'Не удалось JSON.stringify'
      }
    }
  }

  /** Построить тело сообщения */
  private getBody(message, tracingSettings: ITracingSettings){
    let tracing = '';
    if(tracingSettings.include){
      tracing = `Trace:\r\n${this.tracerService.storageService.buildAsString()}\r\n \r\n`;
      if(tracingSettings.clearAfter){
        this.tracerService.storageService.clear();
      }
    }

    return `Error:\r\n${message}\r\n\r\n${tracing}`;
  }

  /** Отправить лог на сервер */
  private _send(logLevel: LogLevel, message){
    this.getPath(logLevel).pipe(switchMap(path => {
      const browsInfo = this.getBrowserInfo();

      return this.httpClient.post<void>(RootPathHelper.join(
        this.appSettingsService.webApiBaseUrl,
        path
      ), { message: `${browsInfo}${message}`})
    })).pipe(take(1)).subscribe({
      next: () => {
        console.log("Ошибка отправлена на сервер")
      },
      error: () => {
        console.log('При отправке ошибки на сервер произошла ошибка')
      }
    })
  }

  /**
   * Получить путь к серверу.
   * Если пользователь авторизован то пойдет к защищенному методу.
   * Необходимо для вывода в логах пользователя
   * @private
   */
  private getPath(level: LogLevel): Observable<string>{
    const authService = this.injector.get(AuthService); //Не лучшее решение, но в конструктор нельзя передавать

    return authService.isAuth$.pipe(take(1), map(value => {
      let path = '';
      switch (level) {
        case LogLevel.Error:
          path = value ?
            this.appSettingsService.webApiLoggingPath.errorAuth :
            this.appSettingsService.webApiLoggingPath.error;
          break;
        case LogLevel.Warn:
          path = value ?
            this.appSettingsService.webApiLoggingPath.warnAuth :
            this.appSettingsService.webApiLoggingPath.warn;
          break;
        default: throw new Error('out of range')
      }

      return path;
    }))
  }

  /** Получить информацию о браузере */
  private getBrowserInfo(){
    return`Browser info: ${JSON.stringify(this.browserDetectionService.parseResult)}\r\n \r\n`;
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.streams$.error.complete();
    this.streams$.warning.complete();
  }
}

/** Интерфейс настроки трассировки в отправляемом на сервер сообщении */
interface ITracingSettings{
  include: boolean,
  clearAfter: boolean
}

enum LogLevel{
  Error,
  Warn
}
