import { Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { CustomFieldControl } from "../../services/classes/field-controls/custom-field-control";
import { IDirectoryEditControlComponent } from "../i-directory-edit-control.component";

@Injectable()
@traceClass('DirectoryEditControlComponentService')
export class DirectoryEditControlComponentService extends ComponentServiceBase<IDirectoryEditControlComponent> {

  constructor(private readonly tracer: TracerServiceBase){super()}

  /**Валидация переданных компоненту инпутов */
  @traceFunc()
  public validateInputs() {
    if(!this.component.field) {
      throw new Error("Не заполнен input [field] в компоненте DirectoryEditControlComponent");
    }
    if(!this.component.formControl) {
      throw new Error("Не заполнен input [formControl] в компоненте DirectoryEditControlComponent");
    }
  }

  /**Идентификация контрола и заполнение переменной componentTypeString у компонента */
  @traceFunc()
  public setComponentTypeString() {
    this.component.componentTypeString = this.component.field.control.name;

    if(this.component.field.control instanceof CustomFieldControl) {
      this.component.customTemplateRef = this.component.field.control.ref instanceof Function ? this.component.field.control.ref() : this.component.field.control.ref;
    }
  }

  /**Регистрация валидаторов (если они необходимы) */
  @traceFunc()
  public registerValidators() {
    if(!this.component.field.settings.isAllowNull) {
      this.component.formControl.addValidators([Validators.required]);
      this.component.formControl.updateValueAndValidity({emitEvent: false});
    }
  }

}
