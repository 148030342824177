import {Injectable, OnDestroy} from "@angular/core";
import {
  ArrayDataSource,
  ArrayDataSourceHasId,
  ArrayDataSourceIEntityId, ArrayDataSourceIEntityIdGuid,
  DataSource, DetectItemResult,
} from '../../classes/array-data-sources/data-source';
import {Observable, of, ReplaySubject, share, switchMap, tap, throwError} from "rxjs";
import {IEntityId} from "../../classes/domain/POCOs/interfaces/IEntityId";
import {catchError, map, take, takeUntil} from "rxjs/operators";
import {xnameofPath} from "../../functions/nameof";
import {exTapN} from "../../operators/ex-tap-n";
import {exLoadingMessage2, ExLoadingMessageParamType} from "../../operators/ex-loading-message.operator";
import {ObservableQueue} from "../../classes/observable-queues/observable-queue";
import { IEntityIdGuid } from '../../classes/domain/POCOs/interfaces/IEntityIdGuid';

const defaultErrorFn = () => {};

/**
 * Интерфейс обобщённого сервиса источника данных только для чтения
 * Чаще всего это означает, что обновлением данных будет заниматься родительский компонент
 */
interface IGenericDataSourceServiceWithParamsReadonly<TParams, TData, TDataSource extends DataSource<TData>> extends OnDestroy {
  paramsDataSource: DataSource<TParams>;
  dataSource: TDataSource;
}

/**
 * Обобщенный базовый сервис источника данных имеющий параметры для получения данных.<br>
 * <br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип данных.<br>
 * TDataSource - Тип источника данных. extends DataSource<TData>.<br>
 */
@Injectable()
abstract class GenericDataSourceServiceWithParamsBase<TParams, TData, TDataSource extends DataSource<TData>> implements IGenericDataSourceServiceWithParamsReadonly<TParams, TData, TDataSource>, OnDestroy{
  /** Стрим освобождения ресурсов */
  protected unsubscribe$ = new ReplaySubject<any>(1);

  /**
   * Параметры по которым построены данные.<br>
   * НЕ ДОПУСКАЕТСЯ использовать методы {@link DataSource.setData} и {@link DataSource.setData$} для обновления данных. Используй метод {@link reloadData$}<br>
   */
  public abstract readonly paramsDataSource: DataSource<TParams>;

  /**
   * Источник данных<br>
   * НЕ ДОПУСКАЕТСЯ использовать методы {@link DataSource.setData} и {@link DataSource.setData$} для обновления данных. Используй метод {@link reloadData$}<br>
   */
  public abstract readonly dataSource: TDataSource;

  /**
   * Использовать signalR<br>
   * Использовать для переопределения логики обработки signalR<br>
   * Метод {@link reloadData$} подписывается на возвращенный стрим, до нового вызова {@link reloadData$}, которое приводит к новой подписке
   * @return null если signalR не требуется(По умолчанию), иначе стрим
   * @protected
   */
  protected useSignalR$(): Observable<Observable<any>> | null{
    return null;
  }

  /** Очередь для обновления данных, источником которых является signalR. {@link ObservableQueue} */
  private _signalRQueue = new ObservableQueue(false);

  /**
   * Метод является НЕ асинхронной обверткой над {@link reloadData$}<br>
   * @param params параметры необходимые для получения данных
   * @param errorHandlingFn функция обработки ошибки. Если не заданно, выполнит пустую функцию-заглушку
   * @param loading настройки для {@link exLoadingMessage2}
   */
  public reloadData(params: TParams | Observable<TParams>, errorHandlingFn: (err?: any) => void = defaultErrorFn, loading: ExLoadingMessageParamType = undefined): void{
    this.reloadData$(params)
      .pipe(
        exLoadingMessage2(loading),
        takeUntil(this.unsubscribe$),
      ).subscribe({
      error: errorHandlingFn
    });
  }

  /**
   * Перезагрузить данные с новыми параметрами необходимыми для запроса<br>
   * Метод связывает два dataSource({@link paramsDataSource} и {@link dataSource}) между собой<br>
   * 1. Вызывает метод {@link paramsDataSource.setData$}(источник параметров). Если происходит ошибка, установит данные в {@link dataSource} в undefined<br>
   * 2. Вызовет метод {@link dataSource.setData$}(источник данных) с параметром результирующим {@link Observable} метода {@link _reloadData$}<br>
   * 3. При первой трансляции(используется {@link exTapN}) подпишется на результирующий стрим метода {@link useSignalR$}, и трансляцию будет добавлять в очередь {@link _signalRQueue}<br>
   * 4. При повторно вызове данного метода будет очищаться очередь {@link _signalRQueue}. Необходимо так как параметры для загрузки данных уже изменены<br>
   * @param params параметры для {@link paramsDataSource}. Если передан {@link Observable}, то старые данные будут до трансляции стрима
   */
  public reloadData$(params: TParams | Observable<TParams>): Observable<TDataSource>{
    return of(null)
      .pipe(
        switchMap(x => {
          return this.paramsDataSource.setData$(params)
            .pipe(
              catchError((err, caught) => {
                this.dataSource.setData(undefined);
                return throwError(err);
              }),
              switchMap(value => this.dataSource.setData$(value.data2 === undefined ? undefined : this._reloadData$(value.data))),
              exTapN(1, data => { //Для signalR
                if(this.paramsDataSource.data2 === undefined || this.dataSource.data2 === undefined){ //Выходим если параметры или данные отсутствуют
                  return;
                }

                const signalR$ = this.useSignalR$();
                if(!signalR$){ //Выходим если signalR не требуется
                  return;
                }

                signalR$.pipe(
                  takeUntil(this.paramsDataSource['streams$']['setData']), //до тех пор, пока не установятся новые данные
                  takeUntil(this.unsubscribe$)
                ).subscribe(value => {
                  this._signalRQueue.push$(value).pipe(
                    takeUntil(this.paramsDataSource['streams$']['setData']), //до тех пор, пока не установятся новые данные
                    takeUntil(this.unsubscribe$)
                  ).subscribe()
                })
              })
            )
        }),
        takeUntil(this.unsubscribe$),
        share()
      );
  }

  /**
   * Обновить данные с текущими параметрами для запроса. {@link paramsDataSource}<br>
   * 1. Получит текущие параметры из {@link paramsDataSource}<br>
   * 2. Получит стрим получения данных, вызовом метода {@link _reloadData$} передав в него параметры запроса<br>
   * 3. Вызовет метод {@link dataSource.setData$} передав стрим п.2<br>
   * 4. Результирующий стрим использует pipe {@link take} 1<br>
   * @exception если {@link paramsDataSource.wasEmitted} == false. Если параметры запроса еще не транслировались
   */
  public updateData$(): Observable<TData>{
    if(!this.paramsDataSource.wasEmitted){
      throw new Error('Параметры запроса еще не проинициализированы');
    }

    return this.setData$(this._reloadData$(this.paramsDataSource.data).pipe(take(1)));
  }

  /**
   * Установить новые данные в dataSource
   */
  public setData$(data: TData | Observable<TData>): Observable<TData>{
    return this.dataSource.setData$(data).pipe(
      take(1),
      map(value => value.data)
    );
  }


  /**
   * Метод получения данных<br>
   * Использовать для переопределения логики<br>
   * БУДЕТ ВЫЗВАН ТОЛЬКО ЕСЛИ ПАРАМЕТР !== undefined или null
   * @param params параметры необходимые для запроса
   * @protected
   */
  protected abstract _reloadData$(params: TParams): Observable<TData>;

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this._signalRQueue.onDestroy();
    this.dataSource.onDestroy();
    this.paramsDataSource.onDestroy();
  }
}


/**
 * Базовый сервис источника данных имеющий параметры для получения данных.<br>
 * TParams - Тип параметров, необходимых для инициализации данных.<br>
 * TData - Тип данных.<br>
 */
export abstract class DataSourceWithParamsBase<TParams, TData>
  extends GenericDataSourceServiceWithParamsBase<TParams, TData, DataSource<TData>>
  implements IDataSourceWithParamsReadonly<TParams, TData>{

}


/**
 * Базовый сервис источника данных (ТОЛЬКО ДЛЯ ЧТЕНИЯ) имеющий параметры для получения данных.<br>
 * TParams - Тип параметров, необходимых для инициализации данных.<br>
 * TData - Тип данных.<br>
 */
export interface IDataSourceWithParamsReadonly<TParams, TData> extends IGenericDataSourceServiceWithParamsReadonly<TParams, TData, DataSource<TData>> {

}


/**
 * Базовый сервис источника данных в виде массива имеющий параметры для получения данных.<br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип данных.<br>
 */
export abstract class ArrayDataSourceServiceWithParamsBase<TParams, TData>
  extends GenericDataSourceServiceWithParamsBase<TParams, TData[], ArrayDataSource<TData>>
  implements IArrayDataSourceServiceWithParamsReadonly<TParams, TData> {

}

/**
 * Базовый интерфейс (ТОЛЬКО ДЛЯ ЧТЕНИЯ) источника данных в виде массива имеющий параметры для получения данных.<br>
 * Чаще всего это означает, что загрузкой данных управляет родительский компонент<br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип данных.<br>
 */
export interface IArrayDataSourceServiceWithParamsReadonly<TParams, TData> extends IGenericDataSourceServiceWithParamsReadonly<TParams, TData[], ArrayDataSource<TData>> {

}


/**
 * Базовый класс для базовых классов где TDataSource extends ArrayDataSourceHasId<br>
 * TParams - Тип параметров, необходимых для инициализации данных.<br>
 * TData - Тип данных.<br>
 * TId - Тип идентификатора строки.<br>
 * TDataSource - Тип источника данных. Должен наследоваться от ArrayDataSourceHasId
 */
abstract class ArrayDataSourceHasIdServiceWithParamsBaseBase<TParams, TData, TId, TDataSource extends ArrayDataSourceHasId<TData, TId>>
  extends GenericDataSourceServiceWithParamsBase<TParams, TData[], TDataSource>{

  /** Перезагрузить элементы */
  public reloadFromRemoteByItems$(...targets: TData[]): Observable<DetectItemResult<TData>[]>{
    return this.reloadFromRemoteByIds$(...targets.map(x => this.dataSource.idGetter(x)));
  }

  /** Перезагрузить элементы по списку идентификаторов */
  public reloadFromRemoteByIds$(...targets: TId[]): Observable<DetectItemResult<TData>[]>{
    targets = targets.filter(x => x !== undefined && x !== null);

    if(targets.length === 0){
      return of([]);
    }

    if(this.paramsDataSource.data2 === undefined){
      throw new Error(`${xnameofPath(this).paramsDataSource.data2} === undefined`);
    }

    if(this.dataSource.data2 === undefined){
      throw new Error(`${xnameofPath(this).dataSource.data2} === undefined`)
    }

    return of(null)
      .pipe(
        switchMap(value => this.dataSource.reloadFromRemoteByIds$(
          (ids) => this._reloadFromRemoteByIds$(this.paramsDataSource.data, ids),
          ...targets
        )),
        share(),
        takeUntil(this.paramsDataSource['streams$']['setData']) //Пока не будут установлены новые параметры.
      );
  }

  /**
   * Перезагрузить элементы по списку идентификаторов полученных из signalR.<br>
   * Если в данный момент данные отсутствует, то вернет пустой массив.<br>
   * Использует метод {@link reloadFromRemoteByIds$}<br>
   */
  public reloadFromSignalR$(targets: TId[]): Observable<DetectItemResult<TData>[]>{
    if(this.paramsDataSource.data2 === undefined || this.dataSource.data2 === undefined){
      return of([]);
    }

    return this.reloadFromRemoteByIds$(...targets);
  }

  /**
   * Метод получения данных для перезагрузки элементов<br>
   * Использовать для переопределения логики<br>
   * @param params параметры необходимые для запроса
   * @param targets список идентификаторов подлежащих перезагрузке
   * @protected
   */
  protected abstract _reloadFromRemoteByIds$(params: TParams, targets: TId[]): Observable<TData[]>

}

export interface IArrayDataSourceHasIdServiceWithParamsBaseBaseReadonly<TParams, TData, TId, TDataSource extends ArrayDataSourceHasId<TData, TId>> extends GenericDataSourceServiceWithParamsBase<TParams, TData[], TDataSource>{

}


/**
 * Базовый сервис источника данных в виде массива элементы которого имеют идентификатор имеющий параметры для получения данных.<br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип элемента данных.<br>
 * TId - Тип идентификатора.<br>
 */
export abstract class ArrayDataSourceHasIdServiceWithParamsBase<TParams, TData, TId>
  extends ArrayDataSourceHasIdServiceWithParamsBaseBase<TParams, TData, TId, ArrayDataSourceHasId<TData, TId>>{

}


export interface IArrayDataSourceHasIdServiceWithParamsBaseReadonly<TParams, TData, TId> extends IArrayDataSourceHasIdServiceWithParamsBaseBaseReadonly<TParams, TData, TId, ArrayDataSourceHasId<TData, TId>>{

}


/**
 * Базовый сервис источника данных в виде массива элементы которого реализуют IEntityId имеющий параметры для получения данных.<br>
 * <br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип элемента данных.<br>
 */
export abstract class ArrayDataSourceIEntityIdServiceWithParamsBase<TParams, TData extends IEntityId>
  extends ArrayDataSourceHasIdServiceWithParamsBaseBase<TParams, TData, IEntityId['id'], ArrayDataSourceIEntityId<TData>>{

}

/**
 * Readonly базовый сервис источника данных в виде массива элементы которого реализуют IEntityId имеющий параметры для получения данных.<br>
 * <br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип элемента данных.<br>
 */
export interface IArrayDataSourceIEntityIdServiceWithParamsBaseReadonly<TParams, TData extends IEntityId>
  extends IArrayDataSourceHasIdServiceWithParamsBaseBaseReadonly<TParams, TData, IEntityId['id'], ArrayDataSourceIEntityId<TData>>{

}

/**
 * Базовый сервис источника данных в виде массива элементы которого реализуют IEntityIdGuid имеющий параметры для получения данных.<br>
 * <br>
 * TParams - Тип параметров, необходимых для инициализации данных<br>
 * TData - Тип элемента данных.<br>
 */
export abstract class ArrayDataSourceIEntityIdGuidServiceWithParamsBase<TParams, TData extends IEntityIdGuid>
  extends ArrayDataSourceHasIdServiceWithParamsBaseBase<TParams, TData, IEntityIdGuid['id'], ArrayDataSourceIEntityIdGuid<TData>>{

}

