import {Injectable} from "@angular/core";
import * as Bowser from "bowser";
import {AlertService} from "./alert.service";

const BROWSER_MAP = {
  amazon_silk: 'Amazon Silk',
  android: 'Android Browser',
  bada: 'Bada',
  blackberry: 'BlackBerry',
  chrome: 'Chrome',
  chromium: 'Chromium',
  electron: 'Electron',
  epiphany: 'Epiphany',
  firefox: 'Firefox',
  focus: 'Focus',
  generic: 'Generic',
  googlebot: 'Googlebot',
  google_search: 'Google Search',
  ie: 'Internet Explorer',
  k_meleon: 'K-Meleon',
  maxthon: 'Maxthon',
  edge: 'Microsoft Edge',
  mz: 'MZ Browser',
  naver: 'NAVER Whale Browser',
  opera: 'Opera',
  opera_coast: 'Opera Coast',
  phantomjs: 'PhantomJS',
  puffin: 'Puffin',
  qupzilla: 'QupZilla',
  qq: 'QQ Browser',
  qqlite: 'QQ Browser Lite',
  safari: 'Safari',
  sailfish: 'Sailfish',
  samsung_internet: 'Samsung Internet for Android',
  seamonkey: 'SeaMonkey',
  sleipnir: 'Sleipnir',
  swing: 'Swing',
  tizen: 'Tizen',
  uc: 'UC Browser',
  vivaldi: 'Vivaldi',
  webos: 'WebOS Browser',
  wechat: 'WeChat',
  yandex: 'Yandex Browser',
};

/**
 * Сервис получения информации о браузере пользователя
 * Использует https://www.npmjs.com/package/bowser
 * Константы https://github.com/lancedikson/bowser/blob/HEAD/src/constants.js
 */
@Injectable({providedIn: "root"})
export class BrowserDetectionService{

  /** Массив поддерживаемых браузеров */
  public readonly supportedBrowsers: BrowserItem[] = [
    new BrowserItem(BROWSER_MAP.chrome, '>99'),
    new BrowserItem(BROWSER_MAP.firefox, '>98'),
    new BrowserItem(BROWSER_MAP.edge, '>100'),
    new BrowserItem(BROWSER_MAP.opera, '>86'),
    new BrowserItem(BROWSER_MAP.yandex, '>20')
  ]

  /** Информация о браузере */
  private readonly browser: Bowser.Parser.Parser;
  /** Информация о браузере(распарсенная) */
  public readonly parseResult: Bowser.Parser.ParsedResult;

  constructor(private alertService: AlertService) {
    this.browser = Bowser.getParser(window.navigator.userAgent);
    this.parseResult = this.browser.getResult();
  }

  /** Проверка браузера пользователя */
  public checkBrowser(){
    const item = this.findSupportedBrowserItem();

    if(!item){
      this.showAlert(`Поддерживаемые браузеры: ${this.supportedBrowsers.map(x => x.name).join(', ')}`)
      return;
    }

    const checkTree = {};
    checkTree[item.name] = item.version;

    if(!this.browser.satisfies(checkTree)){
      this.showAlert('Версия Вашего браузера устарела. Обновите до последней версии');
      return;
    }
  }

  /** Найти элемент из списка поддерживаемых браузеров по текущему браузеру пользователя */
  private findSupportedBrowserItem(){
    const browName = this.browser.getBrowserName();
    return this.supportedBrowsers.find(x => x.name == browName)
  }

  /** Показать сообщение */
  private showAlert(message: string){
    this.alertService.defaultAlertOption.information().mod(x => {
      x.message = '<strong>Мы не можем гарантировать стабильную работу в Вашем браузере</strong><br><br>' + message;
    }).showAlert();
  }
}

class BrowserItem{
  constructor(public readonly name: string,
              public readonly version: string) {
  }
}



const PLATFORMS_MAP = {
  tablet: 'tablet',
  mobile: 'mobile',
  desktop: 'desktop',
  tv: 'tv',
};

const OS_MAP = {
  WindowsPhone: 'Windows Phone',
  Windows: 'Windows',
  MacOS: 'macOS',
  iOS: 'iOS',
  Android: 'Android',
  WebOS: 'WebOS',
  BlackBerry: 'BlackBerry',
  Bada: 'Bada',
  Tizen: 'Tizen',
  Linux: 'Linux',
  ChromeOS: 'Chrome OS',
  PlayStation4: 'PlayStation 4',
  Roku: 'Roku',
};

const ENGINE_MAP = {
  EdgeHTML: 'EdgeHTML',
  Blink: 'Blink',
  Trident: 'Trident',
  Presto: 'Presto',
  Gecko: 'Gecko',
  WebKit: 'WebKit',
};


/*export const BROWSER_ALIASES_MAP = {
  'Amazon Silk': 'amazon_silk',
  'Android Browser': 'android',
  Bada: 'bada',
  BlackBerry: 'blackberry',
  Chrome: 'chrome',
  Chromium: 'chromium',
  Electron: 'electron',
  Epiphany: 'epiphany',
  Firefox: 'firefox',
  Focus: 'focus',
  Generic: 'generic',
  'Google Search': 'google_search',
  Googlebot: 'googlebot',
  'Internet Explorer': 'ie',
  'K-Meleon': 'k_meleon',
  Maxthon: 'maxthon',
  'Microsoft Edge': 'edge',
  'MZ Browser': 'mz',
  'NAVER Whale Browser': 'naver',
  Opera: 'opera',
  'Opera Coast': 'opera_coast',
  PhantomJS: 'phantomjs',
  Puffin: 'puffin',
  QupZilla: 'qupzilla',
  QQ: 'qq',
  QQLite: 'qqlite',
  Safari: 'safari',
  Sailfish: 'sailfish',
  'Samsung Internet for Android': 'samsung_internet',
  SeaMonkey: 'seamonkey',
  Sleipnir: 'sleipnir',
  Swing: 'swing',
  Tizen: 'tizen',
  'UC Browser': 'uc',
  Vivaldi: 'vivaldi',
  'WebOS Browser': 'webos',
  WeChat: 'wechat',
  'Yandex Browser': 'yandex',
  Roku: 'roku',
};*/
