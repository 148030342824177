import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {WebApi1Service} from "../web-api1.service";
import { FinancingSource } from "src/app/classes/domain/POCOs/stafflist/FinancingSource";

@Injectable({
  providedIn: 'root'
})
export class Api1FinancingSourceControllerService {

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service) {

  }

  public getAll$(ids: number[] = null): Observable<Array<FinancingSource>>{
    return this.httpClient
      .post<Array<FinancingSource>>(this.webApi1Service.controllers.financingSource.actions.getAll.toString(),
      {
        Ids: ids
      }
      );
  }
}
