import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISubdivision, Subdivision } from 'src/app/classes/domain/POCOs/stafflist/Subdivision';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { CustomFormValidators } from '../../../../../../../src/app/validators/custom-form-validators.class';
import { exDistinctUntilChanged } from '../../../../../../../src/app/operators/ex-distinct-until-changed.operator';
import { combineLatest, filter, map, Observable, ReplaySubject, startWith, switchMap } from 'rxjs';
import { DateHelper } from '../../../../../../../src/app/helpers/dateHelper';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { DataSource } from '../../../../../../../src/app/classes/array-data-sources/data-source';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../src/app/services/data-source-services/data-source.service';
import { ForDate, IForDate } from '../../../../../../../src/app/classes/for-date';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-subdivision-form',
  templateUrl: './add-subdivision-form.component.html',
  styleUrls: ['./add-subdivision-form.component.css'],
})
@traceClass('AddSubdivisionFormComponent')
export class AddSubdivisionFormComponent implements OnInit, OnDestroy {
  public dataSource: AddSubdivisionFormComponent_DataSourceService;
  public subdivisions$: Observable<ISubdivision[]>;
  @Input('dataItem') public dataItem: Subdivision;
  @Output('onSave') public onSaveEventEmitter = new EventEmitter<Subdivision>();
  @Output('onCancel') public onCancelEventEmitter = new EventEmitter<boolean>();
  public form: FormGroup<IAddSubdivisionFormComponentForm>;
  public readonly selectedSubdivision = new DataSource<Subdivision>();
  public readonly startDateMin$ = new ReplaySubject<Date>(1);
  public readonly endDateMax$ = new ReplaySubject<Date>(1);
  public expandedNodeIndices: number[] = [];
  private readonly streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly traceService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.form = this.createForm(this.dataItem);

    this.subdivisions$ = this.dataSource.data.subdivisions.dataSource.data$;
  }

  @traceFunc()
  public onClickSave() {
    this.onSaveEventEmitter.emit(this.form.value as Subdivision);
  }

  @traceFunc()
  public onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  /** Создать форму */
  @traceFunc()
  private createForm(item: Subdivision) {
    const startDateControl = new FormControl(item?.startDate ?? null, {
      validators: [Validators.required, CustomFormValidators.validateStartDateWithEndDate, this.validateStartDateWithParent.bind(this)],
      updateOn: 'blur',
    });

    const endDateControl = new FormControl(item?.endDate ?? null, {
      validators: [CustomFormValidators.validateEndDateWithStartDate, this.validateEndDateWithParent.bind(this)],
      updateOn: 'blur',
    });

    const form = new FormGroup({
      startDate: startDateControl,
      endDate: endDateControl,
      parentId: new FormControl(item?.parentId ?? null, []),
      code: new FormControl(item?.code ?? '', [this.codeValidator, Validators.maxLength(50)]),
      longName: new FormControl(item?.longName ?? '', [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      shortName: new FormControl(item?.shortName ?? '', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      comment: new FormControl(item?.comment ?? '', Validators.maxLength(100)),
      isAutogenerateCode: new FormControl(true),
    });

    this.selectedSubdivision
      .setData$(form.controls.parentId.valueChanges
        .pipe(
          switchMap(parentId => this.subdivisions$.pipe(map(v => v.find(f => f.id === parentId)))),
        ))
      .subscribe(({ data }) => {
        this.startDateMin$.next(DateHelper.valueOrMin(data?.startDate));
        this.endDateMax$.next(DateHelper.valueOrMax(data?.endDate));

        form.controls.startDate.updateValueAndValidity({ emitEvent: false });
        form.controls.endDate.updateValueAndValidity({ emitEvent: false });
      });

    form.markAllAsTouched();

    combineLatest([
      startDateControl.valueChanges.pipe(exDistinctUntilChanged(startDateControl.value)),
      endDateControl.valueChanges.pipe(exDistinctUntilChanged(endDateControl.value), startWith(null)),
    ]).pipe(
      map(([startDate, endDate]) => ({ startDate, endDate })),
      filter(v => !!v.startDate),
      switchMap(v => this.dataSource.data.subdivisions.reloadData$(ForDate.Get(v))),
      takeUntil(this.streams$.unsubscribes),
    ).subscribe(({ data }) => {
      this.expandedNodeIndices = data.map(s => s.id);
    });

    form.valueChanges
      .subscribe(obj => {
        this.traceService.add2(`Изменена форма добавления подразделения`, { obj });
      });

    return form;
  }

  /** Проверка "Даты начала" с помощью родительского подразделения */
  private validateStartDateWithParent(startDateControl: AbstractControl): { [s: string]: any } | null {
    if (startDateControl.parent && startDateControl?.value < this.selectedSubdivision.data?.startDate)
      return { startDateLowerThanParent: true, parentValue: this.selectedSubdivision.data.startDate };

    return null;
  }

  /** Проверка "Даты конца" с помощью родительского подразделения */
  private validateEndDateWithParent(endDateControl: AbstractControl): { [s: string]: any } | null {
    if (endDateControl.parent && DateHelper.valueOrMax(endDateControl?.value) > DateHelper.valueOrMax(this.selectedSubdivision.data?.endDate))
      return { endDateGreaterThanParent: true, parentValue: this.selectedSubdivision.data.endDate };

    return null;
  }

  /** Кодовый валидатор */
  private codeValidator(codeControl: AbstractControl): { [s: string]: boolean } | null {
    if (codeControl.parent && codeControl.parent.value.isAutogenerateCode !== true)
      return Validators.required(codeControl);

    return null;
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}

export interface IAddSubdivisionFormComponentForm extends PropertyWrapFormControlType<Pick<Subdivision, 'startDate' | 'endDate' | 'parentId' | 'code' | 'longName' | 'shortName' | 'comment'>> {
  /** Генерировать код подразделения автоматически */
  isAutogenerateCode: FormControl<boolean>;
}

export class AddSubdivisionFormComponent_DataSourceService extends DataSource<AddSubdivisionFormComponent_Data> {
}

export class AddSubdivisionFormComponent_Data {
  constructor(public subdivisions: ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, ISubdivision>) {
  }
}
