import { TemplateRef } from "@angular/core";
import { IFieldControl } from "./interfaces/common";

export class CustomFieldControl implements IFieldControl<any> {
  public readonly name = 'CustomFieldControl';
  public getValueFunc = (stringValue: string) => stringValue;

  /* Настройки. В кастомном контроле всегда будут null */
  public settings: null;

  constructor (
    public ref: (() => TemplateRef<any>) | TemplateRef<any>
  ) {}
}
