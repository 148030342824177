<div class="wrap" [formGroup]="form">
  <label class="k-checkbox-label" for='dateFrom'>Дата: </label>
  <kendo-datepicker
    id = "dateFrom"
    style="width: 100%"
    formControlName="date"
    [format]="'dd.MM.yyyy'"
  >
    <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
  </kendo-datepicker>
  <ng-content select=".content1"></ng-content>
  <ng-content select=".content2"></ng-content>
</div>

<ng-content></ng-content>

<div class="k-form-buttons" style="margin-top: 25px; justify-content: flex-end;">
  <button kendoButton type="button" primary="true" (click)="onClickOk()">Сформировать</button>
  <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
</div>
