import { Injectable, OnDestroy, ViewContainerRef } from '@angular/core';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { ComponentServiceBase } from 'src/app/services/abstracts/component-service-base';
import { SettingsReaderService } from '../../../services/classes/service-settings/settings-reader/settings-reader.service';
import { IDropDownTreeDirectoryEditComponent } from '../i-dropdowntree-directory-edit-control.component';
import { map, startWith, takeUntil } from 'rxjs';
import { CustomFormValidationFunctions } from '../../../../../validators/custom-form-validation-functions.class';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { NotVersioningField } from '../../../services/classes/fields/not-versioning-field';
import { FieldType } from '../../../services/classes/fields/field-type';

@Injectable()
@traceClass('DropDownTreeDirectoryEditComponentService')
export class DropDownTreeDirectoryEditComponentService extends ComponentServiceBase<IDropDownTreeDirectoryEditComponent> implements OnDestroy {
  private srs = new SettingsReaderService();
  private _unsubscribe$ = new ReplaySubject(1);

  constructor(private tracer: TracerServiceBase) {
    super();
  }

  /** Считывание значений из настроек и устанавливает в Observable компонента */
  @traceFunc()
  public readSettings() {
    this.component.valueField = this.component.settings.valueField ?? 'id';
    this.component.popupSettings = this.getPopupSettings(this.component.field, this.component.appendContainer);

    this.component.data$ = this.srs.getValue$(this.component.settings.data);
    this.component.dataItem$ = this.component.data$.pipe(
      startWith([]),
      map(data => data.find(d => d[this.component.valueField] === this.component.control.value) ?? {}),
    );
  }

  /** Регистрация валидаторов*/
  @traceFunc()
  public addValidators() {
    CustomFormValidationFunctions.validateDataWithControlValue$(this.component.data$, this.component.control, (d, c) => d[this.component.valueField] === c).pipe(takeUntil(this._unsubscribe$)).subscribe();
  }

  @traceFunc()
  private getPopupSettings(field: FieldType, appendTo: ViewContainerRef) {
    if (field instanceof NotVersioningField) {
      return { appendTo, ...this.component.settings.popupSettings };
    }
    return this.component.settings.popupSettings;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
}
