import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { DropDownListFieldControl_Settings } from "../../services/classes/field-controls/dropdownlist-field-control";
import { IDropDownListDirectoryEditComponent } from "./i-dropdownlist-directory-edit-control.component";
import { DropDownListDirectoryEditComponentService } from "./services/dropdownlist-directory-edit-control-component.service";

@Component({
  selector: 'app-dropdown-directory-edit-control',
  templateUrl: './dropdownlist-directory-edit-control.component.html',
  styleUrls: ['./dropdownlist-directory-edit-control.component.css'],
  providers: [DropDownListDirectoryEditComponentService]
})
@traceClass('DropDownListDirectoryEditComponent')
export class DropDownListDirectoryEditComponent implements OnInit, IDropDownListDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: DropDownListFieldControl_Settings;

  public data$: Observable<any>;
  public valueField: string;

  constructor(private service: DropDownListDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
    this.service.addValidators();
  }

}
