import {IEntityVersioning} from '../interfaces/IEntityVersioning';
import {IEntityPositionOwnerId} from '../interfaces/IEntityPositionOwnerId';
import {classBackend} from '../../../../decorators/classBackend/classBackend.decorator';
import {className} from '../../../../decorators/className/className.decorator';
import {StaffUnitTypeEnum} from "../../enums/StaffUnitTypeEnum";
import {DateHelper} from "../../../../helpers/dateHelper";
import {ObjComparer} from "../../../object-comparers/object-comparer";

/**
 * Результат анализа даты в диапазоне исполнения должности и ее виртуальной строки<br>
 * outOfStaffUnitRange - дата не попадает в диапазон действия исполнения должности<br>
 * outOfSubStaffUnitRange - дата попадает в диапазон действия исполнения должности, но при этом не попадает в диапазон действия текущей виртуальной строки<br>
 * inRange - дата попадает в диапазон действия исполнения должности и в диапазон действия текущей виртуальной строки<br>
 */
export type DateInStaffUnitRangeType = 'outOfStaffUnitRange' | 'outOfSubStaffUnitRange' | 'inRange';

export interface IStaffUnit extends IEntityVersioning, IEntityPositionOwnerId {
  /** Идентификатор сотрудника. FK на таблицу stafflist.Employees */
  employeeId: number | null;
  /** Идентификатор штатной позиции, к которой относится данная штатная единица. FK на таблицу Positions */
  financingSourceId: number | null;
  /** Тип штатной единицы (основная ставка, совмещение, замещение). FK на таблицу stafflist.StaffUnitTypes */
  typeId: number | null;
  /** Идентификатор штатной единицы, которую замещает данная штатная единица. FK на эту же таблицу */
  parentId: number | null;
  /** Исполнение обязанностей */
  executionDutiesFlag: boolean | null;
  /** Количество занятых ставок этой штатной единицей */
  rate: number | null;
  /** Процент выплаты */
  percent: number | null;
  /** Выплата компенсации молока за вредность */
  milk: boolean;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IStaffUnitOptional = Partial<IStaffUnit>;

@classBackend('StaffUnit', 'stafflist')
@className('StaffUnit')
/** Штатная единица */
export class StaffUnit implements IStaffUnit {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public positionId: number | null,
              public employeeId: number | null,
              public financingSourceId: number | null,
              public typeId: number | null,
              public parentId: number | null,
              public executionDutiesFlag: boolean | null,
              public rate: number | null,
              public percent: number | null,
              public timestamp: [],
              public milk: boolean,
  ) {
  }

  private static _fullComparer: ObjComparer<Omit<IStaffUnit, 'timestamp'>>;
  /** Сравнение по всем полям */
  public static get fullComparer() {
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer({
        id: true,
        startDate: ObjComparer.dateComparer,
        endDate: ObjComparer.dateComparer,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        comment: true,
        deletedFlag: true,
        ownerId: true,
        actionId: true,
        orderId: true,
        positionId: true,
        employeeId: true,
        financingSourceId: true,
        typeId: true,
        parentId: true,
        executionDutiesFlag: true,
        rate: true,
        percent: true,
        milk: true
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IStaffUnit, 'modifiedUserId' | 'modifiedDate' | 'timestamp'>>;
  /** Сравнение по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }

  /** Является ли исполнение должности основной ставкой */
  public static isBasic(staffUnitType: number | StaffUnitTypeEnum){
    return staffUnitType === StaffUnitTypeEnum.Basic || this.isExternal(staffUnitType);
  }

  /** Является ли исполнение должности внешним */
  public static isExternal(staffUnitType: number | StaffUnitTypeEnum): boolean {
    switch (staffUnitType) {
      case StaffUnitTypeEnum.CombinationExternal:
      case StaffUnitTypeEnum.MoonlighterExternal:
        return true;
      default:
        return false;
    }
  }

  /** Является ли исполнение должности работой за кого то */
  public static isProxy(parentId: number): boolean {
    return !!parentId;
  }

  /** Является ли исполнение должности Совместительством */
  public static isMoonlighter(staffUnitType: number): boolean{
    return staffUnitType === StaffUnitTypeEnum.MoonlighterInner || staffUnitType === StaffUnitTypeEnum.MoonlighterExternal;
  }

  /** Является ли исполнение должности дежурством */
  public static isDuty(staffUnitType: number): boolean{
    return staffUnitType === StaffUnitTypeEnum.Duty;
  }

  /** Является ли исполнение должности Совмещением */
  public static isCombination(staffUnitType: number): boolean{
    return staffUnitType === StaffUnitTypeEnum.CombinationInner || staffUnitType === StaffUnitTypeEnum.CombinationExternal;
  }

  /** Является ли исполнение должности Увеличением объема работ */
  public static isUvor(staffUnitType: number): boolean{
    return staffUnitType === StaffUnitTypeEnum.Uvor;
  }

  /** Проверяет исполнение должности находится в одном месяце */
  public static inMonth(startDate: Date, endDate: Date): boolean{
    if(!startDate || !endDate){ //Если нет начала или окончания
      return false;
    }

    return startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth();
  }

  /** Может ли исполнение должности иметь совместительство(proxy) {@link StaffUnitTypeEnum.MoonlighterInner} */
  public static isSupportedMoonlighterProxy(parentStaffUnitTypeId: StaffUnitTypeEnum, parentId: number): boolean{
    if(typeof parentId === 'number'){
      return false;
    }

    return parentStaffUnitTypeId === StaffUnitTypeEnum.Basic
      || parentStaffUnitTypeId === StaffUnitTypeEnum.MoonlighterExternal
      || parentStaffUnitTypeId === StaffUnitTypeEnum.MoonlighterInner;
  }

  /** Может ли исполнение должности иметь совмещение(proxy) {@link StaffUnitTypeEnum.CombinationInner} */
  public static isSupportedCombinationProxy(parentStaffUnitTypeId: StaffUnitTypeEnum, parentId: number): boolean{
    if(typeof parentId === 'number'){
      return false;
    }

    return parentStaffUnitTypeId === StaffUnitTypeEnum.Basic
      || parentStaffUnitTypeId === StaffUnitTypeEnum.MoonlighterExternal
      || parentStaffUnitTypeId === StaffUnitTypeEnum.MoonlighterInner;
  }

  /** Может ли исполнение должности иметь УВОР(proxy) {@link StaffUnitTypeEnum.Uvor} */
  public static isSupportedUvorProxy(parentStaffUnitTypeId: StaffUnitTypeEnum, parentId: number): boolean{
    if(typeof parentId === 'number'){
      return false;
    }

    return parentStaffUnitTypeId === StaffUnitTypeEnum.Basic
      || parentStaffUnitTypeId === StaffUnitTypeEnum.MoonlighterExternal
      || parentStaffUnitTypeId === StaffUnitTypeEnum.MoonlighterInner;
  }

  /**
   * Может ли исполнение должности иметь proxy переданного типа
   * @param parentStaffUnitTypeId тип строки ЗА кого
   * @param parentId идентификатор родительской строки у ЗА кого
   * @param proxyStaffUnitTypeId какой тип будет у замещаемого
   */
  public static isSupportedProxy(parentStaffUnitTypeId: StaffUnitTypeEnum, parentId: number, proxyStaffUnitTypeId: StaffUnitTypeEnum){
    switch (proxyStaffUnitTypeId){
      case StaffUnitTypeEnum.MoonlighterInner:
        return this.isSupportedMoonlighterProxy(parentStaffUnitTypeId, parentId);
      case StaffUnitTypeEnum.CombinationInner:
        return this.isSupportedCombinationProxy(parentStaffUnitTypeId, parentId);
      case StaffUnitTypeEnum.Uvor:
        return this.isSupportedUvorProxy(parentStaffUnitTypeId, parentId);
      default: return false;
    }
  }

  /**
   *  Метод анализирует переданную {@link date} на вхождение в диапазон действия исполнения должности и виртуальной строки<br>
   */
  public static checkDateInRange(date: Date, staffUnitStartDate: Date, staffUnitEndDate: Date, subStaffUnitStartDate: Date, subStaffUnitEndDate: Date): DateInStaffUnitRangeType{
    if(date < DateHelper.valueOrMin(staffUnitStartDate) || date >  DateHelper.valueOrMax(staffUnitEndDate)){
      return 'outOfStaffUnitRange';
    }

    if(date < DateHelper.valueOrMin(subStaffUnitStartDate) || date > DateHelper.valueOrMax(subStaffUnitEndDate)){
      return 'outOfSubStaffUnitRange';
    }

    return 'inRange';
  }

  /**
   * Фильтрует массив элементов, на вхождение в диапазон действия исполнения должности
   * @param items что фильтровать
   * @param dateGetter функция получения даты у элемента
   * @param startDate Дата начала действия исполнения должности {@link IStaffUnit.startDate}
   * @param endDate дата окончания действия исполнения должности {@link IStaffUnit.endDate}
   */
  public static* filterByRangeWork<T>(items: T[], dateGetter: (item: T) => Date, startDate: Date, endDate: Date){
    for (let item of items) {
      if(DateHelper.isExistInRange(dateGetter(item), startDate, endDate)){
        yield item;
      }
    }
  }
}
