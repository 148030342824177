import {Component, Input, OnInit} from '@angular/core';
import {  WorkModesDirectoryGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-work-modes-directory-grid-control-controller.service';
import { State } from '@progress/kendo-data-query';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { versioningAndNotVersioningDirectoryServiceProvider } from 'src/app/components/directory-edit/services/providers/versioning-and-not-versioning-directory-service-provider';
import { Observable } from 'rxjs';
import { CellClickEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { IWorkModesDirectoryGridComponent } from './i-work-modes-directory-grid.component';
import { WorkModesDirectoryGridComponentService } from './services/work-modes-directory-grid-component.service';
import { WorkModesDirectoryGridComponentDataService } from './services/work-modes-directory-grid-component-data.service';
import { ReportDateValues } from 'src/app/components/report-dates/classes/report-date-values';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';

@Component({
  selector: 'app-work-modes-directory-grid',
  templateUrl: './work-modes-directory-grid.component.html',
  styleUrls: ['./work-modes-directory-grid.component.css'],
  providers: [versioningAndNotVersioningDirectoryServiceProvider,
              WorkModesDirectoryGridComponentService,
              WorkModesDirectoryGridComponentDataService]
})

@traceClass('WorkModesDirectoryGridComponent')
export class WorkModesDirectoryGridComponent implements IWorkModesDirectoryGridComponent, OnInit {

  /** Стрим данных для грида */
  public data$: Observable<WorkModesDirectoryGridItem[]>;

  /** Текущий выбранный элемент в гриде */
  public currentSelectedItem: WorkModesDirectoryGridItem;

  private _reportDateValues: ReportDateValues;
  /** Диапазон дат формирования отчёта */
  @Input("reportDateValues") set reportDateValues(value: ReportDateValues)
  {
    this._reportDateValues = value;
    this.selectedItems = [];
    this.service.onReportDateValuesChanged();
  }
  get reportDateValues() {
    return this._reportDateValues;
  }

  public selectedItems:number[] = [];
  public gridState:State = {
    sort:  [{
      field: 'Name',
      dir: 'asc'
    }]
  };

  constructor(private service: WorkModesDirectoryGridComponentService,
              private traceService: TracerServiceBase) {

      service.component = this;
  }

  @traceFunc()
  public ngOnInit() {
    this.service.init();
  }

  /** Событие происходит при клике на кнопку добавления нового элемента нового элемента в справочник */
  @traceFunc()
  public onClickAdd() {
    this.service.onClickAdd();
  }

  /** Событие происходит при клике на кнопку начала редактирования (в дополнительных данных) */
  @traceFunc()
  public startEditButtonClick(fieldName: string, dataItem: any, event: any) {
    this.service.edit(fieldName, dataItem, event.currentTarget);
  }

  /** Событие происходит при двойном клике на ячейку */
  public onDblCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.service.onDblCellClick($event);
  }

  /** Событие при изменении выбранного элемента */
  public onSelectionChanged(e: SelectionEvent){
    this.service.onSelectionChanged(e);
  }
}
