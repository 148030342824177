import { Pipe, PipeTransform } from '@angular/core';
import { GenderEnum } from '../classes/domain/enums/gender-enum';

@Pipe({
  name: 'genderText',
})
export class GenderTextPipe implements PipeTransform {

  transform(value?: GenderEnum): string {
    return value === null ? '' : value === 0 ? 'муж.' : 'жен.';
  }

}
