import {Component} from '@angular/core';
import { ReportDatesComponentSharedService } from 'src/app/components/report-dates/services/report-dates-component-shared.service';

@Component({
  selector: 'app-directories',
  templateUrl: './directories.component.html',
  styleUrls: ['./directories.component.css'],
  providers: [ReportDatesComponentSharedService]
})
export class DirectoriesComponent {

}
