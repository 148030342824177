import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EditEmployeeNamesDeclensionsComponentDataSourceService } from './services/edit-employee-names-declensions-component-data-source.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SaveEmployeeDeclensionDTO } from '../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-names-declension-controller.service';
import { PropertyWrapFormControlType } from '../../../../../../../src/app/classes/types/property-wrap-form-control-type';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';

export type EditEmployeeNamesDeclensionsComponentForm = PropertyWrapFormControlType<SaveEmployeeDeclensionDTO>;

@Component({
  selector: 'app-edit-employee-names-declensions',
  templateUrl: './edit-employee-names-declensions.component.html',
  styleUrls: ['./edit-employee-names-declensions.component.css'],
  providers: [EditEmployeeNamesDeclensionsComponentDataSourceService],
})
export class EditEmployeeNamesDeclensionsComponent implements OnInit, OnDestroy {

  @Input() public readonly employeeId: number;
  private _employeeFullName: string;
  @Input() public set employeeFullName(value: string) {
    this._employeeFullName = value;
    this.reloadData();
  };
  public form: FormGroup<EditEmployeeNamesDeclensionsComponentForm>;
  private streams$ = { unsubscribes: new ReplaySubject<any>(1) };

  constructor(private readonly dataSourceService: EditEmployeeNamesDeclensionsComponentDataSourceService) {
  }

  ngOnInit(): void {
    this.validateInit();

    this.form = this.createForm();

    this.dataSourceService.dataSource.data$
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe(({ id, timestamp, nominative, accusative, dative, genitive, instrumental, prepositional }) => {
        this.form.controls.id.setValue(id);
        this.form.controls.timestamp.setValue(timestamp);
        this.form.controls.declensions.setValue({ nominative, accusative, dative, genitive, instrumental, prepositional });
        this.form.markAsUntouched();
      });
  }

  public onClickSave() {
    this.dataSourceService.save$(this.form.value as SaveEmployeeDeclensionDTO)
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe(timestamp => {
        this.form.controls.timestamp.setValue(timestamp);
        this.form.markAsUntouched();
      });
  }

  private validateInit() {
    if (!this.employeeId) {
      throw new Error('[employeeId] обязателен к заполнению');
    }

    if (!this._employeeFullName) {
      throw new Error('[employeeFullName] обязателен к заполнению');
    }
  }

  private createForm() {
    return new FormGroup({
      id: new FormControl(null),
      timestamp: new FormControl(null),
      declensions: new FormControl(null),
    });
  }

  private reloadData() {
    this.dataSourceService
      .reloadData$({ employeeId: this.employeeId, employeeFullName: this._employeeFullName })
      .pipe(takeUntil(this.streams$.unsubscribes))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
