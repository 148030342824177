<div class="error-div" *ngIf="formControl?.errors?.required">Значение не может быть пустым</div>
<div class="error-div" *ngIf="formControl?.errors?.multipleOf">Значение должно быть кратно {{formControl?.errors?.multipleOf.multiplier}}. Ближайшие доступные значения: {{formControl?.errors?.multipleOf.nearestMin}} и {{formControl?.errors?.multipleOf.nearestMax}}</div>

@if ((valueType$ | async) === 'date'){
  <div class="error-div" *ngIf="formControl?.errors?.minError">Минимально допустимое значение {{ formControl?.errors?.minError.minValue | kendoDate: 'dd.MM.yyyy HH:mm' }}</div>
  <div class="error-div" *ngIf="formControl?.errors?.maxError">Максимально допустимое значение {{ formControl?.errors?.maxError.maxValue | kendoDate: 'dd.MM.yyyy HH:mm' }}</div>
} @else {
  <div class="error-div" *ngIf="formControl?.errors?.minError">Минимально допустимое значение {{ formControl?.errors?.minError.minValue }}</div>
  <div class="error-div" *ngIf="formControl?.errors?.maxError">Максимально допустимое значение {{ formControl?.errors?.maxError.maxValue }}</div>
}

<div class="error-div" *ngIf="formControl?.errors?.minlength">Длина значения должна быть больше {{formControl?.errors?.minlength.requiredLength}}. Текущая длина: {{formControl?.errors?.minlength.actualLength}}</div>
<div class="error-div" *ngIf="formControl?.errors?.maxlength">Длина значения должна быть меньше {{formControl?.errors?.maxlength.requiredLength}}. Текущая длина: {{formControl?.errors?.maxlength.actualLength}}</div>
<div class="error-div" *ngIf="formControl?.errors?.invalidData">Неправильное значение</div>
