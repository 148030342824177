import { DrawerItemCustom, WorkspaceServiceBase } from "../../../../src/app/components/workspace/workspace.service";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class StafflistWorkspaceService extends WorkspaceServiceBase {
  public programName = 'Управление персоналом и штатное расписание'

  getItems$(basePath: string, userFunctions: string[], companyName: string): Observable<DrawerItemCustom[]> {
    const items: Array<DrawerItemCustom> = [
      { text: 'Штатное расписание', icon: 'table', routPath: basePath + 'stafflist' },
      { text: 'Справочники', icon: 'dictionary-add', routPath: basePath + 'directories' },
      { text: 'Печать', icon: 'print', routPath: basePath + 'print' },
    ];

    return of(items);
  }
}
