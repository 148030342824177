import { Injectable, OnDestroy } from "@angular/core";
import { DialogService, DialogAction, DialogRef } from "@progress/kendo-angular-dialog";
import { combineLatest, map, Observable, ReplaySubject, take, takeUntil } from 'rxjs';
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { DateHelper } from "src/app/helpers/dateHelper";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { KendoNotificationService } from "src/app/services/kendo-notification.service";
import { AddPositionItem } from "src/app/services/webApi/webApi1/controllers/api1-position-controller.service";
import { StaffPositionGridItem } from "src/app/services/webApi/webApi1/controllers/api1-staff-positions-control.service";
import { AddPositionRateFormComponent } from "../../../position-rates/add/add-position-rate-form.component";
import { AddPositionFormComponent, AddPositionFormComponent_Data, AddPositionFormComponent_DataSourceService } from "../../add/add-position-form.component";
import { ForDate } from "src/app/classes/for-date";
import { IPositionRate } from "src/app/classes/domain/POCOs/stafflist/PositionRate";
import { FinancingSourcesColumnsInfo, PositionsGridComponent } from "../positions-grid.component";


@traceClass('PositionsGridComponentService')
@Injectable()
export class PositionsGridComponentService implements OnDestroy {


  private streams$ = { unsubscribes: new ReplaySubject<any>(1) }

  constructor(private readonly dialogService: DialogService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase) {
  }

  /** Расчёт ширины колонки */
  public calculateStaffUnitColumnWidth(columnsInfos: FinancingSourcesColumnsInfo[], minStaffUnitsColumnSize: number, minStaffUnitsColumnGroupSize: number) {
    const columnsCount = columnsInfos.length + 1;
    const calculatedSize = minStaffUnitsColumnSize * columnsCount;
    return calculatedSize >= minStaffUnitsColumnGroupSize ? calculatedSize / columnsCount : minStaffUnitsColumnGroupSize;
  }

  /** Преобразование информации об источниках финансирования, свободных и занятых ставках в массив */
  public getFinancingSourcesColumnsInfos(data: StaffPositionGridItem[]): FinancingSourcesColumnsInfo[]{
    if (!data) return [];

    const allRates = data.reduce((all, u) => [...all, ...u.staffPositionRates], []);
    const allFreeRates = data.reduce((all, u) => [...all, ...u.staffPositionFreeRates], []);

    const uniqFinancingSources = [...allRates, ...allFreeRates]
      .filter(
        (thing, i, arr) => arr.findIndex(t => t.financingSourceId === thing.financingSourceId) === i
      );

    return uniqFinancingSources.map(fs =>
      new FinancingSourcesColumnsInfo(
        fs.financingSourceId,
        fs.financingSourceShortName,
        allRates.findIndex(r => fs.financingSourceId === r.financingSourceId && r.rate > 0) >= 0,
        allFreeRates.findIndex(r => fs.financingSourceId === r.financingSourceId && r.rate !== 0) >= 0
      )
    );
  }

  /** Функция получения строки отображения ставок */
  public getRatesDisplayValue(rates: Pick<IPositionRate, "rate" | "financingSourceId">[], financingSourceId: number): string | number {
    const spr = rates.find(f => f.financingSourceId === financingSourceId);
    return (spr ? spr.rate : 0) ?? '';
  }


  /** Показать диалог Добавление штатные единицы */
  @traceFunc()
  public showAddPositionRateDialog(component: PositionsGridComponent, financingSourceId: number, dataItem: StaffPositionGridItem): DialogRef {
    const addPositionRateDialogRef = this.dialogService.open({
      title: `Добавление штатные единицы`,
      content: AddPositionRateFormComponent,
      width: "90%",
    });

    const addFormComponent = addPositionRateDialogRef.content.instance as AddPositionRateFormComponent;
    addFormComponent.dataItem = { positionId: dataItem.id, financingSourceId: financingSourceId };
    addFormComponent.isShowEditFinancingSource = false;
    addFormComponent.minDate = dataItem.startDate ?? dataItem.subdivision.startDate;
    addFormComponent.maxDate = dataItem.endDate ?? dataItem.subdivision.endDate;
    addFormComponent.rateStep = component.rateStep;

    addFormComponent.onSaveEventEmitter.subscribe(entity =>
      component.dataSource.addPositionRate$(entity)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
          next: data => {
            if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSource.paramsDataSource.data.startDate, component.dataSource.paramsDataSource.data.endDate)) {
              this.kendoNotificationService.showSuccess({ content: "Штатная единица добавлена" });
            } else {
              this.kendoNotificationService.showSuccess({ content: "Штатная единица добавлена, но она не входит в диапазон выборки дат отчёта, поэтому она не будет отображена.", hideAfter: 7000 });
            }
            addPositionRateDialogRef?.close();
          }, error: error => this.displayErrorsService.handleError(error)
        })
    );

    addFormComponent.onCancelEventEmitter.subscribe(() => addPositionRateDialogRef?.close());

    //Закрываем диалоговое окно если в датасорсе изменился текущий выбранный элемент, или что-то в самом элементе (например его изменил сигналр)
    component.selection.selectedItems2.change$
      .pipe(
        take(1),
        takeUntil(addPositionRateDialogRef.result)
      ).subscribe(() => addPositionRateDialogRef.close());

    return addPositionRateDialogRef;
  }

  /** Показать диалог Добавление штатной единицы */
  @traceFunc()
  public showAddPositionDialog(component: PositionsGridComponent): DialogRef {
    const addStaffPositionDialogRef = this.dialogService.open({
      title: `Добавление штатной единицы`,

      content: AddPositionFormComponent,
      width: "90%",
      maxHeight: "90%"
    });

    const addDataSource = new AddPositionFormComponent_DataSourceService();

    combineLatest([
      component.subdivisionDataSourceService$,
      component.occupationDataSourceService$,
      component.workModeDataSourceService$,
      component.reportPeriodDataSourceService$,
      component.financingSourceDataSourceService$
    ]).pipe(
      takeUntil(addStaffPositionDialogRef.result),
      takeUntil(this.streams$.unsubscribes)
      )
    .subscribe( ([subdivisions, occupations, workModes, reportPeriods, financingSources]) => {
      subdivisions.reloadData$(component.dataSource.paramsDataSource.data$.pipe(map(m=> {
        return {
          ...ForDate.Copy(m),
          subdivisionIds: [m.subdivisionId]
        }
      }))).subscribe();

      addDataSource.setData(new AddPositionFormComponent_Data(
        subdivisions,
        occupations,
        workModes,
        reportPeriods,
        financingSources
      ));
    });

    const addFormComponent = addStaffPositionDialogRef.content.instance as AddPositionFormComponent;
    addFormComponent.dataSource = addDataSource;

    addFormComponent.onSaveEventEmitter.subscribe((entity: AddPositionItem) =>
      component.dataSource.addPosition$(entity)
        .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
          next: data => {
            if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSource.paramsDataSource.data.startDate, component.dataSource.paramsDataSource.data.endDate)) {
              this.kendoNotificationService.showSuccess({ content: "Штатная единица добавлена" });
            } else {
              this.kendoNotificationService.showSuccess({ content: "Штатная единица добавлена, но она не входит в диапазон выборки дат отчёта, поэтому она не будет отображена.", hideAfter: 7000 });
            }
            addStaffPositionDialogRef?.close()
          }, error: error => this.displayErrorsService.handleError(error)
        })
    );

    addFormComponent.onCancelEventEmitter.subscribe(() => addStaffPositionDialogRef?.close());

    return addStaffPositionDialogRef;
  }

  /** Показать диалог удаления штатной единицы */
  @traceFunc()
  public showRemovePositionDialog(deletePosition$: () => Observable<any>) {
    const removeDialogRef = this.dialogService.open({
      title: 'Подтвердите удаление',
      content: 'Вы уверены, что хотите удалить выбранную штатную единицу?\nДальнейшее восстановление будет не возможно!',
      actions: [
        { text: 'Нет' },
        { text: 'Да, удалить', themeColor: 'primary' }
      ],
      width: 450,
      height: 200,
      minWidth: 250
    });

    removeDialogRef.result.subscribe((result: DialogAction) => {
      if (result.themeColor === 'primary') {
        deletePosition$()
          .pipe(trace(this.tracerService), take(1), takeUntil(this.streams$.unsubscribes)).subscribe({
            error: error => this.displayErrorsService.handleError(error)
          });
      }
      removeDialogRef?.close()
    });

    return removeDialogRef;
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }
}
