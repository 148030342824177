import {Component, Input, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {
  SubdivisionsTreelistComponentDataSourceServiceBase
} from "../subdivisions-treelist/services/subdivisions-treelist-component-data.service";
import {traceFunc} from "../../../modules/trace/decorators/func.decorator";
import {Observable, of, ReplaySubject, share} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {ITreeViewItem, TreeViewItem} from "../../../classes/requestResults/iTreeViewItem";
import {TracerServiceBase} from "../../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../../modules/trace/decorators/class.decorator";
import {CheckableSettings} from "@progress/kendo-angular-treeview";
import {ISubdivision} from "../../../classes/domain/POCOs/stafflist/Subdivision";
import {DeferSelectionService} from "../../../services/defer-selection-services/defer-selection.service";
import {
  KendoTreeViewArrayDataSourceSelection
} from "../../../classes/array-data-sources/selections/kendo-treeview-array-data-source-selection";
import {ArrayDataSourceSelection} from "../../../classes/array-data-sources/selections/array-data-source-selection";

@Component({
  selector: 'app-subdivision-treeview2',
  templateUrl: './subdivision-treeview2.component.html',
  styleUrls: ['./subdivision-treeview2.component.css'],
  providers: [DeferSelectionService]
})
@traceClass('SubdivisionTreeview2Component')
export class SubdivisionTreeview2Component implements OnInit, OnDestroy {

  /** Источник данных для компонента */
  public dataSource$: Observable<ITreeViewItem[]>;

  private unsubscribe$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /** разворачивание списка по умолчанию */
  @Input() public expandAll: boolean = true;
  public expandedKeys: number[] = [];

  private _dataSourceService: SubdivisionsTreelistComponentDataSourceServiceBase<any>;
  @Input() public get dataSourceService(){
    return this._dataSourceService;
  }
  public set dataSourceService(value){
    this._dataSourceService = value;
    this.setDataSource();
    this.selectionService.originSelection = value?.dataSource;
  }

  private _selection: ArrayDataSourceSelection<ISubdivision, number>;
  /** Слежение за выделенными строками */
  @Input() get selection(){
    return this._selection;
  }
  set selection(value){
    this._selection = value;
    this.selectionService.originSelection = value;
  }

  private _checkableSettings: CheckableSettings;
  // Настройки TreeView
  @Input()
  public get checkableSettings(): CheckableSettings {
    return this._checkableSettings;
  }
  public set checkableSettings(value: CheckableSettings){
    this._checkableSettings = value;
  }

  /** Функция формирования отображаемой в treeView строк из объекта ISubdivision */
  private _displayTextFn: (subdivision: ISubdivision) => string;
  @Input()public get displayTextFn(){
    return  this._displayTextFn ? this._displayTextFn : x => x.longName;
  }
  public set displayTextFn(value){
    this._displayTextFn = value;
    this.setDataSource();
  }

  // Массив выбранных подразделений
  @Output() public checkedSubdivisions$: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

  constructor(private readonly traceService: TracerServiceBase,
              public readonly selectionService: DeferSelectionService<SubdivisionsTreelistComponentDataSourceServiceBase<any>['dataSource'], KendoTreeViewArrayDataSourceSelection<ISubdivision, number>>) {
    this.selectionService.tempCtorFunc = dataSource => new KendoTreeViewArrayDataSourceSelection<ISubdivision, number>(dataSource);
    this.selectionService.isDeferApply = false;
  }


  @traceFunc()
  public ngOnInit(): void {
    this.selectionService.tempSelection.data$
      .pipe(map(value => !!value ? value.selectedIds.data$ : of([])))
      .subscribe(items$ => {
        items$.subscribe(items => {
          this.checkedSubdivisions$.emit(items);
        });
      });
  }


  /** Устанавливает источник данных для таблицы */
  @traceFunc()
  private setDataSource(){
    if(!this._dataSourceService){
      this.dataSource$ = undefined;
      return;
    }

    this.dataSource$ = this._dataSourceService.dataSource.data$
      .pipe(map(value => {
        this.expandAll = this.expandAll === undefined || this.expandAll;

        if (this.expandAll){
          this.expandedKeys = value.map(x => x.id);
        }

        let source = value.map(x => new TreeViewItem(x.id, x.parentId, this.displayTextFn(x), new Array<TreeViewItem>()));
        let parentItems = source.filter(x => !x.parentId);
        parentItems.forEach(x => x.FillDateItems(source));
        return parentItems;
      }),
        share(),
        takeUntil(this.unsubscribe$)
      );
  }

  public ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
