import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { NumberFieldControl_Settings } from "../../services/classes/field-controls/number-field-control";
import { INumberDirectoryEditComponent } from "./i-number-directory-edit-control.component";
import { NumberDirectoryEditComponentService } from "./services/number-directory-edit-control-component.service";

@Component({
  selector: 'app-number-directory-edit-control',
  templateUrl: './number-directory-edit-control.component.html',
  styleUrls: ['./number-directory-edit-control.component.css'],
  providers: [NumberDirectoryEditComponentService]
})
@traceClass('NumberDirectoryEditComponent')
export class NumberDirectoryEditComponent implements OnInit, INumberDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: NumberFieldControl_Settings;

  public minValue$: Observable<number>;
  public maxValue$: Observable<number>;

  constructor(private service: NumberDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
    this.service.addValidators();
  }
}
