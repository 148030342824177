import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import {ReplaySubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

/** Тип значения в контроле */
type ValueType = 'any' | 'date';

@Component({
  selector: 'app-display-validation-errors',
  templateUrl: './display-validation-errors.component.html',
  styleUrls: ['./display-validation-errors.component.css'],
})
export class DisplayValidationErrorsComponent implements OnDestroy{
  private streams$ = {
    /** Стрим вызова {@link formControl} */
    formControlSet: new Subject<FormControl>(),
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Стрим типа значения {@link formControl}.*/
  public valueType$: ReplaySubject<ValueType> = new ReplaySubject(1);

  private _formControl : FormControl;
  /** Контрол */
  public get formControl(){
    return this._formControl;
  }
  @Input("control") public set formControl(value: FormControl) {
    this._formControl = value;
    this.streams$.formControlSet.next(value);
    this.valueType$.next(this.getValueType(value?.value));

    if(value){ //Подпись на изменение значения
      value.valueChanges
        .pipe(
          takeUntil(this.streams$.formControlSet),
          takeUntil(this.streams$.unsubscribe),
        )
        .subscribe(x => this.valueType$.next(this.getValueType(x)));
    }
  };

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.formControlSet.complete();
    this.valueType$.complete();
  }

  /** Получить тип {@link FormControl.value} */
  private getValueType(value: any): ValueType{
    if(value instanceof Date){
      return 'date';
    } else {
      return 'any';
    }
  }
}
