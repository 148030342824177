<form class="k-form" [formGroup]="form">

  <div class="form-start-end-date-div">
    <kendo-formfield>
      <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
      <kendo-datepicker #startDate formControlName="startDate" [min]="startDateMin$ | async" [max]="form.controls.endDate.value || selectedSubdivision.data?.endDate"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение
        родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
      <kendo-datepicker #endDate formControlName="endDate" [min]="form.controls.startDate.value" [max]="endDateMax$ | async"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение
        родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
    </kendo-formfield>
  </div>

  <div>
    <kendo-formfield *ngIf="!form.value.isAutogenerateCode">
      <kendo-label [for]="code" text="Код подразделения"></kendo-label>
      <input kendoTextBox inputTrim #code formControlName="code"/>
      <kendo-formerror *ngIf="form.controls.code.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.code.errors?.minlength">Длина значения должна быть больше {{form.controls.code.errors?.minlength.requiredLength}}. Текущая
        длина: {{form.controls.code.errors?.minlength.actualLength}}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.code.errors?.maxlength">Длина значения должна быть меньше {{form.controls.code.errors?.maxlength.requiredLength}}. Текущая
        длина: {{form.controls.code.errors?.maxlength.actualLength}}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield class="horizontal-form-field">
      <kendo-label [for]="isAutogenerateCode" text="Генерировать код подразделения автоматически:"></kendo-label>
      <input type="checkbox" kendoCheckBox #isAutogenerateCode formControlName="isAutogenerateCode" (change)="form.controls.code.updateValueAndValidity();"/>
    </kendo-formfield>
  </div>


  <kendo-formfield>
    <kendo-label [for]="longName" text="Наименование подразделения "></kendo-label>
    <input kendoTextBox inputTrim #longName formControlName="longName"/>
    <kendo-formerror *ngIf="form.controls.longName.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.longName.errors?.minlength">Длина значения должна быть больше {{form.controls.longName.errors?.minlength.requiredLength}}. Текущая
      длина: {{form.controls.longName.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.longName.errors?.maxlength">Длина значения должна быть меньше {{form.controls.longName.errors?.maxlength.requiredLength}}. Текущая
      длина: {{form.controls.longName.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="shortName" text="Сокращённое наименование подразделения"></kendo-label>
    <input kendoTextBox inputTrim #shortName formControlName="shortName"/>
    <kendo-formerror *ngIf="form.controls.shortName.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.shortName.errors?.minlength">Длина значения должна быть больше {{form.controls.shortName.errors?.minlength.requiredLength}}. Текущая
      длина: {{form.controls.shortName.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.shortName.errors?.maxlength">Длина значения должна быть меньше {{form.controls.shortName.errors?.maxlength.requiredLength}}. Текущая
      длина: {{form.controls.shortName.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="parentId" text="Входит в состав подразделения"></kendo-label>
    <kendo-dropdowntree #parentId
                        kendoDropDownTreeExpandable
                        [kendoDropDownTreeFlatBinding]="subdivisions$ | async"
                        parentIdField="parentId"
                        valueField="id"
                        textField="longName"
                        formControlName="parentId"
                        [valuePrimitive]="true"
                        [popupSettings]="{ width:'auto' }"
                        [dataItem]="selectedSubdivision.data"
                        [filterable]="true"
                        [(expandedKeys)]="expandedNodeIndices"
                        expandBy="id"
    >
    </kendo-dropdowntree>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment formControlName="comment"/>
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая
      длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая
      длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>
