import { Component, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { FormControl } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { FieldType } from "../services/classes/fields/field-type";
import { IDirectoryEditControlComponent } from "./i-directory-edit-control.component";
import { DirectoryEditControlComponentService } from "./service/directory-edit-control-component.service";

@Component({
  selector: 'app-directory-edit-control',
  templateUrl: './directory-edit-control.component.html',
  styleUrls: ['./directory-edit-control.component.css'],
  providers: [DirectoryEditControlComponentService]
})
@traceClass('DirectoryEditControlComponent')
export class DirectoryEditControlComponent implements IDirectoryEditControlComponent, OnInit{

  @Input("field") public field: FieldType;
  @Input("valueFormControl") public formControl: FormControl;
  @Input("appendContainer") public appendContainer: ViewContainerRef;

  public customTemplateRef: TemplateRef<any>;
  public componentTypeString: string;

  public fieldControlSettings: any;

  public constructor(private readonly service: DirectoryEditControlComponentService, private readonly tracer: TracerServiceBase) {
    this.service.component = this;
  }

  @traceFunc()
  ngOnInit(): void {

    this.fieldControlSettings = this.field.control.settings instanceof Function ? this.field.control.settings() : this.field.control.settings;

    this.service.validateInputs();
    this.service.setComponentTypeString();
    this.service.registerValidators();
  }
}
