/** Класс данных передаваемых серверу при авторизации */
export class AuthServerRequest {
  /**
   * Конструктор
   * @param company Компания
   * @param program Программа в которой авторизовались
   * @param login Логин пользователя
   * @param password Пароль пользователя
   */
  public constructor(
    public company: string,
    public program: string,
    public login: string,
    public password: string,
  ) {
  }
}

/** Класс ответа сервера авторизации */
export class AuthServerResponse {
  /**
   * Конструктор
   * @param token Токен авторизации
   * @param authUser Данные пользователя
   */
  public constructor(
    public token: string,
    public authUser: string
  ) {
  }

  /** Копировать */
  public static Copy(source: AuthServerResponse){
    return !source ? null : new AuthServerResponse(source.token, source.authUser);
  }
}
