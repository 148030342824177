import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { delay, finalize, forkJoin, map, Observable, of, startWith, switchMap, take, tap } from "rxjs";
import { IEntityId } from "../classes/domain/POCOs/interfaces/IEntityId";

/** Общий класс с кастомными валидацторами
 * Содержит только общие валидаторы
 */
export class CustomFormValidators{


  /** Валидатор позволяет проверить что значение НЕ равно переданному */
  public static notEqual(value: any): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const controlValue = control.value;

      return value ===  controlValue ? {notEqual:{
        //значение, которому должно быть кратно
        notEqual: value
      }}: null;
    }
  }

  /** Валидатор позволяет проверить кратно ли значение заданному в multiplierValue */
  public static multipleOf(multiplierValue: number): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
          return null;
      }

      const divisionRemainder = value % multiplierValue;
      const isMultipleOf = divisionRemainder < 0.000001 || (multiplierValue - divisionRemainder) < 0.000001;

      return !isMultipleOf ? {multipleOf:{
        //значение, которому должно быть кратно
        multiplier: multiplierValue,

        //текущее значение
        current: value,

        //ближайшее меньшее значение
        nearestMin: value-divisionRemainder,

        //ближайшее большее значение
        nearestMax: value-divisionRemainder+multiplierValue
      }}: null;
    }
  }



  /** Валидатор позволяет проверить то, что дата начала должна быть раньше чем дата окончания согласно указанному контролу даты окончания endDateControlName*/
  public static validateStartDateWithEndDate(endDateControlName: string = 'endDate'): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
          return null;
      }

      const endDateControl = control.parent?.get(endDateControlName);

      if(control.value && endDateControl?.value )
      {
        if(control.value > endDateControl.value ) {
          return {startDateGreaterThanEndDate: true};
        }
      }
      return null;
    }
  }

  /** Валидатор позволяет проверить то, что дата окончания должна быть позже чем дата начала согласно указанному контролу даты начала startDateControlName*/
  public static validateEndDateWithStartDate(startDateControlName: string = 'startDate'): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
          return null;
      }

      const startDateControl = control.parent?.get(startDateControlName);

      if(control.value && startDateControl?.value )
      {
        if(control.value < startDateControl.value ) {
          return {endDateLowerThanStartDate: true};
        }
      }
      return null;
    }
  }


  /** Валидатор добавляет валидатор Required если выполняется условие expression */
  public static requiredIf(expression: () => boolean) : ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      if(expression()) {
        return Validators.required(control);
      }
        return null;
    }
  }

  /** Валидатор добавляет валидатор переданный в функцию validator  если выполняется условие expression
   * Следует помнить, что функция проверки условия: expression() и валидатора validator() выполняются
   * при любом изменении значения, это позволяет делать гибкие условия валидации
   *  в зависимости от того выполняется ли условие expression() или нет
  */
  public static withExpression(expression: () => boolean, validator: () => ValidatorFn) : ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      if(expression()) {
        return validator()(control);
      }
        return null;
    }
  }


  /** Валидатор позволяет проверить то, значение даты больше (или равно) минимально переданного значения*/
  public static minDate(minValue: Date): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
          return null;
      }

      if(control.value)
      {
        if(control.value < minValue) {
          return {minDate: {minValue: minValue}};
        }
      }
      return null;
    }
  }

  /** Валидатор позволяет проверить то, что значение даты меньше (или равно) максимального переданного значения*/
  public static maxDate(maxValue: Date): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
          return null;
      }

      if(control.value)
      {
        if(control.value > maxValue) {
          return {maxDate: {maxValue: maxValue}};
        }
      }
      return null;
    }
  }
}
