import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit} from "@angular/core";
import {ReplaySubject, Subject} from "rxjs";
import {ArrayDataSourceSelection} from "../../classes/array-data-sources/selections/array-data-source-selection";
import {ArrayHelper} from "../../helpers/arrayHelper";
import {DropDownListComponent} from "@progress/kendo-angular-dropdowns";
import {filter, map, takeUntil} from "rxjs/operators";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../modules/trace/decorators/func.decorator";

/** Тип управляющего выделением */
type SelectionType = ArrayDataSourceSelection<any, any>;

/**
 * Директива управляет выделенной строкой в {@link DropDownListComponent} при помощи переданного {@link ArrayDataSourceSelection}.<br>
 * @example
 * <kendo-dropdownlist
 *    [kendoDropDownListDataSourceSelection]="selection"
 *    >
 */
@Directive({
  selector: '[kendoDropDownListDataSourceSelection]'
})
@traceClass('KendoDropDownListDataSourceSelectionDirective')
export class KendoDropDownListDataSourceSelectionDirective implements OnInit, OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    selectionChange: new Subject<SelectionType>(),
  };

  private _selection: SelectionType;
  /** Управляющий выделением {@link ArrayDataSourceSelection} */
  @Input('kendoDropDownListDataSourceSelection') set selection(value: SelectionType) {
    this.streams$.selectionChange.next(value);
    this._selection = value;
    this.init();
  }

  /** Идентификатор выбранный в этот момент */
  private _selectedId: any = undefined;

  constructor(private readonly dropDownListComponent: DropDownListComponent,
              private readonly cdr: ChangeDetectorRef,
              private readonly traceService: TracerServiceBase) {
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {

  }

  /** Инициализировать */
  @traceFunc()
  private init(){
    this._selectedId = undefined;

    //Обработка изменения в выборщике
    if(this._selection){
      this._selection.selectedIds.data$
        .pipe(
          map(value => ArrayHelper.firstOrDefault(value, undefined)),
          filter(value => this._selectedId !== value), //Только измененные
          takeUntil(this.streams$.selectionChange),
          takeUntil(this.streams$.unsubscribe),
        ).subscribe(value => {
          this._selectedId = value;
          this.dropDownListComponent.value = this._selection.dataSource.getItemById(value);
          this.cdr.markForCheck();
      })
    }

    //Обработка изменения пользователем
    if(this._selection){
      this.dropDownListComponent.selectionChange
        .pipe(
          map(value => this._idGetter(value)), //Получаем идентификатор строки
          filter(value => this._selectedId !== value), //Только измененные
          takeUntil(this.streams$.selectionChange),
          takeUntil(this.streams$.unsubscribe)
        )
        .subscribe(value => {
          this._selectedId = value;
          this._selection.setIds([value]);
          this.cdr.markForCheck();
        })
    }
  }


  /** Безопасно получить идентификатор. Защита от !item */
  private _idGetter(item: any){
    if(item === undefined || item === null){
      return undefined;
    }

    return this._selection.dataSource.idGetter(item);
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.selectionChange.complete();
  }
}
