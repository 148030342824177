import { Injectable } from '@angular/core';
import { WebApi1Service } from '../web-api1.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {IEmployee} from 'src/app/classes/domain/POCOs/stafflist/Employee';
import { EmployeeExtendedDataResult } from 'src/app/classes/extendedDatas/employeeExtended/employee-extended-data-result';
import { IExtendedData } from 'src/app/classes/extendedDatas/extended-data';
import { PassportData } from 'src/app/classes/extendedDatas/employeeExtended/passport-data';
import { InnData } from 'src/app/classes/extendedDatas/employeeExtended/inn-data';
import { SnilsData } from 'src/app/classes/extendedDatas/employeeExtended/snils-data';
import { AddressData } from 'src/app/classes/extendedDatas/employeeExtended/address-data';
import { PhoneData } from 'src/app/classes/extendedDatas/employeeExtended/phone-data';
import { DateOnly } from '../../../../classes/Dates/date-onlys/date-only';
import { GenderEnum } from '../../../../classes/domain/enums/gender-enum';

@Injectable({
  providedIn: 'root',
})
export class Api1EmployersGridControlControllerService {

  constructor(private webApi1Service: WebApi1Service,
              private httpClient: HttpClient) {
  }

  /**
   * Получить Employers
   */
  public getForDate$(date: Date = null, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null): Observable<Array<EmployeeItem>> {
    return this.httpClient
      .post<IGetForDateResponse>(this.webApi1Service.controllers.employersGridControl.actions.getForDate.toString(),
        {
          date: date,
          startDate: startDate,
          endDate: endDate,
          ownerIds: ownerIds,
        }).pipe(map(data => data.employers.map(employee => {
          const extendedData = data.extendedDatas.find(f => f.employeeId === employee.id);

          return new EmployeeItem(
            employee.id,
            employee.startDate,
            employee.endDate,
            employee.timestamp,
            employee.comment,
            employee.lastName,
            employee.firstName,
            employee.patronymic,
            employee.code,
            employee.profUnion,
            employee.gender,
            employee.birthday,
            employee.isWork,
            extendedData?.passports,
            extendedData?.inn,
            extendedData?.snils,
            extendedData?.phone,
            extendedData?.address,
          );
        },
      )));
  }
}

interface IGetForDateResponse {
  extendedDatas: Array<EmployeeExtendedDataResult>;
  employers: Array<IEmployeeDTO>;
}

interface IEmployeeDTO extends Pick<IEmployee, 'id' | 'startDate' | 'endDate' | 'timestamp' | 'comment' | 'lastName' | 'firstName' | 'patronymic' | 'code' | 'gender' | 'birthday' | 'profUnion'> {
  isWork: boolean;
}

export class EmployeeItem implements Pick<IEmployee, 'id' | 'startDate' | 'endDate' | 'timestamp' | 'comment' | 'lastName' | 'firstName' | 'patronymic' | 'code' | 'gender' | 'birthday' | 'profUnion'> {
  constructor(
    public id: number,
    public startDate: Date,
    public endDate: Date,
    public timestamp: [],
    public comment: string,
    public lastName: string,
    public firstName: string,
    public patronymic: string,
    public code: string,
    public profUnion: boolean,
    public gender: GenderEnum,
    public birthday: DateOnly,
    public isWork: boolean,
    public passports: Array<IExtendedData<PassportData>>,
    public inn: IExtendedData<InnData>,
    public snils: IExtendedData<SnilsData>,
    public phone: IExtendedData<PhoneData>,
    public address: IExtendedData<AddressData>,
  ) {
  }
}
