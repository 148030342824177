import {IEntityId} from "../../interfaces/IEntityId";
import {IEntityRowVersion} from "../../interfaces/IEntityRowVersion";
import {IEntityModify} from "../../interfaces/IEntityModify";
import {IEntityDeletedFlag} from "../../interfaces/IEntityDeletedFlag";
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface IPlanRowValue extends IEntityId, IEntityModify, IEntityRowVersion, IEntityDeletedFlag{
  /** Идентификатор строки */
  rowId: number;

  /** Идентификатор источника финансирования */
  financingSourceId: number;

  /** Идентификатор типа мед */
  medicalCareTypeId: number;

  /** Значение */
  value: number;
}

@classBackend('PlanRowValue', 'pfhd_plan')
@className('PlanRowValue')
export class PlanRowValue implements IPlanRowValue{
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public timestamp: [],
              public rowId: number,
              public financingSourceId: number,
              public medicalCareTypeId: number,
              public value: number,) {
  }

  /** Максимально допустимое количество знаков после запятой для {@link value} */
  public static readonly ValueDigits = 2;
}
