import {  Observable } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { IStaffUnitType } from "src/app/classes/domain/POCOs/stafflist/StaffUnitType";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import {
  Api1StaffUnitTypeControllerService
} from "../webApi/webApi1/controllers/api1-staff-unit-type-controller.service";
import {Injectable} from "@angular/core";

export class StaffUnitTypeDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<any, IStaffUnitType>{

  readonly paramsDataSource = new DataSource<any>();
  readonly dataSource = new ArrayDataSourceIEntityId<IStaffUnitType>();

  constructor(private readonly parentDataSourceService: IStaffUnitTypeDataSourceService_ParentDataSourceService) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    //Это енам, он не меняется, поэтому signalr нет
   return null;
  }

  protected _reloadData$(params: any): Observable<IStaffUnitType[]> {
    return this._reloadFromRemoteByIds$(params);
  }

  protected _reloadFromRemoteByIds$(params: any): Observable<IStaffUnitType[]> {
    return this.parentDataSourceService.getStaffUnitTypes$();
  }
}


export interface IStaffUnitTypeDataSourceService_ParentDataSourceService {
  getStaffUnitTypes$(): Observable<IStaffUnitType[]>;
}


/** Тип параметров */
type Param2 = {
  ids: number[] | undefined,
  deletedFlag: boolean | undefined
}

/** Сервис для получения типов исполнения должностей {@link IStaffUnitType} */
@Injectable()
export class StaffUnitTypeDataSourceService2 extends ArrayDataSourceIEntityIdServiceWithParamsBase<Param2, IStaffUnitType> {
  /** @inheritDoc */
  public paramsDataSource: DataSource<Param2> = new DataSource<Param2>();
  /** @inheritDoc */
  public dataSource: ArrayDataSourceIEntityId<IStaffUnitType> = new ArrayDataSourceIEntityId<IStaffUnitType>();

  /** Конструктор */
  constructor(private readonly api1StaffUnitTypeControllerService: Api1StaffUnitTypeControllerService) {
    super();
  }

  /** @inheritDoc */
  protected useSignalR$(): Observable<Observable<any>> | null {
    return undefined; //Является enum. signalR НЕ требуется
  }

  /** @inheritDoc */
  protected _reloadData$(params: Param2): Observable<IStaffUnitType[]> {
    return this.api1StaffUnitTypeControllerService.get$({
      ids: params.ids,
      deletedFlag: params.deletedFlag
    });
  }

  /** @inheritDoc */
  protected _reloadFromRemoteByIds$(params: Param2, targets: number[]): Observable<IStaffUnitType[]> {
    throw new Error('Перегрузка по id НЕ поддерживается');
  }
}
