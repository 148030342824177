import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AlertButton, AlertService, AlertType} from "../../services/alert.service";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {

  constructor(public alertService: AlertService) { }

  /** @inheritDoc */
  public ngOnInit() {
  }

  /** Обработка действий окна */
  public onAction(button: AlertButton){
    if(button?.callBack){
      button.callBack();
    }

    this.alertService.removeLast();
  }

  /** Обработка закрытия окна не через кнопки действия */
  public onClose(){
    const cancelButton = (this.alertService.currentOption.buttons ?? [])
      .find(x => x.isCancel);

    this.onAction(cancelButton);
  }
}
