import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { MaskedTextBoxFieldControl_Settings } from "../../services/classes/field-controls/maskedtextbox-field-control";
import { IMaskedTextBoxDirectoryEditComponent } from "./i-masked-textbox-directory-edit-control.component";

@Component({
  selector: 'app-masked-textbox-directory-edit-control',
  templateUrl: './masked-textbox-directory-edit-control.component.html',
  styleUrls: ['./masked-textbox-directory-edit-control.component.css']
})
@traceClass('MaskedTextBoxDirectoryEditComponent')
export class MaskedTextBoxDirectoryEditComponent implements OnInit, IMaskedTextBoxDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: MaskedTextBoxFieldControl_Settings;


  constructor(private traceService: TracerServiceBase) {
  }

  @traceFunc()
  public ngOnInit(): void {
  }
}
