<div class="wrap"
     [formGroup]="form">
  <kendo-label class="k-checkbox-label"
         [for]="reportDate"
         text="Дата: "
  ></kendo-label>
  <kendo-datepicker
    #reportDate
    style="width: 100%"
    formControlName="reportDate"
    [format]="'dd.MM.yyyy'">
    <kendo-datepicker-messages
      today="Сегодня">
    </kendo-datepicker-messages>  </kendo-datepicker>

  <kendo-label class="k-checkbox-label"
               [for]="occTypes2"
               text="Типы должностей:">
  </kendo-label>
  <kendo-multiselecttree #occTypes2
                         kendoMultiSelectTreeExpandable
                         [kendoMultiSelectTreeFlatBinding]="occupationTypes$ | async"
                         textField="name"
                         valueField="id"
                         parentIdField="parentId"
                         [checkableSettings]="checkableSettings"
                         (valueChange)="setCheckedOccupationTypeIds($event)"
                         style="overflow-y: scroll; max-height: 100px"
  >
  ></kendo-multiselecttree>

  <kendo-label class="k-checkbox-label"
               [for]="finSources"
               text="Источники финансирования:">
  </kendo-label>

  <kendo-multiselect #finSources
                     [data]="financingSources$ | async"
                     textField="shortName"
                     valueField="id"
                     [checkboxes]="true"
                     [autoClose]="false"
                     (valueChange)="setCheckedFinancingSourceIds($event)">
  </kendo-multiselect>

</div>

<app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                         [selection]="selection"
                                         [checkableSettings]="checkableSettings"
                                         [expandAll]="true"
                                         (checkedSubdivisions$)="setCheckedSubdivisionsValue($event)">
</app-subdivision-treeview-with-checkbox2>

<div class="k-form-buttons" style="justify-content: flex-end;">

  <button kendoButton
          type="button"
          primary="true"
          [disabled] = "form.invalid"
          (click)="onClickOk()"
  >
    Сформировать
  </button>

  <button kendoButton
          type="button"
          (click)="onClickCancel()"
  >
    Отменить
  </button>

</div>
