import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IWorkModeType extends IEntityId, IEntityModify, IEntityDeletedFlag {
  /** Наименование типа режима работы */
  name: string;
  /** Описание типа режима работы */
  description: string;
}

@classBackend('WorkModeType', 'stafflist')
@className('WorkModeType')
/** Тип режима работы */
export class WorkModeType implements IWorkModeType {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public deletedFlag: boolean,
              public name: string,
              public description: string,
  ) {
  }

  private static _fullComparer: ObjComparer<IWorkModeType>;
  /** Сравнить по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<IWorkModeType>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        deletedFlag: true,
        name: true,
        description: true,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IWorkModeType, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнить по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer;
  }
}

/** Enum для {@link WorkModeType} */
export enum WorkModeTypeEnum {
  /**
   * Cменный режим
   * стандартный режим
   */
  replaceableMode = 1,

  /**
   * Пятидневный режим с 1 рабочей субботой
   * В месяц есть один рабочий день в субботу, в этот день обед не учитывается
   */
  fiveDayModeWithOneWorkingSaturday = 2,

  /**
   * Гибкий график
   * При этом режиме возможна установка `учитывать обед` на каждый рабочий день(Графики и табеля)
   */
  flexibleGraph = 3,

  /** Пятидневный режим
  * Пятидневная рабочая неделя с двумя выходными днями
  */
  fiveDay = 4,

  /** Шестидневный режим
  * Шестидневная рабочая неделя с одним выходным днем
  */
  sixDay = 5,

  /** Гибкий график с возможностью изменения продолжительности обеда */
  flexibleGraphAndDinner = 6,
}
