import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { TextAreaFieldControl_Settings } from "../../services/classes/field-controls/text-area-field-control";
import { ITextAreaDirectoryEditComponent } from "./i-textarea-directory-edit-control.component";
import { TextAreaDirectoryEditComponentService } from "./services/textarea-directory-edit-control-component.service";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-textarea-directory-edit-control',
  templateUrl: './textarea-directory-edit-control.component.html',
  styleUrls: ['./textarea-directory-edit-control.component.css'],
  providers: [TextAreaDirectoryEditComponentService]
})
@traceClass('TextAreaDirectoryEditComponent')
export class TextAreaDirectoryEditComponent implements OnInit, ITextAreaDirectoryEditComponent {

  @Input("control") public control: FormControl;
  @Input("settings") public settings: TextAreaFieldControl_Settings;

  public placeholder: string;

  public minLength$: Observable<number>;
  public maxLength$: Observable<number>;

  constructor(private service: TextAreaDirectoryEditComponentService, private traceService: TracerServiceBase) {
    service.component = this;
  }

  @traceFunc()
  public ngOnInit(): void {
    this.service.readSettings();
    this.service.registerValidators();
  }
}
