<div class="wrap" [formGroup]="form">
  <label class="k-checkbox-label" for='dateFrom'>с: </label>
  <kendo-datepicker
    id = "dateFrom"
    style="width: 100%; justify-content: space-between;"
    formControlName="fromDate"
    [format]="'dd.MM.yyyy'"
    [disabledDates]="startDateValidator"
  >
    <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
  </kendo-datepicker>
  <label class="k-checkbox-label" for='dateTill'>по: </label>
  <kendo-datepicker
    id = "dateTill"
    style="width: 100%; justify-content: space-between;"
    formControlName="tillDate"
    [format]="'dd.MM.yyyy'"
    [disabledDates]="endDateValidator"
  >
    <kendo-datepicker-messages today="Сегодня"></kendo-datepicker-messages>
  </kendo-datepicker>

</div>

  <app-subdivision-treeview-with-checkbox2 [dataSourceService]="subdivisionDataSourceService"
                                           [selection]="selection"
                                           [expandAll]="expandAllSubdivisions"
                                           [checkableSettings]="checkableSettings"
                                           [displayTextFn]="displayTextFn"
                                           (checkedSubdivisions$)="setCheckedSubdivisionsValue($event)"
                                           (allSubdivisions$)="setAllSubdivisionsFlagValue($event)">
  </app-subdivision-treeview-with-checkbox2>

  <div class="k-form-buttons" style="justify-content: flex-end; margin-top: 10px">

    <button kendoButton
            type="button"
            primary="true"
            [disabled] = "form.invalid"
            (click)="onClickOk()"
    >
      Сформировать
    </button>

    <button kendoButton
            type="button"
            (click)="onClickCancel()"
    >
      Отменить
    </button>

  </div>
