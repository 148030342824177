import { map, Observable } from 'rxjs';
import { ArrayDataSourceIEntityId, DataSource } from 'src/app/classes/array-data-sources/data-source';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../data-source-services/data-source.service';
import { DbChangedListener } from '../signal-r/listeners/db-changed-listener';
import { DataStateBuilder } from '../../classes/data-state-builders/data-state-builder';
import { Code, ICode } from '../../classes/domain/POCOs/timesheet_table/Code';

export class CodeDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<CodeDataSourceService_Params, ICode> {

  readonly paramsDataSource = new DataSource<CodeDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<ICode>();

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(Code)
      .pipe(
        map(value => value.data),
        map(value => new DataStateBuilder(value, this.dataSource.data2, (x, y) => x.id === y.id).build_()),
        map(value => value.source.map(x => x.signalR.currentOrOrigin.id)),
        map(value => this.reloadFromSignalR$(value)),
      );
  }

  protected _reloadData$(params: CodeDataSourceService_Params): Observable<ICode[]> {
    return this._reloadFromRemoteByIds$(params, params.codeIds);
  }

  constructor(private readonly parentDataSourceService: ICodeDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected _reloadFromRemoteByIds$(params: CodeDataSourceService_Params, targets: ICode['id'][]): Observable<ICode[]> {
    return this.parentDataSourceService.getCodes$(targets);
  }
}

export interface CodeDataSourceService_Params {
  codeIds: number[];
}

export interface ICodeDataSourceService_ParentDataSourceService {
  getCodes$(codeIds: number[]): Observable<ICode[]>;
}
