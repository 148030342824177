import {Injectable} from "@angular/core";
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import { switchMap} from "rxjs/operators";
import {ResponseObjError} from "../../../classes/requestResults/responseObjError";

import {ResponseObj} from "../../../classes/requestResults/response-obj";

/**
 * Интерцептор перехватывающий ошибки внутри blob запросов(если сервер возвращает стандартизированную ошибку)
 * Необходимо ставить последним в цепочке интерцепторов(Обязательно после catch-error.interceptor)
 */
@Injectable({providedIn: 'root'})
export class BlobInterceptor implements HttpInterceptor{
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.responseType !== "blob"){
      return next.handle(req.clone())
    }

    return next.handle(req.clone()).pipe(
      switchMap(value => {
        if(!value || value.type !== HttpEventType.Response) {return of(value)}
        if(value.status !== HttpStatusCode.Ok) {return of(value)}

        if(value.body instanceof Blob){
          const blob = value.body as Blob;
          return from(blob.text()).pipe(
            switchMap(blobText => {
              let objFormBlob: any;
              try {
                objFormBlob = JSON.parse(blobText);
              } catch {
                return of(value); //Если в теле не содержится json то сервер не падал стандартизированной ошибкой
              }

              if(!ResponseObj.checkType(objFormBlob)) {return  of(value)} //Если в теле Blob НЕ содержится стандартизированный ответ
              const responseObj = objFormBlob as ResponseObj<any, any>;
              if(!responseObj.error || !ResponseObjError.checkType(responseObj.error)) {return of(value)} //Если ошибка НЕ стандартизированная

              return of(new HttpResponse({body: objFormBlob }))
            })
          )
        }

        return of(value);
      })
    )
  }
}
