import { IFieldControl } from "./interfaces/common";

export class MaskedTextBoxFieldControl implements IFieldControl<string> {
  public readonly name = 'MaskedTextBoxFieldControl';
  public getValueFunc = (stringValue: string) => stringValue;

  constructor (
    public settings?: (() => MaskedTextBoxFieldControl_Settings) | MaskedTextBoxFieldControl_Settings
  ) {}
}

export interface MaskedTextBoxFieldControl_Settings  {
  mask: string;
}
