import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {pairwise, ReplaySubject, switchMap} from "rxjs";
import {SignalRService} from "../../services/signal-r/signal-r.service";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../modules/trace/decorators/func.decorator";
import {KendoNotificationService} from "../../services/kendo-notification.service";
import {
  CustomStorageService,
  StorageLocationEnum,
  StorageOptions
} from "../../services/storages/custom-storage.service";
import {filter, takeUntil} from "rxjs/operators";
import {AppSettingsService} from "../../services/app-settings.service";
import {LoadingIndicatorService} from "../../services/loading-indicator.service";
import {exLoadingMessage} from "../../operators/ex-loading-message.operator";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  styleUrl: './app-update.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

@traceClass('AppUpdateComponent')
export class AppUpdateComponent implements OnInit, OnDestroy {
  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  }

  /** Настройки хранения данных о необходимости перезагрузить страницу */
  private readonly storageOptions = new StorageOptions(
    StorageLocationEnum.SessionStorage,
    'AppUpdateComponent/ShowNotification',
    undefined,
    false,
    false,
  )

  /** Конструктор */
  constructor(private readonly signalRService: SignalRService,
              private readonly appSettingsService: AppSettingsService,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly alertService: AlertService,
              private readonly notificationService: KendoNotificationService,
              private readonly storageService: CustomStorageService,

              private readonly traceService: TracerServiceBase) {
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnInit(): void {
    // Сообщаем если перегрузка страницы вызвана обновлением
    if(this.storageService.get<boolean>(this.storageOptions)){
      this.storageService.remove(this.storageOptions);
      setTimeout(() => {
        this.notificationService.showWarning({
          content: 'Страница перезагружена после обновления программного обеспечения',
          hideAfter: 10000
        });
      }, 100)
    }

    //Подпись на прослушку
    this.signalRService
      .hasConnection$
      .pipe(
        pairwise(),
        filter(value => !value[0] && value[1]),
        switchMap(() => {
          return this.appSettingsService.appVersionFromRemote$()
            .pipe(
              exLoadingMessage(this.loadingIndicatorService, 'Проверка версии программы'),
            )
        }),
        filter(value => value !== this.appSettingsService.appVersion),
        takeUntil(this.streams$.unsubscribe),
      ).subscribe(() => {
        this.storageService.set(this.storageOptions, true);
        window.location.reload();
    })
  }

  /** @inheritDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
