import {ErrorHandler} from "@angular/core";
import {ServerLoggerService} from "./server-logger.service";
import {EnvironmentService} from "../environment.service";

export class ErrorHandlerService implements ErrorHandler{

  public constructor(private serverLogger: ServerLoggerService,
                     private environmentService: EnvironmentService,) {
  }

  /** Обработчик ошибок консоли */
  public handleError(error: any): void {
    //if (this.environmentService.environment.production){ //Отправляем только при production
      this.serverLogger.send(error);
    //}

    console.error(error);
  }
}
