import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OccupationType } from "src/app/classes/domain/POCOs/stafflist/OccupationType";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { IAddOccupationTypeFormComponent } from "../i-add-occupation-type-form.component";

@Injectable()
@traceClass('AddOccupationTypeFormComponentService')
export class AddOccupationTypeFormComponentService extends ComponentServiceBase<IAddOccupationTypeFormComponent> {

  constructor(private traceService: TracerServiceBase) {
    super();
  }

  /** Инициализация сервиса */
  @traceFunc()
  public init() {
    this.validateComponentInputs()
    this.component.form = this.createForm(this.component.dataItem)
  }

  /** Валидация входящих инпутов компонента */
  @traceFunc()
  private validateComponentInputs() {
    if (!this.component.parents) {
      throw new Error("Не задан [parents] в компоненте AddOccupationTypeFormComponent")
    }
  }

  /** Создание формы добавления*/
  @traceFunc()
  private createForm(item: OccupationType) {
    const form = new FormGroup({
      name: new FormControl(item?.name, [Validators.required, Validators.maxLength(50)]),
      comment: new FormControl(item?.comment, Validators.maxLength(100)),
      code: new FormControl(item?.code, Validators.maxLength(50)),
      parentId: new FormControl(item?.parentId),
    })

    form.markAllAsTouched()
    form.valueChanges.subscribe(value => {
      this.traceService.add2(`Изменена форма добавления категории должности`, { obj: value })
    })
    return form
  }

  /** Событие происходит при клике на кнопку сохранения */
  @traceFunc()
  public onClickSave() {
    this.component.onSaveEventEmitter.emit(this.component.form.value)
  }

  /** Событие происходит при клике на кнопку отмены */
  @traceFunc()
  public onClickCancel() {
    this.component.onCancelEventEmitter.emit(false)
  }
}
