import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';
import {ObjComparer} from "../../../object-comparers/object-comparer";

export interface IFinancingSource extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag {
  /** Наименование источника финансирования */
  name: string;
  /** Короткое название */
  shortName: string;
}

@classBackend('FinancingSource', 'stafflist')
@className('FinancingSource')
/** Источник финансирования */
export class FinancingSource implements IFinancingSource {
  constructor(public id: number,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public name: string,
              public shortName: string,
  ) {
  }

  private static _fullComparer: ObjComparer<IFinancingSource>;
  /** Сравнить по всем полям */
  public static get fullComparer(){
    if(!this._fullComparer){
      this._fullComparer = new ObjComparer<IFinancingSource>({
        id: true,
        modifiedUserId: true,
        modifiedDate: ObjComparer.dateComparer,
        comment: true,
        deletedFlag: true,
        name: true,
        shortName: true,
      })
    }

    return this._fullComparer;
  }

  private static _usefulComparer: ObjComparer<Omit<IFinancingSource, 'modifiedUserId' | 'modifiedDate'>>;
  /** Сравнить по полезным полям */
  public static get usefulComparer(){
    if(!this._usefulComparer){
      const instance = this.fullComparer.delete({
        modifiedUserId: true,
        modifiedDate: true,
      })

      this._usefulComparer = instance;
    }

    return this._usefulComparer
  }
}
