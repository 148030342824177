import { OnDestroy } from "@angular/core";
import { filter, Observable, ReplaySubject, take, takeUntil } from "rxjs";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { TraceParamEnum } from "src/app/modules/trace/decorators/classes/traceSetting.interface";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { traceParam } from "src/app/modules/trace/decorators/param.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { AlertService } from "src/app/services/alert.service";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { VersioningField } from "../classes/fields/versioning-field";
import { DirectoryEditSettings_Versioning } from "../classes/service-settings/directory-edit-settings-versioning";
import { DirectoryEditVersioningService, StartEditOrAddEventData, ValueChangeEventData, VersioningDialogEventData } from "../directory-edit-versioning.service";

/** Сервис редактирования версионных полей */
@traceClass('DirectoryEditRef_Versioning')
export class DirectoryEditRef_Versioning implements OnDestroy {

  public onVersionAdded$ = new ReplaySubject<VersioningItem>(1);
  public onVersionEdited$ = new ReplaySubject<VersioningItem>(1);
  public onVersionDeleted$ = new ReplaySubject<number>(1);

  public onStartEditOrAdd$ = new ReplaySubject<StartEditOrAddEventData>(1);
  public onStartDateChangeWithInit$ = new ReplaySubject<ValueChangeEventData>(1);
  public onEndDateChangeWithInit$ = new ReplaySubject<ValueChangeEventData>(1);

  public onEndEdit$: Observable<any>;
  public onClose$: Observable<any>;

  private _streams$:{
    unsubscribes: ReplaySubject<any>,
  }={
    unsubscribes: new ReplaySubject<any>(1),
  }

  constructor(private readonly settings: DirectoryEditSettings_Versioning,
              private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly alertService: AlertService,
              private readonly service: DirectoryEditVersioningService,
              private readonly displayErrorsService: DisplayErrorsService,
              private readonly tracerService: TracerServiceBase,
              private readonly fields: Array<VersioningField>
  )
  {
    //Значение по умолчанию это id
    settings.ownerIdGetter ??= (dataItem: any) => dataItem.id;

    this.onEndEdit$ = this.service.onEndEditOrAdd$;
    this.onClose$ = this.service.onClose$;

    //Подписываемся на событие добавления версии
    this.service.onVersionAdding$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes))
    .subscribe(data => this.onVersioningDataItemAdding(data));

    //Подписываемся на событие редактирования версии
    this.service.onVersionEditing$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes))
    .subscribe(data => this.onVersioningDataItemEditing(data));

    //Подписываемся на событие удаления версии
    this.service.onVersionDeleting$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes))
    .subscribe(data => this.onVersioningDataItemDeleting(data));

    //Подписываемся на событие изменения даты начала
    this.service.onStartDateChangeWithInit$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes))
    .subscribe(e => this.onStartDateChangeWithInit$.next(e));

    //Подписываемся на событие изменения даты окончания
    this.service.onEndDateChangeWithInit$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes))
    .subscribe(e => this.onEndDateChangeWithInit$.next(e));

    this.service.onStartEditOrAdd$.pipe(filter(f => this.fields.some(fld => fld === f.field)), takeUntil(this._streams$.unsubscribes))
    .subscribe(e => this.onStartEditOrAdd$.next(e));
  }

  @traceFunc({traceParamType: TraceParamEnum.traceByDecorators})
  public editField(@traceParam() field: VersioningField, @traceParam({maxLength: 200}) dataItem: any) {
    const ownerId = this.settings.ownerIdGetter(dataItem);
    this.service.edit(dataItem, field, this.settings.getVersionsByOwnerIdAndActionId$(ownerId, field.actionId));
  }

  @traceFunc()
  public close() {
    this.service.close();
  }

  ///Событие происходит в момент когда нужно добавить версию
  @traceFunc({traceParamType: TraceParamEnum.traceAll})
  private onVersioningDataItemAdding(eventData: VersioningDialogEventData) {
    this.loadingIndicatorService.addToObservable("Добавление новой версии значения",this.settings.addVersion$(eventData.field.actionId, eventData.versioningItem))
    .pipe(trace(this.tracerService), take(1),takeUntil(this._streams$.unsubscribes)).subscribe({
      next: data => {
        this.service.updateDataSource(this.settings.getVersionsByOwnerIdAndActionId$(eventData.versioningItem.ownerId, eventData.field.actionId), eventData.field);
        this.onVersionAdded$.next(data);
      },
      error: error => this.displayErrorsService.handleError(error)});
  }

  ///Событие происходит в момент когда нужно отредактировать версию
  @traceFunc()
  private onVersioningDataItemEditing(eventData: VersioningDialogEventData) {
    this.loadingIndicatorService.addToObservable("Сохранение версии значения",this.settings.editVersion$(eventData.field.actionId, eventData.versioningItem))
    .pipe(trace(this.tracerService), take(1),takeUntil(this._streams$.unsubscribes)).subscribe({
      next: data => {
        this.service.updateDataSource(this.settings.getVersionsByOwnerIdAndActionId$(eventData.versioningItem.ownerId, eventData.field.actionId), eventData.field);
        this.onVersionEdited$.next(data);
      },
      error: error => this.displayErrorsService.handleError(error)});
  }

  // Событие происходит в тот момент, когда нужно добавлять новую версию значения
  @traceFunc()
  private onVersioningDataItemDeleting(eventData: VersioningDialogEventData) {
    this.loadingIndicatorService.addToObservable("Удаление версии значения",this.settings.deleteVersion$(eventData.versioningItem.id))
    .pipe(trace(this.tracerService), take(1),takeUntil(this._streams$.unsubscribes)).subscribe({
      next: data => {
        if(data === true) {
          this.service.updateDataSource(this.settings.getVersionsByOwnerIdAndActionId$(eventData.versioningItem.ownerId, eventData.field.actionId), eventData.field);
          this.onVersionDeleted$.next(eventData.versioningItem.ownerId);
        } else {
          this.alertService.defaultAlertOption.warning().mod(x => {
            x.message = "Произошла ошибка при удалении версии значения"
          }).showAlert();
        }
      },
      error: error => this.displayErrorsService.handleError(error)});
  }

  @traceFunc()
  ngOnDestroy() {
    this._streams$.unsubscribes.next(null);
    this._streams$.unsubscribes.complete();

    this.onVersionAdded$.complete();
    this.onVersionEdited$.complete();
    this.onVersionDeleted$.complete();
    this.onStartEditOrAdd$.complete();
    this.onStartDateChangeWithInit$.complete();
    this.onEndDateChangeWithInit$.complete();
  }
}
