import {TraceStorageService} from "../trace-storage-services/trace-storage.service";
import {TracerServiceBase} from "./tracer-base.service";
import {ITraceAddParams} from "../../decorators/classes/traceSetting.interface";
import {TraceStorageItem} from "../trace-storage-services/trace-storage-base.service";
import {convertObjToString, limitString} from "../../decorators/classes/functions";

export class TracerService extends TracerServiceBase{

  constructor(public readonly storageService: TraceStorageService) {
    super();
  }

  add(message: string) {
    if(this.isDisabled) {return;} //Выходим если трассировка отключена

    this.storageService.add(new TraceStorageItem(
      this._tracerLevelManager.count,
      this.buildMessage(message)
    ));
  }

  add2(message?: string, ...params: ITraceAddParams<any>[]) {
    if(this.isDisabled) {return;} //Выходим если трассировка отключена

    const paramsAsString = params?.map(x => {
      if(x.disabled) {return 'notTrace'}
      const objAsString = limitString(convertObjToString(x.obj, x.map), x.maxLength ? x.maxLength : this.defaultParamTraceMaxLength);
      const description = !x.description ? '' : `${x.description}: `;
      return `${description}${objAsString}`;
    })

    this.storageService.add(new TraceStorageItem(
      this._tracerLevelManager.count,
      this.buildMessage(`${(!!message ? `${message}: ` : '')}[ ${paramsAsString?.join('  ,  ') ?? ''} ]`)
    ))
  }

  addNewLine() {
    if(this.isDisabled) {return;} //Выходим если трассировка отключена

    this.storageService.add(null);
  }

  copy(): TracerServiceBase {
    const instance = new TracerService(this.storageService);
    instance._tracerLevelManager = this._tracerLevelManager.copy();
    instance.defaultParamTraceMaxLength = this.defaultParamTraceMaxLength;
    instance.isDisabled = this.isDisabled;

    return instance;
  }

  /** Построить сообщение для добавление в трассировку */
  private buildMessage(message: string){
    return `tracerId: ${this.guid.toString()}\t${message}`
  }
}
