import {ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal} from '@angular/core';
import {SignalRService} from "../../services/signal-r/signal-r.service";
import {EMPTY, of, ReplaySubject, skipWhile, switchMap} from "rxjs";
import {delay, mergeWith, takeUntil} from "rxjs/operators";
import {AuthService} from "../../modules/auth/services/auth.service";

@Component({
  selector: 'app-internet-connection',
  templateUrl: './internet-connection.component.html',
  styleUrls: ['./internet-connection.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/** Компонент отображает сообщение об отсутствии подключения к интернету */
export class InternetConnectionComponent implements OnInit, OnDestroy {
  /** Стримы */
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1)
  }

  /** Отображено ли сообщение об отсутствии подключения к интернету */
  protected readonly isShowed = signal<boolean>(false)

  /** Конструктор */
  constructor(private readonly signalRService: SignalRService,
              private readonly authService: AuthService) { }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.authService.isAuth$
      .pipe(
        switchMap(isAuth => {
          if(!isAuth){
            this.isShowed.set(false);
            return EMPTY;
          }

          /** Стрим пропускает все трансляции пока не будет первая установка */
          const skipWhileNotConnection$ = this.signalRService.hasConnection$
            .pipe(
              skipWhile(value => value !== true),
            );

          /** Стрим трансляции если подключение к signalR дольше чем ожидалось */
          const firstFalse$ = of(false)
            .pipe(
              delay(SignalRService.normalConnectionTime), // Время на ожидание подключение signalR
              takeUntil(skipWhileNotConnection$) // Пока подключение не будет установленно
            );

          return skipWhileNotConnection$
            .pipe(
              mergeWith(firstFalse$)
            );
        }),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe(value => {
        this.isShowed.set(!value);
      })
  }

  /** @inheritDoc */
  public ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
