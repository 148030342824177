import { IAutoTrim, IFieldControl, IMinMaxLength, IPlaceholder } from "./interfaces/common";

export class TextAreaFieldControl implements IFieldControl<string> {
  public readonly name = 'TextAreaFieldControl';
  public getValueFunc = (stringValue: string) => stringValue;

  constructor (
    public settings?: (() => TextAreaFieldControl_Settings) | TextAreaFieldControl_Settings
  ) {}
}

export interface TextAreaFieldControl_Settings extends IMinMaxLength, IAutoTrim, IPlaceholder {

}
