<kendo-splitter orientation="vertical" style="height: 100%">
  <kendo-splitter-pane [resizable]="false" style="height: 51px; margin-bottom: 3px" class="k-toolbar">
    <form>
      <div class="form-controls">
        <div>
          <span class="label-text">Год:</span>
          <kendo-dropdownlist [data]="yearsDataSource.data"
                              [formControl]="yearFormControl"
                              [valuePrimitive]="true"
                              textField="text"
                              valueField="id"
                              style="width: 10em;"
          ></kendo-dropdownlist>
        </div>
      </div>
      <div>
        <span class="label-text">Выбранный день:</span>
        <kendo-textbox [value]="selectedItemDataSource.data?.date.format('DD.MM.YYYY')" [disabled]="true" style="width: 86px"></kendo-textbox>
      </div>
      <div style="display: flex;align-items: center;">
        <span class="label-text">Тип дня:</span>
        <div class="buttons-day-types">
          <button kendoButton
                  *ngFor="let dayType of dayTypesDataSource.data"
                  [disabled]="!selectedItemDataSource.data || selectedItemDataSource.data.dayType.id === dayType.id || (dayType.id == 1 && selectedItemDataSource.data.date.isoWeekday() < 6)"
                  [style.background-color]="dayType.color"
                  (click)="setDayType(dayType.id)"
          >
            {{ dayType.name }}
          </button>
        </div>
      </div>
    </form>
  </kendo-splitter-pane>
  <kendo-splitter-pane style="height: 100%; overflow: hidden;">
    <app-production-calendar-directory-grid [dataSourceService]="dataSourceService"
                                            [selectedItem]="selectedItemDataSource.data"
                                            (onCellClick)="onCellClick($event)"
                                            (onDbCellClick)="onDbCellClick($event)"
    ></app-production-calendar-directory-grid>
  </kendo-splitter-pane>
</kendo-splitter>

