import { IEntityId } from '../interfaces/IEntityId';
import { IEntityModify } from '../interfaces/IEntityModify';
import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityComment } from '../interfaces/IEntityComment';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IPatientMovement extends IEntityId, IEntityModify, IEntityComment, IEntityDeletedFlag, IEntityRowVersion {
  /** Флаг обозначающий, отправлена ли колонка в СВОД */
  sent: boolean;
  /** Дата */
  date: Date;
  /** Подразделение */
  subdivisionId: number;
  /** Количество коек */
  numberBeds: number;

  /** Состояло мужчин */
  consistedMen: number;
  /** Состояло женщин */
  consistedWomen: number;
  /** Состояло с ВИЧ */
  consistedVich: number;
  /** Состояло в т.ч. пациенты с СOVID */
  consistedCovid: number;
  /** Состояло в т.ч. обсервация */
  consistedObservation: number;
  /** Состояло в т.ч. взрослые с ОРВИ */
  consistedOrvi: number;
  /** Состояло в т.ч. дети с ОРВИ */
  consistedChildOrvi: number;
  /** Состояло в т.ч. беременность до 22 недель */
  consistedPregnantBefore22Weeks: number;
  /** Состояло в т.ч. беременность после 22 недель */
  consistedPregnantAfter22Weeks: number;

  /** Госпитализировано мужчин */
  receivedMen: number;
  /** Госпитализировано женщин */
  receivedWomen: number;
  /** Госпитализировано в т.ч. с ВИЧ */
  receivedVich: number;
  /** Госпитализировано в т.ч. пациенты с СOVID */
  receivedCovid: number;
  /** Госпитализировано в т.ч. обсервация */
  receivedObservation: number;
  /** Госпитализировано в т.ч. взрослые с ОРВИ */
  receivedOrvi: number;
  /** Госпитализировано в т.ч. дети с ОРВИ */
  receivedChildOrvi: number;
  /** Госпитализировано в т.ч. беременность до 22 недель */
  receivedPregnantBefore22Weeks: number;
  /** Госпитализировано в т.ч. беременность после 22 недель */
  receivedPregnantAfter22Weeks: number;
  /** Госпитализировано в т.ч. дети с внебольничной пневмонией */
  receivedChildCommunityAcquiredPneumonia: number;

  /** Перевод В отделение мужчин */
  transToDepartmentMen: number;
  /** Перевод В отделение женщин */
  transToDepartmentWomen: number;

  /** Перевод ИЗ отделения мужчин */
  transFromDepartmentMen: number;
  /** Перевод ИЗ отделения женщин */
  transFromDepartmentWomen: number;

  /** Выбыло мужчин */
  droppedOutMen: number;
  /** Выбыло женщин */
  droppedOutWomen: number;

  /** Letalis мужчин */
  letalisMen: number;
  /** Letalis женщин */
  letalisWomen: number;

  /** Состоит с ВИЧ */
  consistsVich: number;
  /** Состоит в т.ч. пациенты с СOVID */
  consistsCovid: number;
  /** Состоит в т.ч. обсервация */
  consistsObservation: number;
  /** Состоит в т.ч. взрослые с ОРВИ */
  consistsOrvi: number;
  /** Состоит в т.ч. дети с ОРВИ */
  consistsChildOrvi: number;
  /** Состоит в т.ч. беременность до 22 недель */
  consistsPregnantBefore22Weeks: number;
  /** Состоит в т.ч. беременность после 22 недель */
  consistsPregnantAfter22Weeks: number;
  /** Состоит в т.ч. дети с внебольничной пневмонией */
  consistsChildCommunityAcquiredPneumonia: number;
  /** Состоит детей */
  consistsChild: number;
  /** Состоит мам */
  consistsMoms: number;

  /** Свободные места мужчин */
  freeBedsMale: number;
  /** Свободные места женщин */
  freeBedsFamale: number;

  /** Отказано Всего по больнице */
  refusedTotal: number;
  /** Отказано в т.ч. взрослые с ОРВИ */
  refusedOrvi: number;
  /** Отказано в т.ч. дети с ОРВИ */
  refusedChildOrvi: number;
  /** Отказано Всего РОДДОМ */
  refusedTotalRoddom: number;
  /** Отказано в т.ч. беременность до 22 недель */
  refusedPregnantBefore22Weeks: number;
  /** Отказано в т.ч. беременность после 22 недель */
  refusedPregnantAfter22Weeks: number;
  /** Отказано в т.ч. дети с внебольничной пневмонией */
  refusedChildCommunityAcquiredPneumonia: number;

  /** Сведения о транспортабельности - Амбулаторно */
  transOutpatient: number;
  /** Сведения о транспортабельности - Транспортировка сидя */
  transSitting: number;
  /** Сведения о транспортабельности - Транспортировка лежа */
  transLying: number;
  /** Сведения о транспортабельности - Нетранспортабельный */
  transNontransportable: number;

  /** Плановые операции */
  operationsPlanned: number;
  /** Экстренные операции */
  operationsEmergency: number;

  /** Родильный дом - Количество родов */
  roddomBirths: number;
  /** Родильный дом - Количество абортов */
  roddomAbortions: number;
  /** Родильный дом - Количество беременных до 22 недель */
  roddomBefore22Weeks: number;
  /** Родильный дом - Количество беременных после 22 недель */
  roddomAfter22Weeks: number;
  /** Родильный дом - Количество больных в гинекологии */
  roddomInGynecology: number;
  /** Родильный дом - Реанимация новорожденных */
  roddomNeonatalResuscitation: number;

  /** Кабинет клещевого энцефалита - Обратилось в кабинет */
  miteApplied: number;
  /** Кабинет клещевого энцефалита - Проведено иммунопрофилактики */
  miteImmunoprophylaxis: number;
  /** Кабинет клещевого энцефалита - Удалено клещей */
  miteDeleted: number;

  /** Каналы госпитализации (по госпитализированным пациентам) по больнице - 103 */
  hospitalizationHospital103: number;
  /** Каналы госпитализации (по госпитализированным пациентам) по больнице - САМОТЕК */
  hospitalizationHospitalFlow: number;
  /** Каналы госпитализации (по госпитализированным пациентам) по больнице - ПЛАН (103 поликлиника) */
  hospitalizationHospitalPlan103: number;
  /** Каналы госпитализации (по госпитализированным пациентам) по больнице - ПЛАН (не экстренно) */
  hospitalizationHospitalPlan: number;

  /** Каналы госпитализации (по госпитализированным пациентам) родильный дом - 103 */
  hospitalizationRoddom103: number;
  /** Каналы госпитализации (по госпитализированным пациентам) родильный дом - САМОТЕК */
  hospitalizationRoddomFlow: number;
  /** Каналы госпитализации (по госпитализированным пациентам) родильный дом - ПЛАН (103 поликлиника) */
  hospitalizationRoddomPlan103: number;
  /** Каналы госпитализации (по госпитализированным пациентам) родильный дом - ПЛАН (не экстренно) */
  hospitalizationRoddomPlan: number;
}

@classBackend('PatientMovement', 'timesheet')
@className('PatientMovement')
/** Модель строки раздела "Движение пациентов в учреждении" */
export class PatientMovement implements IPatientMovement {
  constructor(public id: number,
              public modifiedUserId: number,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public timestamp: [],
              public sent: boolean,
              public date: Date,
              public subdivisionId: number,
              public numberBeds: number,
              public consistedMen: number,
              public consistedWomen: number,
              public consistedVich: number,
              public consistedCovid: number,
              public consistedObservation: number,
              public consistedOrvi: number,
              public consistedChildOrvi: number,
              public consistedPregnantBefore22Weeks: number,
              public consistedPregnantAfter22Weeks: number,
              public receivedMen: number,
              public receivedWomen: number,
              public receivedVich: number,
              public receivedCovid: number,
              public receivedObservation: number,
              public receivedOrvi: number,
              public receivedChildOrvi: number,
              public receivedPregnantBefore22Weeks: number,
              public receivedPregnantAfter22Weeks: number,
              public receivedChildCommunityAcquiredPneumonia: number,
              public transToDepartmentMen: number,
              public transToDepartmentWomen: number,
              public transFromDepartmentMen: number,
              public transFromDepartmentWomen: number,
              public droppedOutMen: number,
              public droppedOutWomen: number,
              public letalisMen: number,
              public letalisWomen: number,
              public consistsVich: number,
              public consistsCovid: number,
              public consistsObservation: number,
              public consistsOrvi: number,
              public consistsChildOrvi: number,
              public consistsPregnantBefore22Weeks: number,
              public consistsPregnantAfter22Weeks: number,
              public consistsChildCommunityAcquiredPneumonia: number,
              public consistsChild: number,
              public consistsMoms: number,
              public freeBedsMale: number,
              public freeBedsFamale: number,
              public refusedTotal: number,
              public refusedOrvi: number,
              public refusedChildOrvi: number,
              public refusedTotalRoddom: number,
              public refusedPregnantBefore22Weeks: number,
              public refusedPregnantAfter22Weeks: number,
              public refusedChildCommunityAcquiredPneumonia: number,
              public transOutpatient: number,
              public transSitting: number,
              public transLying: number,
              public transNontransportable: number,
              public operationsPlanned: number,
              public operationsEmergency: number,
              public roddomBirths: number,
              public roddomAbortions: number,
              public roddomBefore22Weeks: number,
              public roddomAfter22Weeks: number,
              public roddomInGynecology: number,
              public roddomNeonatalResuscitation: number,
              public miteApplied: number,
              public miteImmunoprophylaxis: number,
              public miteDeleted: number,
              public hospitalizationHospital103: number,
              public hospitalizationHospitalFlow: number,
              public hospitalizationHospitalPlan103: number,
              public hospitalizationHospitalPlan: number,
              public hospitalizationRoddom103: number,
              public hospitalizationRoddomFlow: number,
              public hospitalizationRoddomPlan103: number,
              public hospitalizationRoddomPlan: number) {
  }
}

export class PatientMovementCalculatedFields {
  constructor(public consistedTotal: number,
              public consistsTotal: number,
              public consistsMen: number,
              public consistsWomen: number,
              public freeBeds: number,
              public freeBedsPercent: number,
              public hospitalizationHospitalTotal: number,
              public hospitalizationRoddomTotal: number) {
  }

  public static get(dataItem: Pick<IPatientMovement, 'consistedMen' | 'consistedWomen' | 'receivedMen' | 'receivedWomen' | 'transToDepartmentMen' | 'transFromDepartmentMen' | 'droppedOutMen' | 'letalisMen' | 'transToDepartmentWomen' | 'transFromDepartmentWomen' | 'droppedOutWomen' | 'letalisWomen' | 'numberBeds' | 'hospitalizationHospital103' | 'hospitalizationHospitalFlow' | 'hospitalizationHospitalPlan103' | 'hospitalizationHospitalPlan' | 'hospitalizationRoddom103' | 'hospitalizationRoddomFlow' | 'hospitalizationRoddomPlan103' | 'hospitalizationRoddomPlan'>) {
    const consistedTotal = (dataItem?.consistedMen || 0) + (dataItem?.consistedWomen || 0);

    const consistsMen = (dataItem?.consistedMen || 0) + (dataItem?.receivedMen || 0) + (dataItem?.transToDepartmentMen || 0) - (dataItem?.transFromDepartmentMen || 0) - (dataItem?.droppedOutMen || 0) - (dataItem?.letalisMen || 0);
    const consistsWomen = (dataItem?.consistedWomen || 0) + (dataItem?.receivedWomen || 0) + (dataItem?.transToDepartmentWomen || 0) - (dataItem?.transFromDepartmentWomen || 0) - (dataItem?.droppedOutWomen || 0) - (dataItem?.letalisWomen || 0);

    const consistsTotal = consistsMen + consistsWomen;

    const numberBeds = dataItem?.numberBeds || 0;

    const freeBeds = numberBeds - consistsTotal;
    const freeBedsPercent = (numberBeds - consistsTotal) / (numberBeds / 100);

    const hospitalizationHospitalTotal = (dataItem?.hospitalizationHospital103 || 0) + (dataItem?.hospitalizationHospitalFlow || 0) + (dataItem?.hospitalizationHospitalPlan103 || 0) + (dataItem?.hospitalizationHospitalPlan || 0);
    const hospitalizationRoddomTotal = (dataItem?.hospitalizationRoddom103 || 0) + (dataItem?.hospitalizationRoddomFlow || 0) + (dataItem?.hospitalizationRoddomPlan103 || 0) + (dataItem?.hospitalizationRoddomPlan || 0);

    return new PatientMovementCalculatedFields(consistedTotal, consistsTotal, consistsMen, consistsWomen, freeBeds, freeBedsPercent, hospitalizationHospitalTotal, hospitalizationRoddomTotal);
  }
}
