import {Comparer} from "@progress/kendo-data-query";
import {UniqueKeyOrNeverType} from "../types/object-types";
import {ArrayHelper} from "../../helpers/arrayHelper";

/** @deprecated Интерфейс сравнения двух объектов */
interface IComparer{
  /** Равны ли объекты. Перебирает все зарегистрированные поля до ПЕРВОГО НЕ РАВНОГО */
  get isEquals(): boolean;
}

/** @deprecated Интерфейс сравнения двух объектов */
interface IComparer2{
  /**
   * Равны ли объекты.
   * Перебирает все зарегистрированные поля и только после сообщает равны ли или нет.
   * Использовать если в функциях сравнения используется логика и необходимо запуск всех валидаторов.
   * Данный метод по нагрузке больше чем isEquals
   */
  get isEquals2(): boolean;
}

/**@deprecated
 * Сравнивает два объекта(одного типа) на равентство по зарегистрированным полям для сравнения
 * Использовать для частичного сравнивания объектов.
 */
export class ObjectComparer<T> implements IComparer, IComparer2{
  /** Композиция */
  private readonly _objectComparer: ObjectComparer2<T, T>;

  /**@deprecated
   * Конструктор
   * @param item1 Первый объект
   * @param item2 Второй объект
   */
  constructor(private readonly item1: T, private readonly item2: T) {
    this._objectComparer = new ObjectComparer2<T, T>(item1, item2);
  }

  /**@deprecated
   * Зарегистрировать поле для сравнения
   * @param keyItem ключ поля объектов
   * @param comparer функция сравнения
   */
  public registryProp<TProp extends keyof T>(keyItem: TProp, comparer: (item1: PropertyType<T, TProp>, item2: PropertyType<T, TProp>) => boolean = null){
    this._objectComparer.registryProp(keyItem, keyItem, comparer);
    return this;
  }

  public get isEquals(): boolean {
    return this._objectComparer.isEquals;
  }

  get isEquals2(): boolean {
    return this._objectComparer.isEquals2;
  }
}

/**@deprecated
 * Сравнивает два объекта(РАЗНОГО типа) на равенство по зарегистрированным полям для сравнения
 * Использовать, для частичного сравнивания объектов
 */
export class ObjectComparer2<T1, T2> implements IComparer, IComparer2{
  /** Массив всех зарегистрированных полей */
  private _propertyComparers: IComparer[] = [];

  /**@deprecated
   * Конструктор
   * @param item1 Первый объект
   * @param item2 Второй объект
   */
  constructor(private readonly item1: T1,
              private readonly item2: T2) {
  }

  /**
   * Зарегистрировать поле для сравнения
   * @param keyItem1 ключ поля первого объекта
   * @param keyItem2 ключ поля второго объекта
   * @param comparer функция сравнения. По умолчанию ===
   */
  public registryProp<T1Prop extends keyof T1, T2Prop extends keyof T2>(keyItem1: T1Prop,
                                                                        keyItem2: T2Prop,
                                                                        comparer: (item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>) => boolean = null){

    this._propertyComparers.push(new PropertyComparer(this.item1, keyItem1, this.item2, keyItem2, comparer))
    return this;
  }

  public get isEquals(): boolean {
    return !this._propertyComparers.some(x => x.isEquals == false)
  }

  get isEquals2(): boolean {
    let isEquals = true;

    for (let i = 0; i < this._propertyComparers.length; i++) {
      if(!this._propertyComparers[i].isEquals){
        isEquals = false;
      }
    }

    return isEquals;
  }
}



/** @deprecated Класс сравнения поля объектов */
class PropertyComparer<T1, T2, T1Prop extends keyof T1, T2Prop extends keyof T2> {

  /** Функция сравнивает два поля переданных объектов */
  private readonly _comparer : (item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>) => boolean;

  constructor(public readonly item1: T1,
              public readonly item1Prop: T1Prop,
              public readonly item2: T2,
              public readonly item2Prop: T2Prop,
              public readonly comparer: (item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>) => boolean = null) {
    this._comparer = !!comparer ? comparer : this.defaultComparer;
  }

  public get isEquals(){
    return this._comparer(this.item1[this.item1Prop], this.item2[this.item2Prop])
  }

  /** Функция по умолчанию для сравнения полей переданных объектов. Проверяет на === */
  private defaultComparer(item1: PropertyType<T1, T1Prop>, item2: PropertyType<T2, T2Prop>){
    return (item1 as any) === (item2 as any);
  }
}

/** @deprecated Тип поля объекта */
type PropertyType<T, D extends keyof T> = T[D];




type ignoreMissingKeysSummary = 'Если `true`, пропущенные ключи будут проигнорированы. Влияет на поведение, если в одном из сравниваемых объектов ключ присутствует как в другом отсутствует. true - пропускает сравнение поля, false - объекты не равны.';

/**
 * Сравнивает два поля типа `T`.
 *
 * @template T Тип поля, которые сравниваются.
 * @param value1 первое значение.
 * @param value2 второе значение.
 * @returns {boolean} Возвращает `true`, если поля равны, иначе `false`.
 */
type PropertyCompareFuncType<T> = (value1: T, value2: T) => boolean;

/** Тип содержащий поля подлежащие сравнению в методе {@link objectComparerFactory}<br> */
type ObjectComparePropertyType<T> = {
  [K in keyof T]: true | PropertyCompareFuncType<T[K]>
}

/** Тип объекта, содержащий функции сравнения */
type PropertiesType<T> = {
  [K in keyof T]: PropertyCompareFuncType<T[K]>;
};

/** Тип объекта, содержащий поля на удаления */
type DeleteType<T> = {
  [K in keyof T]: true
}

/**
 * Класс позволяет сравнивать два объекта между собой по полям.<br>
 * Переданные объекты могут лишь частично содержать поля типа T.<br>
 * @example
 * class Class1{
 *   constructor(public var1: number, public var2: Date, public var3: boolean){
 *
 *   }
 * }
 *
 * const comparer = new ObjectComparer3<Pick<Class1, 'var1' | 'var2'>>({
 *   var1: true, //будет сравнивать поля на ===
 *   var2: (a, b) => a?.getTime() === b?.getTime() //Своя функция сравнения, в данном случае дату только так сравнить
 *   //var3 поле сравниваться не будет, оно отсутствует в закрывающем типе( Pick<Class1, 'var1' | 'var2'> )
 * })
 */
export class ObjComparer<T>{
  public readonly keyofProperties: ReadonlyArray<keyof T>;
  /** Объект содержащий функции сравнения для каждого поля */
  public readonly properties: Readonly<PropertiesType<T>>;

  /**
   * Конструктор
   * @param properties - Объект настроек полей подлежащих сравнению. Если значение поля является true вместо функции, то будет происходить сравнение на ===.
   */
  constructor(properties: Readonly<ObjectComparePropertyType<T>>) {
    this.keyofProperties = Object.keys(properties) as Array<keyof T>;

    const tempProperties: PropertiesType<T> = {} as any;
    for (let propertyName of this.keyofProperties) {
      tempProperties[propertyName] = typeof properties[propertyName] === 'function'
        ? properties[propertyName]
        : ObjComparer.defaultComparer as any;
    }

    this.properties = tempProperties;
  }

  /**
   * Извлечь поля участвующие в сравнении объекта.<br>
   * Вернет новый экземпляр {@link ObjComparer}
   * @param keys объект где ключ - название поля, значение === true
   * @example
   * class Class1{
   *   constructor(public var1: number, public var2: boolean){
   *
   *   }
   *
   *   public static readonly comparer = new ObjComparer<Class1>({var1: true, var2: true})
   * }
   *
   * //Данный сравнитель будет сравнивать только поле var2
   * const comparer = Class1.comparer.pick({var2: true})
   */
  public pick<TProps extends Partial<DeleteType<T>>>(keys: TProps): ObjComparer<Pick<T, Extract<keyof TProps, keyof T>>> {
    const tempProperties: any = {};

    for (const key of Object.keys(keys)) {
      tempProperties[key] = this.properties[key];
    }

    return new ObjComparer(tempProperties);
  }

  /**
   * Удалить поля участвующие в сравнении объекта.<br>
   * Вернет новый экземпляр {@link ObjComparer}
   * @param keys объект где ключ - название поля, значение === true
   * @example
   * class Class1{
   *   constructor(public var1: number, public var2: boolean){
   *
   *   }
   *
   *   public static readonly comparer = new ObjComparer<Class1>({var1: true, var2: true})
   * }
   *
   * //Данный сравнитель будет сравнивать только поле var2
   * const comparer = Class1.comparer.delete({var1: true});
   */
  public delete<TProps extends Partial<DeleteType<T>>>(keys: TProps): ObjComparer<Omit<T, keyof TProps>> {
    const tempProperties = { ...this.properties };

    for (const key in keys) {
      delete tempProperties[key as any];
    }

    return new ObjComparer<Omit<T, keyof TProps>>(tempProperties as any);
  }

  /**
   * Добавить поля к сравнению.
   * @param properties - объект, где ключ поле. значение функция сравнения или true.
   * @example
   * class Class1 {
   *   constructor(public var1: number){ }
   *
   *   public static comparer = new ObjComparer<Class1>({var1: true});
   * }
   *
   * class Class2 {
   *   constructor(public var2: number, public var3: boolean){ }
   * }
   *
   * //Допустим, есть тип который объединяет поля из класса Class1 и Class2. Для создания сравнивателя объединяем через методом add()
   * const expandedComparer = Class1.comparer.add<Class2>({var3: true}) //Теперь будет сравнивать по полям var1 и var3
   */
  public add<U>(properties: keyof U extends keyof T ? never : Readonly<ObjectComparePropertyType<U>>): ObjComparer<T & U> {
    const tempProperties = { ...this.properties, ...properties } as ObjectComparePropertyType<T & U>;

    return new ObjComparer<T & U>(tempProperties);
  }

  /**
   * Объединить.<br>
   * @param comparer сравнитель с которым объединять. Не допускается пересечение полей, если тип never значит пересекается
   * @example
   * class Class1{
   *   constructor(public var1: number){
   *
   *   }
   *
   *   public static comparer = new ObjComparer<Class1>({var1: true});
   * }
   *
   * class Class2{
   *   constructor(public var2: boolean){
   *
   *   }
   *
   *   public static comparer = new ObjComparer<Class2>({var2: true});
   * }
   *
   * //Пример НЕ валидной ситуации
   * const comparer = Class1.comparer.merge(Class1.comparer) //Будет ругаться что метод должен принять тип never. Причина: пересечение названий полей
   *
   * //Валидный пример
   * const comparer = Class1.comparer.merge(Class2.comparer) //В классах нет пересечений названий полей.
   */
  public merge<TMerge>(comparer: Extract<keyof T, keyof TMerge> extends never ? ObjComparer<TMerge> : never): ObjComparer<T & TMerge> {
    const mergedProperties: any = {
      ...this.properties,
      ...comparer.properties
    };

    return new ObjComparer<T & TMerge>(mergedProperties);
  }

  /**
   * Сравнить два объекта.
   * Поддерживает передачу частичных типов.
   * @param obj1 - первый частичный объект
   * @param obj2 - второй частичный объект
   * @param ignoreMissingKeys ({@link ignoreMissingKeysSummary})
   */
  public compare(obj1: Partial<T>, obj2: Partial<T>, ignoreMissingKeys: boolean = true): boolean{
    if(obj1 === obj2){
      return true;
    }

    if (!obj1 || !obj2) {
      return false;
    }

    for (let propertyName of this.keyofProperties) {
      const hasInObj1 = propertyName in obj1;
      const hasInObj2 = propertyName in obj2;

      if (!hasInObj1) { //Отсутствует в первом объекте
        if(!hasInObj2 || ignoreMissingKeys){
          continue;
        }
        return false;
      } else { //Присутствует в первом объекте
        if(hasInObj2){ //Присутствует во втором объекте
          if(this.properties[propertyName](obj1[propertyName], obj2[propertyName])){
            continue; //Продолжаем если поля в объектах равны
          }
          return false;
        }

        if(ignoreMissingKeys){
          continue;
        }
        return false;
      }
    }

    return true;
  }

  /**
   * Получить как функцию сравнения поля.
   * Использовать если необходимо сравнивать в объекте поле сложного типа.
   * @param ignoreMissingKeys ({@link ignoreMissingKeysSummary})
   * @example
   * class Class1 {
   *   constructor(public var1: boolean){
   *
   *   }
   *
   *   public static readonly comparer = new ObjComparer<Class1>({
   *     var1: true
   *   })
   * }
   *
   * class Class2 {
   *   constructor(public inner: Class1){
   *
   *   }
   * }
   *
   * const comparer = new ObjComparer<Class2>({
   *   inner: Class1.comparer.asPropertyComparerFunc(false) //Определяем для поля inner функцию сравнения из ObjComparer
   * })
   *
   */
  public asPropertyCompareFunc(ignoreMissingKeys: boolean = true): PropertyCompareFuncType<Partial<T>> {
    return (obj1, obj2) => {
      return this.compare(obj1, obj2, ignoreMissingKeys);
    };
  }

  /**
   * Получить как функцию сравнения массивов
   * @param ignoreMissingKeys ({@link ignoreMissingKeysSummary})
   * @param strictOrder должен ли соблюдаться порядок элементов в массивах. С true будет работать быстрее.
   * @example
   * class Class1 {
   *   constructor(public var1: boolean){
   *
   *   }
   *
   *   public static readonly comparer = new ObjComparer<Class1>({
   *     var1: true
   *   })
   * }
   *
   * class Class2 {
   *   constructor(public arr: Class1[]){
   *
   *   }
   * }
   *
   * //Создаем для Class2
   * const comparer = new ObjComparer<Class2>({
   *   arr: Class1.asArrayCompareFunc // Вот применение данной функции
   * })
   */
  public asArrayCompareFunc(ignoreMissingKeys: boolean = true, strictOrder: boolean = false): PropertyCompareFuncType<Array<Partial<T>>>{
    const propComparer = this.asPropertyCompareFunc(ignoreMissingKeys);

    return strictOrder
      ? (arr1, arr2) => {
        return ArrayHelper.equals(arr1, arr2, propComparer);
      }
      : (arr1, arr2) => {
        return  ArrayHelper.equals2(arr1, arr2, propComparer);
      }
  }

  /** Функция сравнения по умолчанию. Сравнивает на === */
  public static readonly defaultComparer: (val1: any, val2: any) => boolean =
    (val1, val2) => val1 === val2;

  /** Функция сравнения для {@link Date} */
  public static readonly dateComparer: (date1: Date, date2: Date) => boolean =
    (date1, date2) => date1?.getTime() === date2?.getTime()
}
