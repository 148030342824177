import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebApi1Service } from '../web-api1.service';
import { Observable } from 'rxjs';
import { EmployeeNamesDeclension } from '../../../../classes/domain/POCOs/stafflist/EmployeeNamesDeclension';

@Injectable({
  providedIn: 'root',
})
export class Api1EmployeeNamesDeclensionControllerService implements OnDestroy {

  constructor(private httpClient: HttpClient, private webApi1Service: WebApi1Service) {
  }

  /** Получить склонения по падежам для сотрудника по его идентификатору и ФИО в именитольном падеже */
  public getEmployeeNamesDeclensionsForEmployee$(employeeId: number, employeeFullName: string): Observable<EmployeeNamesDeclension> {
    return this.httpClient
      .post<EmployeeNamesDeclension>(
        this.webApi1Service.controllers.employeeNamesDeclension.actions.getEmployeeNamesDeclensionsForEmployee.toString(),
        { employeeId, employeeFullName },
      );
  }

  /** Получить или создать склонения по падежам для сотрудника по его идентификатору и ФИО в именитольном падеже */
  public getOrCreateEmployeeNamesDeclensionsForEmployee$(employeeId: number, employeeFullName: string): Observable<EmployeeNamesDeclension> {
    return this.httpClient
      .post<EmployeeNamesDeclension>(
        this.webApi1Service.controllers.employeeNamesDeclension.actions.getOrCreateEmployeeNamesDeclensionsForEmployee.toString(),
        { employeeId, employeeFullName },
      );
  }

  /** Сгенерировать склонения ФИО сотрудника по падежам из переданного ФИО в именитольном падеже */
  public generateEmployeeNamesDeclensionsForEmployeeFullName$(employeeFullName: string): Observable<EmployeeDeclensionDTO> {
    return this.httpClient
      .post<EmployeeDeclensionDTO>(
        this.webApi1Service.controllers.employeeNamesDeclension.actions.generateEmployeeNamesDeclensionsForEmployeeFullName.toString(),
        { employeeFullName },
      );
  }

  /** Сохранить */
  public save$(entity: SaveEmployeeDeclensionDTO): Observable<[]> {
    return this.httpClient
      .post<[]>(
        this.webApi1Service.controllers.employeeNamesDeclension.actions.save.toString(),
        entity,
      );
  }

  ngOnDestroy(): void {
  }
}

export type EmployeeDeclensionDTO = Pick<EmployeeNamesDeclension, 'nominative' | 'accusative' | 'dative' | 'genitive' | 'instrumental' | 'prepositional'>;

export interface SaveEmployeeDeclensionDTO {
  id: number,
  timestamp: [],
  declensions: EmployeeDeclensionDTO
}
