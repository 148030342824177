import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ICode } from 'src/app/classes/domain/POCOs/timesheet_table/Code';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { DataSource } from '../../../../../../../src/app/classes/array-data-sources/data-source';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../src/app/services/data-source-services/data-source.service';
import { Observable, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-edit-occupations-codes-form',
  templateUrl: './edit-occupations-codes.component.html',
  styleUrls: ['./edit-occupations-codes.component.html'],
  providers: [],
})
@traceClass('EditOccupationsCodesFormComponent')
export class EditOccupationsCodesFormComponent implements OnInit {

  @Input('dataSource') public dataSource: EditOccupationsCodesFormComponent_DataSourceService;
  @Input('codesIdsArray') public codesIdsArray: number[];
  @Output('onSave') public onSaveEventEmitter = new EventEmitter<number[]>();
  @Output('onCancel') public onCancelEventEmitter = new EventEmitter<boolean>();

  public codes$: Observable<ICode[]>;
  public form: FormGroup<IEditOccupationsCodesFormComponentForm>;

  constructor(private tracerService: TracerServiceBase) {
  }

  @traceFunc()
  ngOnInit() {
    this.codes$ = this.dataSource.data.codes
      .reloadData$({ codeIds: null })
      .pipe(
        switchMap(s => s.data$),
        startWith([]),
      );

    this.form = this.createForm(this.codesIdsArray);
  }

  @traceFunc()
  public onClickSave() {
    this.onSaveEventEmitter.emit(this.form.value.codesIdsArray);
  }

  @traceFunc()
  public onClickCancel() {
    this.onCancelEventEmitter.emit(false);
  }

  /** Создание формы добавления*/
  @traceFunc()
  private createForm(item: Array<number>) {
    const form = new FormGroup({
      codesIdsArray: new FormControl(item ?? null),
    });

    form.markAllAsTouched();
    form.valueChanges.subscribe(value => {
      this.tracerService.add2(`Изменена форма редактирования кодов должностей`, { obj: value });
    });
    return form;
  }
}

export interface IEditOccupationsCodesFormComponentForm {
  /** Выбранные коды табельного учёта */
  codesIdsArray: FormControl<number[]>;
}

export class EditOccupationsCodesFormComponent_DataSourceService extends DataSource<EditOccupationsCodesFormComponent_Data> {
}

export class EditOccupationsCodesFormComponent_Data {
  constructor(public codes: ArrayDataSourceIEntityIdServiceWithParamsBase<any, ICode>) {
  }
}
