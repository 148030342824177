<!-- Контейнер используется для прикрепления в "appendTo", например для dropdowntree -->
<ng-container #containerForAppend></ng-container>

<!-- Основной контейнер -->
<div *ngIf="field && form && formControl"
  class='popup-content'

  [style.width.px]="field.settings.styleSettings?.width"
  [style.width.%]="field.settings.styleSettings?.widthPercents"
  [style.min-width.px]="field.settings.styleSettings?.minWidth"
  [style.min-width.%]="field.settings.styleSettings?.minWidthPercents"
  [style.max-width.px]="field.settings.styleSettings?.maxWidth"
  [style.max-width.%]="field.settings.styleSettings?.maxWidthPercents"
  [style.min-height.px]="field.settings.styleSettings?.minHeight"
  [style.min-height.%]="field.settings.styleSettings?.minHeightPercents"
  [style.max-height.px]="field.settings.styleSettings?.minHeight"
  [style.max-height.%]="field.settings.styleSettings?.minHeightPercents"
  [style.height.px]="field.settings.styleSettings?.height"
  [style.height.%]="field.settings.styleSettings?.heightPercents"
>
  <form class="k-form" [formGroup]="form">
    <kendo-label *ngIf="labelTitle" [for]="directoryEditControl" >{{labelTitle}}:</kendo-label>
    <app-directory-edit-control #directoryEditControl [field]="field" [valueFormControl]="formControl" [appendContainer]="appendContainer"></app-directory-edit-control>
    <app-display-validation-errors class="k-form-error" [control]="formControl"></app-display-validation-errors>
    <div class="k-form-buttons">
      <button kendoButton icon="check" [disabled]="!form.valid" (click)="onClickSave($event)" [primary]="true">Сохранить</button>
    </div>
  </form>
</div>
