import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { FreeRatesDTO } from "src/app/services/webApi/webApi1/controllers/api1-staff-units-control.service";

/**
 * Оператор дает возможность задать минимальное и максимальное значение свободной ставки
 * @param minValue Минимальное значение свободной ставки (по умолчанию 0)
 * @param maxValue Максимальное значение свободной ставки (по умолчанию 1)
 * @returns
 */
export function exMinMaxStaffUnitFreeRate(minValue: number = 0, maxValue: number = 1): (source$: Observable<FreeRatesDTO>) => Observable<FreeRatesDTO> {
  return source$ => {
    return source$.pipe(
      tap(d=> d.freeRates.forEach(fr=>
        {
           // Ставка не может быть < minValue
           fr.rate = fr.rate <= minValue ? minValue : fr.rate;

           //Ставка не может быть > maxValue
           fr.rate = fr.rate >= maxValue ? maxValue : fr.rate;
        }))
    );
  }
}
