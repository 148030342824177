import { Injectable, OnDestroy } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { DirectoryEditService } from 'src/app/components/directory-edit/services/directory-edit.service';
import { DisplayErrorsService } from 'src/app/components/display-errors/services/display-errors.service';
import { DateHelper } from 'src/app/helpers/dateHelper';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { traceFunc } from 'src/app/modules/trace/decorators/func.decorator';
import { trace } from 'src/app/modules/trace/operators/trace';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { KendoNotificationService } from 'src/app/services/kendo-notification.service';
import { AddSubdivisionFormComponent, AddSubdivisionFormComponent_Data, AddSubdivisionFormComponent_DataSourceService } from '../../add/add-subdivision-form.component';
import { SubdivisionsDirectoryTreeListComponent } from '../subdivisions-directory-tree-list.component';

@Injectable()
@traceClass('SubdivisionsDirectoryTreeListComponentService')
export class SubdivisionsDirectoryTreeListComponentService implements OnDestroy {

  private readonly streams$ = { unsubscribes: new ReplaySubject<any>(1) };
  private addDialogRef: DialogRef;

  constructor(private readonly displayErrorsService: DisplayErrorsService,
              private readonly directoryEditService: DirectoryEditService,
              private readonly dialogService: DialogService,
              private readonly kendoNotificationService: KendoNotificationService,
              private readonly traceService: TracerServiceBase) {
  }


  /** Показать диалог добавления подразделений */
  @traceFunc()
  public showAddDialog(component: SubdivisionsDirectoryTreeListComponent) {
    this.addDialogRef?.close();

    this.addDialogRef = this.dialogService.open({
      title: `Добавление подразделения`,
      content: AddSubdivisionFormComponent,
      width: '90%',
    });

    const instance = this.addDialogRef.content.instance as AddSubdivisionFormComponent;

    instance.onSaveEventEmitter.subscribe(entity => {
      this.addDialogRef?.close();
      component.dataSourceService.addSubdivision$(entity)
        .pipe(trace(this.traceService), take(1), takeUntil(this.streams$.unsubscribes))
        .subscribe({
          next: data => {
            if (DateHelper.isDateIntervalsIntersect(data.startDate, data.endDate, component.dataSourceService.paramsDataSource.data.reportRangeStartDate, component.dataSourceService.paramsDataSource.data.reportRangeEndDate)) {
              this.kendoNotificationService.showSuccess({ content: 'Подразделение добавлено' });
            } else {
              this.kendoNotificationService.showSuccess({ content: 'Подразделение добавлено, но оно не входит в диапазон выборки дат отчёта, поэтому оно не будет отображено.', hideAfter: 7000 });
            }
          },
          error: error => this.displayErrorsService.handleError(error),
        });
    });

    const dataSource = new AddSubdivisionFormComponent_DataSourceService();

    component.subdivisionDataSourceService$
      .pipe(
        takeUntil(this.streams$.unsubscribes),
        takeUntil(this.addDialogRef.result),
      )
      .subscribe(subdivisions => dataSource.setData(new AddSubdivisionFormComponent_Data(subdivisions)));
    instance.dataSource = dataSource;

    instance.onCancelEventEmitter.subscribe(() => this.addDialogRef?.close());
  }

  @traceFunc()
  ngOnDestroy() {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

}
