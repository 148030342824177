/** Помощник для числа */
export class NumberHelper {
  /**
   * Округлить число до переданной точности<br>
   * @param value значение подлежащее округлению
   * @param digits количество знаков после запятой. Если < 0, то не округляется.
   */
  public static round(value: number, digits: number){
    if(digits < 0) {
      return value;
    }

    digits = Math.pow(10, digits);
    return Math.round(value * digits) / digits;
  }
}
