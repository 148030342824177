<div kendoDialogContainer></div>

<kendo-treelist [kendoTreeListFlatBinding]="dataSourceService.dataSource.data$ | async"

                kendoTreeListSelectable
                [selectedItems]="selection.forDirective?.selectedItems"
                [itemKey]="selection?.forDirective?.itemKey"
                (selectedItemsChange)="selection?.forDirective?.onSelectedKeysChange($event)"
                [selectable]="{
                  mode: 'single',
                  enabled: true
                }"

                [sortable]="{ mode: 'multiple' }"
                [sort]="[{ field: 'type', dir: 'desc' }]"

                kendoTreeListExpandable
                [initiallyExpanded]="true"

                KendoTreeListExpanded
                idField="occupationTypeId"
                parentIdField="occupationTypeParentId"

                (dbCellClick)="onDbCellClick($event)"
>
  <ng-template kendoTreeListToolbarTemplate>
    <button (click)="onClickAddOccupationType()"
            kendoButton
            size="small"
            icon="plus"
            [primary]="true"
    >
      Добавить
    </button>
    <button (click)="onClickDelete()"
            [disabled]="!canDelete"
            kendoButton
            size="small"
            icon="delete"
            [primary]="true"
    >
      Удалить
    </button>
  </ng-template>

  <kendo-treelist-column [expandable]="true" title="Категории персонала" [field]="fieldsNames.text.toString()" class="limit-staff-unit-cell-subdivision">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <div *ngIf="dataItem.type === 'occupationType'; else subdivisionBlock" class="limit-staff-unit-cell-occupationType">
        <span>
          {{ dataItem.text }}
        </span>
        <button kendoButton
                icon="plus"
                title="Добавить подразделение"
                class="show-hide-limit-staff-units-columns-button"
                [primary]="true"
                (click)="onClickAddSubdivision(dataItem)"
        ></button>
      </div>
      <ng-template #subdivisionBlock>
        <div class="limit-staff-unit-cell-subdivision-em">{{ dataItem.text }}</div>
      </ng-template>
    </ng-template>
  </kendo-treelist-column>

  <kendo-treelist-column-group title="Лимит штатных единиц" *ngIf="(rateColumns$ | async)?.length">
    <ng-template kendoTreeListHeaderTemplate
                 let-column
                 let-columnIndex="columnIndex"
    >
      <div class="limit-staff-units-column-header-div">
        <span class="k-column-title">{{ column.title }}</span>
        <button kendoButton
                icon="more-horizontal"
                title="Раскрыть/Свернуть"
                (click)="toggleHideEmptyColumns()"
                class="show-hide-limit-staff-units-columns-button"
                [primary]="!(isHideEmptyColumns$ | async)"
        ></button>
      </div>
    </ng-template>

    <ng-container *ngFor="let column of rateColumns$ | async">
      <kendo-treelist-column [title]="column.title"
                             [resizable]="false"
                             [width]="150"
                             [field]="fieldsNames.rates.toString()"
                             class="no-padding"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem>
          <ng-container *ngTemplateOutlet="rateCell; context: { rate: getRatesDisplayValue(dataItem, column.financingSourceId), info: getRateColumnInfo(dataItem.id, column.financingSourceId) }"></ng-container>
          <ng-template #rateCell let-rate="rate" let-info="info">
            <span class="whole-cell"
                  [class.error-cell]="info?.hasError">
              {{ rate | kendoNumber : { minimumFractionDigits: 1, maximumFractionDigits: 2 } }}
              {{ rate ? '' : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' }}
              <span class="unallocated-span"
                    *ngIf="dataItem.type === 'occupationType' && info.unallocated !== 0">
                / {{ info.unallocated | kendoNumber: { minimumFractionDigits: 1, maximumFractionDigits: 2 } }}
              </span>
          </span>
          </ng-template>
        </ng-template>
      </kendo-treelist-column>
    </ng-container>
  </kendo-treelist-column-group>

  <kendo-treelist-column title="Дата начала" [field]="fieldsNames.startDate.toString()">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      {{ dataItem.startDate | kendoDate: "dd.MM.yyyy" }}
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column title="Дата окончания" [field]="fieldsNames.endDate.toString()">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      {{ dataItem.endDate | kendoDate: "dd.MM.yyyy" }}
    </ng-template>
  </kendo-treelist-column>
  <kendo-treelist-column title="Комментарий" [field]="fieldsNames.comment.toString()"></kendo-treelist-column>
</kendo-treelist>