
<div class="main-code-div">
  <div class="code-div">Введите код подтверждения <span class="code-span">{{code}}</span></div>
  <form #templateForm="ngForm" novalidate>
    <kendo-maskedtextbox
      class="text-box-div ng-touched"
      #codeTextBox
      [style.width.px]="45"
      (valueChange)="onValueChange($event)"
      [mask]="mask"
      [readonly]="codeControlValue === code"
      name="code"
      [(ngModel)]="codeControlValue"

      required
      [pattern]="code"
    >
    </kendo-maskedtextbox>
  </form>
</div>
