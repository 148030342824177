import {DbChangedListener_Entity} from "../../services/signal-r/listeners/db-changed-listener";

/** Группа. Событие signalR и массив элементов данных */
export type DataStateBuilder_GroupType<TSignalR, TSource> = {
  signalR: DbChangedListener_Entity<TSignalR>,
  items: TSource[]
}

/** Класс элемента состояния данных */
export class DataState_Item<TSignalR, TSource>{
  /**
   * Конструктор
   * @param signalR Событие signalR. Возможное использование: Для добавочной фильтрации. К примеру: изменилось ли конкретное поле
   * @param dataItem Элемент в источнике данных
   */
  constructor(public signalR: DbChangedListener_Entity<TSignalR>,
              public dataItem: TSource) {
  }
}

/** Класс элемента состояния данных с состоянием */
class DataState_Item_HasState<TSignalR, TSource> extends DataState_Item<TSignalR, TSource>{
  /**
   * Конструктор
   * @param state Состояние
   * @param signalR Событие signalR. Возможное использование: Для добавочной фильтрации. К примеру: изменилось ли конкретное поле
   * @param dataItem Элемент в источнике данных
   */
  constructor(public readonly state: DbChangedListener_Entity<any>['state'],
              signalR: DbChangedListener_Entity<TSignalR>,
              dataItem: TSource) {
    super(signalR, dataItem);
  }
}

/** Класс содержит состояние данных по событиям signalR */
export class DataState<TSignalR, TSource>{
  /** Источник данных, по которому сгруппированы элементы */
  public readonly source: DataState_Item_HasState<TSignalR, TSource>[]
  /** Удаленные */
  public readonly deleted: DataState_Item<TSignalR, TSource>[] = [];
  /** Модифицированные */
  public readonly modified: DataState_Item<TSignalR, TSource>[] = [];
  /** Добавленные */
  public readonly added: DataState_Item<TSignalR, TSource | null>[] = [];

  constructor(source: DataState_Item_HasState<TSignalR, TSource>[]) {
    this.source = source;

    //Заполняем массивы
    source.forEach(item => {
      const arr = item.state === 'deleted' ? this.deleted : (item.state === 'modified' ? this.modified : this.added);
      arr.push(item);
    });
  }
}

/**
 * Тип поля состояния события signalR<br>
 * {@link DbChangedListener_Entity.dbState} или {@link DbChangedListener_Entity.state}
 */
export type DataStateBuilder_PropStateType = keyof Pick<DbChangedListener_Entity<any>, 'dbState' | 'state'>;

/**
 * Строитель состояния данных по событиям signalR<br>
 * Использовать, если данные построены НЕ с учетом версионности<br>
 */
export class DataStateBuilder<TSignalR, TSource>{

  /**
   * Конструктор
   * @param signalR Объекты событий signalR
   * @param data Данные компонента. Если передан undefined, то метод build вернет пустой массив
   * @param comparerFn Функция сравнения объекта signalR с объектом данных компонента
   * @param statePropSource По какому состоянию события signalR определять состояние.
   */
  constructor(protected readonly signalR: DbChangedListener_Entity<TSignalR>[],
              protected readonly data: TSource[] | undefined | null,
              protected readonly comparerFn: (signalRObj: TSignalR, item: TSource) => boolean,
              protected readonly statePropSource: DataStateBuilder_PropStateType = 'state') {
  }

  /** Построить состояние */
  public build_(): DataState<TSignalR, TSource>{
    if(!this.data){
      return new DataState<TSignalR, TSource>([]);
    }

    const unions = this.union(this.signalR, this.data, this.comparerFn);

    const source = unions
      .map(item => new DataState_Item_HasState(this.getState(item, this.statePropSource), item.signalR, item.dataItem));

    return new DataState<TSignalR, TSource>(source);
  }

  /** Получить состояние */
  protected getState(item: DataState_Item<TSignalR, TSource>, statePropSource: DataStateBuilder_PropStateType): DbChangedListener_Entity<any>['state']{
    return item.signalR[statePropSource];
  }


  /** Объединяет signalR объекты с объектом данных к которому относится */
  private union(signalR: DbChangedListener_Entity<TSignalR>[], data: TSource[], comparerFn: (signalRObj: TSignalR, item: TSource) => boolean){
    const groupJoined = this.groupJoin(signalR, data, comparerFn);

    this.pushNullIfArrayEmpty(groupJoined); //Не допускаем потерю добавленных без данных

    return groupJoined
      .map(x => this.convert1(x))
      .reduce((prev, current) => [...prev, ...current], []);
  }

  /** На каждый элемент signalR соберет все элементы данных по функции сравнения */
  private groupJoin(signalR: DbChangedListener_Entity<TSignalR>[], data: TSource[], comparerFn: (signalRObj: TSignalR, item: TSource) => boolean): DataStateBuilder_GroupType<TSignalR, TSource>[]{
    return signalR
      .map(sR => {
        const currentOrOrigin = sR.currentOrOrigin;
        return {
          signalR: sR,
          items: data.filter(d => comparerFn(currentOrOrigin, d))}
        }
      );
  }

  /** Конвертор 1. Соединит каждый элемент данных с ключом группы */
  private convert1(group: DataStateBuilder_GroupType<TSignalR, TSource>): DataState_Item<TSignalR, TSource>[]{
    return group.items.map(item => {
      return new DataState_Item(group.signalR, item)
    })
  }

  /** Необходим для предотвращения потери signalR событий с состоянием added, если отсутствуют элементы в данных */
  private pushNullIfArrayEmpty(groups: DataStateBuilder_GroupType<TSignalR, TSource>[]){
    return groups.filter(x => x.items.length == 0) //С пустым массивом данных
      .forEach(x => x.items.push(null)) //добавляем null в пустой массив для предотвращения удаления записи
  }
}
