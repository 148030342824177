import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WebApi1Service } from '../web-api1.service';
import { Employee } from '../../../../classes/domain/POCOs/stafflist/Employee';
import { defer, Observable, of, ReplaySubject, share, tap } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { VersioningItem } from 'src/app/classes/directories/edit/versioningItem';
import { HttpParamsHelper } from 'src/app/helpers/httpParamsHelper';
import { IdenticalRequestInterceptor } from '../../../../modules/auth/interceptors/identical-request.interceptor';
import { EditEndDateDto, IdAndRowVersionType } from "./shared-DTOs/edit-end-date-dto";
import { EmployeeDeclensionDTO } from './api1-employee-names-declension-controller.service';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';

@Injectable({
  providedIn: 'root',
})
export class Api1EmployeeControllerService implements OnDestroy {
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  };

  private _currentEmployee_cached$: Observable<Employee> = null;

  /** Получить сотрудника для текущего пользователя(Используется кэш) */
  public get currentEmployee_cached$(): Observable<Employee> {
    if (this._currentEmployee_cached$ == null) {
      this._currentEmployee_cached$ = defer(() => {
        return !this.authService.user.MedStaffId ?
          of(null) :
          this.getByOwnerId$(this.authService.user.MedStaffId, true);
      }).pipe(share(), tap(value => this._currentEmployee_cached$ = of(value).pipe(shareReplay(1))));
    }

    return this._currentEmployee_cached$;
  }

  constructor(private httpClient: HttpClient,
              private webApi1Service: WebApi1Service,
              private authService: AuthService) {
    authService.isAuth$.pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => { //Очищаем кешь при выходе пользователя
      if (!value) {
        this._currentEmployee_cached$ = null;
      }
    });
  }

  public deleteEmployee$(ownerId: number, timestamp: any): Observable<boolean> {
    return this.httpClient.post<boolean>(
      this.webApi1Service.controllers.employee.actions.deleteEmployee.toString(),
      {
        ownerId: ownerId,
        timestamp: timestamp
      },
    );
  }

  /** Получить по id на текущую дату */
  public getByOwnerId$(ownerId: number, skipIdenticalInterceptor = false): Observable<Employee> {
    return this.httpClient.get<Employee>(this.webApi1Service.controllers.employee.actions.getByOwnerId.toString(),
      {
        params: new HttpParams({
          fromObject: {
            ownerId: ownerId.toString(),
          },
        }),
        headers: skipIdenticalInterceptor ? IdenticalRequestInterceptor.getSkipHeader() : undefined,
      });
  }

  /** Удалить версию по Id */
  public deleteVersion$(versionId: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(this.webApi1Service.controllers.employee.actions.deleteVersion.toString(),
      { params: HttpParamsHelper.getParams([['versionId', versionId]]) },
    );
  }

  /** Получить работающих сотрудников на год и месяц */
  public getWorkingEmployees(yearId: number, monthId: number): Observable<Array<IGetWorkingEmployeesResponse>> {
    return this.httpClient.get<Array<any>>(
      this.webApi1Service.controllers.employee.actions.getWorkingEmployees.toString(),
      {
        params: new HttpParams({ fromObject: { yearId: yearId.toString(), monthId: monthId.toString() } }),
      },
    ).pipe(map(value => {
      return value.map(item => new class implements IGetWorkingEmployeesResponse {
        ownerId: number = item.ownerId;
        code: string = item.code;
        lastName: string = item.lastName;
        firstName: string = item.firstName;
        patronymic: string = item.patronymic;
      });
    }));
  }

  /** Получить сотрудников на дату и/или по списку OwnerIds */
  public getForDate$(date: Date, startDate: Date = null, endDate: Date = null, ownerIds: number[] = null) {
    return this.httpClient
      .post<Array<Employee>>(this.webApi1Service.controllers.employee.actions.getForDate.toString(),
        {
          startDate: startDate,
          date: date,
          endDate: endDate,
          ownerIds: ownerIds,
        });
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<Array<VersioningItem>> {
    return this.httpClient
      .get<Array<VersioningItem>>(this.webApi1Service.controllers.employee.actions.getVersionsByOwnerIdAndActionId.toString(),
        {
          params: HttpParamsHelper.getParams([['ownerId', ownerId], ['actionId', actionId]]),
        });
  }

  public addEmployeeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.httpClient
      .post<VersioningItem>(this.webApi1Service.controllers.employee.actions.addEmployeeVersion.toString(),
        {
          OwnerId: versioningEntity.ownerId,
          ActionId: actionId,
          Data: versioningEntity,
        });
  }

  public editEmployeeVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.httpClient
      .post<VersioningItem>(this.webApi1Service.controllers.employee.actions.editEmployeeVersion.toString(),
        {
          OwnerId: versioningEntity.ownerId,
          ActionId: actionId,
          Data: versioningEntity,
        });
  }

  /** Добавить Employee */
  public add$(entity: AddEmployeeDTO): Observable<Employee> {
    return this.httpClient
      .post<Employee>(this.webApi1Service.controllers.employee.actions.add.toString(), entity);
  }

  /** Сохранить Employee */
  public save$(entity: Employee): Observable<[]> {
    return this.httpClient
      .post<[]>(this.webApi1Service.controllers.employee.actions.save.toString(),
        entity,
      );
  }

  /** Редактировать окончание срока действия */
  public editEndDate$(date: Date, rows: IdAndRowVersionType[], safe: boolean): Observable<boolean>{
    return this.httpClient.post<boolean>(this.webApi1Service.controllers.employee.actions.editEndDate.toString(),
      new EditEndDateDto(date, rows.map(x => { return {id: x.id, timestamp: x.timestamp}}), safe));
  }

  ngOnDestroy() {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}

/** Интерфейс ответа для getWorkingEmployees */
export interface IGetWorkingEmployeesResponse {
  ownerId: number,
  code: string,
  lastName: string,
  firstName: string,
  patronymic: string
}

export interface AddEmployeeDTO extends Pick<Employee, 'firstName' | 'lastName' | 'code' | 'startDate' | 'endDate' | 'comment' | 'patronymic' | 'birthday' | 'gender' | 'profUnion'> {
  isAutogenerateCode: boolean;
  declensions: EmployeeDeclensionDTO;
}
