import {AfterContentInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2} from "@angular/core";

/** Директива, которая позволяет задавать аттрибут placeholder для первого дочернего инпута */
@Directive({
  selector: '[ChildInputPlaceholder]'
})
export class ChildInputPlaceholderDirective implements AfterContentInit{

  /** Устанавливаемый плейсхолдер */
  @Input("childPlaceholder") public get childPlaceholder(){
    return this._placeholderString;
  }
  public set childPlaceholder(value: string) {
    this._placeholderString = value;
    this.setPlaceholderToChildInput();
  }

  /**Устанавливать только для первого дочернего элемента (если true), или для всех (если false) */
  @Input("isOnlyForFirstChild") isOnlyForFirstChild: boolean = true;



  private _placeholderString: string;


  constructor(private element: ElementRef,
              private renderer : Renderer2) {

  }

  ngOnInit(): void {

  }

  ngAfterContentInit(): void {
    if(this.childPlaceholder){
      this.setPlaceholderToChildInput();
    }
  }

  /** Устанавливает плейсхолдер на дочерний инпут */
  private setPlaceholderToChildInput(){
    if(!this.element.nativeElement) return;

    if(this.isOnlyForFirstChild === true) {
      const element = this.element.nativeElement.querySelector("input");
      this.setPlaceholderToElement(element);
    } else {
      const allElements = this.element.nativeElement.querySelectorAll("input");
      allElements.forEach(element => {this.setPlaceholderToElement(element)});
    }
  }

  private setPlaceholderToElement(element: any) {
    if(!element) return;
    this.renderer.setAttribute(element, 'placeholder', this.childPlaceholder);
  }
}
