import {Injectable, OnDestroy} from "@angular/core";
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {AuthService} from "../../modules/auth/services/auth.service";
import {
  defer,
  EMPTY, from,
  lastValueFrom,
  Observable, of,
  pairwise,
  ReplaySubject, retry,
  skip,
  skipWhile,
  switchMap,
  tap, throwError
} from "rxjs";
import {delay, filter, map, mergeWith, takeUntil} from "rxjs/operators";
import {AppSettingsService} from "../app-settings.service";
import {AlertService} from "../alert.service";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceClass} from "../../modules/trace/decorators/class.decorator";
import {traceFunc} from "../../modules/trace/decorators/func.decorator";
import {exDistinctUntilChanged} from "../../operators/ex-distinct-until-changed.operator";
import {RetryStorage} from "../../classes/retry-storage.class";
import {IRetryPolicy, RetryContext} from "@microsoft/signalr/src/IRetryPolicy";
import {CustomRetryConfig} from "../../pipes/classes/custom-retry-config";

/** Метод signalR сообщающий об остановки сервера */
const ServerStoppingMethod = 'ServerStopping';

/** Через какое время устанавливать подключение signalr после остановки сервера */
const RestartConnectionTimeOutAfterServerStopping = 5000;

/** Через какое время устанавливать подключение signalr, если в данный момент оно завершается */
const RestartConnectionIfCurrentStateDisconnecting = {
  delay: 1000,
  count: 60 * 60,
};

/** Расширенный тип подключения */
type HubConnectionWithTokenGuid = HubConnection & {
  /** Идентификатор токена с которым было произведено подключение */
  currentTokenGuid: string;
}

@Injectable({
  providedIn: "root"
})
/** Сервис работы с SignalR */
@traceClass('SignalRService')
export class SignalRService implements OnDestroy {
  /** Нормальное время, за которое signalR подключение должно установиться */
  public static readonly normalConnectionTime = 2000;

  /** Время в миллисекундах через которое сервер будет переподключать signalR соединение */
  private static readonly _serverTimeout = 10 * 1000;

  /** Время в миллисекундах через которое происходит опрос существования signalR соединения */
  private static readonly _keepAliveInterval = 5 * 1000;

  /** Стримы */
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    /** Имеется ли подключение в данный момент */
    hasConnection$: new ReplaySubject<boolean>(1),
  }

  /** @see _serverTimeout */
  public get serverTimeout() {
    return SignalRService._serverTimeout;
  }

  /** @see _keepAliveInterval */
  public get keepAliveInterval(){
    return SignalRService._keepAliveInterval;
  }

  private readonly _hubConnection: HubConnectionWithTokenGuid = this.createHubConnection();
  /** SignalR хаб */
  public get hubConnection(): Pick<HubConnection, 'send' | 'on' | 'off'> {
    return this._hubConnection;
  }

  /** Идентификатор токена с которым было произведено подключение */
  public get hubConnectionTokenGuid() {
    return this._hubConnection?.currentTokenGuid;
  }

  private _hasConnection: boolean = undefined;
  /** Имеется ли подключение в данный момент */
  public get hasConnection() {
    return this._hasConnection;
  }

  private set hasConnection(value: boolean) {
    value = !!value; //преобразуем в boolean
    if (value === this._hasConnection) {
      return;
    }

    this._hasConnection = value;
    this.streams$.hasConnection$.next(value);
  }

  /** Стрим информирование присутствия/отсутствия подключения signalR. Транслирует только уникальные состояния */
  public readonly hasConnection$: Observable<boolean> = this.streams$.hasConnection$;

  /** Конструктор */
  constructor(private readonly appSettingsService: AppSettingsService,
              private readonly authService: AuthService,
              private readonly alertService: AlertService,
              private readonly traceService: TracerServiceBase) {

    //Подпись на отображение окна возможно устаревших данных
    this.authService.isAuth$
      .pipe(
        switchMap(isAuth => {
          if (!isAuth) {
            return EMPTY;
          }

          /** Стрим пропускает все трансляции пока не будет первая установка */
          const skipWhileNotConnection$ = this.streams$.hasConnection$
            .pipe(
              skipWhile(value => value !== true),
            );

          const firstFalse = {state: false, date: Date.now()};

          /** Стрим трансляции если подключение к signalR дольше чем ожидалось */
          const firstFalse$ = of(firstFalse)
            .pipe(
              delay(SignalRService.normalConnectionTime), // Время на ожидание подключение signalR
              takeUntil(skipWhileNotConnection$) // Пока подключение не будет установленно
            );

          return skipWhileNotConnection$
            .pipe(
              map<boolean, typeof firstFalse>(value => ({
                state: value,
                date: Date.now(),
              })),
              mergeWith(firstFalse$)
            );
        }),
        pairwise(),
        filter(value => value[1].state === true), //Интересуют только если произошло подключение
        filter(value => {
          const timeoutSeconds = (value[1].date - value[0].date) / 1000;
          return timeoutSeconds > this.appSettingsService.disconnectedSignalRMaxTime;
        }),
        switchMap(() => {
          return this.alertService.defaultAlertOption.warning()
            .mod(x => {
              x.titleMessage = '';
              x.message = 'За время отсутствия подключения к сети,<br>данные могли устареть<br><br><strong>Рекомендуем сохранить и перезагрузить страницу</strong>'
            })
            .showAlert$({
              ok: {text: 'Понятно', isPrimary: true}
            })
            .pipe(
              takeUntil(this.authService.isAuth$.pipe(skip(1))),
            )
        }),
        takeUntil(this.streams$.unsubscribe),
      )
      .subscribe();

    //Подпись на изменения состояния авторизации пользователя
    this.authService.isAuth$
      .pipe(
        exDistinctUntilChanged(undefined),
        switchMap(isAuth => {
          if(!isAuth){
            this.traceService.add('Событие отсутствия авторизации');
            return this.stopConnection$();
          }

          this.traceService.add('Событие получения авторизации');
          return this.startConnection$();
        }),
        takeUntil(this.streams$.unsubscribe)
      )
      .subscribe();
  }

  /** Создать {@link HubConnectionWithTokenGuid} */
  @traceFunc()
  protected createHubConnection(): HubConnectionWithTokenGuid {
    const hubConnection: HubConnectionWithTokenGuid = new HubConnectionBuilder()
      .withUrl(this.appSettingsService.signalRPath,
        {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          logger: LogLevel.None,
          accessTokenFactory: () => {
            return lastValueFrom(
              this.authService.signalToken$
                .pipe(
                  tap(token => {
                    hubConnection.currentTokenGuid = this.authService.tokenAsObj.g$;
                  })
                )
            )
          }
        })
      .withServerTimeout(this.serverTimeout)
      .withKeepAliveInterval(this.keepAliveInterval)
      .withAutomaticReconnect(new ReconnectRetry())
      .build() as any;

    hubConnection.onreconnecting(() => {
      this.hasConnection = false;
      this.traceService.add('signalr производит переподключение');
    })

    hubConnection.onreconnected(connectionId => {
      this.hasConnection = true;
      this.traceService.add(`signalr удачно переподключилось. Идентификатор подключения: ${connectionId}`);
    })

    hubConnection.on(ServerStoppingMethod, () => {
      this.traceService.add(`Сервер сообщил о своей остановке по методу ${ServerStoppingMethod}`);
      this.stopConnection$()
        .pipe(
          delay(RestartConnectionTimeOutAfterServerStopping),
          tap(() => this.traceService.add(`Установка подключения signalR после остановки сервера`)),
          switchMap(() => this.startConnection$())
        )
        .subscribe();
    })

    return hubConnection;
  }

  /** Подключится по SignalR */
  @traceFunc()
  protected startConnection$(retries: RetryStorage = new RetryStorage(200, 400, 800, { delay: 1000, count: 60  }, {delay: 5000, count: 720})) {
    return defer(() => {
      if (!this.authService.isAuth) { //Если пользователь НЕ авторизован,
        this.traceService.add('Попытка начать подключение signalR подключение пользователем БЕЗ авторизации');
        return EMPTY;
      }

      switch (this._hubConnection.state) {
        case HubConnectionState.Connected:
        case HubConnectionState.Connecting:
        case HubConnectionState.Reconnecting:
          this.traceService.add(`В данный момент signalR подключение уже запущено/запускается/переподключается`);
          return EMPTY;
        case HubConnectionState.Disconnecting:
          this.traceService.add(`В данный момент подключение signalr завершается. Повторю попытку подключения через ${RestartConnectionIfCurrentStateDisconnecting.delay} мс`);
          return throwError(() => new Error());
      }

      return of(undefined);
    })
      .pipe(
        //Повтор если происходит завершение подключения
        retry(new CustomRetryConfig(
          new RetryStorage({
            delay: RestartConnectionIfCurrentStateDisconnecting.delay,
            count: RestartConnectionIfCurrentStateDisconnecting.count
          }),
          undefined,
          (error, retryCount) => {
            this.traceService.add(`Попытка ${retryCount} установить подключение signalR после попытки начать в момент завершения подключения`);
          })),
        switchMap(() => from(this._hubConnection.start())),
        retry(new CustomRetryConfig(retries, undefined, (err, retryCount) => {
          this.traceService.add(`Попытка ${retryCount} начать подключение signalR после неудачной попытки`);
        })),
        tap(() => {
          this.traceService.add('signalR подключение удачно установлено');
          this.hasConnection = true;
        }),
        takeUntil(this.authService.isAuth$.pipe(skipWhile(value => value))), //Пока пользователь авторизован
        takeUntil(this.streams$.unsubscribe)
      );
  }

  /**
   * Остановить подключение.
   * @param retries массив пауз(в миллисекундах) между попытками остановить подключение
   */
  @traceFunc()
  protected stopConnection$(retries: RetryStorage = new RetryStorage(200, 400, 800, {delay: 1000, count: 2})){
    return defer(() => {
      this.hasConnection = false;

      switch (this._hubConnection.state) {
        case HubConnectionState.Disconnecting:
        case HubConnectionState.Disconnected:
          this.traceService.add('Попытка завершить завершающееся или завершенное signalR подключение');
          return EMPTY;
      }

      return from(this._hubConnection.stop());
    })
      .pipe(
        retry(new CustomRetryConfig(retries, undefined, (err, retryCount) => {
          this.traceService.add(`Попытка ${retryCount} остановить signalR подключение после неудачной попытки`);
        })),
        tap(() => this.traceService.add('signalR подключение программно остановлено')),
        takeUntil(this.streams$.unsubscribe),
      );
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.hasConnection$.complete();
    this._hubConnection?.stop().then();
  }
}


/** Класс политики попыток восстановления соединения signalR */
class ReconnectRetry implements IRetryPolicy {
  /** @inheritDoc */
  public nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
    if(retryContext.previousRetryCount < 5) { //Если первый раз
      return 0;
    } if(retryContext.previousRetryCount < 10){
      return 500;
    } if(retryContext.previousRetryCount < 20){
      return 1000;
    } if(retryContext.previousRetryCount < 30){
      return 2000;
    } if(retryContext.elapsedMilliseconds < 60 * 60 * 1000) {
      return 5000;
    }

    return null;
  }
}
