import {ObjectDeepSerializerServiceBase} from "../object-deep-serializer-service-base";
import {TimeOnly} from "../../../classes/Dates/time-onlys/time-only";
import {AppSettingsService} from "../../app-settings.service";
import {Injectable} from "@angular/core";

/**
 * Сервис глубокой сериализации/десериализации {@link TimeOnly} по переданному формату
 */
export class TimeOnlyDeepSerializerService extends ObjectDeepSerializerServiceBase<TimeOnly>{

  /**
   * Конструктор
   * @param parseFormat формат/форматы для парсинга
   * @param serializeFormat формат для вывода в строку
   */
  constructor(public readonly parseFormat: string | string[], public readonly serializeFormat: string) {
    super();
  }

  public isType(obj: any): obj is TimeOnly {
    return TimeOnly.isTimeOnly(obj);
  }
  public typeToString(obj: TimeOnly): string {
    return obj.format(this.serializeFormat);
  }
  public tryParse(str: string): TimeOnly | undefined {
    return TimeOnly.tryParse(str, this.parseFormat, true);
  }
}

/**
 * Сервис глубокой сериализации/десериализации {@link TimeOnly} в формат сервера
 */
@Injectable({
  providedIn: "root"
})
export class TimeOnlySerializerToServerFormatService extends TimeOnlyDeepSerializerService{
  constructor(appSettingsService: AppSettingsService) {
    super(appSettingsService.webApiFormatSettings.timeOnly, appSettingsService.webApiFormatSettings.timeOnly);
  }
}
