import { IEntityVersioning } from "../interfaces/IEntityVersioning";
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface ILimitStaffUnitRate extends IEntityVersioning {
  /** Идентификатор Лимита штатных единиц. FK на таблицу stafflist.LimitStaffUnits */
  limitStaffUnitId: number
  /** Идентификатор Подразделения. FK на таблицу stafflist.Subdivisions */
  financingSourceId: number
  /** Значение ставки */
  value: number
}

@classBackend('LimitStaffUnitRate', 'stafflist')
@className('LimitStaffUnitRate')
/** Модель БД таблицы stafflist.LimitStaffUnitRates (Ставки лимитов штатных единиц ) */
export class LimitStaffUnitRate implements ILimitStaffUnitRate {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public timestamp: [],

              public limitStaffUnitId: number,
              public financingSourceId: number,
              public value: number) { }
}
