import { Component, ContentChild, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { Api1EmployeeControllerService } from '../../services/webApi/webApi1/controllers/api1-employee-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../modules/auth/services/auth.service';
import { DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { take, takeUntil } from 'rxjs/operators';
import { Employee } from '../../classes/domain/POCOs/stafflist/Employee';
import { DrawerItemCustom, WorkspaceServiceBase } from './workspace.service';
import { traceClass } from '../../modules/trace/decorators/class.decorator';
import { TracerServiceBase } from '../../modules/trace/tracers2/trace-services/tracer-base.service';
import { traceFunc } from '../../modules/trace/decorators/func.decorator';
import { TraceParamEnum } from '../../modules/trace/decorators/classes/traceSetting.interface';
import { ReplaySubject } from 'rxjs';
import { AppSettingsService } from '../../services/app-settings.service';
import { WorkspaceHeaderTemplateDirective } from './directives/workspace-header-template.directive';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css'],
})
@traceClass('WorkspaceComponent')
export class WorkspaceComponent implements OnInit, OnDestroy {

  /** Template заголовка компонента */
  @ContentChild(WorkspaceHeaderTemplateDirective)
  public workspaceHeaderTemplateDirective: WorkspaceHeaderTemplateDirective;

  /** Элементы отображающиеся в левом меню */
  public items: Array<DrawerItemCustom> = [];
  /** ФИО пользователя */
  public userFio: string = '';
  private path = 'workspace';
  private basePath: string = `${this.path}/`;
  private streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
  };

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private api1EmployeeControllerService: Api1EmployeeControllerService,
              private alertService: AlertService,
              public workspaceService: WorkspaceServiceBase,
              private readonly appSettingsService: AppSettingsService,
              private readonly tracerService: TracerServiceBase
  ) {
    if (!workspaceService) {
      throw new Error('Не передан сервис для workspace.component');
    }
  }

  @traceFunc()
  ngOnInit() {
    const functions = this.authService.user.Functions;

    this.workspaceService.getItems$(this.basePath, functions, this.appSettingsService.company).pipe(takeUntil(this.streams$.unsubscribe)).subscribe(value => {
      this.items = value;

      if (this.route.snapshot.children.length == 0 && this.items.length > 0) {
        const item = this.items[0];
        this.router.navigate([this.items[0].routPath]);
        item.selected = true;
      } else {
        this.items
          .filter(x => x.routPath) //Отфильтровываем разделители кнопок
          .forEach(x => { //Устанавливаем активную кнопку в зависимости от root
            x.selected = this.router.url.startsWith(`/${x.routPath}`);
          });
      }
    });

    this.api1EmployeeControllerService.currentEmployee_cached$.pipe(takeUntil(this.streams$.unsubscribe), take(1)).subscribe(userEmployee => {
      this.userFio = userEmployee ? Employee.fullName(userEmployee) : 'ФИО НЕИЗВЕСТНО';
    });
  }

  /** Узнать активен ли переданный роут */
  public isActive(url: string): boolean {
    return this.router.url.includes(url);
  }

  /** Выход из прилажения */
  @traceFunc()
  logout() {
    this.alertService.defaultAlertOption.logout(() => {
      this.authService.logOut();
    }).showAlert();
  }

  /** Обработка события выделения в меню */
  @traceFunc({ traceParamType: TraceParamEnum.notTrace })
  public onSelect(ev: DrawerSelectEvent) {
    const item: DrawerItemCustom = ev.item;
    const url = item.routPath;

    if (!item.disableRedirect && url) {
      this.tracerService.add2('Навигация', { obj: url });
      this.router.navigateByUrl(url);
    }
  }

  ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
  }
}
