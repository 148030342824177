import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IWorkModeOptional } from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { CustomFormValidators } from "src/app/validators/custom-form-validators.class";
import { IAddWorkModeFormComponent } from "../i-add-work-mode-form.component";

@Injectable()
@traceClass('AddWorkModeFormComponentService')
export class AddWorkModeFormComponentService extends ComponentServiceBase<IAddWorkModeFormComponent> {

  constructor(private traceService: TracerServiceBase) {
    super();
  }

  /** Инициализация сервиса */
  @traceFunc()
  public init() {
    this.validateComponentInputs();
    this.component.form = this.createForm(this.component.dataItem);
  }

  /** Валидация входящих инпутов компонента */
  public validateComponentInputs() {
    if(!this.component.workModeTypesDropDownItems) {
      throw Error("Свойство [workModeTypesDropDownItems] обязательно для заполнения в компоненте AddWorkModeFormComponent");
    }
  }

  /** Создание формы добавления*/
  @traceFunc()
  public createForm(item: IWorkModeOptional) {
    const form = new FormGroup({
      startDate: new FormControl(item?.startDate, [Validators.required, CustomFormValidators.validateStartDateWithEndDate('endDate')]),
      endDate: new FormControl(item?.endDate, [CustomFormValidators.validateEndDateWithStartDate('startDate')]),
      name: new FormControl(item?.name ?? "", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]),
      comment: new FormControl(item?.comment ?? "", Validators.maxLength(100)),
      arrangedForWeekFlag: new FormControl(item?.arrangedForWeekFlag ?? false),
      workingDays: new FormControl(item?.workingDays, [Validators.required,Validators.min(0), Validators.max(7)]),
      workdayHourDuration: new FormControl(item?.workdayHourDuration,  [Validators.required,Validators.min(0), Validators.max(24)]),
      preHolidayHourDuration: new FormControl(item?.preHolidayHourDuration,  [Validators.required,Validators.min(0), Validators.max(24)]),
      workModeTypeId: new FormControl(item?.workModeTypeId, [Validators.required ]),
    });

    form.markAllAsTouched();
    form.valueChanges.subscribe(value => {
      this.traceService.add2(`Изменена форма добавления режима работы`, {obj: value});
    });
    return form;
  }

  /** Событие происходит при клике на кнопку сохранения */
  @traceFunc()
  public onClickSave() {
    this.component.onSaveEventEmitter.emit(this.component.form.value);
  }

  /** Событие происходит при клике на кнопку отмены */
  @traceFunc()
  public onClickCancel() {
    this.component.onCancelEventEmitter.emit(false);
  }

}
