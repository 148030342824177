import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit} from "@angular/core";
import {ReplaySubject, Subject} from "rxjs";
import {ArrayDataSourceSelection} from "../../classes/array-data-sources/selections/array-data-source-selection";
import {SelectableDirective, TreeListComponent} from "@progress/kendo-angular-treelist";
import {ArrayHelper} from "../../helpers/arrayHelper";
import {delay, takeUntil} from "rxjs/operators";
import {traceClass} from "../../modules/trace/decorators/class.decorator";
import {TracerServiceBase} from "../../modules/trace/tracers2/trace-services/tracer-base.service";
import {traceFunc} from "../../modules/trace/decorators/func.decorator";

/** Тип управляющего выделением */
type SelectionType = ArrayDataSourceSelection<any, any>;

/** Тип элемента массива в событии оригинальной директивы  */
type OriginEventItemType<TId> = {itemKey: TId};

/**
 * Директива управляет выделенными строками в {@link TreeListComponent}.<br>
 * Инициализирует поля стандартной директивы kendo {@link SelectableDirective}, при помощи переданного {@link ArrayDataSourceSelection}.<br>
 * @example
 * <kendo-treelist
 *    kendoTreeListSelectable (!ОБЯЗАТЕЛЬНО ИСПОЛЬЗОВАНИЕ СТАНДАРТНОЙ ДИРЕКТИВЫ!)
 *    [kendoTreelistDataSourceSelection]="selection"
 */
@Directive({
  selector: '[kendoTreelistDataSourceSelection]'
})
@traceClass('KendoTreelistDataSourceSelectionDirective')
export class KendoTreelistDataSourceSelectionDirective implements OnInit, OnDestroy {
  private readonly streams$ = {
    unsubscribe: new ReplaySubject<any>(1),
    selectionChange: new Subject<SelectionType>(),
  };

  /** Текущее состояния выделенных идентификаторов */
  private _currentIds: any[];

  private _selection: SelectionType;
  /** Управляющий выделением {@link ArrayDataSourceSelection} */
  @Input('kendoTreelistDataSourceSelection') set selection(value: SelectionType) {
    this._selection = value;
    this.streams$.selectionChange.next(value);
    this._currentIds = [];
    this.init();
  }

  constructor(private readonly selectableDirective: SelectableDirective,
              private readonly cdr: ChangeDetectorRef,
              private readonly traceService: TracerServiceBase) {
  }

  /** @incheridDoc */
  @traceFunc()
  public ngOnInit(): void {

  }

  /** Инициализировать */
  @traceFunc()
  private init(){
    //Устанавливаем функцию получения ключа строки в директиву
    this.selectableDirective.itemKey = this._selection
      ? dataItem => {
        return this._selection.dataSource.idGetter(dataItem);
      }
      : undefined;

    //Подпись на изменения в селекторе
    if (this._selection) {
      this._selection.selectedIds.data$
        .pipe(
          takeUntil(this.streams$.selectionChange),
          takeUntil(this.streams$.unsubscribe),
        )
        .subscribe(ids => {
          if (!this.isArraysHasDifference(ids, this._currentIds)) {
            return;
          }

          this._currentIds = ids;
          this.setSelectedKeysToDirective(ids, false);
          this.cdr.markForCheck();
        });
    }

    //Подпись на изменения выделенными строками пользователем
    this.selectableDirective.selectedItemsChange
      .pipe(
        delay(1),
        takeUntil(this.streams$.selectionChange),
        takeUntil(this.streams$.unsubscribe),
      ).subscribe((items: OriginEventItemType<any>[]) => {
        if (!this._selection) {
          return;
        }
        const ids = items.map(x => x.itemKey);

        if (this.isArraysHasDifference(ids, this._currentIds)) {
          this._currentIds = ids;
          this._selection.setIds(ids, false, 'user');
          this.cdr.markForCheck();
        }
    });
  }

  /** Метод устанавливает значение в директиву {@link SelectableDirective} */
  private setSelectedKeysToDirective(ids: any[], isFirstChange: boolean) {
    this.selectableDirective.selectedItems = ids
      .map(id => {
        const item: OriginEventItemType<any> = {
          itemKey: id,
        };

        return item;
      });
  }

  /** Метод возвращает true если набор в массивах разный */
  private isArraysHasDifference(arr1: any[], arr2: any[]) {
    return !ArrayHelper.equals2(arr1 ?? [], arr2 ?? [], (x1, x2) => x1 === x2);
  }

  /** @incheridDoc */
  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribe.next(null);
    this.streams$.unsubscribe.complete();
    this.streams$.selectionChange.complete();
  }
}
