import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { EmployeeNamesDeclension } from '../../../../../../../../src/app/classes/domain/POCOs/stafflist/EmployeeNamesDeclension';
import { LoadingIndicatorService } from '../../../../../../../../src/app/services/loading-indicator.service';
import { Api1EmployeeNamesDeclensionControllerService, SaveEmployeeDeclensionDTO } from '../../../../../../../../src/app/services/webApi/webApi1/controllers/api1-employee-names-declension-controller.service';
import { DataSourceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { DataSource } from '../../../../../../../../src/app/classes/array-data-sources/data-source';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';

@Injectable()
export abstract class EditEmployeeNamesDeclensionsComponentDataSourceServiceBase extends DataSourceWithParamsBase<any, EmployeeNamesDeclension> {
  abstract save$(entity: SaveEmployeeDeclensionDTO): Observable<[]>;
}

@Injectable()
export class EditEmployeeNamesDeclensionsComponentDataSourceService extends DataSourceWithParamsBase<EditEmployeeNamesDeclensionsComponentDataSourceService_Params, EmployeeNamesDeclension> implements EditEmployeeNamesDeclensionsComponentDataSourceServiceBase {

  public readonly dataSource = new DataSource<EmployeeNamesDeclension>();
  public readonly paramsDataSource = new DataSource<EditEmployeeNamesDeclensionsComponentDataSourceService_Params>();

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1EmployeeNamesDeclensionControllerService: Api1EmployeeNamesDeclensionControllerService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  public save$(entity: SaveEmployeeDeclensionDTO): Observable<[]> {
    return this.loadingIndicatorService.addToObservable('Сохранение склонений по падежам', this.api1EmployeeNamesDeclensionControllerService.save$(entity));
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.on(EmployeeNamesDeclension)
      .pipe(
        map(value => value.data),
        map(value => value.filter(v => this.paramsDataSource.data.employeeId === v.currentOrOrigin.employeeId && this.paramsDataSource.data.employeeFullName === v.currentOrOrigin.nominative)),
        map(value => ([!!value.length])),
        filter(value => value.some(v => v)),

        // TODO: Разобраться, почему DataSourceWithParamsBase сам не устанавливает данные
        map(() => this.updateData$()),
      );
  }

  protected _reloadData$(params: EditEmployeeNamesDeclensionsComponentDataSourceService_Params): Observable<EmployeeNamesDeclension> {
    return this.loadingIndicatorService.addToObservable(
      'Загрузка склонений по падежам',
      this.api1EmployeeNamesDeclensionControllerService.getOrCreateEmployeeNamesDeclensionsForEmployee$(params.employeeId, params.employeeFullName),
    );
  }

}

export interface EditEmployeeNamesDeclensionsComponentDataSourceService_Params {
  employeeId: number;
  employeeFullName: string;
}