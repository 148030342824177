import { map, Observable } from "rxjs";
import { ArrayDataSourceIEntityId, DataSource } from "src/app/classes/array-data-sources/data-source";
import { DataHasOwnerStateBuilder } from "src/app/classes/data-state-builders/data-has-owner-state-builder";
import { Occupation } from "src/app/classes/domain/POCOs/stafflist/Occupation";
import { Position } from "src/app/classes/domain/POCOs/stafflist/Position";
import { WorkMode } from "src/app/classes/domain/POCOs/stafflist/WorkMode";
import { IForDate } from "src/app/classes/for-date";
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from "../data-source-services/data-source.service";
import { DbChangedListener } from "../signal-r/listeners/db-changed-listener";
import { PositionWithExtendedData } from "../webApi/webApi1/controllers/api1-staff-units-control.service";

export class PositionWithExtendedDataDataSourceService
extends ArrayDataSourceIEntityIdServiceWithParamsBase<IPositionWithExtendedDataDataSourceService_Params, PositionWithExtendedData>{

  readonly paramsDataSource = new DataSource<IPositionWithExtendedDataDataSourceService_Params>();
  readonly dataSource = new ArrayDataSourceIEntityId<PositionWithExtendedData>();

  constructor(private readonly parentDataSourceService: IPositionWithExtendedDataDataSourceService_ParentDataSourceService,
              private readonly dbChangedListener: DbChangedListener) {
    super();
  }

  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({positions: Position, occupations: Occupation, workModes: WorkMode})
    .pipe(
      map(value => value.data),
      map(value => {
        let retval =  {
          positions: new DataHasOwnerStateBuilder(value.positions, this.dataSource.data2, x => x.id).build_()
          .source.filter(x=>
            (x.state === 'added' && (this.paramsDataSource.data.subdivisionIds.length === 0 || this.paramsDataSource.data.subdivisionIds.some(s=> s === x.signalR.currentOrOrigin.subdivisionId)))
            || x.state === 'modified' || x.state === "deleted"
            )
          .filter(x=> x.dataItem) //Не интересуют позиции, котрые не относятся к нашим данным
          .map(x => x.dataItem?.id ?? x.signalR.currentOrOrigin.ownerId),

          occupations: new DataHasOwnerStateBuilder(value.occupations, this.dataSource.data2, x => x.occupationId).build_()
          .source
          .filter(x => x.state !== 'added') //добавленные должности не интересуют
          .filter(x=> x.dataItem) //Не интересуют должности, котрые не относятся к нашим данным
          .map(x => x.dataItem.id),

          workModes: new DataHasOwnerStateBuilder(value.workModes, this.dataSource.data2, x => x.workModeId).build_()
          .source
          .filter(x => x.state !== 'added') //добавленные режимы работы не интересуют
          .filter(x=> x.dataItem) //Не интересуют режимы работы, котрые не относятся к нашим данным
          .map(x => x.dataItem.id),
        }
        return retval;
      }),
      map(value => ([
        ...value.positions,
        ...value.workModes,
        ...value.occupations
      ])),
      map(value => this.reloadFromSignalR$(value))
    )
  }

  protected _reloadData$(params: IPositionWithExtendedDataDataSourceService_Params): Observable<PositionWithExtendedData[]> {
    return this._reloadFromRemoteByIds$(params, params.positionIds);
  }

  protected _reloadFromRemoteByIds$(params: IPositionWithExtendedDataDataSourceService_Params, targets: PositionWithExtendedData["id"][]): Observable<PositionWithExtendedData[]> {
    return this.parentDataSourceService.getPositionsWithExtendedDataForDate$({...params, positionIds: targets });
  }
}

export interface IPositionWithExtendedDataDataSourceService_Params extends IForDate{
  positionIds: number[];
  subdivisionIds: number[];
}

export interface IPositionWithExtendedDataDataSourceService_ParentDataSourceService {
  getPositionsWithExtendedDataForDate$(params: IPositionWithExtendedDataDataSourceService_Params): Observable<PositionWithExtendedData[]>;
}
