import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { traceClass } from 'src/app/modules/trace/decorators/class.decorator';
import { TracerServiceBase } from 'src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { AddEmployeeFormComponentDataService } from './add-employee-form-component-data.service';

@Injectable()
@traceClass('AddEmployeeFormComponentService')
export class AddEmployeeFormComponentService {

  constructor(private readonly traceService: TracerServiceBase,
              private readonly dataService: AddEmployeeFormComponentDataService) {
  }

  /** Валидатор для поля Код */
  public codeValidator(codeControl: AbstractControl): { [s: string]: boolean } | null {
    if (!codeControl.parent) return null;
    if (codeControl.parent.value.isAutogenerateCode !== true) {
      return Validators.required(codeControl);
    }
    return null;
  }

  public generateEmployeeNamesDeclensionsForEmployeeFullName$(fullName: string) {
    return this.dataService.generateEmployeeNamesDeclensionsForEmployeeFullName$(fullName);
  }

}
