import * as moment from "moment";
import {TimeOnlyFormatBuilder} from "./time-only-format-builder";

/** Сообщение ошибки НЕ валидной строки */
const STRING_NOT_VALID_MESSAGE = 'Строка не валидна';
/** Ключ для проверки типа */
const IS_TIMEONLY_KEY = '$#i_t_o#$';
/** Формат по умолчанию для вывода в строку */
const DEFAULT_TO_STRING_FORMAT = 'HH:mm:ss.SSS';
/**
 * Информация разработчикам о поле {@link CustomParsType.strict}.
 * Строгий синтаксический анализ требует, чтобы формат и вводимые данные точно совпадали, включая разделители. Строгий синтаксический анализ часто является лучшим вариантом синтаксического анализа.
 */
const STRICT_INFO = '';

/** Тип значения */
type ValueType = {
  /** Часы */
  hours: number,
  /** Минуты */
  minutes: number,
  /** Секунды */
  seconds: number,
  /** Миллисекунды */
  milliseconds: number
}

/** Тип для параметра конструктора */
type ConstructorParameterType = Partial<ValueType> & {
  /**
   * Поведение если значение поля {@link ValueType} отсутствует.
   * current - возьмет значение из текущего времени.
   * default - установит по умолчанию.
   * По умолчанию значение 'current'
   */
  behavior?: 'current' | 'default'
};

/**
 * Класс для работы со временем.
 * Под капотом работает с moment.
 */
export class TimeOnly implements Pick<Date, 'getHours' | 'getMinutes' | 'getSeconds' | 'getMilliseconds'>{
  /** Паттерна по умолчанию. {@link TIMEONLY_DEFAULT_SETTINGS} */
  public static readonly defaultFormats = new TimeOnlyFormatBuilder().build();

  /** Значение по умолчанию для года */
  public static readonly defaultYear = 0;
  /** Значение по умолчанию для месяца */
  public static readonly defaultMonth = 0;
  /** Значение по умолчанию для дня */
  public static readonly defaultDay = 1;
  /** Значение по умолчанию для часов */
  public static readonly defaultHours = 0;
  /** Значение по умолчанию для минут */
  public static readonly defaultMinutes = 0;
  /** Значение по умолчанию для секунд */
  public static readonly defaultSeconds = 0;
  /** Значение по умолчанию для миллисекунд */
  public static readonly defaultMilliseconds = 0;

  /** Ключ для проверки типа */
  private get isTimeOnly(){
    return IS_TIMEONLY_KEY;
  }

  private _moment: moment.Moment;

  private _date: Date;
  /**
   * Получить как {@link Date}.
   * Год - {@link TimeOnly.defaultYear}.
   * Месяц - {@link TimeOnly.defaultMonth}.
   * День - {@link TimeOnly.defaultDay}.
   */
  public get date(){
    return this._date;
  }

  /**
   * Конструктор
   * @param value значение для инициализации. Если !value, текущее время.
   */
  constructor(value: ConstructorParameterType = undefined) {
    value = !!value ? value : {};

    if(value.behavior === 'default'){
      value.hours = value.hours === undefined ? TimeOnly.defaultHours : value.hours;
      value.minutes = value.minutes === undefined ? TimeOnly.defaultMinutes : value.minutes;
      value.seconds = value.seconds === undefined ? TimeOnly.defaultSeconds : value.seconds;
      value.milliseconds = value.milliseconds === undefined ? TimeOnly.defaultMilliseconds : value.milliseconds;
    } else {
      const currentM = moment();

      value.hours = value.hours === undefined ? currentM.hour() : value.hours;
      value.minutes = value.minutes === undefined ? currentM.minutes() : value.minutes;
      value.seconds = value.seconds === undefined ? currentM.seconds() : value.seconds;
      value.milliseconds = value.milliseconds === undefined ? currentM.millisecond() : value.milliseconds;
    }

    this.init(moment({
      year: TimeOnly.defaultYear,
      month: TimeOnly.defaultMonth,
      day: TimeOnly.defaultDay,
      hour: value.hours,
      minutes: value.minutes,
      seconds: value.seconds,
      milliseconds: value.milliseconds
    }));
  }

  public getHours(): number {
    return this._moment.hour();
  }
  public getMinutes(): number {
    return this._moment.minute();
  }
  public getSeconds(): number {
    return this._moment.seconds();
  }
  public getMilliseconds(): number {
    return this._moment.milliseconds();
  }

  /** Копировать */
  public copy(){
    return TimeOnly.createFromMomentInternal(this._moment);
  }

  /**
   * Сформировать строку.
   * @param format формат поддерживающий в moment
   */
  public format(format: string = DEFAULT_TO_STRING_FORMAT): string{
    return this._moment.format(format)
  }

  /** Переопределение. Вывод в формате {@link DEFAULT_TO_STRING_FORMAT} */
  public toString(){
    return this.format();
  }

  /** Переопределение. Вывод в формате {@link DEFAULT_TO_STRING_FORMAT} */
  public toJSON(){
    return this.format();
  }

  /** Сравнить */
  public compare(timeOnly: TimeOnly){
    return TimeOnly.compare(this, timeOnly);
  }

  /** Сравнить со временем {@link Date}  */
  public compareDate(date: Date){
    return TimeOnly.compareDate(this, date);
  }

  /** Инициализация */
  private init(moment: moment.Moment){
    if(!moment.isValid()){
      throw new Error('данные времени не валидны');
    }

    this._moment = moment;
    this._date = moment.toDate();
  }

  /** Внутренний метод создания экземпляра из moment. */
  private static createFromMomentInternal(m: moment.Moment){
    return new TimeOnly({
      hours: m.hours(),
      minutes: m.minutes(),
      seconds: m.seconds(),
      milliseconds: m.millisecond(),
      behavior: 'default'
    });
  }

  /**
   * Попробовать получить {@link TimeOnly} из строки.
   * Если строка не валидна вернет undefined
   * @param value строка содержащая время
   * @param format формат или возможные форматы
   * @param strict строго. см.{@link STRICT_INFO}
   */
  public static tryParse(value: string, format: string | string[] = this.defaultFormats, strict: boolean = true): TimeOnly | undefined{
    const instance = moment(value, format, strict);

    if(!instance.isValid()){
      return undefined;
    }

    return this.createFromMomentInternal(instance);
  }

  /**
   * Получить {@link TimeOnly} из строки.
   * Если строка не валидна бросит ошибку содержащую сообщение {@link STRING_NOT_VALID_MESSAGE}
   * @param value строка содержащая время
   * @param format формат или возможные форматы
   * @param strict строго. см.{@link STRICT_INFO}
   */
  public static parse(value: string, format: string | string[] = this.defaultFormats, strict: boolean = true): TimeOnly{
    const instance = this.tryParse(value, format, strict);
    if(!instance){
      throw new Error(STRING_NOT_VALID_MESSAGE)
    }

    return instance;
  }

  /** Создать экземпляр из moment */
  public static createFromMoment(m: moment.Moment){
    if(!m){
      return undefined;
    }

    if(!m.isValid()){
      throw new Error('переданный момент НЕ валиден')
    }

    return this.createFromMomentInternal(m);
  }

  /** Создать экземпляр из {@link Date} */
  public static createFromDate(date: Date){
    if(!date){
      return undefined;
    }

    return this.createFromMomentInternal(moment(date));
  }

  /** Создать экземпляр из числа {@link Date} */
  public static createFromDateNumber(value: number){
    if(!value){
      return undefined;
    }

    return this.createFromMomentInternal(moment(value));
  }

  /** Вернет, является ли переданный объект экземпляром {@link TimeOnly} */
  public static isTimeOnly(obj: any): obj is TimeOnly{
    return (obj as TimeOnly)?.isTimeOnly === IS_TIMEONLY_KEY;
  }

  /** Сравнить два объекта {@link TimeOnly} */
  public static compare(first: TimeOnly, second: TimeOnly){
    if(!first || !second){
      return first === second;
    }

    return first.getHours() === second.getHours() &&
           first.getMinutes() === second.getMinutes() &&
           first.getSeconds() === second.getSeconds() &&
           first.getMilliseconds() === second.getMilliseconds();
  }

  /** Сравнить время со временем {@link Date} */
  public static compareDate(timeOnly: TimeOnly, date: Date) {
    return timeOnly?.getHours() === date?.getHours() &&
           timeOnly?.getMinutes() === date?.getMinutes() &&
           timeOnly?.getSeconds() === date?.getSeconds() &&
           timeOnly?.getMilliseconds() === date?.getMilliseconds();
  }
}
