import { IEntityDeletedFlag } from '../interfaces/IEntityDeletedFlag';
import { IEntityId } from '../interfaces/IEntityId';
import { IEntityRowVersion } from '../interfaces/IEntityRowVersion';
import { IEntityStartEndDate } from '../interfaces/IEntityStartEndDate';
import { IEntityComment } from '../interfaces/IEntityComment';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface INomenclatureType extends IEntityId, IEntityDeletedFlag, IEntityStartEndDate, IEntityComment, IEntityRowVersion {
  name: string;
  parentId: number;
  categoryId: number;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type INomenclatureTypeOptional = Partial<INomenclatureType>;

@classBackend('NomenclatureType', 'pfhd_directories')
@className('NomenclatureType')
/** Модель БД таблицы pfhd.NomenclatureTypes (Типы бюджета) */
export class NomenclatureType implements INomenclatureType {
  constructor(public id: number,
              public deletedFlag: boolean,
              public startDate: Date,
              public endDate: Date,
              public comment: string,
              public name: string,
              public parentId: number,
              public timestamp: [],
              public categoryId: number,
  ) {
  }
}
