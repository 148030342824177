import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { VersioningItem } from 'src/app/classes/directories/edit/versioningItem';
import { SupportedActionEnum } from 'src/app/classes/domain/enums/supported-action-enum';
import { IOccupationOptional, Occupation } from 'src/app/classes/domain/POCOs/stafflist/Occupation';
import { IOccupationType, OccupationType } from 'src/app/classes/domain/POCOs/stafflist/OccupationType';
import { ICode } from 'src/app/classes/domain/POCOs/timesheet_table/Code';
import { exLoadingMessage } from 'src/app/operators/ex-loading-message.operator';
import { LoadingIndicatorService } from 'src/app/services/loading-indicator.service';
import { Api1CodeControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-code-controller.service';
import { Api1OccupationTypesControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-occupation-types-controller.service';
import { Api1OccupationsControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-occupations-controller.service';
import { Api1OccupationsDirectoryGridControlControllerService, OccupationsDirectoryGridItem } from 'src/app/services/webApi/webApi1/controllers/api1-occupations-directory-grid-control-controller.service';
import { Api1StaffListSettingsControllerService } from 'src/app/services/webApi/webApi1/controllers/api1-staff-list-settings-controller.service';
import { ArrayDataSourceIEntityIdServiceWithParamsBase } from '../../../../../../../../src/app/services/data-source-services/data-source.service';
import { IForDate } from '../../../../../../../../src/app/classes/for-date';
import { traceClass } from '../../../../../../../../src/app/modules/trace/decorators/class.decorator';
import { ArrayDataSourceIEntityId, DataSource } from '../../../../../../../../src/app/classes/array-data-sources/data-source';
import { IEntityId } from '../../../../../../../../src/app/classes/domain/POCOs/interfaces/IEntityId';
import { traceFunc } from '../../../../../../../../src/app/modules/trace/decorators/func.decorator';
import { DataHasOwnerStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-has-owner-state-builder';
import { DataStateBuilder } from '../../../../../../../../src/app/classes/data-state-builders/data-state-builder';
import { DbChangedListener } from '../../../../../../../../src/app/services/signal-r/listeners/db-changed-listener';
import { TracerServiceBase } from '../../../../../../../../src/app/modules/trace/tracers2/trace-services/tracer-base.service';
import { OccupationCode } from '../../../../../../../../src/app/classes/domain/POCOs/timesheet_table/OccupationCode';

@Injectable()
export abstract class OccupationsDirectoryGridComponentDataSourceServiceBase extends ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, OccupationsDirectoryGridItem> {
  abstract addOccupationVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>

  abstract deleteOccupationVersion$(versionId: number): Observable<boolean>

  abstract getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]>

  abstract editOccupationVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem>

  abstract save$(entity: Occupation): Observable<[]>

  abstract addOccupation$(entity: IOccupationOptional): Observable<Occupation>

  abstract updateOccupationCodes$(occupationId: number, codesIdsArray: number[]): Observable<boolean>

  abstract getOccupationTypes$(occupationTypeIds: number[]): Observable<IOccupationType[]>;

  abstract getCodes$(codeIds: number[]): Observable<ICode[]>

  abstract getOccupationCodeLength$(): Observable<number>
}

@Injectable()
@traceClass('OccupationsDirectoryGridComponentDataSourceService')
export class OccupationsDirectoryGridComponentDataSourceService extends ArrayDataSourceIEntityIdServiceWithParamsBase<IForDate, OccupationsDirectoryGridItem> implements OccupationsDirectoryGridComponentDataSourceServiceBase {

  readonly paramsDataSource = new DataSource<IForDate>;
  readonly dataSource = new ArrayDataSourceIEntityId<OccupationsDirectoryGridItem>();

  constructor(private readonly loadingIndicatorService: LoadingIndicatorService,
              private readonly api1OccupationsDirectoryGridControlControllerService: Api1OccupationsDirectoryGridControlControllerService,
              private readonly api1OccupationsControllerService: Api1OccupationsControllerService,
              private readonly api1OccupationTypesControllerService: Api1OccupationTypesControllerService,
              private readonly api1CodeControllerService: Api1CodeControllerService,
              private readonly dbChangedListener: DbChangedListener,
              private readonly api1StaffListSettingsControllerService: Api1StaffListSettingsControllerService,
              private readonly tracerService: TracerServiceBase) {
    super();
  }

  public addOccupationVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.api1OccupationsControllerService.addOccupationVersion$(actionId, versioningEntity);
  }

  public deleteOccupationVersion$(versionId: number): Observable<boolean> {
    return this.api1OccupationsControllerService.deleteOccupationVersion$(versionId);
  }

  public getVersionsByOwnerIdAndActionId$(ownerId: number, actionId: SupportedActionEnum): Observable<VersioningItem[]> {
    return this.api1OccupationsControllerService.getVersionsByOwnerIdAndActionId$(ownerId, actionId);
  }

  public editOccupationVersion$(actionId: SupportedActionEnum, versioningEntity: VersioningItem): Observable<VersioningItem> {
    return this.api1OccupationsControllerService.editOccupationVersion$(actionId, versioningEntity);
  }

  public save$(entity: Occupation): Observable<[]> {
    return this.api1OccupationsControllerService.save$(entity).pipe(exLoadingMessage(this.loadingIndicatorService, 'Сохранение данных'));
  }

  public addOccupation$(entity: IOccupationOptional): Observable<Occupation> {
    return this.loadingIndicatorService.addToObservable(
      'Добавление должности',
      this.api1OccupationsControllerService.addOccupation$(entity),
    );
  }

  public updateOccupationCodes$(occupationId: number, codesIdsArray: number[]): Observable<boolean> {
    return this.loadingIndicatorService.addToObservable(
      'Сохранение кодов табельного учёта',
      this.api1OccupationsControllerService.updateOccupationCodes$(occupationId, codesIdsArray),
    );
  }

  public getOccupationTypes$(occupationTypeIds: number[]): Observable<IOccupationType[]> {
    return this.api1OccupationTypesControllerService.get$();
  }

  public getCodes$(codeIds: number[]): Observable<ICode[]> {
    return this.loadingIndicatorService.addToObservable(
      'Загрузка списка категорий должностей',
      this.api1CodeControllerService.getAll$(),
    );
  }

  public getOccupationCodeLength$(): Observable<number> {
    return this.loadingIndicatorService.addToObservable(
      'Загрузка длины кода подразделения',
      this.api1StaffListSettingsControllerService.getOccupationCodeLength$(),
    );
  }

  @traceFunc()
  protected useSignalR$(): Observable<Observable<any>> | null {
    return this.dbChangedListener.onMulti({
        occupations: Occupation,
        occupationTypes: OccupationType,
        occupationCodes: OccupationCode,
      })
      .pipe(
        map(value => value.data),
        map(value => ([
          ...new DataHasOwnerStateBuilder(value.occupations, this.dataSource.data2, x => x.id).build_()
            .source.map(x => x.signalR.currentOrOrigin.ownerId),
          ...new DataStateBuilder(value.occupationTypes, this.dataSource.data2, (x, y) => x.id === y.occupationTypeId).build_()
            .source.filter(x => x.state !== 'added').filter(x => x.dataItem).map(x => x.dataItem.id),
          ...new DataStateBuilder(value.occupationCodes, this.dataSource.data2, (x, y) => x.occupationId === y.id).build_()
            .source.filter(x => x.dataItem).map(x => x.dataItem.id),
        ])),
        map(value => this.reloadFromSignalR$(value)),
      );
  }

  protected _reloadData$(params: IForDate): Observable<OccupationsDirectoryGridItem[]> {
    return this.loadingIndicatorService.addToObservable(
      'Загрузка списка должностей',
      this.api1OccupationsDirectoryGridControlControllerService.getForDate$(params.forDate, params.startDate, params.endDate),
    );
  }

  protected _reloadFromRemoteByIds$(params: IForDate, targets: IEntityId['id'][]): Observable<OccupationsDirectoryGridItem[]> {
    return this.api1OccupationsDirectoryGridControlControllerService.getForDate$(params.forDate, params.startDate, params.endDate, targets);
  }
}
