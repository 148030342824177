<form class="k-form" [formGroup]="form" *ngIf="form">
  <ng-container *ngIf="this.currentPosition$ | async as position">
    <div class="form-inline-div">
      <kendo-formfield>
        <kendo-label [for]="startDate" text="Дата начала"></kendo-label>
        <kendo-datepicker #startDate [formControlName]="'startDate'" [min]="position.startDate" [max]="form.controls.endDate.value ? form.controls.endDate.value : position.endDate"></kendo-datepicker>
        <kendo-formerror *ngIf="form.controls.startDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateGreaterThanEndDate">Дата начала действия не может быть позже чем дата окончания действия</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.startDate.errors?.startDateLowerThanParent">Дата начала действия не может быть раньше чем дата начала действия родителя. Значение родителя: {{form.controls.startDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label [for]="endDate" text="Дата окончания"></kendo-label>
        <kendo-datepicker #endDate [formControlName]="'endDate'" [min]="form.controls.startDate.value ? form.controls.startDate.value : position.startDate" [max]="position.endDate"></kendo-datepicker>
        <kendo-formerror *ngIf="form.controls.endDate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateLowerThanStartDate">Дата окончания действия не может быть раньше чем дата начала действия</kendo-formerror>
        <kendo-formerror *ngIf="form.controls.endDate.errors?.endDateGreaterThanParent">Дата окончания действия не может быть позже чем дата окончания действия родителя. Значение родителя: {{form.controls.endDate.errors?.parentValue | kendoDate: 'dd.MM.yyyy'}}</kendo-formerror>
      </kendo-formfield>
    </div>
  </ng-container>

  <kendo-formfield>
    <kendo-label [for]="employeeId" text="Сотрудник"></kendo-label>
      <kendo-dropdownlist
        #employeeId
        [data]="employeesDropDown$ | async"
        [textField]="'text'"
        [valueField]="'id'"
        [valuePrimitive]="true"
        [virtual]="{
          itemHeight: 28
        }"
        [kendoDropDownFilter]="{
          caseSensitive: false,
          operator: 'contains'
        }"
        [formControlName]="'employeeId'"
      >
      <ng-template kendoDropDownListItemTemplate  let-dataItem>
        {{ dataItem?.text }} <span *ngIf="dataItem?.startDate">с: {{ dataItem?.startDate  | kendoDate : 'dd.MM.yyyy'}}</span> <span *ngIf="dataItem?.endDate">по: {{ dataItem?.endDate  | kendoDate : 'dd.MM.yyyy'}}</span>
      </ng-template>
      </kendo-dropdownlist>
    <kendo-formerror *ngIf="form.controls.employeeId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>

  <div class="form-inline-div">
    <kendo-formfield>
      <kendo-label [for]="financingSourceId" text="Источник финансирования"></kendo-label>
      <kendo-dropdownlist
        #financingSourceId
        [data]="financingSources$ | async"
        textField="shortName"
        valueField="id"
        [valuePrimitive]="true"
        [formControlName]="'financingSourceId'"
      >
      <ng-template kendoDropDownListNoDataTemplate>
          Нет вакантных ставок
      </ng-template>
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="form.controls.financingSourceId?.errors?.invalidData">Неправильное значение</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.financingSourceId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [for]="rate" text="Количество ставок"></kendo-label>
      <kendo-numerictextbox #rate KendoNumericExpanded [formControlName]="'rate'" [min]="0" [max]="maxRate$ | async"  [format]="'n2'" [decimals]="2" [step]="(dataSource.data$ | async).rateStep" [autoCorrect]="true"></kendo-numerictextbox>

      <kendo-formerror *ngIf="form.controls.rate.errors?.notEqual">Ставка не должна равняться {{form.controls.rate.errors.notEqual.notEqual}}.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.rate.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.rate.errors?.maxError">Количество вакантных ставок равно {{form.controls.rate.errors.maxError.maxValue | kendoNumber:{minimumFractionDigits:0, maximumFractionDigits:2} }}</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.rate.errors?.max">Количество вакантных ставок равно {{form.controls.rate.errors.max.max | kendoNumber:{minimumFractionDigits:0, maximumFractionDigits:2} }}</kendo-formerror>
    </kendo-formfield>
  </div>

  <div class="form-inline-div" style="flex-wrap: nowrap;">
    <kendo-formfield>
      <kendo-label [for]="typeId" text="Вид занятости"></kendo-label>
      <kendo-dropdownlist
        #typeId
        [data]="staffUnitTypes$ | async"
        textField="description"
        valueField="id"
        [valuePrimitive]="true"
        [formControlName]="'typeId'"
        [style.min-width.px]="400"
        (valueChange)="changeStaffUnitType($event)"
      >
      </kendo-dropdownlist>
      <kendo-formerror *ngIf="form.controls.typeId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield *ngIf="staffUnitWithPercent">
      <kendo-label [for]="percent" text="% выплаты"></kendo-label>
      <kendo-numerictextbox #percent [style.width.px]="150" KendoNumericExpanded [formControlName]="'percent'" [min]="0" [max]="100"  [format]="'#'" [decimals]="0" [step]="1" [autoCorrect]="true"></kendo-numerictextbox>

      <kendo-formerror *ngIf="form.controls.rate.errors?.maxError">% выплаты не должен превышать {{form.controls.percent.errors.maxError.maxValue | kendoNumber:"#" }}</kendo-formerror>
    </kendo-formfield>
  </div>

  <div class="form-inline-div">
    <kendo-formfield class="horizontal-form-field">
      <kendo-label [for]="executionDutiesFlag" text="Исполнение обязанностей:"></kendo-label>
      <input type="checkbox" kendoCheckBox #executionDutiesFlag [formControlName]="'executionDutiesFlag'"/>
    </kendo-formfield>

    <kendo-formfield class="horizontal-form-field">
      <kendo-label [for]="milk" text="Участвует в выплате компенсации за молоко"></kendo-label>
      <input type="checkbox" kendoCheckBox #milk [formControlName]="'milk'"/>
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment [formControlName]="'comment'" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

