import { IEntityDeletedFlag } from "../../interfaces/IEntityDeletedFlag";
import { IEntityId } from "../../interfaces/IEntityId";
import { IEntityModify } from "../../interfaces/IEntityModify";
import { IEntityRowVersion } from "../../interfaces/IEntityRowVersion";
import { classBackend } from '../../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../../decorators/className/className.decorator';

export interface ICfoSummaryValuesPresetValue extends IEntityId, IEntityModify, IEntityDeletedFlag, IEntityRowVersion {
  presetId: number,
  financingSourceId: number | null,
  medicalCareTypeId: number | null,
  costTypeId: number | null,
  value: number
}

@classBackend('CfoSummaryValuesPresetValue', 'pfhd_cfo_request')
@className('CfoSummaryValuesPresetValue')
/** Модель БД таблицы pfhd_cfo_request.CfoSummaryValuesPresetValues (Значения пресетов значений свода цфо) */
export class CfoSummaryValuesPresetValue implements ICfoSummaryValuesPresetValue {
  constructor(
    public id: number,
    public modifiedDate: Date,
    public modifiedUserId: number | null,
    public deletedFlag: boolean,
    public presetId: number,
    public financingSourceId: number | null,
    public medicalCareTypeId: number | null,
    public costTypeId: number | null,
    public value: number,
    public timestamp: []
  ) { }
}
