import {Injectable} from "@angular/core";
import {AuthService} from "../services/auth.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";

/** Доступ только авторизованным пользователям */
@Injectable({
  providedIn: "root"
})
export class AuthGuard {
  constructor(private authService: AuthService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuth$;
  }
}
