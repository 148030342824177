import { IEntityVersioning } from '../interfaces/IEntityVersioning';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';


export interface IKvr extends IEntityVersioning {
  /** Идентификатор вышестоящего подразделения */
  parentId: number | null;
  /** Наименование КВР */
  name: string;
  /** Код подразделения */
  code: string;
  /** Описание */
  description: string;
}

/** @deprecated ИСПОЛЬЗУЙ Pick<класс> */
export type IKvrOptional = Partial<IKvr>;

@classBackend('Kvr', 'pfhd_directories')
@className('Kvr')
/** Модель БД таблицы pfhd_directories.Kvrs (КВР) */
export class Kvr implements IKvr {
  constructor(public id: number,
              public startDate: Date | null,
              public endDate: Date | null,
              public modifiedUserId: number | null,
              public modifiedDate: Date,
              public comment: string,
              public deletedFlag: boolean,
              public ownerId: number | null,
              public actionId: number,
              public orderId: number | null,
              public parentId: number | null,
              public name: string,
              public code: string,
              public description: string,
              public timestamp: [],
  ) {
  }
}
