import { EmployeesExtendedDataTypesEnum } from '../../enums/employees-extended-data-types-enum';
import { IEntityExtendedData } from '../interfaces/IEntityExtendedData';
import { classBackend } from '../../../../decorators/classBackend/classBackend.decorator';
import { className } from '../../../../decorators/className/className.decorator';

export interface IEmployeeExtended extends IEntityExtendedData<EmployeesExtendedDataTypesEnum> {
  /** Идентификатор сотрудника */
  employeeId: number;
}

@classBackend('EmployeeExtended', 'stafflist')
@className('EmployeeExtended')
export class EmployeeExtended implements IEmployeeExtended {
  constructor(public id: number,
              public modifiedDate: Date,
              public modifiedUserId: number | null,
              public deletedFlag: boolean,
              public timestamp: [],
              public dataType: EmployeesExtendedDataTypesEnum,
              public dataJsonString: string,
              public employeeId: number) {
  }
}
