import {AppSettingsService} from "../app-settings.service";
import {CryptService} from "../cryptServices/crypt.service";
import {CustomStorageService, StorageLocationEnum, StorageOptions} from "./custom-storage.service";
import * as moment from "moment";

/** Реальный сервис хранения данных в session/local storage */
export class CustomStorageRealService extends CustomStorageService{
  private readonly crypt: Crypt;
  /** Строитель путей для хранилища */
  private readonly pathBuilder: CustomStorageService_PathBuilder;

  /** Конструктор */
  constructor(private readonly appSettingsService: AppSettingsService,
              cryptService: CryptService) {
    super();
    this.crypt = new Crypt(cryptService);
    this.pathBuilder = new CustomStorageService_PathBuilder(appSettingsService.program);
    this.checkStorageVersion(StorageLocationEnum.LocalStorage);
    this.checkStorageVersion(StorageLocationEnum.SessionStorage);
  }

  public get<T>(key: StorageOptions): T{
    const buildKey = this.pathBuilder.build(key.key);
    const storageValue = StorageValue.CreateFromString(this.getStorage(key.location).getItem(buildKey));
    if(!storageValue){
      return null;
    }

    if(storageValue.isExpired()){
      this.remove(key);
      return null;
    }

    if(storageValue.value === undefined || storageValue.value === null){
      return undefined;
    }

    return storageValue.isConfidential ?
      this.crypt.decryptObj(storageValue.value) :
      JSON.parse(storageValue.value);
  }

  public set<T>(key: StorageOptions, value: T){
    const storageValue = new StorageValue(
      key.liveSeconds,
      key.isConfidentialData,
      key.isSaved,
      key.isConfidentialData ? this.crypt.encryptObj(value) : JSON.stringify(value))

    this.getStorage(key.location).setItem(this.pathBuilder.build(key.key), JSON.stringify(storageValue));
  }

  public remove(key: StorageOptions){
    this.getStorage(key.location).removeItem(this.pathBuilder.build(key.key));
  }

  public clear(andIsSaved: boolean){
    this._clear(this.getStorage(StorageLocationEnum.LocalStorage), andIsSaved);
    this._clear(this.getStorage(StorageLocationEnum.SessionStorage), andIsSaved);
  }

  /** Очищает данные в переданном хранилище */
  private _clear(storage: Storage, andIsSaved: boolean){
    const startWithKey = this.pathBuilder.programKey;
    const keysToRemoving: Array<string> = new Array<string>();

    for(let i = 0; i < storage.length; i++){
      const lSKey = storage.key(i);
      if(!lSKey.startsWith(startWithKey)){
        continue;
      }
      const lSValue = StorageValue.CreateFromString(storage.getItem(lSKey));
      if(!andIsSaved && lSValue.isSaved){
        continue;
      }

      keysToRemoving.push(lSKey);
    }

    keysToRemoving.forEach(x => storage.removeItem(x));
  }

  /** Проверяет версию хранилищ и если она не актуальная очищает */
  protected checkStorageVersion(location: StorageLocationEnum){
    const options = this.storageOptionsVersion(location);
    const versionFromStorage = this.get<number>(options);
    if(versionFromStorage != this.appSettingsService.storageVersion){
      if(!versionFromStorage && location == StorageLocationEnum.SessionStorage){
        this.set(options, this.appSettingsService.storageVersion);
        return;
      }
      this._clear(this.getStorage(location), false);
      this.set(options, this.appSettingsService.storageVersion);
      console.log(`Структура хранилища устарела. Произошла очистка. Хранилище - ${location}`)
    }
  }

  /** Получить хранилище в зависимости от переданного типа */
  protected getStorage(location: StorageLocationEnum): Storage{
    switch (location) {
      case StorageLocationEnum.LocalStorage:
        return localStorage;
      case StorageLocationEnum.SessionStorage:
        return sessionStorage;
      default: throw new Error('OutOfRange')
    }
  }

  /** Ключ для хранения внутренней текущей версии данных хранилища */
  private storageOptionsVersion(location: StorageLocationEnum) {
    return new StorageOptions(
      location,
      "CustomLocalStorage/Version",
      null,
      false,
      true
    )
  }
}

/** Строитель путей в хранилище */
class CustomStorageService_PathBuilder{
  /** Название программы в base64 */
  public readonly programKey: string;

  constructor(programName: string) {
    this.programKey = window.btoa(programName) + '/#*';
  }

  /** Построить путь. За основу возьмет программу и к нему добавит path */
  public build(path: string): string{
    return `${this.programKey}${window.btoa(path)}`
  }
}

/** Для конфедициальных данных */
class Crypt{
  public static readonly jvjvt: string = 'qW91';

  constructor(private readonly cryptService: CryptService) {
  }

  /** Зашифровать строку */
  public encrypt(str: string){
    return this.cryptService.encrypt(str, Crypt.jvjvt);
  }

  /** Зашифровать объект */
  public encryptObj<T>(obj: T){
    return this.cryptService.encryptObj(obj, Crypt.jvjvt);
  }

  /** Расшифровать объект */
  public decryptObj<T>(encryptStr: string) : T{
    return this.cryptService.decryptObj<T>(encryptStr, Crypt.jvjvt);
  }
}

/** Класс хранящийся в storage по ключу */
class StorageValue {
  /** Время установки значения */
  public readonly setDate: number;

  private _expired: number;
  /** Время протухания значения */
  public get expired(){
    return this._expired;
  }

  /**
   * Конструктор
   * @param secondsLive время жизни данных. Если переданно 0, null, undefined то будут безсрочные
   * @param isConfidential Являются ли данные конфедициальными
   * @param isSaved Являются ли данные защищенные от очищения. Если да то если будет очистка storage сервисом то они не будет удалены
   * @param value Значение
   */
  constructor(secondsLive: number,
              public readonly isConfidential: boolean,
              public readonly isSaved: boolean,
              public readonly value: string) {
    this.setDate = +moment().toDate();
    this._expired = !secondsLive ? null : +moment().add(secondsLive, 'seconds').toDate()
  }

  /** Истек ли срок действия */
  public isExpired(){
    return this.expired && +moment().toDate() > this.expired
  }

  /** Установить срок действия */
  private setExpired(value: number) : StorageValue{
    this._expired = value;
    return this;
  }

  /** Создать из строки */
  public static CreateFromString(source: string): StorageValue{
    return this.Copy(JSON.parse(source));
  }

  /** Копировать. Если переданно null вернет null */
  public static Copy(source: StorageValue): StorageValue{
    if(!source){
      return null;
    }

    return new StorageValue(
      null,
      source.isConfidential,
      source.isSaved,
      source.value
    ).setExpired(source.expired);
  }
}
