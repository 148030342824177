import { IFieldControl, IMinMaxValue } from './interfaces/common';

export class DateOnlyFieldControl implements IFieldControl<any> {
  public readonly name = 'DateOnlyFieldControl';
  public getValueFunc = (stringValue: string) => stringValue;

  constructor(public settings?: (() => DateOnlyFieldControl_Settings) | DateOnlyFieldControl_Settings) {
  }
}

export interface DateOnlyFieldControl_Settings {
}
