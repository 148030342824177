import {
  ITraceFuncSettings, TraceParamEnum
} from "./classes/traceSetting.interface";
import "reflect-metadata";
import {_getTraceParamMetadata} from "./param.decorator";
import {TraceFuncMetadata, TraceParamMetadata} from "./classes/metadatas";
import {convertObjToString, getDisabled, getTracerFromObj, limitString} from "./classes/functions";

/** Ключ хранения метаданных */
export const traceFuncMetadataKey = '_traceFunc_'

/** Декоратор трассировки функций */
export function traceFunc(settings?: ITraceFuncSettings) {
  return (target: Object, propertyKey: string | symbol, descriptor: TypedPropertyDescriptor<Function>) => {
    const metadata = new TraceFuncMetadata(propertyKey.toString(), settings);

    //Добавляем метаданные
    let metadataArray: Array<TraceFuncMetadata> = Reflect.getMetadata(traceFuncMetadataKey, target)
    if(!metadataArray){
      metadataArray = [];
      Reflect.defineMetadata(traceFuncMetadataKey, metadataArray, target);
    }
    metadataArray.push(metadata);
    //-----

    const traceParamType = settings?.traceParamType ?? TraceParamEnum.traceByDecoratorsOrTraceAll;

    const originMethod = descriptor.value;
    descriptor.value = function (...args) {
      if(!metadata.traceClassMetadata){
        throw new Error('При декорировании метода необходимо декорировать класс traceClass')
      }

      const tracer = getTracerFromObj(this);
      if(!tracer){
        throw new Error('Трассировщик отсутствует в объекте. Возможно отсутствует декоратор traceClass')
      }

      const prevTracerIsDisabled = tracer.isDisabled;
      if(metadata.isDisable){
        tracer.isDisabled = true;
      }

      const currentFuncParamMetadata = _getTraceParamMetadata(target, propertyKey.toString())
        .filter(x => !x.isDisabled);

      tracer.add(convertToStr(
        metadata.traceClassMetadata?.name ?? 'Неизвестно',
        metadata.settings?.name ?? metadata.name,
        metadata.settings?.description,
        args,
        currentFuncParamMetadata,
        traceParamType,
        tracer.defaultParamTraceMaxLength)
      );

      tracer.tracerLevelManager.add();
      try {
        return originMethod.apply(this, args);
      } catch (e) {
        tracer.tracerLevelManager.add();
        tracer.add(e?.toString() ?? 'Объект ошибки == null');
        tracer.tracerLevelManager.remove();
        throw e;
      } finally {
        tracer.tracerLevelManager.remove();
        tracer.isDisabled = prevTracerIsDisabled;
      }
    }
  }
}

/** Получить метаданные декораторов traceFunc всех функций в объекте */
export function getAllTraceFuncMetadata<T>(object: T): Array<TraceFuncMetadata>{
  return Reflect.getMetadata(traceFuncMetadataKey, object) ?? [];
}

/** Получить метаданные декоратора traceFunc (объект у которого нужно получить, название метода) */
export function getTraceFuncMetadata<T, K extends keyof T = keyof T>(object: T, method: T[K] extends Function ? K : never): TraceFuncMetadata{
  return _getTraceFuncMetadata(object, method.toString());
}

/**
 * Получить метаданные декоратора traceFunc метода по строке названия метода
 * НЕ ИСПОЛЬЗОВАТЬ ПО КОДУ. НЕОБХОДИМО ДЛЯ ВНУТРЕННЕЙ РАБОТЫ
 */
export function _getTraceFuncMetadata<T>(object: T, method: string){
  return getAllTraceFuncMetadata(object).find(x => x.name == method);
}

/** Конвертирует переданные данные в строку */
export function convertToStr(className: string, methodName: string, methodDescription: string, methodArgs: Array<any>, paramMetadata: Array<TraceParamMetadata>, traceParamType: TraceParamEnum, paramTraceMaxLength: number): string{
  return `${className}.${methodName}( ${getParamAsArrayStr(methodArgs, paramMetadata, traceParamType, paramTraceMaxLength).join('  ,  ')} )${!methodDescription ? '' : `    ${methodDescription}`}`
}

/** Получить параметры как строку для вывода */
export function getParamAsArrayStr(args: Array<any>, paramMetadata: Array<TraceParamMetadata>, type: TraceParamEnum, paramTraceMaxLength: number): Array<string>{
  return getParam(args, paramMetadata, type)
    .map(x => paramToStr(x, paramTraceMaxLength))
}

/** Конвертация параметра в строку */
function paramToStr(param: {value: any, metadata: TraceParamMetadata, isTrace: boolean}, paramTraceMaxLength: number): string{
  if(!param.isTrace){
    return 'notTrace';
  }

  const paramAsStr = convertObjToString(param?.value, param?.metadata?.settings?.map);
  let result = `${!!param.metadata?.settings?.name ? param.metadata.settings.name + ': ' : ''}${paramAsStr}`

  const maxLength = param.metadata?.settings?.maxLength ?? paramTraceMaxLength;
  return limitString(result, maxLength);
}

/** Объединить параметры с metadata и определяет нужно ли выводить в трассировку */
function getParam(args: Array<any>, paramMetadata: Array<TraceParamMetadata>, type: TraceParamEnum):Array<{value: any, metadata: TraceParamMetadata, isTrace: boolean}>{
  const result = args.map((value, index) => {
    return { value: value, metadata: paramMetadata.find(p => p.parameterIndex == index) }
  });

  //Добавляем те, которых нет в Args но при этом они помечены аттрибутом
  paramMetadata.filter(x => !result.some(r => r.metadata?.parameterIndex == x.parameterIndex))
    .map(x => {return {value: 'не найден в args', metadata: x}})
    .forEach(x => {
      if(x.metadata.parameterIndex > result.length){
        result.push(x);
        return;
      }
      result.splice(x.metadata.parameterIndex, 0, x);
    });

  const hasSomeDecorator = result.some(x => !!x.metadata && !getDisabled(x.metadata?.settings));
  const hasExcludeDecorator = result.some(x => !getDisabled(x.metadata?.settings) && x.metadata?.settings?.type == 'exclude')

  return result.map(x => {
    return {
      value: x.value,
      metadata: x.metadata,
      isTrace: isTraceParam(x.metadata)
    }
  });

  //Функция определяет нужно ли выводить параметр
  function isTraceParam(metadata: TraceParamMetadata): boolean{
    switch (type){
      case TraceParamEnum.notTrace:
        return false;
      case TraceParamEnum.traceAll:
        return true;
      case TraceParamEnum.traceByDecoratorsOrTraceAll:
      case TraceParamEnum.traceByDecorators:
        if(!hasSomeDecorator && type == TraceParamEnum.traceByDecoratorsOrTraceAll){ return true; }
        if(metadata?.settings?.type == 'exclude') {return false;}

        return hasExcludeDecorator || (!!metadata && !metadata.isDisabled);
      default: throw new Error('OutOfRange');
    }
  }
}
