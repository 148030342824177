import {AfterContentInit, AfterViewInit, Directive, Input, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {NumericTextBoxComponent} from "@progress/kendo-angular-inputs";

/** Директива для kendo-numeric которая преобразует его для работы с количеством ставок */
@Directive({
  selector: '[KendoNumericExpanded]'
})
export class KendoNumericExpandedDirective implements OnInit, OnDestroy, AfterContentInit, AfterViewInit{
  private mutationObserver: MutationObserver = null;

  @Input() readonlyInput: boolean = false;
  /** Нужно ли переносить фокус */
  @Input() initAutoFocus: boolean = false;

  constructor(private numeric: NumericTextBoxComponent,
              private renderer : Renderer2) {

  }

  ngOnInit(): void {

  }

  ngAfterContentInit(): void {
    if(this.readonlyInput){
      this.setReadonlyInput();
    }
  }

  ngAfterViewInit(): void {
    if(this.initAutoFocus){
      this.numeric.focus();
    }
  }

  /** Устанавливает инпут как только для чтения */
  private setReadonlyInput(){
    this.renderer.setAttribute(this.numeric.numericInput.nativeElement, 'disabled', '');

    this.mutationObserver = new MutationObserver(mutations => {
      if(mutations.find(x => x.attributeName == 'disabled') &&
        !this.numeric.numericInput.nativeElement.hasAttribute('disabled')){
        this.renderer.setAttribute(this.numeric.numericInput.nativeElement, 'disabled', '');
      }
    });

    this.mutationObserver.observe(this.numeric.numericInput.nativeElement, {attributes: true})
  }

  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
    this.mutationObserver = null;
  }
}
