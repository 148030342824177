import { IFieldControl, IMinMaxValue } from "./interfaces/common";

export class NumberFieldControl implements IFieldControl<number> {
  public readonly name = 'NumberFieldControl';
  public getValueFunc = (stringValue: string) => !stringValue ? null : Number(stringValue);

  constructor (
    public settings?: (() => NumberFieldControl_Settings) | NumberFieldControl_Settings
  ) {}
}

export interface NumberFieldControl_Settings extends IMinMaxValue<number>  {
  multipleOf?: number;
  format?: string;
  decimals?: number;
  step: number;
}
