import { IForDate } from 'src/app/classes/for-date';
import { DateHelper } from 'src/app/helpers/dateHelper';

export type InitRangeType = 'none' | 'year' | 'month' | 'week' | 'day';

export class ReportDateValues {
  constructor(public reportDate: Date,
              public reportRangeStartDate: Date,
              public reportRangeEndDate: Date) {
  }

  /** Создать на текущее время */
  public static createForNow(initRange: InitRangeType = 'month') {
    return this.create(new Date(), initRange);
  }

  /** Создать на указанное время */
  public static create(date: Date, initRange: InitRangeType = 'month') {
    let startFunc: (Date) => Date;
    let endFunc: (Date) => Date;

    switch (initRange) {
      case 'none':
        startFunc = x => undefined;
        endFunc = x => undefined;
        break;
      case 'year':
        startFunc = x => DateHelper.getStartOfYear(x);
        endFunc = x => DateHelper.getEndOfYear(x);
        break;
      case 'month':
        startFunc = x => DateHelper.getStartOfMounth(x);
        endFunc = x => DateHelper.getEndOfMounth(x);
        break;
      case 'week':
        startFunc = x => DateHelper.getStartOfWeek(x);
        endFunc = x => DateHelper.getEndOfWeek(x);
        break;
      case 'day':
        startFunc = x => DateHelper.getStartOfDay(x);
        endFunc = x => DateHelper.getEndOfDay(x);
        break;
      default:
        throw new Error('initRange out of range');
    }

    return this.createCustom(date, startFunc(date), endFunc(date));
  }

  /** Создать точно */
  public static createCustom(date: Date, startDate: Date, endDate: Date) {
    date = !date ? date : DateHelper.getStartOfDay(date);
    startDate = !startDate ? startDate : DateHelper.getStartOfDay(startDate);
    endDate = !endDate ? endDate : DateHelper.getEndOfDay(endDate);

    return new ReportDateValues(date, startDate, endDate);
  }

  /** Получить IForDate из ReportDateValues*/
  public static toForDate(reportDateValues: ReportDateValues): IForDate {
    return {
      forDate: reportDateValues?.reportDate ?? new Date(),
      startDate: reportDateValues?.reportRangeStartDate,
      endDate: reportDateValues?.reportRangeEndDate,
    };
  }
}
