<form [formGroup]="form" class="k-form" style="max-width: 50%;">
  <kendo-formfield>
    <kendo-label [for]="declensions" text="Склонения по падежам ФИО"></kendo-label>
    <app-employee-names-declensions-control #declensions formControlName="declensions"></app-employee-names-declensions-control>
  </kendo-formfield>
  <div class="k-form-buttons">
    <button kendoButton type="button" primary="true" [disabled]="form.invalid || form.untouched" (click)="onClickSave()">Сохранить</button>
  </div>
</form>

