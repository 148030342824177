import { Injectable, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogAction, DialogService } from "@progress/kendo-angular-dialog";
import { ReplaySubject, take, takeUntil } from "rxjs";
import { VersioningItem } from "src/app/classes/directories/edit/versioningItem";
import { ResponseObjError } from "src/app/classes/requestResults/responseObjError";
import { DisplayErrorsService } from "src/app/components/display-errors/services/display-errors.service";
import { traceClass } from "src/app/modules/trace/decorators/class.decorator";
import { traceFunc } from "src/app/modules/trace/decorators/func.decorator";
import { trace } from "src/app/modules/trace/operators/trace";
import { TracerServiceBase } from "src/app/modules/trace/tracers2/trace-services/tracer-base.service";
import { exDistinctUntilChanged } from "src/app/operators/ex-distinct-until-changed.operator";
import { ComponentServiceBase } from "src/app/services/abstracts/component-service-base";
import { LoadingIndicatorService } from "src/app/services/loading-indicator.service";
import { CustomFormValidators } from "src/app/validators/custom-form-validators.class";
import { SettingsReaderService } from "../../services/classes/service-settings/settings-reader/settings-reader.service";
import { IDirectoryEditVersioningComponent, IVersioningForm, ValueChangeEvent } from "../i-directory-edit-versioning.component";

@Injectable()
@traceClass('DirectoryEditVersioningComponentService')
export class DirectoryEditVersioningComponentService extends ComponentServiceBase<IDirectoryEditVersioningComponent> implements OnDestroy {

  private streams$: {
    unsubscribes: ReplaySubject<any>
  } = {
      unsubscribes: new ReplaySubject<any>(1)
    }

  /** Родительский элемент */
  public parent: VersioningItem;

  /** Индекс редактируемой строки */
  private editedRowIndex: number;

  private srs = new SettingsReaderService();

  constructor(private loadingIndicatorService: LoadingIndicatorService,
    private tracerService: TracerServiceBase,
    private displayErrorsService: DisplayErrorsService,
    private dialogService: DialogService) {
    super();
  }

  /** Инициализация сервиса компонента */
  @traceFunc()
  public initialize() {
    this.setColumnTitle();
  }

  /** Инициализация формы редактирования/создания */
  @traceFunc()
  public initForm(versioningItem: VersioningItem) {

    //Получаем значение через функцию полечения значения (она зависит от конкретного типа контрола)
    const value = this.component.field.control.getValueFunc(versioningItem.valueAsString);

    //Отключить ли редактирование даты начала и окончания
    const disabledStartEndDate = this.isDisabledStartEndDate(this.component.field.settings.isAllowEditRootStartEndData, this.isParentVersioningItem(versioningItem));

    const form = new FormGroup<IVersioningForm>({
      id: new FormControl(versioningItem.id, { nonNullable: true }),
      ownerId: new FormControl(versioningItem.ownerId),
      comment: new FormControl(versioningItem.comment, [Validators.maxLength(100)]),
      endDate: new FormControl({ value: versioningItem.endDate, disabled: disabledStartEndDate }, { validators: [CustomFormValidators.requiredIf(() => this.isParentVersioningItem(versioningItem) ? this.component.field.settings.isRequiredEndDate : (!!this.parent?.endDate || this.component.field.settings.isRequiredEndDate))], updateOn: 'blur' }),
      startDate: new FormControl({ value: versioningItem.startDate, disabled: disabledStartEndDate }, { validators: [CustomFormValidators.requiredIf(() => this.isParentVersioningItem(versioningItem) ? this.component.field.settings.isRequiredStartDate : (!!this.parent?.startDate || this.component.field.settings.isRequiredStartDate))], updateOn: 'blur' }),
      value: new FormControl(value),
      timestamp: new FormControl(versioningItem.timestamp)
    });

    form.valueChanges.subscribe(v => {
      this.tracerService.add2('Изменилась форма', { obj: v });
    });

    form.controls.startDate.valueChanges
      .pipe(exDistinctUntilChanged(form.controls.startDate.value))
      .subscribe(v => {
        this.component.onStartDateChangeWithInitEventEmitter.next(new ValueChangeEvent(this.getVersioningItemValue(form.value), v));
        this.setStartEndMaxDates(versioningItem);
      });

    form.controls.endDate.valueChanges
      .pipe(exDistinctUntilChanged(form.controls.endDate.value))
      .subscribe(v => {
        this.component.onEndDateChangeWithInitEventEmitter.next(new ValueChangeEvent(this.getVersioningItemValue(form.value), v));
        this.setStartEndMaxDates(versioningItem);
      });

    this.component.form = form;
    setTimeout(() => { this.component.form.markAllAsTouched() }, 100);
  }

  /** Запрещено ли редактировать дату начала и окончания */
  public isDisabledStartEndDate(isAllowEditRootStartEndData: boolean, isParentVersioningItem: boolean) {
    return isAllowEditRootStartEndData ? !isAllowEditRootStartEndData : isParentVersioningItem;
  }

  /** Устанавливает доступные максимальные и минимальные диапазоны дат */
  @traceFunc()
  public setStartEndMaxDates(dataItem: any) {
    const isParent = this.isParentVersioningItem(dataItem);
    const maxParentDateValue = this.component.field.settings.maxParentDateValueFunc ? this.component.field.settings.maxParentDateValueFunc(dataItem) : null;
    const minParentDateValue = this.component.field.settings.minParentDateValueFunc ? this.component.field.settings.minParentDateValueFunc(dataItem) : null;

    this.component.startDateMaxValue = this.getStartDateMaxValue(this.component.form.controls.endDate.value, isParent, maxParentDateValue, this.parent?.endDate);
    this.component.endDateMaxValue = this.getEndDateMaxValue(isParent, maxParentDateValue, this.parent?.endDate);
    this.component.startDateMinValue = this.getStartDateMinValue(isParent, minParentDateValue, this.parent?.startDate);
    this.component.endDateMinValue = this.getEndDateMinValue(this.component.form.controls.startDate.value, isParent, minParentDateValue, this.parent?.startDate);
  }

  /** Получить максимально допустимую дату начала */
  @traceFunc()
  public getStartDateMaxValue(endDate: Date, isParent: boolean, maxParentDateValue: Date, parentEndDateValue: Date) {
    if (isParent) {
      return endDate ? endDate : maxParentDateValue;
    }

    if (endDate && parentEndDateValue) {
      return parentEndDateValue < endDate ? parentEndDateValue : endDate;
    }

    return (parentEndDateValue) ? parentEndDateValue : endDate;
  }

  /** Получить минимально допустимую дату начала */
  @traceFunc()
  public getStartDateMinValue(isParent: boolean, minParentDateValue: Date, parentStartDateValue: Date) {
    if (isParent) {
      return minParentDateValue;
    }
    return parentStartDateValue;
  }

  /** Получить минимально допустимую дату окончания */
  @traceFunc()
  public getEndDateMinValue(startDate: Date, isParent: boolean, minParentDateValue: Date, parentStartDateValue: Date) {

    if (isParent) {
      return startDate ? startDate : minParentDateValue;
    }

    if (startDate && parentStartDateValue) {
      return parentStartDateValue > startDate ? parentStartDateValue : startDate;
    }

    return (parentStartDateValue) ? parentStartDateValue : startDate;
  }

  /** Получить максимально допустимую дату окончания */
  @traceFunc()
  public getEndDateMaxValue(isParent: boolean, maxParentDateValue: Date, parentEndDateValue: Date) {
    if (isParent) {
      return maxParentDateValue;
    }
    return parentEndDateValue;
  }

  /** Является ли VersioningItem родительским? */
  public isParentVersioningItem(item: VersioningItem) {
    if (!item) return false;
    return item.id !== 0 && (item.id === item.ownerId);
  }

  /** Установить columnTitle на основании значения из конфига  */
  @traceFunc()
  public setColumnTitle() {
    this.component.columnTitle = this.srs.getValue(this.component.field.settings.title);
  }

  /** Событие при нажатии кнопки добавить */
  @traceFunc()
  public addHandler({ sender }) {
    this.closeEditor(sender);
    const dataItem = new VersioningItem(null, this.parent.ownerId, null, null, null, null, null);
    this.initForm(dataItem);
    this.setStartEndMaxDates(dataItem);
    sender.addRow(this.component.form);
    this.component.onStartEditOrAddEventEmitter.next(this.getVersioningItemValue(this.component.form.value));
    this.component.onStartDateChangeWithInitEventEmitter.next(new ValueChangeEvent(this.getVersioningItemValue(this.component.form.getRawValue()), this.component.form.getRawValue().startDate));
    this.component.onEndDateChangeWithInitEventEmitter.next(new ValueChangeEvent(this.getVersioningItemValue(this.component.form.getRawValue()), this.component.form.getRawValue().endDate));
  }

  /** Событие при нажатии кнопки редактировать */
  @traceFunc()
  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(sender);
    this.initForm(dataItem);
    this.setStartEndMaxDates(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.component.form);
    this.component.onStartEditOrAddEventEmitter.next(this.getVersioningItemValue(this.component.form.value));
    this.component.onStartDateChangeWithInitEventEmitter.next(new ValueChangeEvent(this.getVersioningItemValue(this.component.form.getRawValue()), this.component.form.getRawValue().startDate));
    this.component.onEndDateChangeWithInitEventEmitter.next(new ValueChangeEvent(this.getVersioningItemValue(this.component.form.getRawValue()), this.component.form.getRawValue().endDate));
  }

  /** Событие при нажатии кнопки сохранить (редактирование/добавление) */
  @traceFunc()
  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const item: VersioningItem = this.getVersioningItemValue(formGroup.value);
    if (isNew) {
      this.component.onAddingEventEmitter.emit(item);
    } else {
      this.component.onSavingEventEmitter.emit(item);
    }
    this.closeEditor(sender);
  }

  /** Событие при нажатии кнопки удалить */
  @traceFunc()
  public removeHandler({ dataItem, isNew, rowIndex, sender }) {
    if (!isNew) {
      const dialog = this.dialogService.open({
        title: 'Подтвердите удаление записи',
        content: "Вы НЕ сможете восстановить удалённые данные в дальнейшем!",
        actions: [{ text: 'Отмена' }, { text: 'Подтверждаю, удалить', themeColor: 'primary' }],
        minWidth: 350,
        minHeight: 150
      });

      dialog.result.subscribe((result: DialogAction) => {
        if (result.themeColor === 'primary') {
          this.component.onDeletingEventEmitter.emit(this.getVersioningItemValue(dataItem));
        }
      });
    }
  }

  /** Событие при нажатии кнопки отменить */
  @traceFunc()
  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  @traceFunc()
  public closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;

    if(this.component.form) {
      this.component.onEndEditOrAddEventEmitter.next(this.getVersioningItemValue(this.component.form.value));
      this.component.form = undefined;
    }
  }

  @traceFunc()
  public ngOnDestroy(): void {
    this.streams$.unsubscribes.next(null);
    this.streams$.unsubscribes.complete();
  }

  /** Получить VersioningItem значения формы */
  @traceFunc()
  public getVersioningItemValue(formValue: { id?, comment?, startDate?, endDate?, ownerId?, timestamp?, value?}) {
    return new VersioningItem(
      formValue.id,
      formValue.ownerId,
      (formValue.value === undefined || formValue.value === null) ? null : String(formValue.value),
      formValue.startDate,
      formValue.endDate,
      formValue.comment,
      formValue.timestamp
    );
  }
}


