export class VersioningItem{

  public displayValue: string;

  constructor(
    public id: number,
    public ownerId: number,
    public valueAsString: string,
    public startDate: Date,
    public endDate: Date,
    public comment: string,
    public timestamp: []
  ) {}
}
