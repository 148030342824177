import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { CellClickExpandedEvent } from 'src/app/services/directives/grid-treelist-expanded-directive.service';

@Component({
  selector: 'app-directories-list',
  templateUrl: './directories-list.component.html',
  styleUrls: ['./directories-list.component.css']
})
export class DirectoriesListComponent {

  basePath = 'workspace/directories/';

  constructor(private router: Router,) {
  }

  public gridDirectoriesData: IGridDirectoryItem[] = [
    { name: 'Сотрудники', description: 'Справочник сотрудников', url: this.basePath + 'employees' },
    { name: 'Подразделения', description: 'Справочник подразделений', url: this.basePath + 'subdivisions' },
    { name: 'Должности', description: 'Справочник должностей', url: this.basePath + 'occupations' },
    { name: 'Категории должностей', description: 'Справочник категорий должностей', url: this.basePath + 'occupation-types' },
    { name: 'Периоды контроля', description: 'Справочник периодов контроля', url: this.basePath + 'report-periods' },
    { name: 'Режимы работы', description: 'Справочник режимов работы', url: this.basePath + 'work-modes' },
    { name: 'Лимиты штатных единиц', description: 'Справочник лимитов штатных единиц', url: this.basePath + 'limit-staff-units' },
    { name: 'Производственный календарь', description: 'Справочник производственного календаря', url: this.basePath + 'production-calendar'}
  ];

  public editHandler({ dataItem }) {
    this.navigateToDirectory(dataItem);
  }

  private navigateToDirectory(item: IGridDirectoryItem) {
    this.router.navigate([item.url]);
  }

  /**
   * Событие происходит при двойном клике на ячейку
   */
  public onDblCellClick($event: CellClickExpandedEvent<CellClickEvent>) {
    this.navigateToDirectory($event.originalEvent.dataItem);
  }

  public getEditButtonTitle(dataItem: IGridDirectoryItem) {
    return `Редактирование справочника "${dataItem.name}"`
  }

}

export interface IGridDirectoryItem {
  name: string;
  description: string;
  url: string;
}
