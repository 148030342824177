import {Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {ObjectHelper} from "../../../helpers/objectHelper";

/**
 * Интерцептор глубоко копирует запрос к серверу.
 *
 * Предотвращает редактирование переданного объекта в запрос последующими интерцепторами.
 * Необходимо подключать раньше модификационных интерцепторов.
 */
@Injectable({providedIn: 'root'})
export class DeepCopyRequestInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqCloned = req.clone({body: ObjectHelper.deepCopy(req.body)});

    return next.handle(reqCloned);
  }
}
