import 'reflect-metadata';

/** Ключ хранения метаданных */
const classBackendMetadataKey = '_classBackendDecorator_'

/** Тип хранимых метаданных */
type ClassBackendType = {
  /** Название типа на сервере */
  name: string,
  /** Схема таблицы */
  scheme: string,
}

/**
 * Декоратор для хранения метаданных информации о серверном объекте.
 * Для получения метаданных используй метод {@link getClassBackendMetadata}.
 * @example POCO объект используемый в signalR
 */
export function classBackend(name: string, scheme?: string) {
  if(!name){
    throw new Error('Параметр `name` должен быть заполнен')
  }

  return function <T extends { new(...constructorArgs: any[]): {} }>(ctor: T) {
    const metadata: ClassBackendType = {
      name: name,
      scheme: scheme,
    }

    Reflect.defineMetadata(classBackendMetadataKey, metadata, ctor);
    return ctor;
  }
}

/**
 * Получить метаданные
 * @param ctor Тип
 */
export function getClassBackendMetadata<T extends abstract new (...args: any) => any>(ctor: T): ClassBackendType {
  return Reflect.getMetadata(classBackendMetadataKey, ctor);
}
