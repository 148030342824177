<form class="k-form" [formGroup]="form" *ngIf="form">
  <div class="form-inline-div">
    <kendo-formfield>
      <kendo-label [for]="date" text="Дата перевода"></kendo-label>
      <kendo-datepicker #date [formControlName]="'date'"></kendo-datepicker>
      <kendo-formerror *ngIf="form.controls.date.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
    </kendo-formfield>
  </div>

  <kendo-formfield>
    <kendo-label [for]="subdivisionId" text="Подразделение"></kendo-label>
    <kendo-dropdowntree #subdivisionId
      kendoDropDownTreeExpandable
      [expandedKeys]="expandedSubdivisionsIndices$ | async"
      [kendoDropDownTreeFlatBinding]="subdivisions$ | async"
      parentIdField="parentId"
      [valueField]="'id'"
      [textField]="'longName'"
      [formControlName]="'subdivisionId'"
      [valuePrimitive]="true"
      [popupSettings]="{width:'auto'}"
      [filterable]="true"
      [expandBy]="'id'"
    >
    </kendo-dropdowntree>

      <kendo-formerror *ngIf="form.controls.subdivisionId?.errors?.invalidData">Неправильное значение</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.subdivisionId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="positionId" text="Штатная единица"></kendo-label>
    <kendo-dropdownlist
      #positionId
      [data]="positions$ | async"
      textField="occupation.name"
      valueField="id"
      [valuePrimitive]="true"
      [formControlName]="'positionId'"
    >
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      {{dataItem.occupation.name}} / {{dataItem.workMode.name}}
    </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror *ngIf="form.controls.positionId?.errors?.invalidData">Неправильное значение</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.positionId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="financingSourceId" text="Источник финансирования"></kendo-label>
    <kendo-dropdownlist
      #financingSourceId
      [data]="freeRates$ | async"
      textField="financingSource.shortName"
      valueField="financingSource.id"
      [valuePrimitive]="true"
      [formControlName]="'financingSourceId'"
      [itemDisabled]="financingSourceDisabled"
    >
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      {{dataItem.financingSource.shortName}} (вакантно: {{dataItem.rate}})
    </ng-template>
    </kendo-dropdownlist>
    <kendo-formerror *ngIf="form.controls.financingSourceId?.errors?.invalidData">Неправильное значение</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.financingSourceId.errors?.required">Пожалуйста, заполните обязательное поле.</kendo-formerror>
  </kendo-formfield>

  <kendo-formfield class="horizontal-form-field">
    <kendo-label [for]="executionDutiesFlag"
                 text="Исполнение обязанностей"
                 style="margin-right: 10px;">
    </kendo-label>
    <input type="checkbox"
           kendoCheckBox
           #executionDutiesFlag
           [formControl]="form.controls.executionDutiesFlag"/>
  </kendo-formfield>

  <kendo-formfield>
    <kendo-label [for]="comment" text="Комментарий"></kendo-label>
    <input kendoTextBox #comment [formControlName]="'comment'" />
    <kendo-formerror *ngIf="form.controls.comment.errors?.minlength">Длина значения должна быть больше {{form.controls.comment.errors?.minlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.minlength.actualLength}}</kendo-formerror>
    <kendo-formerror *ngIf="form.controls.comment.errors?.maxlength">Длина значения должна быть меньше {{form.controls.comment.errors?.maxlength.requiredLength}}. Текущая длина: {{form.controls.comment.errors?.maxlength.actualLength}}</kendo-formerror>
  </kendo-formfield>

  <div class="k-form-buttons">
    <button kendoButton type="button" (click)="onClickCancel()">Отменить</button>
    <button kendoButton type="button" primary="true" [disabled]="!form.valid" (click)="onClickTransfer()">Перевести</button>
  </div>
</form>

